import { TableCell } from '../../../../ui-components/Table';
import { WerkeAuswahl } from '../../../WerkeAuswahl/WerkeAuswahl';
import { useWerkOptions } from '../../../../hooks/useWerkOptions';
import { PropertyEntryReadonlyProps } from '../types';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../../../store/Navigation.store';

export const WerkeAuswahlTableCell = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps): ReactElement | null => {
    const werkOptions = useWerkOptions(bestellposition.nummer);
    const isDebitor = useSelector(selectIsDebitor);

    if (!isDebitor) {
        return null;
    }

    if (readonly) {
        const werkName = bestellposition.werk || werkOptions.find((werk) => werk.value === bestellposition.werkId)?.label;
        return (
            <TableCell text storniert={bestellposition.storno}>
                <span data-cy="werk" data-testid={`${bestellposition.nummer}-werk-${bestellposition.werkId}`}>
                    {werkName}
                </span>
            </TableCell>
        );
    }

    if (werkOptions.length === 0) {
        return <TableCell text>Lade Werke...</TableCell>;
    }

    return (
        <TableCell select storniert={bestellposition.storno}>
            <WerkeAuswahl bestellposition={bestellposition} disabled={disabled} options={werkOptions} />
        </TableCell>
    );
};
