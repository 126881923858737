import { ReactNode } from 'react';
import styles from './Label.module.scss';

export type Props = Readonly<{
    children: ReactNode;
    htmlFor?: string;
    hasError?: boolean;
    noWrap?: boolean;
}>;

const Label = (props: Props): JSX.Element => {
    const baseStyles = props.hasError ? [styles._label, styles['_label--error']] : [styles._label];
    const labelStyles = props.noWrap ? [baseStyles, styles['_label--no-wrap']] : [baseStyles];

    return (
        <label className={labelStyles.join(' ')} htmlFor={props.htmlFor}>
            {props.children}
        </label>
    );
};

export default Label;
