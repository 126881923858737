import { ArtikelTableValues, BestellpositionArtikelLoseWareView } from '../../types';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../store/Navigation.store';
import { KontraktSelectors } from '../../../store/Kontrakt.store';
import { optionsFromKontrakte } from '../../helper/select-options';
import { Berechnungsart } from '../../types/enums';
import styles from '../Bestellpositionen/ArtikelLoseWareTable/LoseWareTable.module.scss';
import SelectInput, { SingleValue } from '../../ui-components/SelectInput/SelectInput';
import TextItem from '../../ui-components/TextItem/TextItem';
import Label from '../../ui-components/Label/Label';
import { useFetchKontrakte } from '../../hooks/useFetchKontrakte';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';
import { getISOWunschtermin } from '../../helper/date-helper';
import { isExistingBestellposition } from '../../helper/bestellungen-helper';
import { BestelluebersichtSelectors } from '../../../store/Bestelluebersicht.store';

const KEIN_KONTRAKT_VORHANDEN_TEXT = 'Kein gültiger Kontrakt vorhanden';
const BITTE_WERK_UND_WUNSCHTERMIN_AUSWAEHLEN_TEXT = 'Bitte wählen Sie Werk und Wunschtermin aus';

type KontraktSelectionProps = { bestellposition: BestellpositionArtikelLoseWareView; disabled?: boolean };
export const KontraktAuswahl = ({ bestellposition, disabled }: KontraktSelectionProps): JSX.Element | null => {
    const isDebitor = useSelector(selectIsDebitor);
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const formikPath = `bestellpositionenView[${bestellposition.index}].kontrakt`;
    const { isDraft } = useSelector(BestelluebersichtSelectors.currentBestellung);

    const { values } = useFormikContext<ArtikelTableValues & { datumDebitor: string; uhrzeitDebitor: string }>();
    const wunschterminISO =
        !isDraft && isExistingBestellposition(bestellposition)
            ? getISOWunschtermin(bestellposition.debitorDatum, bestellposition.debitorUhrzeit)
            : getISOWunschtermin(values.datumDebitor, values.uhrzeitDebitor);

    useFetchKontrakte(
        bestellposition.nummer,
        bestellposition.werkId,
        bestellposition.index,
        bestellposition.berechnungsart,
        wunschterminISO
    );

    const kontrakteForArtikel = useSelector(KontraktSelectors.forArtikel);
    const kontraktOptions = optionsFromKontrakte(kontrakteForArtikel[bestellposition.nummer]);

    if (bestellposition.berechnungsart !== Berechnungsart.Kontrakt || !isDebitor) {
        return null;
    }

    if (!bestellposition.werkId || !values.datumDebitor) {
        if (isMobile) {
            return <TextItem label="Kontrakt" text="&nbsp;" error={BITTE_WERK_UND_WUNSCHTERMIN_AUSWAEHLEN_TEXT} />;
        }
        return <div className={styles._kontraktMeldung}>{BITTE_WERK_UND_WUNSCHTERMIN_AUSWAEHLEN_TEXT}</div>;
    }

    if (kontraktOptions.length === 0) {
        if (isMobile) {
            return <TextItem label="Kontrakt" text="&nbsp;" error={KEIN_KONTRAKT_VORHANDEN_TEXT} />;
        }
        return <div className={styles._kontraktMeldung}>{KEIN_KONTRAKT_VORHANDEN_TEXT}</div>;
    }

    const SelectionComponent = (
        <SelectInput
            key={`selected-${bestellposition.kontrakt}`}
            storniert={bestellposition.storno}
            isDisabled={disabled}
            classNamePrefix={'react_select__form-kontrakt__control'}
            id={formikPath}
            name={formikPath}
            options={kontraktOptions}
            components={{ SingleValue }}
            placeholder="Auswahl Kontrakt"
            menuAlignment="right"
            hideNativeSelect={isMobile}
            isClearable={false}
            inTableCell={!isMobile}
            autoSelectIfOnlyOneOption
        />
    );

    if (isMobile) {
        return (
            <div>
                <Label htmlFor={formikPath}>Kontrakt</Label>
                {SelectionComponent}
            </div>
        );
    }

    return SelectionComponent;
};
