import { CellProps, Column } from 'react-table';
import TableCell from '../../../../shared/ui-components/Table/ReactTable/TableCell';
import type { TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from '../../TourenplanTypes';

const LieferhinweisColumnCell = (
    cellProps: CellProps<TourenplanLoseWareBestellung | TourenplanSackwareBestellung, string>
): JSX.Element => {
    // TODO: Lieferhinweis ist pro Bestellung, soll dieser bearbeitet werden können?
    const lieferhinweis = cellProps.value;
    const cell = cellProps.cell;
    return <TableCell cell={cell}>{lieferhinweis}</TableCell>;
};

const lieferhinweisColumnConfig = <T extends TourenplanLoseWareBestellung | TourenplanSackwareBestellung>(): Column<T> => ({
    Header: 'Lieferhinweis',
    accessor: 'lieferhinweis',
    Cell: LieferhinweisColumnCell,
});

export const loseWareLieferhinweisColumnConfig = lieferhinweisColumnConfig<TourenplanLoseWareBestellung>();
export const sackwareLieferhinweisColumnConfig = lieferhinweisColumnConfig<TourenplanSackwareBestellung>();
