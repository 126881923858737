import { Row } from 'react-table';
import tableStyles from '../Table.module.scss';
import { Fragment } from 'react';

interface TableRowProps<T extends Record<string, unknown>> {
    row: Row<T>;
}
const TableRow = <T extends Record<string, unknown>>({ row }: TableRowProps<T>): JSX.Element => {
    return (
        <tr {...row.getRowProps()} className={tableStyles._tableBodyRow}>
            {row.cells.map((cell, index) => {
                return <Fragment key={index}>{cell.render('Cell')}</Fragment>;
            })}
        </tr>
    );
};

export default TableRow;
