export enum LoseWareColumns {
    WarenempfaengerColumnHeader = 'Warenempfaenger',
    ArtikelColumnHeader = 'Artikel',
    WerkColumnHeader = 'Werk',
    MengeInTonnenColumnHeader = 'Menge',
    KesselColumnHeader = 'Kesselverteilung',
    SiloColumnHeader = 'Silo',
    LieferhinweisColumnHeader = 'Lieferhinweis',
}

export enum SackwareColumns {
    WarenempfaengerColumnHeader = 'Warenempfaenger',
    ArtikelColumnHeader = 'Artikel',
    WerkColumnHeader = 'Werk',
    MengeVeColumnHeader = 'VE',
    MengePalColumnHeader = 'Pal.',
    LieferhinweisColumnHeader = 'Lieferhinweis',
}
