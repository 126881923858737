import type { Dokument, Filter } from '../shared/types';
import { Order, RequestState } from '../shared/types/enums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../configureStore';

export interface GetDokumenteAction {
    page: number;
    size: number;
    sortColumn: string;
    sortOrder: Order;
    keyword?: string;
    filter?: Filter;
}

export enum DOKUMENTART {
    ABHOLSCHEIN = 'Abholschein',
    AUSRECH = 'Ausgangsrechnung',
    LIEFERMELDUNG = 'Liefermeldung',
    SERIENBR = 'Serienbrief',
    VEKONTRAKT = 'Vertriebskontrakt',
    VEPRLISTE = 'Vertriebspreisliste',
    VEREINB = 'Vereinbarung',
    LIEFERSCHEIN = 'Lieferschein',
}

const dokumentartOptions = Object.entries(DOKUMENTART).map(([key, value]) => ({ value: key, label: value }));

export const sortedDokumentartOptions = dokumentartOptions.sort((a, b) => a.label.localeCompare(b.label));

export type DokumentId = {
    dokumentId: string;
};

export type DokumentDownloadAction = DokumentId;
export type SelectDokumentAction = DokumentId;
export type DeselectDokumentAction = DokumentId;

export interface GetDokumenteSucceededAction {
    dokumente: Dokument[];
    totalCount: number;
}

export type DokumenteState = {
    dokumente: Dokument[];
    dokumenteLoadingState: RequestState;
    dokumentDownloadingState: RequestState;
    dokumenteDownloadingState: RequestState;
    totalCount: number;
    selectedDokumentIds: string[];
};

export const INITIAL_DOKUMENTE_STATE: DokumenteState = {
    dokumente: [],
    dokumenteLoadingState: RequestState.INITIAL,
    dokumentDownloadingState: RequestState.INITIAL,
    dokumenteDownloadingState: RequestState.INITIAL,
    totalCount: 0,
    selectedDokumentIds: [],
};

export const DokumentSelectors = {
    dokumente: (state: RootState): Dokument[] => state.dokumenteSilageergebnisse.dokumente,
    totalCount: (state: RootState): number => state.dokumenteSilageergebnisse.totalCount,
    dokumenteLoading: (state: RootState): boolean => state.dokumenteSilageergebnisse.dokumenteLoadingState === RequestState.LOADING,
    dokumenteFailed: (state: RootState): boolean => state.dokumenteSilageergebnisse.dokumenteLoadingState === RequestState.FAILED,
    isDokumentChecked: (dokumentId: string): ((state: RootState) => boolean) => {
        return (state: RootState) => state.dokumenteSilageergebnisse.selectedDokumentIds.some((dok) => dok === dokumentId);
    },
    selectedDokumentIds: (state: RootState): string[] => state.dokumente.selectedDokumentIds,
    isSelectedDokumenteEmpty: (state: RootState): boolean => state.dokumenteSilageergebnisse.selectedDokumentIds.length <= 0,
};

const dokumentSlice = createSlice({
    name: 'dokument',
    initialState: INITIAL_DOKUMENTE_STATE,
    reducers: {
        getDokumenteSilageergebnisse(state, _: PayloadAction<GetDokumenteAction>) {
            state.dokumenteLoadingState = RequestState.LOADING;
        },
        getDokumenteSucceeded(state, action: PayloadAction<GetDokumenteSucceededAction>) {
            state.dokumente = action.payload.dokumente;
            state.totalCount = action.payload.totalCount;
            state.dokumenteLoadingState = RequestState.SUCCESSFUL;
        },
        getDokumenteFailed(state, _: PayloadAction) {
            state.dokumenteLoadingState = RequestState.FAILED;
        },
        dokumentDownload(state, _: PayloadAction<DokumentDownloadAction>) {
            state.dokumentDownloadingState = RequestState.LOADING;
        },
        dokumentDownloadSucceeded(state, _: PayloadAction) {
            state.dokumentDownloadingState = RequestState.SUCCESSFUL;
        },
        dokumentDownloadFailed(state, _: PayloadAction) {
            state.dokumentDownloadingState = RequestState.FAILED;
        },
        selectDokument(state, action: PayloadAction<SelectDokumentAction>) {
            state.selectedDokumentIds = [...state.selectedDokumentIds, action.payload.dokumentId];
        },
        deselectDokument(state, action: PayloadAction<DeselectDokumentAction>) {
            state.selectedDokumentIds = state.selectedDokumentIds.filter((dokId) => dokId !== action.payload.dokumentId);
        },
        deselectAllDokumente(state, _: PayloadAction) {
            state.selectedDokumentIds = [];
        },
        dokumenteDownload(state, _: PayloadAction) {
            state.dokumenteDownloadingState = RequestState.LOADING;
        },
        dokumenteDownloadSucceeded(state, _: PayloadAction) {
            state.dokumenteDownloadingState = RequestState.SUCCESSFUL;
        },
        dokumenteDownloadFailed(state, _: PayloadAction) {
            state.dokumenteDownloadingState = RequestState.FAILED;
        },
    },
});

export const {
    getDokumenteSucceeded,
    getDokumenteFailed,
    getDokumenteSilageergebnisse,
    dokumentDownload,
    dokumentDownloadSucceeded,
    dokumentDownloadFailed,
    dokumenteDownloadFailed,
    dokumenteDownloadSucceeded,
    dokumenteDownload,
    selectDokument,
    deselectDokument,
    deselectAllDokumente,
} = dokumentSlice.actions;

export const dokumenteSilageergebnisseReducer = dokumentSlice.reducer;
