import { WunschterminTageszeit } from './WunschterminTageszeit';
import { WunschterminUhrzeit } from './WunschterminUhrzeit';
import { useSelector } from 'react-redux';
import { selectIsWarenempfaenger } from '../../../store/Navigation.store';
import { WunschterminMode, WunschterminProps } from './wunschtermin-helper';
import { WunschterminZeitraum } from './WunschterminZeitraum';

const Wunschtermin = (props: WunschterminProps): JSX.Element => {
    const isWarenempfeanger = useSelector(selectIsWarenempfaenger);

    if (isWarenempfeanger) {
        return <WunschterminZeitraum />;
    }

    switch (props.mode) {
        case WunschterminMode.TAGESZEIT:
            return <WunschterminTageszeit {...props} />;
        case WunschterminMode.UHRZEIT:
            return <WunschterminUhrzeit {...props} />;
        default:
            return <></>;
    }
};

export { Wunschtermin };
