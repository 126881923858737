import { Vorschlag } from '../shared/types';
import { setRechnungsempfaenger, setWarenempfaenger } from './Bestellung.store';

export const GET_VORSCHLAEGE = 'GET_VORSCHLAEGE';
export const GET_VORSCHLAEGE_SUCCEEDED = 'GET_VORSCHLAEGE_SUCCEEDED';

export interface GetVorschlaegeSucceededAction {
    type: typeof GET_VORSCHLAEGE_SUCCEEDED;
    vorschlaege: Vorschlag[];
}
export interface GetVorschlaegeAction {
    type: typeof GET_VORSCHLAEGE;
    rechnungsempfaengerPartnerNummer: string;
    warenempfaengerPartnerNummer: string;
}

export function getVorschlaege(rechnungsempfaengerPartnerNummer: string, warenempfaengerPartnerNummer: string): GetVorschlaegeAction {
    return {
        type: GET_VORSCHLAEGE,
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
    };
}
export function getVorschlaegeSucceeded(vorschlaege: Vorschlag[]): GetVorschlaegeSucceededAction {
    return {
        type: GET_VORSCHLAEGE_SUCCEEDED,
        vorschlaege,
    };
}

export type VorschlaegeState = {
    vorschlaege: Vorschlag[];
    isLoading: boolean;
    loadFinished: boolean;
};

export const INITIAL_VORSCHLAEGE_STATE: VorschlaegeState = {
    vorschlaege: [],
    isLoading: false,
    loadFinished: false,
};

export type VorschlaegeActions =
    | GetVorschlaegeSucceededAction
    | GetVorschlaegeAction
    | { type: typeof setRechnungsempfaenger.type }
    | { type: typeof setWarenempfaenger.type };

export function vorschlaegeReducer(state: VorschlaegeState = INITIAL_VORSCHLAEGE_STATE, action: VorschlaegeActions): VorschlaegeState {
    switch (action.type) {
        case GET_VORSCHLAEGE:
            return {
                ...state,
                isLoading: true,
                loadFinished: false,
            };
        case GET_VORSCHLAEGE_SUCCEEDED:
            return {
                ...state,
                vorschlaege: action.vorschlaege,
                isLoading: false,
                loadFinished: true,
            };
        case setWarenempfaenger.type:
            return INITIAL_VORSCHLAEGE_STATE;
        case setRechnungsempfaenger.type:
            return INITIAL_VORSCHLAEGE_STATE;
        default:
            return state;
    }
}
