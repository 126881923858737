import textItemStyles from './TextItem.module.scss';
import { ReactElement, useMemo } from 'react';

export type Props = Readonly<{
    label: string | JSX.Element;
    text?: string | number | JSX.Element;
    error?: string;
    isNumeric?: boolean;
    isStorniert?: boolean;
    testId?: string;
    dataCy?: string;
    compressed?: boolean;
    additionalStyles?: string[];
    containerStyle?: string;
}>;

const TextItem = ({
    label,
    text,
    error,
    isStorniert = false,
    isNumeric,
    testId,
    dataCy,
    compressed = false,
    additionalStyles = [],
    containerStyle = '',
}: Props): ReactElement => {
    const textStyles = useMemo(() => {
        const styles = [textItemStyles._text];
        if (isNumeric) {
            styles.push(textItemStyles['_text--numeric']);
        }

        if (isStorniert) {
            styles.push(textItemStyles['_text--storniert']);
        }

        if (additionalStyles?.length > 0) {
            styles.push(...additionalStyles);
        }

        return styles.join(' ');
    }, [additionalStyles, isNumeric, isStorniert]);

    if (compressed) {
        return (
            <div className={containerStyle}>
                <p className={[textItemStyles._label, textItemStyles['_text--compressed']].join(' ')}>
                    <span>{label}:</span>
                    <span>{text}</span>
                </p>
            </div>
        );
    }

    if (text) {
        return (
            <div className={containerStyle}>
                <p className={textItemStyles._label}>{label}</p>
                <div className={textStyles} data-testid={testId} data-cy={dataCy}>
                    {text}
                </div>
                {error && <div className={textItemStyles._errorMessage}>{error}</div>}
            </div>
        );
    }

    return <></>;
};

export default TextItem;
