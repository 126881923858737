import { ReactNode } from 'react';
import InputMask from 'react-input-mask';
import SelectInput from '../../ui-components/SelectInput/SelectInput';
import FormControl from '../../ui-components/FormControl/FormControl';
import { formatVvvoNummer, vvvoRegExp } from '../../helper/vvvoNummern-helper';
import type { Option } from '../../helper/select-options';
import styles from './VvvoNummerAuswahl.module.scss';
import { addVvvoNummer } from '../../../store/Warenempfaenger.store';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../../configureStore';
import { useAppSelector } from '../../../appSelector';
import { emptyWarenempfaenger } from '../../types/defaultValues';

export type VvvoNummerAuswahlProps = {
    onVvvoCreated: (newVvvoNummer: string) => void;
    onVvvoNummerSelected: (selectedVvvoNummer: string) => void;
};
const VvvoNummerAuswahl = ({ onVvvoCreated, onVvvoNummerSelected }: VvvoNummerAuswahlProps): JSX.Element => {
    const warenempfaengerPartnerNummer = useSelector((state: RootState) => state.bestellung.warenempfaengerPartnerNummer);
    const warenempfaenger =
        useAppSelector((state) =>
            state.warenempfaenger.warenempfaenger.find((we) => state.bestellung.warenempfaengerPartnerNummer === we.partnerNummer)
        ) || emptyWarenempfaenger;
    const vvvoNummern: Option[] = warenempfaenger.vvvoNummern.map((vvvoNummer) => {
        const formattedVvvoNummer = formatVvvoNummer(vvvoNummer);
        return {
            value: vvvoNummer,
            label: formattedVvvoNummer,
        };
    });
    const dispatch = useDispatch();

    return (
        <FormControl
            name="vvvoNummer"
            id="vvvoNummer"
            label="VVVO-Nummer"
            renderChild={(props): JSX.Element => (
                <SelectInput<Option>
                    {...props}
                    options={vvvoNummern}
                    onCreateItem={(newVvvoNummer: string) => {
                        const isValidVvvoNummer = vvvoRegExp.test(newVvvoNummer);
                        if (isValidVvvoNummer) {
                            dispatch(addVvvoNummer(warenempfaengerPartnerNummer, newVvvoNummer));
                            onVvvoCreated(newVvvoNummer);
                        }
                    }}
                    onValueChange={onVvvoNummerSelected}
                    hideNativeSelect={true}
                    formatCreateLabel={(inputValue: string): ReactNode => {
                        const isValidVvvoNummer = vvvoRegExp.test(inputValue);
                        return (
                            <div>
                                <InputMask mask="999 99 999 999 9999" className={styles._newVvvoNummer} value={inputValue} readOnly />
                                {isValidVvvoNummer ? (
                                    <span> anlegen</span>
                                ) : (
                                    <div> (Geben Sie eine gültige VVVO Nummer an, um diese neu zu erfassen)</div>
                                )}
                            </div>
                        );
                    }}
                    placeholder="VVVO-Nummer auswählen oder neu eingeben"
                    noOptionsMessage="VVVO-Nummer eingeben (es sind noch keine Nummern hinterlegt)"
                    maxLength={15}
                    isClearable={true}
                    autoSelectIfOnlyOneOption={true}
                />
            )}
        />
    );
};

export default VvvoNummerAuswahl;
