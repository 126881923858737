import { HeaderGroup, TableInstance } from 'react-table';
import tableStyles from '../Table.module.scss';
import { kesselverteilungColumnConfig } from '../../../../views/Tourenplan/tables/columns/loseware/KesselverteilungColumnConfig';
import tourenplanTableStyles from '../../../../views/Tourenplan/tables/TourenplanTable.module.scss';

interface TableHeaderWithoutSortingProps<T extends Record<string, unknown>> extends TableInstance<T> {
    headerGroup: HeaderGroup<T>;
}

function TableHeaderRowWithoutSorting<T extends Record<string, unknown>>({ headerGroup }: TableHeaderWithoutSortingProps<T>): JSX.Element {
    const headers = headerGroup.headers;
    const headerGroupProps = headerGroup.getHeaderGroupProps();
    return (
        <tr {...headerGroupProps} className={tableStyles._overviewHeaderRow}>
            {headers.map((column, index) => {
                const classNames = `${tableStyles['_tableCell--text']} ${
                    column.id === kesselverteilungColumnConfig.id ? tourenplanTableStyles._kesselVerteilungColumn : ''
                }`;
                return (
                    <th className={classNames} key={index}>
                        {column.render('Header')}
                    </th>
                );
            })}
        </tr>
    );
}

export default TableHeaderRowWithoutSorting;
