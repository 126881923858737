import { ErpRueckmeldung } from '../../../../types';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../../../store/Navigation.store';
import { BestelluebersichtSelectors } from '../../../../../store/Bestelluebersicht.store';

export const useErpRueckmeldung = (nummer: string, positionsnummer?: number): Partial<ErpRueckmeldung> | null => {
    const isDebitor = useSelector(selectIsDebitor);
    const { isDraft } = useSelector(BestelluebersichtSelectors.currentBestellung);
    const erpRueckmeldung = useSelector(BestelluebersichtSelectors.getErpRueckmeldungForPosition(nummer, positionsnummer));

    if (isDebitor && erpRueckmeldung && !isDraft) {
        return erpRueckmeldung;
    }

    return null;
};
