import { useSelector } from 'react-redux';
import { BestellungSelectors } from '../../../store/Bestellung.store';
import NotificationBar from '../../../shared/ui-components/NotificationBar/NotificationBar';
import { Link } from 'react-router-dom';
import { routes } from '../../../store/Workflow.store';

export const RechnungsempfaengerErrorNotification = (): JSX.Element => {
    const rechnungsempfaengerPartnerNummer = useSelector(BestellungSelectors.rechnungsempfaengerPartnerNummer);
    return (
        <NotificationBar testId="rechnungsempfaenger-error-message-bar" message="" isVisible={!Boolean(rechnungsempfaengerPartnerNummer)}>
            <Link to={routes.rechnungsempfaengerAuswaehlen}>Bitte wählen Sie erst einen Rechnungsempfänger aus.</Link>
        </NotificationBar>
    );
};
