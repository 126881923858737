import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import styles from './ArtikelHeimtierfutter.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { BaseBestellposition, BestellpositionArtikelSackwareView, SollbestandAndBestand } from '../../shared/types';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { Form, Formik, FormikContextType, useFormikContext } from 'formik';
import Button from '../../shared/ui-components/Button/Button';
import { Link, useHistory } from 'react-router-dom';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { routes, selectNextRoute, setCurrentWorkflowStep, WorkflowStep } from '../../store/Workflow.store';
import { BestellungSelectors, HeimtierfutterFormular, setBestellpositionen, setHeimtierfutterFormular } from '../../store/Bestellung.store';
import {
    ArtikelTableValuesGrouped,
    forEachBestellposition,
    getFlatBestellpositionen,
    getGroupedBestellpositionen,
    GroupedBestellpostionen,
    MarkeArtikelGruppen,
} from '../../shared/helper/artikel-helper';
import BestellinformationenHeimtierfutter from '../../shared/content-components/BestellinformationenHeimtierfutter/BestellinformationenHeimtierfutter';
import { getWerke } from '../../store/Abholwerk.store';
import { postSollbestand } from '../../store/Sollbestand.store';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import type { RootState } from '../../configureStore';
import WorkflowButtons from '../../shared/content-components/WorkflowButtons/WorkflowButtons';
import { ARTIKEL_VALIDATION_ERROR } from '../../shared/constants';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { ArtikelTyp, Bestellprozesstyp } from '../../shared/types/enums';
import { emptyWerk } from '../../shared/types/defaultValues';
import SackwareTableGrouped from '../../shared/content-components/Bestellpositionen/ArtikelSackwareTable/SackwareTableGrouped';
import { useFetchArtikelSackwaren } from '../../shared/hooks/useFetchArtikelSackwaren';
import { useFetchSollbestand } from '../../shared/hooks/useFetchSollbestand';
import { ArtikelSackwarenSelectors } from '../../store/ArtikelSackware.store';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';

function isBestellungNotEmpty(values: ArtikelTableValuesGrouped): boolean {
    const positionen = getFlatBestellpositionen(values);
    return positionen.some((bp): boolean => Boolean(bp.mengeVe) || Boolean(bp.mengePal));
}

function showFormularwechselConfirmation(onOkCallback: () => void): void {
    showConfirmationDialog({
        message: <>Durch eine Änderung des Bestellformulars werden Ihre bisherigen Eingaben verworfen.</>,
        okButtonText: 'Bestellformular wechseln',
        onOkCallback,
    });
}

function clearBestellpositionen({ values, setFieldValue }: FormikContextType<ArtikelTableValuesGrouped>): void {
    forEachBestellposition(values.groupedBestellpositionen, (bp, markeId, artikelGruppeId, bpIndex) => {
        void setFieldValue(
            `groupedBestellpositionen[${markeId}].artikelGruppen[${artikelGruppeId}].bestellpositionenView[${bpIndex}].mengeVe`,
            undefined
        );
        void setFieldValue(
            `groupedBestellpositionen[${markeId}].artikelGruppen[${artikelGruppeId}].bestellpositionenView[${bpIndex}].mengePal`,
            undefined
        );
    });
}

const orderByBestellposition = (
    [, artikelGruppenDerMarke1]: [string, MarkeArtikelGruppen],
    [, artikelGruppenDerMarke2]: [string, MarkeArtikelGruppen]
): number => {
    if (artikelGruppenDerMarke1.position < artikelGruppenDerMarke2.position) {
        return -1;
    }
    if (artikelGruppenDerMarke2.position > artikelGruppenDerMarke1.position) {
        return 1;
    }
    return 0;
};

const BestellungMitBestellvorschlag = (): ReactElement => {
    const heimtierfutterFormular = useSelector((state: RootState) => state.bestellung.heimtierfutterFormular);
    const dispatch = useDispatch();
    const formikContext = useFormikContext<ArtikelTableValuesGrouped>();

    const setBestellvorschlagMitHilfe = (): void => {
        if (isBestellungNotEmpty(formikContext.values)) {
            showFormularwechselConfirmation(() => {
                clearBestellpositionen(formikContext);
                dispatch(setHeimtierfutterFormular(HeimtierfutterFormular.Bestellvorschlag));
            });
        } else {
            dispatch(setHeimtierfutterFormular(HeimtierfutterFormular.Bestellvorschlag));
        }
    };

    return (
        <Button
            isSecondary={heimtierfutterFormular === HeimtierfutterFormular.Standard}
            type="button"
            onClick={setBestellvorschlagMitHilfe}
        >
            Bestellung aus Bestellvorschlag
        </Button>
    );
};

const BestellungOhneBestellvorschlag = (): ReactElement => {
    const heimtierfutterFormular = useSelector((state: RootState) => state.bestellung.heimtierfutterFormular);
    const dispatch = useDispatch();
    const formikContext = useFormikContext<ArtikelTableValuesGrouped>();

    const setBestellvorschlagToOhneHilfe = (): void => {
        if (isBestellungNotEmpty(formikContext.values)) {
            showFormularwechselConfirmation(() => {
                clearBestellpositionen(formikContext);
                dispatch(setHeimtierfutterFormular(HeimtierfutterFormular.Standard));
            });
        } else {
            dispatch(setHeimtierfutterFormular(HeimtierfutterFormular.Standard));
        }
    };

    return (
        <Button
            isSecondary={heimtierfutterFormular === HeimtierfutterFormular.Bestellvorschlag}
            type="button"
            onClick={setBestellvorschlagToOhneHilfe}
        >
            Bestellung ohne Hilfe
        </Button>
    );
};

const GroupedBestellpositionen = (): ReactElement => {
    const { values } = useFormikContext<ArtikelTableValuesGrouped>();
    const heimtierfutterFormular = useSelector((state: RootState) => state.bestellung.heimtierfutterFormular);

    return (
        <>
            {Object.entries(values.groupedBestellpositionen)
                .sort(orderByBestellposition)
                .map(([marke, artikelGruppenDerMarke]) => {
                    return (
                        <Fragment key={marke}>
                            <h3 className={styles._marke}>Artikel {artikelGruppenDerMarke.label}</h3>
                            <SackwareTableGrouped
                                markenFilter={marke}
                                showSollbestaende={heimtierfutterFormular === HeimtierfutterFormular.Bestellvorschlag}
                            />
                        </Fragment>
                    );
                })}
        </>
    );
};

const ArtikelHeimtierFutterWorkflowButtons = (): ReactElement => {
    const { submitForm } = useFormikContext<ArtikelTableValuesGrouped>();
    return <WorkflowButtons onNextClick={submitForm} />;
};

const ArtikelHeimtierfutter = (): ReactElement => {
    const selectedWerkId = useSelector(BestellungSelectors.selectedWerkId);
    const selectedWerk = useSelector((state: RootState) => state.werke.werke.find((w) => w.id === selectedWerkId) || emptyWerk);
    const artikelList = useSelector(ArtikelSackwarenSelectors.list);
    const isLoading = useSelector(ArtikelSackwarenSelectors.loading);
    const loadFailed = useSelector(ArtikelSackwarenSelectors.loadFailed);
    const rechnungsempfaengerPartnerNummer = useSelector(BestellungSelectors.rechnungsempfaengerPartnerNummer);
    const warenempfaengerPartnerNummer = useSelector(BestellungSelectors.warenempfaengerPartnerNummer);
    const sollbestand = useSelector((state: RootState) => state.sollbestand.sollbestand);
    const bestellpositionen = useSelector(BestellungSelectors.bestellpositionenSackware);
    const isTabletPortrait = useBreakpoint(Breakpoint.TABLET_PORTRAIT);
    useDocumentTitle('Artikel Sackware');
    const dispatch = useDispatch();
    const history = useHistory();

    const nextRoute = useSelector(selectNextRoute);
    const [amountMissing, setAmountMissing] = useState(false);

    const groupedBestellpositionen = useMemo(
        () => getGroupedBestellpositionen(artikelList, bestellpositionen, sollbestand),
        [artikelList, bestellpositionen, sollbestand]
    );

    const reloadArtikelSackwaren = useFetchArtikelSackwaren(
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        Bestellprozesstyp.Heimtierfutter,
        selectedWerkId
    );
    useFetchSollbestand(warenempfaengerPartnerNummer);

    useEffect(() => {
        dispatch(setCurrentWorkflowStep(WorkflowStep.ErfassungHeimtierfutter, history));
    }, [dispatch, history]);

    useEffect(() => {
        if (rechnungsempfaengerPartnerNummer) {
            dispatch(getWerke(rechnungsempfaengerPartnerNummer));
        }
    }, [dispatch, rechnungsempfaengerPartnerNummer]);

    const onSubmit = (values: { groupedBestellpositionen: GroupedBestellpostionen }): void => {
        const bestellpositionenSackwareView = getFlatBestellpositionen(values) as BestellpositionArtikelSackwareView[];

        const neueBestellpositionen: BaseBestellposition[] = bestellpositionenSackwareView
            .filter((bv) => bv.mengeVe > 0 || bv.mengePal > 0)
            .map((bv) => ({
                artikelTyp: ArtikelTyp.Sackware,
                artikelNummer: bv.nummer,
                mengeVe: bv.mengeVe,
                mengePal: bv.mengePal,
                werkId: selectedWerkId || '',
            }));
        const hasMissingAmount = neueBestellpositionen.length === 0;
        setAmountMissing(hasMissingAmount);

        const neueSollbestaende: SollbestandAndBestand[] = bestellpositionenSackwareView
            .map(
                (bpView): SollbestandAndBestand => ({
                    artikelNummer: bpView.nummer,
                    aufPalRunden: bpView.aufPalRunden || false,
                    lagerKapazitaetVE: bpView.lagerKapazitaetVE,
                    bestandVE: bpView.bestandVE,
                })
            )
            .filter((bestand) => typeof bestand.lagerKapazitaetVE !== 'undefined');

        if (!hasMissingAmount && selectedWerkId && rechnungsempfaengerPartnerNummer && warenempfaengerPartnerNummer) {
            dispatch(setBestellpositionen(neueBestellpositionen));
            dispatch(postSollbestand(neueSollbestaende, rechnungsempfaengerPartnerNummer, warenempfaengerPartnerNummer, selectedWerkId));
            history.push(nextRoute);
        }
    };

    const initialValues = {
        groupedBestellpositionen,
    };
    return (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} validateOnChange={false}>
            <Form>
                <h2>
                    {selectedWerk === emptyWerk ? '' : ' Werk '}
                    {selectedWerk.name}
                </h2>
                {isTabletPortrait ? (
                    <>
                        <h3>Bestellformular</h3>
                        <div>
                            <BestellungOhneBestellvorschlag />
                            <BestellungMitBestellvorschlag />
                        </div>
                    </>
                ) : null}
                <br />
                <NotificationBar
                    testId="validation-message-bar"
                    dataCy={'validation-message-bar'}
                    message={ARTIKEL_VALIDATION_ERROR}
                    isVisible={amountMissing}
                />
                <NotificationBar testId="loadError-message-bar" message="" isVisible={!selectedWerkId}>
                    <Link className={styles._notificationLink} to={routes.abholwerk}>
                        Bitte wählen Sie erst ein Werk aus.
                    </Link>
                </NotificationBar>
                <NotificationBar
                    testId="server-message-bar"
                    message="Beim Laden der Artikel ist ein Fehler aufgetreten"
                    actionText="Neu laden"
                    actionCallback={reloadArtikelSackwaren}
                    isVisible={loadFailed}
                />
                {selectedWerkId && (
                    <LoadingSpinner isLoading={isLoading}>
                        <>
                            <GroupedBestellpositionen />
                            <div className={styles._bestellinformationen}>
                                <BestellinformationenHeimtierfutter />
                            </div>
                            <ArtikelHeimtierFutterWorkflowButtons />
                        </>
                    </LoadingSpinner>
                )}
            </Form>
        </Formik>
    );
};

export default ArtikelHeimtierfutter;
