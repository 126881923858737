import styles from './OverviewPagination.module.scss';
import Button from '../Button/Button';
import { TableInstance } from 'react-table';

type OverviewPaginationProps<T extends Record<string, unknown>> = {
    tableInstance: TableInstance<T>;
    totalCount: number;
    setPageSizeState: (size: number) => void;
    tablePageSizes?: number[];
};
const DEFAULT_PAGE_SIZES = [5, 10, 20, 30, 50, 100];

export function OverviewPagination<T extends Record<string, unknown>>(props: OverviewPaginationProps<T>): JSX.Element {
    const { tableInstance, setPageSizeState, totalCount, tablePageSizes = DEFAULT_PAGE_SIZES } = props;
    const { canPreviousPage, canNextPage, gotoPage, nextPage, previousPage, setPageSize, state } = tableInstance;
    const pageCount = Math.ceil(totalCount / state.pageSize);

    return (
        <div className={styles._paginationContainer}>
            <div className={styles._pageSizeContainer}>
                <span>zu Seite:</span>
                <div className={styles._pageJump}>
                    <input
                        type="number"
                        defaultValue={state.pageIndex + 1}
                        onBlur={(e): void => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        onKeyDown={(e): void => {
                            if (e.key === 'Enter') {
                                const target = e.target as HTMLInputElement;
                                const page = target.value ? Number(target.value) - 1 : 0;
                                gotoPage(page);
                            }
                        }}
                        className={styles._pageJump__input}
                    />
                </div>{' '}
                <span>Zeilen anzeigen:</span>
                <select
                    value={state.pageSize}
                    onChange={(e): void => {
                        const size = Number(e.target.value);
                        setPageSizeState(size);
                        setPageSize(size);
                        gotoPage(0);
                    }}
                    className={styles._rowAmount}
                >
                    {tablePageSizes.map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <Button onClick={(): void => gotoPage(0)} disabled={!canPreviousPage} isSmall={true}>
                    {'<<'}
                </Button>
                <Button onClick={(): void => previousPage()} disabled={!canPreviousPage} isSmall={true}>
                    {'<'}
                </Button>
                <span className={styles._pageCounter}>
                    Seite&nbsp;
                    <strong data-testid="page-counter">{state.pageIndex + 1}</strong>
                </span>
                <Button onClick={(): void => nextPage()} disabled={!canNextPage} isSmall={true}>
                    {'>'}
                </Button>
                <Button onClick={(): void => gotoPage(pageCount - 1)} disabled={!canNextPage} isSmall={true}>
                    {'>>'}
                </Button>
            </div>
        </div>
    );
}
