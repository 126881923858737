import { BestellpositionArtikelSackwareView } from '../../../../types';
import styles from '../SackwareList.module.scss';
import { FieldArrayRenderProps } from 'formik';
import { BezeichnungEntry, DeleteEntry, MengeSWEntry, WerkeEntry } from '../../PropertyEntries';
import { ReactElement } from 'react';

type ArtikelSackwareListFormEntryProps = {
    bestellposition: BestellpositionArtikelSackwareView;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
    allowDelete?: boolean;
};

export const SackwareListEntryNewBestellung = ({
    bestellposition,
    onArtikelDeleted,
    allowDelete,
    arrayHelpers,
}: ArtikelSackwareListFormEntryProps): ReactElement => {
    const { mengeVe, mengePal } = bestellposition;

    return (
        <div className={styles._artikelContainer}>
            <div className={styles._headerContainer}>
                <BezeichnungEntry.List bestellposition={bestellposition} />
                {allowDelete ? (
                    <DeleteEntry.Delete.List
                        bestellposition={bestellposition}
                        arrayHelpers={arrayHelpers}
                        showConfirmDelete={mengeVe > 0 || mengePal > 0}
                        onArtikelDeleted={onArtikelDeleted}
                    />
                ) : null}
            </div>
            <div className={styles._amountsContainer}>
                <MengeSWEntry.List bestellposition={bestellposition} />
            </div>
            <WerkeEntry.List bestellposition={bestellposition} />
        </div>
    );
};
