import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'normalize.css';
import './styles/globalstyles.scss';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import jwtDecode from 'jwt-decode';
import * as serviceWorker from './serviceWorkerRegistration';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
// eslint-disable-next-line
import './buildinfo.ts';
import { isFeatureOn } from './shared/ui-components/FeatureToggle/FeatureToggle';
import Config from './shared/config';
import { hashUserId } from './shared/helper/logging';
import { Rollen } from './shared/types/enums';
import { CurrentUser } from './shared/types';
import FlutterBridge from './flutter/flutterBridge';

const { sentryConfig, keycloakConfig, mockKeycloak, isApp } = Config.getConfig();

const history = createBrowserHistory();

if (sentryConfig.dsn) {
    Sentry.init({
        dsn: sentryConfig.dsn,
        environment: sentryConfig.sentryEnv,
        integrations: [
            new TracingIntegrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                tracingOrigins: ['localhost', sentryConfig.origin, /^\//],
            }),
        ],
        tracesSampleRate: sentryConfig.sampleRate,
    });
}

const keycloak = new Keycloak({
    clientId: keycloakConfig.clientId,
    realm: keycloakConfig.realm,
    url: keycloakConfig.url,
});

const initializeUser = (keycloak: Keycloak): CurrentUser => {
    if (keycloak.token != null) {
        sessionStorage.setItem('authentication', keycloak.token);
        FlutterBridge.sendToken(keycloak.token);
        const decodedToken = jwtDecode<KeycloakTokenParsed>(keycloak.token);
        if (decodedToken.sub) {
            Sentry.setUser({ id: hashUserId(decodedToken.sub) });
        }
        return {
            name: decodedToken?.name ?? '',
            roles: decodedToken?.realm_access?.roles ?? [],
        };
    } else {
        return {
            name: '',
            roles: [],
        };
    }
};

//Initialization of the keycloak instance
if (mockKeycloak) {
    const keycloakStub: Partial<Keycloak> = {
        createLogoutUrl: (): string => 'https://www.deuka.de',
    };
    const currentUser: CurrentUser = {
        name: 'Max Mustermann',
        roles: [Rollen.DEBITOR, Rollen.ADMIN],
    };
    ReactDOM.render(
        <StrictMode>
            <App keycloak={keycloakStub} currentUser={currentUser} history={history} />
        </StrictMode>,
        document.getElementById('root')
    );
} else {
    (async (): Promise<void> => {
        const responseMode = isApp() ? 'query' : 'fragment';
        const authenticated = await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false, responseMode });

        if (!authenticated) {
            FlutterBridge.sendToken(null);
            window.location.reload();
        }

        if (keycloak.refreshToken != null) {
            sessionStorage.setItem('refreshToken', keycloak.refreshToken);
        }

        setInterval(async () => {
            const refreshed = await keycloak.updateToken(70);
            if (refreshed && keycloak.token) {
                sessionStorage.setItem('authentication', keycloak.token);
                FlutterBridge.sendToken(keycloak.token);
            }
        }, 60000);

        ReactDOM.render(
            <StrictMode>
                <App keycloak={keycloak} currentUser={initializeUser(keycloak)} history={history} />
            </StrictMode>,
            document.getElementById('root')
        );
    })();
}

if (isFeatureOn('PROGRESSIVE_WEB_APP')) {
    serviceWorker.register();
}
