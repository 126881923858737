import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData } from '../shared/fetchApi';
import {
    GET_WERKE_FAILED,
    GET_WERKE_FOR_ARTIKEL_FAILED,
    GET_WERKE_FOR_ARTIKEL_SUCCEEDED,
    GET_WERKE_SUCCEEDED,
    GetWerkeAction,
    GetWerkeForArtikelAction,
} from '../store/Abholwerk.store';
import { NEWID_PREFIX } from '../shared/constants';

export function* getWerkeSaga({
    rechnungsempfaengerPartnerNummer,
    warenempfaengerPartnerNummer,
}: GetWerkeAction): Generator<PutEffect | CallEffect> {
    try {
        const url = warenempfaengerPartnerNummer
            ? `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/warenempfaenger/${warenempfaengerPartnerNummer}/werke`
            : `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/werke`;
        const werke = yield call(getData, url);
        yield put({ type: GET_WERKE_SUCCEEDED, werke });
    } catch (e) {
        yield put({ type: GET_WERKE_FAILED });
    }
}

export function* getWerkeForArtikelSaga({
    artikelNummer,
    rechnungsempfaengerPartnerNummer,
}: GetWerkeForArtikelAction): Generator<PutEffect | CallEffect> {
    const url = artikelNummer.startsWith(NEWID_PREFIX)
        ? `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/werke?excludeArtikelCheck=true`
        : `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/artikel/${artikelNummer}/werke`;
    try {
        const werke = yield call(getData, url);
        yield put({ type: GET_WERKE_FOR_ARTIKEL_SUCCEEDED, artikelNummer, werke });
    } catch (e) {
        yield put({ type: GET_WERKE_FOR_ARTIKEL_FAILED });
    }
}
