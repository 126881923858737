import { Kontrakt } from '../shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../configureStore';

export type KontrakteForArtikel = {
    [artikelNummer: string]: Kontrakt[];
};

export type KontrakteState = {
    kontrakteForArtikel: KontrakteForArtikel;
    kontrakteForArtikelLoadFailed: boolean;
};

export const INITIAL_KONTRAKTE_STATE: KontrakteState = {
    kontrakteForArtikel: {},
    kontrakteForArtikelLoadFailed: false,
};

type KontrakteAndArtikel = {
    artikelNummer: string;
    kontrakte: Kontrakt[];
};

const selectKontraktForArtikel = (state: RootState): KontrakteForArtikel => state.kontrakte.kontrakteForArtikel;

export const KontraktSelectors = {
    forArtikel: selectKontraktForArtikel,
};

export type GetKontraktePayload = {
    artikelNummer: string;
    rechnungsempfaengerPartnerNummer: string;
    warenempfaengerPartnerNummer: string;
    werkId: string;
    wunschtermin: string;
};

const kontrakteSlice = createSlice({
    name: 'kontrakte',
    initialState: INITIAL_KONTRAKTE_STATE,
    reducers: {
        getKontrakteForArtikel(state, _: PayloadAction<GetKontraktePayload>) {
            state.kontrakteForArtikelLoadFailed = false;
        },
        getKontrakteForArtikelSucceeded(state, action: PayloadAction<KontrakteAndArtikel>) {
            state.kontrakteForArtikelLoadFailed = false;
            state.kontrakteForArtikel[action.payload.artikelNummer] = action.payload.kontrakte;
        },
        getKontrakteForArtikelFailed(state) {
            state.kontrakteForArtikelLoadFailed = true;
        },
    },
});

export const { getKontrakteForArtikelFailed, getKontrakteForArtikelSucceeded, getKontrakteForArtikel } = kontrakteSlice.actions;

export const kontrakteReducer = kontrakteSlice.reducer;
