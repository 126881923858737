import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreislistenkennzeichen, setPreislistenkennzeichen } from '../../store/Preislistenkennzeichen.store';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import type { Preislistenkennzeichen } from '../../shared/types';
import TextInput from '../../shared/ui-components/TextInput/TextInput';
import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import IconButton from '../../shared/ui-components/IconButton/IconButton';
import iconTrashcan from '../../assets/icon-trashcan.svg';
import iconAdd from '../../assets/icon-add.svg';
import SelectInput from '../../shared/ui-components/SelectInput/SelectInput';
import Button from '../../shared/ui-components/Button/Button';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import { useHistory } from 'react-router-dom';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import Select, { components, OptionTypeBase } from 'react-select';
import styles from '../../shared/ui-components/SelectInput/SelectInput.module.scss';
import * as Yup from 'yup';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import { Option } from '../../shared/helper/select-options';
import type { RootState } from '../../configureStore';
import { VALIDATION_ERROR } from '../../shared/constants';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Sichtbarkeit } from '../../shared/types/enums';
import { createCustomStyles } from '../../shared/ui-components/SelectInput/CustomStyles';

type PreislistenkennzeichenFormFields = {
    preislistenkennzeichen: Preislistenkennzeichen[];
};

const kennzeichenOptions: Option[] = [
    { value: Sichtbarkeit.ALLE, label: 'Alle' },
    { value: Sichtbarkeit.KEINE, label: 'Keine' },
    { value: Sichtbarkeit.ZUWEISUNG, label: 'Zuweisung' },
];

const PreislistenkennzeichenTable = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [addKennzeichen, setAddKennzeichen] = useState('');
    const [addBeschreibung, setAddBeschreibung] = useState('');
    const [addSichtbarkeit, setAddSichtbarkeit] = useState<OptionTypeBase>({});
    const [hasNewError, setHasNewError] = useState(false);
    const preislistenkennzeichen = useSelector((state: RootState) => state.preislistenkennzeichen.preislistenkennzeichen);
    const hasSubmitError = useSelector((state: RootState) => state.preislistenkennzeichen.hasSubmitError);
    const hasLoadError = useSelector((state: RootState) => state.preislistenkennzeichen.hasLoadError);
    const isLoading = useSelector((state: RootState) => state.preislistenkennzeichen.isLoading);
    const isSubmitting = useSelector((state: RootState) => state.preislistenkennzeichen.isSubmitting);

    useEffect(() => {
        dispatch(getPreislistenkennzeichen());
    }, [dispatch]);

    useDocumentTitle('Preislistenkennzeichen');

    return (
        <>
            <h2>Preislistenkennzeichen</h2>
            <Formik
                initialValues={{ preislistenkennzeichen }}
                enableReinitialize={true}
                onSubmit={(values): void => {
                    showConfirmationDialog({
                        message: (
                            <>
                                Die Änderung der Preislistenkennzeichen kann Auswirkungen auf die Sichtbarkeit von Artikeln im Portal haben.
                                <br /> Sind Sie sicher das Sie die Änderungen speichern wollen?
                            </>
                        ),
                        okButtonText: 'Änderungen speichern',
                        onOkCallback: () => {
                            dispatch(setPreislistenkennzeichen(values.preislistenkennzeichen));
                        },
                    });
                }}
                validateOnChange={false}
                validationSchema={Yup.object({
                    preislistenkennzeichen: Yup.array().of(
                        Yup.object().shape({
                            kennzeichen: Yup.string().required('Bitte geben Sie ein Kennzeichen an.'),
                            beschreibung: Yup.string().required('Bitte geben Sie eine Beschreibung an.'),
                            sichtbarkeit: Yup.string().required('Bitte wählen Sie die Sichtbarkeit aus.'),
                        })
                    ),
                })}
            >
                {(formikProps: FormikProps<PreislistenkennzeichenFormFields>): JSX.Element => {
                    const errorKeys = Object.keys(formikProps.errors);
                    const touchedKeys = Object.keys(formikProps.touched);
                    const hasError = errorKeys.some((errorKey) => touchedKeys.includes(errorKey));

                    return (
                        <Form noValidate>
                            <NotificationBar testId="validation-message-bar" message={VALIDATION_ERROR} isVisible={hasError} />
                            <NotificationBar
                                testId="validation-new-message-bar"
                                message="Bitte geben Sie Kennzeichen, Beschreibung und Sichtbarkeit für ein neues Preislistenkennzeichen an."
                                isVisible={hasNewError}
                            />
                            <NotificationBar
                                testId="load-error-message-bar"
                                message="Beim Laden der Preislistenkennzeichen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
                                isVisible={hasLoadError}
                                actionText="Neu Laden"
                                actionCallback={(): void => {
                                    dispatch(getPreislistenkennzeichen());
                                }}
                            ></NotificationBar>
                            <NotificationBar
                                testId="save-error-message-bar"
                                message="Beim Speichern der Preislistenkennzeichen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
                                isVisible={hasSubmitError}
                                actionText="Neu Speichern"
                                actionCallback={(): void => {
                                    dispatch(setPreislistenkennzeichen(formikProps.values.preislistenkennzeichen));
                                }}
                            ></NotificationBar>
                            <table className={tableStyles._table}>
                                <thead>
                                    <tr className={tableStyles._tableHeaderRow}>
                                        <th className={tableStyles['_tableCell--text']}>Kennzeichen</th>
                                        <th className={tableStyles['_tableCell--text']}>Beschreibung</th>
                                        <th className={tableStyles['_tableCell--text']}>Sichtbarkeit</th>
                                        <th className={[tableStyles['_tableCell--text'], tableStyles._actionColumn].join(' ')}>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <FieldArray
                                        name="preislistenkennzeichen"
                                        render={(arrayHelpers): JSX.Element => (
                                            <>
                                                {formikProps.values.preislistenkennzeichen.map((kennzeichen, index) => (
                                                    <tr key={index}>
                                                        <td
                                                            className={[tableStyles._tableCell, tableStyles['_tableCell--input']].join(' ')}
                                                        >
                                                            <TextInput
                                                                name={`preislistenkennzeichen[${index}].kennzeichen`}
                                                                id={`preislistenkennzeichen[${index}].kennzeichen`}
                                                                inTableCell={true}
                                                            />
                                                        </td>
                                                        <td
                                                            className={[tableStyles._tableCell, tableStyles['_tableCell--wideInput']].join(
                                                                ' '
                                                            )}
                                                        >
                                                            <TextInput
                                                                name={`preislistenkennzeichen[${index}].beschreibung`}
                                                                id={`preislistenkennzeichen[${index}].beschreibung`}
                                                                inTableCell={true}
                                                            />
                                                        </td>
                                                        <td
                                                            className={[tableStyles._tableCell, tableStyles['_tableCell--select']].join(
                                                                ' '
                                                            )}
                                                        >
                                                            <SelectInput<Option>
                                                                id={`preislistenkennzeichen[${index}].sichtbarkeit`}
                                                                name={`preislistenkennzeichen[${index}].sichtbarkeit`}
                                                                options={kennzeichenOptions}
                                                                inTableCell={true}
                                                                placeholder="Auswahl Sichtbarkeit"
                                                                isClearable={false}
                                                                autoSelectIfOnlyOneOption={true}
                                                            />
                                                        </td>
                                                        <td
                                                            className={[
                                                                tableStyles._tableCell,
                                                                tableStyles['_tableCell--text'],
                                                                tableStyles._actionColumn,
                                                            ].join(' ')}
                                                        >
                                                            <IconButton
                                                                icon={iconTrashcan}
                                                                alt="Löschen"
                                                                onClick={(event): void => {
                                                                    event.preventDefault();
                                                                    arrayHelpers.remove(index);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr className={tableStyles._tableHeaderRow}>
                                                    <td className={tableStyles._tableCell} colSpan={4}>
                                                        <strong>Neues Preislistenkennzeichen hinzufügen:</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={[tableStyles._tableCell, tableStyles['_tableCell--input']].join(' ')}>
                                                        <TextInputWithoutFormik
                                                            name="addKennzeichen"
                                                            id="addKennzeichen"
                                                            inTableCell={true}
                                                            type="text"
                                                            placeholder="Neues Kennzeichen"
                                                            value={addKennzeichen}
                                                            errorMessage={'Error Message'}
                                                            hasError={false}
                                                            maxLength={255}
                                                            onFieldChange={(value): void => {
                                                                setAddKennzeichen(value);
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={[tableStyles._tableCell, tableStyles['_tableCell--input']].join(' ')}>
                                                        <TextInputWithoutFormik
                                                            name="addBescheibung"
                                                            id="addBescheibung"
                                                            inTableCell={true}
                                                            type="text"
                                                            placeholder="Neue Beschreibung"
                                                            value={addBeschreibung}
                                                            errorMessage={'Error Message'}
                                                            hasError={false}
                                                            maxLength={255}
                                                            onFieldChange={(value): void => {
                                                                setAddBeschreibung(value);
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={[tableStyles._tableCell, tableStyles['_tableCell--input']].join(' ')}>
                                                        <Select
                                                            options={kennzeichenOptions}
                                                            name={'addSichtbarkeit'}
                                                            value={addSichtbarkeit}
                                                            onChange={(selectedOption): void => {
                                                                setAddSichtbarkeit(selectedOption || {});
                                                            }}
                                                            styles={createCustomStyles(true, true, 'left')}
                                                            className={[styles._select, styles['_select--inTableCell']].join(' ')}
                                                            placeholder="Auswahl Sichtbarkeit"
                                                            menuShouldScrollIntoView={true}
                                                            components={components}
                                                            menuPlacement={'auto'}
                                                            noOptionsMessage={(): string => 'Keine Einträge'}
                                                        />
                                                    </td>
                                                    <td
                                                        className={[
                                                            tableStyles._tableCell,
                                                            tableStyles['_tableCell--text'],
                                                            tableStyles._actionColumn,
                                                        ].join(' ')}
                                                    >
                                                        <IconButton
                                                            icon={iconAdd}
                                                            alt="Hinzufügen"
                                                            onClick={(event): void => {
                                                                if (
                                                                    addKennzeichen.length > 1 &&
                                                                    addBeschreibung.length > 1 &&
                                                                    !(typeof addSichtbarkeit === 'string')
                                                                ) {
                                                                    const newKennzeichen: Preislistenkennzeichen = {
                                                                        kennzeichen: addKennzeichen,
                                                                        beschreibung: addBeschreibung,
                                                                        sichtbarkeit: addSichtbarkeit.value as Sichtbarkeit,
                                                                    };
                                                                    arrayHelpers.push(newKennzeichen);
                                                                    setAddSichtbarkeit({});
                                                                    setAddBeschreibung('');
                                                                    setAddKennzeichen('');
                                                                    setHasNewError(false);
                                                                } else {
                                                                    setHasNewError(true);
                                                                }
                                                                event.preventDefault();
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    />
                                </tbody>
                            </table>
                            <ButtonGroup>
                                <Button type="button" onClick={(): void => history.goBack()} isSecondary={true}>
                                    {'Abbrechen'}
                                </Button>
                                <Button
                                    type="button"
                                    data-testid="submitButton"
                                    onClick={formikProps.submitForm}
                                    disabled={isLoading || isSubmitting}
                                >
                                    {isSubmitting ? 'Wird gespeichert...' : 'Speichern'}
                                </Button>
                            </ButtonGroup>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default PreislistenkennzeichenTable;
