import { Dispatch } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useMediaQuery } from 'react-responsive';
import styles from './Auftragsansicht.module.scss';
import Button from '../../shared/ui-components/Button/Button';
import { routes } from '../../store/Workflow.store';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import { BESTELLEN_BUTTON_TEXT } from '../../shared/constants';
import type { AuftragsFormularFelder } from './Auftragsansicht';
import { isExistingBestellposition, isStornierbarePosition } from '../../shared/helper/bestellungen-helper';
import { Lieferbedingungen } from '../../shared/types/enums';

type AuftragsansichtButtonsProps = {
    setStorniereAuftrag: Dispatch<boolean>;
    setTriedToSubmit: Dispatch<boolean>;
    setSaveAsDraft: Dispatch<boolean>;
    overWeightConfirmed: boolean;
    hasOverWeight: boolean;
};

function submitAfterNextRender(submitForm: () => void) {
    setTimeout(() => {
        // Hack: A different validation schema is used depending on which button is pressed.
        // This happens asynchronously, so we need to wait for the next render to submit the form.
        submitForm();
    }, 0);
}

export const AuftragsansichtButtons = ({
    setStorniereAuftrag,
    setTriedToSubmit,
    setSaveAsDraft,
    overWeightConfirmed,
    hasOverWeight,
}: AuftragsansichtButtonsProps): JSX.Element => {
    const history = useHistory();
    const { submitForm, values, setFieldValue } = useFormikContext<AuftragsFormularFelder>();
    //eslint-disable-next-line
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });

    const navigateToAuftragsuebersicht = () => {
        history.push(routes.auftragsuebersicht);
    };

    const onAuftragStornieren = (): void => {
        showConfirmationDialog({
            okButtonText: 'Auftrag unwiderruflich stornieren',
            message: 'Der Auftrag wird mit allen Positionen storniert. Dieser Vorgang kann nicht rückgängig gemacht werden.',
            onOkCallback: (): void => {
                values.bestellpositionenView
                    .filter((position) => isExistingBestellposition(position) && isStornierbarePosition(position))
                    .forEach((bestellposition) => {
                        setFieldValue(`bestellpositionenView[${bestellposition.index}].storno`, true);
                    });
                setStorniereAuftrag(true);
                setTriedToSubmit(true);
                submitAfterNextRender(submitForm);
            },
        });
    };

    const onAuftragSpeichern = (): void => {
        setSaveAsDraft(true);
        setTriedToSubmit(true);
        submitAfterNextRender(submitForm);
    };

    const onAuftragVerbindlichBestellen = (): void => {
        if (values.lieferbedingung == Lieferbedingungen.FRANCO) {
            values.spedition = '';
        }
        setSaveAsDraft(false);
        setTriedToSubmit(true);
        submitAfterNextRender(submitForm);
    };

    return (
        <div className={styles._multiButtonRowButtonGroup}>
            <div>
                <Button type="button" isSecondary isSmall={isMobile} onClick={navigateToAuftragsuebersicht}>
                    Zurück
                </Button>
                <Button type="button" isSecondary isSmall={isMobile} onClick={onAuftragStornieren}>
                    Auftrag stornieren
                </Button>
                <Button
                    type="button"
                    isSecondary
                    isSmall={isMobile}
                    onClick={onAuftragSpeichern}
                    disabled={!overWeightConfirmed && hasOverWeight}
                >
                    Auftrag speichern
                </Button>
            </div>
            <Button type="button" onClick={onAuftragVerbindlichBestellen} disabled={!overWeightConfirmed && hasOverWeight}>
                {BESTELLEN_BUTTON_TEXT}
            </Button>
        </div>
    );
};
