import { call, CallEffect, PutEffect, put } from 'redux-saga/effects';
import { getData, postData } from '../shared/fetchApi';
import { GET_SOLLBESTAND_FAILED, GET_SOLLBESTAND_SUCCEEDED, GetSollbestandAction, PostSollbestandAction } from '../store/Sollbestand.store';
import type { Sollbestand, SollbestandAndBestand } from '../shared/types';

export function* postSollbestandSaga({
    warenempfaengerPartnerNummer,
    sollbestand,
    werkId,
    rechnungsempfaengerPartnerNummer,
}: PostSollbestandAction): IterableIterator<PutEffect | CallEffect> {
    const sollbestandOhneBestand: Sollbestand[] = sollbestand.map(
        (sb: SollbestandAndBestand): Sollbestand => ({
            artikelNummer: sb.artikelNummer,
            lagerKapazitaetVE: sb.lagerKapazitaetVE,
            aufPalRunden: sb.aufPalRunden,
        })
    );

    try {
        yield call(
            postData,
            `sollbestand/rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/warenempfaenger/${warenempfaengerPartnerNummer}/werk/${werkId}`,
            sollbestandOhneBestand,
            'POST'
        );
    } catch {
        console.error('Lagerkapazitäten konnten nicht gespeichert werden');
    }
}

export function* getSollbestandSaga(action: GetSollbestandAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const sollbestand = yield call(getData, `sollbestand/warenempfaenger/${action.warenempfaengerPartnerNummer}/werk/${action.werkId}`);
        yield put({ type: GET_SOLLBESTAND_SUCCEEDED, sollbestand });
    } catch (e) {
        yield put({ type: GET_SOLLBESTAND_FAILED });
    }
}
