import Validator, { ValidationError, ValidationSchema } from 'fastest-validator';
import Formulas from '../formulas';
import { BESTELLUNG_MAX_WEIGHT_IN_KG } from '../constants';
import type { TourenplanForm } from '../../views/Tourenplan/TourenplanTypes';

const maximalGewichtValidator = new Validator({
    useNewCustomCheckerFunction: true,
});

function validateMaximalGewicht() {
    return (values: Partial<TourenplanForm>, errors: ValidationError[]): Partial<TourenplanForm> => {
        const { loseWareBestellungen, sackwareBestellungen } = values;
        const loseWareWeight =
            loseWareBestellungen?.reduce((acc, bestellung) => {
                return acc + Formulas.mengeToWeightInKg(bestellung.menge);
            }, 0) ?? 0;

        const sackwareWeight =
            sackwareBestellungen?.reduce((acc, bestellung) => {
                const { mengePal, mengeVe, faktorBasiseinheitPal, faktorBasiseinheitVe } = bestellung;
                return acc + Formulas.mengePalAndVeToWeightInKg(mengePal, mengeVe, faktorBasiseinheitPal, faktorBasiseinheitVe);
            }, 0) ?? 0;

        if (loseWareWeight + sackwareWeight > BESTELLUNG_MAX_WEIGHT_IN_KG) {
            errors.push({ field: 'maximalGewicht', type: 'maximalGewicht' });
        }
        return values;
    };
}

// This works also if there are only SackwareBestellungen and no loseWareBestellungen because the validation is triggered everytime.
const MaximalGewichtSchema = {
    $$root: true,
    type: 'object',
    custom: validateMaximalGewicht(),
    optional: true,
} as ValidationSchema;

export default {
    validate: (data: Pick<TourenplanForm, 'loseWareBestellungen' | 'sackwareBestellungen'>): boolean => {
        const errorsOrTrue = maximalGewichtValidator.validate(data, MaximalGewichtSchema);
        return errorsOrTrue !== true;
    },
};
