import { DateInfo } from '../../helper/date-helper';
import { Bestellprozesstyp } from '../../types/enums';

export enum WunschterminMode {
    UHRZEIT,
    TAGESZEIT,
}

export type WunschterminProps = {
    mode?: WunschterminMode;
    minDate?: string;
    isDateRequired?: boolean;
    isTimeRequired?: boolean;
    label?: string;
    dateFieldName?: string;
    timeFieldName?: string;
};

export const createWunschterminString = (
    formattedDateWithTime: DateInfo,
    isDebitor: boolean,
    bestellprozesstyp: Bestellprozesstyp
): string => {
    if (!formattedDateWithTime.uhrzeitIsSet) {
        return '';
    }

    if (!isDebitor || bestellprozesstyp !== Bestellprozesstyp.Nutztierfutter) {
        return formattedDateWithTime.tageszeit;
    }

    return formattedDateWithTime.uhrzeit;
};
