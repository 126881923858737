import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BestelldatenFormular, BestellpositionView, BestellungDetails, BestellungParam, BestellungUpdateRequest } from '../../shared/types';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { extractDateAndTimeFromDateString, getISOWunschtermin } from '../../shared/helper/date-helper';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { ArtikelNutztierfutterFormular } from '../../shared/helper/artikel-helper';
import * as BestellungenHelper from '../../shared/helper/bestellungen-helper';
import { MAX_WEIGHT_ERROR } from '../../shared/validation/bestellung/validation';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { BestellpositionenNotifications } from '../../shared/content-components/BestellpositionenNotifications/BestellpositionenNotifications';
import { Bestellpositionen } from '../../shared/content-components/Bestellpositionen/Bestellpositionen';
import { routes } from '../../store/Workflow.store';
import {
    resetUpdateBestellung,
    selectBestellungIsUpdating,
    selectBestellungUpdateFailed,
    selectBestellungUpdateSuccess,
    updateBestellung,
} from '../../store/Bestellung.store';
import FormControl, { FormControlWithoutErrorDisplay } from '../../shared/ui-components/FormControl/FormControl';
import TextInput from '../../shared/ui-components/TextInput/TextInput';
import SelectInput from '../../shared/ui-components/SelectInput/SelectInput';
import { lieferbedingungOptions, Option } from '../../shared/helper/select-options';
import Textarea from '../../shared/ui-components/Textarea/Textarea';
import KfzKennzeichenAuswahl, {
    mapKfzKennzeichenIdToKfzKennzeichen,
} from '../../shared/content-components/KfzKennzeichenAuswahl/KfzKennzeichenAuswahl';
import { addKfzKennzeichen, kfzKennzeichenSelectors, KfzKennzeichenView, TEMPORARY_KFZ_ID } from '../../store/KfzKennzeichen.store';
import SpeditionAuswahl, { mapSpeditionIdToSpedition } from 'shared/content-components/SpeditionAuswahl/SpeditionAuswahl';
import { addSpedition, speditionSelectors, SpeditionView, TEMPORARY_SPEDITION_ID } from 'store/Spedition.store';
import { createWunschterminLabelByLieferbedingung } from '../../shared/content-components/Wunschtermin/WunschterminTageszeit';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import { OVERWEIGHT_ERROR, UPDATE_BESTELLUNG_ERROR, VALIDATION_ERROR } from '../../shared/constants';
import MandatoryFormFieldHint from '../../shared/ui-components/Form/MandatoryFormFieldHint';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Severity, Lieferbedingungen } from '../../shared/types/enums';
import styles from './Auftragsansicht.module.scss';
import { WunschterminZeitraum } from '../../shared/content-components/Wunschtermin/WunschterminZeitraum';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { WunschterminUhrzeit } from '../../shared/content-components/Wunschtermin/WunschterminUhrzeit';
import { ArtikelauswahlDropdown } from '../../shared/content-components/Artikelauswahl/ArtikelauswahlDropdown';
import { useFetchArtikelLoseWare } from '../../shared/hooks/useFetchArtikelLoseWare';
import { useFetchWerkeForArtikel } from '../../shared/hooks/useFetchWerke';
import { useAuftragskorbValidationSchema } from './useAuftragskorbValidationSchema';
import { useFetchBestellung } from '../../shared/hooks/useFetchBestellung';
import { AuftragsansichtButtons } from './AuftragsansichtButtons';
import { useFetchKfzKennzeichen } from '../../shared/hooks/useFetchKfzKennzeichen';
import { useFetchSpedition } from 'shared/hooks/useFetchSpedition';
import { removeFromListOfSelectedArtikel } from '../../store/Artikelauswahl.store';
import { useFetchArtikelSackwaren } from '../../shared/hooks/useFetchArtikelSackwaren';
import { RechnungsempfaengerFormSection } from '../../shared/content-components/Rechnungsempfaenger/RechnungsempfaengerFormSection';
import FormikCheckbox from '../../shared/ui-components/CheckBox/FormikCheckbox';

export type AuftragsFormularFelder = ArtikelNutztierfutterFormular & BestelldatenFormular;

function getInitialFormikValues(bestellung: BestellungDetails, bestellpositionenView: BestellpositionView[]): AuftragsFormularFelder {
    const wunschtermin = extractDateAndTimeFromDateString(bestellung.wunschtermin);
    const wunschterminWEVon = extractDateAndTimeFromDateString(bestellung.wunschterminWEVon);
    const wunschterminWEBis = extractDateAndTimeFromDateString(bestellung.wunschterminWEBis);

    return {
        bestellpositionenView: bestellpositionenView,
        addArticle: undefined,
        datumDebitor: wunschtermin.datum,
        uhrzeitDebitor: wunschtermin.uhrzeit,
        datumWEVon: wunschterminWEVon.datum,
        datumWEBis: wunschterminWEBis.datum,
        uhrzeitWEVon: wunschterminWEVon.uhrzeit,
        uhrzeitWEBis: wunschterminWEBis.uhrzeit,
        ansprechpartner: bestellung.ansprechpartner,
        bestellhinweis: bestellung.bestellhinweis,
        kfzKennzeichen: bestellung.kfzKennzeichen,
        spedition: bestellung.spedition,
        lieferbedingung: bestellung.lieferbedingung,
        lieferhinweis: bestellung.lieferhinweis,
        notificationCheck: false,
    };
}

const Auftragsansicht = (): ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { bestellnummer } = useParams<BestellungParam>();
    const pageTitle = 'Auftrag ' + bestellnummer;
    useDocumentTitle(pageTitle);

    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);
    const isLoading = useSelector(BestelluebersichtSelectors.currentBestellungLoading);
    const loadFailed = useSelector(BestelluebersichtSelectors.currentBestellungLoadFailed);
    const updateSuccessful = useSelector(selectBestellungUpdateSuccess);
    const isUpdating = useSelector(selectBestellungIsUpdating);
    const updateBestellungRequestFailed = useSelector(selectBestellungUpdateFailed);
    const kfzKennzeichenOptions: Option[] = useSelector(kfzKennzeichenSelectors.allAsOptions);
    const allKfzKennzeichen: KfzKennzeichenView[] = useSelector(kfzKennzeichenSelectors.allKfzKennzeichen);
    const initialKennzeichenId = useSelector(kfzKennzeichenSelectors.mapKennzeichenIdFromKennzeichen(bestellung.kfzKennzeichen));
    const speditionOptions: Option[] = useSelector(speditionSelectors.allAsOptions);
    const allSpeditionen: SpeditionView[] = useSelector(speditionSelectors.allSpeditionen);
    const initialSpeditionId = useSelector(speditionSelectors.mapSpeditionIdFromSpedition(bestellung.spedition));
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [hasInvalidEmailError, setHasInvalidEmailError] = useState(false);
    const [saveAsDraft, setSaveAsDraft] = useState(true);
    const [storniereAuftrag, setStorniereAuftrag] = useState(false);
    const [overWeightConfirmed, setOverWeightConfirmed] = useState(false);
    const bestellpositionenView = useSelector(BestelluebersichtSelectors.bestellpositionenViewBestellpositionDetails);
    const rechnungsempfaengerPartnerNummer = bestellung.rechnungsempfaenger.partnerNummer;
    const warenempfaengerPartnerNummer = bestellung.warenempfaenger.partnerNummer;
    const hasSpeditionServerError = useSelector(speditionSelectors.hasSubmitError);

    const reloadArtikelLoseWare = useFetchArtikelLoseWare(
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        bestellung.bestellprozesstyp
    );
    const reloadArtikelSackware = useFetchArtikelSackwaren(
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        bestellung.bestellprozesstyp
    );

    const bestellpositionenViewNummern = useSelector(BestelluebersichtSelectors.bestellpositionenViewNummern);
    const reloadWerke = useFetchWerkeForArtikel(bestellpositionenViewNummern, rechnungsempfaengerPartnerNummer);

    const validationSchema = useAuftragskorbValidationSchema(overWeightConfirmed, saveAsDraft, storniereAuftrag);
    const initialValues = {
        ...getInitialFormikValues(bestellung, bestellpositionenView),
        kfzKennzeichen: initialKennzeichenId,
        spedition: initialSpeditionId,
    };
    useEffect(() => {
        dispatch(resetUpdateBestellung());
    }, [dispatch]);

    const reloadBestellung = useFetchBestellung(bestellnummer);
    useFetchKfzKennzeichen();
    useFetchSpedition();

    useEffect(() => {
        if (updateSuccessful) {
            dispatch(resetUpdateBestellung());
            history.push(routes.auftragsuebersicht);
        }
    }, [dispatch, history, updateSuccessful]);

    const onArtikelDeleted = useCallback(
        (artikelNummer: string): void => {
            dispatch(removeFromListOfSelectedArtikel(artikelNummer));
        },
        [dispatch]
    );

    const onSubmit = (values: FormikValues): void => {
        const neueBestellpositionen = values.bestellpositionenView.map(BestellungenHelper.mapBestellpositionenViewToBestellpositionen);
        const wunschtermin = getISOWunschtermin(values.datumDebitor, values.uhrzeitDebitor);
        const wunschterminVon = getISOWunschtermin(values.datumWEVon, values.uhrzeitWEVon);
        const wunschterminBis = getISOWunschtermin(values.datumWEBis, values.uhrzeitWEBis);
        const bestellungUpdate: BestellungUpdateRequest = {
            ...bestellung,
            ansprechpartner: values.ansprechpartner || '',
            lieferbedingung: values.lieferbedingung || '',
            bestellhinweis: values.bestellhinweis || '',
            lieferhinweis: values.lieferhinweis || '',
            rechnungsempfaengerPartnerNummer: bestellung.rechnungsempfaenger.partnerNummer,
            bestellpositionen: neueBestellpositionen,
            isDraft: saveAsDraft,
            geschlossen: storniereAuftrag,
            wunschtermin,
            wunschterminWEVon: wunschterminVon,
            wunschterminWEBis: wunschterminBis,
            kfzKennzeichen: mapKfzKennzeichenIdToKfzKennzeichen(allKfzKennzeichen, values.kfzKennzeichen),
            spedition: mapSpeditionIdToSpedition(allSpeditionen, values.spedition),
        };
        dispatch(updateBestellung(bestellungUpdate));
        !saveAsDraft && history.push(routes.bestelluebermittlung + '/bestellung/update');
    };

    function isValidEmail(email: string): boolean {
        return /\S+@\S+\.\S+/.test(email);
    }

    if (!bestellung.isDraft) {
        return <NotificationBar message="Der Auftrag befindet sich nicht mehr im Auftragskorb!" isVisible={false} />;
    }

    const getEmailById = (speditionId: string): string => {
        const selectedSpedition = speditionOptions.find((spedition) => spedition.value === speditionId);
        return selectedSpedition ? (typeof selectedSpedition.label === 'string' ? selectedSpedition.label : '') : '';
    };

    return (
        <>
            <h2>{pageTitle}</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message="Der Auftrag konnte nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={reloadBestellung}
            />
            <NotificationBar
                testId="server-validation-message-bar"
                message="Beim Speichern der Spedition ist ein Fehler aufgetreten. Bitte geben Sie eine E-Mailadresse ein."
                isVisible={hasSpeditionServerError}
            />
            <NotificationBar message={UPDATE_BESTELLUNG_ERROR} isVisible={updateBestellungRequestFailed} />
            <LoadingSpinner isLoading={isLoading || isUpdating}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnChange={true}
                >
                    {(formikProps: FormikProps<AuftragsFormularFelder>): ReactElement => {
                        const errorKeys = Object.keys(formikProps.errors);
                        const touchedKeys = Object.keys(formikProps.touched);
                        const hasValidationError = errorKeys
                            .filter((key) => key !== 'bestellpositionenView')
                            .some((errorKey) => touchedKeys.includes(errorKey));

                        const hasOverWeight = formikProps.errors.bestellpositionenView === MAX_WEIGHT_ERROR;
                        const notificationSeverity = hasOverWeight && overWeightConfirmed ? Severity.CONFIRMRED : Severity.ERROR;

                        const { values } = formikProps;
                        const selectedLieferbedingung = values.lieferbedingung;
                        const shouldDisplaySpeditionAuswahl =
                            selectedLieferbedingung && selectedLieferbedingung !== Lieferbedingungen.FRANCO;

                        return (
                            <>
                                <NotificationBar
                                    testId="validation-message-bar"
                                    message={VALIDATION_ERROR}
                                    isVisible={hasValidationError}
                                />
                                <Form>
                                    <FormRow>
                                        <FormColumn isSection>
                                            <div className={formStyles._sectionHeader}>
                                                <h3>Warenempfänger</h3>
                                            </div>
                                            <>
                                                <div>{bestellung.warenempfaenger?.hauptname}</div>
                                                <div>{bestellung.warenempfaenger?.strasse}</div>
                                                <div className={styles._plzOrt}>
                                                    {bestellung.warenempfaenger?.postleitzahl} {bestellung.warenempfaenger?.ort}
                                                </div>
                                                {bestellung.vvvoNummer && (
                                                    <>
                                                        <br />
                                                        <div>VVVO-Nummer: {bestellung.vvvoNummer}</div>
                                                    </>
                                                )}
                                            </>
                                        </FormColumn>
                                        <RechnungsempfaengerFormSection />
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn isSection>
                                            <div className={formStyles._sectionHeader}>
                                                <h3>Zusätzliche Angaben</h3>
                                            </div>
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn isSection>
                                            <FormControlWithoutErrorDisplay
                                                name="lieferbedingung"
                                                id="lieferbedingung"
                                                label="Lieferart *"
                                                renderChild={(props): ReactElement => (
                                                    <SelectInput<Option>
                                                        {...props}
                                                        setFieldTouched={formikProps.setFieldTouched}
                                                        options={lieferbedingungOptions}
                                                        placeholder="bitte auswählen"
                                                        isClearable={false}
                                                        autoSelectIfOnlyOneOption={true}
                                                    />
                                                )}
                                            />
                                        </FormColumn>
                                        <FormColumn isSection>
                                            <FormControl
                                                name="ansprechpartner"
                                                id="ansprechpartner"
                                                label="Kontakt für Rückfragen"
                                                renderChild={(props): ReactElement => <TextInput {...props} />}
                                            />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn isSection>
                                            <FormControl
                                                name="bestellhinweis"
                                                id="bestellhinweis"
                                                label="Bestellhinweis"
                                                allowChildToGrow={true}
                                                renderChild={(props): ReactElement => (
                                                    <Textarea data-cy={'form-bestellhinweis'} maxLength={250} {...props} />
                                                )}
                                            />
                                        </FormColumn>
                                        <FormColumn isSection>
                                            <FormControl
                                                name="lieferhinweis"
                                                id="lieferhinweis"
                                                label="Lieferhinweis"
                                                allowChildToGrow={true}
                                                renderChild={(props): ReactElement => (
                                                    <Textarea data-cy={'form-lieferhinweis'} maxLength={235} {...props} />
                                                )}
                                            />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn isSection>
                                            <div className={formStyles._selectWithButtonContainer}>
                                                <KfzKennzeichenAuswahl
                                                    kfzKennzeichen={kfzKennzeichenOptions}
                                                    onKfzKennzeichenCreated={(kfzKennzeichen): void => {
                                                        dispatch(
                                                            addKfzKennzeichen({
                                                                id: TEMPORARY_KFZ_ID,
                                                                kennzeichen: kfzKennzeichen,
                                                            })
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </FormColumn>
                                        <FormColumn isSection>
                                            <WunschterminUhrzeit
                                                label={createWunschterminLabelByLieferbedingung(formikProps.values.lieferbedingung)}
                                            />
                                        </FormColumn>
                                        {shouldDisplaySpeditionAuswahl && (
                                            <FormColumn isSection>
                                                <div className={formStyles._selectWithButtonContainer} style={{ width: '46.6%' }}>
                                                    <SpeditionAuswahl
                                                        spedition={speditionOptions}
                                                        onSpeditionCreated={(spedition): void => {
                                                            if (spedition.trim() !== '') {
                                                                if (!isValidEmail(spedition)) {
                                                                    setHasInvalidEmailError(true);
                                                                    return;
                                                                } else {
                                                                    setHasInvalidEmailError(false);
                                                                }
                                                            }
                                                            dispatch(
                                                                addSpedition({
                                                                    id: TEMPORARY_SPEDITION_ID,
                                                                    email: spedition,
                                                                })
                                                            );
                                                        }}
                                                        onSpeditionSelected={(spedition): void => {
                                                            if (spedition.trim() !== '') {
                                                                if (!isValidEmail(getEmailById(spedition))) {
                                                                    setHasInvalidEmailError(true);
                                                                    return;
                                                                } else {
                                                                    setHasInvalidEmailError(false);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <NotificationBar
                                                    testId="invalid-email-message-bar"
                                                    message="Bitte geben Sie eine gültige Mailadresse ein."
                                                    isVisible={hasInvalidEmailError}
                                                />
                                            </FormColumn>
                                        )}
                                        <FormRow></FormRow>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn isSection>
                                            <div className={formStyles._sectionHeader}>
                                                <h3>Angaben des Warenempfängers</h3>
                                            </div>
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn isSection>
                                            <WunschterminZeitraum />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        {bestellung.bestellhinweisWE ? (
                                            <FormColumn isSection>
                                                <TextItem label="Bestellhinweis" text={bestellung.bestellhinweisWE} />
                                            </FormColumn>
                                        ) : null}
                                        <FormColumn />
                                    </FormRow>
                                    <>
                                        <NotificationBar
                                            testId="weight-validation-message-bar"
                                            dataCy={'weight-validation-message-bar'}
                                            message=""
                                            isVisible={hasOverWeight}
                                            severity={notificationSeverity}
                                        >
                                            <FormikCheckbox
                                                id="notificationCheck"
                                                name="notificationCheck"
                                                label={OVERWEIGHT_ERROR}
                                                onChangeCallback={(checked): void => {
                                                    setOverWeightConfirmed(checked.currentTarget.checked);
                                                }}
                                            />
                                        </NotificationBar>
                                        <BestellpositionenNotifications
                                            reloadArtikelCallback={(): void => {
                                                reloadArtikelLoseWare();
                                                reloadArtikelSackware();
                                                reloadWerke();
                                            }}
                                            triedToSubmit={triedToSubmit}
                                        />
                                        <br />
                                        <ArtikelauswahlDropdown />
                                        <br />
                                        <div className={formStyles._sectionHeader}>
                                            <h3>Auftragsmengen</h3>
                                        </div>
                                        <Bestellpositionen onArtikelDeleted={onArtikelDeleted} />
                                        <MandatoryFormFieldHint />
                                        <FormRow>
                                            <FormColumn>
                                                <AuftragsansichtButtons
                                                    setTriedToSubmit={setTriedToSubmit}
                                                    setSaveAsDraft={setSaveAsDraft}
                                                    setStorniereAuftrag={setStorniereAuftrag}
                                                    overWeightConfirmed={overWeightConfirmed}
                                                    hasOverWeight={hasOverWeight}
                                                />
                                            </FormColumn>
                                        </FormRow>
                                    </>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </LoadingSpinner>
        </>
    );
};

export default Auftragsansicht;
