import { CellProps, Column } from 'react-table';
import styles from '../../../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb.module.scss';
import TableCell from '../../../../shared/ui-components/Table/ReactTable/TableCell';
import type { TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from '../../TourenplanTypes';
import { NEWID_PREFIX } from '../../../../shared/constants';

const ArtikelColumnCell = (
    cellProps: CellProps<
        TourenplanLoseWareBestellung | TourenplanSackwareBestellung,
        TourenplanLoseWareBestellung | TourenplanSackwareBestellung
    >
): JSX.Element => {
    const { nummer, bezeichnung } = cellProps.value;
    const { zulage, zulageText } = cellProps.value as TourenplanLoseWareBestellung;
    return (
        <TableCell cell={cellProps.cell}>
            <div data-testid="tourenplan-artikel-column">
                {nummer?.startsWith(NEWID_PREFIX) || <div data-cy="tourenplan-artikel-nummer">{nummer}</div>}
                <div data-cy="tourenplan-artikel-bezeichnung" className={styles._subInfo}>
                    {bezeichnung}
                    {zulage && (
                        <>
                            <br />
                            <span>Zulage: </span>
                            <span>{zulageText}</span>
                        </>
                    )}
                </div>
            </div>
        </TableCell>
    );
};

const artikelColumnConfig = <T extends TourenplanLoseWareBestellung | TourenplanSackwareBestellung>(): Column<T> => ({
    id: 'article',
    Header: 'Artikel',
    accessor: (bestellung: T): TourenplanLoseWareBestellung | TourenplanSackwareBestellung => {
        return {
            ...bestellung,
            bezeichnung: bestellung.bezeichnung,
            nummer: bestellung.nummer,
        };
    },
    Cell: ArtikelColumnCell,
});

export const loseWareArtikelColumnConfig = artikelColumnConfig<TourenplanLoseWareBestellung>();
export const sackwareArtikelColumnConfig = artikelColumnConfig<TourenplanSackwareBestellung>();
