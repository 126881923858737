import type { DownloadConfig } from '../shared/fetchApi';
import { LicenseReport } from './licenseReport';
import Config from '../shared/config';
const { isApp } = Config.getConfig();

type FlutterDownloadConfig = {
    url: string;
    filename: string;
};

const FlutterBridge = {
    sendLicenses: (): void => {
        if (!window.LicenseChannel) {
            console.log('LicenseChannel does not exist');
            return;
        }

        const licenseReport = LicenseReport.get();
        if (Object.entries(licenseReport).length === 0) {
            console.log('License report is empty');
            return;
        }
        window.LicenseChannel.postMessage(JSON.stringify(licenseReport));
    },
    sendDownloadLink: (config: Pick<DownloadConfig, 'filename' | 'originalUrl'>): void => {
        if (!window.DownloadChannel) {
            console.log('DownloadChannel does not exist');
            return;
        }

        if (!config.originalUrl) {
            console.log('Missing download URL');
            return;
        }

        const flutterDownloadConfig: FlutterDownloadConfig = {
            url: config.originalUrl,
            filename: config.filename,
        };
        window.DownloadChannel.postMessage(JSON.stringify(flutterDownloadConfig));
    },
    sendLifecycleState: (mounted: boolean): void => {
        if (!window.FrontendLifecycleChannel) {
            console.log('FrontendLifecycleChannel does not exist');
            return;
        }

        window.FrontendLifecycleChannel.postMessage(JSON.stringify({ mounted: mounted }));
    },
    sendToken: (token: string | undefined | null): void => {
        if (!isApp()) {
            return;
        }
        if (!window.TokenChannel) {
            console.log('TokenChannel does not exist');
            return;
        }

        window.TokenChannel.postMessage(JSON.stringify(token));
    },
};

export default FlutterBridge;
