import { BestellpositionArtikelLoseWareView } from '../../../../types';
import { FieldArrayRenderProps } from 'formik';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../../../store/Navigation.store';
import styles from '../LoseWareList.module.scss';
import { BezeichnungEntry, DeleteEntry, MengeEntry, PreisartEntry, SiloEntry, WerkeEntry, ZulagenEntry } from '../../PropertyEntries';

type LoseWareListEntryNewBestellungProps = {
    bestellposition: BestellpositionArtikelLoseWareView;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
};

export const LoseWareListEntryNewBestellung = ({
    bestellposition,
    arrayHelpers,
    onArtikelDeleted,
}: LoseWareListEntryNewBestellungProps): JSX.Element => {
    const isDebitor = useSelector(selectIsDebitor);
    const { index, menge } = bestellposition;

    return (
        <div className={styles._artikelContainer} key={index}>
            <div className={styles._headerContainer}>
                <BezeichnungEntry.List bestellposition={bestellposition} />
                <DeleteEntry.Delete.List
                    bestellposition={bestellposition}
                    arrayHelpers={arrayHelpers}
                    showConfirmDelete={menge > '0'}
                    onArtikelDeleted={onArtikelDeleted}
                />
            </div>
            <div className={styles._amountsContainer}>
                <MengeEntry.List bestellposition={bestellposition} />
                <ZulagenEntry.ListCheckbox bestellposition={bestellposition} />
            </div>
            <ZulagenEntry.ListText bestellposition={bestellposition} />
            <div className={styles._siloWerk}>
                <SiloEntry.List bestellposition={bestellposition} />
                <WerkeEntry.List bestellposition={bestellposition} />
            </div>
            {isDebitor ? <PreisartEntry.List bestellposition={bestellposition} /> : null}
        </div>
    );
};
