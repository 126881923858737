import { Option } from '../helper/select-options';
import { useSelector } from 'react-redux';
import { WerkSelectors } from '../../store/Abholwerk.store';

export const useWerkOptions = (bestellpositionNummer: string): Option[] => {
    const werkeForArtikel = useSelector(WerkSelectors.selectWerkeForArtikel);

    const werkeForThisArtikel = werkeForArtikel.find((wa) => wa.artikelNummer === bestellpositionNummer)?.werke;

    if (werkeForThisArtikel && werkeForThisArtikel.length > 0) {
        return werkeForThisArtikel.map(
            (w): Option => ({
                value: w.id,
                label: w.name,
            })
        );
    }

    return [];
};
