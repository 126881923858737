import { getData, postData } from '../shared/fetchApi';
import { PayloadAction } from '@reduxjs/toolkit';
import type { Spedition } from '../shared/types';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { setSpedition } from '../store/Bestellung.store';
import {
    addSpeditionFailed,
    addSpeditionSucceeded,
    deleteSpeditionFailed,
    deleteSpeditionSucceeded,
    getSpeditionenFailed,
    getSpeditionenSucceeded,
    updateSpeditionFailed,
    updateSpeditionSucceeded,
} from '../store/Spedition.store';

export function* putSpedition({ payload }: PayloadAction<Spedition>): Generator<PutEffect | CallEffect, void, Spedition> {
    try {
        yield put(setSpedition(payload.id));
        const copy: Pick<Spedition, 'email'> = { email: payload.email };
        const newSpedition = yield call(postData, 'spedition', copy, 'POST');
        yield put(addSpeditionSucceeded(newSpedition));
        yield put(setSpedition(newSpedition.id));
    } catch (error) {
        if (error instanceof Error) {
            yield put(addSpeditionFailed(error.message));
        }
        yield put(setSpedition());
    }
}

export function* getSpeditionenSaga(): Generator<PutEffect | CallEffect, void, Spedition[]> {
    try {
        const spedition = yield call(getData, 'spedition');
        yield put(getSpeditionenSucceeded(spedition));
    } catch (error: unknown) {
        if (error instanceof Error) {
            yield put(getSpeditionenFailed(error.message));
        }
    }
}

export function* updateSpeditionSaga({ payload: spedition }: PayloadAction<Spedition>): Generator<PutEffect | CallEffect, void> {
    try {
        yield call(postData, `spedition/${spedition.id}`, spedition, 'PUT');
        yield put(updateSpeditionSucceeded(spedition));
    } catch {
        yield put(updateSpeditionFailed(spedition));
    }
}

export function* deleteSpeditionSaga({ payload: spedition }: PayloadAction<Spedition>): Generator<PutEffect | CallEffect, void> {
    try {
        yield call(postData, `spedition/${spedition.id}`, spedition, 'DELETE');
        yield put(deleteSpeditionSucceeded(spedition));
    } catch {
        yield put(deleteSpeditionFailed(spedition));
    }
}
