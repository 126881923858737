import { ModifikationsstatusCellProps } from './type';
import { useErpRueckmeldung } from './useErpRueckmeldung';
import { TableCell } from '../../../../ui-components/Table';
import { ModifikationsstatusIcon } from './ModifikationsstatusIcon';

export const ModifikationsstatusTableCell = (props: ModifikationsstatusCellProps): JSX.Element | null => {
    const erpRueckmeldung = useErpRueckmeldung(props.nummer, props.positionsnummer);

    return (
        <TableCell button>
            <ModifikationsstatusIcon status={erpRueckmeldung?.modifikationsstatus} comment={erpRueckmeldung?.modifikationskommentar} />
        </TableCell>
    );
};
