import styles from './SelectableCard.module.scss';
import { ReactNode } from 'react';

export type Props = Readonly<{
    children: ReactNode;
    selected: boolean;
    onClick: () => void;
    testId?: string;
    dataCy?: string;
}>;

const SelectableCard = ({ children, selected, onClick, testId, dataCy }: Props): JSX.Element => {
    const linkClass = selected ? [styles['_card'], styles['_card--selected']].join(' ') : styles._card;
    return (
        <div className={linkClass} onClick={onClick} data-testid={testId} data-cy={dataCy}>
            <div className={styles._selectionIcon}>
                <div className={styles._innerSelectionIcon}></div>
            </div>
            <div>{children}</div>
        </div>
    );
};

export default SelectableCard;
