import { Sollbestand, SollbestandAndBestand } from '../shared/types';
import { setRechnungsempfaenger, setWarenempfaenger, setWerk } from './Bestellung.store';

export const GET_SOLLBESTAND = 'GET_SOLLBESTAND';
export const GET_SOLLBESTAND_SUCCEEDED = 'GET_SOLLBESTAND_SUCCEEDED';
export const GET_SOLLBESTAND_FAILED = 'GET_SOLLBESTAND_FAILED';
export const POST_SOLLBESTAND = 'POST_SOLLBESTAND';

export interface GetSollbestandAction {
    type: typeof GET_SOLLBESTAND;
    warenempfaengerPartnerNummer: string;
    werkId: string;
}

export interface GetSollbestandSucceededAction {
    type: typeof GET_SOLLBESTAND_SUCCEEDED;
    sollbestand: Sollbestand[];
}

export interface GetSollbestandFailedAction {
    type: typeof GET_SOLLBESTAND_FAILED;
}

export interface PostSollbestandAction {
    type: typeof POST_SOLLBESTAND;
    sollbestand: SollbestandAndBestand[];
    rechnungsempfaengerPartnerNummer: string;
    warenempfaengerPartnerNummer: string;
    werkId: string;
}

export function getSollbestand(warenempfaengerPartnerNummer: string, werkId: string): GetSollbestandAction {
    return {
        type: GET_SOLLBESTAND,
        warenempfaengerPartnerNummer,
        werkId,
    };
}

export function getSollbestandSucceeded(sollbestand: Sollbestand[]): GetSollbestandSucceededAction {
    return {
        type: GET_SOLLBESTAND_SUCCEEDED,
        sollbestand,
    };
}

export function getSollbestandFailed(): GetSollbestandFailedAction {
    return {
        type: GET_SOLLBESTAND_FAILED,
    };
}

export function postSollbestand(
    sollbestand: SollbestandAndBestand[],
    rechnungsempfaengerPartnerNummer: string,
    warenempfaengerPartnerNummer: string,
    werkId: string
): PostSollbestandAction {
    return {
        type: POST_SOLLBESTAND,
        sollbestand,
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        werkId,
    };
}

export type SollbestandState = {
    sollbestand: SollbestandAndBestand[];
    isLoading: boolean;
    loadFinished: boolean;
    hasLoadError: boolean;
};

export const INITIAL_SOLLBESTAND_STATE: SollbestandState = {
    sollbestand: [],
    isLoading: false,
    loadFinished: false,
    hasLoadError: false,
};

type SollbestandActions = GetSollbestandAction | GetSollbestandFailedAction | GetSollbestandSucceededAction | PostSollbestandAction;

function mergeSollbestandWithBestand(bestand: SollbestandAndBestand[], sollbestand: Sollbestand[]): SollbestandAndBestand[] {
    const mergedMap: { [artikelNummer: string]: SollbestandAndBestand } = {};
    sollbestand.forEach((sb) => {
        mergedMap[sb.artikelNummer] = { ...sb };
    });

    bestand.forEach((b) => {
        if (mergedMap.hasOwnProperty(b.artikelNummer)) {
            mergedMap[b.artikelNummer].bestandVE = b.bestandVE;
        } else {
            mergedMap[b.artikelNummer] = { ...b };
        }
    });

    return Object.values(mergedMap);
}

export function sollbestandReducer(
    state: SollbestandState = INITIAL_SOLLBESTAND_STATE,
    action:
        | SollbestandActions
        | { type: typeof setWerk.type }
        | { type: typeof setRechnungsempfaenger.type }
        | { type: typeof setWarenempfaenger.type }
): SollbestandState {
    switch (action.type) {
        case setWerk.type:
            return INITIAL_SOLLBESTAND_STATE;
        case setRechnungsempfaenger.type:
            return INITIAL_SOLLBESTAND_STATE;
        case setWarenempfaenger.type:
            return INITIAL_SOLLBESTAND_STATE;
        case POST_SOLLBESTAND:
            return {
                ...state,
                sollbestand: action.sollbestand,
            };
        case GET_SOLLBESTAND:
            return {
                ...state,
                isLoading: true,
            };
        case GET_SOLLBESTAND_SUCCEEDED:
            return {
                ...state,
                sollbestand: mergeSollbestandWithBestand(state.sollbestand, action.sollbestand),
                isLoading: false,
                loadFinished: true,
                hasLoadError: false,
            };
        case GET_SOLLBESTAND_FAILED:
            return {
                ...state,
                isLoading: false,
                loadFinished: true,
                hasLoadError: true,
            };
        default:
            return state;
    }
}
