import { useController } from 'react-hook-form';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { components, NamedProps } from 'react-select';
import styles from './SelectInput.module.scss';
import { useMemo, HTMLProps } from 'react';
import { Option } from '../../helper/select-options';
import { InputProps } from 'react-select/src/components/Input';
import { createCustomStyles } from './CustomStyles';

export type CreatableSelectProps = {
    autoSelectIfOnlyOneOption: boolean;
    inTableCell?: boolean;
    hideNativeSelect?: boolean;
    onValueChange?: (value: string) => void;
    menuAlignment?: 'right' | 'left';
    maxLength?: number;

    // List of NamedProps overrides to make them required
    name: string;
    options: Option[];
} & NamedProps<Option> &
    CreatableProps<Option, false>;

const createSelectInputComponentWithMaxLength = (maxLength: number | undefined) => {
    const Input = function Input(props: InputProps & HTMLProps<HTMLInputElement>): JSX.Element {
        return <components.Input {...props} />;
    };

    return function InputWithMaxLength(props: InputProps & HTMLProps<HTMLInputElement>): JSX.Element {
        return <Input {...props} maxLength={maxLength} />;
    };
};

const CreatableSelectWithHookForm = (props: CreatableSelectProps): JSX.Element => {
    const {
        isDisabled,
        options,
        name,
        maxLength,
        placeholder,
        onCreateOption,
        formatCreateLabel,
        inTableCell,
        onValueChange,
        menuAlignment,
        noOptionsMessage,
        isClearable,
        classNamePrefix,
    } = props;
    const { field, fieldState } = useController({
        name,
    });

    const InputWithMaxLength = useMemo(() => createSelectInputComponentWithMaxLength(maxLength), [maxLength]);

    const value = (options ? options.find((option) => option.value === field.value) : '') as Option;

    const onChange = (option: Option | null): void => {
        const newValue = option ? option.value : '';
        field.onChange(newValue);
        onValueChange && onValueChange(newValue);
    };

    return (
        <CreatableSelect<Option>
            formatCreateLabel={formatCreateLabel}
            classNamePrefix={classNamePrefix}
            className={styles._select}
            styles={createCustomStyles<Option>(fieldState.invalid, inTableCell, menuAlignment)}
            options={options}
            name={field.name}
            value={value}
            placeholder={placeholder}
            onCreateOption={onCreateOption}
            onBlur={field.onBlur}
            onChange={onChange}
            isDisabled={isDisabled}
            isClearable={isClearable}
            noOptionsMessage={noOptionsMessage || ((): string => 'Keine Einträge')}
            components={{ ...components, Input: InputWithMaxLength }}
        />
    );
};

export default CreatableSelectWithHookForm;
