import styles from '../../ArtikelLoseWareTable/LoseWareList.module.scss';
import { PreisartAuswahl } from '../../../PreisartAuswahl/PreisartAuswahl';
import { PropertyEntryReadonlyProps } from '../types';
import TextItem from '../../../../ui-components/TextItem/TextItem';
import { berechnungsartOptions } from '../../../../helper/select-options';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const PreisartListEntry = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly) {
        return (
            <>
                <TextItem
                    label="Berechnungsart"
                    text={berechnungsartOptions.find((o) => o.value === bestellposition.berechnungsart)?.label}
                    isStorniert={bestellposition.storno}
                />
                <TextItem label="Kontrakt" text={bestellposition.kontrakt} isStorniert={bestellposition.storno} />
            </>
        );
    }

    return (
        <div className={styles._berechnungsartKontrakt}>
            <PreisartAuswahl bestellposition={bestellposition} disabled={disabled} />
        </div>
    );
};
