import { WunschterminListEntry } from './WunschterminListEntry';
import { WunschterminDatumCell } from './WunschterminDatumCell';
import { WunschterminUhrzeitCell } from './WunschterminUhrzeitCell';

const WunschterminEntry = {
    List: WunschterminListEntry,
    DatumCell: WunschterminDatumCell,
    UhrzeitCell: WunschterminUhrzeitCell,
};

export { WunschterminEntry };
