import { select, put, call, SagaGenerator } from 'typed-redux-saga';
import { getData } from '../shared/fetchApi';
import { getRechnungsempfaengerFailed, getRechnungsempfaengerSucceeded } from '../store/Rechnungsempfaenger.store';
import { CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import type { Rechnungsempfaenger } from '../shared/types';
import { NEWID_PREFIX } from '../shared/constants';
import { BestellungSelectors } from '../store/Bestellung.store';

type GetRechnungsEmpfaenger = (url: string) => Promise<Rechnungsempfaenger[]>;
export function* getRechnungsempfaengerSaga(): SagaGenerator<void, SelectEffect | CallEffect | PutEffect> {
    try {
        const warenempfaenger = yield* select(BestellungSelectors.warenempfaengerPartnerNummer);
        const isNewWarenempfaenger = warenempfaenger === '' || warenempfaenger.startsWith(NEWID_PREFIX);

        const url = isNewWarenempfaenger ? 'rechnungsempfaenger' : `warenempfaenger/${warenempfaenger}/rechnungsempfaenger`;
        const rechnungsempfaenger = yield* call<[string], GetRechnungsEmpfaenger>(getData, url);

        yield* put(getRechnungsempfaengerSucceeded(rechnungsempfaenger));
    } catch (e) {
        yield* put(getRechnungsempfaengerFailed());
    }
}
