import { BestellpositionArtikelSackwareView } from '../../../../types';
import styles from '../SackwareList.module.scss';
import { FieldArrayRenderProps } from 'formik';
import { useSelector } from 'react-redux';
import { isExistingBestellposition, isStornierbarePosition } from '../../../../helper/bestellungen-helper';
import { AenderbarBisListEntry } from '../../../AenderbarBis/AenderbarBisListEntry';
import { BestelluebersichtSelectors } from '../../../../../store/Bestelluebersicht.store';
import { DeleteEntry, MengeSWEntry, WerkeEntry } from '../../PropertyEntries';
import { ReactElement } from 'react';

type SackwareListEntryExistingBestellungProps = {
    bestellposition: BestellpositionArtikelSackwareView;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
    allowDelete?: boolean;
};

export const SackwareListEntryExistingBestellung = ({
    bestellposition,
    onArtikelDeleted,
    allowDelete,
    arrayHelpers,
}: SackwareListEntryExistingBestellungProps): ReactElement => {
    const { mengeVe, mengePal, bezeichnung, nummer, storno, positionsnummer } = bestellposition;
    const aenderbarBisExpired = useSelector(BestelluebersichtSelectors.hasAenderbarBisExpiredForPosition(nummer, positionsnummer));
    const isCurrentPositionGesperrt = useSelector(BestelluebersichtSelectors.isCurrentPositionGesperrt(nummer, positionsnummer));
    const isDisabled = isCurrentPositionGesperrt || aenderbarBisExpired;

    return (
        <div className={styles._artikelContainer}>
            <div className={styles._headerContainer}>
                <h4 className={storno ? styles['_artikelHeadline--storniert'] : ''}>{bezeichnung}</h4>
                {isExistingBestellposition(bestellposition) ? (
                    <DeleteEntry.Storno.List
                        bestellposition={bestellposition}
                        disabled={!isStornierbarePosition(bestellposition) || isDisabled}
                    />
                ) : allowDelete ? (
                    <DeleteEntry.Delete.List
                        bestellposition={bestellposition}
                        arrayHelpers={arrayHelpers}
                        disabled={isDisabled}
                        showConfirmDelete={mengeVe > 0 || mengePal > 0}
                        onArtikelDeleted={onArtikelDeleted}
                    />
                ) : null}
            </div>
            <div className={styles._modifikationsContainer}>
                <AenderbarBisListEntry
                    nummer={nummer}
                    positionsnummer={positionsnummer}
                    aenderbarBisExpired={aenderbarBisExpired}
                    storno={storno}
                />
            </div>
            <div className={styles._amountsContainer}>
                <MengeSWEntry.List bestellposition={bestellposition} disabled={isDisabled} />
            </div>
            <WerkeEntry.List bestellposition={bestellposition} readonly={isExistingBestellposition(bestellposition)} />
        </div>
    );
};
