import { ReactElement } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';

type BestelldatenHintProps = { text: string } & TippyProps;
export const BestelldatenHint = ({ children, text, ...props }: BestelldatenHintProps): ReactElement | null => {
    return (
        <Tippy content={text} offset={[0, -5]} maxWidth={400} delay={200} {...props}>
            {children}
        </Tippy>
    );
};
