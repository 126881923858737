import Select, { components, OptionTypeBase, SingleValueProps } from 'react-select';
import styles from '../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb.module.scss';
import selectStyles from '../../shared/ui-components/SelectInput/SelectInput.module.scss';
import { FunctionComponent, ReactElement } from 'react';
import { KontraktHandelsPartner, UebersichtKontrakt } from '../../shared/types';
import { Option, removeDuplicateOptions } from '../../shared/helper/select-options';
import { NEWID_PREFIX } from '../../shared/constants';
import { createCustomStyles } from '../../shared/ui-components/SelectInput/CustomStyles';
import { SelectComponentsProps } from 'react-select/base';

export const FormattedValueAfterSelectionWE: FunctionComponent<SingleValueProps<OptionTypeBase>> = (
    props: SingleValueProps<OptionTypeBase>
) => {
    const warenempfaenger = props.data.warenempfaenger as KontraktHandelsPartner;
    const { name, partnerNummer } = warenempfaenger;
    return <components.SingleValue {...props}>{`${name} (${partnerNummer})`}</components.SingleValue>;
};

type FilterWithOptionsProps = Readonly<{
    options: Option[];
    loading: boolean;
    name: string;
    placeholder: string;
    formattedValue?: FunctionComponent<SingleValueProps<OptionTypeBase>>;
    className?: string;
}> &
    Pick<SelectComponentsProps, 'onChange' | 'value'>;

export const warenempfaengerOptionen = (kontrakte: UebersichtKontrakt[]): Option[] => {
    const kontraktHandelspartnerWarenempfaenger = kontrakte
        .flatMap((kontrakt) => {
            return kontrakt.allWarenempfaenger;
        })
        .filter((entry) => entry);

    const kontraktHandelspartnerOptions = kontraktHandelspartnerWarenempfaenger.map(
        (warenempfaenger): Option & { warenempfaenger: KontraktHandelsPartner } => {
            return {
                nummer: warenempfaenger.partnerNummer,
                value: `${warenempfaenger.name}-${warenempfaenger.partnerNummer}`,
                label: (
                    <>
                        <div>{warenempfaenger.name}</div>
                        <div>
                            <span>
                                ({warenempfaenger.partnerNummer?.startsWith(NEWID_PREFIX) ? 'Neu angelegt' : warenempfaenger.partnerNummer})
                            </span>
                        </div>
                    </>
                ),
                warenempfaenger,
            };
        }
    );
    return removeDuplicateOptions(kontraktHandelspartnerOptions);
};

export const kontraktpartnerOptionen = (kontrakte: UebersichtKontrakt[]): Option[] => {
    const kontraktpartnerOptions = kontrakte
        .filter((kontrakt) => kontrakt.kontraktpartnerNummer)
        .map((kontrakt): Option => {
            return {
                nummer: kontrakt.kontraktpartnerNummer,
                value: `${kontrakt.kontraktpartnerName}-${kontrakt.kontraktpartnerNummer}`,
                label: (
                    <>
                        <div>{kontrakt.kontraktpartnerName}</div>
                    </>
                ),
            };
        });
    return removeDuplicateOptions(kontraktpartnerOptions);
};

export const FilterWithOptions = ({
    options,
    value,
    onChange,
    loading,
    placeholder,
    name,
    formattedValue,
    className = styles._filter,
}: FilterWithOptionsProps): ReactElement => {
    return (
        <div className={className}>
            <Select
                options={options}
                name={name}
                value={value}
                onChange={onChange}
                styles={createCustomStyles(false, false, 'left')}
                className={[selectStyles._select, selectStyles['_select--inTableCell']].join(' ')}
                placeholder={placeholder}
                menuShouldScrollIntoView
                components={formattedValue ? { SingleValue: formattedValue } : undefined}
                menuPlacement={'auto'}
                noOptionsMessage={(): string => 'Keine Einträge'}
                isLoading={loading}
                isClearable
            />
        </div>
    );
};
