import { useEffect } from 'react';
import styles from './Lieferungsansicht.module.scss';
import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LieferansichtParam, Lieferung } from '../../shared/types';
import { getLieferung } from '../../store/Lieferung.store';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { convertDateWithTimeFromIsoStringToDateInfo } from '../../shared/helper/date-helper';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import Button from '../../shared/ui-components/Button/Button';
import { routes } from '../../store/Workflow.store';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import LieferungPositionenTable from '../../shared/content-components/LieferungPositionenTable/LieferungPositionenTable';
import LieferungPositionenList from '../../shared/content-components/LieferungPositionenTable/LieferungPositionenList';
import iconGeliefert from '../../assets/icon-geliefert.svg';
import iconNichtGeliefert from '../../assets/icon-nicht-geliefert.svg';
import iconFakturiert from '../../assets/icon-fakturiert.svg';
import iconNichtFakturiert from '../../assets/icon-nicht-fakturiert.svg';
import { useMediaQuery } from 'react-responsive';
import type { RootState } from '../../configureStore';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Auftragsart, Fakturierungsstatus, Lieferstatus, LieferungLieferbedingung } from '../../shared/types/enums';
import { selectIsDebitor } from '../../store/Navigation.store';

const auftragsArtToText = (auftragsart: Auftragsart): string => {
    switch (auftragsart) {
        case Auftragsart.LoseWare:
            return 'lose Ware';
        case Auftragsart.Sackware:
            return 'Sackware';
        case Auftragsart.Werbemittel:
            return 'Werbemittel';
        default:
            return '';
    }
};

export const fakturierungsstatusToText = (fakturierungsstatus: Fakturierungsstatus): string => {
    switch (fakturierungsstatus) {
        case Fakturierungsstatus.NichtFakturiert:
            return 'nicht fakturiert';
        case Fakturierungsstatus.KomplettFakturiert:
            return 'fakturiert';
        case Fakturierungsstatus.TeilweiseFakturiert:
            return 'fakturiert';
        default:
            return '';
    }
};

export const fakturierungsstatusToIcon = (fakturierungsstatus: Fakturierungsstatus): string => {
    switch (fakturierungsstatus) {
        case Fakturierungsstatus.NichtFakturiert:
            return iconNichtFakturiert;
        case Fakturierungsstatus.KomplettFakturiert:
            return iconFakturiert;
        case Fakturierungsstatus.TeilweiseFakturiert:
            return iconFakturiert;
        default:
            return '';
    }
};

export const lieferstatusToText = (lieferstatus: Lieferstatus): string => {
    switch (lieferstatus) {
        case Lieferstatus.KomplettGeliefert:
            return 'LKW beladen';
        case Lieferstatus.NichtGeliefert:
            return 'LKW nicht beladen';
        case Lieferstatus.TeilweiseGeliefert:
            return 'LKW beladen';
        default:
            return '';
    }
};

export const lieferstatusToIcon = (lieferstatus: Lieferstatus): string => {
    switch (lieferstatus) {
        case Lieferstatus.KomplettGeliefert:
            return iconGeliefert;
        case Lieferstatus.NichtGeliefert:
            return iconNichtGeliefert;
        case Lieferstatus.TeilweiseGeliefert:
            return iconGeliefert;
        default:
            return '';
    }
};

function formatLieferbedingung(lieferung: Lieferung) {
    switch (lieferung.lieferbedingung) {
        case LieferungLieferbedingung.AbWerk:
            return 'Ab Werk';
        case LieferungLieferbedingung.FreiKunde:
            return 'Frei Kunde / frei Silo';
        case LieferungLieferbedingung.Unbekannt:
            return ''; // customer wants this empty, so there is no confusion for buyers
        default:
            throw new Error(`unknown Lieferbedingung ${lieferung.lieferbedingung}`);
    }
}

const Lieferungsansicht = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { vertriebsauftragsnummer, bestellnummer } = useParams<LieferansichtParam>();
    const lieferung: Lieferung = useSelector((state: RootState) => state.lieferungen.currentLieferung);
    const isLoading = useSelector((state: RootState) => state.lieferungen.currentIsLoading);
    const loadFailed = useSelector((state: RootState) => state.lieferungen.currentLoadFailed);
    const isDebitor = useSelector(selectIsDebitor);
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });

    useEffect(() => {
        dispatch(getLieferung(vertriebsauftragsnummer));
    }, [vertriebsauftragsnummer, dispatch]);

    useDocumentTitle('Lieferung ' + vertriebsauftragsnummer);

    const containerClasses = formStyles._formContainer;
    const columnClasses = [formStyles._formColumn, formStyles._section].join(' ');

    const { datum: wunschtermin, uhrzeit: wunschuhrzeit } = convertDateWithTimeFromIsoStringToDateInfo(lieferung.wunschtermin);
    const lieferscheinDatumInfo = convertDateWithTimeFromIsoStringToDateInfo(lieferung.lieferscheindatum);

    return (
        <>
            <h2>Lieferung {vertriebsauftragsnummer}</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message="Die Lieferung konnte nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={(): void => {
                    dispatch(getLieferung(vertriebsauftragsnummer));
                }}
            />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    <div className={containerClasses}>
                        <div className={columnClasses}>
                            <div className={formStyles._sectionHeader}>
                                <h3>Bestellinformationen</h3>
                            </div>

                            <table className={tableStyles._datentabelle}>
                                <tbody>
                                    <tr>
                                        <td>Eingang Vertriebsauftrag:</td>
                                        <td>{convertDateWithTimeFromIsoStringToDateInfo(lieferung.vertriebsauftragsdatum).datum}</td>
                                    </tr>
                                    <tr>
                                        <td>Bestellnummer:</td>
                                        <td>
                                            {lieferung.fremdbelegnummer.startsWith('PT-') ? (
                                                <Link to={`${routes.bestellansicht}/${lieferung.fremdbelegnummer}`}>
                                                    {lieferung.fremdbelegnummer}
                                                </Link>
                                            ) : (
                                                <>{lieferung.fremdbelegnummer}</>
                                            )}
                                        </td>
                                    </tr>
                                    {isDebitor ? (
                                        <tr>
                                            <td>Debitor Referenznummer:</td>
                                            <td>{lieferung.bestellreferenzDebitor}</td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td>Auftragsart:</td>
                                        <td>{auftragsArtToText(lieferung.auftragsart)}</td>
                                    </tr>
                                    <tr>
                                        <td>Werk:</td>
                                        <td>{lieferung.werk}</td>
                                    </tr>
                                    {lieferung.wunschtermin ? (
                                        <tr>
                                            <td>{isDebitor ? 'Wunschtermin:' : 'Wunschtermin Verladung LKW:'}</td>
                                            <td>
                                                {wunschtermin} {wunschuhrzeit}
                                            </td>
                                        </tr>
                                    ) : null}
                                    {lieferung.sonderherstellung ? (
                                        <tr>
                                            <td>Sonderherstellung:</td>
                                            <td>Ja</td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                        <div className={columnClasses}>
                            <div className={formStyles._sectionHeader}>
                                <h3>Status</h3>
                            </div>
                            <table className={tableStyles._datentabelle}>
                                <tbody>
                                    {isDebitor ? (
                                        <tr>
                                            <td>Fakturierung:</td>
                                            <td>
                                                <img
                                                    className={styles._statusIcon}
                                                    src={fakturierungsstatusToIcon(lieferung.fakturierungsstatus)}
                                                    alt={fakturierungsstatusToText(lieferung.fakturierungsstatus)}
                                                />
                                                <span> ({fakturierungsstatusToText(lieferung.fakturierungsstatus)})</span>
                                            </td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td>Lieferung:</td>
                                        <td>
                                            <img
                                                className={styles._statusIcon}
                                                src={lieferstatusToIcon(lieferung.lieferstatus)}
                                                alt={lieferstatusToText(lieferung.lieferstatus)}
                                            />
                                            <span> ({lieferstatusToText(lieferung.lieferstatus)})</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={containerClasses}>
                        <div className={columnClasses}>
                            <div className={formStyles._sectionHeader}>
                                <h3>Warenempfänger</h3>
                            </div>
                            <>
                                <div>{lieferung.warenempfaenger?.hauptname}</div>
                                <div>{lieferung.warenempfaenger?.strasse}</div>
                                <div className={styles._plzOrt}>
                                    {lieferung.warenempfaenger?.postleitzahl} {lieferung.warenempfaenger?.ort}
                                </div>
                                {lieferung.vvvoNummer && (
                                    <>
                                        <br />
                                        <div>VVVO-Nummer: {lieferung.vvvoNummer}</div>
                                    </>
                                )}
                            </>
                        </div>
                        <div className={columnClasses}>
                            <div className={formStyles._sectionHeader}>
                                <h3>{isDebitor ? 'Rechnungsempfänger' : 'Landwarenhandel'}</h3>
                            </div>
                            <>
                                <div>{lieferung.rechnungsempfaenger?.hauptname}</div>
                                <div>{lieferung.rechnungsempfaenger?.strasse}</div>
                                <div className={styles._plzOrt}>
                                    {lieferung.rechnungsempfaenger?.postleitzahl} {lieferung.rechnungsempfaenger?.ort}
                                </div>
                            </>
                        </div>
                    </div>

                    <div className={containerClasses}>
                        {isDebitor && (
                            <div className={columnClasses}>
                                <div className={formStyles._sectionHeader}>
                                    <h3>Lieferart</h3>
                                </div>
                                <div>{formatLieferbedingung(lieferung)}</div>
                            </div>
                        )}
                        <div className={columnClasses}>
                            <div className={formStyles._sectionHeader}>
                                <h3>Lieferschein</h3>
                            </div>
                            <div>Nummer: {lieferung.lieferscheinnummer}</div>
                            {lieferung.lieferscheindatum && (
                                <div>
                                    Datum: {lieferscheinDatumInfo.datum} {lieferscheinDatumInfo.uhrzeit}
                                </div>
                            )}
                        </div>
                        {!isDebitor && <div className={columnClasses}></div>}
                    </div>

                    {lieferung.positionen.length > 0 && (
                        <>
                            <h3>Positionen</h3>
                            {isMobile ? (
                                <LieferungPositionenList positionen={lieferung.positionen} isDebitor={isDebitor} />
                            ) : (
                                <LieferungPositionenTable positionen={lieferung.positionen} isDebitor={isDebitor} />
                            )}
                        </>
                    )}
                </>
            </LoadingSpinner>
            <ButtonGroup>
                <Button
                    type="button"
                    onClick={(): void => {
                        const path = bestellnummer ? `${routes.bestellansicht}/${bestellnummer}/lieferungen/` : routes.lieferungsuebersicht;
                        history.push(path);
                    }}
                >
                    Zurück
                </Button>
            </ButtonGroup>
        </>
    );
};

export default Lieferungsansicht;
