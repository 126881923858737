import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { GET_KONTRAKTE_ERROR, PAGE_TITLE_KONTRAKTE } from '../../shared/constants';

export function KontraktuebersichtHeader(props: { visible: boolean; actionCallback: () => void }): JSX.Element {
    return (
        <>
            <h2>{PAGE_TITLE_KONTRAKTE}</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message={GET_KONTRAKTE_ERROR}
                isVisible={props.visible}
                actionText="Neu laden"
                actionCallback={props.actionCallback}
            />
        </>
    );
}
