import TextInput from '../../../../ui-components/TextInput/TextInput';
import FormControl from '../../../../ui-components/FormControl/FormControl';
import { PropertyEntryReadonlyProps } from '../types';
import TextItem from '../../../../ui-components/TextItem/TextItem';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const SiloListEntry = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly) {
        return <TextItem label="Silo" text={bestellposition.silo || ''} isStorniert={bestellposition.storno} />;
    }

    return (
        <FormControl
            name={`bestellpositionenView[${bestellposition.index}].silo`}
            id={`bestellpositionenView[${bestellposition.index}].silo`}
            label="Silo"
            renderChild={(props): JSX.Element => (
                <TextInput
                    {...props}
                    type="text"
                    storniert={bestellposition.storno}
                    disabled={disabled}
                    maxLength={40}
                    data-testid={`${bestellposition.nummer}-silo`}
                />
            )}
        />
    );
};
