import { useCallback, useEffect, useState } from 'react';
import styles from './WarenempfaengerAuswaehlen.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Warenempfaenger } from '../../shared/types';
import { Link, useHistory } from 'react-router-dom';
import iconAdd from '../../assets/icon-add.svg';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { setBestellpositionen, setWarenempfaenger } from '../../store/Bestellung.store';
import { getWarenempfaenger, searchWarenempfaenger } from '../../store/Warenempfaenger.store';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { selectNextRoute, setCurrentWorkflowStep, WorkflowStep } from '../../store/Workflow.store';
import SelectableCard from '../../shared/ui-components/SelectableCard/SelectableCard';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import type { RootState } from '../../configureStore';
import WorkflowButtons from '../../shared/content-components/WorkflowButtons/WorkflowButtons';
import { NEWID_PREFIX, routes } from '../../shared/constants';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';

const WarenempfaengerAuswaehlen = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const warenempfaenger = useSelector((state: RootState) => state.warenempfaenger.searchResult);
    const isLoading = useSelector((state: RootState) => state.warenempfaenger.isLoading);
    const hasLoadError = useSelector((state: RootState) => state.warenempfaenger.hasLoadError);
    const nextRoute = useSelector(selectNextRoute);
    const ausgewaehlterWarenempfaenger = useSelector((state: RootState) => state.bestellung.warenempfaengerPartnerNummer);
    const hasBestellpositionen = useSelector(
        (state: RootState) => state.bestellung.bestellpositionen && state.bestellung.bestellpositionen.length > 0
    );

    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useDocumentTitle('Warenempfänger auswählen');

    useEffect(() => {
        dispatch(setCurrentWorkflowStep(WorkflowStep.AuswahlWarenempfaenger, history));
        dispatch(getWarenempfaenger());
    }, [dispatch, history]);

    const validateBestellung = useCallback((): void => {
        if (!ausgewaehlterWarenempfaenger) {
            setErrorMessage('Bitte wählen Sie einen Warenempfänger aus.');
            return;
        }
        history.push(nextRoute);
    }, [history, ausgewaehlterWarenempfaenger, nextRoute, setErrorMessage]);

    const debouncedSearchTerm = useDebounce(searchTerm, 200);
    useEffect(
        () => {
            // Make sure we have a value (user has entered something in input)
            if (debouncedSearchTerm) {
                dispatch(searchWarenempfaenger(searchTerm));
            } else {
                dispatch(searchWarenempfaenger(''));
            }
        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm, dispatch, searchTerm]
    );

    return (
        <>
            <h2>Warenempfänger auswählen</h2>
            <NotificationBar
                testId="bestellpositionen-error-message-bar"
                dataCy="bestellpositionen-error-message-bar"
                message={errorMessage}
                isVisible={errorMessage !== ''}
            />
            <NotificationBar
                testId="load-error-message-bar"
                message="Die Warenempfänger konnten nicht geladen werden. Bitte versuchen Sie es noch einmal."
                actionText="Neu laden"
                actionCallback={(): void => {
                    dispatch(getWarenempfaenger());
                }}
                isVisible={hasLoadError}
            />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    <div className={styles._searchForm}>
                        <TextInputWithoutFormik
                            type="text"
                            name="searchText"
                            id="searchText"
                            placeholder="Suche nach Warenempfängern"
                            onFieldChange={(value: string): void => {
                                setSearchTerm(value);
                            }}
                        />
                    </div>

                    <div className={styles._warenempfaengerContainer}>
                        {warenempfaenger.map((warenempfaenger: Warenempfaenger, index) => {
                            return (
                                <SelectableCard
                                    dataCy={`workflow-warenempfaenger`}
                                    key={index}
                                    selected={warenempfaenger.partnerNummer === ausgewaehlterWarenempfaenger}
                                    onClick={(): void => {
                                        if (ausgewaehlterWarenempfaenger !== warenempfaenger.partnerNummer && hasBestellpositionen) {
                                            showConfirmationDialog({
                                                message:
                                                    ' Sie haben bereits eine nicht abgeschlossene Bestellung. Wenn Sie fortfahren werden Ihre Bestellpositionen gelöscht.',
                                                okButtonText: 'Bestehende Bestellpositionen löschen',
                                                onOkCallback: () => {
                                                    dispatch(setBestellpositionen([]));
                                                    dispatch(setWarenempfaenger(warenempfaenger.partnerNummer));
                                                    dispatch(setCurrentWorkflowStep(WorkflowStep.AuswahlWarenempfaenger, history));
                                                    setErrorMessage('');
                                                },
                                            });
                                        } else {
                                            dispatch(setWarenempfaenger(warenempfaenger.partnerNummer));
                                            dispatch(setCurrentWorkflowStep(WorkflowStep.AuswahlWarenempfaenger, history));
                                            setErrorMessage('');
                                        }
                                    }}
                                >
                                    <div>{warenempfaenger.hauptname}</div>
                                    <div>{warenempfaenger.nebenname}</div>
                                    <div>
                                        <span>{warenempfaenger.namenszusatz}</span>
                                        <span>
                                            {' '}
                                            (
                                            {warenempfaenger.partnerNummer?.startsWith(NEWID_PREFIX)
                                                ? 'Neu angelegt'
                                                : warenempfaenger.partnerNummer}
                                            )
                                        </span>
                                    </div>
                                    <div>{warenempfaenger.strasse}</div>
                                    <div className={styles._plzOrt}>
                                        {warenempfaenger.postleitzahl} {warenempfaenger.ort}
                                    </div>
                                </SelectableCard>
                            );
                        })}
                        <Link to={routes.warenempfaengerAnlegen} className={styles._neuerWarenempfaenger}>
                            <img className={styles._addIcon} src={iconAdd} alt="Neuen Warenempfänger erfassen" />
                            <div className={styles._newAdressLabel} data-cy={'neuen-warenempfaenger-anlegen'}>
                                Neuen Warenempfänger erfassen
                            </div>
                        </Link>
                    </div>
                    <WorkflowButtons onNextClick={validateBestellung} />
                </>
            </LoadingSpinner>
        </>
    );
};

export default WarenempfaengerAuswaehlen;
