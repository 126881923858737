import iconTrashcan from '../../../assets/icon-trashcan.svg';
import IconButton from '../../ui-components/IconButton/IconButton';
import { showConfirmationDialog } from '../../ui-components/ConfirmationDialog/confirmationDialog';

export type Props = Readonly<{
    showConfirmation: boolean;
    deleteRequested?: () => void;
    disabled?: boolean;
}>;

const DeleteArtikelButton = ({ showConfirmation, deleteRequested, disabled = false }: Props): JSX.Element => {
    return (
        <IconButton
            data-cy={'delete-button'}
            test-id={'delete-button'}
            icon={iconTrashcan}
            disabled={disabled}
            alt="Löschen"
            onClick={(event): void => {
                event.preventDefault();

                if (showConfirmation) {
                    showConfirmationDialog({
                        message: (
                            <>Sie haben für diesen Artikel eine Bestellmenge angegeben. Wenn Sie fortfahren wird diese Angabe verworfen.</>
                        ),
                        okButtonText: 'Bestehenden Artikel löschen',
                        onOkCallback: () => {
                            deleteRequested && deleteRequested();
                        },
                    });
                } else {
                    deleteRequested && deleteRequested();
                }
            }}
        />
    );
};

export default DeleteArtikelButton;
