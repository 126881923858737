// overall count calculated here, see: https://github.com/jaredpalmer/formik/issues/1312
import { BestellpositionArtikelSackwareView } from './types';
import { FAKTOR_UEBERMENGE, GEWICHT_LEERPALETTE } from './constants';

const mengeVeAndPeToNumber = (mengeVe: number | string, mengePal: number | string): [number, number] => {
    // if mengeVe and mengePal are empty strings, we return 0
    if (!mengeVe && !mengePal) {
        return [0, 0];
    }

    // if mengeVe or mengePal are strings but with parsable content e.g. "50", we parse to number, otherwise we return 0 as well.
    const numberMengeVe: number = typeof mengeVe === 'number' ? mengeVe : parseInt(mengeVe) || 0;
    const numberMengePal: number = typeof mengePal === 'number' ? mengePal : parseInt(mengePal) || 0;
    return [numberMengeVe, numberMengePal];
};

const overallVerkaufseinheitCount = (mengeVe: number | string, mengePal: number | string, faktorPal: number, faktorVe: number): number => {
    if (faktorVe === 0) {
        return 0;
    }

    const [numberMengeVe, numberMengePal] = mengeVeAndPeToNumber(mengeVe, mengePal);
    return numberMengeVe + (numberMengePal * faktorPal) / faktorVe;
};

const mengePalAndVeToWeightInKg = (mengePal: number | string, mengeVe: number | string, faktorPal: number, faktorVe: number): number => {
    if (faktorVe === 0) {
        return 0;
    }

    const [numberMengeVe, numberMengePal] = mengeVeAndPeToNumber(mengeVe, mengePal);
    return numberMengePal * faktorPal + numberMengeVe * faktorVe;
};

const mengeToNumber = (menge: number | string): number => {
    // if menge is empty, we return 0
    if (!menge) {
        return 0;
    }

    // if menge is an string but with parsable content e.g. "50.5", we parse to number, otherwise we return 0 as well.
    return typeof menge === 'number' ? menge : parseFloat(menge) || 0;
};

const mengeToWeightInKg = (menge: number | string): number => {
    return mengeToNumber(menge) * 1000;
};

type Bestellinformationen = {
    anzahlPaletten: number;
    gesamtGewichtIncUebermenge: number;
};

export const palettenAnzahlFuerBestellposition = (overallVECount: number, faktorPal: number, faktorVe: number): number => {
    if (faktorPal === 0) {
        return 0;
    }

    if (faktorVe === 0) {
        return 0;
    }

    return (overallVECount * faktorVe) / faktorPal;
};

const calcPalettenGesamtanzahl = (bestellpositionenSackwareView: BestellpositionArtikelSackwareView[]): number => {
    // Berechnung der Anzahl der Paletten:
    // Die Anzahl Gesamte VE wird mit den kg je VE mutipliziert und durch die kg pro Pal. dividiert. [gesamt VE x kg / kg pro Pal.
    // Diese Berechnung wird je Position vorgenommen und summiert. Erst das Ergebnis wird aufgerundet.
    const anzahlPalettenUngerundet = bestellpositionenSackwareView.reduce((acc, curr): number => {
        const overallVECount = Formulas.overallVerkaufseinheitCount(
            curr.mengeVe,
            curr.mengePal,
            curr.faktorBasiseinheitPal,
            curr.faktorBasiseinheitVe
        );
        return acc + palettenAnzahlFuerBestellposition(overallVECount, curr.faktorBasiseinheitPal, curr.faktorBasiseinheitVe);
    }, 0.0);

    return Math.ceil(anzahlPalettenUngerundet);
};

const calcPositionenGesamtgewicht = (bestellpositionenSackwareView: BestellpositionArtikelSackwareView[]): number => {
    return bestellpositionenSackwareView.reduce((acc, curr): number => {
        const { mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe } = curr;
        return (
            acc +
            Formulas.overallVerkaufseinheitCount(mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe) * curr.faktorBasiseinheitVe
        );
    }, 0);
};

const calculateBestellinformationen = (bestellpositionenSackwareView: BestellpositionArtikelSackwareView[]): Bestellinformationen => {
    const anzahlPaletten = calcPalettenGesamtanzahl(bestellpositionenSackwareView);
    const gewichtDerPaletten = anzahlPaletten * GEWICHT_LEERPALETTE;
    const gewichtDerPositionen = calcPositionenGesamtgewicht(bestellpositionenSackwareView);

    // Berechnung des Gesamtgewicht inkl. Leerpalettengewicht:
    // (Gesamt VE x kg + Anzahl aufgerundeter Pal. x 23 kg) x 1,01
    const gesamtGewichtIncUebermenge = (gewichtDerPositionen + gewichtDerPaletten) * FAKTOR_UEBERMENGE;

    return {
        gesamtGewichtIncUebermenge,
        anzahlPaletten,
    };
};

const Formulas = {
    mengeToNumber,
    mengeVeAndPeToNumber,
    mengeToWeightInKg,
    mengePalAndVeToWeightInKg,
    overallVerkaufseinheitCount,
    calculateBestellinformationen,
};

export default Formulas;
