import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { GET_WARENEMPFAENGER_FAILED, GET_WARENEMPFAENGER_SUCCEEDED } from '../store/Warenempfaenger.store';
import { getData } from '../shared/fetchApi';

export function* getWarenempfaengerSaga(): IterableIterator<PutEffect | CallEffect> {
    try {
        const warenempfaenger = yield call(getData, 'warenempfaenger');
        yield put({ type: GET_WARENEMPFAENGER_SUCCEEDED, warenempfaenger: warenempfaenger });
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.log(error.message);
            yield put({ type: GET_WARENEMPFAENGER_FAILED, message: error.message });
        }
    }
}
