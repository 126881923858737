import { useRechnungsempfaengerFromBestellung } from './useRechnungsempfaengerFromBestellung';
import { useWarenempfaengerFromBestellung } from './useWarenempfaengerFromBestellung';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getKontrakteForArtikel } from '../../store/Kontrakt.store';
import { Berechnungsart } from '../types/enums';
import { useFormikContext } from 'formik';

type RefreshKontrakteFn = (newWunschterminISO: string | undefined) => void;
export const useFetchKontrakte = (
    artikelNummer: string,
    werkId: string,
    index?: number,
    berechnungsart?: Berechnungsart,
    wunschterminISO?: string
): RefreshKontrakteFn => {
    const { setFieldValue } = useFormikContext();
    const rechnungsempfaenger = useRechnungsempfaengerFromBestellung();
    const warenempfaenger = useWarenempfaengerFromBestellung();
    const dispatch = useDispatch();

    const reloadKontrakte = useCallback(
        (wunschterminAsISO) => {
            if (berechnungsart !== Berechnungsart.Kontrakt) {
                return;
            }
            if (
                rechnungsempfaenger?.partnerNummer &&
                warenempfaenger?.partnerNummer &&
                wunschterminAsISO &&
                artikelNummer &&
                werkId &&
                typeof index !== 'undefined' // NOTE: Index can be 0, so we have to check for undefined
            ) {
                dispatch(
                    getKontrakteForArtikel({
                        artikelNummer,
                        rechnungsempfaengerPartnerNummer: rechnungsempfaenger?.partnerNummer,
                        warenempfaengerPartnerNummer: warenempfaenger?.partnerNummer,
                        werkId,
                        wunschtermin: wunschterminAsISO,
                    })
                );
                setFieldValue(`bestellpositionenView[${index}].kontrakt`, undefined);
            }
        },
        [artikelNummer, berechnungsart, dispatch, index, rechnungsempfaenger, setFieldValue, warenempfaenger?.partnerNummer, werkId]
    );

    useEffect(() => {
        reloadKontrakte(wunschterminISO);
    }, [reloadKontrakte, wunschterminISO]);

    return reloadKontrakte;
};
