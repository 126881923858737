import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import { KontraktDetails } from '../../shared/types';
import { useSelector } from 'react-redux';
import { KontraktuebersichtSelectors } from '../../store/Kontraktuebersicht.store';
import { TableCell, TableHeaderCell, TableRow } from '../../shared/ui-components/Table';

export const WarenempfaengerReadOnlyTable = (): JSX.Element => {
    const kontrakt: KontraktDetails = useSelector(KontraktuebersichtSelectors.currentKontrakt);
    return (
        <>
            <h3>Warenempfänger</h3>
            <table className={tableStyles._table}>
                <thead>
                    <tr className={tableStyles._tableHeaderRow}>
                        <TableHeaderCell text>Name</TableHeaderCell>
                        <TableHeaderCell text>Strasse</TableHeaderCell>
                        <TableHeaderCell text>PLZ</TableHeaderCell>
                        <TableHeaderCell text>Stadt</TableHeaderCell>
                    </tr>
                </thead>
                <tbody>
                    {kontrakt.warenempfaenger.map((warenempfaenger, index) => {
                        return (
                            <TableRow className={tableStyles._tableBodyRow} key={index}>
                                <TableCell text>
                                    {warenempfaenger.hauptname}&nbsp;{warenempfaenger.nebenname}
                                </TableCell>
                                <TableCell text>{warenempfaenger.strasse}</TableCell>
                                <TableCell text>{warenempfaenger.postleitzahl}</TableCell>
                                <TableCell text>{warenempfaenger.ort}</TableCell>
                            </TableRow>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
