import { PutEffect, SelectEffect, TakeEffect } from 'redux-saga/effects';
import { put, SagaGenerator, select, take } from 'typed-redux-saga';
import {
    calcNextWorkflowStep,
    calcPreviousWorkflowStep,
    isAllowedWorkflowStep,
    isFirstWorkflowStep,
    isLastWorkflowStep,
    selectBestellprozesstyp,
    SetCurrentWorkflowStepAction,
    setCurrentWorkflowStepSucceeded,
    WorkflowStep,
} from '../store/Workflow.store';
import {
    GET_RECHNUNGSEMPFAENGER,
    GET_RECHNUNGSEMPFAENGER_FAILED,
    GET_RECHNUNGSEMPFAENGER_SUCCEEDED,
    GetRechnungsempfaengerFailedAction,
    GetRechnungsempfaengerSucceededAction,
} from '../store/Rechnungsempfaenger.store';
import {
    GET_WARENEMPFAENGER_FAILED,
    GET_WARENEMPFAENGER_SUCCEEDED,
    getWarenempfaenger,
    GetWarenempfaengerFailedAction,
    GetWarenempfaengerSucceededAction,
} from '../store/Warenempfaenger.store';
import { Bestellprozesstyp } from '../shared/types/enums';
import type { RootState } from '../configureStore';
import type { Rechnungsempfaenger, Warenempfaenger } from '../shared/types';
import { setRechnungsempfaenger, setWarenempfaenger } from '../store/Bestellung.store';

export const selectRechnungsempaengerList = (rootState: RootState): Rechnungsempfaenger[] =>
    rootState.rechnungsempfaenger.rechnungsempfaenger;
export const selectWarenempaengerList = (rootState: RootState): Warenempfaenger[] => rootState.warenempfaenger.warenempfaenger;
export const selectRechnungsempfaengerLoadFinished = (rootState: RootState): boolean => rootState.rechnungsempfaenger.loadFinished;
export const selectWarenempfaengerLoadFinished = (rootState: RootState): boolean => rootState.warenempfaenger.loadFinished;
export const selectWarenempfaengerPartnerNummerFromBestellung = (rootState: RootState): string =>
    rootState.bestellung.warenempfaengerPartnerNummer;

enum NavigationDirection {
    Forward = 'Forward',
    Backwards = 'Backwards',
}
/*

    1. auswahl-futtertyp
    (2) Rechnungsempfaenger
    3. warenempfaenger
    4. artikel-nutztier
    5. bestelldaten
    6. bestellzusammenfassung

 */
function* checkIfStepShouldBeSkipped(
    nextStep: WorkflowStep,
    direction: NavigationDirection,
    bestellprozesstyp?: Bestellprozesstyp
): SagaGenerator<SelectEffect | PutEffect | TakeEffect | WorkflowStep> {
    if (nextStep === WorkflowStep.AuswahlRechnungsempfaenger) {
        const warenempfaengerSelected = (yield* select(selectWarenempfaengerPartnerNummerFromBestellung)) !== '';
        const rechnungsempfaengerLoaded = yield* select(selectRechnungsempfaengerLoadFinished);

        // Nur wenn der Warenempfänger bereits ausgewählt ist (egal ob manuell durch den Benutzer
        // oder automatisch durch das Workflow modul, können die Rechnungsempfänger geladen werden
        if (warenempfaengerSelected && !rechnungsempfaengerLoaded) {
            yield* put({ type: GET_RECHNUNGSEMPFAENGER });
            const result = yield* take<GetRechnungsempfaengerSucceededAction | GetRechnungsempfaengerFailedAction>([
                GET_RECHNUNGSEMPFAENGER_SUCCEEDED,
                GET_RECHNUNGSEMPFAENGER_FAILED,
            ]);
            if (result && result.type === GET_RECHNUNGSEMPFAENGER_FAILED) {
                // Die Rechungsempfängerauswahl nicht überspringen, damit da ein Fehler angezeigt werden kann.
                return nextStep;
            }
        }
        const rechnungsempfaengerList = yield* select(selectRechnungsempaengerList);
        if (rechnungsempfaengerList && rechnungsempfaengerList.length === 1) {
            // Rechnungsempfänger überspringen:
            nextStep =
                direction === NavigationDirection.Forward
                    ? calcNextWorkflowStep(nextStep, bestellprozesstyp)
                    : calcPreviousWorkflowStep(nextStep, bestellprozesstyp);
            if (direction === NavigationDirection.Forward) {
                yield* put(setRechnungsempfaenger(rechnungsempfaengerList[0].partnerNummer));
            }
        }
    } else if (nextStep === WorkflowStep.AuswahlWarenempfaenger) {
        const warenempfaengerLoaded = yield* select(selectWarenempfaengerLoadFinished);
        if (!warenempfaengerLoaded) {
            yield* put(getWarenempfaenger());
            const result = yield* take<GetWarenempfaengerSucceededAction | GetWarenempfaengerFailedAction>([
                GET_WARENEMPFAENGER_SUCCEEDED,
                GET_WARENEMPFAENGER_FAILED,
            ]);
            if (result && result.type === GET_WARENEMPFAENGER_FAILED) {
                // Die Warenempfängerauswahl nicht überspringen, damit da ein Fehler angezeigt werden kann.
                return nextStep;
            }
        }
        const warenempfaengerList = yield* select(selectWarenempaengerList);
        if (warenempfaengerList && warenempfaengerList.length === 1) {
            // Schritt überspringen:
            nextStep =
                direction === NavigationDirection.Forward
                    ? calcNextWorkflowStep(nextStep, bestellprozesstyp)
                    : calcPreviousWorkflowStep(nextStep, bestellprozesstyp);
            if (direction === NavigationDirection.Forward) {
                yield* put(setWarenempfaenger(warenempfaengerList[0].partnerNummer));
            }
        }
    }
    return nextStep;
}

export function* workflowSaga(
    action: SetCurrentWorkflowStepAction
): SagaGenerator<SelectEffect | PutEffect | TakeEffect | WorkflowStep | undefined> {
    const bestellprozesstyp: Bestellprozesstyp | undefined = yield* select(selectBestellprozesstyp);
    const isBestellprozesstypOkay = bestellprozesstyp !== undefined || action.step === WorkflowStep.AuswahlBestellprozesstyp;
    const isWorkflowStepOkay = bestellprozesstyp === undefined || isAllowedWorkflowStep(bestellprozesstyp, action.step);
    if (!isBestellprozesstypOkay || !isWorkflowStepOkay) {
        action.history.push('/');
        yield* put(setCurrentWorkflowStepSucceeded(WorkflowStep.UNDECIDED, WorkflowStep.UNDECIDED, WorkflowStep.UNDECIDED));
        return;
    }
    // Prüfen ob der nächste Workflow-Schritt übersprungen werden muss
    const nextStep = calcNextWorkflowStep(action.step, bestellprozesstyp);
    let unchangedNext = nextStep;
    let correctedNext =
        isLastWorkflowStep(nextStep) || nextStep === WorkflowStep.UNDECIDED
            ? nextStep
            : yield* checkIfStepShouldBeSkipped(nextStep, NavigationDirection.Forward, bestellprozesstyp);
    while (unchangedNext !== correctedNext) {
        unchangedNext = correctedNext as WorkflowStep;
        correctedNext = yield* checkIfStepShouldBeSkipped(unchangedNext, NavigationDirection.Forward, bestellprozesstyp);
    }

    const previousStep = calcPreviousWorkflowStep(action.step, bestellprozesstyp);
    let unchangedPrevious = previousStep;
    let correctedPrevious =
        isFirstWorkflowStep(previousStep) || previousStep === WorkflowStep.UNDECIDED
            ? previousStep
            : yield* checkIfStepShouldBeSkipped(previousStep, NavigationDirection.Backwards, bestellprozesstyp);
    while (unchangedPrevious !== correctedPrevious) {
        unchangedPrevious = correctedPrevious as WorkflowStep;
        correctedPrevious = yield* checkIfStepShouldBeSkipped(unchangedPrevious, NavigationDirection.Backwards, bestellprozesstyp);
    }

    // Was ist, wenn die obigen Berechnungen zu lange dauern, und der Nutzer schon vorher auf weiter klickt?
    // Dann landet er auf einer falschen Seite.
    yield* put(setCurrentWorkflowStepSucceeded(action.step, correctedNext, correctedPrevious));
    return;
}
