import { useSelector } from 'react-redux';
import { BestelluebersichtSelectors } from '../../../store/Bestelluebersicht.store';
import { FormColumn } from '../../ui-components/Form/FormColumn';
import formStyles from '../../ui-components/Form/Form.module.scss';
import styles from '../../../views/Bestellzusammenfassung/Bestellzusammenfassung.module.scss';

export const WarenempfaengerFormSection = (): JSX.Element => {
    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);

    return (
        <FormColumn isSection>
            <div className={formStyles._sectionHeader}>
                <h3>Warenempfänger</h3>
            </div>
            <div>{bestellung.warenempfaenger?.hauptname}</div>
            <div>{bestellung.warenempfaenger?.strasse}</div>
            <div className={styles._plzOrt}>
                {bestellung.warenempfaenger?.postleitzahl} {bestellung.warenempfaenger?.ort}
            </div>
            {bestellung.vvvoNummer && (
                <>
                    <br />
                    <div>VVVO-Nummer: {bestellung.vvvoNummer}</div>
                </>
            )}
        </FormColumn>
    );
};
