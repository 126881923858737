import { InputHTMLAttributes, useMemo, KeyboardEvent } from 'react';
import { TextInputRule } from '../types/enums';

function isAttemptingSecondDecimalInput(cursorPosition: number | null, textInputValue: string) {
    if (cursorPosition) {
        const commaIndex = textInputValue.indexOf(',');
        const hasComma = commaIndex !== -1;
        if (hasComma) {
            const numberOfDecimalPlaces = textInputValue.length - commaIndex;
            const cursorIsAfterComma = cursorPosition >= commaIndex + 1;

            return cursorIsAfterComma && numberOfDecimalPlaces > 1;
        }
    }
    return false;
}

export default function useTextInputRuleProps(textInputRule: TextInputRule): InputHTMLAttributes<HTMLInputElement> {
    return useMemo(() => {
        const filterInvalidInput = (e: KeyboardEvent): void => {
            const evt = e.target as HTMLInputElement;

            const isSpecialInput = e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab';
            if (isSpecialInput) {
                return;
            }

            const isCommaAllowed =
                textInputRule === TextInputRule.ONLY_POSITIVE_DECIMALS_WITH_ONE_DECIMAL_PLACE && !evt.value.includes(',');
            const isCommaInput = e.key === ',';
            if (isCommaInput && isCommaAllowed) {
                return;
            }

            const isNumberInput = /^\d$/.test(e.key);
            if (isNumberInput && !isAttemptingSecondDecimalInput(evt.selectionStart, evt.value)) {
                return;
            }

            e.preventDefault();
        };

        let textInputRuleProps: InputHTMLAttributes<HTMLInputElement> = {};
        switch (textInputRule) {
            case TextInputRule.ONLY_POSITIVE_INTEGERS:
                textInputRuleProps = { inputMode: 'numeric', min: 0, step: 1, onKeyDown: filterInvalidInput };
                break;
            case TextInputRule.ONLY_POSITIVE_DECIMALS_WITH_ONE_DECIMAL_PLACE:
                textInputRuleProps = { inputMode: 'decimal', onKeyDown: filterInvalidInput };
                break;
            default:
                break;
        }

        return textInputRuleProps;
    }, [textInputRule]);
}
