import { useDispatch, useSelector } from 'react-redux';
import { selectIsDebitor, selectIsWarenempfaenger } from '../../store/Navigation.store';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { ReactElement, useCallback, useState } from 'react';
import { extractDateAndTimeFromDateString, getISOWunschtermin } from '../../shared/helper/date-helper';
import { BestellpositionView, BestellungUpdateRequest } from '../../shared/types';
import { Form, Formik } from 'formik';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { WarenempfaengerFormSection } from '../../shared/content-components/Warenempfaenger/WarenempfaengerFormSection';
import { RechnungsempfaengerFormSection } from '../../shared/content-components/Rechnungsempfaenger/RechnungsempfaengerFormSection';
import { DebitorBestellansichtForm } from './DebitorBestellansichtForm';
import { WarenempfaengerBestellansichtForm } from './WarenempfaengerBestellansichtForm';
import { ArtikelauswahlDropdown } from '../../shared/content-components/Artikelauswahl/ArtikelauswahlDropdown';
import { BestellpositionenNotifications } from '../../shared/content-components/BestellpositionenNotifications/BestellpositionenNotifications';
import { useFetchArtikelLoseWare } from '../../shared/hooks/useFetchArtikelLoseWare';
import * as BestellungenHelper from '../../shared/helper/bestellungen-helper';
import { isLoseWare } from '../../shared/helper/bestellungen-helper';
import { useBestellansichtFormSchema } from './useBestellansichtSchema';
import { updateBestellung } from '../../store/Bestellung.store';
import { useFetchWerkeForArtikel } from '../../shared/hooks/useFetchWerke';
import { BestellansichtButtons } from './BestellansichtButtons';
import { routes } from '../../store/Workflow.store';
import { useHistory } from 'react-router-dom';
import { Bestellpositionen } from '../../shared/content-components/Bestellpositionen/Bestellpositionen';
import { removeFromListOfSelectedArtikel } from '../../store/Artikelauswahl.store';
import { kfzKennzeichenSelectors } from '../../store/KfzKennzeichen.store';
import { mapKfzKennzeichenIdToKfzKennzeichen } from '../../shared/content-components/KfzKennzeichenAuswahl/KfzKennzeichenAuswahl';
import { speditionSelectors } from 'store/Spedition.store';
import { mapSpeditionIdToSpedition } from 'shared/content-components/SpeditionAuswahl/SpeditionAuswahl';
import { ModifikationsstatusLegende } from '../../shared/content-components/Bestellpositionen/PropertyEntries/ModifikationsstatusEntry/ModifikationsstatusLegende';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';
import { useFetchArtikelSackwaren } from '../../shared/hooks/useFetchArtikelSackwaren';
import { Lieferbedingungen } from '../../shared/types/enums';

type BestellansichtFormular = {
    bestellhinweis?: string;
    bestellhinweisWE?: string;
    lieferhinweis?: string;
    lieferbedingung?: string;
    datumDebitor?: string;
    uhrzeitDebitor?: string;
    ansprechpartner?: string;
    kfzKennzeichen?: string;
    spedition?: string;
    datumWEVon?: string;
    uhrzeitWEVon?: string;
    datumWEBis?: string;
    uhrzeitWEBis?: string;
    bestellpositionenView: BestellpositionView[];
    addArticle: undefined;
};

export const BestellansichtAenderbar = (): ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isDebitor = useSelector(selectIsDebitor);
    const isWarenempfaenger = useSelector(selectIsWarenempfaenger);
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [storniereBestellung, setStorniereBestellung] = useState(false);
    const wunschtermin = extractDateAndTimeFromDateString(bestellung.wunschtermin);
    const wunschterminWEVon = extractDateAndTimeFromDateString(bestellung.wunschterminWEVon);
    const wunschterminWEBis = extractDateAndTimeFromDateString(bestellung.wunschterminWEBis);
    const allKfzKennzeichen = useSelector(kfzKennzeichenSelectors.allKfzKennzeichen);
    const initialKennzeichenId = useSelector(kfzKennzeichenSelectors.mapKennzeichenIdFromKennzeichen(bestellung.kfzKennzeichen));
    const allSpeditionen = useSelector(speditionSelectors.allSpeditionen);
    const initialSpeditionId = useSelector(speditionSelectors.mapSpeditionIdFromSpedition(bestellung.spedition));
    const validationSchema = useBestellansichtFormSchema(storniereBestellung);
    const rechnungsempfaengerPartnerNummer = bestellung.rechnungsempfaenger.partnerNummer;
    const warenempfaengerPartnerNummer = bestellung.warenempfaenger.partnerNummer;
    const reloadArtikelLoseWare = useFetchArtikelLoseWare(
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        bestellung.bestellprozesstyp
    );
    const reloadArtikelSackware = useFetchArtikelSackwaren(
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        bestellung.bestellprozesstyp
    );

    const bestellpositionenView: BestellpositionView[] = useSelector(
        BestelluebersichtSelectors.bestellpositionenViewBestellpositionDetails
    );
    const bestellpositionenViewNummern = useSelector(BestelluebersichtSelectors.bestellpositionenViewNummern);

    useFetchWerkeForArtikel(bestellpositionenViewNummern, rechnungsempfaengerPartnerNummer);

    const reloadLoseWareAndSackware = () => {
        reloadArtikelLoseWare();
        reloadArtikelSackware();
    };

    const onArtikelDeleted = (artikelNummer: string): void => {
        dispatch(removeFromListOfSelectedArtikel(artikelNummer));
    };

    const onSubmit = useCallback(
        (values: BestellansichtFormular) => {
            const neueBestellpositionen = values.bestellpositionenView
                .map(BestellungenHelper.mapDateAndTimeToWunschterminForPosition)
                .map(BestellungenHelper.mapBestellpositionenViewToBestellpositionen);

            const wunschterminVon = getISOWunschtermin(values.datumWEVon, values.uhrzeitWEVon);
            const wunschterminBis = getISOWunschtermin(values.datumWEBis, values.uhrzeitWEBis);
            const wunschtermin = getISOWunschtermin(values.datumDebitor, values.uhrzeitDebitor);
            const kfzKennzeichen = mapKfzKennzeichenIdToKfzKennzeichen(allKfzKennzeichen, values.kfzKennzeichen);
            let spedition = mapSpeditionIdToSpedition(allSpeditionen, values.spedition);
            if (values.lieferbedingung == Lieferbedingungen.FRANCO) {
                spedition = '';
            }
            let bestellungUpdate: BestellungUpdateRequest;

            if (isDebitor) {
                bestellungUpdate = {
                    ...bestellung,
                    geschlossen: storniereBestellung,
                    bestellhinweisWE: values.bestellhinweisWE || '',
                    lieferhinweis: values.lieferhinweis || '',
                    rechnungsempfaengerPartnerNummer: bestellung.rechnungsempfaenger.partnerNummer,
                    bestellpositionen: neueBestellpositionen,
                    wunschtermin: wunschtermin || '',
                    wunschterminWEVon: wunschterminVon || '',
                    wunschterminWEBis: wunschterminBis || '',
                    lieferbedingung: values.lieferbedingung || '',
                    ansprechpartner: values.ansprechpartner || '',
                    bestellhinweis: values.bestellhinweis || '',
                    kfzKennzeichen: kfzKennzeichen,
                    spedition: spedition,
                };
            } else {
                bestellungUpdate = {
                    ...bestellung,
                    geschlossen: storniereBestellung,
                    bestellhinweisWE: values.bestellhinweisWE || '',
                    lieferhinweis: values.lieferhinweis || '',
                    rechnungsempfaengerPartnerNummer: bestellung.rechnungsempfaenger.partnerNummer,
                    bestellpositionen: neueBestellpositionen,
                    wunschterminWEVon: wunschterminVon,
                    wunschterminWEBis: wunschterminBis,
                };
            }
            dispatch(updateBestellung(bestellungUpdate));
            history.push(routes.bestelluebermittlung + '/bestellung/update');
        },
        [allKfzKennzeichen, allSpeditionen, bestellung, dispatch, history, isDebitor, storniereBestellung]
    );

    const initialValues: BestellansichtFormular = isDebitor
        ? {
              datumWEVon: wunschterminWEVon?.datum ?? '',
              datumWEBis: wunschterminWEBis?.datum ?? '',
              uhrzeitWEVon: wunschterminWEVon?.uhrzeit ?? '',
              uhrzeitWEBis: wunschterminWEBis?.uhrzeit ?? '',
              datumDebitor: wunschtermin?.datum ?? '',
              uhrzeitDebitor: wunschtermin?.uhrzeit ?? '',
              bestellpositionenView: bestellpositionenView,
              lieferbedingung: bestellung.lieferbedingung,
              lieferhinweis: bestellung.lieferhinweis,
              kfzKennzeichen: initialKennzeichenId,
              spedition: initialSpeditionId,
              bestellhinweis: bestellung.bestellhinweis,
              ansprechpartner: bestellung.ansprechpartner,
              bestellhinweisWE: bestellung.bestellhinweisWE,
              addArticle: undefined,
          }
        : {
              datumWEVon: wunschterminWEVon?.datum ?? '',
              datumWEBis: wunschterminWEBis?.datum ?? '',
              uhrzeitWEVon: wunschterminWEVon?.uhrzeit ?? '',
              uhrzeitWEBis: wunschterminWEBis?.uhrzeit ?? '',
              kfzKennzeichen: bestellung.kfzKennzeichen,
              spedition: bestellung.spedition,
              ansprechpartner: bestellung.ansprechpartner,
              datumDebitor: '',
              bestellpositionenView: bestellpositionenView,
              lieferhinweis: bestellung.lieferhinweis,
              lieferbedingung: bestellung.lieferbedingung,
              bestellhinweisWE: bestellung.bestellhinweisWE,
              addArticle: undefined,
          };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            <Form>
                <FormRow>
                    <WarenempfaengerFormSection />
                    <RechnungsempfaengerFormSection />
                </FormRow>
                {isDebitor ? <DebitorBestellansichtForm /> : <WarenempfaengerBestellansichtForm />}
                {isWarenempfaenger ? <ArtikelauswahlDropdown showOnlySackWare={isDebitor} /> : null}
                <BestellpositionenNotifications reloadArtikelCallback={reloadLoseWareAndSackware} triedToSubmit={triedToSubmit} />
                <Bestellpositionen onArtikelDeleted={onArtikelDeleted} />
                {isDebitor && !isMobile && bestellpositionenView.some(isLoseWare) ? <ModifikationsstatusLegende /> : null}
                <BestellansichtButtons setTriedToSubmit={setTriedToSubmit} setStorniereBestellung={setStorniereBestellung} />
            </Form>
        </Formik>
    );
};
