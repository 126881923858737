import { CellProps, Column } from 'react-table';
import TextInputWithHookForms from '../../../../../shared/ui-components/TextInput/TextInputWithHookForms';
import TableCell from '../../../../../shared/ui-components/Table/ReactTable/TableCell';
import { useFormContext } from 'react-hook-form';
import { getErrorMessageForPropertyFieldError } from '../../../error-helper';
import { SackwareColumns } from '../ColumnEnums';
import { TextInputRule } from '../../../../../shared/types/enums';
import type { TourenplanForm, TourenplanSackwareBestellung } from '../../../TourenplanTypes';
import { BestellungDetails } from '../../../../../shared/types';

const MengePalColumnCell = (cellProps: CellProps<BestellungDetails, TourenplanSackwareBestellung>): JSX.Element => {
    const { mengePal } = cellProps.value;
    const rowIndex = cellProps.row.index;
    const cell = cellProps.cell;

    const { formState } = useFormContext<TourenplanForm>();
    const { errors } = formState;
    const mengePalErrorMessage = getErrorMessageForPropertyFieldError(errors?.sackwareBestellungen?.[rowIndex], 'mengePal');

    return (
        <TableCell cell={cell} input>
            <TextInputWithHookForms
                data-testid="tourenplan-mengepal-input"
                data-cy="sw-menge-pal"
                defaultValue={mengePal}
                textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                name={`sackwareBestellungen.${rowIndex}.mengePal`}
                size={2}
                hasError={Boolean(mengePalErrorMessage)}
                errorMessage={mengePalErrorMessage ?? ''}
                inTableCell
            />
        </TableCell>
    );
};

export const mengePalColumnConfig: Column<TourenplanSackwareBestellung> = {
    id: 'mengePal',
    Header: SackwareColumns.MengePalColumnHeader,
    accessor: (bestellung): Partial<TourenplanSackwareBestellung> => {
        return {
            bestellnummer: bestellung.bestellnummer,
            mengePal: bestellung.mengePal,
            nummer: bestellung.nummer,
        };
    },
    Cell: MengePalColumnCell,
};
