import tableStyles from '../../ui-components/Table/Table.module.scss';
import { LieferungPosition } from '../../types';
import { translateEinheit } from '../../helper/lieferungenHelper';

export type Props = Readonly<{
    positionen: LieferungPosition[];
    isDebitor: boolean;
}>;

const LieferungPositionenTable = ({ positionen, isDebitor }: Props): JSX.Element => {
    const hasKontrakte = positionen.some((p) => p.kontrakt);

    return (
        <table className={tableStyles._table}>
            <thead>
                <tr className={tableStyles._tableHeaderRow}>
                    <th className={tableStyles['_tableCell--text']}>Artikelnr.</th>
                    <th className={tableStyles['_tableCell--text']}>Bezeichnung</th>
                    <th className={tableStyles['_tableCell--number']}>Menge</th>
                    {isDebitor && hasKontrakte && <th className={tableStyles['_tableCell--text']}>Kontrakt</th>}
                </tr>
            </thead>
            <tbody>
                {positionen.map((position, index) => {
                    return (
                        <tr className={tableStyles._tableBodyRow} key={index}>
                            <td className={[tableStyles._tableCell, tableStyles['_tableCell--text']].join(' ')}>
                                {position.artikelnummer}
                            </td>
                            <td className={[tableStyles._tableCell, tableStyles['_tableCell--text']].join(' ')}>{position.bezeichnung}</td>
                            <td className={[tableStyles._tableCell, tableStyles['_tableCell--number']].join(' ')}>
                                {position.lieferungMenge || position.auftragMenge}{' '}
                                {translateEinheit(position.lieferungEinheit || position.auftragEinheit)}
                            </td>
                            {isDebitor && hasKontrakte && (
                                <td className={[tableStyles._tableCell, tableStyles['_tableCell--text']].join(' ')}>{position.kontrakt}</td>
                            )}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default LieferungPositionenTable;
