import { RequestState } from '../shared/types/enums';
import type { Filter, KontraktDetails, UebersichtKontrakt } from '../shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emptyKontraktDetails } from '../shared/types/defaultValues';
import type { RootState } from '../configureStore';

export interface GetKontraktAction {
    kontraktNummer: string;
}

export interface GetKontrakteAction {
    filter?: Filter;
}

export interface GetKontrakteSucceededAction {
    kontrakte: UebersichtKontrakt[];
}

export type KontraktUebersichtState = {
    kontrakte: UebersichtKontrakt[];
    currentKontraktLoadingState: RequestState;
    kontrakteLoadingState: RequestState;
    currentKontrakt: KontraktDetails;
};
export const INITIAL_KONTRAKTUEBERSICHT_STATE: KontraktUebersichtState = {
    kontrakte: [],
    kontrakteLoadingState: RequestState.INITIAL,
    currentKontraktLoadingState: RequestState.INITIAL,
    currentKontrakt: emptyKontraktDetails,
};

export const KontraktuebersichtSelectors = {
    kontrakte: (state: RootState): UebersichtKontrakt[] => state.kontraktuebersicht.kontrakte,
    kontrakteLoading: (state: RootState): boolean => state.kontraktuebersicht.kontrakteLoadingState === RequestState.LOADING,
    kontrakteLoadFailed: (state: RootState): boolean => state.kontraktuebersicht.kontrakteLoadingState === RequestState.FAILED,
    currentKontrakt: (state: RootState): KontraktDetails => state.kontraktuebersicht.currentKontrakt,
    currentKontraktIsLoading: (state: RootState): boolean => state.kontraktuebersicht.currentKontraktLoadingState === RequestState.LOADING,
    currentKontraktFailed: (state: RootState): boolean => state.kontraktuebersicht.currentKontraktLoadingState === RequestState.FAILED,
};

const kontraktuebersichtSlice = createSlice({
    name: 'kontraktuebersicht',
    initialState: INITIAL_KONTRAKTUEBERSICHT_STATE,
    reducers: {
        getKontrakt(state, _: PayloadAction<GetKontraktAction>) {
            state.currentKontraktLoadingState = RequestState.LOADING;
            state.currentKontrakt = emptyKontraktDetails;
        },
        getKontraktSucceeded(state, action: PayloadAction<KontraktDetails>) {
            state.currentKontrakt = action.payload;
            state.currentKontraktLoadingState = RequestState.SUCCESSFUL;
        },
        getKontraktFailed(state, _: PayloadAction) {
            state.currentKontraktLoadingState = RequestState.FAILED;
        },
        getKontrakte(state, _: PayloadAction<GetKontrakteAction>) {
            state.kontrakteLoadingState = RequestState.LOADING;
        },
        getKontrakteSucceeded(state, action: PayloadAction<GetKontrakteSucceededAction>) {
            const { kontrakte } = action.payload;
            state.kontrakte = kontrakte;
            state.kontrakteLoadingState = RequestState.SUCCESSFUL;
        },
        getKontrakteFailed(state, _: PayloadAction) {
            state.kontrakteLoadingState = RequestState.FAILED;
        },
    },
});

export const { getKontrakt, getKontraktSucceeded, getKontraktFailed, getKontrakte, getKontrakteSucceeded, getKontrakteFailed } =
    kontraktuebersichtSlice.actions;
export const kontraktuebersichtReducer = kontraktuebersichtSlice.reducer;
