import { confirmAlert, ReactConfirmAlertProps } from 'react-confirm-alert';
import dialogStyles from './confirmationDialog.module.scss';
import Button from '../Button/Button';
import { ReactElement, ReactNode } from 'react';

type ConfirmationDialogParams = {
    title?: string;
    message: string | ReactElement;
    okButtonText?: string;
    onOkCallback: () => void;
    onCloseCallback?: () => void;
    okIsPrimaryButton?: boolean;
    additionalButtonCallback?: (onClose: () => void) => ReactNode | undefined;
} & Pick<ReactConfirmAlertProps, 'onClickOutside'>;

export function showConfirmationDialog({
    title = 'Sind Sie sicher?',
    message,
    okButtonText = 'OK',
    onCloseCallback,
    onOkCallback,
    onClickOutside,
    okIsPrimaryButton = true,
    additionalButtonCallback,
}: ConfirmationDialogParams): void {
    confirmAlert({
        onClickOutside: onClickOutside,
        customUI: ({ onClose }): ReactElement => {
            let additionalButton: ReactNode | undefined;
            if (additionalButtonCallback) {
                additionalButton = additionalButtonCallback(onClose);
            }

            return (
                <div className={['custom-ui', dialogStyles._dialog].join(' ')}>
                    <h2>{title}</h2>
                    <p>{message}</p>
                    <div className={dialogStyles._dialog__buttons}>
                        <Button
                            isSecondary={okIsPrimaryButton}
                            onClick={() => {
                                if (onCloseCallback) {
                                    onCloseCallback();
                                }
                                onClose();
                            }}
                        >
                            Abbrechen
                        </Button>
                        {additionalButton}
                        <Button
                            isSecondary={!okIsPrimaryButton}
                            onClick={(): void => {
                                onOkCallback();
                                onClose();
                            }}
                        >
                            {okButtonText}
                        </Button>
                    </div>
                </div>
            );
        },
    });
}
