import { TextareaHTMLAttributes, useMemo } from 'react';
import inputStyles from '../TextInput/TextInput.module.scss';
import textareaStyles from './Textarea.module.scss';
import { useFormContext } from 'react-hook-form';

type AllOptionalInputProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
type RequiredInputProps = Required<Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, 'name'>>;

type TextareaWithHookFormsProps = AllOptionalInputProps &
    RequiredInputProps & {
        hasError?: boolean;
        inTable?: boolean;
        resizable?: boolean;
        allowEditAfterSubmit?: boolean;
    };

const TextareaWithHookForms = (props: TextareaWithHookFormsProps): JSX.Element => {
    const { hasError, resizable, allowEditAfterSubmit, inTable, ...remainingProps } = props;
    const { register, formState, getValues } = useFormContext();
    const { isSubmitSuccessful } = formState;

    const styles = useMemo((): string => {
        const styles = [inputStyles._input, textareaStyles._textarea];
        if (inTable) {
            styles.push(textareaStyles['_textarea--inTableCell']);
        }

        if (hasError) {
            styles.push(inputStyles['_input--error']);
        }

        if (!resizable) {
            styles.push(textareaStyles['_textarea--non-resizeable']);
        }
        return styles.join(' ');
    }, [hasError, inTable, resizable]);

    const printStyles = useMemo((): string => {
        const styles = [textareaStyles._print];
        if (inTable) {
            styles.push(textareaStyles['_print--inTableCell']);
        }
        return styles.join(' ');
    }, [inTable]);

    return (
        <>
            <div className={printStyles}>{getValues(props.name)}</div>
            <textarea
                {...register(props.name)}
                {...remainingProps}
                className={styles}
                readOnly={isSubmitSuccessful && !allowEditAfterSubmit}
            />
        </>
    );
};

export default TextareaWithHookForms;
