import { Children, useCallback, useRef } from 'react';
import { MenuListComponentProps } from 'react-select/src/components/Menu';
import { OptionTypeBase } from 'react-select';
import { useVirtual } from 'react-virtual';
import menuStyle from './MenuList.module.scss';

export const MenuList = ({ selectProps, children }: MenuListComponentProps<OptionTypeBase, false>): JSX.Element => {
    const parentRef = useRef(null);
    const { width } = selectProps;
    const rows = Children.toArray(children);

    // NOTE: One item as the approximate size of 35px
    const rowVirtualizer = useVirtual({
        size: rows.length || 0,
        parentRef,
        estimateSize: useCallback(() => 35, []),
    });

    const totalSize = {
        height: `${rowVirtualizer.totalSize}px`,
    };

    return (
        <div
            className={[
                menuStyle._virtualListParent,
                rows.length <= 5 ? menuStyle['_virtualListParent--full'] : menuStyle['_virtualListParent--scroll'],
            ].join(' ')}
            style={{ width }}
            ref={parentRef}
        >
            <div className={menuStyle._virtualListContainer} style={totalSize}>
                {rowVirtualizer.virtualItems.map((virtualRow) => {
                    const virtualItemStyle = {
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                    };
                    return (
                        <div key={virtualRow.index} className={menuStyle._virtualItem} style={virtualItemStyle}>
                            {rows[virtualRow.index]}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
