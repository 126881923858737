import { NEWID_PREFIX } from '../../../../constants';
import { TableCell } from '../../../../ui-components/Table';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import type { PropertyEntryReadonlyProps } from '../types';
import { isSackwareView } from '../../../../helper/bestellungen-helper';
import { Artikelaustausch } from '../../../Artikelaustausch/Artikelaustausch';
import styles from './BezeichnungEntry.module.scss';
import { Breakpoint, useBreakpoint } from '../../../../hooks/useBreakpoint';

export const BezeichnungTableCell = ({
    bestellposition,
    disabled = false,
    readonly = false,
    replaceable = false,
}: PropertyEntryReadonlyProps & { replaceable?: boolean }): JSX.Element => {
    const { storno, nummer, bezeichnung, index } = bestellposition;
    const isNewArtikel = bestellposition.nummer?.startsWith(NEWID_PREFIX);
    const isTabletPortrait = useBreakpoint(Breakpoint.TABLET_PORTRAIT);

    if (isSackwareView(bestellposition)) {
        if (isTabletPortrait) {
            return (
                <>
                    <TableCell text storniert={storno}>
                        {nummer}
                    </TableCell>
                    <TableCell text storniert={storno}>
                        {bezeichnung}
                    </TableCell>
                </>
            );
        }

        return (
            <TableCell text storniert={storno}>
                <span>{bezeichnung}</span>
                <br />
                <span className={styles._subInfo}>{nummer}</span>
            </TableCell>
        );
    }

    if (readonly || bestellposition.storno || disabled) {
        const { zulage, zulageText } = bestellposition;
        const isNummerWithNewPrefix = nummer.startsWith('NEW-');
        return (
            <TableCell text storniert={storno}>
                {isNummerWithNewPrefix ? <span /> : <span>{nummer}</span>}
                <span>&nbsp;{bezeichnung}</span>
                {zulage && (
                    <>
                        <br />
                        <span>Zulage: </span>
                        <span>{zulageText}</span>
                    </>
                )}
            </TableCell>
        );
    }

    if (isNewArtikel) {
        return (
            <TableCell text wideInput storniert={storno}>
                <TextInput
                    disabled={disabled}
                    data-cy={'lw-new-artikel'}
                    id={`bestellpositionenView[${index}].bezeichnung`}
                    name={`bestellpositionenView[${index}].bezeichnung`}
                    storniert={storno}
                    autoFocus
                    inTableCell
                />
            </TableCell>
        );
    }

    if (replaceable) {
        return (
            <TableCell select additionalStyles={[styles._searchInput]}>
                <Artikelaustausch bestellposition={bestellposition} inTableCell />
            </TableCell>
        );
    }

    return (
        <TableCell text storniert={storno}>
            <span>{nummer}</span>
            <span>&nbsp;{bezeichnung}</span>
        </TableCell>
    );
};
