import { Row, TableInstance } from 'react-table';
import tableStyles from '../Table/Table.module.scss';
import localStyles from './OverviewList.module.scss';
import { OverviewListBody } from './OverviewListBody';

type OverviewListProps<T extends Record<string, unknown>> = {
    tableInstance: TableInstance<T>;
    mobileColumns: string[];
    onRowClick?: (row: Row<T>) => void;
    headerColumns?: string[];
};

export function OverviewList<T extends Record<string, unknown>>(props: OverviewListProps<T>): JSX.Element {
    const { tableInstance, onRowClick, mobileColumns, headerColumns } = props;
    const { headerGroups } = tableInstance;

    const columns = headerColumns ?? mobileColumns;

    return (
        <div className={tableStyles._overviewContainer}>
            <div>
                {headerGroups.map((headerGroup, index) => (
                    <div {...headerGroup.getHeaderGroupProps()} className={tableStyles._overviewHeaderRow} key={index}>
                        {headerGroup.headers
                            .filter((c) => columns.includes(c.id))
                            .map((column, i) => {
                                const headerClasses = column.isSorted
                                    ? [localStyles._sortHeader, localStyles['_sortHeader--active']]
                                    : [localStyles._sortHeader];
                                return (
                                    <span
                                        className={headerClasses.join(' ')}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={i}
                                    >
                                        {column.render('Header')}
                                        <span className={tableStyles._tableSortElement}>
                                            {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                                        </span>
                                    </span>
                                );
                            })}
                    </div>
                ))}
                <OverviewListBody tableInstance={tableInstance} mobileColumns={mobileColumns} onRowClick={onRowClick} />
            </div>
        </div>
    );
}

export default OverviewList;
