import { useSelector } from 'react-redux';
import FormControl from '../../ui-components/FormControl/FormControl';
import Textarea from '../../ui-components/Textarea/Textarea';
import { selectIsWarenempfaenger } from '../../../store/Navigation.store';
import { PropsWithChildren, ReactElement } from 'react';

export const FormikBestellhinweisInput = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const isWarenempfaenger = useSelector(selectIsWarenempfaenger);

    return (
        <FormControl
            name={isWarenempfaenger ? 'bestellhinweisWE' : 'bestellhinweis'}
            id={isWarenempfaenger ? 'bestellhinweisWE' : 'bestellhinweis'}
            label="Bestellhinweis"
            allowChildToGrow={true}
            renderChild={(props): ReactElement => {
                return (
                    <>
                        <Textarea data-cy={'form-bestellhinweis'} maxLength={250} {...props} />
                        {children}
                    </>
                );
            }}
        />
    );
};
