import { minDate } from '../../helper/date-helper';
import formStyles from '../../ui-components/Form/Form.module.scss';
import FormControl from '../../ui-components/FormControl/FormControl';
import TextInput from '../../ui-components/TextInput/TextInput';
import SelectInput from '../../ui-components/SelectInput/SelectInput';
import { Option, tageszeitOptions } from '../../helper/select-options';
import { BaseProps } from '../../ui-components/FormControl';
import { WunschterminLabels } from './labels';
import { useFormikContext } from 'formik';
import { ArtikelNutztierfutterFormular } from '../../helper/artikel-helper';
import NotificationBar from '../../ui-components/NotificationBar/NotificationBar';
import { BestelldatenFormular } from '../../types';

const createDateTextInput = (props: BaseProps): JSX.Element => <TextInput {...props} type="date" placeholder="jjjj-mm-tt" min={minDate} />;

const createDateSelectInput = (props: BaseProps): JSX.Element => (
    <SelectInput<Option>
        {...props}
        options={tageszeitOptions}
        placeholder={WunschterminLabels.SELECT_PLACEHOLDER}
        classNamePrefix="react_select__form-tageszeit"
        isClearable={false}
        autoSelectIfOnlyOneOption
    />
);

const WunschterminZeitraumErrors = (): JSX.Element | null => {
    const { errors, touched } = useFormikContext<ArtikelNutztierfutterFormular | BestelldatenFormular>();
    if (errors.datumWEVon && touched.datumWEVon) {
        return <NotificationBar message={errors.datumWEVon} isVisible />;
    }
    if (errors.datumWEBis && touched.datumWEBis) {
        return <NotificationBar message={errors.datumWEBis} isVisible />;
    }
    if (errors.uhrzeitWEBis && touched.uhrzeitWEBis) {
        return <NotificationBar message={errors.uhrzeitWEBis} isVisible />;
    }

    return null;
};

const WunschterminZeitraum = (): JSX.Element => {
    return (
        <>
            <WunschterminZeitraumErrors />
            <div data-cy={'form-lw-wunschtermin'} className={`${formStyles._terminContainer} ${formStyles['_terminContainer--zeitraum']}`}>
                <FormControl
                    name="datumWEVon"
                    id="datumWEVon"
                    label={`${WunschterminLabels.ABHOL_BZW_LIEFERTERMIN} (von) *`}
                    renderChild={createDateTextInput}
                    displayError={false}
                />
                <FormControl
                    name="uhrzeitWEVon"
                    id="uhrzeitWEVon"
                    label={WunschterminLabels.TAGESZEIT}
                    renderChild={createDateSelectInput}
                    displayError={false}
                />
                <FormControl
                    name="datumWEBis"
                    id="datumWEBis"
                    label={`${WunschterminLabels.ABHOL_BZW_LIEFERTERMIN} (bis)`}
                    renderChild={createDateTextInput}
                    displayError={false}
                />
                <FormControl
                    name="uhrzeitWEBis"
                    id="uhrzeitWEBis"
                    label={WunschterminLabels.TAGESZEIT}
                    renderChild={createDateSelectInput}
                    displayError={false}
                />
            </div>
        </>
    );
};

export { WunschterminZeitraum };
