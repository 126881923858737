import { Buffer, CellRichTextValue, CellValue, Column, CsvWriteOptions, RichText, Workbook } from 'exceljs';
import { BorderOptions, ExcelGenerator } from '../../../../shared/excel/ExcelGenerator';
import { LoseWareColumns, SackwareColumns } from '../../tables/columns/ColumnEnums';
import type { TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from '../../TourenplanTypes';
import type { Rechnungsempfaenger } from '../../../../shared/types';
import type { TourenplanFormWithRechnungsempfaengerList } from '../../TourenplanBestelluebermittlung';
import { Lieferbedingungen, LieferbedingungShortLabel } from '../../../../shared/types/enums';
import { NEWID_PREFIX } from '../../../../shared/constants';

export enum TourenplanColumns {
    A = 1,
    B,
    C,
    D,
    E,
    F,
    G,
    H,
    I,
    J,
    K,
    L,
    M,
    N,
    O,
    P,
}

// TODO: write tests
class TourenplanExcelGenerator<DataType extends TourenplanFormWithRechnungsempfaengerList> extends ExcelGenerator<
    DataType,
    TourenplanColumns
> {
    loseWareTableStartRow: number;
    sackwareTableStartRow: number;
    tourenplanDetailsStartRow: number;
    SPACING = 2;

    constructor(data: DataType) {
        super(data);
        this.tourenplanDetailsStartRow = this.SPACING;
        this.loseWareTableStartRow = this.tourenplanDetailsStartRow + this.data.rechnungsempfaenger.length + this.SPACING + this.SPACING;
        this.sackwareTableStartRow = this.loseWareTableStartRow + this.data.loseWareBestellungen.length + this.SPACING + this.SPACING;
    }

    createTourenplanColumns(): void {
        const COLUMN_WIDTHS = [20, 20, 20, 10, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 20, 20];
        const rows = this.createRowConfig<number>(COLUMN_WIDTHS, TourenplanColumns);
        this.worksheet.columns = Object.keys(TourenplanColumns)
            .filter((colKey) => isNaN(Number(colKey)))
            .map((colKey): Partial<Column> => {
                return {
                    key: TourenplanColumns[colKey],
                    width: rows[TourenplanColumns[colKey]],
                };
            });
    }

    fillFirstRowWithKesselverteilungNumbers(): void {
        const loseWareHeaderKesselVerteilungConfig = this.createRowConfig<number | string>(
            ['', '', '', '', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '', ''],
            TourenplanColumns
        );
        this.worksheet.insertRow(this.loseWareTableStartRow + 1, loseWareHeaderKesselVerteilungConfig);
    }

    combineLoseWareColumnsAndKesselverteilungNumbersToOneHeader(): void {
        // merge row 2 and row 3
        this.mergeCellsHorizontal(TourenplanColumns.E, TourenplanColumns.N, this.loseWareTableStartRow);
        this.mergeCellsVertical(TourenplanColumns.A, this.loseWareTableStartRow, this.loseWareTableStartRow + 1);
        this.mergeCellsVertical(TourenplanColumns.B, this.loseWareTableStartRow, this.loseWareTableStartRow + 1);
        this.mergeCellsVertical(TourenplanColumns.C, this.loseWareTableStartRow, this.loseWareTableStartRow + 1);
        this.mergeCellsVertical(TourenplanColumns.D, this.loseWareTableStartRow, this.loseWareTableStartRow + 1);
        this.mergeCellsVertical(TourenplanColumns.O, this.loseWareTableStartRow, this.loseWareTableStartRow + 1);
        this.mergeCellsVertical(TourenplanColumns.P, this.loseWareTableStartRow, this.loseWareTableStartRow + 1);
    }

    createWarenempfaengerCell(loseWareBestellung: TourenplanLoseWareBestellung | TourenplanSackwareBestellung): CellValue {
        const { hauptname, namenszusatz, nebenname, postleitzahl, ort } = loseWareBestellung.warenempfaenger;
        const richText = [
            this.createRichText(hauptname, false),
            this.createRichText(nebenname),
            this.createRichText(namenszusatz),
            this.createRichText(`${postleitzahl} ${ort}`, true, {
                size: 8,
                color: { argb: '6E6E6E' },
                name: 'Arial Narrow',
            }),
        ];

        return {
            richText,
        };
    }

    createRechnungsempfaengerCell(rechnungsempfaenger: Rechnungsempfaenger): CellValue {
        const { hauptname, nebenname, postleitzahl, ort, strasse } = rechnungsempfaenger;

        const richText = [
            this.createRichText(hauptname, false),
            this.createRichText(nebenname),
            this.createRichText(`${postleitzahl} ${ort} ${strasse}`, true, {
                size: 8,
                color: { argb: '6E6E6E' },
                name: 'Arial Narrow',
            }),
        ];

        return {
            richText,
        };
    }

    createRichTextForGeneralBestellung(bestellung: TourenplanLoseWareBestellung | TourenplanSackwareBestellung): RichText[] {
        const { nummer, bezeichnung } = bestellung;
        return [
            this.createRichText(nummer.startsWith(NEWID_PREFIX) ? '' : nummer, false),
            this.createRichText(bezeichnung, true, {
                size: 8,
                color: { argb: '6E6E6E' },
                name: 'Arial Narrow',
            }),
        ];
    }

    createSackwareArtikelCell(bestellung: TourenplanSackwareBestellung): CellRichTextValue {
        return {
            richText: this.createRichTextForGeneralBestellung(bestellung),
        };
    }

    createLoseWareArtikelCell(bestellung: TourenplanLoseWareBestellung): CellRichTextValue {
        const { zulage, zulageText } = bestellung;
        return {
            richText: [
                ...this.createRichTextForGeneralBestellung(bestellung),
                this.createRichText(zulage ? `Zulage: ${zulageText}` : '', true, {
                    size: 8,
                    color: { argb: '6E6E6E' },
                    name: 'Arial Narrow',
                }),
            ],
        };
    }

    createLoseWareTableHeader(): void {
        const loseWareHeaderKesselVerteilungConfig = this.createRowConfig<string>(
            [
                LoseWareColumns.WarenempfaengerColumnHeader,
                LoseWareColumns.ArtikelColumnHeader,
                LoseWareColumns.WerkColumnHeader,
                LoseWareColumns.MengeInTonnenColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.KesselColumnHeader,
                LoseWareColumns.SiloColumnHeader,
                LoseWareColumns.LieferhinweisColumnHeader,
            ],
            TourenplanColumns
        );
        this.worksheet.insertRow(this.loseWareTableStartRow, loseWareHeaderKesselVerteilungConfig);
        this.fillFirstRowWithKesselverteilungNumbers();
        this.combineLoseWareColumnsAndKesselverteilungNumbersToOneHeader();

        const loseWareHeaderRow = this.worksheet.getRow(this.loseWareTableStartRow);
        loseWareHeaderRow.eachCell((cell, index) => {
            this.setTableHeaderStyle(cell);
            if (index < TourenplanColumns.E || index > TourenplanColumns.N) {
                this.setCellBorder(cell);
            } else {
                this.setCellBorder(cell, BorderOptions.top);
            }
        });

        this.styleRow(loseWareHeaderRow, {
            height: 24,
        });

        const kesselVerteilungHeaderRow = this.worksheet.getRow(this.loseWareTableStartRow + 1);
        kesselVerteilungHeaderRow.eachCell((cell, index) => {
            this.setTableHeaderStyle(cell);
            if (index < TourenplanColumns.E || index > TourenplanColumns.N) {
                this.setCellBorder(cell);
            } else {
                this.setCellBorder(cell, BorderOptions.bottom);
            }
        });
    }

    createLoseWareTableBody(): void {
        this.data.loseWareBestellungen?.forEach((loseWareBestellung: TourenplanLoseWareBestellung) => {
            const loseWareBestellpositionRowContent = this.createRowConfig(
                [
                    this.createWarenempfaengerCell(loseWareBestellung),
                    this.createLoseWareArtikelCell(loseWareBestellung),
                    loseWareBestellung.werk,
                    loseWareBestellung.menge,
                    loseWareBestellung.kesselverteilung?.[0],
                    loseWareBestellung.kesselverteilung?.[1],
                    loseWareBestellung.kesselverteilung?.[2],
                    loseWareBestellung.kesselverteilung?.[3],
                    loseWareBestellung.kesselverteilung?.[4],
                    loseWareBestellung.kesselverteilung?.[5],
                    loseWareBestellung.kesselverteilung?.[6],
                    loseWareBestellung.kesselverteilung?.[7],
                    loseWareBestellung.kesselverteilung?.[8],
                    loseWareBestellung.kesselverteilung?.[9],
                    loseWareBestellung.silo,
                    loseWareBestellung.lieferhinweis,
                ],
                TourenplanColumns
            );

            const loseWareBestellpositionRow = this.worksheet.addRow(loseWareBestellpositionRowContent);

            loseWareBestellpositionRow.eachCell({ includeEmpty: true }, (cell, index) => {
                if (index === TourenplanColumns.A || index === TourenplanColumns.B) {
                    this.styleCellInColumn(index, loseWareBestellpositionRow, {
                        alignment: {
                            wrapText: true,
                            vertical: 'middle',
                            horizontal: 'left',
                        },
                    });
                } else if (index >= TourenplanColumns.C && index <= TourenplanColumns.N) {
                    this.styleCellInColumn(index, loseWareBestellpositionRow, {
                        alignment: {
                            vertical: 'middle',
                            horizontal: 'center',
                        },
                    });
                } else if (index === TourenplanColumns.O || index === TourenplanColumns.P) {
                    this.styleCellInColumn(index, loseWareBestellpositionRow, {
                        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
                    });
                }

                if (index < loseWareBestellpositionRow.cellCount) {
                    this.setCellBorder(cell, BorderOptions.bottom | BorderOptions.right);
                } else {
                    this.setCellBorder(cell, BorderOptions.bottom);
                }
            });
        });
    }

    createSackwareTableHeader(): void {
        const EMPTY_COLUMNS = ['', '', '', '', '', '', '', '', '', ''];
        const sackwareHeaderConfig = this.createRowConfig<string>(
            [
                SackwareColumns.WarenempfaengerColumnHeader,
                SackwareColumns.ArtikelColumnHeader,
                SackwareColumns.WerkColumnHeader,
                SackwareColumns.MengeVeColumnHeader,
                SackwareColumns.MengePalColumnHeader,
                ...EMPTY_COLUMNS,
                SackwareColumns.LieferhinweisColumnHeader,
            ],
            TourenplanColumns
        );

        const sackwareHeaderRow = this.worksheet.insertRow(this.sackwareTableStartRow, sackwareHeaderConfig);
        sackwareHeaderRow.eachCell((cell) => {
            this.setTableHeaderStyle(cell);
            this.setCellBorder(cell);
        });

        this.styleRow(sackwareHeaderRow, {
            height: 24,
        });
    }

    createSackwareTableBody(): void {
        const EMPTY_COLUMNS = ['', '', '', '', '', '', '', '', '', ''];
        this.data.sackwareBestellungen?.forEach((sackwareBestellung: TourenplanSackwareBestellung) => {
            const sackwareBestellpositionRowContent = this.createRowConfig(
                [
                    this.createWarenempfaengerCell(sackwareBestellung),
                    this.createSackwareArtikelCell(sackwareBestellung),
                    sackwareBestellung.werk,
                    sackwareBestellung.mengeVe,
                    sackwareBestellung.mengePal,
                    ...EMPTY_COLUMNS,
                    sackwareBestellung.lieferhinweis,
                ],
                TourenplanColumns
            );
            const sackwareBestellpositionRow = this.worksheet.addRow(sackwareBestellpositionRowContent);

            sackwareBestellpositionRow.eachCell({ includeEmpty: true }, (cell, index) => {
                if (index === TourenplanColumns.A || index === TourenplanColumns.B) {
                    this.styleCellInColumn(index, sackwareBestellpositionRow, {
                        alignment: {
                            wrapText: true,
                            vertical: 'middle',
                            horizontal: 'left',
                        },
                    });
                } else if (index >= TourenplanColumns.C && index <= TourenplanColumns.E) {
                    this.styleCellInColumn(index, sackwareBestellpositionRow, {
                        alignment: {
                            vertical: 'middle',
                            horizontal: 'center',
                        },
                    });
                } else if (index === TourenplanColumns.P) {
                    this.styleCellInColumn(index, sackwareBestellpositionRow, {
                        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
                    });
                }

                if (index < sackwareBestellpositionRow.cellCount) {
                    this.setCellBorder(cell, BorderOptions.bottom | BorderOptions.right);
                } else {
                    this.setCellBorder(cell, BorderOptions.bottom);
                }
            });
        });
    }

    createTourenplanDetails(): void {
        const tourenplanDetailRows = [];
        tourenplanDetailRows.push(
            this.worksheet.insertRow(2, {
                [TourenplanColumns.A]: 'Datum',
                [TourenplanColumns.B]: this.data.datumDebitor,
                [TourenplanColumns.C]: 'Uhrzeit',
                [TourenplanColumns.D]: this.data.uhrzeitDebitor,
                [TourenplanColumns.E]: 'Rechnungsempfänger',
            })
        );

        const lieferart =
            this.data.lieferbedingung === Lieferbedingungen.EX_WERK ? LieferbedingungShortLabel.EX_WERK : LieferbedingungShortLabel.FRANCO;

        tourenplanDetailRows.push(
            this.worksheet.addRow({
                [TourenplanColumns.A]: 'Lieferart',
                [TourenplanColumns.B]: lieferart,
                [TourenplanColumns.C]: 'Kennzeichen',
                [TourenplanColumns.D]: this.data.kfzKennzeichen,
            })
        );

        // merge Rechnungsempfänger column from E to N on row 2 and 3
        this.mergeCellArea([2, TourenplanColumns.E], [3, TourenplanColumns.N]);

        tourenplanDetailRows.forEach((row) =>
            row.eachCell((cell, cellIndex) => {
                if (cellIndex !== TourenplanColumns.B && cellIndex !== TourenplanColumns.D) {
                    this.setTableHeaderStyle(cell);
                }
                this.setCellBorder(cell);
            })
        );

        this.data.rechnungsempfaenger?.forEach((rechnungsempfaenger) => {
            const rechnungsempfaengerRow = this.worksheet.addRow({
                [TourenplanColumns.E]: this.createRechnungsempfaengerCell(rechnungsempfaenger),
            });

            this.mergeCellsHorizontal(TourenplanColumns.E, TourenplanColumns.N, rechnungsempfaengerRow.number);

            this.styleRow(rechnungsempfaengerRow, {
                height: 60,
            });

            rechnungsempfaengerRow.eachCell((cell) => {
                this.styleCell(cell, {
                    alignment: {
                        wrapText: true,
                        vertical: 'middle',
                        horizontal: 'left',
                    },
                });
                this.setCellBorder(cell);
            });
        });
    }

    generateExcel(): Promise<Buffer> {
        this.workbook = new Workbook();
        this.worksheet = this.workbook.addWorksheet('Tourenplan');
        this.createTourenplanColumns();

        this.createTourenplanDetails();

        this.createLoseWareTableHeader();
        this.createLoseWareTableBody();

        this.createSackwareTableHeader();
        this.createSackwareTableBody();

        return this.workbook.xlsx.writeBuffer();
    }

    generateCsv(options: Partial<CsvWriteOptions>): Promise<Buffer> {
        this.workbook = new Workbook();
        this.worksheet = this.workbook.addWorksheet('Tourenplan');
        this.createTourenplanColumns();

        this.createTourenplanDetails();

        this.createLoseWareTableHeader();
        this.createLoseWareTableBody();

        this.createSackwareTableHeader();
        this.createSackwareTableBody();

        return this.workbook.csv.writeBuffer(options);
    }
}

export default TourenplanExcelGenerator;
