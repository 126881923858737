import type { Marke } from '../../shared/types';
import { useEffect, useState } from 'react';
import styles from './PartnerDetail.module.scss';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';
import IconButton, { IconSize } from '../../shared/ui-components/IconButton/IconButton';
import iconAddArrow from '../../assets/icon-add-arrow.svg';
import iconTrashcan from '../../assets/icon-trashcan.svg';

type MarkenComponentProps = {
    ausgewaehlteMarken: Marke[];
    alleMarken: Marke[];
    onChangedMarken: (newMarken: Marke[]) => void;
    idPrefix: string;
};
type MarkenView = Marke & { selected: boolean };

const markeFromMarkeView = ({ selected: _, ...marke }: MarkenView): Marke => marke;

export const MarkenZuordnung = ({ ausgewaehlteMarken, alleMarken, onChangedMarken, idPrefix }: MarkenComponentProps): JSX.Element => {
    const markenFromStore = alleMarken;
    const selectedMarken: Marke[] = ausgewaehlteMarken;
    const [selectedMarkenView, setSelectedMarkenView] = useState<MarkenView[]>([]);
    const [markenView, setMarkenView] = useState<MarkenView[]>([]);

    useEffect(() => {
        setMarkenView(markenFromStore.map((m) => ({ ...m, selected: false })));
    }, [markenFromStore, setMarkenView]);

    useEffect(() => {
        if (selectedMarken) {
            setSelectedMarkenView(selectedMarken.map((m) => ({ ...m, selected: false })));
        }
    }, [selectedMarken, setSelectedMarkenView]);

    const filteredMarkenView = markenView.filter((m) => !selectedMarkenView.some((mv) => m.id === mv.id));

    const addSelectedMarke = (): void => {
        const selectedMarkenViewNext = [...selectedMarkenView, ...filteredMarkenView.filter((mv) => mv.selected)].map((m) => ({
            ...m,
            selected: false,
        }));
        setSelectedMarkenView(selectedMarkenViewNext);
        onChangedMarken(selectedMarkenViewNext.map(markeFromMarkeView));
    };

    return (
        <>
            <div className={styles._markenZuordnungContainer}>
                <div className={styles._alleMarken}>
                    <h4>Verfügbare Marken</h4>
                    <div className={[styles._listContainer, styles['_listContainer--fixedHeightMarken']].join(' ')}>
                        {filteredMarkenView.map((m, index) => (
                            <NonFormikCheckbox
                                id={idPrefix + m.id}
                                name={idPrefix + m.id}
                                label={<span>{m.name}</span>}
                                key={index}
                                checked={m.selected}
                                placement="left"
                                onFieldChange={(checked: boolean): void => {
                                    setMarkenView(
                                        markenView.map((mvLocal) =>
                                            mvLocal.id !== m.id
                                                ? mvLocal
                                                : {
                                                      ...mvLocal,
                                                      selected: checked,
                                                  }
                                        )
                                    );
                                }}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles._zuordnungActions}>
                    <IconButton icon={iconAddArrow} iconSize={IconSize.LARGE} alt="Marke hinzufügen" onClick={addSelectedMarke} />
                </div>
                <div className={styles._ausgewaehlteMarken}>
                    <h4>Zugeordnete Marken</h4>
                    <div className={[styles._listContainer, styles['_listContainer--fullHeight']].join(' ')}>
                        {selectedMarkenView.map((marke, index) => (
                            <div key={index} className={styles._selectedEntry}>
                                <div>{marke.name}</div>
                                <IconButton
                                    onClick={(): void => {
                                        const selectedMarkenViewNext = selectedMarkenView.filter(
                                            (markeInView) => markeInView.id !== marke.id
                                        );
                                        setSelectedMarkenView(selectedMarkenViewNext);
                                        setMarkenView(markenView.map((markeInView) => ({ ...markeInView, selected: false })));
                                        onChangedMarken(selectedMarkenViewNext.map(markeFromMarkeView));
                                    }}
                                    icon={iconTrashcan}
                                    alt="Partnerzuordnung löschen"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
