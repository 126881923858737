import { FieldArray, useFormikContext } from 'formik';
import { isSackwareView } from '../../../helper/bestellungen-helper';
import { SackwareListEntryExistingBestellung } from './ExistingBestellung/SackwareListEntryExistingBestellung';
import { ArtikelTableValues } from '../../../types';
import { useSelector } from 'react-redux';
import { selectIsBestellungExisting } from '../../../../store/Global.selectors';
import { SackwareListEntryNewBestellung } from './NewBestellung/SackwareListEntryNewBestellung';

interface Props {
    onArtikelDeleted?: (artikelNummer: string) => void;
    allowDelete?: boolean;
}

export const SackwareList = ({ onArtikelDeleted, allowDelete }: Props): JSX.Element => {
    const formikProps = useFormikContext<ArtikelTableValues>();
    const isExisting = useSelector(selectIsBestellungExisting);

    const ListEntry = isExisting ? SackwareListEntryExistingBestellung : SackwareListEntryNewBestellung;

    return (
        <div>
            <FieldArray
                name="bestellpositionenView"
                render={(arrayHelpers): JSX.Element => (
                    <>
                        {formikProps.values.bestellpositionenView.filter(isSackwareView).map((bestellposition, index) => {
                            return (
                                <ListEntry
                                    key={index}
                                    bestellposition={bestellposition}
                                    arrayHelpers={arrayHelpers}
                                    onArtikelDeleted={onArtikelDeleted}
                                    allowDelete={allowDelete}
                                />
                            );
                        })}
                    </>
                )}
            />
        </div>
    );
};
