import { Marke } from '../shared/types';

export const GET_MARKEN = 'GET_MARKEN';
export const GET_MARKEN_SUCCEEDED = 'GET_MARKEN_SUCCEEDED';
export const GET_MARKEN_FAILED = 'GET_MARKEN_FAILED';

export interface GetMarkenAction {
    type: typeof GET_MARKEN;
}

export interface GetMarkenSucceededAction {
    type: typeof GET_MARKEN_SUCCEEDED;
    marken: Marke[];
}

export interface GetMarkenFailedAction {
    type: typeof GET_MARKEN_FAILED;
}

export function getMarken(): GetMarkenAction {
    return {
        type: GET_MARKEN,
    };
}

export function getMarkenSucceeded(marken: Marke[]): GetMarkenSucceededAction {
    return {
        type: GET_MARKEN_SUCCEEDED,
        marken,
    };
}

export function getMarkenFailed(): GetMarkenFailedAction {
    return {
        type: GET_MARKEN_FAILED,
    };
}

export type MarkenActions = GetMarkenAction | GetMarkenSucceededAction | GetMarkenFailedAction;

export type MarkenState = {
    marken: Marke[];
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
};

export const INITIAL_MARKEN_STATE: MarkenState = {
    marken: [],
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
};

export function markenReducer(state = INITIAL_MARKEN_STATE, action: MarkenActions): MarkenState {
    switch (action.type) {
        case GET_MARKEN:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
                loadFinished: false,
            };
        case GET_MARKEN_FAILED:
            return {
                ...state,
                isLoading: false,
                loadFailed: true,
                loadFinished: true,
            };
        case GET_MARKEN_SUCCEEDED:
            return {
                ...state,
                marken: action.marken,
                isLoading: false,
                loadFailed: false,
                loadFinished: true,
            };
        default:
            return state;
    }
}
