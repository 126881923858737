import { PartnerMitAdminDaten, Partner } from '../shared/types';
import { History, LocationState } from 'history';
import { emptyPartnerMitMarken } from '../shared/types/defaultValues';
import { Order } from '../shared/types/enums';
import type { RootState } from '../configureStore';

export const GET_PARTNER_LIST = 'GET_PARTNER_LIST';
export const GET_PARTNER_LIST_SUCCEEDED = 'GET_PARTNER_LIST_SUCCEEDED';
export const GET_PARTNER_LIST_FAILED = 'GET_PARTNER_LIST_FAILED';

export const GET_PARTNER_DETAILS = 'GET_PARTNER_DETAILS';
export const GET_PARTNER_DETAILS_SUCCEEDED = 'GET_PARTNER_DETAILS_SUCCEEDED';
export const GET_PARTNER_DETAILS_FAILED = 'GET_PARTNER_DETAILS_FAILED';

export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNER_SUCCEEDED = 'UPDATE_PARTNER_SUCCEEDED';
export const UPDATE_PARTNER_FAILED = 'UPDATE_PARTNER_FAILED';

export interface GetPartnerListAction {
    type: typeof GET_PARTNER_LIST;
    page: number;
    size: number;
    sortColumn: string;
    sortOrder: Order;
}

export interface GetPartnerListSucceededAction {
    type: typeof GET_PARTNER_LIST_SUCCEEDED;
    partner: Partner[];
    totalCount: number;
}

export interface GetPartnerListFailedAction {
    type: typeof GET_PARTNER_LIST_FAILED;
}

export interface GetPartnerDetailsAction {
    type: typeof GET_PARTNER_DETAILS;
    partnerNummer: string;
}

export interface GetPartnerDetailsSucceededAction {
    type: typeof GET_PARTNER_DETAILS_SUCCEEDED;
    partner: PartnerMitAdminDaten;
}

export interface GetPartnerDetailsFailedAction {
    type: typeof GET_PARTNER_DETAILS_FAILED;
}

export interface UpdatePartnerAction {
    type: typeof UPDATE_PARTNER;
    partner: PartnerMitAdminDaten;
    history: History<LocationState>;
}

export interface UpdatePartnerSucceededAction {
    type: typeof UPDATE_PARTNER_SUCCEEDED;
    partner: PartnerMitAdminDaten;
}

export interface UpdatePartnerFailedAction {
    type: typeof UPDATE_PARTNER_FAILED;
    partner: PartnerMitAdminDaten;
}

export function updatePartner(partner: PartnerMitAdminDaten, history: History<LocationState>): UpdatePartnerAction {
    return {
        type: UPDATE_PARTNER,
        partner,
        history,
    };
}

export function updatePartnerSucceeded(partner: PartnerMitAdminDaten): UpdatePartnerSucceededAction {
    return {
        type: UPDATE_PARTNER_SUCCEEDED,
        partner,
    };
}

export function updatePartnerFailed(partner: PartnerMitAdminDaten): UpdatePartnerFailedAction {
    return {
        type: UPDATE_PARTNER_FAILED,
        partner,
    };
}

export function getPartnerDetails(partnerNummer: string): GetPartnerDetailsAction {
    return {
        type: GET_PARTNER_DETAILS,
        partnerNummer,
    };
}

export function getPartnerDetailsSucceeded(partner: PartnerMitAdminDaten): GetPartnerDetailsSucceededAction {
    return {
        type: GET_PARTNER_DETAILS_SUCCEEDED,
        partner,
    };
}

export function getPartnerDetailsFAILED(): GetPartnerDetailsFailedAction {
    return {
        type: GET_PARTNER_DETAILS_FAILED,
    };
}

export function getPartnerList(page: number, size: number, sortColumn: string, sortOrder: Order): GetPartnerListAction {
    return {
        type: GET_PARTNER_LIST,
        page,
        size,
        sortColumn,
        sortOrder,
    };
}

export function getPartnerListSucceeded(partner: Partner[], totalCount: number): GetPartnerListSucceededAction {
    return {
        type: GET_PARTNER_LIST_SUCCEEDED,
        partner,
        totalCount,
    };
}

export function getPartnerListFailed(): GetPartnerListFailedAction {
    return {
        type: GET_PARTNER_LIST_FAILED,
    };
}

export type PartneruebersichtActions =
    | GetPartnerListAction
    | GetPartnerListFailedAction
    | GetPartnerListSucceededAction
    | GetPartnerDetailsAction
    | GetPartnerDetailsFailedAction
    | GetPartnerDetailsSucceededAction
    | UpdatePartnerAction
    | UpdatePartnerFailedAction
    | UpdatePartnerSucceededAction;

export type PartneruebersichtState = {
    partner: Partner[];
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
    totalCount: number;
    selectedPartner: PartnerMitAdminDaten;
    isLoadingDetails: boolean;
    loadDetailsFailed: boolean;
    loadDetailsFinished: boolean;
    isSubmitting: boolean;
    hasSubmitError: boolean;
};

export const INITIAL_PARTNER_UEBERSICHT_STATE: PartneruebersichtState = {
    partner: [],
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
    totalCount: 0,
    selectedPartner: emptyPartnerMitMarken,
    isLoadingDetails: false,
    loadDetailsFailed: false,
    loadDetailsFinished: false,
    isSubmitting: false,
    hasSubmitError: false,
};

const selectPartneruebersicht = (state: RootState): PartneruebersichtState => state.partneruebersicht;
const selectPartner = (state: RootState): Partner[] => selectPartneruebersicht(state).partner;

export const PartneruebersichtSelectors = {
    partner: selectPartner,
    isLoading: (state: RootState): boolean => selectPartneruebersicht(state).isLoading,
    loadFailed: (state: RootState): boolean => selectPartneruebersicht(state).loadFailed,
    totalCount: (state: RootState): number => selectPartneruebersicht(state).totalCount,
};

export function partneruebersichtReducer(
    state = INITIAL_PARTNER_UEBERSICHT_STATE,
    action: PartneruebersichtActions
): PartneruebersichtState {
    switch (action.type) {
        case GET_PARTNER_LIST:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
                loadFinished: false,
            };
        case GET_PARTNER_LIST_FAILED:
            return {
                ...state,
                loadFailed: true,
                isLoading: false,
                loadFinished: true,
            };
        case GET_PARTNER_LIST_SUCCEEDED:
            return {
                ...state,
                partner: action.partner,
                totalCount: action.totalCount,
                loadFailed: false,
                isLoading: false,
                loadFinished: true,
            };
        case GET_PARTNER_DETAILS:
            return {
                ...state,
                selectedPartner: emptyPartnerMitMarken,
                isLoadingDetails: true,
                loadDetailsFailed: false,
                loadDetailsFinished: false,
            };
        case GET_PARTNER_DETAILS_SUCCEEDED:
            return {
                ...state,
                selectedPartner: action.partner,
                loadDetailsFailed: false,
                isLoadingDetails: false,
                loadDetailsFinished: true,
            };
        case GET_PARTNER_DETAILS_FAILED:
            return {
                ...state,
                loadDetailsFailed: true,
                isLoadingDetails: false,
                loadDetailsFinished: true,
            };
        case UPDATE_PARTNER:
            return {
                ...state,
                partner: state.partner.map((p) => (p.partnerNummer === action.partner.partnerNummer ? action.partner : p)),
                hasSubmitError: false,
                isSubmitting: true,
            };
        case UPDATE_PARTNER_SUCCEEDED:
            return {
                ...state,
                partner: state.partner.map((p) => (p.partnerNummer === action.partner.partnerNummer ? action.partner : p)),
                hasSubmitError: false,
                isSubmitting: false,
            };
        case UPDATE_PARTNER_FAILED:
            return {
                ...state,
                partner: state.partner.map((p) => (p.partnerNummer === action.partner.partnerNummer ? action.partner : p)),
                hasSubmitError: true,
                isSubmitting: false,
            };
        default:
            return state;
    }
}
