import { CellProps, Column } from 'react-table';
import TextInputWithHookForms from '../../../../../shared/ui-components/TextInput/TextInputWithHookForms';
import { useFormContext } from 'react-hook-form';
import TableCell from '../../../../../shared/ui-components/Table/ReactTable/TableCell';
import { getErrorMessageForPropertyFieldError } from '../../../error-helper';
import { TextInputRule } from '../../../../../shared/types/enums';
import type { TourenplanForm, TourenplanLoseWareBestellung } from '../../../TourenplanTypes';
import { BestellungDetails } from '../../../../../shared/types';

const MengeColumnCell = (cellProps: CellProps<BestellungDetails, Partial<TourenplanLoseWareBestellung>>): JSX.Element => {
    const { menge } = cellProps.value;
    const rowIndex = cellProps.row.index;
    const cell = cellProps.cell;

    const { formState } = useFormContext<TourenplanForm>();
    const { errors } = formState;

    const mengeInTonnenErrorMessage = getErrorMessageForPropertyFieldError(errors?.loseWareBestellungen?.[rowIndex], 'menge') ?? '';
    return (
        <TableCell cell={cell} input>
            <TextInputWithHookForms
                data-testid="tourenplan-menge-in-tonnen-input"
                data-cy={`lw-menge-${rowIndex}`}
                defaultValue={menge}
                name={`loseWareBestellungen.${rowIndex}.menge`}
                hasError={Boolean(mengeInTonnenErrorMessage)}
                errorMessage={mengeInTonnenErrorMessage}
                inTableCell
                textInputRule={TextInputRule.ONLY_POSITIVE_DECIMALS_WITH_ONE_DECIMAL_PLACE}
            />
        </TableCell>
    );
};

export const mengeInTonnenColumnConfig: Column<TourenplanLoseWareBestellung> = {
    id: 'menge',
    Header: 'Menge',
    accessor: (bestellung): Partial<TourenplanLoseWareBestellung> => {
        return {
            bestellnummer: bestellung.bestellnummer,
            menge: bestellung.menge,
            nummer: bestellung.nummer,
        };
    },
    Cell: MengeColumnCell,
};
