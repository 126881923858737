import { History, LocationState } from 'history';
import { clearBestellung, sendBestellungSucceeded } from './Bestellung.store';
import { routes as constantRoutes } from '../shared/constants';
import { Bestellprozesstyp } from '../shared/types/enums';
import { isFeatureOn } from '../shared/ui-components/FeatureToggle/FeatureToggle';
import type { RootState } from '../configureStore';

export const SET_BESTELLPROZESSTYP = 'SET_BESTELLPROZESSTYP';
export const SET_CURRENT_WORKFLOW_STEP = 'SET_CURRENT_WORKFLOW_STEP';

export const SET_CURRENT_WORKFLOW_STEP_SUCCEEDED = 'SET_CURRENT_WORKFLOW_STEP_SUCCEEDED';

export interface SetCurrentWorkflowStepSucceededAction {
    type: typeof SET_CURRENT_WORKFLOW_STEP_SUCCEEDED;
    currentStep: WorkflowStep;
    nextStep: WorkflowStep;
    previousStep: WorkflowStep;
}

export function setCurrentWorkflowStepSucceeded(
    currentStep: WorkflowStep,
    nextStep: WorkflowStep,
    previousStep: WorkflowStep
): SetCurrentWorkflowStepSucceededAction {
    return {
        type: SET_CURRENT_WORKFLOW_STEP_SUCCEEDED,
        currentStep,
        nextStep,
        previousStep,
    };
}

export enum WorkflowStep {
    UNDECIDED = 'UNDECIDED',
    AuswahlBestellprozesstyp = 'AuswahlBestellprozesstyp',
    AuswahlWarenempfaenger = 'AuswahlWarenempfaenger',
    AuswahlRechnungsempfaenger = 'AuswahlRechnungsempfaenger',
    AuswahlWerk = 'AuswahlWerk',
    ErfassungNutztierfutter = 'ErfassungNutztierfutter',
    ErfassungHeimtierfutter = 'ErfassungHeimtierfutter',
    Bestelldaten = 'Bestelldaten',
    Bestellzusammenfassung = 'Bestellzusammenfassung',
    Bestellversand = 'Bestellversand',
}

const allowedWorkflowStepsNutztierfutter = [
    WorkflowStep.AuswahlBestellprozesstyp,
    WorkflowStep.AuswahlWarenempfaenger,
    WorkflowStep.AuswahlRechnungsempfaenger,
    WorkflowStep.ErfassungNutztierfutter,
    WorkflowStep.Bestelldaten,
    WorkflowStep.Bestellzusammenfassung,
    WorkflowStep.Bestellversand,
];

const allowedWorkflowStepsHeimtierfutter = [
    WorkflowStep.AuswahlBestellprozesstyp,
    WorkflowStep.AuswahlWarenempfaenger,
    WorkflowStep.AuswahlRechnungsempfaenger,
    WorkflowStep.AuswahlWerk,
    WorkflowStep.ErfassungHeimtierfutter,
    WorkflowStep.Bestelldaten,
    WorkflowStep.Bestellzusammenfassung,
    WorkflowStep.Bestellversand,
];

export function isAllowedWorkflowStep(bestellprozesstyp: Bestellprozesstyp, workflowStep: WorkflowStep): boolean {
    return bestellprozesstyp === Bestellprozesstyp.Nutztierfutter
        ? allowedWorkflowStepsNutztierfutter.includes(workflowStep)
        : allowedWorkflowStepsHeimtierfutter.includes(workflowStep);
}

export interface SetBestellprozesstypAction {
    type: typeof SET_BESTELLPROZESSTYP;
    bestellprozesstyp: Bestellprozesstyp;
}

export interface SetCurrentWorkflowStepAction {
    type: typeof SET_CURRENT_WORKFLOW_STEP;
    step: WorkflowStep;
    history: History<LocationState>;
}

export function setBestellprozesstyp(bestellprozesstyp: Bestellprozesstyp): SetBestellprozesstypAction {
    return {
        type: SET_BESTELLPROZESSTYP,
        bestellprozesstyp,
    };
}

export function setCurrentWorkflowStep(step: WorkflowStep, history: History<LocationState>): SetCurrentWorkflowStepAction {
    return {
        type: SET_CURRENT_WORKFLOW_STEP,
        step,
        history,
    };
}
export const INITIAL_WORKFLOW_STATE: WorkflowState = {
    currentWorkflowStep: WorkflowStep.AuswahlBestellprozesstyp,
    nextRoute: '/',
    previousRoute: '/',
    currentRoute: '/',
    calculatingWorkflow: false,
};

export type WorkflowState = {
    bestellprozesstyp?: Bestellprozesstyp;
    currentWorkflowStep: WorkflowStep;
    nextRoute: string;
    previousRoute: string;
    currentRoute: string;
    calculatingWorkflow: boolean;
};

export type WorkflowActions = SetBestellprozesstypAction | SetCurrentWorkflowStepAction | SetCurrentWorkflowStepSucceededAction;

export const isFirstWorkflowStep = (step: WorkflowStep): boolean => step === WorkflowStep.AuswahlBestellprozesstyp;
export const isLastWorkflowStep = (step: WorkflowStep): boolean => step === WorkflowStep.Bestellversand;

export function calcNextWorkflowStep(currentWorkflowStep: WorkflowStep, bestellprozesstyp?: Bestellprozesstyp): WorkflowStep {
    if (bestellprozesstyp === Bestellprozesstyp.Heimtierfutter) {
        switch (currentWorkflowStep) {
            case WorkflowStep.AuswahlBestellprozesstyp:
                return WorkflowStep.AuswahlWarenempfaenger;
            case WorkflowStep.AuswahlWarenempfaenger:
                return WorkflowStep.AuswahlRechnungsempfaenger;
            case WorkflowStep.AuswahlRechnungsempfaenger:
                return WorkflowStep.AuswahlWerk;
            case WorkflowStep.AuswahlWerk:
                return WorkflowStep.ErfassungHeimtierfutter;
            case WorkflowStep.ErfassungHeimtierfutter:
                return WorkflowStep.Bestelldaten;
            case WorkflowStep.Bestelldaten:
                return WorkflowStep.Bestellzusammenfassung;
            case WorkflowStep.Bestellzusammenfassung:
                return WorkflowStep.Bestellversand;
            default:
                return currentWorkflowStep;
        }
    } else if (bestellprozesstyp === Bestellprozesstyp.Nutztierfutter) {
        switch (currentWorkflowStep) {
            case WorkflowStep.AuswahlBestellprozesstyp:
                return WorkflowStep.AuswahlWarenempfaenger;
            case WorkflowStep.AuswahlWarenempfaenger:
                return WorkflowStep.AuswahlRechnungsempfaenger;
            case WorkflowStep.AuswahlRechnungsempfaenger:
                return WorkflowStep.ErfassungNutztierfutter;
            case WorkflowStep.ErfassungNutztierfutter:
                return WorkflowStep.Bestelldaten;
            case WorkflowStep.Bestelldaten:
                return WorkflowStep.Bestellzusammenfassung;
            case WorkflowStep.Bestellzusammenfassung:
                return WorkflowStep.Bestellversand;
            default:
                return currentWorkflowStep;
        }
    } else {
        return WorkflowStep.UNDECIDED;
    }
}

export function calcPreviousWorkflowStep(currentWorkflowStep: WorkflowStep, bestellprozesstyp?: Bestellprozesstyp): WorkflowStep {
    if (bestellprozesstyp === Bestellprozesstyp.Heimtierfutter) {
        switch (currentWorkflowStep) {
            case WorkflowStep.AuswahlWarenempfaenger:
                return WorkflowStep.AuswahlBestellprozesstyp;
            case WorkflowStep.AuswahlRechnungsempfaenger:
                return WorkflowStep.AuswahlWarenempfaenger;
            case WorkflowStep.AuswahlWerk:
                return WorkflowStep.AuswahlRechnungsempfaenger;
            case WorkflowStep.ErfassungHeimtierfutter:
                return WorkflowStep.AuswahlWerk;
            case WorkflowStep.Bestelldaten:
                return WorkflowStep.ErfassungHeimtierfutter;
            case WorkflowStep.Bestellzusammenfassung:
                return WorkflowStep.Bestelldaten;
            case WorkflowStep.Bestellversand:
                return WorkflowStep.Bestellzusammenfassung;
            default:
                return currentWorkflowStep;
        }
    } else if (bestellprozesstyp === Bestellprozesstyp.Nutztierfutter) {
        switch (currentWorkflowStep) {
            case WorkflowStep.AuswahlWarenempfaenger:
                return WorkflowStep.AuswahlBestellprozesstyp;
            case WorkflowStep.AuswahlRechnungsempfaenger:
                return WorkflowStep.AuswahlWarenempfaenger;
            case WorkflowStep.ErfassungNutztierfutter:
                return WorkflowStep.AuswahlRechnungsempfaenger;
            case WorkflowStep.Bestelldaten:
                return WorkflowStep.ErfassungNutztierfutter;
            case WorkflowStep.Bestellzusammenfassung:
                return WorkflowStep.Bestelldaten;
            case WorkflowStep.Bestellversand:
                return WorkflowStep.Bestellzusammenfassung;
            default:
                return currentWorkflowStep;
        }
    } else {
        return currentWorkflowStep;
    }
}

export const routes = {
    ...constantRoutes,
    auftragsuebersicht: isFeatureOn('AUFTRAGSEINGANG') ? '/auftragsuebersicht' : '/noch-in-aufbau',
    auftragsansicht: isFeatureOn('AUFTRAGSEINGANG') ? '/auftragsansicht' : '/noch-in-aufbau',
    preislistenkennzeichen: isFeatureOn('PREISLISTENKENNZEICHEN') ? '/admin/preislistenkennzeichen' : '/noch-in-aufbau',
};
export const routeFromWorkflowStep = (step: WorkflowStep): string => {
    switch (step) {
        case WorkflowStep.AuswahlBestellprozesstyp:
            return routes.auswahlFuttertyp;
        case WorkflowStep.AuswahlWarenempfaenger:
            return routes.warenempfaengerAuswaehlen;
        case WorkflowStep.AuswahlRechnungsempfaenger:
            return routes.rechnungsempfaengerAuswaehlen;
        case WorkflowStep.AuswahlWerk:
            return routes.abholwerk;
        case WorkflowStep.ErfassungHeimtierfutter:
            return routes.artikelHeimtierfutter;
        case WorkflowStep.ErfassungNutztierfutter:
            return routes.artikelNutztier;
        case WorkflowStep.Bestelldaten:
            return routes.bestelldaten;
        case WorkflowStep.Bestellzusammenfassung:
            return routes.bestellzusammenfassung;
        case WorkflowStep.Bestellversand:
            return routes.bestelluebermittlung;
        case WorkflowStep.UNDECIDED:
            return '/';
        default:
            throw new Error('unknown Workflowstep: ' + step);
    }
};

const selectWorkflowState = (state: RootState): WorkflowState => state.workflow;

export const selectBestellprozesstyp = (state: RootState): Bestellprozesstyp | undefined => selectWorkflowState(state).bestellprozesstyp;
export const selectIsNutztierfutter = (state: RootState): boolean => selectBestellprozesstyp(state) === Bestellprozesstyp.Nutztierfutter;
export const selectIsHeimtierfutter = (state: RootState): boolean => selectBestellprozesstyp(state) === Bestellprozesstyp.Heimtierfutter;
export const selectCurrentWorkflowStep = (state: RootState): WorkflowStep => selectWorkflowState(state).currentWorkflowStep;
export const selectIsCalculatingWorkflow = (state: RootState): boolean => selectWorkflowState(state).calculatingWorkflow;
export const selectHasOpenBestellung = (state: RootState): boolean => {
    const currentWorkflowStep = selectWorkflowState(state).currentWorkflowStep;
    return currentWorkflowStep !== WorkflowStep.UNDECIDED && currentWorkflowStep !== WorkflowStep.AuswahlBestellprozesstyp;
};
export const selectNextRoute = (state: RootState): string => selectWorkflowState(state).nextRoute;
export const selectCurrentRoute = (state: RootState): string => selectWorkflowState(state).currentRoute;
export const selectPreviousRoute = (state: RootState): string => selectWorkflowState(state).previousRoute;

export function workflowReducer(
    state = INITIAL_WORKFLOW_STATE,
    action: WorkflowActions | { type: typeof sendBestellungSucceeded.type } | { type: typeof clearBestellung.type }
): WorkflowState {
    switch (action.type) {
        case SET_BESTELLPROZESSTYP:
            return {
                ...state,
                bestellprozesstyp: action.bestellprozesstyp,
            };
        case sendBestellungSucceeded.type:
            // Wenn eine Bestellung erfolgreich war, wird der Workflow zurückgesetzt
            return INITIAL_WORKFLOW_STATE;
        case clearBestellung.type:
            return INITIAL_WORKFLOW_STATE;
        case SET_CURRENT_WORKFLOW_STEP: {
            return {
                ...state,
                currentWorkflowStep: action.step,
                calculatingWorkflow: true,
                nextRoute: routeFromWorkflowStep(action.step),
                currentRoute: state.currentRoute,
                previousRoute: routeFromWorkflowStep(action.step),
            };
        }
        case SET_CURRENT_WORKFLOW_STEP_SUCCEEDED: {
            const nextWorkflowStep = action.nextStep;
            const previousWorkflowStep = action.previousStep;
            return {
                ...state,
                currentWorkflowStep: action.currentStep,
                calculatingWorkflow: false,
                nextRoute: routeFromWorkflowStep(nextWorkflowStep),
                currentRoute: routeFromWorkflowStep(action.currentStep),
                previousRoute: routeFromWorkflowStep(previousWorkflowStep),
            };
        }
        default:
            return state;
    }
}
