import { PropertyEntryProps } from '../types';
import FormikCheckbox from '../../../../ui-components/CheckBox/FormikCheckbox';
import { useRechnungsempfaengerFromBestellung } from '../../../../hooks/useRechnungsempfaengerFromBestellung';

export const ZulageListEntryCheckbox = ({ bestellposition, disabled }: PropertyEntryProps): JSX.Element | null => {
    const rechnungsempfaenger = useRechnungsempfaengerFromBestellung();

    if (!rechnungsempfaenger?.zulagenErfassungErlaubt) {
        return null;
    }

    return (
        <>
            <FormikCheckbox
                disabled={bestellposition.storno || disabled}
                label="Zulage?"
                name={`bestellpositionenView[${bestellposition.index}].zulage`}
                id={`bestellpositionenView[${bestellposition.index}].zulage`}
            />
        </>
    );
};
