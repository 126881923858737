import { useFormikContext } from 'formik';
import { ArtikelNutztierfutterFormular } from '../../shared/helper/artikel-helper';
import WorkflowButtons from '../../shared/content-components/WorkflowButtons/WorkflowButtons';

type ArtikelNutztierWorkflowButtonsProps = { setTriedToSubmit: (tried: boolean) => void };
export const ArtikelNutztierWorkflowButtons = ({ setTriedToSubmit }: ArtikelNutztierWorkflowButtonsProps): JSX.Element => {
    const { submitForm } = useFormikContext<ArtikelNutztierfutterFormular>();

    const onNextClick = (): void => {
        setTriedToSubmit(true);
        void submitForm();
    };

    return <WorkflowButtons onNextClick={onNextClick} />;
};
