import { DetailedHTMLProps, PropsWithChildren, TdHTMLAttributes, useMemo } from 'react';
import tableStyles from './Table.module.scss';

type TableCellProps = {
    text?: boolean;
    button?: boolean;
    number?: boolean;
    checkbox?: boolean;
    master?: boolean;
    detail?: boolean;
    storniert?: boolean;
    hidden?: boolean;
    select?: boolean;
    input?: boolean;
    wideInput?: boolean;
    selectWithError?: boolean;
    actionColumn?: boolean;
    additionalStyles?: string[];
} & DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;

export const TableCell = ({
    children,
    additionalStyles,
    text = false,
    number = false,
    checkbox = false,
    master = false,
    button = false,
    select = false,
    hidden = false,
    input = false,
    wideInput = false,
    selectWithError = false,
    detail = false,
    storniert = false,
    actionColumn = false,
    ...TdHTMLElementPops
}: PropsWithChildren<TableCellProps>): JSX.Element => {
    const tableCellClasses = useMemo(() => {
        let styles = [tableStyles._tableCell];

        if (button) {
            styles.push(tableStyles['_tableCell--button']);
        }

        if (number) {
            styles.push(tableStyles['_tableCell--number']);
        }

        if (checkbox) {
            styles.push(tableStyles['_tableCell--checkbox']);
        }

        if (master) {
            styles.push(tableStyles['_tableCell--master']);
        }

        if (input) {
            styles.push(tableStyles['_tableCell--input']);
        }

        if (wideInput) {
            styles.push(tableStyles['_tableCell--wideInput']);
        }

        if (selectWithError) {
            styles.push(tableStyles['_tableCell--select-with-error']);
        }

        if (detail) {
            styles.push(tableStyles['_tableCell--detail']);
        }

        if (select) {
            styles.push(tableStyles['_tableCell--select']);
        }

        if (hidden) {
            styles.push(tableStyles['_tableCell--hidden']);
        }

        if (text) {
            styles.push(tableStyles['_tableCell--text']);
        }

        if (additionalStyles) {
            styles = styles.concat(additionalStyles);
        }

        if (storniert) {
            styles.push(tableStyles['_tableCell--storniert']);
        }

        if (actionColumn) {
            styles.push(tableStyles._actionColumn);
        }

        return styles.join(' ');
    }, [
        actionColumn,
        additionalStyles,
        button,
        checkbox,
        detail,
        hidden,
        input,
        master,
        number,
        select,
        selectWithError,
        storniert,
        text,
        wideInput,
    ]);

    return (
        <td {...TdHTMLElementPops} className={tableCellClasses}>
            {children}
        </td>
    );
};
