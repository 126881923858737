import { ArtikelTableValuesGrouped, getFlatBestellpositionen } from '../../helper/artikel-helper';
import { useFormikContext } from 'formik';
import { BestellpositionArtikelSackwareView } from '../../types';
import TextItem from '../../ui-components/TextItem/TextItem';
import formStyles from '../../ui-components/Form/Form.module.scss';
import styles from './BestellinformationenHeimtierfutter.module.scss';
import Formulas from '../../formulas';
import { ReactElement } from 'react';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

function formatGesamtGewicht(gesamtGewichtIncUebermenge: number) {
    const formatted = `${Math.round(gesamtGewichtIncUebermenge).toLocaleString('de-DE')} kg`;
    return gesamtGewichtIncUebermenge ? formatted : '-';
}
const bestellinformationenText =
    'Bitte beachten Sie, dass es sich bei dieser Berechnung um Richtwerte handelt und das tatsächliche Gewicht sowie die ' +
    'tatsächlich benötigten Palattenstellplätze, je nach Kommissionierung abweichen können.';
const BestellinformationenHeimtierfutter = (): ReactElement => {
    const { values } = useFormikContext<ArtikelTableValuesGrouped>();
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const bestellpositionenSackwareView = getFlatBestellpositionen(values) as BestellpositionArtikelSackwareView[]; // FIXME: Get rid of casting
    const { anzahlPaletten, gesamtGewichtIncUebermenge } = Formulas.calculateBestellinformationen(bestellpositionenSackwareView);
    return (
        <>
            <h4>Bestellinformationen</h4>
            {isMobile ? null : <p className={styles._info}>{bestellinformationenText}</p>}
            <div className={formStyles._formContainer}>
                <div className={formStyles._formSmallColumn}>
                    <TextItem
                        testId="anzahl-paletten"
                        dataCy={'anzahl-paletten'}
                        label="Voraussichtliche Palettenstellplätze"
                        text={anzahlPaletten || '-'}
                        containerStyle={styles._textAndInputField}
                        isNumeric
                    />
                </div>
                <div className={formStyles._formSmallColumn}>
                    <TextItem
                        testId={'gesamt-gewicht'}
                        dataCy={'gesamt-gewicht'}
                        label="Gesamtgewicht inkl. Leerpalettengewicht"
                        text={formatGesamtGewicht(gesamtGewichtIncUebermenge)}
                        containerStyle={styles._textAndInputField}
                        isNumeric
                    />
                </div>
            </div>
        </>
    );
};

export default BestellinformationenHeimtierfutter;
