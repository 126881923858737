import { useFormikContext } from 'formik';
import { BestelldatenFormular } from '../../shared/types';
import WorkflowButtons from '../../shared/content-components/WorkflowButtons/WorkflowButtons';
import { Lieferbedingungen } from '../../shared/types/enums';

type BestelldatenWorkflowButtonsProps = { setTriedToSubmit: (tried: boolean) => void };
export const BestelldatenWorkflowButtons = ({ setTriedToSubmit }: BestelldatenWorkflowButtonsProps): JSX.Element => {
    const { submitForm, values } = useFormikContext<BestelldatenFormular>();

    const onNextClick = (): void => {
        setTriedToSubmit(true);

        if (values.lieferbedingung == Lieferbedingungen.FRANCO) {
            values.spedition = '';
        }

        setTimeout(() => {
            // Evtl. ist die Bestellung erst valide geworden bei Fokusverlust des letzten Feldes.
            // Deswegen wird das Submit im Timeout ausgeführt, damit die Validierung bis dahin
            // aktualisiert werden kann.
            void submitForm();
        }, 0);
    };

    return <WorkflowButtons onNextClick={onNextClick} />;
};
