import styles from './KesselverteilungColumn.module.scss';
import { CellProps } from 'react-table';
import TableCell from '../../../../../shared/ui-components/Table/ReactTable/TableCell';
import TextInputWithHookForms from '../../../../../shared/ui-components/TextInput/TextInputWithHookForms';
import { BestellungDetails } from '../../../../../shared/types';

export const KESSEL_COUNT = 10;
const KesselverteilungColumnCell = (cellProps: CellProps<BestellungDetails>): JSX.Element => {
    const kesselList = new Array(KESSEL_COUNT).fill(0);
    const rowIndex = cellProps.row.index;
    const cell = cellProps.cell;

    return (
        <TableCell additionalClasses={[styles._tableCell]} cell={cell} input>
            <div data-testid="tourenplan-kessel-column" className={styles._kesselWrapper}>
                {kesselList.map((_, index) => {
                    return (
                        <div key={index} className={styles._kessel}>
                            <TextInputWithHookForms
                                key={index}
                                name={`loseWareBestellungen.${rowIndex}.kesselverteilung.${index}`}
                                data-testid={`tourenplan-kessel-column-input-${index}`}
                                size={3}
                                defaultValue={''}
                                inTableCell
                                allowEditAfterSubmit
                            />
                        </div>
                    );
                })}
            </div>
        </TableCell>
    );
};

const KesselverteilungHeader = (): JSX.Element => {
    const kesselList = new Array(KESSEL_COUNT).fill(0);

    return (
        <div data-testid="tourenplan-kessel-column-header" className={styles._kesselHeaderWrapper}>
            Kesselverteilung in t
            <div className={styles._kesselHeader}>
                {kesselList.map((_, index) => {
                    return <div key={index}>{index + 1}</div>;
                })}
            </div>
        </div>
    );
};

export const kesselverteilungColumnConfig = {
    id: 'kessel',
    Header: KesselverteilungHeader,
    Cell: KesselverteilungColumnCell,
};
