import { Link } from 'react-router-dom';
import localStyles from './Bestellansicht.module.scss';
import { routes } from '../../shared/constants';
import iconDetailsLink from '../../assets/icon-details-link.svg';

type LinkToLieferungenProps = {
    bestellnummer: string;
};

export const LinkToLieferungen = ({ bestellnummer }: LinkToLieferungenProps): JSX.Element => {
    return (
        <Link className={localStyles._bestellungLink} to={`${routes.bestellansicht}/${bestellnummer}/lieferungen/`}>
            <span>Lieferungen zu dieser Bestellung ansehen</span>
            <img src={iconDetailsLink} className={localStyles._editIcon} alt="Lieferungen zu dieser Bestellung ansehen" />
        </Link>
    );
};
