import { useField } from 'formik';
import InputMask, { Props } from 'react-input-mask';
import type { BaseProps } from '../FormControl';
import styles from './MaskedTextInput.module.scss';

const MaskedTextInput = (props: BaseProps & Props): JSX.Element => {
    const [field, meta] = useField(props);
    const showError = meta.touched && meta.error;
    const inputStyles = showError ? [styles._input, styles['_input--error']] : [styles._input];
    return (
        <div className={styles._container}>
            <InputMask className={inputStyles.join(' ')} {...field} {...props} />
        </div>
    );
};

export default MaskedTextInput;
