import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getKontrakt } from '../../store/Kontraktuebersicht.store';

export const useKontraktDetails = (kontraktNummer: string): (() => void) => {
    const dispatch = useDispatch();
    const fetchKontaktDetails = useCallback(() => {
        if (!kontraktNummer) return;

        dispatch(getKontrakt({ kontraktNummer: kontraktNummer }));
    }, [kontraktNummer, dispatch]);

    useEffect(() => {
        fetchKontaktDetails();
    }, [fetchKontaktDetails]);

    return fetchKontaktDetails;
};
