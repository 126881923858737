import { useRechnungsempfaengerFromBestellung } from '../../../../hooks/useRechnungsempfaengerFromBestellung';
import { TableCell } from '../../../../ui-components/Table';
import FormikCheckbox from '../../../../ui-components/CheckBox/FormikCheckbox';
import { PropertyEntryProps } from '../types';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const ZulageCheckboxTableCell = ({
    bestellposition,
    disabled,
}: PropertyEntryProps<BestellpositionArtikelLoseWareView>): JSX.Element | null => {
    const rechnungsempfaenger = useRechnungsempfaengerFromBestellung();

    return rechnungsempfaenger?.zulagenErfassungErlaubt ? (
        <TableCell checkbox master={bestellposition.zulage}>
            <FormikCheckbox
                disabled={bestellposition.storno || disabled}
                name={`bestellpositionenView[${bestellposition.index}].zulage`}
                id={`bestellpositionenView[${bestellposition.index}].zulage`}
                data-cy={'zulage-checkbox'}
            />
        </TableCell>
    ) : null;
};
