import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import tourenplanHeaderStyles from './TourenplanHeader.module.scss';
import { BestelluebersichtSelectors } from '../../../store/Bestelluebersicht.store';
import type { Rechnungsempfaenger } from '../../../shared/types';

const RechnungsempfaengerCard = ({
    hauptname,
    partnerNummer,
    postleitzahl,
    ort,
    children,
}: PropsWithChildren<Rechnungsempfaenger>): JSX.Element => {
    return (
        <div>
            <strong>{hauptname}</strong>
            <br />
            {partnerNummer}
            <br />
            {postleitzahl} {ort}
            <br />
            {children}
        </div>
    );
};

const TourenplanRechnungsempfaenger = (): JSX.Element => {
    const rechnungsempfaengerList = useSelector(BestelluebersichtSelectors.uniqueRechnungsempfaengerFromCurrentSammelbestellungen);
    const [firstRechnungsempfaenger, ...otherRechnungsempfaenger] = rechnungsempfaengerList;
    const hasOnlyOneRechnungsempfaenger = otherRechnungsempfaenger?.length === 0;

    return (
        <div className={tourenplanHeaderStyles._rechnungsEmpfaenger}>
            <label>Rechnungsempfänger</label>
            <Tippy
                content={otherRechnungsempfaenger.map((r, i) => {
                    return <RechnungsempfaengerCard key={i} {...r} />;
                })}
                arrow={false}
                followCursor={true}
                duration={0}
                className={tourenplanHeaderStyles['_rechnungsEmpfaenger--tippy']}
                plugins={[followCursor]}
                disabled={hasOnlyOneRechnungsempfaenger}
            >
                <div>
                    <RechnungsempfaengerCard {...firstRechnungsempfaenger}>
                        {!hasOnlyOneRechnungsempfaenger && <span>... {otherRechnungsempfaenger.length} weitere</span>}
                    </RechnungsempfaengerCard>
                </div>
            </Tippy>
        </div>
    );
};

export default TourenplanRechnungsempfaenger;
