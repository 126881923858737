import FormControl, { FormControlWithoutErrorDisplay } from '../../shared/ui-components/FormControl/FormControl';
import TextInput from '../../shared/ui-components/TextInput/TextInput';
import styles from './WarenempfaengerAnlegen.module.scss';
import SelectInput from '../../shared/ui-components/SelectInput/SelectInput';
import { Option } from '../../shared/helper/select-options';
import { ReactElement } from 'react';

const laenderOptions = [
    { value: 'BE', label: 'Belgien' },
    { value: 'BG', label: 'Bulgarien' },
    { value: 'DK', label: 'Dänemark' },
    { value: 'DE', label: 'Deutschland' },
    { value: 'EE', label: 'Estland' },
    { value: 'FI', label: 'Finnland' },
    { value: 'FR', label: 'Frankreich' },
    { value: 'GR', label: 'Griechenland' },
    { value: 'IE', label: 'Irland' },
    { value: 'HR', label: 'Kroatien' },
    { value: 'LV', label: 'Litauen' },
    { value: 'LU', label: 'Luxemburg' },
    { value: 'MT', label: 'Malta' },
    { value: 'NL', label: 'Niederlande' },
    { value: 'AT', label: 'Österreich' },
    { value: 'PL', label: 'Polen' },
    { value: 'PT', label: 'Portugal' },
    { value: 'RO', label: 'Rumänien' },
    { value: 'SE', label: 'Schweden' },
    { value: 'SK', label: 'Slowakei' },
    { value: 'SI', label: 'Slowenien' },
    { value: 'ES', label: 'Spanien' },
    { value: 'CZ', label: 'Tschechien' },
    { value: 'HU', label: 'Ungarn' },
    { value: 'CY', label: 'Zypern' },
    { value: 'GB', label: 'Großbritannien' },
    { value: 'UK', label: 'Nordirland' },
    { value: 'RU', label: 'Russland' },
    { value: 'TR', label: 'Türkei' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'BY', label: 'Weißrussland' },
];

export const Adressblock = (): ReactElement => (
    <>
        <h3>Anschrift</h3>
        <FormControlWithoutErrorDisplay
            name="hauptname"
            id="hauptname"
            label="Name des Kunden *"
            renderChild={(props): ReactElement => (
                <TextInput {...props} noTip data-cy={'warenempfaenger-anlegen-hauptname'} maxLength={60} type="text" />
            )}
        />
        <FormControl
            name="nebenname"
            id="nebenname"
            label="2. Nameszeile"
            renderChild={(props): ReactElement => (
                <TextInput {...props} data-cy={'warenempfaenger-anlegen-nebenname'} maxLength={60} type="text" />
            )}
        />
        <FormControlWithoutErrorDisplay
            name="strasse"
            id="strasse"
            label="Straße/Nr. *"
            renderChild={(props): ReactElement => (
                <TextInput {...props} noTip maxLength={200} type="text" data-cy={'warenempfaenger-anlegen-strasse'} />
            )}
        />
        <div className={styles._ortPlzContainer}>
            <div className={styles._plzContainer}>
                <FormControlWithoutErrorDisplay
                    name="postleitzahl"
                    id="postleitzahl"
                    label="Postleitzahl *"
                    renderChild={(props): ReactElement => (
                        <TextInput {...props} noTip maxLength={30} data-cy={'warenempfaenger-anlegen-postleitzahl'} type="text" />
                    )}
                />
            </div>
            <div className={styles._ortContainer}>
                <FormControlWithoutErrorDisplay
                    name="ort"
                    id="ort"
                    label="Ort *"
                    renderChild={(props): ReactElement => (
                        <TextInput {...props} noTip maxLength={60} data-cy={'warenempfaenger-anlegen-ort'} type="text" />
                    )}
                />
            </div>
        </div>
        <FormControl
            name="namenszusatz"
            id="namenszusatz"
            label="Ortsteil"
            renderChild={(props): ReactElement => <TextInput {...props} maxLength={60} type="text" />}
        />
        <FormControl
            name="land"
            id="land"
            label="Land *"
            renderChild={(props): ReactElement => (
                <SelectInput<Option> {...props} options={laenderOptions} isClearable={false} autoSelectIfOnlyOneOption={true} />
            )}
        />
    </>
);
