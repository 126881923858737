import { TableCell } from '../../../../ui-components/Table';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { TextInputRule } from '../../../../types/enums';
import { PropertyEntryReadonlyProps } from '../types';
import { BestellpositionArtikelLoseWareView } from '../../../../types';
import { NEWID_PREFIX } from '../../../../constants';

export const MengeTableCell = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    const dataCy = bestellposition.nummer.startsWith(NEWID_PREFIX)
        ? `lw-menge-${bestellposition.bezeichnung}`
        : `lw-menge-${bestellposition.nummer}`;

    if (readonly) {
        return (
            <TableCell number storniert={bestellposition.storno}>
                <span data-cy={dataCy} data-testid={`${bestellposition.nummer}-menge`}>
                    {bestellposition.menge}
                </span>
            </TableCell>
        );
    }

    return (
        <TableCell number input storniert={bestellposition.storno}>
            <TextInput
                name={`bestellpositionenView[${bestellposition.index}].menge`}
                id={`bestellpositionenView[${bestellposition.index}].menge`}
                type="text"
                textInputRule={TextInputRule.ONLY_POSITIVE_DECIMALS_WITH_ONE_DECIMAL_PLACE}
                data-testid={`${bestellposition.nummer}-menge`}
                data-cy={dataCy}
                disabled={bestellposition.storno || disabled}
                inTableCell
            />
        </TableCell>
    );
};
