import { useDispatch, useSelector } from 'react-redux';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import { FormikLieferbedingungenSelect } from '../Bestelldaten/FormikLieferbedingungenSelect';
import FormControl from '../../shared/ui-components/FormControl/FormControl';
import TextInput from '../../shared/ui-components/TextInput/TextInput';
import { BestelldatenHint } from '../Bestelldaten/BestelldatenHint';
import { FormikBestellhinweisInput } from '../../shared/content-components/Form/FormikBestellhinweisInput';
import { FormikLieferhinweisInput } from '../../shared/content-components/Form/FormikLieferhinweisInput';
import KfzKennzeichenAuswahl from '../../shared/content-components/KfzKennzeichenAuswahl/KfzKennzeichenAuswahl';
import { addKfzKennzeichen, kfzKennzeichenSelectors, TEMPORARY_KFZ_ID } from '../../store/KfzKennzeichen.store';
import { useFetchKfzKennzeichen } from '../../shared/hooks/useFetchKfzKennzeichen';
import { WunschterminZeitraum } from '../../shared/content-components/Wunschtermin/WunschterminZeitraum';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import { addSpedition, speditionSelectors, TEMPORARY_SPEDITION_ID } from 'store/Spedition.store';
import { useFetchSpedition } from 'shared/hooks/useFetchSpedition';
import SpeditionAuswahl from 'shared/content-components/SpeditionAuswahl/SpeditionAuswahl';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { lieferbedingungOptions, Option } from '../../shared/helper/select-options';
import { Lieferbedingungen } from '../../shared/types/enums';
import type { BestelldatenFormular } from '../../shared/types';
import { useFormikContext } from 'formik';
import { useState } from 'react';

export const DebitorBestellansichtForm = (): JSX.Element => {
    const dispatch = useDispatch();
    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);
    const kfzKennzeichenOptions = useSelector(kfzKennzeichenSelectors.allAsOptions);
    const speditionOptions = useSelector(speditionSelectors.allAsOptions);
    const hasSpeditionServerError = useSelector(speditionSelectors.hasSubmitError);

    const { values } = useFormikContext<BestelldatenFormular>();
    const selectedLieferbedingung: Option | undefined = lieferbedingungOptions.find((option) => option.value === values.lieferbedingung);
    const shouldDisplaySpeditionAuswahl = selectedLieferbedingung && selectedLieferbedingung.value !== Lieferbedingungen.FRANCO;

    useFetchKfzKennzeichen();

    useFetchSpedition();

    const [hasInvalidEmailError, setHasInvalidEmailError] = useState(false);

    function isValidEmail(email: string): boolean {
        return /\S+@\S+\.\S+/.test(email);
    }

    const getEmailById = (speditionId: string): string => {
        const selectedSpedition = speditionOptions.find((spedition) => spedition.value === speditionId);
        return selectedSpedition ? (typeof selectedSpedition.label === 'string' ? selectedSpedition.label : '') : '';
    };

    return (
        <>
            <NotificationBar
                testId="server-validation-message-bar"
                message="Beim Speichern der Spedition ist ein Fehler aufgetreten. Bitte geben Sie eine E-Mailadresse ein."
                isVisible={hasSpeditionServerError}
            />
            <FormRow>
                <FormColumn isSection>
                    <FormikLieferbedingungenSelect />
                </FormColumn>
                <FormColumn isSection>
                    <FormControl
                        name="ansprechpartner"
                        id="ansprechpartner"
                        label="Kontakt für Rückfragen"
                        renderChild={(props): JSX.Element => <TextInput {...props} />}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn>
                    <div className={formStyles._sectionHeader}>
                        <h3>Zusätzliche Angaben</h3>
                    </div>
                </FormColumn>
            </FormRow>
            <FormRow>
                <BestelldatenHint text="Hinweis an den Bearbeiter bei Ihrem Landwarenhandel.">
                    <FormColumn>
                        <FormikBestellhinweisInput />
                    </FormColumn>
                </BestelldatenHint>
                <BestelldatenHint text="Hinweis an den Fahrer. Wird auf den Lieferschein gedruckt.">
                    <FormColumn>
                        <FormikLieferhinweisInput />
                    </FormColumn>
                </BestelldatenHint>
            </FormRow>
            <FormRow>
                <FormColumn isSection>
                    <div className={formStyles._selectWithButtonContainer} style={{ width: shouldDisplaySpeditionAuswahl ? '' : '47.9%' }}>
                        <KfzKennzeichenAuswahl
                            kfzKennzeichen={kfzKennzeichenOptions}
                            onKfzKennzeichenCreated={(kfzKennzeichen): void => {
                                dispatch(
                                    addKfzKennzeichen({
                                        id: TEMPORARY_KFZ_ID,
                                        kennzeichen: kfzKennzeichen,
                                    })
                                );
                            }}
                        />
                    </div>
                </FormColumn>
                {shouldDisplaySpeditionAuswahl && (
                    <FormColumn isSection>
                        <div className={formStyles._selectWithButtonContainer}>
                            <SpeditionAuswahl
                                spedition={speditionOptions}
                                onSpeditionCreated={(spedition): void => {
                                    if (spedition.trim() !== '') {
                                        if (!isValidEmail(spedition)) {
                                            setHasInvalidEmailError(true);
                                            return;
                                        } else {
                                            setHasInvalidEmailError(false);
                                        }
                                    }
                                    dispatch(
                                        addSpedition({
                                            id: TEMPORARY_SPEDITION_ID,
                                            email: spedition,
                                        })
                                    );
                                }}
                                onSpeditionSelected={(spedition): void => {
                                    if (spedition.trim() !== '') {
                                        if (!isValidEmail(getEmailById(spedition))) {
                                            setHasInvalidEmailError(true);
                                            return;
                                        } else {
                                            setHasInvalidEmailError(false);
                                        }
                                    }
                                }}
                            />
                        </div>
                        <NotificationBar
                            testId="invalid-email-message-bar"
                            message="Bitte geben Sie eine gültige Mailadresse ein."
                            isVisible={hasInvalidEmailError}
                        />
                    </FormColumn>
                )}
            </FormRow>
            <FormRow>
                <FormColumn isSection>
                    <div className={formStyles._sectionHeader}>
                        <h3>Angaben des Warenempfängers</h3>
                    </div>
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn isSection>
                    <WunschterminZeitraum />
                </FormColumn>
            </FormRow>
            <FormRow>
                {bestellung.bestellhinweisWE ? (
                    <FormColumn isSection>
                        <TextItem label="Bestellhinweis" text={bestellung.bestellhinweisWE} />
                    </FormColumn>
                ) : null}
                <FormColumn />
            </FormRow>
        </>
    );
};
