import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import Select, { components, OptionTypeBase, SingleValueProps, ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from 'formik';
import { SelectComponents } from 'react-select/src/components';
import styles from './SelectInput.module.scss';
import { MenuList } from '../../content-components/Artikelauswahl/MenuList/MenuList';
import Config from '../../config';
import { createCustomStyles } from './CustomStyles';

const { isApp } = Config.getConfig();

export type SelectInputProps<T extends OptionTypeBase> = Readonly<{
    options: T[];
    id: string;
    name: string;
    isClearable: boolean;
    autoSelectIfOnlyOneOption: boolean;
    nativeClearLabel?: string;
    placeholder?: string;
    onCreateItem?: (inputValue: string) => void;
    formatCreateLabel?: (inputValue: string) => ReactNode;
    setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
    inTableCell?: boolean;
    storniert?: boolean;
    hideNativeSelect?: boolean;
    onValueChange?: (value: string) => void;
    components?: Partial<SelectComponents<OptionTypeBase, false>>;
    menuPlacement?: 'auto' | 'bottom' | 'top';
    menuAlignment?: 'right' | 'left';
    noOptionsMessage?: string;
    inputId?: string;
    maxLength?: number;
    isDisabled?: boolean;
    classNamePrefix?: string;
}>;

export const SingleValue: FunctionComponent<SingleValueProps<OptionTypeBase>> = (props: SingleValueProps<OptionTypeBase>) => {
    return <components.SingleValue {...props}>{props.data.value}</components.SingleValue>;
};

function findSelectedOptionWithFieldValue(
    options: OptionTypeBase[],
    valueToFind: FieldInputProps<string>
): ValueType<OptionTypeBase, false> | undefined {
    if (options) {
        return options.find((option: OptionTypeBase) => option.value === valueToFind.value);
    }
    return undefined;
}

function renderMobileSelect<T extends OptionTypeBase>(
    props: SelectInputProps<T>,
    { onChange, ...field }: FieldInputProps<string>,
    meta: FieldMetaProps<string>,
    setHasBeenDeselectedManually: (deselected: boolean) => void
): JSX.Element {
    const { options, inTableCell, onValueChange, setFieldTouched, isDisabled, placeholder, isClearable, nativeClearLabel, ...selectProps } =
        props;
    const showError = meta.touched && Boolean(meta.error);
    const baseStyles = showError ? [styles._mobileSelect, styles['_mobileSelect--error']] : [styles._mobileSelect];
    const mobileStyles = inTableCell ? [...baseStyles, styles['_mobileSelect--inTableCell']] : baseStyles;
    const { value, ...fieldWithoutValue } = field;
    return (
        <div className={styles._selectContainer}>
            <select
                disabled={isDisabled}
                {...fieldWithoutValue}
                value={value || 'placeholder'}
                onBlur={(event): void => {
                    setFieldTouched && setFieldTouched(field.name, true, true);
                    field.onBlur(event);
                }}
                onChange={(event): void => {
                    if (event.target.value === '') {
                        setHasBeenDeselectedManually(true);
                    }
                    onChange(event);
                    onValueChange && onValueChange(event.target.value);
                }}
                {...selectProps}
                className={mobileStyles.join(' ')}
            >
                {placeholder && (
                    <option value="placeholder" disabled>
                        {placeholder}
                    </option>
                )}
                {isClearable && <option value="">{nativeClearLabel}</option>}
                {options.map((option, index) => (
                    <option value={option.value} key={index}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

function renderSelect<T extends OptionTypeBase>(
    props: SelectInputProps<T>,
    field: FieldInputProps<string>,
    meta: FieldMetaProps<string>,
    helpers: FieldHelperProps<string>,
    setHasBeenDeselectedManually: (deselected: boolean) => void
): JSX.Element {
    const {
        options,
        placeholder,
        inTableCell,
        storniert,
        onValueChange,
        components,
        menuPlacement,
        menuAlignment,
        noOptionsMessage,
        isDisabled,
        isClearable,
        inputId,
        classNamePrefix,
    } = props;
    const showError = meta.touched && Boolean(meta.error);
    const selectStyles = inTableCell ? [styles._select, styles['_select--inTableCell']] : [styles._select];
    return (
        <Select
            classNamePrefix={classNamePrefix}
            options={options}
            name={field.name}
            value={findSelectedOptionWithFieldValue(options, field)}
            onChange={
                // We couldn't come up with a fitting type for the onChange method
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (option: any): void => {
                    if (option === null) {
                        setHasBeenDeselectedManually(true);
                    }
                    helpers.setValue(option.value);
                    onValueChange && onValueChange(option.value);
                }
            }
            onBlur={(event): void => {
                props.setFieldTouched && props.setFieldTouched(field.name, true, true);
                field.onBlur(event);
            }}
            styles={createCustomStyles(showError, inTableCell, menuAlignment, storniert)}
            className={selectStyles.join(' ')}
            placeholder={placeholder}
            menuShouldScrollIntoView={true}
            isDisabled={isDisabled || storniert}
            components={components}
            inputId={inputId}
            menuPlacement={menuPlacement || 'auto'}
            isClearable={isClearable}
            noOptionsMessage={(): string => noOptionsMessage || 'Keine Einträge'}
        />
    );
}

function renderCreatableSelect<T extends OptionTypeBase>(
    props: SelectInputProps<T>,
    field: FieldInputProps<string>,
    meta: FieldMetaProps<string>,
    helpers: FieldHelperProps<string>,
    setHasBeenDeselectedManually: (deselected: boolean) => void
): JSX.Element {
    const {
        isDisabled,
        storniert,
        options,
        placeholder,
        onCreateItem,
        formatCreateLabel,
        inTableCell,
        onValueChange,
        menuAlignment,
        noOptionsMessage,
        maxLength,
        components: componentsFromProps,
        isClearable,
        classNamePrefix,
    } = props;
    const showError = meta.touched && Boolean(meta.error);

    // We couldn't come up with a fitting type for the component Input
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Input = (props: any): JSX.Element => <components.Input {...props} maxLength={maxLength} />;

    return (
        <CreatableSelect
            classNamePrefix={classNamePrefix}
            options={options}
            name={field.name}
            value={findSelectedOptionWithFieldValue(options, field)}
            onChange={
                // We couldn't come up with a fitting type for the onChange method
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (option: any): void => {
                    if (option === null) {
                        setHasBeenDeselectedManually(true);
                    }
                    const newValue = option ? option.value : '';
                    helpers.setValue(newValue);
                    onValueChange && onValueChange(newValue);
                }
            }
            onCreateOption={onCreateItem}
            onBlur={(event): void => {
                props.setFieldTouched && props.setFieldTouched(field.name, true, true);
                field.onBlur(event);
            }}
            styles={createCustomStyles(showError, inTableCell, menuAlignment, storniert)}
            className={styles._select}
            placeholder={placeholder}
            formatCreateLabel={formatCreateLabel}
            noOptionsMessage={(): string => noOptionsMessage || 'Keine Einträge'}
            components={{ ...componentsFromProps, Input }}
            isDisabled={isDisabled || storniert}
            isClearable={isClearable}
        />
    );
}

export function VirtualizedSelectInput<T extends OptionTypeBase>(props: SelectInputProps<T>): JSX.Element {
    return <SelectInput {...props} components={{ MenuList }} />;
}

function SelectInput<T extends OptionTypeBase>(props: SelectInputProps<T>): JSX.Element {
    const [field, meta, helpers] = useField(props);

    // Due to very frequent re-renders and some data being available initially, but other data being fetched after
    // several re-renders, we need to prevent automatic reselection of a single option after it has been manually
    // deselected. This is now handled by the additional state hasBeenDeselectedManually.
    const [hasBeenDeselectedManually, setHasBeenDeselectedManually] = useState(false);

    const { options, onValueChange, autoSelectIfOnlyOneOption } = props;
    useEffect(() => {
        if (autoSelectIfOnlyOneOption && !hasBeenDeselectedManually && options.length === 1) {
            const value = options[0].value;
            if (value && field.value !== value) {
                helpers.setValue(value);
                helpers.setTouched(true);

                onValueChange && onValueChange(value);
            }
        }
    }, [options, onValueChange, helpers, field.value, autoSelectIfOnlyOneOption, hasBeenDeselectedManually]);

    if (window.isMobile && !props.hideNativeSelect && !isApp()) {
        return renderMobileSelect(props, field, meta, setHasBeenDeselectedManually);
    }

    if (props.onCreateItem) {
        return renderCreatableSelect<T>(props, field, meta, helpers, setHasBeenDeselectedManually);
    }

    return renderSelect<T>(props, field, meta, helpers, setHasBeenDeselectedManually);
}

export default SelectInput;
