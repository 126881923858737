import { Context, ValidationError, ValidationRuleObject, ValidationSchema } from 'fastest-validator';
import dayjs from 'dayjs';
import { convertDateWithTimeFromDayJSToDateInfo, convertDateWithTimeFromIsoStringToDateInfo } from './date-helper';
import {
    LIEFERBEDINGUNG_VALIDATION_ERROR,
    LOSE_WARE_BESTELLMENGE_VALIDATION_ERROR,
    SACKWARE_BESTELLMENGE_VALIDATION_ERROR,
    TIME_FORMAT_REGEX,
    WERK_VALIDATION_ERROR,
    WUNSCHTERMIN_VALIDATION_ERRORS,
} from '../constants';
import { loseWareMengePattern, sackwareMengePattern } from './bestellungen-helper';
import type { TourenplanForm, TourenplanSackwareBestellung } from '../../views/Tourenplan/TourenplanTypes';

export function hasNonZeroValue(value1: string, value2: string | undefined): boolean {
    // we need to verify that we got at least one value greater than 0
    // the other value can be 0, "" or undefined -> does not matter
    const v1 = parseInt(value1 ? value1 : '', 10);
    const v2 = parseInt(value2 ? value2 : '', 10);

    const isValidNumber = !isNaN(v1) || !isNaN(v2);

    if (!isValidNumber) {
        return false;
    }

    return v1 > 0 || v2 > 0;
}

type Sackware = 'mengeVe' | 'mengePal';
type Parent = {
    mengeVe: string | undefined;
    mengePal: string | undefined;
    bestellnummer: string;
    nummer: string;
};

function validateMenge(
    context: Context & { data: TourenplanForm },
    parent: Parent,
    value: string,
    errors: ValidationError[],
    fieldName: Sackware,
    errorField: Sackware
) {
    const index = context.data.sackwareBestellungen.findIndex(
        (bestellung: TourenplanSackwareBestellung) =>
            bestellung.bestellnummer === parent?.bestellnummer && bestellung.nummer === parent?.nummer
    );

    const valueIsInvalid = !sackwareMengePattern.test(value);
    const hasValue = hasNonZeroValue(value, parent?.[errorField]);
    if (valueIsInvalid || !hasValue) {
        errors.push({
            field: `sackwareBestellungen[${index}].${fieldName}`,
            message: SACKWARE_BESTELLMENGE_VALIDATION_ERROR,
            type: 'string',
        });
    }
    return value;
}

export const tourenplanValidationSchema = {
    datumDebitor: {
        type: 'custom',
        check: (dateInput: string, errors: ValidationError[]): string => {
            const today = dayjs();
            if (!dayjs(dateInput).isValid() || dayjs(dateInput).isBefore(today, 'day')) {
                const expected = convertDateWithTimeFromDayJSToDateInfo(today).datum;
                const actual = convertDateWithTimeFromIsoStringToDateInfo(dateInput).datum;
                errors.push({
                    type: 'validDate',
                    message: WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_DATE,
                    expected,
                    actual,
                    field: 'datumDebitor',
                });
            }
            return dateInput;
        },
    },
    uhrzeitDebitor: {
        type: 'string',
        pattern: TIME_FORMAT_REGEX,
        messages: {
            stringPattern: WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_TIME,
        },
    },
    lieferbedingung: {
        type: 'string',
        optional: false,
        messages: {
            required: LIEFERBEDINGUNG_VALIDATION_ERROR,
        },
    },
    loseWareBestellungen: {
        type: 'array',
        items: {
            $$type: 'object',
            menge: {
                type: 'string',
                convert: true,
                pattern: loseWareMengePattern,
                nullable: false,
                messages: {
                    stringPattern: LOSE_WARE_BESTELLMENGE_VALIDATION_ERROR,
                },
            },
            bestellnummer: { type: 'string', empty: false },
            werkId: {
                type: 'string',
                empty: false,
                messages: {
                    stringEmpty: WERK_VALIDATION_ERROR,
                    required: WERK_VALIDATION_ERROR,
                },
            },
            silo: { type: 'string', optional: true },
        },
        optional: true,
    },
    sackwareBestellungen: {
        type: 'array',
        items: {
            $$type: 'object',
            mengeVe: {
                type: 'custom',
                check(
                    value: string,
                    errors: ValidationError[],
                    schema: ValidationRuleObject,
                    path: string,
                    parent: TourenplanSackwareBestellung,
                    context: Context & { data: TourenplanForm }
                ) {
                    return validateMenge(context, parent, value, errors, 'mengeVe', 'mengePal');
                },
            },
            mengePal: {
                type: 'custom',
                check(
                    value: string,
                    errors: ValidationError[],
                    schema: ValidationRuleObject,
                    path: string,
                    parent: TourenplanSackwareBestellung,
                    context: Context & { data: TourenplanForm }
                ) {
                    return validateMenge(context, parent, value, errors, 'mengePal', 'mengeVe');
                },
            },
            werkId: {
                type: 'string',
                empty: false,
                messages: {
                    stringEmpty: WERK_VALIDATION_ERROR,
                    required: WERK_VALIDATION_ERROR,
                },
            },
            bestellnummer: { type: 'string', empty: false },
        },
        optional: true,
    },
} as ValidationSchema<TourenplanForm>;
