import { ButtonHTMLAttributes, DetailedHTMLProps, PropsWithChildren, ReactElement, useMemo } from 'react';
import styles from './Button.module.scss';

export type ButtonProps = Readonly<{
    isSecondary?: boolean;
    isSmall?: boolean;
    isFullWidth?: boolean;
    additionalStyles?: string;
}> &
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = (props: PropsWithChildren<ButtonProps>): ReactElement => {
    const { isSecondary, isSmall, isFullWidth, additionalStyles, children, ...attributes } = props;

    const buttonClasses = useMemo(() => {
        const _styles = [styles.button];

        if (isSecondary) {
            _styles.push(styles['button--secondary']);
        }

        if (isSmall) {
            _styles.push(styles['button--small']);
        }

        if (isFullWidth) {
            _styles.push(styles['button--full-width']);
        }

        if (additionalStyles) {
            _styles.push(additionalStyles);
        }

        return _styles.join(' ');
    }, [isSecondary, isSmall, isFullWidth, additionalStyles]);

    return (
        <button {...attributes} className={buttonClasses}>
            {children}
        </button>
    );
};

export default Button;
