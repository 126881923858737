import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { WunschterminWithHookForms } from '../../../shared/content-components/Wunschtermin/WunschterminWithHookForms';
import KfzKennzeichenSelectWithHookForms from '../../../shared/content-components/KfzKennzeichenAuswahl/KfzKennzeichenSelectWithHookForms';
import { addKfzKennzeichen, TEMPORARY_KFZ_ID } from '../../../store/KfzKennzeichen.store';
import { addSpedition, TEMPORARY_SPEDITION_ID, speditionSelectors } from 'store/Spedition.store';
import TourenplanRechnungsempfaenger from './TourenplanRechnungsempfaenger';
import LieferbedingungSelectWithHookForms from '../../../shared/content-components/LieferbedingungSelect/LieferbedingungSelectWithHookForms';
import tourenplanHeaderStyles from './TourenplanHeader.module.scss';
import SpeditionSelectWithHookForms from 'shared/content-components/SpeditionAuswahl/SpeditionSelectWithHookForms';
import { Lieferbedingungen } from '../../../shared/types/enums';
import NotificationBar from '../../../shared/ui-components/NotificationBar/NotificationBar';
import { Option } from '../../../shared/helper/select-options';

const LieferbedingungAndKfzKennzeichenSelect = (): JSX.Element => {
    const dispatch = useDispatch();
    const [showSpeditionSelect, setShowSpeditionSelect] = useState<boolean>(true);

    const handleLieferbedingungChange = (lieferbedingung: string) => {
        setShowSpeditionSelect(lieferbedingung !== Lieferbedingungen.FRANCO);
    };

    const speditionOptions: Option[] = useSelector(speditionSelectors.allAsOptions);

    const [hasInvalidEmailError, setHasInvalidEmailError] = useState(false);

    function isValidEmail(email: string): boolean {
        return /\S+@\S+\.\S+/.test(email);
    }

    const getEmailById = (speditionId: string): string => {
        const selectedSpedition = speditionOptions.find((spedition) => spedition.value === speditionId);
        return selectedSpedition ? (typeof selectedSpedition.label === 'string' ? selectedSpedition.label : '') : '';
    };

    return (
        <div className={tourenplanHeaderStyles._kfzKennzeichenAndLieferbedingung}>
            <div>
                <LieferbedingungSelectWithHookForms onLieferbedingungSelected={handleLieferbedingungChange} />
                {showSpeditionSelect && (
                    <SpeditionSelectWithHookForms
                        onSpeditionCreated={(spedition): void => {
                            if (spedition.trim() !== '') {
                                if (!isValidEmail(spedition)) {
                                    setHasInvalidEmailError(true);
                                    return;
                                } else {
                                    setHasInvalidEmailError(false);
                                }
                            }
                            dispatch(
                                addSpedition({
                                    id: TEMPORARY_SPEDITION_ID,
                                    email: spedition,
                                })
                            );
                        }}
                        onSpeditionSelected={(spedition): void => {
                            if (spedition.trim() !== '') {
                                if (!isValidEmail(getEmailById(spedition))) {
                                    setHasInvalidEmailError(true);
                                    return;
                                } else {
                                    setHasInvalidEmailError(false);
                                }
                            }
                        }}
                    />
                )}
                <NotificationBar
                    testId="invalid-email-message-bar"
                    message="Bitte geben Sie eine gültige Mailadresse ein."
                    isVisible={hasInvalidEmailError}
                />
            </div>
            <div>
                <KfzKennzeichenSelectWithHookForms
                    onKfzKennzeichenCreated={(kfzKennzeichen): void => {
                        dispatch(
                            addKfzKennzeichen({
                                id: TEMPORARY_KFZ_ID,
                                kennzeichen: kfzKennzeichen,
                            })
                        );
                    }}
                />
            </div>
        </div>
    );
};

const TourenplanHeader = (): JSX.Element => {
    return (
        <div className={tourenplanHeaderStyles._container}>
            <div className={tourenplanHeaderStyles._headerAreaWithSelectableElements}>
                <WunschterminWithHookForms minDate={dayjs().format('YYYY-MM-DD')} />
                <LieferbedingungAndKfzKennzeichenSelect />
            </div>
            <TourenplanRechnungsempfaenger />
        </div>
    );
};

export default TourenplanHeader;
