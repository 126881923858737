import { ReactComponent as PrintIcon } from '../../../assets/icon-print.svg';
import ButtonIcon from './ButtonIcon';

export const PrintButton = (): JSX.Element => {
    const handlePrint = () => {
        window.print();
    };

    return (
        <ButtonIcon icon={<PrintIcon stroke={'#6f6f6f'} fill={'#6f6f6f'} strokeWidth={1.5} />} onClick={handlePrint}>
            Drucken
        </ButtonIcon>
    );
};
