import {
    GET_PARTNER_DETAILS_FAILED,
    GET_PARTNER_DETAILS_SUCCEEDED,
    GET_PARTNER_LIST_FAILED,
    GET_PARTNER_LIST_SUCCEEDED,
    GetPartnerDetailsAction,
    GetPartnerListAction,
    UPDATE_PARTNER_FAILED,
    UPDATE_PARTNER_SUCCEEDED,
    UpdatePartnerAction,
} from '../store/Partneruebersicht.store';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData, getDataWithResponseHeaders, postData } from '../shared/fetchApi';
import { routes } from '../store/Workflow.store';
import type { Partner } from '../shared/types';

export function* getPartnerListSaga(action: GetPartnerListAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const [partner, headers] = (yield call(
            getDataWithResponseHeaders,
            `admin/partners?page=${action.page - 1}&size=${action.size}&sort=${action.sortColumn},${action.sortOrder}`
        )) as unknown as [Partner[], Headers];
        const totalCount = headers.get('x-total-count');
        yield put({ type: GET_PARTNER_LIST_SUCCEEDED, partner, totalCount });
    } catch (e) {
        yield put({ type: GET_PARTNER_LIST_FAILED });
    }
}

export function* getPartnerDetailsSaga(action: GetPartnerDetailsAction): Generator<PutEffect | CallEffect> {
    try {
        const url = 'admin/partners/' + action.partnerNummer;
        const partner = yield call(getData, url);
        yield put({ type: GET_PARTNER_DETAILS_SUCCEEDED, partner });
    } catch (e) {
        yield put({ type: GET_PARTNER_DETAILS_FAILED });
    }
}

export function* updatePartnerSaga(action: UpdatePartnerAction): IterableIterator<PutEffect | CallEffect> {
    try {
        yield call(postData, 'admin/partners/' + action.partner.partnerNummer, action.partner, 'PUT');
        yield put({ type: UPDATE_PARTNER_SUCCEEDED, partner: action.partner });
        action.history.push(routes.partner);
    } catch (e) {
        yield put({ type: UPDATE_PARTNER_FAILED, partner: action.partner });
    }
}
