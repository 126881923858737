import { TableCell } from '../../../../ui-components/Table';
import { DeleteListEntry } from './DeleteListEntry';
import { PropertyEntryProps } from '../types';
import { FieldArrayRenderProps } from 'formik';

export const DeleteCell = (
    props: PropertyEntryProps & {
        arrayHelpers: FieldArrayRenderProps;
        onArtikelDeleted?: (artikelNummer: string) => void;
        showConfirmDelete?: boolean;
    }
): JSX.Element => {
    return (
        <TableCell button>
            <DeleteListEntry {...props} />
        </TableCell>
    );
};
