import { CallEffect, PutEffect } from 'redux-saga/effects';
import { getDataWithResponseHeaders } from '../shared/fetchApi';
import type { Dokument } from '../shared/types';
import { GetDokumenteAction, getDokumenteFailed, getDokumenteSucceeded } from '../store/Dokument.store';
import { PayloadAction } from '@reduxjs/toolkit';
import { convertFilterToString } from './utils/sagaHelperFunctions';
import { SagaGenerator, call, put } from 'typed-redux-saga';

type GetDokumenteWithHeaders = (url: string) => Promise<[Dokument[], Headers]>;

export function* getDokumenteSaga(action: PayloadAction<GetDokumenteAction>): SagaGenerator<void, PutEffect | CallEffect> {
    const { page, size, sortColumn, sortOrder, keyword } = action.payload;

    const filter = convertFilterToString(action.payload.filter);
    const url = `dokumente?page=${page}&size=${size}${keyword ? `&keyword=${keyword}` : ''}&sort=${sortColumn},${sortOrder}${filter}`;

    try {
        const [dokumente, headers] = yield* call<[string], GetDokumenteWithHeaders>(getDataWithResponseHeaders, url);

        const totalCount = Number(headers.get('x-total-count'));
        if (isNaN(totalCount)) {
            yield* put(getDokumenteFailed());
        } else {
            yield* put(getDokumenteSucceeded({ dokumente, totalCount }));
        }
    } catch (errors: unknown) {
        yield* put(getDokumenteFailed());
    }
}
