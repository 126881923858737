import { useEffect } from 'react';

export const TITLE_PREFIX = 'DEU·PA - ';

type UseDocumentTitleOptions = {
    disablePrefix?: boolean;
};

export default function useDocumentTitle(title: string, { disablePrefix }: UseDocumentTitleOptions = {}): void {
    useEffect(() => {
        document.title = disablePrefix ? title : TITLE_PREFIX + title;
    }, [title, disablePrefix]);
}
