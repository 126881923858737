import SelectInput from '../../ui-components/SelectInput/SelectInput';
import formStyles from '../../ui-components/Form/Form.module.scss';
import FormControl from '../../ui-components/FormControl/FormControl';
import TextInput from '../../ui-components/TextInput/TextInput';
import { Option, tageszeitOptions } from '../../helper/select-options';
import { Lieferbedingungen } from '../../types/enums';
import type { WunschterminProps } from './wunschtermin-helper';
import { WunschterminLabels } from './labels';

export function createWunschterminLabelByLieferbedingung(lieferbedingung?: string): string | undefined {
    if (!lieferbedingung) {
        return;
    }

    if (lieferbedingung === Lieferbedingungen.EX_WERK) {
        return `${WunschterminLabels.WUNSCHABHOLTERMIN} *`;
    } else {
        return `${WunschterminLabels.WUNSCHLIEFERTERMIN} *`;
    }
}

export const WunschterminTageszeit = ({
    minDate,
    dateFieldName = 'datumDebitor',
    timeFieldName = 'uhrzeitDebitor',
    isDateRequired = true,
    isTimeRequired = true,
    label = WunschterminLabels.WUNSCHABHOL_BZW_LIEFERTERMIN,
}: WunschterminProps): JSX.Element => {
    const requiredString = ' *';
    const dateLabel = `${label}${isDateRequired ? requiredString : ''}`;
    const timeLabel = `${WunschterminLabels.TAGESZEIT}${isTimeRequired ? requiredString : ''}`;
    return (
        <div data-cy={'form-lw-wunschtermin'} className={[formStyles._terminContainer, formStyles['_terminContainer--zeitraum']].join(' ')}>
            <FormControl
                name={dateFieldName}
                id={dateFieldName}
                label={dateLabel}
                renderChild={(props): JSX.Element => <TextInput {...props} type="date" placeholder="jjjj-mm-tt" min={minDate} />}
            />
            <FormControl
                name={timeFieldName}
                id={timeFieldName}
                label={timeLabel}
                renderChild={(props): JSX.Element => (
                    <SelectInput<Option>
                        {...props}
                        options={tageszeitOptions}
                        placeholder={WunschterminLabels.SELECT_PLACEHOLDER}
                        isClearable={false}
                        autoSelectIfOnlyOneOption={true}
                        classNamePrefix={'react_select__form-tageszeit'}
                    />
                )}
            />
        </div>
    );
};
