import { HTMLAttributes, PropsWithChildren, ReactElement, useMemo } from 'react';
import styles from './ButtonGroup.module.scss';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

export enum ButtonGroupAlignment {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export type Props = HTMLAttributes<HTMLElement> &
    Readonly<{
        alignment?: ButtonGroupAlignment;
        isWithinCell?: boolean;
    }>;

const ButtonGroup = ({
    className: additionalClassNames,
    alignment = ButtonGroupAlignment.RIGHT,
    isWithinCell = false,
    children,
}: PropsWithChildren<Props>): ReactElement => {
    const isMobile = useBreakpoint(Breakpoint.MOBILE);

    const classNames = useMemo(() => {
        const classes = [styles._buttonGroup, additionalClassNames];

        switch (alignment) {
            case ButtonGroupAlignment.LEFT:
                classes.push(styles['_buttonGroup--leftAligned']);
                break;
            case ButtonGroupAlignment.RIGHT:
                classes.push(styles['_buttonGroup--rightAligned']);
                break;
            case ButtonGroupAlignment.CENTER: {
                if (isMobile) {
                    classes.push(styles['_buttonGroup--mobile']);
                }
                classes.push(styles['_buttonGroup--centerAligned']);
                break;
            }
        }

        if (isWithinCell) {
            classes.push(styles['_buttonGroup--withinCell']);
        }

        return classes.join(' ');
    }, [additionalClassNames, alignment, isWithinCell, isMobile]);
    return <div className={classNames}>{children}</div>;
};

export default ButtonGroup;
