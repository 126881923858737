import { useSelector } from 'react-redux';
import { PropsWithChildren, useEffect } from 'react';
import { selectBestellungIsUpdating, selectBestellungUpdateFailed } from '../../store/Bestellung.store';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { SAMMELBESTELLUNG_ERROR } from '../../shared/constants';
import { useFormContext } from 'react-hook-form';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { kfzKennzeichenSelectors } from '../../store/KfzKennzeichen.store';
import { speditionSelectors } from '../../store/Spedition.store';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { SuccessfulBestellungButtons } from './components/SuccessfulBestellungButtons';
import useConfirmExitPrompt from '../../shared/hooks/useConfirmExitPrompt';
import type { Rechnungsempfaenger } from '../../shared/types';
import type { TourenplanForm } from './TourenplanTypes';
import styles from './TourenplanBestelluebermittlung.module.scss';

export type TourenplanFormWithRechnungsempfaengerList = TourenplanForm & {
    rechnungsempfaenger: Rechnungsempfaenger[];
};

export const TourenplanBestelluebermittlung = ({ children }: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
    const { formState, reset, getValues, watch } = useFormContext<TourenplanForm>();
    const rechnungsempfaengerList = useSelector(BestelluebersichtSelectors.uniqueRechnungsempfaengerFromCurrentSammelbestellungen);
    const updateBestellungBatchRequestFailed = useSelector(selectBestellungUpdateFailed);
    const allKfzKennzeichen = useSelector(kfzKennzeichenSelectors.allKfzKennzeichen);
    const allSpeditionen = useSelector(speditionSelectors.allSpeditionen);
    const isUpdating = useSelector(selectBestellungIsUpdating);
    const { isSubmitSuccessful: formIsValidAndSubmitted } = formState;
    const formAndUpdateBestellungRequestWereSuccessful = formIsValidAndSubmitted && !updateBestellungBatchRequestFailed;

    watch(['loseWareBestellungen']);
    const [kfzKennzeichenId] = watch(['kfzKennzeichen']);
    const [speditionId] = watch(['spedition']);
    const tourenplanFormWithRechnungsempfaengerList: TourenplanFormWithRechnungsempfaengerList = {
        ...getValues(),
        // Include the Rechnungsempfaenger list in the TourenplanFormData for the excel generation
        rechnungsempfaenger: rechnungsempfaengerList,
        // Include the actual kfzKennzeichen in the TourenplanFormData for the excel generation instead of the id
        kfzKennzeichen: allKfzKennzeichen.find((kennzeichen) => kennzeichen.id === kfzKennzeichenId)?.kennzeichen ?? '',
        // Include the actual spedition in the TourenplanFormData for the excel generation instead of the id
        spedition: allSpeditionen.find((spedition) => spedition.id === speditionId)?.email ?? '',
    };

    useEffect(() => {
        if (formIsValidAndSubmitted && updateBestellungBatchRequestFailed) {
            // We reset the formState if the request failed so that the user can edit and send the form again
            reset(
                {},
                {
                    keepValues: true,
                }
            );
        }
    }, [formIsValidAndSubmitted, updateBestellungBatchRequestFailed, reset]);

    const beforeOrderingConfirmText = `Sind Sie sicher?

Sie haben den Tourenplan nicht fertiggestellt. Ihre Fortschritte gehen verloren.`;

    const afterOrderingConfirmText = `Sind Sie sicher?

Der Download der Excel-Datei und die Druckfunktion stehen Ihnen für diesen Tourenplan nicht mehr zur Verfügung.

Die Dateien werden nicht gespeichert.`;

    useConfirmExitPrompt(!formIsValidAndSubmitted ? beforeOrderingConfirmText : afterOrderingConfirmText);

    return (
        <div className={styles._container}>
            {formAndUpdateBestellungRequestWereSuccessful &&
                (isUpdating ? (
                    <h2>Bestellung wird übermittelt</h2>
                ) : (
                    <>
                        <h2>Vielen Dank für Ihre Bestellung</h2>
                        <p>Sie erhalten in Kürze die Bestellzusammenfassung per E-Mail.</p>
                    </>
                ))}
            <LoadingSpinner isLoading={isUpdating}>
                <>
                    <NotificationBar
                        dataCy="sammelbestellung-error-message-bar"
                        message={SAMMELBESTELLUNG_ERROR}
                        isVisible={updateBestellungBatchRequestFailed}
                    />
                    {formAndUpdateBestellungRequestWereSuccessful ? (
                        <SuccessfulBestellungButtons data={tourenplanFormWithRechnungsempfaengerList} />
                    ) : (
                        children
                    )}
                </>
            </LoadingSpinner>
        </div>
    );
};
