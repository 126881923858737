import { ReactElement, useState } from 'react';
import styles from './Accordion.module.scss';
import { SubVorgang } from '../../types';
import Vorgangsansicht from '../Vorgangsansicht/Vorgangsansicht';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icon-arrow-down.svg';
import dayjs from 'dayjs';

export type Props = {
    name: string;
    firstDate: string;
    firstDateShort: string;
    lastDate: string;
    lastDateShort: string;
    content: SubVorgang[];
};
const Accordion = (props: Props): ReactElement => {
    const [isActive, setIsActive] = useState(false);
    const sortedContent = [...props.content].sort((a, b) => {
        const dateA = new Date(a.datum);
        const dateB = new Date(b.datum);
        return dateB.getTime() - dateA.getTime();
    });

    const subVorgangList = sortedContent.map((subVorgang) => {
        const parsedDate = dayjs(subVorgang.datum);
        const date = parsedDate.format('DD.MM.YYYY');
        const time = parsedDate.format('HH:mm');
        return (
            <Vorgangsansicht
                key={subVorgang.detailcode}
                date={date}
                time={time}
                reference={subVorgang.betreff}
                message={subVorgang.text}
                documents={subVorgang.dokumente}
            />
        );
    });

    return (
        <div className={styles._accordion}>
            <div className={styles._accordionHeader} onClick={() => setIsActive(!isActive)}>
                <div className={styles._accordionName}>
                    <span className={styles._rationsserviceText}>Rationsservice&nbsp;</span>&quot;{props.name}&quot;&nbsp;
                    <span className={styles._accordionDate}>
                        <span className={styles._longDate}>
                            ({props.firstDate} - {props.lastDate})
                        </span>
                        <span className={styles._shortDate}>
                            ({props.firstDateShort} - {props.lastDateShort})
                        </span>
                    </span>
                </div>
                <div className={styles._accordionExtendButton}>
                    {isActive ? <ArrowDownIcon className={styles._arrowUpIcon} /> : <ArrowDownIcon />}
                </div>
            </div>
            {isActive && (
                <div className={styles.accordionContent}>
                    <ul>{subVorgangList}</ul>
                </div>
            )}
        </div>
    );
};

export default Accordion;
