import { PropertyEntryReadonlyProps } from '../types';
import TextItem from '../../../../ui-components/TextItem/TextItem';
import { BestellpositionArtikelLoseWareView } from '../../../../types';
import FormControl from '../../../../ui-components/FormControl/FormControl';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import formStyles from '../../../../ui-components/Form/Form.module.scss';
import { WunschterminLabels } from '../../../Wunschtermin/labels';
import { getWunschterminDatumText } from '../../../../helper/date-helper';

export const WunschterminListEntry = ({
    readonly = false,
    disabled = false,
    bestellposition,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly) {
        const wunschterminText = getWunschterminDatumText(bestellposition.wunschtermin);
        return <TextItem label="Wunschtermin" text={wunschterminText} isStorniert={bestellposition.storno} />;
    }
    return (
        <div className={formStyles._terminContainer}>
            <FormControl
                label={WunschterminLabels.ABHOL_BZW_LIEFERTERMIN}
                name={`bestellpositionenView[${bestellposition.index}].debitorDatum`}
                id={`bestellpositionenView[${bestellposition.index}].debitorDatum`}
                renderChild={(props): JSX.Element => (
                    <TextInput {...props} type="date" placeholder="jjjj-mm-tt" disabled={disabled} storniert={bestellposition.storno} />
                )}
            />
            <FormControl
                name={`bestellpositionenView[${bestellposition.index}].debitorUhrzeit`}
                id={`bestellpositionenView[${bestellposition.index}].debitorUhrzeit`}
                label={WunschterminLabels.UHRZEIT}
                renderChild={(props): JSX.Element => (
                    <TextInput {...props} type="time" placeholder="hh:mm" disabled={disabled} storniert={bestellposition.storno} />
                )}
            />
        </div>
    );
};
