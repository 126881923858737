import { ReactNode } from 'react';
import CreatableSelectWithHookForm from '../../ui-components/SelectInput/CreatableSelectWithHookForms';
import { useSelector } from 'react-redux';
import { speditionSelectors } from '../../../store/Spedition.store';
import NotificationBar from '../../ui-components/NotificationBar/NotificationBar';
import { SPEDITION_LOADING_ERROR } from '../../constants';
import { useFetchSpedition } from '../../hooks/useFetchSpedition';
import styles from './SpeditionAuswahl.module.scss';

export type SpeditionSelectWithHookFormsProps = Readonly<{
    onSpeditionCreated: (spedition: string) => void;
    onSpeditionSelected: (spedition: string) => void;
}>;

const SpeditionSelectWithHookForms = ({ onSpeditionCreated, onSpeditionSelected }: SpeditionSelectWithHookFormsProps): JSX.Element => {
    const SpeditionOptions = useSelector(speditionSelectors.allAsOptions);
    const isLoading = useSelector(speditionSelectors.isLoading);
    const hasLoadError = useSelector(speditionSelectors.hasSubmitError);

    useFetchSpedition();

    return (
        <div>
            <label htmlFor="spedition">Spediteur</label>
            <NotificationBar isVisible={hasLoadError} message={SPEDITION_LOADING_ERROR} />
            <CreatableSelectWithHookForm
                isLoading={isLoading}
                options={SpeditionOptions}
                onCreateOption={onSpeditionCreated}
                onValueChange={onSpeditionSelected}
                formatCreateLabel={(inputValue: string): ReactNode => {
                    return <div className={styles._newSelectOption}>{`"${inputValue}" anlegen`}</div>;
                }}
                placeholder={'SpediteurAuswahl'}
                maxLength={350}
                isClearable={true}
                autoSelectIfOnlyOneOption={false}
                name="spedition"
            />
        </div>
    );
};

export default SpeditionSelectWithHookForms;
