import { BestellpositionArtikelSackwareView } from '../../../types';
import 'tippy.js/dist/tippy.css';
import { TableRow } from '../../../ui-components/Table';
import { BezeichnungEntry, MengeSWEntry } from '../PropertyEntries';

export type SackwareTableGroupedRowProps = Readonly<{
    index: number;
    bestellposition: BestellpositionArtikelSackwareView;
    formikRowPath: string;
    withSollbestaende: boolean;
    onApplyBestellvorschlag?: () => void;
    bestellvorschlagVE?: number;
}>;

export const SackwareTableGroupedRow = ({
    bestellposition,
    formikRowPath,
    onApplyBestellvorschlag,
    bestellvorschlagVE,
    withSollbestaende,
}: SackwareTableGroupedRowProps): JSX.Element => {
    return (
        <TableRow key={bestellposition.index}>
            <BezeichnungEntry.Cell bestellposition={bestellposition} readonly />
            <MengeSWEntry.Sollbestaende
                bestellposition={bestellposition}
                formikRowPath={formikRowPath}
                onApplyBestellvorschlag={onApplyBestellvorschlag}
                bestellvorschlagVE={bestellvorschlagVE}
                withSollbestaende={withSollbestaende}
            />
        </TableRow>
    );
};
