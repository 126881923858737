import { ArtikelSackware } from '../shared/types';
import { ArtikelTyp, Bestellprozesstyp, RequestState } from '../shared/types/enums';
import type { RootState } from '../configureStore';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GetArtikelSackwareParams {
    bestellprozesstyp: Bestellprozesstyp;
    rechnungsempfaengerPartnerNummer: string;
    warenempfaengerPartnerNummer?: string;
    werkId?: string;
}

export type ArtikelSackwareState = {
    artikel: ArtikelSackware[];
    loadingState: RequestState;
};

export const INITIAL_ARTIKELSACKWARE_STATE: ArtikelSackwareState = {
    artikel: [],
    loadingState: RequestState.INITIAL,
};

const selectArtikelSackware = (state: RootState) => state.artikelSackware;
const selectArtikelSackwareList = (state: RootState): ArtikelSackware[] => selectArtikelSackware(state).artikel;
const selectArtikelSackwareLoaded = (state: RootState): boolean => selectArtikelSackware(state).loadingState === RequestState.SUCCESSFUL;
const selectArtikelSackwareIsLoading = (state: RootState): boolean => selectArtikelSackware(state).loadingState === RequestState.LOADING;
const selectArtikelSackwareLoadFailed = (state: RootState): boolean => selectArtikelSackware(state).loadingState === RequestState.FAILED;

export const ArtikelSackwarenSelectors = {
    list: selectArtikelSackwareList,
    loading: selectArtikelSackwareIsLoading,
    loaded: selectArtikelSackwareLoaded,
    loadFailed: selectArtikelSackwareLoadFailed,
};

const artikelSackwareSlice = createSlice({
    name: 'artikelSackware',
    initialState: INITIAL_ARTIKELSACKWARE_STATE,
    reducers: {
        getArtikelSackware(state, _: PayloadAction<GetArtikelSackwareParams>) {
            state.loadingState = RequestState.LOADING;
        },
        getArtikelSackwareSucceeded(state, action: PayloadAction<ArtikelSackware[]>) {
            state.artikel = action.payload.map((a) => ({ ...a, artikelTyp: ArtikelTyp.Sackware }));
            state.loadingState = RequestState.SUCCESSFUL;
        },
        getArtikelSackwareFailed(state, _) {
            state.artikel = [];
            state.loadingState = RequestState.FAILED;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('bestellung/setWarenempfaenger', () => INITIAL_ARTIKELSACKWARE_STATE);
        builder.addCase('bestellung/setRechnungsempfaenger', () => INITIAL_ARTIKELSACKWARE_STATE);
    },
});

export const { getArtikelSackwareSucceeded, getArtikelSackwareFailed, getArtikelSackware } = artikelSackwareSlice.actions;
export const artikelSackwareReducer = artikelSackwareSlice.reducer;
