import { PropertyEntryReadonlyProps } from '../types';
import TextItem from '../../../../ui-components/TextItem/TextItem';
import Formulas from '../../../../../shared/formulas';
import { BestellpositionArtikelSackwareView } from '../../../../types';
import FormControl from '../../../../ui-components/FormControl/FormControl';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { TextInputRule } from '../../../../types/enums';

export const MengeSWListEntry = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelSackwareView>): JSX.Element => {
    const { mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe, storno } = bestellposition;

    const overallVECount = Formulas.overallVerkaufseinheitCount(mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe);

    if (readonly) {
        return (
            <>
                <TextItem
                    label={
                        <>
                            Menge VE
                            <br />({faktorBasiseinheitVe}kg)
                        </>
                    }
                    text={mengeVe}
                    isStorniert={storno}
                />
                <TextItem
                    label={
                        <>
                            Menge Pal.
                            <br />({faktorBasiseinheitPal}kg / Pal.)
                        </>
                    }
                    text={mengePal}
                    isStorniert={storno}
                />
                <TextItem label="Gesamt VE" text={overallVECount} isStorniert={storno} />
            </>
        );
    }

    return (
        <>
            <FormControl
                name={`bestellpositionenView[${bestellposition.index}].mengeVe`}
                id={`bestellpositionenView[${bestellposition.index}].mengeVe`}
                label={`Menge VE (${faktorBasiseinheitVe}kg) *`}
                renderChild={(props): JSX.Element => (
                    <TextInput
                        {...props}
                        type="number"
                        textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                        data-testid={`${bestellposition.nummer}-ve`}
                        disabled={disabled}
                        storniert={storno}
                    />
                )}
            />
            <FormControl
                name={`bestellpositionenView[${bestellposition.index}].mengePal`}
                id={`bestellpositionenView[${bestellposition.index}].mengePal`}
                label={`Menge Pal. (${faktorBasiseinheitPal}kg / Pal.) *`}
                renderChild={(props): JSX.Element => (
                    <TextInput
                        {...props}
                        type="number"
                        textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                        data-testid={`${bestellposition.nummer}-ve`}
                        disabled={disabled}
                        storniert={storno}
                    />
                )}
            />
            <FormControl
                name={`bestellpositionenView[${bestellposition.index}].overallVECount`}
                id={`bestellpositionenView[${bestellposition.index}].overallVECount`}
                label="Gesamt VE"
                renderChild={(props): JSX.Element => (
                    <TextInput
                        {...props}
                        type="number"
                        value={overallVECount}
                        textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                        data-testid={`${bestellposition.nummer}-ve`}
                        disabled
                        storniert={storno}
                    />
                )}
            />
        </>
    );
};
