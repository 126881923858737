import tableStyles from '../Table/Table.module.scss';
import localStyles from './OverviewList.module.scss';
import { Row, TableInstance } from 'react-table';
import { noop } from '../../helper/noop';

type OverviewListBodyProps<T extends Record<string, unknown>> = {
    tableInstance: TableInstance<T>;
    mobileColumns: string[];
    isPaginationOn?: boolean;
    onRowClick?: (row: Row<T>) => void;
};

export function OverviewListBody<T extends Record<string, unknown>>(props: OverviewListBodyProps<T>): JSX.Element {
    const { tableInstance, mobileColumns, isPaginationOn = true, onRowClick = noop } = props;
    const { prepareRow } = tableInstance;
    const rows = isPaginationOn ? tableInstance.page : tableInstance.rows;

    return (
        <div className={tableStyles._overviewContainer}>
            <div>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <div
                            {...row.getRowProps()}
                            className={localStyles._row}
                            key={row.index}
                            onClick={(): void => {
                                onRowClick(row);
                            }}
                        >
                            {row.cells
                                .filter((c) => mobileColumns.includes(c.column.id))
                                .map((cell, index) => {
                                    return (
                                        <div {...cell.getCellProps()} key={index}>
                                            {cell.render('Cell', { isMobile: true })}
                                        </div>
                                    );
                                })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
