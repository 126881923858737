import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../store/Navigation.store';
import { useMemo } from 'react';
import { date, object, string } from 'yup';
import {
    createBestellpositionViewSchemaExistingBestellung,
    createDateSchema,
    createTimeSchema,
} from '../../shared/validation/bestellung/validation';
import { minDate } from '../../shared/helper/date-helper';
import { datumWEBisSchema, uhrzeitWEBisSchema } from '../../shared/validation/bestellung/schemas';
import { BestellpositionView } from '../../shared/types';
import { isSackwareView } from '../../shared/helper/bestellungen-helper';
import { WUNSCHTERMIN_VALIDATION_ERRORS } from '../../shared/constants';

// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBestellansichtFormSchema = (storniereBestellung: boolean) => {
    const isDebitor = useSelector(selectIsDebitor);
    return useMemo(() => {
        if (!storniereBestellung) {
            const DebitorSchema = object({
                bestellpositionenView: createBestellpositionViewSchemaExistingBestellung(isDebitor),
                datumDebitor: date().when('bestellpositionenView', {
                    is: (bestellpositionenView: BestellpositionView[]) => {
                        const sackwaren = bestellpositionenView.filter(isSackwareView);
                        return sackwaren.length > 0 && sackwaren.some((sackware) => !sackware.storno);
                    },
                    then: () => createDateSchema(minDate).required(WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_DATE),
                    otherwise: (schema) => schema.notRequired(),
                }),
                uhrzeitDebitor: string().when('bestellpositionenView', {
                    is: (bestellpositionenView: BestellpositionView[]) => {
                        const sackwaren = bestellpositionenView.filter(isSackwareView);
                        return sackwaren.length > 0 && sackwaren.some((sackware) => !sackware.storno);
                    },
                    then: () => createTimeSchema().required(WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_TIME),
                    otherwise: (schema) => schema.notRequired(),
                }),
                datumWEVon: createDateSchema(minDate, false),
                datumWEBis: datumWEBisSchema,
                uhrzeitWEVon: createTimeSchema(false),
                uhrzeitWEBis: uhrzeitWEBisSchema,
            });

            const WarenempfaengerSchema = object({
                bestellpositionenView: createBestellpositionViewSchemaExistingBestellung(isDebitor),
                datumWEVon: createDateSchema(minDate),
                datumWEBis: datumWEBisSchema,
                uhrzeitWEVon: createTimeSchema(false),
                uhrzeitWEBis: uhrzeitWEBisSchema,
            });

            return isDebitor ? DebitorSchema : WarenempfaengerSchema;
        }
        return null;
    }, [isDebitor, storniereBestellung]);
};
