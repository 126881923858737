import { Dispatch, ReactElement, SetStateAction } from 'react';
import styles from './SearchInput.module.scss';

type SearchInputProps = {
    searchAction: (keyword: string | undefined) => void;
    keyword?: string;
    setKeyword: Dispatch<SetStateAction<string | undefined>>;
};

export const SearchInput = ({ keyword, setKeyword }: SearchInputProps): ReactElement => {
    return (
        <div className={styles._search}>
            <input
                autoFocus
                placeholder={'Suche...'}
                className={styles._input}
                type={'search'}
                id={'search'}
                value={keyword}
                onChange={(event) => {
                    event.preventDefault();
                    setKeyword(event.target.value);
                }}
            />
        </div>
    );
};
