import { FormikErrors, FormikValues } from 'formik';
import { ArtikelTableValues, BestellpositionArtikelLoseWareView, BestellpositionArtikelSackwareView } from '../../types';

export const errorForBestellpositionsField = (
    errors: FormikErrors<ArtikelTableValues>,
    fieldName: keyof (BestellpositionArtikelSackwareView & BestellpositionArtikelLoseWareView)
): [boolean, string | undefined] => {
    const bestellpositionenErrors = errors.bestellpositionenView;
    if (!bestellpositionenErrors || !Array.isArray(bestellpositionenErrors)) {
        return [false, undefined];
    }

    // NOTE: We need to ts-ignore here because typescript does not recognize the "find" method on array union types
    //       in this case for: string[] | FormikErrors<BestellpositionView>[]
    // @ts-ignore https://github.com/microsoft/TypeScript/issues/44373
    const errorForField = bestellpositionenErrors.find((error) => {
        return error && error.hasOwnProperty(fieldName);
    });

    if (errorForField) {
        return [true, errorForField[fieldName]];
    }
    return [false, undefined];
};

/**
 * @deprecated use errorForBestellpositionsField instead
 */
export const errorForFieldExist = (errors: FormikErrors<FormikValues>, fieldName: string): boolean => {
    const bestellpositionenErrors = errors['bestellpositionenView'] as { [key: string]: string | string[] }[];

    const errorWerk =
        Array.isArray(bestellpositionenErrors) &&
        bestellpositionenErrors?.some((errorEntry: { [key: string]: string | string[] }) => errorEntry?.hasOwnProperty(fieldName));
    return Boolean(errorWerk);
};
