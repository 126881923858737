import { BestellpositionView } from '../../types';
import SelectInput from '../../ui-components/SelectInput/SelectInput';
import { Option } from '../../helper/select-options';

export const WerkeAuswahl = ({
    bestellposition,
    options,
    disabled,
}: {
    bestellposition: BestellpositionView;
    disabled?: boolean;
    options: Option[];
}): JSX.Element => {
    return (
        <SelectInput
            storniert={bestellposition.storno}
            isDisabled={disabled}
            classNamePrefix={'react_select__form-werk'}
            id={`bestellpositionenView[${bestellposition.index}].werkId`}
            name={`bestellpositionenView[${bestellposition.index}].werkId`}
            options={options}
            placeholder="Auswahl Werk"
            isClearable={false}
            inTableCell
            autoSelectIfOnlyOneOption
        />
    );
};
