import dayjs, { Dayjs, extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

extend(utc);
extend(timezone);

export const minDate = dayjs().format('YYYY-MM-DD');

export const getISOWunschtermin = (datum?: string, uhrzeit?: string): string | undefined => {
    // needed if time comes from text input (safari and IE)
    const addSeconds = uhrzeit && uhrzeit.length <= 5 ? ':00' : '';

    if (datum && uhrzeit) {
        return dayjs(`${datum}T${uhrzeit}${addSeconds}`).toISOString();
    }

    if (datum) {
        return dayjs(datum).toISOString();
    }

    return undefined;
};

export type DateAndTime = { datum: string; uhrzeit: string | undefined; iso: string };

export const extractDateAndTimeFromDateString = (dateString?: string): DateAndTime => {
    const localDate = dayjs(dateString);

    if (!dateString || !localDate.isValid()) {
        return {
            datum: '',
            uhrzeit: '',
            iso: '',
        };
    }

    return {
        datum: localDate.format('YYYY-MM-DD'),
        uhrzeit: localDate.format('HH:mm'),
        iso: localDate.toISOString(),
    };
};

export const getWunschterminDatumText = (wunschterminIsoString?: string): string => {
    const dateAndTime = convertDateWithTimeFromIsoStringToDateInfo(wunschterminIsoString);
    return `${dateAndTime.datum} ${dateAndTime.uhrzeit}`;
};

export type DateInfo = {
    datum: string;
    uhrzeit: string;
    uhrzeitIsSet: boolean;
    tageszeit: string;
};
export const EMPTY_DATEINFO: DateInfo = {
    tageszeit: '',
    datum: '',
    uhrzeit: '',
    uhrzeitIsSet: false,
};

export const convertDateWithTimeFromIsoStringToDateInfo = (isoString?: string): DateInfo => {
    const localDate = dayjs(isoString);
    if (isoString && localDate.isValid()) {
        const datum = localDate.format('DD.MM.YYYY');
        const uhrzeit = localDate.format('HH:mm') + ' Uhr';
        const utcHour = dayjs(isoString).subtract(dayjs(isoString).utcOffset(), 'minute').hour();
        const uhrzeitIsSet = utcHour > 0 || (localDate.hour() === 0 && localDate.minute() > 0);
        const tageszeit = localDate.hour() >= 12 ? 'Nachmittags' : 'Vormittags';
        return { datum, uhrzeit, uhrzeitIsSet, tageszeit };
    } else {
        return EMPTY_DATEINFO;
    }
};

export const convertDateWithTimeFromDayJSToDateInfo = (date: Dayjs): DateInfo => {
    return convertDateWithTimeFromIsoStringToDateInfo(date.toISOString());
};

export const getWunschterminZeitraumDisplayText = (wunschterminISOWEVon?: string, wunschterminISOWEBis?: string): string => {
    const {
        datum: wunschterminWEVon,
        tageszeit: wunschtageszeitWEVon,
        uhrzeitIsSet: uhrzeitIsSetWEVon,
    } = convertDateWithTimeFromIsoStringToDateInfo(wunschterminISOWEVon);

    const {
        datum: wunschterminWEBis,
        tageszeit: wunschtageszeitWEBis,
        uhrzeitIsSet: uhrzeitIsSetWEBis,
    } = convertDateWithTimeFromIsoStringToDateInfo(wunschterminISOWEBis);

    if (!wunschterminWEVon) {
        return '';
    }
    const wunschterminVonDisplayText = `Von ${wunschterminWEVon}${uhrzeitIsSetWEVon ? ` ${wunschtageszeitWEVon}` : ''}`;

    if (!wunschterminWEBis) {
        return wunschterminVonDisplayText;
    }
    return `${wunschterminVonDisplayText} Bis ${wunschterminWEBis}${uhrzeitIsSetWEBis ? ` ${wunschtageszeitWEBis}` : ''}`;
};
