import { useMemo } from 'react';
import {
    createBestellpositionViewSchemaNewBestellung,
    createDateSchema,
    createTimeSchema,
} from '../../shared/validation/bestellung/validation';
import { minDate } from '../../shared/helper/date-helper';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../store/Navigation.store';
import { object } from 'yup';
import { datumWEBisSchema, uhrzeitWEBisSchema } from '../../shared/validation/bestellung/schemas';

// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useArtikelNutztierFormularSchema = () => {
    const isDebitor = useSelector(selectIsDebitor);

    return useMemo(() => {
        const DebitorSchema = object({
            bestellpositionenView: createBestellpositionViewSchemaNewBestellung(true),
            datumDebitor: createDateSchema(minDate),
            uhrzeitDebitor: createTimeSchema(),
        });

        const WarenempfaengerSchema = object({
            bestellpositionenView: createBestellpositionViewSchemaNewBestellung(false),
            datumWEVon: createDateSchema(minDate),
            datumWEBis: datumWEBisSchema,
            uhrzeitWEVon: createTimeSchema(false),
            uhrzeitWEBis: uhrzeitWEBisSchema,
        });

        return isDebitor ? DebitorSchema : WarenempfaengerSchema;
    }, [isDebitor]);
};
