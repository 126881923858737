import { CallEffect, PutEffect } from 'redux-saga/effects';
import { getDataWithResponseHeaders } from '../shared/fetchApi';
import type { Dokument } from '../shared/types';
import { GetDokumenteAction, getDokumenteFailed, getDokumenteSucceeded } from '../store/DokumentSilageergebnisse.store';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaGenerator, call, put } from 'typed-redux-saga';

type GetDokumenteWithHeaders = (url: string) => Promise<[Dokument[], Headers]>;

export function* getDokumenteSilageergebnisseSaga(action: PayloadAction<GetDokumenteAction>): SagaGenerator<void, PutEffect | CallEffect> {
    const { page, size, sortColumn, sortOrder } = action.payload;

    const url = `dokumente?dokumentArt=SILAGEERGEBNIS&page=${page}&size=${size}&sort=${sortColumn},${sortOrder}`;

    try {
        const [dokumente, headers] = yield* call<[string], GetDokumenteWithHeaders>(getDataWithResponseHeaders, url);

        const totalCount = Number(headers.get('x-total-count'));
        if (isNaN(totalCount)) {
            yield* put(getDokumenteFailed());
        } else {
            yield* put(getDokumenteSucceeded({ dokumente, totalCount }));
        }
    } catch (errors: unknown) {
        yield* put(getDokumenteFailed());
    }
}
