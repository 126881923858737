import { BestellpositionArtikelSackwareView, BestellpositionView } from '../../types';
import {
    hasAenderbarBisExpiredForBestellposition,
    isLoseWare,
    isSackwareView,
    onlyBestellpositionenWithMenge,
} from '../../helper/bestellungen-helper';
import Formulas from '../../formulas';
import {
    BESTELLUNG_MAX_WEIGHT_IN_KG,
    LOSE_WARE_BESTELLMENGE_VALIDATION_ERROR,
    SACKWARE_BESTELLMENGE_VALIDATION_ERROR,
    TIME_FORMAT_REGEX,
    WUNSCHTERMIN_VALIDATION_ERRORS,
} from '../../constants';
import { TestContext } from 'yup';
import ValidationError from 'yup/lib/ValidationError';
import { TestFunction } from 'yup/lib/util/createValidation';
import { AnyObject } from 'yup/es/types';
import dayjs from 'dayjs';
import type { BestellpositionViewTypeShape } from './schemas';

type TestFunctionReturnValue = boolean | ValidationError | Promise<boolean | ValidationError>;

export const testAtLeastOnePositionWithoutStorno = (bestellpositionenView?: BestellpositionViewTypeShape[]): TestFunctionReturnValue => {
    const nichtStornierteBestellpositionen: Partial<BestellpositionView>[] =
        bestellpositionenView?.filter((position) => {
            return !(position as unknown as BestellpositionView)?.storno;
        }) ?? [];
    return nichtStornierteBestellpositionen.length > 0;
};

export const testLogischerWunschterminzeitraum: TestFunction<string | undefined, AnyObject> = (uhrzeitWEBis, context) => {
    const parent = context.parent;

    if (!parent.datumWEVon || !parent.datumWEBis || !parent.uhrzeitWEVon || !uhrzeitWEBis) {
        return true;
    }

    const dateVon = dayjs(parent.datumWEVon);
    const dateBis = dayjs(parent.datumWEBis);
    const dateVonWithTime = dayjs(`${dateVon.format('YYYY-MM-DD')}T${parent.uhrzeitWEVon}`);
    const dateBisWithTime = dayjs(`${dateBis.format('YYYY-MM-DD')}T${uhrzeitWEBis}`);
    const isSameDay = dateVon.isSame(dateBis);
    // NOTE: Only Report an error in the following case:
    //       DatumVon: 22.01.2023 Tageszeit: Nachmittag
    //       DatumBis: 22.01.2023 Tageszeit: Vormittag
    // We negate the expression, because yup expects a "false" to show the error.
    return !(isSameDay && dateVonWithTime.isAfter(dateBisWithTime));
};

export const testMengeValide = (position: Partial<BestellpositionView>, context: TestContext): TestFunctionReturnValue => {
    if (position.storno === true) {
        return true;
    }
    if (isLoseWare(position)) {
        const menge = Formulas.mengeToNumber(position.menge);
        if (hasAenderbarBisExpiredForBestellposition(position)) {
            return true;
        }
        if (menge === 0) {
            return context.createError({
                path: `${context.path}.menge`,
                message: LOSE_WARE_BESTELLMENGE_VALIDATION_ERROR,
            });
        } else {
            return true;
        }
    } else if (isSackwareView(position)) {
        const [mengeVe, mengePal] = Formulas.mengeVeAndPeToNumber(position.mengeVe, position.mengePal);
        if (mengeVe > 0 || mengePal > 0) {
            return true;
        } else {
            return context.createError({
                path: `${context.path}.mengePal`,
                message: SACKWARE_BESTELLMENGE_VALIDATION_ERROR,
            });
        }
    }
    return true;
};
export const testWunschtermin = (position: Partial<BestellpositionView>, context: TestContext): TestFunctionReturnValue => {
    if (isSackwareView(position)) {
        return true;
    }

    if (isLoseWare(position)) {
        if (hasAenderbarBisExpiredForBestellposition(position)) {
            return true;
        }
    }
    if (position.storno) {
        return true;
    }

    if (!position.debitorDatum) {
        return context.createError({
            path: `${context.path}.debitorDatum`,
            message: WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_DATE,
        });
    }
    if (!position.debitorUhrzeit) {
        return context.createError({
            path: `${context.path}.debitorUhrzeit`,
            message: WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_TIME,
        });
    }
    const date = dayjs(position.debitorDatum);
    if (!date.isValid()) {
        return context.createError({
            path: `${context.path}.debitorDatum`,
            message: WUNSCHTERMIN_VALIDATION_ERRORS.WRONG_DATE_FORMAT,
        });
    }
    if (!position.debitorUhrzeit.match(TIME_FORMAT_REGEX)) {
        return context.createError({
            path: `${context.path}.debitorUhrzeit`,
            message: WUNSCHTERMIN_VALIDATION_ERRORS.WRONG_TIME_FORMAT,
        });
    }
    const currentDate = dayjs();
    const wunschtermin = dayjs(`${date.format('YYYY-MM-DD')}T${position.debitorUhrzeit}`);

    if (wunschtermin.isBefore(currentDate)) {
        return context.createError({
            path: `${context.path}.debitorUhrzeit`,
            message: WUNSCHTERMIN_VALIDATION_ERRORS.DATE_IN_THE_PAST,
        });
    }
    return true;
};

export const testMindestensEinArtikel = (bestellpositionenView: BestellpositionViewTypeShape[] | undefined): TestFunctionReturnValue => {
    return bestellpositionenView ? bestellpositionenView.length > 0 : true;
};

export const testMindestensEineMenge = (bestellpositionenView: BestellpositionViewTypeShape[] | undefined): TestFunctionReturnValue => {
    /* This validation seems odd but is actually intended. The Rechnungsempfaenger should at least enter the
     amount for one article. Any further article is not required to have an amount, they will be discarded later.

     The reason for this behaviour is that the Rechnungsempfaeger is given a list of suggested articles. To prevent the
     user from deleting all articles explicitly (which is not a great user experience) they can just go to the next step.
   */
    const neueBestellpositionen: Partial<BestellpositionView>[] = bestellpositionenView?.filter(onlyBestellpositionenWithMenge) ?? [];
    return neueBestellpositionen.length > 0;
};

export const testMaximalGewicht = (checkForOverWeight: boolean) => {
    return (bestellpositionenView: BestellpositionViewTypeShape[] | undefined): TestFunctionReturnValue => {
        if (checkForOverWeight && bestellpositionenView) {
            const totalWeight = bestellpositionenView
                .map((bestellposition) => {
                    if (isLoseWare(bestellposition)) {
                        return Formulas.mengeToWeightInKg(bestellposition.menge);
                    } else {
                        const bestellpositionSackware = bestellposition as unknown as BestellpositionArtikelSackwareView;
                        return Formulas.mengePalAndVeToWeightInKg(
                            bestellpositionSackware.mengePal,
                            bestellpositionSackware.mengeVe,
                            bestellpositionSackware.faktorBasiseinheitPal,
                            bestellpositionSackware.faktorBasiseinheitVe
                        );
                    }
                })
                .reduce((acc, curr) => acc + curr, 0);
            return totalWeight <= BESTELLUNG_MAX_WEIGHT_IN_KG;
        }
        return true;
    };
};
