import { CellProps, Column } from 'react-table';
import { Dokument } from '../../shared/types';
import dayjs from 'dayjs';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import styles from './Dokumentenuebersicht.module.scss';
import ButtonGroup, { ButtonGroupAlignment } from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import IconButton from '../../shared/ui-components/IconButton/IconButton';
import iconDownload from '../../assets/icon-file-download.png';
import { ReactComponent as DownloadIcon } from '../../assets/icon-download.svg';
import iconDelete, { ReactComponent as DeleteIcon } from '../../assets/icon-trashcan.svg';
import {
    deleteDokument,
    deselectDokument,
    dokumentDownload,
    DokumentSelectors,
    selectDokument,
    sortedDokumentartOptions,
} from '../../store/Dokument.store';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';
import { ReactElement, useCallback } from 'react';
import ButtonIcon from '../../shared/ui-components/Button/ButtonIcon';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';

export const createDokumentenCheckBox = (): Column<Dokument> => {
    return {
        id: 'checkBox',
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: (cellProps: CellProps<Dokument>): JSX.Element => {
            const dispatch = useDispatch();
            const isMobile = useBreakpoint(Breakpoint.MOBILE);
            const cell = cellProps.cell;
            const value = cellProps.value;
            const isCurrentCheckBoxChecked = useSelector(DokumentSelectors.isDokumentChecked(value));
            if (!value) return <></>;

            const onChange = (checked: boolean): void => {
                if (checked) {
                    dispatch(selectDokument({ dokumentId: value }));
                } else {
                    dispatch(deselectDokument({ dokumentId: value }));
                }
            };

            return isMobile ? (
                <div className={styles._checkbox}>
                    <NonFormikCheckbox
                        label="Zum Download markieren"
                        name="checkdownload"
                        id={cell.value}
                        checked={isCurrentCheckBoxChecked}
                        placement="left"
                        onFieldChange={onChange}
                    />
                </div>
            ) : (
                <NonFormikCheckbox
                    name="checkdownload"
                    id={cell.value}
                    checked={isCurrentCheckBoxChecked}
                    placement="left"
                    onFieldChange={onChange}
                />
            );
        },
    };
};

export const createDokumentArtColumn = (): Column<Dokument> => {
    return {
        id: 'dokumentArt',
        Header: 'Dokumentart',
        accessor: (row: Dokument): string => {
            const dokumentArt = sortedDokumentartOptions.find((dokumentArtOption) => dokumentArtOption.value == row.dokumentArt);
            if (dokumentArt) {
                return dokumentArt.label;
            }
            return row.dokumentArt;
        },
        Cell: (cellProps: CellProps<Dokument>): ReactElement => {
            const isMobile = useBreakpoint(Breakpoint.MOBILE);
            const cell = cellProps.cell;
            if (!cell.value) return <></>;

            return isMobile ? <TextItem label="Dokumentart" text={cell.value} compressed /> : <>{cell.value}</>;
        },
        disableSortBy: true,
    };
};

export const createAbsenderColumn = (): Column<Dokument> => {
    return {
        id: 'dokumentAbsender',
        Header: 'Absender',
        accessor: (row: Dokument): string => {
            return `${row.absender.name} (${row.absender.partnerNummer})`;
        },
        Cell: (cellProps: CellProps<Dokument>): ReactElement => {
            const isMobile = useBreakpoint(Breakpoint.MOBILE);
            const cell = cellProps.cell;
            if (!cell.value) return <></>;

            return isMobile ? <TextItem label="Absender" text={cell.value} compressed /> : <>{cell.value}</>;
        },
        disableSortBy: true,
    };
};

export const createBelegNummerColumn = (): Column<Dokument> => {
    return {
        id: 'dokumentBelegNummer',
        Header: 'Belegnummer',
        accessor: 'belegNummer',
        disableSortBy: true,
        Cell: (cellProps: CellProps<Dokument>): ReactElement => {
            const isMobile = useBreakpoint(Breakpoint.MOBILE);
            const cell = cellProps.cell;
            if (!cell.value) return <></>;

            return isMobile ? <TextItem label="Belegnummer" text={cell.value} compressed /> : <>{cell.value}</>;
        },
    };
};

export const createBelegDatumColumn = (): Column<Dokument> => {
    return {
        id: 'belegDatum',
        Header: 'Belegdatum',
        accessor: (row: Dokument): Date | undefined => {
            return row.belegDatum ? new Date(row.belegDatum) : undefined;
        },
        Cell: (cellProps: CellProps<Dokument>): ReactElement => {
            const isMobile = useBreakpoint(Breakpoint.MOBILE);

            const cell = cellProps.cell;
            if (!cell.value) return <></>;

            const parsedDate = dayjs(cell.value);
            const datum = parsedDate.format('DD.MM.YYYY');
            const uhrzeit = parsedDate.format('HH:mm') + ' Uhr';
            return isMobile ? (
                <TextItem label="Datum" text={datum + ' - ' + uhrzeit} compressed />
            ) : (
                <>
                    <div>{datum}</div>
                    <span className={styles._subInfo}>{uhrzeit}</span>
                </>
            );
        },
    };
};

export const createDownloadLinkColumn = (): Column<Dokument> => {
    return {
        id: 'actions',
        Cell: (cellProps: CellProps<Dokument>): ReactElement => {
            const isMobile = useBreakpoint(Breakpoint.MOBILE);
            const dispatch = useDispatch();
            const cell = cellProps.cell;
            const onDelete = useCallback(
                (event) => {
                    event.preventDefault();
                    showConfirmationDialog({
                        message: <>Sind Sie sich sicher, dass Sie dieses Dokument löschen möchten?</>,
                        okButtonText: 'Dokument löschen',
                        onOkCallback: () => {
                            dispatch(deleteDokument({ dokumentId: cell.value }));
                        },
                    });
                },
                [cell.value, dispatch]
            );
            const disabled = !cellProps.row.original.deletable;
            return isMobile ? (
                <ButtonGroup alignment={ButtonGroupAlignment.CENTER} isWithinCell={false}>
                    <ButtonIcon
                        data-cy={'download-dokument-button'}
                        test-id={'download-dokument-button'}
                        icon={<DownloadIcon title={'Dokument herunterladen'} stroke={'#6f6f6f'} fill={'#6f6f6f'} strokeWidth={1.5} />}
                        onClick={() => dispatch(dokumentDownload({ dokumentId: cell.value }))}
                        disabled={!cell.value}
                    >
                        Downloaden
                    </ButtonIcon>
                    <ButtonIcon
                        data-cy={'delete-dokument-button'}
                        test-id={'delete-dokument-button'}
                        icon={<DeleteIcon title={'Dokument löschen'} stroke={'#6f6f6f'} fill={'#6f6f6f'} strokeWidth={1.5} />}
                        onClick={onDelete}
                        disabled={!cell.value || disabled}
                    >
                        Löschen
                    </ButtonIcon>
                </ButtonGroup>
            ) : (
                <ButtonGroup alignment={ButtonGroupAlignment.LEFT} isWithinCell>
                    <IconButton
                        data-cy={'download-dokument-button'}
                        test-id={'download-dokument-button'}
                        icon={iconDownload}
                        alt="Dokument herunterladen"
                        disabled={!cell.value}
                        onClick={() => dispatch(dokumentDownload({ dokumentId: cell.value }))}
                    />
                    <IconButton
                        data-cy={'delete-dokument-button'}
                        test-id={'delete-dokument-button'}
                        icon={iconDelete}
                        disabled={disabled}
                        alt="Dokument löschen"
                        onClick={onDelete}
                    />
                </ButtonGroup>
            );
        },
        Header: '',
        accessor: 'id',
        disableSortBy: true,
    };
};
