import { FormEvent, HTMLProps } from 'react';
import Tippy from '@tippyjs/react';
import styles from './TextInput.module.scss';
import { BaseProps } from '../FormControl';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

type Props = HTMLProps<HTMLInputElement> & {
    inTableCell?: boolean;
    onFieldChange?: (value: string) => void;
    onFieldBlur?: (value: string) => void | string;
    hasError?: boolean;
    errorMessage?: string;
};

const TextInputWithoutFormik = (props: BaseProps & Props): JSX.Element => {
    const { inTableCell, onFieldChange, onFieldBlur, hasError, errorMessage, ...textFieldProps } = props;
    const baseStyles = inTableCell ? [styles._input, styles['_input--inTableCell']] : [styles._input, styles['_input--regular']];
    const inputStyles = hasError ? [...baseStyles, styles['_input--error']] : baseStyles;
    const isMobile = useBreakpoint(Breakpoint.MOBILE);

    return (
        <Tippy content={errorMessage} disabled={!hasError || isMobile}>
            <input
                className={inputStyles.join(' ')}
                {...textFieldProps}
                onChange={(e: FormEvent<HTMLInputElement>): void => {
                    if (onFieldChange) {
                        onFieldChange(e.currentTarget.value);
                    }
                }}
                onBlur={(e: FormEvent<HTMLInputElement>): void => {
                    if (onFieldBlur) {
                        onFieldBlur(e.currentTarget.value);
                    }
                }}
            />
        </Tippy>
    );
};

export default TextInputWithoutFormik;
