import {
    Artikel,
    ArtikelSackware,
    BestellpositionArtikelSackware,
    BestellpositionArtikelSackwareView,
    BestellpositionView,
    SollbestandAndBestand,
} from '../types';
import { Option } from './select-options';

export type ArtikelGruppe = {
    artikelGruppeId: string;
    artikelGruppeBezeichnung: string;
    bestellpositionenView: BestellpositionView[];
};

export type ArtikelGruppen = {
    [artikelGruppeId: string]: ArtikelGruppe;
};

export type MarkeArtikelGruppen = {
    label: string;
    position: number;
    artikelGruppen: ArtikelGruppen;
};

export type GroupedBestellpostionen = {
    [markeId: string]: MarkeArtikelGruppen;
};

export interface ArtikelTableValuesGrouped {
    groupedBestellpositionen: GroupedBestellpostionen;
    addArticle?: string;
}

export interface ArtikelNutztierfutterFormular {
    bestellpositionenView: BestellpositionView[];
    addArticle?: string;
    datumDebitor: string;
    uhrzeitDebitor?: string;
    datumWEVon: string;
    uhrzeitWEVon?: string;
    datumWEBis?: string;
    uhrzeitWEBis?: string;
}

function getMarkePostion(markenName: string): number {
    switch (markenName.toLowerCase()) {
        case 'deuka':
            return 0;
        case 'cfm':
            return 1;
        case 'baywa':
            return 2;
        default:
            return 0;
    }
}

export function getGroupedBestellpositionen(
    artikelList: ArtikelSackware[],
    bestellpositionen: BestellpositionArtikelSackware[],
    sollbestandAndBestand: SollbestandAndBestand[]
): GroupedBestellpostionen {
    const sortedBestellpositionen = {};
    artikelList.forEach((artikel) => {
        const bestellposition: BestellpositionArtikelSackware | undefined = bestellpositionen.find(
            (bp) => bp.artikelNummer === artikel.nummer
        );
        const bestand = sollbestandAndBestand.find((b) => b.artikelNummer === artikel.nummer);
        const bestellpositionView: BestellpositionArtikelSackwareView = {
            ...artikel,
            mengeVe: bestellposition ? bestellposition.mengeVe : '',
            mengePal: bestellposition ? bestellposition.mengePal : '',
            index: bestellposition?.index,
            bestandVE: undefined,
            ...(bestand ? bestand : {}),
        };

        if (artikel.markeId && artikel.artikelGruppeBezeichnung) {
            if (artikel.markeId in sortedBestellpositionen) {
                if (artikel.artikelGruppeId in sortedBestellpositionen[artikel.markeId].artikelGruppen) {
                    sortedBestellpositionen[artikel.markeId].artikelGruppen[artikel.artikelGruppeId].bestellpositionenView.push(
                        bestellpositionView
                    );
                } else {
                    sortedBestellpositionen[artikel.markeId].artikelGruppen[artikel.artikelGruppeId] = {
                        artikelGruppeBezeichnung: artikel.artikelGruppeBezeichnung,
                        artikelGruppeId: artikel.artikelGruppeId,
                        bestellpositionenView: [bestellpositionView],
                    };
                }
            } else {
                sortedBestellpositionen[artikel.markeId] = {
                    label: artikel.markeBezeichnung.toUpperCase() === 'CFM' ? 'Club' : artikel.markeBezeichnung,
                    position: getMarkePostion(artikel.markeBezeichnung),
                    artikelGruppen: {},
                };
                sortedBestellpositionen[artikel.markeId].artikelGruppen[artikel.artikelGruppeId] = {
                    artikelGruppeBezeichnung: artikel.artikelGruppeBezeichnung,
                    artikelGruppeId: artikel.artikelGruppeId,
                    bestellpositionenView: [bestellpositionView],
                };
            }
        }
    });

    return sortedBestellpositionen;
}

export function forEachBestellposition(
    groupedBestellpositionen: GroupedBestellpostionen,
    callback: (bp: BestellpositionView, markeId: string, artikelGruppeId: string, bpIndex: number) => void
): void {
    const marken = Object.keys(groupedBestellpositionen);
    marken.forEach((markeId): void => {
        const artikelGruppen = groupedBestellpositionen[markeId].artikelGruppen;
        const artikelGruppenEntries = Object.entries(artikelGruppen);

        artikelGruppenEntries.forEach(([artikelGruppeId, artikelGruppe]) => {
            Array.isArray(artikelGruppe.bestellpositionenView) &&
                artikelGruppe.bestellpositionenView
                    .map((bp, index) => ({ ...bp, index }))
                    .forEach((bp: BestellpositionView, index) => {
                        callback(bp, markeId, artikelGruppeId, index);
                    });
        });
    });
}

export function getFlatBestellpositionen(values: ArtikelTableValuesGrouped): BestellpositionView[] {
    return Object.values(values.groupedBestellpositionen)
        .map((marke) => {
            return Object.values(marke.artikelGruppen).map((group) => {
                return group.bestellpositionenView;
            });
        })
        .flat(2);
}

export const sortArtikelByNumber = (a: BestellpositionView, b: BestellpositionView): number => {
    if (a.nummer < b.nummer) {
        return -1;
    }
    if (a.nummer > b.nummer) {
        return 1;
    }
    return 0;
};

export const mapArtikelToOptions = (artikel: Artikel): Option => ({
    label: artikel.nummer + ' ' + artikel.bezeichnung,
    value: artikel.nummer,
});
