import { useMemo } from 'react';
import { TableInstance, TableOptions, useSortBy, useTable } from 'react-table';
import { mengeVeColumnConfig } from './columns/sackware/MengeVeColumnConfig';
import { mengePalColumnConfig } from './columns/sackware/MengePalColumnConfig';
import { sackwareWarenempfaengerColumnConfig } from './columns/WarenempfaengerColumnConfig';
import { sackwareArtikelColumnConfig } from './columns/ArtikelColumnConfig';
import { sackwareWerkColumnConfig } from './columns/WerkColumnConfig';
import { sackwareLieferhinweisColumnConfig } from './columns/LieferhinweisColumnConfig';
import tableStyles from '../../../shared/ui-components/Table/Table.module.scss';
import TableHeader from '../../../shared/ui-components/Table/ReactTable/TableHeader';
import TableBody from '../../../shared/ui-components/Table/ReactTable/TableBody';
import type { TourenplanSackwareBestellung } from '../TourenplanTypes';

type TourenplanSackwareTableProps = {
    bestellungen: TourenplanSackwareBestellung[];
};

export const TourenplanSackwareTable = ({ bestellungen }: TourenplanSackwareTableProps): JSX.Element => {
    const columns = useMemo(
        () => [
            sackwareWarenempfaengerColumnConfig,
            sackwareArtikelColumnConfig,
            sackwareWerkColumnConfig,
            mengeVeColumnConfig,
            mengePalColumnConfig,
            sackwareLieferhinweisColumnConfig,
        ],
        []
    );

    const options: TableOptions<TourenplanSackwareBestellung> = {
        columns,
        data: bestellungen,
        initialState: {},
    };
    const tableInstance: TableInstance<TourenplanSackwareBestellung> = useTable<TourenplanSackwareBestellung>(options, useSortBy);

    const { getTableProps } = tableInstance;
    return (
        <div data-cy="tourenplan-sackware-table" className={tableStyles._tableContainer}>
            <table {...getTableProps()} className={tableStyles._table}>
                <TableHeader {...tableInstance} />
                <TableBody {...tableInstance} />
            </table>
        </div>
    );
};
