import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestState } from '../shared/types/enums';
import type { RootState } from '../configureStore';
import type { Spedition } from '../shared/types';
import { Option } from '../shared/helper/select-options';

export const TEMPORARY_SPEDITION_ID = 'NEW';

const selectAllSpeditionen = (state: RootState): SpeditionView[] => {
    return state.spedition.spedition;
};

const selectSpeditionOptions = createSelector(selectAllSpeditionen, (spedition): Option[] => {
    return spedition.map((spedition) => ({
        value: spedition.id,
        label: spedition.email,
    }));
});

const selectSpeditionIdFromEmail =
    (spedition: string | undefined): ((state: RootState) => string) =>
    (state) => {
        if (!spedition) {
            return '';
        }
        return state.spedition.spedition.find((speditionView) => speditionView.email === spedition)?.id ?? '';
    };

export const speditionSelectors = {
    allSpeditionen: selectAllSpeditionen,
    allAsOptions: selectSpeditionOptions,
    isLoading(state: RootState): boolean {
        return state.spedition.loadingState === RequestState.LOADING;
    },
    loaded(state: RootState): boolean {
        return state.spedition.loadingState === RequestState.SUCCESSFUL;
    },
    hasSubmitError(state: RootState): boolean {
        return state.spedition.submitState === RequestState.FAILED;
    },
    mapSpeditionIdFromSpedition: selectSpeditionIdFromEmail,
};

export type SpeditionView = Spedition & {
    isSubmitting: boolean;
    hasValidationError?: boolean;
    hasDublettenError?: boolean;
};

export const createSpedition = (id: string, email: string): Spedition => {
    return { id, email };
};

export const viewFromSpedition = (spedition: Spedition): SpeditionView => ({
    ...spedition,
    isSubmitting: false,
    hasValidationError: false,
    hasDublettenError: false,
});

const sortSpeditionen = (a: SpeditionView, b: SpeditionView): number => {
    if (a.email.toUpperCase() < b.email.toUpperCase()) {
        return -1;
    }
    if (a.email.toUpperCase() > b.email.toUpperCase()) {
        return 1;
    }
    return 0;
};

function setSpeditionSubmitting(state: SpeditionState, spedition: Spedition, isSubmitting: boolean): SpeditionView[] {
    return state.spedition.map((currentSpedition) => ({
        ...currentSpedition,
        isSubmitting: currentSpedition.id === spedition.id ? isSubmitting : currentSpedition.isSubmitting,
    }));
}

export interface SpeditionState {
    spedition: SpeditionView[];
    loadingState: RequestState;
    submitState: RequestState;
}

export const INITIAL_SPEDITIONEN_STATE: SpeditionState = {
    spedition: [],
    loadingState: RequestState.INITIAL,
    submitState: RequestState.INITIAL,
};

const speditionSlice = createSlice({
    name: 'spedition',
    initialState: INITIAL_SPEDITIONEN_STATE,
    reducers: {
        addSpedition(state, action: PayloadAction<Spedition>) {
            state.submitState = RequestState.INITIAL;
            state.spedition.push(viewFromSpedition(action.payload));
        },
        addSpeditionSucceeded(state, action: PayloadAction<Spedition>) {
            state.spedition = state.spedition.filter((s) => s.id !== TEMPORARY_SPEDITION_ID);
            state.loadingState = RequestState.INITIAL;
            state.spedition.push(viewFromSpedition(action.payload));
        },
        addSpeditionFailed(state, _: PayloadAction<string>) {
            state.submitState = RequestState.FAILED;
            state.spedition = state.spedition.filter((s) => s.id !== TEMPORARY_SPEDITION_ID);
        },
        getSpeditionen(state) {
            state.loadingState = RequestState.LOADING;
        },
        getSpeditionenSucceeded(state, action: PayloadAction<Spedition[]>) {
            const speditionSorted = action.payload.map(viewFromSpedition).sort(sortSpeditionen);
            state.loadingState = RequestState.SUCCESSFUL;
            state.submitState = RequestState.SUCCESSFUL;
            state.spedition = speditionSorted;
        },
        getSpeditionenFailed(state, _: PayloadAction<string>) {
            state.loadingState = RequestState.FAILED;
        },
        deleteSpedition(state, action: PayloadAction<Spedition>) {
            state.spedition = setSpeditionSubmitting(state, action.payload, true);
        },
        deleteSpeditionSucceeded(state, action: PayloadAction<Spedition>) {
            state.submitState = RequestState.SUCCESSFUL;
            state.loadingState = RequestState.INITIAL;
            state.spedition = state.spedition.filter((s) => s.id !== action.payload.id);
        },
        deleteSpeditionFailed(state, action: PayloadAction<Spedition>) {
            state.submitState = RequestState.FAILED;
            state.spedition = setSpeditionSubmitting(state, action.payload, false);
        },
        updateSpedition(state, { payload: updatedSpedition }: PayloadAction<Spedition>) {
            state.spedition = state.spedition.map((spedition) => ({
                ...(spedition.id === updatedSpedition.id ? updatedSpedition : spedition),
                isSubmitting: spedition.id === updatedSpedition.id ? true : spedition.isSubmitting,
            }));
        },
        updateSpeditionSucceeded(state, action: PayloadAction<Spedition>) {
            state.submitState = RequestState.SUCCESSFUL;
            state.loadingState = RequestState.INITIAL;
            state.spedition = setSpeditionSubmitting(state, action.payload, false);
        },
        updateSpeditionFailed(state, action: PayloadAction<Spedition>) {
            state.submitState = RequestState.FAILED;
            state.spedition = setSpeditionSubmitting(state, action.payload, true);
        },
    },
});

export const {
    addSpedition,
    addSpeditionSucceeded,
    addSpeditionFailed,
    deleteSpedition,
    deleteSpeditionSucceeded,
    deleteSpeditionFailed,
    getSpeditionen,
    getSpeditionenSucceeded,
    getSpeditionenFailed,
    updateSpedition,
    updateSpeditionSucceeded,
    updateSpeditionFailed,
} = speditionSlice.actions;
export const speditionReducer = speditionSlice.reducer;
