import { Filter, Lieferung } from '../shared/types';
import { emptyLieferung } from '../shared/types/defaultValues';
import { Order } from '../shared/types/enums';

export const GET_LIEFERUNG = 'GET_LIEFERUNG';
export const GET_LIEFERUNG_SUCCEEDED = 'GET_LIEFERUNG_SUCCEEDED';
export const GET_LIEFERUNG_FAILED = 'GET_LIEFERUNG_FAILED';
export const GET_LIEFERUNGEN = 'GET_LIEFERUNGEN';
export const GET_LIEFERUNGEN_SUCCEEDED = 'GET_LIEFERUNGEN_SUCCEEDED';
export const GET_LIEFERUNGEN_FAILED = 'GET_LIEFERUNGEN_FAILED';

export interface GetLieferungAction {
    type: typeof GET_LIEFERUNG;
    vertriebsauftragsnummer: string;
}

export interface GetLieferungSucceededAction {
    type: typeof GET_LIEFERUNG_SUCCEEDED;
    lieferung: Lieferung;
}

export interface GetLieferungFailedAction {
    type: typeof GET_LIEFERUNG_FAILED;
}

export interface GetLieferungenAction {
    type: typeof GET_LIEFERUNGEN;
    page: number;
    size: number;
    sortColumn: string;
    sortOrder: Order;
    bestellnummer?: string;
    filter?: Filter;
}

export interface GetLieferungenSucceededAction {
    type: typeof GET_LIEFERUNGEN_SUCCEEDED;
    lieferungen: Lieferung[];
    totalCount: number;
}

export interface GetLieferungenFailedAction {
    type: typeof GET_LIEFERUNGEN_FAILED;
}

export function getLieferung(vertriebsauftragsnummer: string): GetLieferungAction {
    return {
        type: GET_LIEFERUNG,
        vertriebsauftragsnummer,
    };
}

export function getLieferungSucceeded(lieferung: Lieferung): GetLieferungSucceededAction {
    return {
        type: GET_LIEFERUNG_SUCCEEDED,
        lieferung,
    };
}

export function getLieferungFailed(): GetLieferungFailedAction {
    return {
        type: GET_LIEFERUNG_FAILED,
    };
}

export function getLieferungen(
    page: number,
    size: number,
    sortColumn: string,
    sortOrder: Order,
    bestellnummer?: string,
    filter?: Filter
): GetLieferungenAction {
    return {
        type: GET_LIEFERUNGEN,
        page,
        size,
        sortColumn,
        sortOrder,
        bestellnummer,
        filter,
    };
}

export function getLieferungenSucceeded(lieferungen: Lieferung[], totalCount: number): GetLieferungenSucceededAction {
    return {
        type: GET_LIEFERUNGEN_SUCCEEDED,
        lieferungen,
        totalCount,
    };
}

export function getLieferungenFailed(): GetLieferungenFailedAction {
    return {
        type: GET_LIEFERUNGEN_FAILED,
    };
}

export type LieferungActions =
    | GetLieferungenAction
    | GetLieferungenSucceededAction
    | GetLieferungenFailedAction
    | GetLieferungAction
    | GetLieferungSucceededAction
    | GetLieferungFailedAction;

export type LieferungsState = {
    lieferungen: Lieferung[];
    currentLieferung: Lieferung;
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
    totalCount: number;
    currentLoadFailed: boolean;
    currentIsLoading: boolean;
    currentLoadFinished: boolean;
};

export const INITIAL_LIEFERUNG_STATE: LieferungsState = {
    lieferungen: [],
    currentLieferung: emptyLieferung,
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
    totalCount: 0,
    currentLoadFailed: false,
    currentIsLoading: false,
    currentLoadFinished: false,
};

export function lieferungsReducer(state = INITIAL_LIEFERUNG_STATE, action: LieferungActions): LieferungsState {
    switch (action.type) {
        case GET_LIEFERUNG:
            return {
                ...state,
                currentLieferung: emptyLieferung,
                currentIsLoading: true,
                currentLoadFinished: false,
                currentLoadFailed: false,
            };
        case GET_LIEFERUNG_FAILED:
            return {
                ...state,
                currentIsLoading: false,
                currentLoadFinished: true,
                currentLoadFailed: true,
            };
        case GET_LIEFERUNG_SUCCEEDED:
            return {
                ...state,
                currentLieferung: action.lieferung,
                currentIsLoading: false,
                currentLoadFinished: true,
                currentLoadFailed: false,
            };
        case GET_LIEFERUNGEN:
            return {
                ...state,
                isLoading: true,
                loadFinished: false,
                loadFailed: false,
            };
        case GET_LIEFERUNGEN_FAILED:
            return {
                ...state,
                isLoading: false,
                loadFinished: true,
                loadFailed: true,
            };
        case GET_LIEFERUNGEN_SUCCEEDED:
            return {
                ...state,
                lieferungen: action.lieferungen,
                isLoading: false,
                loadFinished: true,
                loadFailed: false,
                totalCount: action.totalCount,
            };
        default:
            return state;
    }
}
