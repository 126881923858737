import formStyles from '../../ui-components/Form/Form.module.scss';
import styles from './WunschterminUhrzeit.module.scss';
import FormControl from '../../ui-components/FormControl/FormControl';
import TextInput from '../../ui-components/TextInput/TextInput';
import type { WunschterminProps } from './wunschtermin-helper';
import { WunschterminLabels } from './labels';
import TextItem from '../../ui-components/TextItem/TextItem';
import { convertDateWithTimeFromIsoStringToDateInfo } from '../../helper/date-helper';
import { ReactElement } from 'react';

export const WunschterminUhrzeitReadonly = ({
    label,
    wunschtermin = '',
    isDateRequired = true,
    isTimeRequired = true,
}: WunschterminProps & { wunschtermin?: string }): ReactElement => {
    const requiredString = ' *';
    const dateLabel = label ?? `${WunschterminLabels.ABHOL_BZW_LIEFERTERMIN}${isDateRequired ? requiredString : ''}`;
    const timeLabel = `${WunschterminLabels.UHRZEIT}${isTimeRequired ? requiredString : ''}`;

    const { datum, uhrzeit } = convertDateWithTimeFromIsoStringToDateInfo(wunschtermin);

    return (
        <div data-cy={'form-lw-wunschtermin'} className={formStyles._terminContainer}>
            <TextItem label={dateLabel} text={datum} containerStyle={styles._textAndInputField} />
            <TextItem label={timeLabel} text={uhrzeit} containerStyle={styles._textAndInputField} />
        </div>
    );
};

export const WunschterminUhrzeit = ({
    minDate,
    label,
    timeFieldName = 'uhrzeitDebitor',
    dateFieldName = 'datumDebitor',
    isDateRequired = true,
    isTimeRequired = true,
}: WunschterminProps): ReactElement => {
    const requiredString = ' *';
    const dateLabel = label ?? `${WunschterminLabels.ABHOL_BZW_LIEFERTERMIN}${isDateRequired ? requiredString : ''}`;
    const timeLabel = `${WunschterminLabels.UHRZEIT}${isTimeRequired ? requiredString : ''}`;

    return (
        <div data-cy={'form-lw-wunschtermin'} className={formStyles._terminContainer}>
            <FormControl
                name={dateFieldName}
                id={dateFieldName}
                label={dateLabel}
                noLabelWrap
                renderChild={(props) => {
                    return <TextInput {...props} type="date" placeholder="jjjj-mm-tt" min={minDate} />;
                }}
            />
            <FormControl
                name={timeFieldName}
                id={timeFieldName}
                label={timeLabel}
                renderChild={(props) => {
                    return <TextInput {...props} type="time" placeholder="hh:mm" />;
                }}
            />
        </div>
    );
};
