import { routes } from '../constants';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { BestellungSelectors } from '../../store/Bestellung.store';
import { WarenempfaengerSelectors } from '../../store/Warenempfaenger.store';
import type { Warenempfaenger } from '../types';

export const useWarenempfaengerFromBestellung = (): Warenempfaenger | undefined => {
    // NOTE: Select the Warenempfaenger from the active Lose Ware or Sackwaren Bestellung
    const warenempfaengerPartnerNummer = useSelector(BestellungSelectors.warenempfaengerPartnerNummer);
    let warenempfaenger = useSelector(WarenempfaengerSelectors.byPartnerNummer(warenempfaengerPartnerNummer));

    // NOTE: Select the Warenempfaenger from the current selected Bestellung from Bestelluebersicht/Auftragskorb
    const location = useLocation();
    const currentBestellung = useSelector(BestelluebersichtSelectors.currentBestellung);

    if (location.pathname.includes(routes.auftragsansicht) || location.pathname.includes(routes.bestellansicht)) {
        warenempfaenger = currentBestellung.warenempfaenger;
    }

    return warenempfaenger;
};
