import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { BestellungGesamtstatus } from '../types/enums';
import { useSelector } from 'react-redux';

type BestellstatusGuardProps = {
    beimLandhandel?: JSX.Element;
    beiDTC?: JSX.Element;
    vomLandhandelStorniert?: JSX.Element;
    vomWarenempfaengerStorniert?: JSX.Element;
    aenderungswunsch?: JSX.Element;
};

export const BestellstatusGuard = ({
    beimLandhandel,
    beiDTC,
    vomLandhandelStorniert,
    vomWarenempfaengerStorniert,
    aenderungswunsch,
}: BestellstatusGuardProps): JSX.Element | null => {
    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);

    switch (bestellung.status) {
        case BestellungGesamtstatus.STORNIERT_DURCH_LANDWARENHANDEL:
            return <>{vomLandhandelStorniert}</>;
        case BestellungGesamtstatus.STORNIERT_DURCH_WARENEMPFAENGER:
            return <>{vomWarenempfaengerStorniert}</>;
        case BestellungGesamtstatus.LANDWARENHANDEL:
            return <>{beimLandhandel}</>;
        case BestellungGesamtstatus.DTC:
            return <>{beiDTC}</>;
        case BestellungGesamtstatus.AENDERUNGSWUNSCH:
            return <>{aenderungswunsch}</>;
        default:
            return null;
    }
};
