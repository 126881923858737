import Button from '../../shared/ui-components/Button/Button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from '../../shared/constants';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';

type SammelbestellungButtonProps = Readonly<Record<string, unknown>>;

const SammelbestellungButton = (_: SammelbestellungButtonProps): JSX.Element => {
    const history = useHistory();
    const canCreateSammelbestellung = useSelector(BestelluebersichtSelectors.hasValidCurrentSammelbestellung);

    return (
        <Button
            data-cy="create-sammelbestellung"
            disabled={!canCreateSammelbestellung}
            type="button"
            onClick={(): void => {
                history.push(routes.tourenplan);
            }}
        >
            Aufträge zusammenfassen / Tour planen
        </Button>
    );
};

export default SammelbestellungButton;
