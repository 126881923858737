import iconClose from '../../../assets/icon-close.svg';
import IconButton from '../../ui-components/IconButton/IconButton';
import { showConfirmationDialog } from '../../ui-components/ConfirmationDialog/confirmationDialog';

export type Props = Readonly<{
    onStorno?: () => void;
    disabled: boolean;
}>;

export const StornoArtikelButton = ({ onStorno, disabled }: Props): JSX.Element => {
    return (
        <IconButton
            data-cy={'storno-button'}
            test-id={'storno-button'}
            icon={iconClose}
            disabled={disabled}
            alt="Stornieren"
            onClick={(event): void => {
                event.preventDefault();

                showConfirmationDialog({
                    message: <>Sind Sie sich sicher, dass Sie diesen Artikel stornieren möchten?</>,
                    okButtonText: 'Bestehenden Artikel stornieren',
                    onOkCallback: () => {
                        onStorno && onStorno();
                    },
                });
            }}
        />
    );
};
