import { ArtikelTyp, Berechnungsart, RequestState } from '../shared/types/enums';
import type {
    ArtikelLoseWare,
    ArtikelSackware,
    BaseBestellposition,
    BestelldatenFormular,
    BestellpositionArtikelLoseWare,
    BestellpositionArtikelLoseWareView,
    BestellpositionArtikelSackware,
    BestellpositionArtikelSackwareView,
    BestellungUpdateRequest,
    KfzKennzeichen,
    Spedition,
    Vorschlag,
    Warenempfaenger,
} from '../shared/types';
import { BestellpositionView, BestellungDetails } from '../shared/types';
import type { RootState } from '../configureStore';
import { DateAndTime, extractDateAndTimeFromDateString } from '../shared/helper/date-helper';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as BestellungenHelper from '../shared/helper/bestellungen-helper';
import { isLoseWare, isSackwareView, isSackware } from '../shared/helper/bestellungen-helper';
import { selectArtikelLoseWareList } from './ArtikelLoseWare.store';
import { ArtikelSackwarenSelectors } from './ArtikelSackware.store';

export interface MergeVorschlaegeWithBestellpositionenAction {
    artikelSackware: ArtikelSackware[];
    artikelLoseWare: ArtikelLoseWare[];
    vorschlaege: Vorschlag[];
}

export interface SendBestellungAction {
    isDraft: boolean;
}

export interface SetBestelldatenAction {
    lieferbedingung: string;
    ansprechpartner: string;
    bestellhinweis?: string;
    bestellhinweisWE?: string;
    lieferhinweis?: string;
    kfzKennzeichenId?: string;
    speditionId?: string;
    wunschtermin?: string;
    wunschterminVon?: string;
    wunschterminBis?: string;
    vvvoNummer?: string;
}

export interface SetWunschterminVonBisAction {
    wunschterminVon: string;
    wunschterminBis?: string;
}

export enum HeimtierfutterFormular {
    Standard,
    Bestellvorschlag,
}

export const INITIAL_BESTELLUNGEN_STATE: BestellungState = {
    bestellpositionen: [],
    submitState: RequestState.INITIAL,
    updateState: RequestState.INITIAL,
    amountMissing: false,
    warenempfaengerPartnerNummer: '',
    rechnungsempfaengerPartnerNummer: '',
    lieferbedingung: '',
    ansprechpartner: '',
    selectedWerkId: undefined,
    stateVersion: 5,
    bestellhinweis: '',
    bestellhinweisWE: '',
    heimtierfutterFormular: HeimtierfutterFormular.Standard,
    isLoadingVorschlaege: false,
    hasMergedVorschlaege: false,
};

const selectBestellung = (state: RootState): BestellungState => state.bestellung;
const selectRechnungsempfaengerPartnerNummer = (state: RootState): string => selectBestellung(state).rechnungsempfaengerPartnerNummer;
export const selectLieferbedingung = (state: RootState): string => selectBestellung(state).lieferbedingung;
export const selectAnsprechpartner = (state: RootState): string => selectBestellung(state).ansprechpartner;
export const selectBestellhinweis = (state: RootState): string | undefined => selectBestellung(state).bestellhinweis;

export const selectBestelldaten = (state: RootState): BestelldatenFormular => {
    const wunschtermin = selectDateAndTimeFromWunschtermin(state);
    const wunschterminWEVon = selectDateAndTimeFromWunschterminVon(state);
    const wunschterminWEBis = selectDateAndTimeFromWunschterminBis(state);

    return {
        lieferbedingung: selectLieferbedingung(state),
        ansprechpartner: selectAnsprechpartner(state),
        bestellhinweis: selectBestellhinweis(state),
        bestellhinweisWE: selectBestellhinweisWE(state),
        lieferhinweis: selectLieferhinweis(state),
        kfzKennzeichen: selectBestellung(state).kfzKennzeichenId,
        spedition: selectBestellung(state).speditionId,
        datumDebitor: wunschtermin?.datum,
        uhrzeitDebitor: wunschtermin?.uhrzeit,
        datumWEVon: wunschterminWEVon?.datum,
        uhrzeitWEVon: wunschterminWEVon?.uhrzeit,
        datumWEBis: wunschterminWEBis?.datum,
        uhrzeitWEBis: wunschterminWEBis?.uhrzeit,
        vvvoNummer: selectVvvoNummer(state),
    };
};

export const selectBestellhinweisWE = (state: RootState): string | undefined => selectBestellung(state).bestellhinweisWE;
export const selectLieferhinweis = (state: RootState): string | undefined => selectBestellung(state).lieferhinweis;
export const selectBestellpositionen = (state: RootState): BaseBestellposition[] => selectBestellung(state).bestellpositionen;
export const selectWunschterminVon = (state: RootState): string | undefined => selectBestellung(state).wunschterminVon;
export const selectDateAndTimeFromWunschterminVon = createSelector(selectWunschterminVon, (wunschterminVon): DateAndTime | undefined => {
    if (wunschterminVon) {
        return extractDateAndTimeFromDateString(wunschterminVon);
    }
    return undefined;
});

export const selectWunschterminBis = (state: RootState): string | undefined => selectBestellung(state).wunschterminBis;
export const selectDateAndTimeFromWunschterminBis = createSelector(selectWunschterminBis, (wunschterminBis): DateAndTime | undefined => {
    if (wunschterminBis) {
        return extractDateAndTimeFromDateString(wunschterminBis);
    }

    return undefined;
});

export const selectWunschtermin = (state: RootState): string | undefined => selectBestellung(state).wunschtermin;
export const selectDateAndTimeFromWunschtermin = createSelector(selectWunschtermin, (wunschtermin): DateAndTime | undefined => {
    if (wunschtermin) {
        return extractDateAndTimeFromDateString(wunschtermin);
    }

    return undefined;
});

export const selectVvvoNummer = (state: RootState): string | undefined => selectBestellung(state).vvvoNummer;
export const selectWarenempfaengerFromBestellung = (state: RootState, warenempfaenger: Warenempfaenger[]): Warenempfaenger | undefined =>
    warenempfaenger.find((warenempfaenger) => warenempfaenger.partnerNummer === selectBestellung(state).warenempfaengerPartnerNummer);
export const selectKfzKennzeichenFromBestellung = (state: RootState, kfzKennzeichen: KfzKennzeichen[]): string | undefined => {
    return kfzKennzeichen.find((kennzeichen) => kennzeichen.id === selectBestellung(state).kfzKennzeichenId)?.kennzeichen;
};
export const selectSpeditionFromBestellung = (state: RootState, spedition: Spedition[]): string | undefined => {
    return spedition.find((spedition) => spedition.id === selectBestellung(state).speditionId)?.email;
};

const selectSelectedWerkId = (state: RootState): string | undefined => selectBestellung(state).selectedWerkId;
export const selectBestellungIsSubmitting = (state: RootState): boolean => selectBestellung(state).submitState === RequestState.LOADING;
export const selectBestellungSubmitFailed = (state: RootState): boolean => selectBestellung(state).submitState === RequestState.FAILED;
export const selectBestellungIsUpdating = (state: RootState): boolean => selectBestellung(state).updateState === RequestState.LOADING;
export const selectBestellungUpdateFailed = (state: RootState): boolean => selectBestellung(state).updateState === RequestState.FAILED;
export const selectBestellungUpdateSuccess = (state: RootState): boolean => selectBestellung(state).updateState === RequestState.SUCCESSFUL;
const selectWarenempfaengerPartnerNummer = (state: RootState): string => selectBestellung(state).warenempfaengerPartnerNummer;

const selectBestellpositionenViewFromBestellpositionenAndArtikeln = createSelector(
    selectBestellung,
    selectArtikelLoseWareList,
    ArtikelSackwarenSelectors.list,
    (bestellung, artikelLoseWareList, artikelSackwareList): BestellpositionView[] => {
        return BestellungenHelper.bestellpositionenViewFromBestellpositionenAndArtikeln(
            bestellung.bestellpositionen,
            artikelSackwareList,
            artikelLoseWareList
        );
    }
);

const selectBestellpositionenViewNummern = createSelector(
    selectBestellpositionenViewFromBestellpositionenAndArtikeln,
    (bestellpositionen): string[] => {
        return bestellpositionen.map((b) => b.nummer);
    }
);

const selectBestellpositionenLoseWareView = createSelector(
    selectBestellpositionenViewFromBestellpositionenAndArtikeln,
    (bestellpositionenView): BestellpositionArtikelLoseWareView[] => {
        return bestellpositionenView.filter(isLoseWare);
    }
);

const selectBestellpositionenSackwareView = createSelector(
    selectBestellpositionenViewFromBestellpositionenAndArtikeln,
    (bestellpositionenView): BestellpositionArtikelSackwareView[] => {
        return bestellpositionenView.filter(isSackwareView);
    }
);

const selectBestellpositionenSackware = createSelector(selectBestellpositionen, (bestellpositionen): BestellpositionArtikelSackware[] => {
    return bestellpositionen.filter(isSackware);
});

export const BestellungSelectors = {
    bestellung: selectBestellung,
    bestellpositionen: selectBestellpositionen,
    rechnungsempfaengerPartnerNummer: selectRechnungsempfaengerPartnerNummer,
    warenempfaengerPartnerNummer: selectWarenempfaengerPartnerNummer,
    bestellpositionenViewFromBestellpositionenAndArtikeln: selectBestellpositionenViewFromBestellpositionenAndArtikeln,
    bestellpositionenViewNummern: selectBestellpositionenViewNummern,
    bestellpositionenLoseWareView: selectBestellpositionenLoseWareView,
    bestellpositionenSackwareView: selectBestellpositionenSackwareView,
    bestellpositionenSackware: selectBestellpositionenSackware,
    selectedWerkId: selectSelectedWerkId,
    isUpdating: selectBestellungIsUpdating,
    updateFailed: selectBestellungUpdateFailed,
    isSubmitting: selectBestellungIsSubmitting,
    submitFailed: selectBestellungSubmitFailed,
};

export type BestellungState = {
    bestellpositionen: BaseBestellposition[];
    submitState: RequestState;
    updateState: RequestState;
    amountMissing: boolean;
    warenempfaengerPartnerNummer: string;
    rechnungsempfaengerPartnerNummer: string;
    lieferbedingung: string;
    ansprechpartner: string;
    bestellhinweis?: string;
    bestellhinweisWE?: string;
    lieferhinweis?: string;
    selectedWerkId?: string;
    stateVersion: number;
    kfzKennzeichenId?: string;
    speditionId?: string;
    wunschtermin?: string;
    wunschterminVon?: string;
    wunschterminBis?: string;
    vvvoNummer?: string;
    heimtierfutterFormular: HeimtierfutterFormular;
    isLoadingVorschlaege: boolean;
    hasMergedVorschlaege: boolean;
};

export const sortByLieferscheindatumDesc = (a: Vorschlag, b: Vorschlag): number => {
    if (a.lieferscheindatum > b.lieferscheindatum) {
        return -1;
    }
    if (a.lieferscheindatum < b.lieferscheindatum) {
        return 1;
    }
    return 0;
};

function mergeVorschlaegeWithBestellpositionenHelper(
    bestellpositionen: BaseBestellposition[],
    artikelSackware: ArtikelSackware[],
    artikelLoseWare: ArtikelLoseWare[],
    vorschlaege: Vorschlag[]
): BaseBestellposition[] {
    const filteredVorschlaege = vorschlaege.filter((v) => !bestellpositionen.some((bp) => bp.artikelNummer === v.artikelNummer));
    const filteredVorschlaegeWithKnownArtikeln = filteredVorschlaege.filter(
        (v) => artikelSackware.some((a) => a.nummer === v.artikelNummer) || artikelLoseWare.some((a) => a.nummer === v.artikelNummer)
    );
    const sortedVorschlaege = filteredVorschlaegeWithKnownArtikeln.sort(sortByLieferscheindatumDesc);

    const newBestellpositionen: BaseBestellposition[] = sortedVorschlaege.map((v) => {
        // Artikel suchen und SW oder LW unterscheiden
        const artikelSW = artikelSackware.find((a) => a.nummer === v.artikelNummer);
        if (artikelSW) {
            const bestellpositionSW: BestellpositionArtikelSackware = {
                werkId: v.werkId,
                artikelNummer: v.artikelNummer,
                mengeVe: '',
                mengePal: '',
                silo: '',
                artikelTyp: ArtikelTyp.Sackware,
            };
            return bestellpositionSW;
        }
        const artikelLW = artikelLoseWare.find((a) => a.nummer === v.artikelNummer);
        if (artikelLW) {
            const bestellpositionLW: BestellpositionArtikelLoseWare = {
                werkId: v.werkId,
                artikelNummer: v.artikelNummer,
                menge: '',
                artikelTyp: ArtikelTyp.LoseWare,
                berechnungsart: Berechnungsart.KeinVorgabe,
                zulage: false,
            };
            return bestellpositionLW;
        }
        // Wenn kein Artikel gefunden wird, wurde ein unbekannter Artikel vorgeschlagen, kann aber nicht passieren
        // da die oben rausgefiltert wurden
        throw new Error('Vorschlag for unknown article');
    });
    return [...bestellpositionen, ...newBestellpositionen];
}

const bestellungSlice = createSlice({
    name: 'bestellung',
    initialState: INITIAL_BESTELLUNGEN_STATE,
    reducers: {
        sendBestellung(state, _: PayloadAction<SendBestellungAction>) {
            state.submitState = RequestState.LOADING;
        },
        sendBestellungSucceeded(state) {
            return {
                ...INITIAL_BESTELLUNGEN_STATE,
                updateState: state.updateState,
            };
        },
        sendBestellungFailed(state) {
            state.submitState = RequestState.FAILED;
        },
        clearBestellung() {
            return INITIAL_BESTELLUNGEN_STATE;
        },
        resetUpdateBestellung(state) {
            state.updateState = RequestState.INITIAL;
        },
        updateBestellungBatch(state, _: PayloadAction<BestellungDetails[]>) {
            state.updateState = RequestState.LOADING;
        },
        updateBestellung(state, _: PayloadAction<BestellungUpdateRequest>) {
            state.updateState = RequestState.LOADING;
        },
        updateBestellungBatchSucceeded(state) {
            state.updateState = RequestState.SUCCESSFUL;
        },
        updateBestellungSucceeded(state) {
            state.updateState = RequestState.SUCCESSFUL;
        },
        updateBestellungBatchFailed(state) {
            state.updateState = RequestState.FAILED;
        },
        updateBestellungFailed(state) {
            state.updateState = RequestState.FAILED;
        },
        updateLieferbedingung(state) {
            return state;
        },
        setBestellpositionen(state, action: PayloadAction<BaseBestellposition[]>) {
            state.amountMissing = false;
            state.bestellpositionen = action.payload;
        },
        setBestelldaten(state, action: PayloadAction<SetBestelldatenAction>) {
            return {
                ...state,
                ...action.payload,
            };
        },
        setWunschtermin(state, action: PayloadAction<string>) {
            state.wunschtermin = action.payload;
        },
        setWunschterminVonBis(state, action: PayloadAction<SetWunschterminVonBisAction>) {
            state.wunschterminVon = action.payload.wunschterminVon;
            state.wunschterminBis = action.payload.wunschterminBis;
        },
        setWarenempfaenger(state, action: PayloadAction<string>) {
            state.warenempfaengerPartnerNummer = action.payload;
            // Da die VVVO-Nummer abhängig vom WE ist, muss sie jetzt gelöscht werden
            state.vvvoNummer = undefined;
            // Auch der Rechnungsempfänger ist abh. vom WE
            state.rechnungsempfaengerPartnerNummer = '';
            // und die Bestellvorschläge
            state.hasMergedVorschlaege = false;
        },
        setRechnungsempfaenger(state, action: PayloadAction<string>) {
            state.rechnungsempfaengerPartnerNummer = action.payload;
            // wenn der Rechnungsempfänger sich ändert, müssen die Vorschläge neu ausgewertet werden
            state.hasMergedVorschlaege = false;
        },
        setKfzKennzeichen(state, action: PayloadAction<string | undefined>) {
            state.kfzKennzeichenId = action.payload;
        },
        setSpedition(state, action: PayloadAction<string | undefined>) {
            state.speditionId = action.payload;
        },
        setWerk(state, action: PayloadAction<string | undefined>) {
            state.selectedWerkId = action.payload;
        },
        setHeimtierfutterFormular(state, action: PayloadAction<HeimtierfutterFormular>) {
            state.heimtierfutterFormular = action.payload;
        },
        setLieferbedingung(state, action: PayloadAction<string>) {
            state.lieferbedingung = action.payload;
        },
        mergeVorschlaegeWithBestellpositionen(state, action: PayloadAction<MergeVorschlaegeWithBestellpositionenAction>) {
            const { artikelLoseWare, artikelSackware, vorschlaege } = action.payload;
            state.bestellpositionen = state.hasMergedVorschlaege
                ? state.bestellpositionen
                : mergeVorschlaegeWithBestellpositionenHelper(state.bestellpositionen, artikelSackware, artikelLoseWare, vorschlaege);
            state.hasMergedVorschlaege = true;
        },
    },
});

export const {
    setRechnungsempfaenger,
    setWarenempfaenger,
    setBestellpositionen,
    setWunschtermin,
    setLieferbedingung,
    setBestelldaten,
    setHeimtierfutterFormular,
    setKfzKennzeichen,
    setSpedition,
    setWerk,
    setWunschterminVonBis,
    sendBestellung,
    sendBestellungFailed,
    sendBestellungSucceeded,
    clearBestellung,
    mergeVorschlaegeWithBestellpositionen,
    resetUpdateBestellung,
    updateBestellung,
    updateBestellungBatch,
    updateBestellungBatchFailed,
    updateBestellungBatchSucceeded,
    updateBestellungSucceeded,
    updateBestellungFailed,
    updateLieferbedingung,
} = bestellungSlice.actions;

export type SetWarenempfaengerType = { type: typeof setWarenempfaenger.type };
export type SetRechnungsempfaengerType = { type: typeof setRechnungsempfaenger.type };

export const bestellungReducer = bestellungSlice.reducer;
