import FormControl from '../../../../ui-components/FormControl/FormControl';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { TextInputRule } from '../../../../types/enums';
import { PropertyEntryReadonlyProps } from '../types';
import TextItem from '../../../../ui-components/TextItem/TextItem';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const MengeListEntry = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly) {
        return <TextItem label="Menge (t)" text={bestellposition.menge} isStorniert={bestellposition.storno} />;
    }

    return (
        <FormControl
            name={`bestellpositionenView[${bestellposition.index}].menge`}
            id={`bestellpositionenView[${bestellposition.index}].menge`}
            label="Menge (t) *"
            renderChild={(props): JSX.Element => (
                <TextInput
                    {...props}
                    type="text"
                    storniert={bestellposition.storno}
                    disabled={disabled}
                    textInputRule={TextInputRule.ONLY_POSITIVE_DECIMALS_WITH_ONE_DECIMAL_PLACE}
                    data-testid={`${bestellposition.nummer}-menge`}
                />
            )}
        />
    );
};
