import { KeyboardEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Column, useTable } from 'react-table';
import { Dispatch } from 'redux';
import { getKontrakte, KontraktuebersichtSelectors } from '../../store/Kontraktuebersicht.store';
import { mobileQuery } from '../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { PAGE_TITLE_KONTRAKTE, routes } from '../../shared/constants';
import { Filter, UebersichtKontrakt } from '../../shared/types';
import {
    kontraktAbschlussmengeColumn,
    kontraktansichtLinkColumn,
    kontraktColumn,
    kontraktLieferDatumAbColumn,
    kontraktLieferDatumBisColumn,
    kontraktpartnerColumn,
    kontraktPreislistenArt,
    kontraktRestmengeColumn,
    kontraktWeNameColumn,
} from './Columns';
import { useMediaQuery } from 'react-responsive';
import kontraktStyles from './Kontraktuebersicht.module.scss';
import styles from '../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb.module.scss';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import { KontraktuebersichtHeader } from './KontraktuebersichtHeader';
import { FilterWithOptions, FormattedValueAfterSelectionWE, kontraktpartnerOptionen, warenempfaengerOptionen } from './FilterWithOptions';
import { OverviewListBody } from '../../shared/ui-components/OverviewList/OverviewListBody';
import KontraktUebersichtTable from './KontraktUebersichtTable/KontraktUebersichtTable';
import { useHistory } from 'react-router-dom';
import { Option } from '../../shared/helper/select-options';

const loadKontrakte = (
    dispatch: Dispatch,
    kontraktNummer?: string,
    preislistenArt?: string,
    warenempfaengerNummer?: string,
    kontraktPartnerNummer?: string
) => {
    const filter: Filter = {};
    if (kontraktNummer) {
        filter.kontraktNummer = kontraktNummer;
    }

    if (preislistenArt) {
        filter.preislistenArt = preislistenArt;
    }

    if (warenempfaengerNummer) {
        filter.warenempfaengerNummer = warenempfaengerNummer;
    }

    if (kontraktPartnerNummer) {
        filter.kontraktPartnerNummer = kontraktPartnerNummer;
    }

    dispatch(
        getKontrakte({
            filter,
        })
    );
};

const MOBILE_COLUMNS = [
    'kontraktNummer',
    'kontraktpartnerName',
    'rechnungsempfaengerName',
    'preislistenArt',
    'lieferDatumAb',
    'lieferDatumBis',
    'abschlussmenge',
    'restmenge',
];

const getKontrakteColumns = (isMobile: boolean): Column<UebersichtKontrakt>[] => {
    return [
        kontraktColumn(isMobile),
        kontraktpartnerColumn(isMobile),
        kontraktPreislistenArt(isMobile),
        kontraktWeNameColumn(isMobile),
        kontraktLieferDatumAbColumn(isMobile),
        kontraktLieferDatumBisColumn(isMobile),
        kontraktAbschlussmengeColumn(isMobile),
        kontraktRestmengeColumn(isMobile),
        kontraktansichtLinkColumn(),
    ];
};

export const Kontraktuebersicht = (): ReactElement => {
    const kontrakte = useSelector(KontraktuebersichtSelectors.kontrakte);
    const isLoading = useSelector(KontraktuebersichtSelectors.kontrakteLoading);
    const loadedFailed = useSelector(KontraktuebersichtSelectors.kontrakteLoadFailed);
    const history = useHistory();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: mobileQuery });

    useDocumentTitle(PAGE_TITLE_KONTRAKTE);

    const [kontraktNummerFilter, setKontraktNummerFilter] = useState('');
    const [kontraktNummerFilterInternalValue, setKontraktNummerFilterInternalValue] = useState('');
    const [preislistenArtFilter, setPreislistenArtFilter] = useState('');
    const [preislistenArtFilterInternalValue, setPreislistenArtFilterInternalValue] = useState('');
    const [warenempfaengerNummerFilter, setWarenempfaengerNummerFilter] = useState<Option>();
    const [kontraktpartnerNummerFilter, setKontraktpartnerNummerFilter] = useState<Option>();

    const columns: Column<UebersichtKontrakt>[] = useMemo(() => getKontrakteColumns(isMobile), [isMobile]);

    const tableInstance = useTable<UebersichtKontrakt>({
        columns,
        data: kontrakte,
    });

    const { state } = tableInstance;

    const onFilterKeyDown = (event: KeyboardEvent, setFunction: VoidFunction): void => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            setFunction();
        }
    };

    useEffect(() => {
        loadKontrakte(
            dispatch,
            kontraktNummerFilter,
            preislistenArtFilter,
            warenempfaengerNummerFilter?.nummer,
            kontraktpartnerNummerFilter?.nummer
        );
    }, [
        dispatch,
        kontraktNummerFilter,
        preislistenArtFilter,
        state.pageIndex,
        state.pageSize,
        kontraktpartnerNummerFilter?.nummer,
        warenempfaengerNummerFilter?.nummer,
    ]);

    return (
        <>
            <KontraktuebersichtHeader
                visible={loadedFailed}
                actionCallback={() =>
                    loadKontrakte(
                        dispatch,
                        kontraktNummerFilter,
                        preislistenArtFilter,
                        warenempfaengerNummerFilter?.value,
                        kontraktpartnerNummerFilter?.value
                    )
                }
            />

            <LoadingSpinner isLoading={isLoading}>
                <>
                    <div className={styles._panelAboveTable}>
                        <div className={styles._filterPanel}>
                            <div className={kontraktStyles._kontraktFilter}>
                                <TextInputWithoutFormik
                                    name="kontraktNummerFilter"
                                    id="kontraktNummerFilter"
                                    placeholder="Kontraktnummer Filter"
                                    value={kontraktNummerFilterInternalValue}
                                    onKeyDown={(event) =>
                                        onFilterKeyDown(event, () => setKontraktNummerFilter(kontraktNummerFilterInternalValue))
                                    }
                                    onFieldChange={setKontraktNummerFilterInternalValue}
                                    onFieldBlur={setKontraktNummerFilter}
                                />
                            </div>
                            <FilterWithOptions
                                className={kontraktStyles._kontraktFilter}
                                options={kontraktpartnerOptionen(kontrakte)}
                                value={kontraktpartnerNummerFilter}
                                onChange={setKontraktpartnerNummerFilter}
                                loading={isLoading}
                                name={'kontraktpartnerNummerFilter'}
                                placeholder={'Kontraktpartner Filter'}
                            />
                            <div className={kontraktStyles._kontraktFilter}>
                                <TextInputWithoutFormik
                                    name="preislistenArtFilter"
                                    id="preislistenArtFilter"
                                    placeholder="Preislistenart Filter"
                                    value={preislistenArtFilterInternalValue}
                                    onKeyDown={(event) =>
                                        onFilterKeyDown(event, () => setPreislistenArtFilter(preislistenArtFilterInternalValue))
                                    }
                                    onFieldChange={setPreislistenArtFilterInternalValue}
                                    onFieldBlur={setPreislistenArtFilter}
                                />
                            </div>
                            <FilterWithOptions
                                className={kontraktStyles._kontraktFilter}
                                options={warenempfaengerOptionen(kontrakte)}
                                value={warenempfaengerNummerFilter}
                                onChange={setWarenempfaengerNummerFilter}
                                loading={isLoading}
                                name={'warenempfaengerNummerFilter'}
                                placeholder={'Warenempfänger Filter'}
                                formattedValue={FormattedValueAfterSelectionWE}
                            />
                        </div>
                    </div>
                    {isMobile ? (
                        <OverviewListBody
                            tableInstance={tableInstance}
                            mobileColumns={MOBILE_COLUMNS}
                            isPaginationOn={false}
                            onRowClick={(row): void => {
                                history.push(`${routes.kontraktansicht}/${row.original['kontraktNummer']}`);
                            }}
                        />
                    ) : (
                        <KontraktUebersichtTable {...tableInstance} />
                    )}
                </>
            </LoadingSpinner>
        </>
    );
};
