import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData, postData } from '../shared/fetchApi';
import {
    GET_PREISLISTENKENNZEICHEN_FAILED,
    GET_PREISLISTENKENNZEICHEN_SUCCEEDED,
    SET_PREISLISTENKENNZEICHEN_FAILED,
    SET_PREISLISTENKENNZEICHEN_SUCCEEDED,
    SetPreislistenkennzeichenAction,
} from '../store/Preislistenkennzeichen.store';

export function* getPreislistenkennzeichenSaga(): IterableIterator<PutEffect | CallEffect> {
    try {
        const preislistenkennzeichen = yield call(getData, 'preislistenkennzeichen');
        yield put({ type: GET_PREISLISTENKENNZEICHEN_SUCCEEDED, preislistenkennzeichen: preislistenkennzeichen });
    } catch (e) {
        yield put({ type: GET_PREISLISTENKENNZEICHEN_FAILED });
    }
}

export function* postPreislistenkennzeichenSaga(action: SetPreislistenkennzeichenAction): Generator<PutEffect | CallEffect> {
    try {
        yield call(postData, 'preislistenkennzeichen', action.preislistenkennzeichen);
        yield put({ type: SET_PREISLISTENKENNZEICHEN_SUCCEEDED });
    } catch (e) {
        yield put({ type: SET_PREISLISTENKENNZEICHEN_FAILED });
    }
}
