import { DetailedHTMLProps, forwardRef, HTMLAttributes, LegacyRef, PropsWithChildren } from 'react';
import tableStyles from './Table.module.scss';

type TableRowProps = DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

const TableRow = forwardRef(({ children }: PropsWithChildren<TableRowProps>, ref: LegacyRef<HTMLTableRowElement>): JSX.Element => {
    return (
        <tr ref={ref} className={tableStyles._tableBodyRow}>
            {children}
        </tr>
    );
});

TableRow.displayName = 'TableRow';

export { TableRow };
