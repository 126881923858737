import { FormRow } from '../Form/FormRow';
import { Lieferbedingungen, LieferbedingungLabel } from '../../types/enums';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../store/Navigation.store';
import React from 'react';
import { BestellungDetails } from '../../types';
import { FormColumnContent } from '../Form/FormColumnContent';

export const LieferartAndRueckfragenFormRow: React.FC<{ bestellung: BestellungDetails }> = ({ bestellung }): JSX.Element | null => {
    const isDebitor = useSelector(selectIsDebitor);
    const lieferbedingung =
        bestellung.lieferbedingung === Lieferbedingungen.EX_WERK ? LieferbedingungLabel.EX_WERK : LieferbedingungLabel.FRANCO;

    if (!isDebitor) return null;
    return (
        <FormRow>
            <FormColumnContent content={lieferbedingung} title={'Lieferart'} />
            <FormColumnContent content={bestellung.ansprechpartner} title={'Kontakt für Rückfragen'} />
        </FormRow>
    );
};
