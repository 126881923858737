import { TableCell } from '../../../../ui-components/Table';
import FormikCheckbox from '../../../../ui-components/CheckBox/FormikCheckbox';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { TextInputRule } from '../../../../types/enums';
import styles from '../../ArtikelSackwareTable/SackwareTable.module.scss';
import IconButton, { IconSize } from '../../../../ui-components/IconButton/IconButton';
import iconAddArrow from '../../../../../assets/icon-add-arrow.svg';
import { BestellpositionArtikelSackwareView } from '../../../../types';
import { PropertyEntryProps } from '../types';
import { MengeSWTableCell } from './MengeSWTableCell';

export const MengeSWSollbestaende = ({
    bestellposition,
    formikRowPath,
    onApplyBestellvorschlag,
    bestellvorschlagVE,
    withSollbestaende = false,
}: PropertyEntryProps<BestellpositionArtikelSackwareView> & {
    withSollbestaende?: boolean;
    onApplyBestellvorschlag?: () => void;
    bestellvorschlagVE?: number;
}): JSX.Element => {
    const { nummer, faktorBasiseinheitVe } = bestellposition;
    const groupedFormikPath = `${formikRowPath}[${bestellposition.index}]`;

    if (withSollbestaende) {
        return (
            <>
                <TableCell text checkbox>
                    <FormikCheckbox
                        name={`${groupedFormikPath}.aufPalRunden`}
                        id={`${groupedFormikPath}.aufPalRunden`}
                        data-testid={`${nummer}-aufPalRunden`}
                    />
                </TableCell>
                <TableCell number input>
                    <TextInput
                        name={`${groupedFormikPath}.lagerKapazitaetVE`}
                        id={`${groupedFormikPath}.lagerKapazitaetVE`}
                        type="number"
                        textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                        inTableCell={true}
                        data-testid={`${nummer}-lagerKapazitaetVE`}
                    />
                </TableCell>
                <TableCell number input>
                    <TextInput
                        name={`${groupedFormikPath}.bestandVE`}
                        id={`${groupedFormikPath}.bestandVE`}
                        type="number"
                        textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                        inTableCell={true}
                        data-testid={`${nummer}-bestandVE`}
                    />
                </TableCell>
                <TableCell number>
                    <span data-testid={`${nummer}-bestellvorschlagVE`}>{bestellvorschlagVE}</span>
                </TableCell>
                <TableCell button input>
                    <div className={styles._buttonContainer}>
                        <IconButton
                            icon={iconAddArrow}
                            iconSize={IconSize.LARGE}
                            alt="Vorschlag übernehmen"
                            fullWidth
                            data-testid={`${nummer}-applyBestellvorschlag`}
                            onClick={(): void => {
                                if (onApplyBestellvorschlag) {
                                    onApplyBestellvorschlag();
                                }
                            }}
                        />
                    </div>
                </TableCell>
                <TableCell number input>
                    <TextInput
                        name={`${groupedFormikPath}.mengeVe`}
                        id={`${groupedFormikPath}.mengeVe`}
                        type="number"
                        textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                        inTableCell={true}
                        data-testid={`${nummer}-ve`}
                    />
                </TableCell>
                <TableCell number>{faktorBasiseinheitVe}</TableCell>
            </>
        );
    }

    return <MengeSWTableCell bestellposition={bestellposition} formikRowPath={groupedFormikPath} />;
};
