import { TableCell } from '../../../../ui-components/Table';
import FormControl from '../../../../ui-components/FormControl/FormControl';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { BestellpositionArtikelLoseWareView } from '../../../../types';
import { PropertyEntryReadonlyProps } from '../types';
import { extractDateAndTimeFromDateString } from '../../../../helper/date-helper';

export const WunschterminDatumCell = ({
    disabled,
    bestellposition,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly || bestellposition.storno) {
        const dateAndTime = extractDateAndTimeFromDateString(bestellposition.wunschtermin);
        return (
            <TableCell text storniert={bestellposition.storno}>
                <span>{dateAndTime.datum}</span>
            </TableCell>
        );
    }
    return (
        <TableCell select>
            <FormControl
                id={`bestellpositionenView[${bestellposition.index}].debitorDatum`}
                name={`bestellpositionenView[${bestellposition.index}].debitorDatum`}
                inTableCell
                displayError={false}
                renderChild={(props) => <TextInput {...props} inTableCell type="date" placeholder="jjjj-mm-tt" disabled={disabled} />}
            />
        </TableCell>
    );
};
