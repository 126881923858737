import { Filter } from '../../shared/types';

export const convertFilterToString = (filter: Filter | undefined): string => {
    if (!filter) {
        return '';
    }
    const newFilter = Object.entries(filter).reduce(
        (acc, [filtername, filterwert]) => `${acc}&${encodeURIComponent(filtername)}=${encodeURIComponent(filterwert.toString())}`,
        ''
    );
    return newFilter;
};
