import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getWerke } from '../../store/Abholwerk.store';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import Button from '../../shared/ui-components/Button/Button';
import { routes, selectNextRoute, selectPreviousRoute, setCurrentWorkflowStep, WorkflowStep } from '../../store/Workflow.store';
import { setBestellpositionen, setWerk } from '../../store/Bestellung.store';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { selectIsDebitor } from '../../store/Navigation.store';
import type { RootState } from '../../configureStore';
import styles from './Abholwerk.module.scss';

const Abholwerk = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const previousRoute = useSelector(selectPreviousRoute);
    const rechnungsempfaengerPartnerNummer = useSelector((state: RootState) => state.bestellung.rechnungsempfaengerPartnerNummer);
    const warenempfaengerPartnerNummer = useSelector((state: RootState) => state.bestellung.warenempfaengerPartnerNummer);
    const isDebitor = useSelector(selectIsDebitor);

    useEffect(() => {
        dispatch(setCurrentWorkflowStep(WorkflowStep.AuswahlWerk, history));
    }, [dispatch, history]);

    useEffect(() => {
        if (isDebitor) {
            if (rechnungsempfaengerPartnerNummer) {
                dispatch(getWerke(rechnungsempfaengerPartnerNummer));
            }
        } else {
            if (rechnungsempfaengerPartnerNummer && warenempfaengerPartnerNummer) {
                dispatch(getWerke(rechnungsempfaengerPartnerNummer, warenempfaengerPartnerNummer));
            }
        }
    }, [dispatch, rechnungsempfaengerPartnerNummer, warenempfaengerPartnerNummer, isDebitor]);

    useDocumentTitle('Auswahl Werk');

    const nextRoute = useSelector(selectNextRoute);

    const werke = useSelector((state: RootState) => state.werke.werke);
    const isLoading = useSelector((state: RootState) => state.werke.isLoading);
    const loadFailed = useSelector((state: RootState) => state.werke.loadFailed);
    return (
        <>
            <h2>Abholwerk auswählen</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message="Die Werke konnten nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={(): void => {
                    dispatch(getWerke(rechnungsempfaengerPartnerNummer));
                }}
            />
            <NotificationBar testId="loadError-message-bar" message="" isVisible={!rechnungsempfaengerPartnerNummer}>
                <Link className={styles._notificationLink} to={routes.rechnungsempfaengerAuswaehlen}>
                    Bitte wählen Sie erst einen Rechnungsempfänger aus.
                </Link>
            </NotificationBar>
            {rechnungsempfaengerPartnerNummer && (
                <LoadingSpinner isLoading={isLoading}>
                    <>
                        <div className={styles._container}>
                            {werke.map((werk, index) => (
                                <Link
                                    data-cy={`werk-${werk.name}`}
                                    key={index}
                                    onClick={(): void => {
                                        dispatch(setBestellpositionen([]));
                                        dispatch(setWerk(werk.id));
                                    }}
                                    to={nextRoute}
                                    className={styles.linkButton}
                                >
                                    {werk.name}
                                </Link>
                            ))}
                        </div>
                        <ButtonGroup>
                            <Button type="button" onClick={(): void => history.push(previousRoute)} isSecondary={true}>
                                Zurück
                            </Button>
                        </ButtonGroup>
                    </>
                </LoadingSpinner>
            )}
        </>
    );
};

export default Abholwerk;
