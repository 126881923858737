import { forwardRef, LegacyRef, MouseEventHandler } from 'react';
import styles from './IconButton.module.scss';
import Label from '../Label/Label';

export enum IconSize {
    SMALL,
    LARGE,
}

export type Props = Readonly<{
    icon: string;
    alt: string;
    onClick?: MouseEventHandler;
    label?: string;
    iconSize?: IconSize;
    disabled?: boolean;
    fullWidth?: boolean;
    dataCy?: string;
    testId?: string;
    background?: boolean;
    burger?: boolean;
}>;

const IconButton = (props: Props, ref: LegacyRef<HTMLButtonElement> | undefined): JSX.Element => {
    const { icon, alt, onClick, label, iconSize, fullWidth, dataCy, testId, background, burger, ...additionalProperties } = props;
    const buttonStyles = label ? [styles._button, styles['_button--withLabel']] : [styles._button];
    if (fullWidth) {
        buttonStyles.push(styles['_button--fullWidth']);
    }
    if (background) {
        buttonStyles.push(styles['_button--background']);
    }
    if (burger) {
        buttonStyles.push(styles['_button--burger']);
    }
    const iconStyles = iconSize === IconSize.LARGE ? [styles._icon, styles['_icon--large']] : [styles._icon, styles['_icon--small']];
    return (
        <button
            data-cy={dataCy}
            data-testid={testId}
            ref={ref}
            type="button"
            className={buttonStyles.join(' ')}
            onClick={onClick}
            {...additionalProperties}
        >
            <img src={icon} className={iconStyles.join(' ')} alt={alt} />
            {label && <Label>{label}</Label>}
        </button>
    );
};

export default forwardRef(IconButton);
