import { ReactNode } from 'react';
import FormControl from '../../ui-components/FormControl/FormControl';
import SelectInput from '../../ui-components/SelectInput/SelectInput';
import { Option } from '../../helper/select-options';
import styles from './KfzKennzeichenAuswahl.module.scss';
import { KfzKennzeichenView } from '../../../store/KfzKennzeichen.store';

export type Props = Readonly<{
    kfzKennzeichen: Option[];
    onKfzKennzeichenCreated: (kfzKennzeichen: string) => void;
}>;

export const mapKfzKennzeichenIdToKfzKennzeichen = (
    allKfzKennzeichen: KfzKennzeichenView[],
    selectedKfzKennzeichenId: string | undefined
): string => {
    if (!selectedKfzKennzeichenId) return '';
    return allKfzKennzeichen.find((kfzKennzeichenView) => kfzKennzeichenView.id === selectedKfzKennzeichenId)?.kennzeichen ?? '';
};

const KfzKennzeichenAuswahl = ({ kfzKennzeichen, onKfzKennzeichenCreated }: Props): JSX.Element => {
    return (
        <FormControl
            name="kfzKennzeichen"
            id="kfzKennzeichen"
            label="KFZ-Kennzeichen"
            renderChild={(props): JSX.Element => (
                <SelectInput
                    {...props}
                    options={kfzKennzeichen}
                    onCreateItem={onKfzKennzeichenCreated}
                    formatCreateLabel={(inputValue: string): ReactNode => (
                        <div className={styles._newSelectOption}>{`"${inputValue}" anlegen`}</div>
                    )}
                    placeholder={'Kennzeichenauswahl'}
                    maxLength={12}
                    isClearable={true}
                    nativeClearLabel={'(Kein Kennzeichen)'}
                    autoSelectIfOnlyOneOption={true}
                />
            )}
        />
    );
};

export default KfzKennzeichenAuswahl;
