import SelectInput from '../../ui-components/SelectInput/SelectInput';
import { berechnungsartOptions } from '../../helper/select-options';
import { BestellpositionArtikelLoseWareView } from '../../types';
import { KontraktAuswahl } from './KontraktAuswahl';
import { useMediaQuery } from 'react-responsive';
import styles from '../../../admin-views/Partner/Partner.module.scss';
import Label from '../../ui-components/Label/Label';

type PreisartSelectionProps = {
    disabled?: boolean;
    bestellposition: BestellpositionArtikelLoseWareView;
};

export const PreisartAuswahl = ({ disabled, bestellposition }: PreisartSelectionProps): JSX.Element => {
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });
    const formikPath = `bestellpositionenView[${bestellposition.index}].berechnungsart`;

    const SelectionComponent = (
        <SelectInput
            storniert={bestellposition.storno}
            isDisabled={disabled}
            classNamePrefix={'react_select__form-kontrakt'}
            id={formikPath}
            name={formikPath}
            options={berechnungsartOptions}
            placeholder="Auswahl Preisfindung"
            isClearable={false}
            inTableCell={!isMobile}
            autoSelectIfOnlyOneOption
        />
    );

    if (isMobile) {
        return (
            <>
                <div>
                    <Label htmlFor={formikPath}>Preisfindung</Label>
                    {SelectionComponent}
                </div>
                <KontraktAuswahl bestellposition={bestellposition} disabled={disabled} />
            </>
        );
    }

    return (
        <>
            {SelectionComponent}
            <KontraktAuswahl bestellposition={bestellposition} disabled={disabled} />
        </>
    );
};
