import type { TableInstance } from 'react-table';
import TableRow from './TableRow';
import { useMemo } from 'react';

interface TableBodyProps<T extends Record<string, unknown>> extends TableInstance<T> {
    isPagination?: boolean;
}

const TableBody = <T extends Record<string, unknown>>({
    getTableBodyProps,
    rows,
    prepareRow,
    page,
    isPagination = false,
}: TableBodyProps<T>): JSX.Element => {
    const initialRows = useMemo(() => (isPagination ? page : rows), [isPagination, page, rows]);

    return (
        <tbody {...getTableBodyProps()}>
            {initialRows.map((row, index) => {
                prepareRow(row);
                return <TableRow key={index} row={row} />;
            })}
        </tbody>
    );
};

export default TableBody;
