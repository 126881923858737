import { call, put, SagaGenerator, select } from 'typed-redux-saga';
import { CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getDataAsBlob, postData, triggerDownload } from '../shared/fetchApi';
import {
    DeleteDokumentAction,
    deleteDokumentFailed,
    deleteDokumentSucceeded,
    deselectAllDokumente,
    DokumentDownloadAction,
    dokumentDownloadFailed,
    dokumentDownloadSucceeded,
    dokumenteDownloadFailed,
    dokumenteDownloadSucceeded,
    DokumentSelectors,
} from '../store/Dokument.store';
import { PayloadAction } from '@reduxjs/toolkit';

export function* deleteDokumenteSaga(action: PayloadAction<DeleteDokumentAction>): SagaGenerator<void, PutEffect | CallEffect> {
    const { dokumentId } = action.payload;
    const url = `dokumente/${dokumentId}`;
    try {
        yield* call(postData, url, {}, 'DELETE');
        yield* put(deleteDokumentSucceeded({ dokumentId }));
    } catch (e) {
        yield* put(deleteDokumentFailed());
    }
}

export function* downloadDokumentSaga(action: PayloadAction<DokumentDownloadAction>): SagaGenerator<void, PutEffect | CallEffect> {
    const { dokumentId } = action.payload;
    const url = `dokumente/${dokumentId}`;
    try {
        const downloadConfig = yield* call(getDataAsBlob, url);
        yield* call(triggerDownload, downloadConfig);
        yield* put(dokumentDownloadSucceeded());
    } catch (e) {
        yield* put(dokumentDownloadFailed());
    }
}

export function* downloadMultipleDokumenteSaga(_: PayloadAction): SagaGenerator<void, PutEffect | CallEffect | SelectEffect> {
    const selectedDokumentIds = yield* select(DokumentSelectors.selectedDokumentIds);
    try {
        for (const selectedDokumentId of selectedDokumentIds) {
            const url = `dokumente/${selectedDokumentId}`;
            const downloadConfig = yield* call(getDataAsBlob, url);
            yield* call(triggerDownload, downloadConfig);
            yield* put(dokumenteDownloadSucceeded());
        }
        yield* put(deselectAllDokumente());
    } catch (e) {
        yield* put(dokumenteDownloadFailed());
    }
}
