import type { RootState } from '../configureStore';
import { BestelluebersichtSelectors } from './Bestelluebersicht.store';
import { BestellungGesamtstatus } from '../shared/types/enums';

// NOTE: This is the file where we store selectors that have to access multiple redux slices at once to
//       derive state from.

export const selectIsBestellungDraft = (state: RootState): boolean => {
    // current bestellung
    const { isDraft, status } = BestelluebersichtSelectors.currentBestellung(state);

    return (
        isDraft &&
        (status === BestellungGesamtstatus.LANDWARENHANDEL ||
            status === BestellungGesamtstatus.STORNIERT_DURCH_LANDWARENHANDEL ||
            status === BestellungGesamtstatus.STORNIERT_DURCH_WARENEMPFAENGER)
    );
};

export const selectIsBestellungExisting = (state: RootState): boolean => {
    const { isDraft, status } = BestelluebersichtSelectors.currentBestellung(state);

    return !isDraft && status !== BestellungGesamtstatus.NONE;
};

export const selectIsBestellungNew = (state: RootState): boolean => {
    const { isDraft, status } = BestelluebersichtSelectors.currentBestellung(state);

    return !isDraft && status === BestellungGesamtstatus.NONE;
};
