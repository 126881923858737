import { call, put, SagaGenerator, select } from 'typed-redux-saga';
import { getData, postData } from '../shared/fetchApi';
import {
    BestellungSelectors,
    selectAnsprechpartner,
    selectBestellhinweis,
    selectBestellhinweisWE,
    selectKfzKennzeichenFromBestellung,
    selectSpeditionFromBestellung,
    selectLieferbedingung,
    selectLieferhinweis,
    selectVvvoNummer,
    selectWarenempfaengerFromBestellung,
    selectWunschtermin,
    selectWunschterminBis,
    selectWunschterminVon,
    sendBestellungFailed,
    sendBestellungSucceeded,
    setLieferbedingung,
    updateBestellungBatchFailed,
    updateBestellungBatchSucceeded,
    updateBestellungFailed,
    updateBestellungSucceeded,
} from '../store/Bestellung.store';
import { resetWarenempfaenger } from '../store/Warenempfaenger.store';
import { kfzKennzeichenSelectors } from '../store/KfzKennzeichen.store';
import { speditionSelectors } from 'store/Spedition.store';
import { mapBestellpositionenDetailsToBestellpositionen, onlyBestellpositionenWithMenge } from '../shared/helper/bestellungen-helper';
import { selectWarenempaengerList } from './workflow.saga';
import { selectBestellprozesstyp } from '../store/Workflow.store';
import { selectIsDebitor } from '../store/Navigation.store';
import type { SendBestellungAction } from '../store/Bestellung.store';
import type { CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import type { KfzKennzeichenView } from '../store/KfzKennzeichen.store';
import type { SpeditionView } from 'store/Spedition.store';
import type { BestellungRequest, BestellungUpdateRequest, Bestellposition, BestellungDetails } from '../shared/types';
import { PayloadAction } from '@reduxjs/toolkit';

export function* sendBestellungSaga(
    action: PayloadAction<SendBestellungAction>
): SagaGenerator<void, PutEffect | CallEffect | SelectEffect> {
    try {
        const allWarenempfaenger = yield* select(selectWarenempaengerList);
        const warenempfaenger = yield* select(selectWarenempfaengerFromBestellung, allWarenempfaenger);
        const rechnungsempfaengerPartnerNummer = yield* select(BestellungSelectors.rechnungsempfaengerPartnerNummer);
        const lieferbedingung = yield* select(selectLieferbedingung);
        const ansprechpartner = yield* select(selectAnsprechpartner);
        const bestellhinweis = yield* select(selectBestellhinweis);
        const bestellhinweisWE = yield* select(selectBestellhinweisWE);
        const lieferhinweis = yield* select(selectLieferhinweis);
        const bestellpositionen = (yield* select(BestellungSelectors.bestellpositionen)) as Bestellposition[];
        const allKfzKennzeichen = yield* select(kfzKennzeichenSelectors.allKfzKennzeichen);
        const kfzKennzeichen = yield* select(selectKfzKennzeichenFromBestellung, allKfzKennzeichen);
        const allSpeditionen = yield* select(speditionSelectors.allSpeditionen);
        const spedition = yield* select(selectSpeditionFromBestellung, allSpeditionen);
        const wunschtermin = yield* select(selectWunschtermin);
        const wunschterminWEVon = yield* select(selectWunschterminVon);
        const wunschterminWEBis = yield* select(selectWunschterminBis);
        const vvvoNummer = yield* select(selectVvvoNummer);
        const bestellprozesstyp = yield* select(selectBestellprozesstyp);
        const bestellpositionenMitPositionsnummer = bestellpositionen.map(
            (bp, index): Bestellposition => ({
                ...bp,
                positionsnummer: (index + 1) * 10,
            })
        );
        const bestellung: BestellungRequest = {
            warenempfaenger,
            rechnungsempfaengerPartnerNummer,
            bestellpositionen: bestellpositionenMitPositionsnummer,
            lieferbedingung,
            ansprechpartner,
            bestellhinweis,
            bestellhinweisWE,
            lieferhinweis,
            wunschtermin,
            wunschterminWEVon: wunschterminWEVon,
            wunschterminWEBis: wunschterminWEBis,
            vvvoNummer,
            bestellprozesstyp: bestellprozesstyp?.toString().toUpperCase() ?? '',
            isDraft: action.payload.isDraft,
            geschlossen: false,
        };
        if (kfzKennzeichen) {
            bestellung.kfzKennzeichen = kfzKennzeichen;
        }

        if (spedition) {
            bestellung.spedition = spedition;
        }

        console.log('Hallo hier sendBestellungSaga: ' + bestellung);
        yield* call(postData, 'bestellungen', bestellung);
        yield* put(sendBestellungSucceeded());
        yield* put(resetWarenempfaenger());
    } catch (exception) {
        yield* put(sendBestellungFailed());
    }
}

export function* updateBestellungSaga(
    action: PayloadAction<BestellungUpdateRequest>
): SagaGenerator<void, PutEffect | CallEffect | SelectEffect> {
    try {
        const bestellungUpdateRequest = action.payload;
        const positionsNummern = bestellungUpdateRequest.bestellpositionen.map((pos) => pos.positionsnummer ?? 0);
        let maxPositionsNummer = Math.max(...positionsNummern);

        for (let i = 0; i < bestellungUpdateRequest.bestellpositionen.length; i++) {
            const bp = bestellungUpdateRequest.bestellpositionen[i];
            if (bp.positionsnummer === undefined) {
                maxPositionsNummer += 10;
                bp.positionsnummer = maxPositionsNummer;
            }
        }

        yield* call(postData, 'bestellungen/' + bestellungUpdateRequest.bestellnummer, bestellungUpdateRequest, 'PUT');
        yield* put(updateBestellungSucceeded());
    } catch (exception) {
        yield* put(updateBestellungFailed());
    }
}

export function* updateBestellungBatchSaga(
    action: PayloadAction<BestellungDetails[]>
): SagaGenerator<void, PutEffect | CallEffect | SelectEffect> {
    try {
        const bestellungen = action.payload;

        const allKfzKennzeichen: KfzKennzeichenView[] = yield* select(kfzKennzeichenSelectors.allKfzKennzeichen);
        const allSpeditionen: SpeditionView[] = yield* select(speditionSelectors.allSpeditionen);
        const updatedBestellungen: BestellungUpdateRequest[] = bestellungen.map((bestellung) => {
            const kfzKennzeichen = allKfzKennzeichen.find((kennzeichen) => kennzeichen.id === bestellung.kfzKennzeichen)?.kennzeichen ?? '';
            const spedition = allSpeditionen.find((spedition) => spedition.id === bestellung.spedition)?.email ?? '';
            const updatedBestellung: BestellungUpdateRequest = {
                id: bestellung.id,
                version: bestellung.version,
                ansprechpartner: bestellung.ansprechpartner,
                warenempfaenger: bestellung.warenempfaenger,
                bestellnummer: bestellung.bestellnummer,
                wunschtermin: bestellung.wunschtermin,
                wunschterminWEVon: bestellung.wunschterminWEVon,
                wunschterminWEBis: bestellung.wunschterminWEBis,
                bestellhinweis: bestellung.bestellhinweis,
                bestellhinweisWE: bestellung.bestellhinweisWE,
                lieferhinweis: bestellung.lieferhinweis,
                bestellprozesstyp: bestellung.bestellprozesstyp,
                vvvoNummer: bestellung.vvvoNummer,
                geschlossen: false,
                isDraft: false,
                lieferbedingung: bestellung.lieferbedingung,
                kfzKennzeichen,
                spedition,
                rechnungsempfaengerPartnerNummer: bestellung.rechnungsempfaenger.partnerNummer,
                bestellpositionen: bestellung.bestellpositionen
                    .filter(onlyBestellpositionenWithMenge)
                    .map(mapBestellpositionenDetailsToBestellpositionen)
                    .map((bp, index) => ({
                        ...bp,
                        positionsnummer: (index + 1) * 10,
                    })),
            };
            return updatedBestellung;
        });

        console.log('Hallo hier updateBestellungBatch: ' + updatedBestellungen);
        yield* call(postData, 'bestellungen', updatedBestellungen, 'PUT');
        yield* put(updateBestellungBatchSucceeded());
    } catch (exception) {
        yield* put(updateBestellungBatchFailed());
    }
}

type GetLieferbedingung = (url: string) => Promise<BestellungDetails[]>;

export function* updateLieferbedingungSaga(): SagaGenerator<void, PutEffect | CallEffect | SelectEffect> {
    try {
        const response = yield* call<[string], GetLieferbedingung>(getData, 'bestellungen?page=0&size=1&sort=bestelldatum,desc');
        const currentLieferbedingung = yield* select(selectLieferbedingung);
        const isDebitor = yield* select(selectIsDebitor);
        if (currentLieferbedingung === '' && response?.length > 0 && isDebitor) {
            yield* put(setLieferbedingung(response[0].lieferbedingung));
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.log(error.message);
        }
    }
}
