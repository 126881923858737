import { Fragment, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import styles from './BestelluebersichtUndAuftragskorb.module.scss';
import {
    isLoseWare,
    isSackwareView,
    mengeStringFromBestellpositionView,
    statusToIcon,
    statusToText,
} from '../../shared/helper/bestellungen-helper';
import dayjs from 'dayjs';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import { DateInfo, convertDateWithTimeFromIsoStringToDateInfo } from '../../shared/helper/date-helper';
import { createWunschterminString } from '../../shared/content-components/Wunschtermin/wunschtermin-helper';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';
import { addToSammelbestellung, removeFromSammelbestellung, BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { Link } from 'react-router-dom';
import iconDetailsLink from '../../assets/icon-details-link.svg';
import Tippy from '@tippyjs/react';
import { validateAuftragskorbBestellung } from '../../shared/helper/auftragskorb-validation';
import { useDispatch, useSelector } from 'react-redux';
import type { BestellungDetails, Rechnungsempfaenger, Warenempfaenger } from '../../shared/types';
import { NEWID_PREFIX } from '../../shared/constants';

const MAX_NUMBER_OF_BESTELLPOSITIONEN = 5;

export function CheckboxColumn(cellProps: CellProps<BestellungDetails>): JSX.Element {
    const dispatch = useDispatch();
    const currentSammelbestellung = useSelector(BestelluebersichtSelectors.currentSammelbestellung);

    const { row, value } = cellProps;
    const rowIndex = row.index;
    const bestellung = row.original;

    const isBestellungInCurrentSammelbestellung = useMemo(
        () => currentSammelbestellung.some((bestellnummer) => bestellnummer === bestellung.bestellnummer),
        [bestellung.bestellnummer, currentSammelbestellung]
    );
    const isValid = useMemo(
        () =>
            validateAuftragskorbBestellung({
                loseWareBestellpositionen: bestellung.bestellpositionen.filter(isLoseWare),
                sackwareBestellpositionen: bestellung.bestellpositionen.filter(isSackwareView),
            }),
        [bestellung]
    );
    return (
        <Tippy content={'Bestellung unvollständig'} disabled={isValid}>
            <NonFormikCheckbox
                name={`bestellung-${value}`}
                id={`bestellung-${value}`}
                disabled={!isValid}
                checked={isBestellungInCurrentSammelbestellung}
                onFieldChange={(checked: boolean): void => {
                    if (checked) {
                        dispatch(addToSammelbestellung(bestellung.bestellnummer));
                    } else {
                        dispatch(removeFromSammelbestellung(bestellung.bestellnummer));
                    }
                }}
                dataCy={`checkbox-${rowIndex}`}
            />
        </Tippy>
    );
}

export const checkboxColumnConfig: Column<BestellungDetails> = {
    id: 'checkbox',
    Header: '',
    accessor: 'bestellnummer',
    Cell: CheckboxColumn,
    disableSortBy: true,
};

export const bestellungColumn = (isMobile: boolean): Column<BestellungDetails> => {
    return {
        id: 'bestellnummer',
        Header: 'Bestellung',
        accessor: function accessor(row: BestellungDetails): JSX.Element | undefined {
            return isMobile ? (
                <h3 className={styles._mobileBestellnummer}>Bestellung {row.bestellnummer}</h3>
            ) : (
                <>
                    <div>{row.bestellnummer}</div>
                    {row.bestellpositionen.slice(0, MAX_NUMBER_OF_BESTELLPOSITIONEN).map((position, index: number) => {
                        const werkAndMenge = [position.werk, mengeStringFromBestellpositionView(position)].filter(Boolean).join(', ');
                        const werkAndMengeInParentheses = werkAndMenge ? ` (${werkAndMenge})` : '';
                        return (
                            <Fragment key={index}>
                                <span className={[styles._subInfo, position.storno ? styles['_subInfo--storniert'] : ''].join(' ')}>
                                    {position.nummer.startsWith(NEWID_PREFIX) ? '' : `${position.nummer} `}
                                    {position.bezeichnung}
                                    {werkAndMengeInParentheses}
                                </span>
                                <br />
                            </Fragment>
                        );
                    })}
                    {row.bestellpositionen.length > MAX_NUMBER_OF_BESTELLPOSITIONEN ? <span className={styles._subInfo}>...</span> : <></>}
                </>
            );
        },
    };
};

export const statusColumn = (): Column<BestellungDetails> => {
    return {
        id: 'geschlossen',
        Header: <>Status</>,
        accessor: function accessor(row: BestellungDetails): JSX.Element | undefined {
            const icon = statusToIcon(row.status);
            const tooltip = statusToText(row.status);
            return (
                <Tippy content={tooltip}>
                    <img src={icon} alt={tooltip} />
                </Tippy>
            );
        },
        disableSortBy: true,
    };
};

export const bestelldatumColumn = (isMobile: boolean): Column<BestellungDetails> => {
    return {
        Header: 'Bestelldatum',
        id: 'bestelldatum',
        accessor: (row: BestellungDetails): Date | undefined => {
            return row.bestelldatum ? new Date(row.bestelldatum) : undefined;
        },
        Cell: function cell(cellProps: CellProps<BestellungDetails>): JSX.Element {
            const cell = cellProps.cell;
            if (!cell.value) return <></>;
            const datum = dayjs(cell.value).format('DD.MM.YYYY');
            const uhrzeit = dayjs(cell.value).format('HH:mm') + ' Uhr';
            return isMobile ? (
                <TextItem label="Bestelldatum" text={datum + ' - ' + uhrzeit} compressed />
            ) : (
                <>
                    <div>{datum}</div>
                    <span className={styles._subInfo}>{uhrzeit}</span>
                </>
            );
        },
    };
};

export const warenempfaengerColumn = (isMobile: boolean): Column<BestellungDetails> => {
    return {
        Header: <span>Warenempfänger</span>,
        accessor: 'warenempfaenger',
        Cell: function editCell(cellProps: CellProps<BestellungDetails, Warenempfaenger>): JSX.Element {
            const warenempfaenger = cellProps.cell.value;
            if (isMobile) {
                const warenempfaengerNamen = [warenempfaenger.hauptname, warenempfaenger.nebenname, warenempfaenger.namenszusatz].filter(
                    Boolean
                ) as string[];

                const warenempfaengerText = (
                    <>
                        {warenempfaengerNamen.map((name) => {
                            return (
                                <>
                                    {name}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );

                return <TextItem label="Warenempfänger" text={warenempfaengerText} compressed />;
            } else {
                return (
                    <div>
                        <div>{warenempfaenger.hauptname}</div>
                        {warenempfaenger.nebenname && <div>{warenempfaenger.nebenname}</div>}
                        {warenempfaenger.namenszusatz && <div>{warenempfaenger.namenszusatz}</div>}
                        <div className={styles._subInfo}>
                            {warenempfaenger.postleitzahl} {warenempfaenger.ort}
                        </div>
                    </div>
                );
            }
        },
    };
};

export const rechnungsempfaengerOrLandwarenhandelColumn = (isDebitor: boolean): Column<BestellungDetails> => {
    return {
        Header: isDebitor ? (
            <span>
                Rechnungs-
                <br />
                empfänger
            </span>
        ) : (
            <span>Landwarenhandel</span>
        ),
        accessor: 'rechnungsempfaenger',
        Cell: function editCell(cellProps: CellProps<BestellungDetails, Rechnungsempfaenger>): JSX.Element {
            const rechnungsempfaenger = cellProps.cell.value;
            return rechnungsempfaenger ? (
                <div>
                    <div>{rechnungsempfaenger.hauptname}</div>
                    <div className={styles._subInfo}>
                        {rechnungsempfaenger.postleitzahl} {rechnungsempfaenger.ort}
                    </div>
                </div>
            ) : (
                <></>
            );
        },
    };
};

export const wunschterminColumn = (): Column<BestellungDetails> => {
    return {
        Header: 'Wunschtermin',
        id: 'wunschtermin',
        accessor: (row: BestellungDetails): string[] | undefined => {
            const dateField = row.wunschtermin;

            const formattedDateWithTime: DateInfo = convertDateWithTimeFromIsoStringToDateInfo(dateField);
            const displayedWunschtermin = createWunschterminString(formattedDateWithTime, true, row.bestellprozesstyp);

            return dateField ? [formattedDateWithTime.datum, displayedWunschtermin] : undefined;
        },
        Cell: createWunschterminCell,
    };
};

export const wunschterminWarenempfaengerVonColumn = (): Column<BestellungDetails> => {
    return {
        Header: (
            <>
                Wunschtermin
                <br />
                Warenempfänger
                <br />
                (Von)
            </>
        ),
        id: 'wunschterminWEVon',
        accessor: (row: BestellungDetails): string[] | undefined => retrieveAndConvertWunschtermin(row.wunschterminWEVon),
        Cell: createWunschterminCell,
    };
};

export const wunschterminWarenempfaengerBisColumn = (): Column<BestellungDetails> => {
    return {
        Header: (
            <>
                Wunschtermin
                <br />
                Warenempfänger
                <br />
                (Bis)
            </>
        ),
        id: 'wunschterminWEBis',
        accessor: (row: BestellungDetails): string[] | undefined => retrieveAndConvertWunschtermin(row.wunschterminWEBis),
        Cell: createWunschterminCell,
    };
};

export const bestelldetailsLinkColumn = (route: string): Column<BestellungDetails> => {
    return {
        Header: '',
        id: 'editColumn',
        accessor: 'bestellnummer',
        Cell: function editCell(cellProps: CellProps<BestellungDetails, string>): JSX.Element {
            const value = cellProps.cell.value;

            return (
                <Link to={`${route}/${value}`} data-cy={`bestelldetails-link-${value}`}>
                    <img src={iconDetailsLink} className={styles._editIcon} alt="Bestellung ansehen" title="Bestellung ansehen" />
                </Link>
            );
        },
        disableSortBy: true,
    };
};

const createWunschterminCell = (cellProps: CellProps<BestellungDetails, [string, string] | undefined>): JSX.Element => {
    const value = cellProps.cell.value;
    if (!value) return <></>;
    return (
        <>
            <div>{value[0]}</div>
            <span className={styles._subInfo}>{value[1]}</span>
        </>
    );
};

const retrieveAndConvertWunschtermin = (wunschtermin?: string): [string, string] | undefined => {
    const { datum, tageszeit, uhrzeitIsSet } = convertDateWithTimeFromIsoStringToDateInfo(wunschtermin);
    const displayedWunschtermin = uhrzeitIsSet ? tageszeit : '';

    return wunschtermin ? [datum, displayedWunschtermin] : undefined;
};
