import type { Vorgang } from '../shared/types';
import { RequestState } from '../shared/types/enums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../configureStore';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetRationsservicesAction {}

export type RationsserviceId = {
    rationsserviceId: string;
};

export type DokumentId = {
    dokumentId: string;
};

export type DokumentDownloadAction = DokumentId;

export interface GetRationsservicesSucceededAction {
    rationsservices: Vorgang[];
    totalCount: number;
}

export type RationsservicesState = {
    rationsservices: Vorgang[];
    rationsservicesLoadingState: RequestState;
    dokumentDownloadingState: RequestState;
    totalCount: number;
};

export const INITIAL_RATIONSSERVICES_STATE: RationsservicesState = {
    rationsservices: [],
    rationsservicesLoadingState: RequestState.INITIAL,
    dokumentDownloadingState: RequestState.INITIAL,
    totalCount: 0,
};

export const RationsserviceSelectors = {
    rationsservices: (state: RootState): Vorgang[] => state.rationsservices.rationsservices,
    totalCount: (state: RootState): number => state.rationsservices.totalCount,
    rationsservicesLoading: (state: RootState): boolean => state.rationsservices.rationsservicesLoadingState === RequestState.LOADING,
    rationsservicesFailed: (state: RootState): boolean => state.rationsservices.rationsservicesLoadingState === RequestState.FAILED,
};

const rationsserviceSlice = createSlice({
    name: 'rationsservice',
    initialState: INITIAL_RATIONSSERVICES_STATE,
    reducers: {
        getRationsservices(state, _: PayloadAction<GetRationsservicesAction>) {
            state.rationsservicesLoadingState = RequestState.LOADING;
        },
        getRationsservicesSucceeded(state, action: PayloadAction<GetRationsservicesSucceededAction>) {
            state.rationsservices = action.payload.rationsservices;
            state.totalCount = action.payload.totalCount;
            state.rationsservicesLoadingState = RequestState.SUCCESSFUL;
        },
        getRationsservicesFailed(state, _: PayloadAction) {
            state.rationsservicesLoadingState = RequestState.FAILED;
        },
        dokumentDownload(state, _: PayloadAction<DokumentDownloadAction>) {
            state.dokumentDownloadingState = RequestState.LOADING;
        },
        dokumentDownloadSucceeded(state, _: PayloadAction) {
            state.dokumentDownloadingState = RequestState.SUCCESSFUL;
        },
        dokumentDownloadFailed(state, _: PayloadAction) {
            state.dokumentDownloadingState = RequestState.FAILED;
        },
    },
});

export const {
    getRationsservicesSucceeded,
    getRationsservicesFailed,
    getRationsservices,
    dokumentDownload,
    dokumentDownloadSucceeded,
    dokumentDownloadFailed,
} = rationsserviceSlice.actions;

export const rationsserviceReducer = rationsserviceSlice.reducer;
