import tableStyles from '../../../shared/ui-components/Table/Table.module.scss';
import { TableInstance } from 'react-table';
import TableHeader from '../../../shared/ui-components/Table/ReactTable/TableHeader';
import TableBody from '../../../shared/ui-components/Table/ReactTable/TableBody';

type KontraktUebersichtTableProps<T extends Record<string, unknown>> = TableInstance<T>;

function KontraktUebersichtTable<T extends Record<string, unknown>>(tableInstance: KontraktUebersichtTableProps<T>): JSX.Element {
    const { getTableProps } = tableInstance;
    return (
        <div className={tableStyles._overviewContainer}>
            <table {...getTableProps()} className={tableStyles._table}>
                <TableHeader {...tableInstance} enableSorting={false} />
                <TableBody {...tableInstance} />
            </table>
        </div>
    );
}

export default KontraktUebersichtTable;
