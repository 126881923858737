import { Preislistenkennzeichen } from '../shared/types';

export const GET_PREISLISTENKENNZEICHEN = 'GET_PREISLISTENKENNZEICHEN';
export const GET_PREISLISTENKENNZEICHEN_SUCCEEDED = 'GET_PREISLISTENKENNZEICHEN_SUCCEEDED';
export const GET_PREISLISTENKENNZEICHEN_FAILED = 'GET_PREISLISTENKENNZEICHEN_FAILED';
export const SET_PREISLISTENKENNZEICHEN = 'SET_PREISLISTENKENNZEICHEN';
export const SET_PREISLISTENKENNZEICHEN_SUCCEEDED = 'SET_PREISLISTENKENNZEICHEN_SUCCEEDED';
export const SET_PREISLISTENKENNZEICHEN_FAILED = 'SET_PREISLISTENKENNZEICHEN_FAILED';

export interface GetPreislistenkennzeichenAction {
    type: typeof GET_PREISLISTENKENNZEICHEN;
}

export interface GetPreislistenkennzeichenSucceededAction {
    type: typeof GET_PREISLISTENKENNZEICHEN_SUCCEEDED;
    preislistenkennzeichen: Preislistenkennzeichen[];
}

export interface GetPreislistenkennzeichenFailedAction {
    type: typeof GET_PREISLISTENKENNZEICHEN_FAILED;
}

export interface SetPreislistenkennzeichenAction {
    type: typeof SET_PREISLISTENKENNZEICHEN;
    preislistenkennzeichen: Preislistenkennzeichen[];
}

export interface SetPreislistenkennzeichenSucceededAction {
    type: typeof SET_PREISLISTENKENNZEICHEN_SUCCEEDED;
}

export interface SetPreislistenkennzeichenFailedAction {
    type: typeof SET_PREISLISTENKENNZEICHEN_FAILED;
}

export function getPreislistenkennzeichen(): GetPreislistenkennzeichenAction {
    return {
        type: GET_PREISLISTENKENNZEICHEN,
    };
}

export function getPreislistenkennzeichenSucceeded(
    preislistenkennzeichen: Preislistenkennzeichen[]
): GetPreislistenkennzeichenSucceededAction {
    return {
        type: GET_PREISLISTENKENNZEICHEN_SUCCEEDED,
        preislistenkennzeichen,
    };
}

export function getPreislistenkennzeichenFailed(): GetPreislistenkennzeichenFailedAction {
    return {
        type: GET_PREISLISTENKENNZEICHEN_FAILED,
    };
}

export function setPreislistenkennzeichen(preislistenkennzeichen: Preislistenkennzeichen[]): SetPreislistenkennzeichenAction {
    return {
        type: SET_PREISLISTENKENNZEICHEN,
        preislistenkennzeichen,
    };
}

export function setPreislistenkennzeichenSucceeded(): SetPreislistenkennzeichenSucceededAction {
    return {
        type: SET_PREISLISTENKENNZEICHEN_SUCCEEDED,
    };
}

export function setPreislistenkennzeichenFailed(): SetPreislistenkennzeichenFailedAction {
    return {
        type: SET_PREISLISTENKENNZEICHEN_FAILED,
    };
}

export type PreislistenkennzeichenActions =
    | GetPreislistenkennzeichenAction
    | GetPreislistenkennzeichenFailedAction
    | GetPreislistenkennzeichenSucceededAction
    | SetPreislistenkennzeichenAction
    | SetPreislistenkennzeichenFailedAction
    | SetPreislistenkennzeichenSucceededAction;

export type PreislistenkennzeichenState = {
    preislistenkennzeichen: Preislistenkennzeichen[];
    hasLoadError: boolean;
    hasSubmitError: boolean;
    isLoading: boolean;
    isSubmitting: boolean;
};

export const INITIAL_PREISLISTENKANNZEICHEN_STATE: PreislistenkennzeichenState = {
    preislistenkennzeichen: [],
    hasLoadError: false,
    hasSubmitError: false,
    isLoading: false,
    isSubmitting: false,
};

export function preislistenkennzeichenReducer(
    state = INITIAL_PREISLISTENKANNZEICHEN_STATE,
    action: PreislistenkennzeichenActions
): PreislistenkennzeichenState {
    switch (action.type) {
        case GET_PREISLISTENKENNZEICHEN:
            return {
                ...state,
                isLoading: true,
            };
        case GET_PREISLISTENKENNZEICHEN_SUCCEEDED:
            return {
                ...state,
                preislistenkennzeichen: action.preislistenkennzeichen,
                isLoading: false,
                hasLoadError: false,
            };
        case GET_PREISLISTENKENNZEICHEN_FAILED:
            return {
                ...state,
                isLoading: false,
                hasLoadError: true,
            };
        case SET_PREISLISTENKENNZEICHEN:
            return {
                ...state,
                hasSubmitError: false,
                isSubmitting: true,
            };
        case SET_PREISLISTENKENNZEICHEN_FAILED:
            return {
                ...state,
                hasSubmitError: true,
                isSubmitting: false,
            };
        case SET_PREISLISTENKENNZEICHEN_SUCCEEDED:
            return {
                ...state,
                hasSubmitError: false,
                isSubmitting: false,
            };
        default:
            return state;
    }
}
