import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { useSelector } from 'react-redux';
import { ReactElement } from 'react';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { useFetchRationsservices } from './useFetchRationsservices';
import { RationsserviceSelectors } from '../../store/Rationsservice.store';
import Accordion from '../../shared/ui-components/Accordion/Accordion';
import dayjs from 'dayjs';

const Rationsserviceuebersicht = (): ReactElement => {
    const pageTitle = 'Rationsservice';

    useDocumentTitle(pageTitle);
    const rationsservices = useSelector(RationsserviceSelectors.rationsservices);
    const isLoading = useSelector(RationsserviceSelectors.rationsservicesLoading);
    const loadFailed = useSelector(RationsserviceSelectors.rationsservicesFailed);
    const sortedRationsservices = rationsservices.slice().sort((a, b) => {
        const firstDateA = new Date(a.details[0].datum);
        const firstDateB = new Date(b.details[0].datum);
        return firstDateB.getTime() - firstDateA.getTime();
    });
    const accordionList = sortedRationsservices.map((vorgang) => {
        const firstDate = dayjs(vorgang.details[0].datum);
        const firstDateShort = firstDate.format('DD.MM.');
        const firstDateLong = firstDate.format('DD.MM.YYYY');
        const lastDate = dayjs(vorgang.details[vorgang.details.length - 1].datum);
        const lastDateShort = lastDate.format('DD.MM.');
        const lastDateLong = lastDate.format('DD.MM.YYYY');

        return (
            <Accordion
                key={vorgang.id}
                name={vorgang.vorgangsbezeichnung}
                firstDate={firstDateLong}
                firstDateShort={firstDateShort}
                lastDate={lastDateLong}
                lastDateShort={lastDateShort}
                content={vorgang.details}
            />
        );
    });

    const reloadRationsservices = useFetchRationsservices();

    return (
        <>
            <h2>{pageTitle}</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message="Die Rationsservices konnten nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={reloadRationsservices}
            />
            <NotificationBar isVisible={rationsservices.length == 0 && !isLoading} message="Keine Rationsservices vorhanden." />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    <ul>{accordionList}</ul>
                </>
            </LoadingSpinner>
        </>
    );
};

export default Rationsserviceuebersicht;
