import { PropertyEntryProps } from '../types';
import { FieldArrayRenderProps } from 'formik';
import DeleteArtikelButton from '../../../DeleteArtikelButton/DeleteArtikelButton';

export const DeleteListEntry = ({
    bestellposition,
    arrayHelpers,
    onArtikelDeleted,
    disabled = false,
    showConfirmDelete = false,
}: PropertyEntryProps & {
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
    showConfirmDelete?: boolean;
}): JSX.Element => {
    const onDeletion = () => {
        if (typeof bestellposition.index !== 'undefined') {
            arrayHelpers.remove(bestellposition.index);
            onArtikelDeleted && onArtikelDeleted(bestellposition.nummer);
        }
    };

    return <DeleteArtikelButton disabled={disabled} showConfirmation={showConfirmDelete} deleteRequested={onDeletion} />;
};
