import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import { KontraktDetails } from '../../shared/types';
import { useSelector } from 'react-redux';
import { KontraktuebersichtSelectors } from '../../store/Kontraktuebersicht.store';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import { convertDateWithTimeFromIsoStringToDateInfo } from '../../shared/helper/date-helper';
import dayjs from 'dayjs';
import { amountNumberFormat } from '../../shared/helper/format-helper';

export const GeneralKontraktInformationReadonlyTable = (): JSX.Element => {
    const kontrakt: KontraktDetails = useSelector(KontraktuebersichtSelectors.currentKontrakt);
    const lastModifiedAt = convertDateWithTimeFromIsoStringToDateInfo(kontrakt.lastModifiedAt);
    const dateFormat = (dateAsString: string) => dayjs(dateAsString).format('DD.MM.YYYY');

    return (
        <FormRow>
            <FormColumn isSection>
                <div className={formStyles._sectionHeader}>
                    <h3>Kontraktinformationen</h3>
                </div>
                <table className={tableStyles._datentabelle}>
                    <tbody>
                        <tr>
                            <td>Stand:</td>
                            <td>
                                {lastModifiedAt.datum} {lastModifiedAt.uhrzeit}
                            </td>
                        </tr>
                        <tr>
                            <td>Preislistenart:</td>
                            <td>{kontrakt.preislistenArt}</td>
                        </tr>
                        <tr>
                            <td>Gültigkeitszeitraum:</td>
                            <td>
                                {dateFormat(kontrakt.lieferDatumAb)} bis {dateFormat(kontrakt.lieferDatumBis)}
                            </td>
                        </tr>
                        <tr>
                            <td>Abschlussmenge:</td>
                            <td>
                                {amountNumberFormat(kontrakt.abschlussmenge)}&nbsp;
                                {kontrakt.abschlussmengeEinheit}
                            </td>
                        </tr>
                        <tr>
                            <td>Restmenge (inkl. Vorbestellungen):</td>
                            <td>
                                {amountNumberFormat(kontrakt.restmenge)}&nbsp;
                                {kontrakt.restmengeEinheit}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </FormColumn>
            <FormColumn isSection>
                <div className={formStyles._sectionHeader}>
                    <h3>Kontraktpartner</h3>
                </div>
                <>
                    <div>{kontrakt.kontraktpartner?.hauptname}</div>
                    <div>{kontrakt.kontraktpartner?.strasse}</div>
                    <div className={tableStyles._plzOrt}>
                        {kontrakt.kontraktpartner?.postleitzahl} {kontrakt.kontraktpartner?.ort}
                    </div>
                </>
            </FormColumn>
        </FormRow>
    );
};
