import FormControl from '../../../../ui-components/FormControl/FormControl';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import styles from '../../ArtikelLoseWareTable/LoseWareList.module.scss';
import bezeichnungsStyles from './BezeichnungEntry.module.scss';
import { NEWID_PREFIX } from '../../../../constants';
import { PropertyEntryReadonlyProps } from '../types';
import { isLoseWare } from '../../../../helper/bestellungen-helper';
import { Artikelaustausch } from '../../../Artikelaustausch/Artikelaustausch';

export const BezeichnungListEntry = ({
    bestellposition,
    readonly,
    disabled,
    replaceable = false,
}: PropertyEntryReadonlyProps & { replaceable?: boolean }): JSX.Element => {
    const isNewArtikel = bestellposition.nummer.startsWith(NEWID_PREFIX);

    if (readonly || bestellposition.storno || disabled) {
        return (
            <h4 className={bestellposition.storno ? styles['_artikelHeadline--storniert'] : ''}>
                {isNewArtikel ? '' : bestellposition.nummer + ' '}
                {bestellposition.bezeichnung}
                {isLoseWare(bestellposition) && bestellposition.zulage ? (
                    <>
                        <br />
                        <span>Zulage: </span>
                        <span>{bestellposition.zulageText}</span>
                    </>
                ) : null}
            </h4>
        );
    }

    if (isNewArtikel) {
        return (
            <FormControl
                name={`bestellpositionenView[${bestellposition.index}].bezeichnung`}
                id={`bestellpositionenView[${bestellposition.index}].bezeichnung`}
                label="Beschreibung des neuen Artikels"
                renderChild={(props): JSX.Element => <TextInput {...props} autoFocus storniert={bestellposition.storno} />}
            />
        );
    }

    if (replaceable && isLoseWare(bestellposition)) {
        return (
            <div className={bezeichnungsStyles._searchInput}>
                <Artikelaustausch bestellposition={bestellposition} />
            </div>
        );
    }

    return <h4 className={bestellposition.storno ? styles['_artikelHeadline--storniert'] : ''}>{bestellposition.bezeichnung}</h4>;
};
