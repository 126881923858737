import { TableInstance } from 'react-table';
import tableStyles from '../Table/Table.module.scss';
import TableHeader from '../Table/ReactTable/TableHeader';
import TableCell from '../Table/ReactTable/TableCell';
import { Fragment } from 'react';

type OverviewTableProps<T extends Record<string, unknown>> = {
    tableInstance: TableInstance<T>;
    showSortingIcon?: boolean;
};

export function OverviewTable<T extends Record<string, unknown>>(props: OverviewTableProps<T>): JSX.Element {
    const { tableInstance } = props;
    const { getTableProps, getTableBodyProps, prepareRow, page } = tableInstance;
    return (
        <div className={tableStyles._overviewContainer}>
            <table {...getTableProps()} className={tableStyles._table}>
                <TableHeader {...tableInstance} showSortingIcon enableSorting />
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={tableStyles._tableBodyRow}
                                key={row.index}
                                data-testid={`table-row-${row.index}`}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <TableCell key={index} cell={cell} data-cy={cell.column.id}>
                                            <Fragment key={index}>{cell.render('Cell')}</Fragment>
                                        </TableCell>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default OverviewTable;
