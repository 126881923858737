import { setWarenempfaenger } from './Bestellung.store';
import type { Rechnungsempfaenger } from '../shared/types';
import type { RootState } from '../configureStore';

export const GET_RECHNUNGSEMPFAENGER = 'GET_RECHNUNGSEMPFAENGER';
export const GET_RECHNUNGSEMPFAENGER_SUCCEEDED = 'GET_RECHNUNGSEMPFAENGER_SUCCEEDED';
export const GET_RECHNUNGSEMPFAENGER_FAILED = 'GET_RECHNUNGSEMPFAENGER_FAILED';

export interface GetRechnungsempfaengerAction {
    type: typeof GET_RECHNUNGSEMPFAENGER;
}

export interface GetRechnungsempfaengerSucceededAction {
    type: typeof GET_RECHNUNGSEMPFAENGER_SUCCEEDED;
    rechnungsempfaenger: Rechnungsempfaenger[];
}

export interface GetRechnungsempfaengerFailedAction {
    type: typeof GET_RECHNUNGSEMPFAENGER_FAILED;
}

export function getRechnungsempfaenger(): GetRechnungsempfaengerAction {
    return {
        type: GET_RECHNUNGSEMPFAENGER,
    };
}

export function getRechnungsempfaengerSucceeded(rechnungsempfaenger: Rechnungsempfaenger[]): GetRechnungsempfaengerSucceededAction {
    return {
        type: GET_RECHNUNGSEMPFAENGER_SUCCEEDED,
        rechnungsempfaenger,
    };
}

export function getRechnungsempfaengerFailed(): GetRechnungsempfaengerFailedAction {
    return {
        type: GET_RECHNUNGSEMPFAENGER_FAILED,
    };
}

export type RechnungsempfaengerActions =
    | GetRechnungsempfaengerAction
    | GetRechnungsempfaengerSucceededAction
    | GetRechnungsempfaengerFailedAction;

const selectRechnungsempfaenger = (state: RootState) => state.rechnungsempfaenger;
const selectAllRechnungsempfaenger = (state: RootState): Rechnungsempfaenger[] => selectRechnungsempfaenger(state).rechnungsempfaenger;

const selectRechnungsempfaengerByPartnerNummer = (partnerNummer: string) => {
    return (state: RootState): Rechnungsempfaenger | undefined => {
        return selectAllRechnungsempfaenger(state).find((partner) => partner.partnerNummer === partnerNummer);
    };
};

export const RechnungsempfaengerSelectors = {
    all: selectAllRechnungsempfaenger,
    byPartnerNummer: selectRechnungsempfaengerByPartnerNummer,
};

export type RechnungsempfaengerState = {
    rechnungsempfaenger: Rechnungsempfaenger[];
    isLoading: boolean;
    hasLoadError: boolean;
    loadFinished: boolean;
};

export const INITIAL_RECHNUNGSEMPFAENGER_STATE: RechnungsempfaengerState = {
    rechnungsempfaenger: [],
    hasLoadError: false,
    isLoading: false,
    loadFinished: false,
};

export function rechnungsempfaengerReducer(
    state: RechnungsempfaengerState = INITIAL_RECHNUNGSEMPFAENGER_STATE,
    action: RechnungsempfaengerActions | { type: typeof setWarenempfaenger.type }
): RechnungsempfaengerState {
    switch (action.type) {
        case setWarenempfaenger.type:
            return {
                ...state,
                rechnungsempfaenger: [],
                loadFinished: false,
            };
        case GET_RECHNUNGSEMPFAENGER:
            return {
                ...state,
                isLoading: true,
                hasLoadError: false,
            };
        case GET_RECHNUNGSEMPFAENGER_SUCCEEDED:
            return {
                ...state,
                rechnungsempfaenger: action.rechnungsempfaenger,
                isLoading: false,
                hasLoadError: false,
                loadFinished: true,
            };
        case GET_RECHNUNGSEMPFAENGER_FAILED:
            return {
                ...state,
                isLoading: false,
                hasLoadError: true,
                loadFinished: true,
            };
        default:
            return state;
    }
}
