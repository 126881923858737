import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import { BestelldatenHint } from '../Bestelldaten/BestelldatenHint';
import { WunschterminZeitraum } from '../../shared/content-components/Wunschtermin/WunschterminZeitraum';
import { FormikBestellhinweisInput } from '../../shared/content-components/Form/FormikBestellhinweisInput';
import { FormikLieferhinweisInput } from '../../shared/content-components/Form/FormikLieferhinweisInput';

export const WarenempfaengerBestellansichtForm = (): JSX.Element => {
    return (
        <>
            <FormRow>
                <FormColumn>
                    <div className={formStyles._sectionHeader}>
                        <h3>Zusätzliche Angaben</h3>
                    </div>
                </FormColumn>
            </FormRow>
            <FormRow>
                <BestelldatenHint text="Hinweis an den Bearbeiter bei Ihrem Landwarenhandel.">
                    <FormColumn>
                        <FormikBestellhinweisInput />
                    </FormColumn>
                </BestelldatenHint>
                <BestelldatenHint text="Hinweis an den Fahrer. Wird auf den Lieferschein gedruckt.">
                    <FormColumn>
                        <FormikLieferhinweisInput />
                    </FormColumn>
                </BestelldatenHint>
            </FormRow>
            <FormRow>
                <FormColumn>
                    <WunschterminZeitraum />
                </FormColumn>
            </FormRow>
        </>
    );
};
