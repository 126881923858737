import { routes } from './store/Workflow.store';
import WarenempfaengerAuswaehlen from './views/WarenempfaengerAuswaehlen/WarenempfaengerAuswaehlen';
import WarenempfaengerAnlegen from './views/WarenempfaengerAnlegen/WarenempfaengerAnlegen';
import RechnungsempfaengerAuswaehlen from './views/RechnungsempfaengerAuswaehlen/RechnungsempfaengerAuswaehlen';
import Bestellbestaetigung from './views/Bestellbestaetigung/Bestellbestaetigung';
import ArtikelHeimtierfutter from './views/ArtikelHeimtierfutter/ArtikelHeimtierfutter';
import Abholwerk from './views/Abholwerk/Abholwerk';
import BestelldatenView from './views/Bestelldaten/BestelldatenView';
import Bestellzusammenfassung from './views/Bestellzusammenfassung/Bestellzusammenfassung';
import KfzKennzeichenUndSpeditionenFormular from './views/KfzKennzeichenUndSpeditionen/KfzKennzeichenUndSpeditionenFormular';
import AuswahlFuttertyp from './views/AuswahlFuttertyp/AuswahlFuttertyp';
import ArtikelNutztierfutter from './views/ArtikelNutztierfutter/ArtikelNutztierfutter';
import UnderConstruction from './views/UnderConstruction/UnderConstruction';
import { Auftragskorb, Bestelluebersicht } from './views/BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb';
import Lieferungsansicht from './views/Lieferungsansicht/Lieferungsansicht';
import Lieferungsuebersicht from './views/Lieferungsuebersicht/Lieferungsuebersicht';
import Bestellansicht from './views/Bestellansicht/Bestellansicht';
import Auftragsansicht from './views/Auftragsansicht/Auftragsansicht';
import { Tourenplan } from './views/Tourenplan/Tourenplan';
import NpRechner from './views/NpRechner/NpRechner';
import FragenUndAntworten from './views/FragenUndAntworten/FragenUndAntworten';
import { Kontraktuebersicht } from './views/Kontraktuebersicht/Kontraktuebersicht';
import { Kontraktansicht } from './views/Kontraktansicht/Kontraktansicht';
import Dokumentenuebersicht from './views/Dokumentenuebersicht/Dokumentenuebersicht';
import UserTable from './admin-views/UserTable/UserTable';
import UserDetail from './admin-views/UserDetail/UserDetail';
import PartnerTable from './admin-views/Partner/PartnerTable';
import PartnerDetail from './admin-views/PartnerDetail/PartnerDetail';
import PreislistenkennzeichenTable from './admin-views/Preislistenkennzeichen/PreislistenkennzeichenTable';
import StartseiteInteressent from './views/StartseiteInteressent/StartseiteInteressent';
import { ComponentType } from 'react';
import { RouteProps } from 'react-router-dom';
import DokumentSilageergebnisseuebersicht from 'views/DokumentSilageergebnisseuebersicht/DokumentSilageergebnisse';
import Rationsserviceuebersicht from './views/Rationsserviceuebersicht/Rationsserviceuebersicht';
import Agrarwetter from './views/Agrarwetter/Agrarwetter';
import Marktcharts from './views/Marktcharts/Marktcharts';

export type RouteConfig = Pick<RouteProps, 'path' | 'component'>;

export const createRoute = (path: string, component?: ComponentType): RouteConfig => {
    return { path, component };
};

const appRouten = [createRoute(routes.nachrichten), createRoute(routes.lizenzen)];

const debitorRouten = [
    createRoute(routes.warenempfaengerAuswaehlen, WarenempfaengerAuswaehlen),
    createRoute(routes.warenempfaengerAnlegen, WarenempfaengerAnlegen),
    createRoute(routes.rechnungsempfaengerAuswaehlen, RechnungsempfaengerAuswaehlen),
    createRoute(`${routes.bestelluebermittlung}/:auftragOrBestellung/:update?`, Bestellbestaetigung),
    createRoute(routes.artikelHeimtierfutter, ArtikelHeimtierfutter),
    createRoute(routes.abholwerk, Abholwerk),
    createRoute(routes.bestelldaten, BestelldatenView),
    createRoute(routes.bestellzusammenfassung, Bestellzusammenfassung),
    createRoute(routes.kfzKennzeichenUndSpeditionen, KfzKennzeichenUndSpeditionenFormular),
    createRoute(routes.auswahlFuttertyp, AuswahlFuttertyp),
    createRoute(routes.artikelNutztier, ArtikelNutztierfutter),
    createRoute(routes.underConstruction, UnderConstruction),
    createRoute(routes.bestelluebersicht, Bestelluebersicht),
    createRoute(`${routes.bestellansicht}/:bestellnummer/lieferungen/:vertriebsauftragsnummer`, Lieferungsansicht),
    createRoute(`${routes.bestellansicht}/:bestellnummer/lieferungen/`, Lieferungsuebersicht),
    createRoute(`${routes.bestellansicht}/:bestellnummer`, Bestellansicht),
    createRoute(routes.auftragsuebersicht, Auftragskorb),
    createRoute(`${routes.auftragsansicht}/:bestellnummer`, Auftragsansicht),
    createRoute(routes.tourenplan, Tourenplan),
    createRoute(routes.lieferungsuebersicht, Lieferungsuebersicht),
    createRoute(routes.npRechner, NpRechner),
    createRoute(routes.agrarwetter, Agrarwetter),
    createRoute(routes.marktcharts, Marktcharts),
    createRoute(routes.fragenUndAntworten, FragenUndAntworten),
    createRoute(`${routes.lieferungsansicht}/:vertriebsauftragsnummer`, Lieferungsansicht),
    createRoute(routes.kontraktuebersicht, Kontraktuebersicht),
    createRoute(`${routes.kontraktansicht}/:kontraktNummer`, Kontraktansicht),
    createRoute(routes.dokumentenuebersicht, Dokumentenuebersicht),
    createRoute(routes.futteruntersuchungen, DokumentSilageergebnisseuebersicht),
    createRoute(routes.rationsservice, Rationsserviceuebersicht),
];

const warenempfaengerRouten = [
    createRoute(routes.warenempfaengerAuswaehlen, WarenempfaengerAuswaehlen),
    createRoute(routes.warenempfaengerAnlegen, WarenempfaengerAnlegen),
    createRoute(routes.rechnungsempfaengerAuswaehlen, RechnungsempfaengerAuswaehlen),
    createRoute(`${routes.bestelluebermittlung}/:auftragOrBestellung/:update?`, Bestellbestaetigung),
    createRoute(routes.artikelHeimtierfutter, ArtikelHeimtierfutter),
    createRoute(routes.abholwerk, Abholwerk),
    createRoute(routes.bestelldaten, BestelldatenView),
    createRoute(routes.bestellzusammenfassung, Bestellzusammenfassung),
    createRoute(routes.auswahlFuttertyp, AuswahlFuttertyp),
    createRoute(routes.artikelNutztier, ArtikelNutztierfutter),
    createRoute(routes.underConstruction, UnderConstruction),
    createRoute(routes.bestelluebersicht, Bestelluebersicht),
    createRoute(`${routes.bestellansicht}/:bestellnummer/lieferungen/:vertriebsauftragsnummer`, Lieferungsansicht),
    createRoute(`${routes.bestellansicht}/:bestellnummer/lieferungen/`, Lieferungsuebersicht),
    createRoute(`${routes.bestellansicht}/:bestellnummer`, Bestellansicht),
    createRoute(`${routes.lieferungsuebersicht}`, Lieferungsuebersicht),
    createRoute(`${routes.npRechner}`, NpRechner),
    createRoute(`${routes.fragenUndAntworten}`, FragenUndAntworten),
    createRoute(`${routes.lieferungsansicht}/:vertriebsauftragsnummer`, Lieferungsansicht),
    createRoute(`${routes.futteruntersuchungen}`, DokumentSilageergebnisseuebersicht),
    createRoute(routes.rationsservice, Rationsserviceuebersicht),
    createRoute(routes.dokumentenuebersicht, Dokumentenuebersicht),
    createRoute(routes.agrarwetter, Agrarwetter),
    createRoute(routes.marktcharts, Marktcharts),
];

const adminRouten = [
    createRoute(`${routes.benutzer}/:registrierungsStatus`, UserTable),
    createRoute(`${routes.benutzerdetails}/:userId`, UserDetail),
    createRoute(routes.partner, PartnerTable),
    createRoute(`${routes.partnerdetails}/:partnerNummer`, PartnerDetail),
    createRoute(`${routes.preislistenkennzeichen}`, PreislistenkennzeichenTable),
];

const prospectRouten = [createRoute(routes.startseiteInteressent, StartseiteInteressent)];

export { appRouten, debitorRouten, warenempfaengerRouten, adminRouten, prospectRouten };
