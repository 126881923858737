import { ReactNode } from 'react';
import Config from '../../../shared/config';
const { gitBranch } = Config.getConfig();

type Features = {
    KONTRAKTE: boolean;
    INTERNE_ARTIKEL: boolean;
    DEBITOR_DRAFT_BESTELLUNG: boolean;
    AUFTRAGSEINGANG: boolean;
    REDUX_DEV_TOOLS: boolean;
    PREISLISTENKENNZEICHEN: boolean;
    BESTELLUNGEN_FILTER: boolean;
    PROGRESSIVE_WEB_APP: boolean;
    DELETE_REGISTRATION: boolean;
    NP_RECHNER: boolean;
    TEST: boolean;
};

export const features: Features = {
    KONTRAKTE: true,
    INTERNE_ARTIKEL: true,
    DEBITOR_DRAFT_BESTELLUNG: true,
    AUFTRAGSEINGANG: true,
    REDUX_DEV_TOOLS: false,
    PREISLISTENKENNZEICHEN: true,
    BESTELLUNGEN_FILTER: true,
    PROGRESSIVE_WEB_APP: false,
    DELETE_REGISTRATION: true,
    NP_RECHNER: true,
    TEST: true,
};

switch (gitBranch) {
    case 'develop':
        features.REDUX_DEV_TOOLS = true;
        features.PROGRESSIVE_WEB_APP = true;
        break;
    case 'master':
        features.REDUX_DEV_TOOLS = true;
        features.PROGRESSIVE_WEB_APP = true;
        break;
    case 'release':
        features.PROGRESSIVE_WEB_APP = true;
        break;
    default:
        console.error('Branch name for feature detection not found', gitBranch);
}

export type Props = Readonly<{
    featureName: keyof Features;
    children: ReactNode;
}>;

export const isFeatureOn = (featureName: keyof Features): boolean => {
    return features[featureName] || false;
};

const FeatureToggle = ({ featureName, children }: Props): JSX.Element => {
    return isFeatureOn(featureName) ? <>{children}</> : <></>;
};

export default FeatureToggle;
