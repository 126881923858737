import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../configureStore';

type ArtikelauswahlState = {
    artikelNummern: string[];
};

const selectArtikelauswahl = (state: RootState) => state.artikelauswahl;
export const selectListOfSelectedArtikel = (state: RootState): string[] => selectArtikelauswahl(state).artikelNummern;

const INITIAL_ARTIKELAUSWAHL_STATE: ArtikelauswahlState = {
    artikelNummern: [],
};

type RemoveAndAdd = {
    toRemove: string;
    toAdd: string;
};

const artikelauswahlSlice = createSlice({
    name: 'artikelAuswahl',
    initialState: INITIAL_ARTIKELAUSWAHL_STATE,
    reducers: {
        setArtikelNummern(state, action: PayloadAction<string[]>) {
            state.artikelNummern = action.payload;
        },
        addToListOfSelectedArtikel(state, action: PayloadAction<string>) {
            state.artikelNummern.push(action.payload);
        },
        removeFromListOfSelectedArtikel(state, action: PayloadAction<string>) {
            state.artikelNummern = state.artikelNummern.filter((artikelNummer) => artikelNummer !== action.payload);
        },
        removeAndAddToListOfSelectedArtikel(state, action: PayloadAction<RemoveAndAdd>) {
            state.artikelNummern = state.artikelNummern.filter((artikelNummer) => artikelNummer !== action.payload.toRemove);
            state.artikelNummern.push(action.payload.toAdd);
        },
    },
});

export const { removeAndAddToListOfSelectedArtikel, removeFromListOfSelectedArtikel, setArtikelNummern } = artikelauswahlSlice.actions;
export const artikelauswahlReducer = artikelauswahlSlice.reducer;
