import { ArtikelReadOnlyTable } from './ArtikelReadOnlyTable';
import { WarenempfaengerReadOnlyTable } from './WarenempfaengerReadOnlyTable';

export const KontraktansichtTableReadonly = (): JSX.Element => {
    return (
        <>
            <ArtikelReadOnlyTable />
            <WarenempfaengerReadOnlyTable />
        </>
    );
};
