import { useEffect, useState } from 'react';
import styles from './AddToHomescreenBanner.module.scss';
import IconButton from '../IconButton/IconButton';
import iconClose from '../../../assets/icon-close.svg';
import { localStorageWrapper } from '../../../localStorage';
import { isFeatureOn } from '../FeatureToggle/FeatureToggle';

const DISMISSED_ADD_TO_HOME_KEY = 'dismissedAddToHome';

const AddToHomescreenBanner = (): JSX.Element => {
    const [pwaButtonDisplay, setPwaButtonDisplay] = useState('none');

    const dismissedAddToHome = localStorageWrapper.getItem(DISMISSED_ADD_TO_HOME_KEY) === 'true';

    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>();
    useEffect(() => {
        if (window.isMobile && isFeatureOn('PROGRESSIVE_WEB_APP')) {
            // Evtl. ersetzen durch https://love2dev.com/pwa/add-to-homescreen-library/
            console.log('registering beforeinstallprompt');
            window.addEventListener('beforeinstallprompt', (e: Event) => {
                const beforeInstallEvent = e as BeforeInstallPromptEvent;
                console.log('beforeinstallprompt received');

                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault();
                // Stash the event so it can be triggered later.
                setDeferredPrompt(beforeInstallEvent);
                // Update UI to notify the user they can add to home screen
                setPwaButtonDisplay('flex');
            });
        }
    }, []);
    return (
        <div className={styles._ahsPrompt} style={{ display: dismissedAddToHome ? 'none' : pwaButtonDisplay }}>
            <button
                className={styles._ahsButton}
                onClick={(): void => {
                    setPwaButtonDisplay('none');
                    console.log(deferredPrompt);

                    if (deferredPrompt?.prompt) {
                        // Show the prompt
                        deferredPrompt.prompt();
                        // Wait for the user to respond to the prompt
                        deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
                            if (choiceResult?.outcome === 'accepted') {
                                console.log('User accepted the A2HS prompt');
                            } else {
                                console.log('User dismissed the A2HS prompt');
                            }
                            setDeferredPrompt(null);
                            localStorageWrapper.setItem(DISMISSED_ADD_TO_HOME_KEY, 'true');
                        });
                    }
                }}
            >
                <span>Für den Schnellzugriff über den Startbildschirm </span>
                <span className={styles._emphasis}>klicken Sie hier</span>
            </button>
            <IconButton
                icon={iconClose}
                alt="Dismiss"
                onClick={(): void => {
                    setPwaButtonDisplay('none');
                    localStorageWrapper.setItem(DISMISSED_ADD_TO_HOME_KEY, 'true');
                }}
            />
        </div>
    );
};

export default AddToHomescreenBanner;
