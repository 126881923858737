import { call, CallEffect, put, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getData } from '../shared/fetchApi';
import { GET_ARTIKEL_LOSE_WARE_FAILED, GET_ARTIKEL_LOSE_WARE_SUCCEEDED, GetArtikelLoseWareAction } from '../store/ArtikelLoseWare.store';

export function* getArtikelLoseWareSaga({
    params: { bestellprozesstyp, rechnungsempfaengerPartnerNummer, warenempfaengerPartnerNummer },
}: GetArtikelLoseWareAction): Generator<SelectEffect | PutEffect | CallEffect> {
    try {
        const urlPath = warenempfaengerPartnerNummer
            ? `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/warenempfaenger/${warenempfaengerPartnerNummer}/artikel/lose-ware`
            : `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/artikel/lose-ware`;

        const artikel = yield call(getData, `${urlPath}?process=${bestellprozesstyp.toUpperCase()}`);
        yield put({ type: GET_ARTIKEL_LOSE_WARE_SUCCEEDED, artikel });
    } catch (e) {
        yield put({ type: GET_ARTIKEL_LOSE_WARE_FAILED });
    }
}
