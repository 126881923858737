import { PutEffect, CallEffect, call, put } from 'redux-saga/effects';
import { getData } from '../shared/fetchApi';
import {
    SEARCH_INTERNE_ARTIKEL_FAILED,
    SEARCH_INTERNE_ARTIKEL_SUCCEEDED,
    SearchInterneArtikelAction,
} from '../store/InterneArtikelSuche.store';

export function* searchInterneArtikelSaga(action: SearchInterneArtikelAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const interneArtikel = yield call(getData, `artikel/intern/${action.partnernummer}?q=${action.searchQuery}`);
        yield put({ type: SEARCH_INTERNE_ARTIKEL_SUCCEEDED, interneArtikel });
    } catch (e) {
        yield put({ type: SEARCH_INTERNE_ARTIKEL_FAILED });
    }
}
