import { Dispatch, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import MaximalGewichtValidator from '../MaximalGewichtValidator';
import type { TourenplanForm } from '../../../views/Tourenplan/TourenplanTypes';

export default function useMaximalGewichtValidator(formMethods: UseFormReturn<TourenplanForm>): [boolean, boolean, Dispatch<boolean>] {
    const [hasOverweight, setHasOverweight] = useState(false);
    const [overweightConfirmed, setOverweightConfirmed] = useState(false);
    const { watch } = formMethods;
    const watchFields = watch(['loseWareBestellungen', 'sackwareBestellungen']);
    useEffect(() => {
        const [loseWareBestellungen, sackwareBestellungen] = watchFields;
        const hasOverweight = MaximalGewichtValidator.validate({ loseWareBestellungen, sackwareBestellungen });
        setHasOverweight(hasOverweight);
    }, [watchFields]);

    return [hasOverweight, overweightConfirmed, setOverweightConfirmed];
}
