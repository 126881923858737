import tableStyles from '../../../ui-components/Table/Table.module.scss';
import styles from './LoseWareTable.module.scss';
import { FieldArray, useFormikContext } from 'formik';
import { useRechnungsempfaengerFromBestellung } from '../../../hooks/useRechnungsempfaengerFromBestellung';
import { TableHeaderCell } from '../../../ui-components/Table';
import { isLoseWare } from '../../../helper/bestellungen-helper';
import { LoseWareTableRowExistingBestellung } from './ExistingBestellung/LoseWareTableRowExistingBestellung';
import { LoseWareTableRowNewBestellung } from './NewBestellung/LoseWareTableRowNewBestellung';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../../store/Navigation.store';
import type { ArtikelTableValues } from '../../../types';
import { selectIsBestellungExisting } from '../../../../store/Global.selectors';
import { WunschterminLabels } from '../../Wunschtermin/labels';
import { Breakpoint, useBreakpoint } from '../../../hooks/useBreakpoint';

interface Props {
    onArtikelDeleted?: (artikelNummer: string) => void;
}

export const LoseWareTable = ({ onArtikelDeleted }: Props): JSX.Element => {
    const rechnungsempfaenger = useRechnungsempfaengerFromBestellung();
    const isDebitor = useSelector(selectIsDebitor);
    const isExisting = useSelector(selectIsBestellungExisting);
    const isBigDesktop = useBreakpoint(Breakpoint.BIG_DESKTOP);
    const { values } = useFormikContext<ArtikelTableValues>();

    const TableRow = isExisting ? LoseWareTableRowExistingBestellung : LoseWareTableRowNewBestellung;

    return (
        <div className={[tableStyles._tableContainer, !isBigDesktop ? tableStyles['_tableContainer--scrolling'] : ''].join(' ')}>
            <table className={tableStyles._table}>
                <thead>
                    <tr className={tableStyles._tableHeaderRow}>
                        {isExisting ? <TableHeaderCell> Status </TableHeaderCell> : null}
                        <TableHeaderCell text additionalStyles={[styles._artikelNrBezeichnung]}>
                            Artikel
                        </TableHeaderCell>
                        {rechnungsempfaenger?.zulagenErfassungErlaubt ? <TableHeaderCell button>Zulage?</TableHeaderCell> : null}
                        {isDebitor && isExisting ? <TableHeaderCell> {WunschterminLabels.ABHOL_BZW_LIEFERTERMIN} </TableHeaderCell> : null}
                        {isDebitor && isExisting ? <TableHeaderCell> {WunschterminLabels.UHRZEIT}</TableHeaderCell> : null}
                        {isDebitor ? <TableHeaderCell text>Werk</TableHeaderCell> : null}
                        {isDebitor ? <TableHeaderCell text>Preisfindung</TableHeaderCell> : null}
                        <TableHeaderCell text additionalStyles={[styles._artikelNrBezeichnung]}>
                            Silo
                        </TableHeaderCell>
                        <TableHeaderCell number>Menge (t)</TableHeaderCell>
                        <TableHeaderCell button />
                    </tr>
                </thead>
                <tbody>
                    <FieldArray
                        name="bestellpositionenView"
                        render={(arrayHelpers): JSX.Element => {
                            return (
                                <>
                                    {values.bestellpositionenView.filter(isLoseWare).map((bestellposition) => (
                                        <TableRow
                                            key={`${bestellposition.index}-${bestellposition.nummer}`}
                                            bestellposition={bestellposition}
                                            arrayHelpers={arrayHelpers}
                                            onArtikelDeleted={onArtikelDeleted}
                                        />
                                    ))}
                                </>
                            );
                        }}
                    />
                </tbody>
            </table>
        </div>
    );
};
