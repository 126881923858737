import { ReactNode, MouseEvent, useEffect, useRef } from 'react';
import styles from './NotificationBar.module.scss';
import { collapseAnimationDuration } from '../TextInput/TextInput';
import AnimateHeight from 'react-animate-height';
import IconButton from '../IconButton/IconButton';
import iconRefresh from '../../../assets/icon-refresh.svg';
import { Severity } from '../../types/enums';

export type Props = Readonly<{
    message: string;
    isVisible: boolean;
    severity: Severity;
    actionText?: string;
    disableScrolling?: boolean;
    actionCallback?: (mouseEvent: MouseEvent<HTMLElement>) => void;
    children?: ReactNode;
    testId?: string;
    dataCy?: string;
}>;

const NotificationBar = (props: Props): JSX.Element => {
    const baseStyles =
        props.severity === Severity.ERROR
            ? [styles._notificationBar, styles['_notificationBar--error']]
            : [styles._notificationBar, styles['_notificationBar--success']];
    const messageRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (props.isVisible) {
            const currentRef = messageRef && messageRef.current;
            if (currentRef && !props.disableScrolling) {
                const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
                const headerHeight = 63;
                const scrollTopTarget = (messageRef.current?.getBoundingClientRect().top || 100) + window.scrollY - headerHeight;

                if (isSmoothScrollSupported) {
                    // Native smooth scrolling
                    window.scrollTo({
                        top: scrollTopTarget,
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Old way scrolling without effects
                    window.scroll(0, scrollTopTarget);
                }
            }
        }
    }, [props.isVisible, messageRef, props.disableScrolling]);
    return (
        <AnimateHeight duration={collapseAnimationDuration} height={props.isVisible ? 'auto' : '0%'}>
            <div ref={messageRef} data-testid={props.testId} data-cy={props.dataCy} className={baseStyles.join(' ')}>
                {props.isVisible && props.message}
                {props.children}
                {props.actionText && <IconButton alt={props.actionText} icon={iconRefresh} onClick={props.actionCallback} />}
            </div>
        </AnimateHeight>
    );
};

NotificationBar.defaultProps = {
    severity: Severity.ERROR,
};

export default NotificationBar;
