import { useSelector } from 'react-redux';
import { BestelluebersichtSelectors } from '../../../store/Bestelluebersicht.store';
import { selectIsWarenempfaenger } from '../../../store/Navigation.store';
import styles from './AenderbarBis.module.scss';
import iconEdit from '../../../assets/icon-pencil.svg';
import { getAenderbarBisText } from './aenderbarBis';

type AenderbarBisListEntryProps = {
    aenderbarBisExpired: boolean;
    nummer: string;
    positionsnummer?: number;
    storno?: boolean;
};

export const AenderbarBisListEntry = ({
    nummer,
    positionsnummer,
    aenderbarBisExpired,
    storno,
}: AenderbarBisListEntryProps): JSX.Element | null => {
    const isWarenempfaenger = useSelector(selectIsWarenempfaenger);
    const aenderbarBis = useSelector(BestelluebersichtSelectors.getAenderbarBisDateForPosition(nummer, positionsnummer));

    if (storno || isWarenempfaenger || !aenderbarBis) {
        return null;
    }

    const text = getAenderbarBisText(aenderbarBisExpired, aenderbarBis);

    return (
        <div className={styles._aenderbarBis}>
            <img src={iconEdit} alt={text} className={styles._aenderbarBis__icon} />
            &nbsp;<span>{text}</span>
        </div>
    );
};
