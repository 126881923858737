import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBestellung, resetCurrentBestellung } from '../../store/Bestelluebersicht.store';

export const useFetchBestellung = (bestellnummer: string): (() => void) => {
    const dispatch = useDispatch();

    const fetchBestellung = useCallback(() => {
        dispatch(getBestellung({ bestellnummer }));
    }, [bestellnummer, dispatch]);

    useEffect(() => {
        fetchBestellung();
        return () => {
            dispatch(resetCurrentBestellung());
        };
    }, [bestellnummer, dispatch, fetchBestellung]);

    return fetchBestellung;
};
