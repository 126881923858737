import { PropertyEntryProps } from '../types';
import { TableCell } from '../../../../ui-components/Table';
import { StornoListEntry } from './StornoListEntry';

export const StornoCell = (props: PropertyEntryProps): JSX.Element => {
    return (
        <TableCell button>
            <StornoListEntry {...props} />
        </TableCell>
    );
};
