import { ReactElement } from 'react';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';
import { FieldArray, FormikProps } from 'formik';
import styles from './WarenempfaengerAnlegen.module.scss';
import TextInput from '../../shared/ui-components/TextInput/TextInput';
import Tippy from '@tippyjs/react';
import IconButton from '../../shared/ui-components/IconButton/IconButton';
import iconFavorite from '../../assets/icon-favorite.svg';
import iconNonFavorite from '../../assets/icon-non-favorite.svg';
import iconTrashcan from '../../assets/icon-trashcan.svg';
import { WarenempfaengerWithFormFields } from '../../shared/types';
import { Kommunikationsart } from '../../shared/types/enums';

type KommunikationsdatenEntriesProps = {
    formikProps: FormikProps<WarenempfaengerWithFormFields>;
    kommunikationsart: Kommunikationsart;
    fieldForAdding: string;
    placeholderText: string;
    inputType?: string;
};

export const KommunikationsdatenEntries = ({
    formikProps,
    kommunikationsart,
    fieldForAdding,
    placeholderText,
    inputType = 'text',
}: KommunikationsdatenEntriesProps): ReactElement => {
    const kommunikationsdaten = formikProps.values.kommunikationsdaten;
    const isMobile = useBreakpoint(Breakpoint.MOBILE);

    return (
        <FieldArray
            name="kommunikationsdaten"
            render={(arrayHelpers): JSX.Element => (
                <>
                    {kommunikationsdaten.map((datenElement, index) => {
                        return kommunikationsdaten[index].kommunikationsart === kommunikationsart ? (
                            <div key={`kommunikationsdaten[${index}]`} className={styles._kommunikationsdatenEntry}>
                                <div className={styles._kommunikationsdaten}>
                                    <TextInput
                                        id={`kommunikationsdaten[${index}].kommunikationsdaten`}
                                        name={`kommunikationsdaten[${index}].kommunikationsdaten`}
                                        type={inputType}
                                        autoComplete={'non'}
                                        maxLength={80}
                                    />
                                </div>
                                <div className={styles._iconContainer}>
                                    <Tippy content="Kommunikationskanal als bevorzugt markieren" disabled={isMobile}>
                                        <IconButton
                                            icon={datenElement.bevorzugt ? iconFavorite : iconNonFavorite}
                                            alt={datenElement.bevorzugt ? 'Favorit' : 'Kein Favorit'}
                                            data-testid={kommunikationsart + '-bevorzugt-button'}
                                            onClick={(event): void => {
                                                event.preventDefault();
                                                const kommunikationsart = kommunikationsdaten[index].kommunikationsart;
                                                kommunikationsdaten
                                                    .filter((kd) => kd.kommunikationsart === kommunikationsart)
                                                    .forEach((kd) => {
                                                        kd.bevorzugt = false;
                                                    });
                                                kommunikationsdaten[index].bevorzugt = !kommunikationsdaten[index].bevorzugt;
                                                formikProps.setFieldValue('kommunikationsdaten', kommunikationsdaten);
                                            }}
                                        />
                                    </Tippy>
                                </div>
                                <div className={styles._iconContainer}>
                                    <IconButton
                                        icon={iconTrashcan}
                                        alt="Löschen"
                                        onClick={(event): void => {
                                            event.preventDefault();
                                            arrayHelpers.remove(index);
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        );
                    })}
                    <div className={styles._kommunikationsdatenTemplate}>
                        <div className={styles._kommunikationsdaten}>
                            <div className={styles._kommunikationsdaten}>
                                <TextInput
                                    id={fieldForAdding}
                                    name={fieldForAdding}
                                    type={inputType}
                                    placeholder={placeholderText}
                                    maxLength={80}
                                    onBlurCallback={async (): Promise<void> => {
                                        const validation = await formikProps.validateForm();
                                        if (
                                            !validation[fieldForAdding] &&
                                            formikProps.values[fieldForAdding].trim() !== '' &&
                                            !kommunikationsdaten.some(
                                                (entry) => entry.kommunikationsdaten === formikProps.values[fieldForAdding]
                                            )
                                        ) {
                                            arrayHelpers.push({
                                                kommunikationsart,
                                                bevorzugt: false,
                                                kommunikationsdaten: formikProps.values[fieldForAdding],
                                            });
                                            formikProps.setFieldValue(fieldForAdding, '');
                                            formikProps.setFieldTouched(fieldForAdding, false);
                                        } else {
                                            formikProps.setFieldTouched(fieldForAdding, true);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles._kommunikationsdatenError}>
                        {formikProps.errors[fieldForAdding] && formikProps.touched[fieldForAdding] && formikProps.errors[fieldForAdding]}
                    </div>
                </>
            )}
        />
    );
};
