export function throttle(callback: () => void, limit: number): () => void {
    let wait = false;
    let onePendingCallback = false;

    return (): void => {
        if (!wait) {
            callback();
            wait = true;
            setTimeout(function () {
                wait = false;
                if (onePendingCallback) {
                    callback();
                    onePendingCallback = false;
                }
            }, limit);
        } else {
            onePendingCallback = true;
        }
    };
}
