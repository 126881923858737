import { PropertyEntryReadonlyProps } from '../types';
import { BestellpositionArtikelSackwareView } from '../../../../types';
import { TableCell } from '../../../../ui-components/Table';
import Formulas from '../../../../formulas';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { TextInputRule } from '../../../../types/enums';

export const MengeSWTableCell = ({
    bestellposition,
    readonly,
    formikRowPath,
}: PropertyEntryReadonlyProps<BestellpositionArtikelSackwareView>): JSX.Element => {
    const { mengePal, mengeVe, nummer, faktorBasiseinheitVe, faktorBasiseinheitPal, storno } = bestellposition;
    const overallVECount = Formulas.overallVerkaufseinheitCount(mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe);

    if (readonly) {
        return (
            <>
                <TableCell number storniert={storno}>
                    <span data-testid={`${nummer}-ve`} data-cy={'sw-menge-ve'}>
                        {mengeVe === 0 ? '' : mengeVe}
                    </span>
                </TableCell>
                <TableCell number storniert={storno}>
                    {faktorBasiseinheitVe}
                </TableCell>
                <TableCell number storniert={storno}>
                    <span data-cy={'sw-menge-pal'}>{mengePal === 0 ? '' : mengePal}</span>
                </TableCell>
                <TableCell number storniert={storno}>
                    {faktorBasiseinheitPal}
                </TableCell>
                <TableCell data-testid={`${nummer}-overallVe`} number storniert={storno}>
                    <span data-cy={`sw-menge-overallVe`}>{overallVECount}</span>
                </TableCell>
            </>
        );
    }

    return (
        <>
            <TableCell number storniert={storno} input>
                <TextInput
                    name={formikRowPath ? `${formikRowPath}.mengeVe` : `bestellpositionenView[${bestellposition.index}].mengeVe`}
                    id={formikRowPath ? `${formikRowPath}.mengeVe` : `bestellpositionenView[${bestellposition.index}].mengeVe`}
                    type="number"
                    textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                    data-testid={`${nummer}-ve`}
                    data-cy={'sw-menge-ve'}
                    disabled={storno}
                    inTableCell
                />
            </TableCell>
            <TableCell number storniert={storno}>
                {faktorBasiseinheitVe}
            </TableCell>
            <TableCell number input storniert={storno}>
                <TextInput
                    name={formikRowPath ? `${formikRowPath}.mengePal` : `bestellpositionenView[${bestellposition.index}].mengePal`}
                    id={formikRowPath ? `${formikRowPath}.mengePal` : `bestellpositionenView[${bestellposition.index}].mengePal`}
                    type="number"
                    textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                    data-testid={`${nummer}-pal`}
                    data-cy={'sw-menge-pal'}
                    disabled={storno}
                    inTableCell
                />
            </TableCell>
            <TableCell number storniert={storno}>
                {faktorBasiseinheitPal}
            </TableCell>
            <TableCell number storniert={storno} data-testid={`${nummer}-overallVe`}>
                <span data-cy={`sw-menge-overallVe`}>{overallVECount}</span>
            </TableCell>
        </>
    );
};
