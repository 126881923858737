import { getData, postData } from '../shared/fetchApi';
import {
    addKfzKennzeichenFailed,
    addKfzKennzeichenSucceeded,
    deleteKfzKennzeichenFailed,
    deleteKfzKennzeichenSucceeded,
    getKfzKennzeichenFailed,
    getKfzKennzeichenSucceeded,
    updateKfzKennzeichenFailed,
    updateKfzKennzeichenSucceeded,
} from '../store/KfzKennzeichen.store';
import type { KfzKennzeichen } from '../shared/types';
import { setKfzKennzeichen } from '../store/Bestellung.store';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

export function* putKfzKennzeichen({ payload }: PayloadAction<KfzKennzeichen>): Generator<PutEffect | CallEffect, void, KfzKennzeichen> {
    try {
        yield put(setKfzKennzeichen(payload.id));
        const copy: Pick<KfzKennzeichen, 'kennzeichen'> = { kennzeichen: payload.kennzeichen };
        const newKfzKennzeichen = yield call(postData, 'kfzkennzeichen', copy, 'POST');
        yield put(addKfzKennzeichenSucceeded(newKfzKennzeichen));
        yield put(setKfzKennzeichen(newKfzKennzeichen.id));
    } catch (error) {
        if (error instanceof Error) {
            yield put(addKfzKennzeichenFailed(error.message));
        }
        yield put(setKfzKennzeichen());
    }
}

export function* getKfzKennzeichenSaga(): Generator<PutEffect | CallEffect, void, KfzKennzeichen[]> {
    try {
        const kfzKennzeichen = yield call(getData, 'kfzkennzeichen');
        yield put(getKfzKennzeichenSucceeded(kfzKennzeichen));
    } catch (error: unknown) {
        if (error instanceof Error) {
            yield put(getKfzKennzeichenFailed(error.message));
        }
    }
}

export function* updateKfzKennzeichenSaga({
    payload: kennzeichen,
}: PayloadAction<KfzKennzeichen>): Generator<PutEffect | CallEffect, void> {
    try {
        yield call(postData, `kfzkennzeichen/${kennzeichen.id}`, kennzeichen, 'PUT');
        yield put(updateKfzKennzeichenSucceeded(kennzeichen));
    } catch {
        yield put(updateKfzKennzeichenFailed(kennzeichen));
    }
}

export function* deleteKfzKennzeichenSaga({
    payload: kennzeichen,
}: PayloadAction<KfzKennzeichen>): Generator<PutEffect | CallEffect, void> {
    try {
        yield call(postData, `kfzkennzeichen/${kennzeichen.id}`, kennzeichen, 'DELETE');
        yield put(deleteKfzKennzeichenSucceeded(kennzeichen));
    } catch {
        yield put(deleteKfzKennzeichenFailed(kennzeichen));
    }
}
