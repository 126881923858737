import { LieferungPosition } from '../../types';
import styles from './LieferungPositionenList.module.scss';
import TextItem from '../../ui-components/TextItem/TextItem';
import { translateEinheit } from '../../helper/lieferungenHelper';

export type Props = Readonly<{
    positionen: LieferungPosition[];
    isDebitor: boolean;
}>;

const LieferungPositionenList = ({ positionen, isDebitor }: Props): JSX.Element => {
    return (
        <div>
            {positionen.map((position, index: number) => {
                return (
                    <div className={styles._positionContainer} key={index}>
                        <h4>{position.bezeichnung}</h4>
                        <TextItem
                            label="Menge"
                            text={`${position.lieferungMenge || position.auftragMenge} ${translateEinheit(
                                position.lieferungEinheit || position.auftragEinheit
                            )}`}
                            compressed
                        />
                        {isDebitor ? <TextItem label="Kontrakt" text={position.kontrakt} compressed /> : null}
                    </div>
                );
            })}
        </div>
    );
};

export default LieferungPositionenList;
