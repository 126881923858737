import { ChangeEventHandler, useMemo } from 'react';
import styles from './Checkbox.module.scss';
import { useField } from 'formik';

export type Props = Readonly<{
    label?: string | JSX.Element;
    name: string;
    id: string;
    disabled?: boolean;
    placement?: 'left' | 'center' | 'right';
    onChangeCallback?: ChangeEventHandler<HTMLInputElement>;
}>;

const FormikCheckbox = ({ label, id, onChangeCallback, placement = 'center', ...props }: Props): JSX.Element => {
    const [{ onChange: fieldOnChange, ...field }, meta] = useField(props);

    const containerStyles = useMemo((): string => {
        const containerStyles = [styles._checkbox, styles[`_checkbox--${placement}`]];
        return containerStyles.join(' ');
    }, [placement]);

    const inputStyles = useMemo((): string => {
        const inputStyles = [styles._checkboxInput];
        return inputStyles.join(' ');
    }, []);

    const labelStyles = useMemo((): string => {
        const labelStyles = [styles._checkboxLabel];
        return labelStyles.join(' ');
    }, []);

    return (
        <div className={containerStyles}>
            <input
                type="checkbox"
                id={id}
                {...field}
                {...props}
                onChange={(event): void => {
                    fieldOnChange(event);
                    onChangeCallback && onChangeCallback(event);
                }}
                checked={field.value || false}
                className={inputStyles}
            />
            {label && (
                <label htmlFor={id} className={labelStyles}>
                    {label}
                </label>
            )}

            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </div>
    );
};

export default FormikCheckbox;
