import { useEffect } from 'react';
import { Action, Location } from 'history';
import { useHistory } from 'react-router-dom';

export default function useConfirmExitPrompt(promptText = 'Änderungen verwerfen?'): void {
    const history = useHistory();
    const preventReload = (unloadEvent: BeforeUnloadEvent) => {
        const event = unloadEvent || window.event;
        if (event) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('beforeunload', preventReload);
        const unblockNavigation = history.block((_: Location, __: Action) => {
            if (!window.confirm(promptText)) {
                // Prevent navigation
                return false;
            }
            // Allow navigation
            return;
        });

        return () => {
            window.removeEventListener('beforeunload', preventReload);
            unblockNavigation();
        };
    }, [history, promptText]);
}
