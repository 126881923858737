import { useEffect, useState } from 'react';
import './PartnerDetail.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPartnerDetails, updatePartner } from '../../store/Partneruebersicht.store';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import Button from '../../shared/ui-components/Button/Button';
import { routes } from '../../store/Workflow.store';
import type { PartnerMitAdminDaten, PartnerParam } from '../../shared/types';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import { getMarken } from '../../store/Marke.store';
import { MarkenZuordnung } from './Markenzuordnung';
import { InterneArtikelzuordnung } from './InterneArtikelzuordnung';
import { clearInterneArtikel, searchInterneArtikel } from '../../store/InterneArtikelSuche.store';
import FeatureToggle from '../../shared/ui-components/FeatureToggle/FeatureToggle';
import type { RootState } from '../../configureStore';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';

type PartnerDatenComponentProps = {
    partner: PartnerMitAdminDaten;
    setChangedPartner: (changedPartner: PartnerMitAdminDaten) => void;
};

const Partnerdaten = ({ partner, setChangedPartner }: PartnerDatenComponentProps): JSX.Element => {
    return (
        <>
            <div className={formStyles._formContainer}>
                <div className={formStyles._formColumn}>
                    <TextItem label="Hauptname" text={partner.hauptname} />
                </div>
                <div className={formStyles._formColumn}>
                    <TextItem label="Nebenname" text={partner.nebenname} />
                </div>
            </div>
            <div className={formStyles._formContainer}>
                <div className={formStyles._formColumn}>
                    <TextItem label="Straße" text={partner.strasse} />
                </div>
                <div className={formStyles._formColumn}>
                    <TextItem label="PLZ / Ort" text={`${partner.postleitzahl} ${partner.ort}`} />
                </div>
            </div>
            <div className={formStyles._formContainer}>
                <div className={formStyles._formColumn}>
                    <NonFormikCheckbox
                        checked={partner.eigenesErp}
                        label="eigenes ERP"
                        name="eigenes_erp"
                        id="eigenes_erp"
                        placement="left"
                        onFieldChange={(checked: boolean): void => {
                            const newPartner = {
                                ...partner,
                                eigenesErp: checked,
                            };
                            setChangedPartner(newPartner);
                        }}
                    />
                    <NonFormikCheckbox
                        checked={partner.zulagenErfassungErlaubt}
                        label="Zulagenerfassung erlaubt"
                        name="zulagenerfassung"
                        id="zulagenerfassung"
                        placement="left"
                        onFieldChange={(checked: boolean): void => {
                            const newPartner = {
                                ...partner,
                                zulagenErfassungErlaubt: checked,
                            };
                            setChangedPartner(newPartner);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const PartnerDetail = (): JSX.Element => {
    const { partnerNummer } = useParams<PartnerParam>();
    const partner = useSelector((state: RootState) => state.partneruebersicht.selectedPartner);
    const marken = useSelector((state: RootState) => state.marken.marken);
    const interneArtikel = useSelector((state: RootState) => state.interneArtikelSuche.interneArtikel);
    const isLoading = useSelector((state: RootState) => state.partneruebersicht.isLoadingDetails);
    const isSubmitting = useSelector((state: RootState) => state.partneruebersicht.isSubmitting);
    const isLoadingArtikel = useSelector((state: RootState) => state.interneArtikelSuche.isLoading);
    const hasSubmitError = useSelector((state: RootState) => state.partneruebersicht.hasSubmitError);
    const dispatch = useDispatch();
    const history = useHistory();
    const [changedPartner, setChangedPartner] = useState(partner);

    useDocumentTitle('Partnerdetails');

    useEffect(() => {
        dispatch(getPartnerDetails(partnerNummer));
    }, [dispatch, partnerNummer]);

    useEffect(() => {
        dispatch(getMarken());
        dispatch(clearInterneArtikel());
    }, [dispatch]);

    useEffect(() => {
        setChangedPartner(partner);
    }, [partner]);

    return (
        <>
            <h2>Partner {partner.partnerNummer}</h2>
            <NotificationBar
                testId="server-validation-message-bar"
                message="Die Partnerdaten konnten nicht gespeichert werden. Bitte versuchen Sie es erneut."
                isVisible={hasSubmitError}
                actionText={'Beutzerdaten erneut speichern.'}
                actionCallback={(): void => {
                    dispatch(updatePartner(changedPartner, history));
                }}
            />
            <Partnerdaten partner={changedPartner} setChangedPartner={setChangedPartner} />
            <br />
            <h3>Markenzuordnung Heimtierfutter</h3>
            <MarkenZuordnung
                ausgewaehlteMarken={changedPartner.heimtierfutterMarken}
                alleMarken={marken}
                idPrefix="htf"
                onChangedMarken={(newMarken): void => {
                    const newPartner = {
                        ...changedPartner,
                        heimtierfutterMarken: newMarken,
                    };
                    setChangedPartner(newPartner);
                }}
            />
            <br />
            <h3>Markenzuordnung Nutztierfutter</h3>
            <MarkenZuordnung
                ausgewaehlteMarken={changedPartner.nutztierfutterMarken}
                alleMarken={marken}
                idPrefix="ntf"
                onChangedMarken={(newMarken): void => {
                    const newPartner = {
                        ...changedPartner,
                        nutztierfutterMarken: newMarken,
                    };
                    setChangedPartner(newPartner);
                }}
            />
            <br />
            <FeatureToggle featureName="INTERNE_ARTIKEL">
                <h3>Zuordnung interner Artikel</h3>
                <InterneArtikelzuordnung
                    ausgewaehlteArtikel={partner.interneArtikel}
                    alleArtikel={interneArtikel}
                    onChangedArtikel={(newArtikel): void => {
                        const newPartner = {
                            ...changedPartner,
                            interneArtikel: newArtikel,
                        };
                        setChangedPartner(newPartner);
                    }}
                    onSearch={(searchTerm): void => {
                        dispatch(searchInterneArtikel(searchTerm, partner.partnerNummer));
                    }}
                    idPrefix="art"
                    isLoading={isLoadingArtikel}
                />
            </FeatureToggle>
            <ButtonGroup>
                <Button type="button" onClick={(): void => history.push(routes.partner)} isSecondary={true}>
                    Abbrechen
                </Button>
                <Button
                    type="button"
                    onClick={(): void => {
                        dispatch(updatePartner(changedPartner, history));
                    }}
                    disabled={isLoading || isSubmitting}
                >
                    {isSubmitting ? 'Wird gespeichert...' : 'Speichern'}
                </Button>
            </ButtonGroup>
        </>
    );
};

export default PartnerDetail;
