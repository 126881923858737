import { ZulageListEntryCheckbox } from './ZulageListEntryCheckbox';
import { ZulageCheckboxTableCell } from './ZulageCheckboxTableCell';
import { ZulageInputTableRow } from './ZulageInputTableRow';
import { ZulageListeEntryText } from './ZulageListeEntryText';

const ZulagenEntry = {
    ListCheckbox: ZulageListEntryCheckbox,
    ListText: ZulageListeEntryText,
    Cell: ZulageCheckboxTableCell,
    Row: ZulageInputTableRow,
};

export { ZulagenEntry };
