import { ReactElement, useEffect, useMemo, useState } from 'react';
import styles from './Partner.module.scss';
import { OverviewList } from '../../shared/ui-components/OverviewList/OverviewList';
import { OverviewTable } from '../../shared/ui-components/OverviewTable/OverviewTable';
import { OverviewPagination } from '../../shared/ui-components/OverviewPagination/OverviewPagination';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import {
    Accessor,
    Column,
    SortingRule,
    TableInstance,
    TableOptions,
    TableState,
    useAsyncDebounce,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';
import { Dispatch } from 'redux';
import { useMediaQuery } from 'react-responsive';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { getPartnerList, PartneruebersichtSelectors } from '../../store/Partneruebersicht.store';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import { EditCellProps } from '../UserTable/UserTable';
import { Link, useHistory } from 'react-router-dom';
import { routes } from '../../store/Workflow.store';
import iconDetailsLink from '../../assets/icon-details-link.svg';
import { getPartner, PartnerSucheSelectors } from '../../store/Partner.store';
import { localStorageWrapper } from '../../localStorage';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import type { Partner } from '../../shared/types';
import { Order } from '../../shared/types/enums';

const PAGE_SIZE_KEY = 'Partneruebersicht.pageSize';
const PAGE_INDEX_KEY = 'Partneruebersicht.page';
const PAGE_SORTBY_KEY = 'Partneruebersicht.sortBy';
const MOBILE_COLUMNS = ['partnerNummer', 'hauptname', 'postleitzahl'];

const createPartnerNummerColumn = (isMobile: boolean): Column<Partner> => {
    return {
        id: 'partnerNummer',
        Header: 'Partnernummer',
        accessor: function accessor(row: Partner): JSX.Element | undefined {
            return isMobile ? (
                <h3 className={styles._partnernummer}>Partner {row.partnerNummer}</h3>
            ) : (
                <>
                    <div>{row.partnerNummer}</div>
                </>
            );
        },
    };
};
const createHauptnameColumn = (isMobile: boolean): Column<Partner> => {
    const accessor: Accessor<Partner> = function accessor(row: Partner): JSX.Element | undefined {
        return isMobile ? (
            <TextItem label="Hauptname" text={row.hauptname} />
        ) : (
            <>
                <div>{row.hauptname}</div>
            </>
        );
    };

    return {
        id: 'hauptname',
        Header: 'Hauptname',
        accessor,
    };
};
const createPostleitzahlColumn = (isMobile: boolean): Column<Partner> => {
    return {
        id: 'postleitzahl',
        Header: 'PLZ / Ort',
        accessor: function accessor(row: Partner): JSX.Element | undefined {
            return isMobile ? (
                <TextItem label="Ort" text={`${row.postleitzahl} ${row.ort}`} />
            ) : (
                <>
                    <div>
                        {row.postleitzahl} {row.ort}
                    </div>
                </>
            );
        },
    };
};
const createPartnerDetailsLinkColumn = (): Column<Partner> => {
    return {
        Header: '',
        id: 'editColumn',
        accessor: 'partnerNummer',
        Cell: function editCell(editCellProps: EditCellProps): JSX.Element {
            const cell = editCellProps.cell;
            return (
                <Link to={`${routes.partnerdetails}/${cell.value}`}>
                    <img src={iconDetailsLink} className={styles._editIcon} alt="Partnerdetails ansehen" title="Partnerdetails ansehen" />
                </Link>
            );
        },
        disableSortBy: true,
    };
};
const createNebennameColumn = (): Column<Partner> => {
    return {
        id: 'nebenname',
        Header: 'Nebenname',
        accessor: 'nebenname',
    };
};
const createStrasseColumn = (): Column<Partner> => {
    return {
        id: 'strasse',
        Header: 'Straße',
        accessor: 'strasse',
    };
};

const getPartneruebersichtColumns = (isMobile: boolean): Column<Partner>[] => [
    createPartnerNummerColumn(isMobile),
    createHauptnameColumn(isMobile),
    createNebennameColumn(),
    createStrasseColumn(),
    createPostleitzahlColumn(isMobile),
    createPartnerDetailsLinkColumn(),
];

function loadPartnerForPage(
    pageIndex: number,
    pageSize: number,
    sortBy: Array<SortingRule<Record<string, unknown>>>,
    dispatch: Dispatch
): void {
    const sortColumn = sortBy[0].id;
    const sortOrder = sortBy[0].desc ? Order.DESC : Order.ASC;

    dispatch(getPartnerList(pageIndex + 1, pageSize, sortColumn, sortOrder));
}

const PartnerTable = (): ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();

    const partnersForPage = useSelector(PartneruebersichtSelectors.partner);
    const searchResult = useSelector(PartnerSucheSelectors.partner);
    const isPageLoading = useSelector(PartneruebersichtSelectors.isLoading);
    const isSearchLoading = useSelector(PartnerSucheSelectors.isLoading);
    const isLoading = isPageLoading || isSearchLoading;
    const loadFailedForPage = useSelector(PartneruebersichtSelectors.loadFailed);
    const loadFailedForSearch = useSelector(PartneruebersichtSelectors.loadFailed);
    const totalCount = useSelector(PartneruebersichtSelectors.totalCount);
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });

    const [pageSizeState, setPageSizeState] = useState<number>(parseInt(localStorageWrapper.getItem(PAGE_SIZE_KEY) || '10'));
    const [searchTerm, setSearchTerm] = useState('');
    const [internalSearchValue, setInternalSearchValue] = useState('');
    const showSearchResult = searchTerm !== '';

    const onChangeSearchTerm = useAsyncDebounce((newValue) => {
        if (newValue.length > 2) {
            setSearchTerm(newValue);
        } else {
            setSearchTerm('');
        }
    }, 500);

    useEffect(() => {
        localStorageWrapper.setItem(PAGE_SIZE_KEY, pageSizeState.toString());
    }, [pageSizeState]);

    useDocumentTitle('Benutzerübersicht');

    const columns: Column<Partner>[] = useMemo(() => getPartneruebersichtColumns(isMobile), [isMobile]);
    const initialPageCount = Math.ceil(totalCount / pageSizeState);

    const initialState: Partial<TableState<Partner>> = {
        sortBy: showSearchResult
            ? []
            : [
                  {
                      id: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.id || 'partnerNummer',
                      desc: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.desc || true,
                  },
              ],
        pageSize: pageSizeState,
        pageIndex: parseInt(localStorageWrapper.getItem(PAGE_INDEX_KEY) || '0'),
    };

    const options: TableOptions<Partner> = {
        columns: columns,
        data: !showSearchResult ? partnersForPage : searchResult,
        pageCount: initialPageCount,
        initialState: initialState || {},
        manualPagination: true,
        manualSortBy: true,
        disableSortRemove: true,
        disableMultiSort: true,
        disableSortBy: showSearchResult,
    };

    const tableInstance: TableInstance<Partner> = useTable<Partner>(options, useSortBy, usePagination);
    const state: TableState<Partner> = tableInstance.state;

    useEffect(() => {
        if (searchTerm === '') {
            loadPartnerForPage(state.pageIndex, state.pageSize, state.sortBy, dispatch);
        } else {
            dispatch(getPartner(searchTerm));
        }
    }, [dispatch, state.pageIndex, state.pageSize, state.sortBy, searchTerm]);

    useEffect(() => {
        localStorageWrapper.setItem(PAGE_INDEX_KEY, state.pageIndex.toString());
        localStorageWrapper.setItem(PAGE_SORTBY_KEY, JSON.stringify(state.sortBy));
    }, [state.pageIndex, state.sortBy]);

    return (
        <>
            <div className={styles._headerContainer}>
                <h2>Partner</h2>
                <div className={styles._searchField__container}>
                    <strong>Suche: </strong>
                    <input
                        autoFocus={true}
                        value={internalSearchValue}
                        onChange={(e): void => {
                            setInternalSearchValue(e.target.value);
                            onChangeSearchTerm(e.target.value);
                        }}
                        placeholder="Mindestens drei Zeichen eingeben"
                        className={styles._searchField}
                    />
                </div>
            </div>
            <NotificationBar
                message="Die Partner konnten nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailedForPage || loadFailedForSearch}
                actionText="Neu laden"
                actionCallback={(): void => {
                    if (showSearchResult) {
                        dispatch(getPartner(searchTerm));
                    } else {
                        loadPartnerForPage(state.pageIndex, state.pageSize, state.sortBy, dispatch);
                    }
                }}
            />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    <>
                        {isMobile ? (
                            <OverviewList
                                tableInstance={tableInstance}
                                onRowClick={(row): void => {
                                    history.push(`${routes.partnerdetails}/${row.original['partnerNummer']}`);
                                }}
                                mobileColumns={MOBILE_COLUMNS}
                            />
                        ) : (
                            <OverviewTable tableInstance={tableInstance} showSortingIcon={!showSearchResult} />
                        )}
                    </>
                    {!showSearchResult && (
                        <OverviewPagination tableInstance={tableInstance} setPageSizeState={setPageSizeState} totalCount={totalCount} />
                    )}
                </>
            </LoadingSpinner>
        </>
    );
};

export default PartnerTable;
