import { BestellpositionArtikelSackwareView } from '../../../types';
import styles from './SackwareList.module.scss';
import { BezeichnungEntry, MengeSWEntry, WerkeEntry } from '../PropertyEntries';

export type Props = Readonly<{
    bestellpositionenView: BestellpositionArtikelSackwareView[];
}>;

export const SackwareListReadonly = ({ bestellpositionenView }: Props): JSX.Element => {
    return (
        <div>
            {bestellpositionenView.map((bestellposition, index: number) => {
                return (
                    <div className={styles._artikelContainer} key={index}>
                        <BezeichnungEntry.List bestellposition={bestellposition} readonly />
                        <div className={styles._amountsContainer}>
                            <MengeSWEntry.List bestellposition={bestellposition} readonly />
                        </div>
                        <WerkeEntry.List bestellposition={bestellposition} readonly />
                    </div>
                );
            })}
        </div>
    );
};
