import { Modifikationsstatus } from '../../../../types/enums';
import Tippy from '@tippyjs/react';
import localStyles from '../../../../../views/Bestellansicht/Bestellansicht.module.scss';
import iconStorniert from '../../../../../assets/icon-bestellung-storniert.svg';
import iconEingangDTC from '../../../../../assets/icon-bestellung-eingang-dtc.svg';
import { ReactElement } from 'react';
import { Breakpoint, useBreakpoint } from '../../../../hooks/useBreakpoint';

const mapModifikationsstatusToIcon = (status?: Modifikationsstatus): string | undefined => {
    switch (status) {
        case Modifikationsstatus.V:
            return iconStorniert;
        case Modifikationsstatus.E:
            return iconEingangDTC;
    }
    return;
};

const mapModifikationsstatusToText = (status?: Modifikationsstatus): string | undefined => {
    switch (status) {
        case Modifikationsstatus.V:
            return 'Modifikation verworfen';
        case Modifikationsstatus.E:
            return 'Modifikation erfolgreich';
    }
    return;
};

type ModifikationsstatusIconProps = {
    status?: Modifikationsstatus;
    comment?: string;
    withText?: boolean;
};

export const ModifikationsstatusIcon = ({ status, comment, withText }: ModifikationsstatusIconProps): ReactElement => {
    const statusIcon = mapModifikationsstatusToIcon(status);
    const isMobile = useBreakpoint(Breakpoint.MOBILE);

    return (
        <div>
            {statusIcon ? (
                <Tippy content={comment} disabled={!comment || isMobile}>
                    <img className={localStyles._statusIcon} src={statusIcon} alt={comment} />
                </Tippy>
            ) : null}
            &nbsp;
            {isMobile || withText ? mapModifikationsstatusToText(status) : null}
        </div>
    );
};
