import './SackwareTable.module.scss';
import tableStyles from '../../../ui-components/Table/Table.module.scss';
import { FieldArray, useFormikContext } from 'formik';
import { isSackwareView } from '../../../helper/bestellungen-helper';
import { ArtikelTableValues } from '../../../types';
import { SackwareTableRowExistingBestellung } from './ExistingBestellung/SackwareTableRowExistingBestellung';
import { SackwareTableRowNewBestellung } from './NewBestellung/SackwareTableRowNewBestellung';
import { TableHeaderCell } from '../../../ui-components/Table';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../../store/Navigation.store';
import { selectIsBestellungExisting } from '../../../../store/Global.selectors';
import { Breakpoint, useBreakpoint } from '../../../hooks/useBreakpoint';

interface Props {
    allowDelete?: boolean;
    onArtikelDeleted?: (artikelNummer: string) => void;
}

export const SackwareTable = <T extends ArtikelTableValues>({ allowDelete, onArtikelDeleted }: Props): JSX.Element => {
    const formikProps = useFormikContext<T>();
    const isDebitor = useSelector(selectIsDebitor);
    const isExisting = useSelector(selectIsBestellungExisting);
    const isTabletPortrait = useBreakpoint(Breakpoint.TABLET_PORTRAIT);

    const TableRow = isExisting ? SackwareTableRowExistingBestellung : SackwareTableRowNewBestellung;

    return (
        <>
            <div className={tableStyles._tableContainer}>
                <table className={tableStyles._table}>
                    <thead>
                        <tr className={tableStyles._tableHeaderRow}>
                            {isTabletPortrait ? <TableHeaderCell textCentered>Artikelnr.</TableHeaderCell> : null}
                            <TableHeaderCell textCentered>Artikel</TableHeaderCell>
                            {isDebitor && <TableHeaderCell textCentered>Werk</TableHeaderCell>}
                            <TableHeaderCell textCentered>VE</TableHeaderCell>
                            <TableHeaderCell textCentered>kg / VE</TableHeaderCell>
                            <TableHeaderCell textCentered>Pal.</TableHeaderCell>
                            <TableHeaderCell textCentered>kg / Pal.</TableHeaderCell>
                            <TableHeaderCell textCentered>Gesamt VE</TableHeaderCell>
                            {allowDelete && <TableHeaderCell />}
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray
                            name="bestellpositionenView"
                            render={(arrayHelpers): JSX.Element => (
                                <>
                                    {formikProps.values.bestellpositionenView.filter(isSackwareView).map((bestellposition) => (
                                        <TableRow
                                            key={bestellposition.index}
                                            bestellposition={bestellposition}
                                            onArtikelDeleted={onArtikelDeleted}
                                            allowDelete={allowDelete}
                                            arrayHelpers={arrayHelpers}
                                        />
                                    ))}
                                </>
                            )}
                        />
                    </tbody>
                </table>
            </div>
        </>
    );
};
