import { CallEffect, PutEffect } from 'redux-saga/effects';
import { call, put, SagaGenerator } from 'typed-redux-saga';
import { getData, getDataWithResponseHeaders } from '../shared/fetchApi';
import {
    GetBestellungAction,
    GetBestellungenAction,
    getBestellungenFailed,
    getBestellungenSucceeded,
    getBestellungFailed,
    getBestellungSucceeded,
} from '../store/Bestelluebersicht.store';
import type { BestellungDetails } from '../shared/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { convertFilterToString } from './utils/sagaHelperFunctions';

type GetBestellung = (url: string) => Promise<BestellungDetails>;
export function* getBestellungSaga(action: PayloadAction<GetBestellungAction>): SagaGenerator<void, PutEffect | CallEffect> {
    try {
        const bestellung = yield* call<[string], GetBestellung>(getData, `bestellungen/${action.payload.bestellnummer}`);

        yield* put(getBestellungSucceeded(bestellung));
    } catch (erros: unknown) {
        yield* put(getBestellungFailed());
    }
}

type GetBestellungenWithHeaders = (url: string) => Promise<[BestellungDetails[], Headers]>;
export function* getBestellungenSaga(action: PayloadAction<GetBestellungenAction>): SagaGenerator<void, PutEffect | CallEffect> {
    try {
        const { page, size, sortColumn, sortOrder } = action.payload;

        const includeBestellpositionen = action.payload.includeBestellpositionen ?? false;

        const filter = convertFilterToString(action.payload.filter);

        const [bestellungen, headers] = yield* call<[string], GetBestellungenWithHeaders>(
            getDataWithResponseHeaders,
            `bestellungen?page=${page - 1}&size=${size}&sort=${sortColumn},${sortOrder}${filter}&positionen=${includeBestellpositionen}`
        );

        const totalCount = Number(headers.get('x-total-count'));
        if (isNaN(totalCount)) {
            yield* put(getBestellungenFailed());
        } else {
            yield* put(
                getBestellungenSucceeded({
                    bestellungen,
                    totalCount,
                })
            );
        }
    } catch (erros: unknown) {
        yield* put(getBestellungenFailed());
    }
}
