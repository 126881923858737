import { CellProps, Column } from 'react-table';
import TableCell from '../../../../../shared/ui-components/Table/ReactTable/TableCell';
import TextareaWithHookForms from '../../../../../shared/ui-components/Textarea/TextareaWithHookForms';
import type { TourenplanLoseWareBestellung } from '../../../TourenplanTypes';
import { BestellungDetails } from '../../../../../shared/types';

const SiloColumnCell = (cellProps: CellProps<BestellungDetails, Partial<TourenplanLoseWareBestellung>>): JSX.Element => {
    const silo = cellProps.value.silo;
    const rowIndex = cellProps.row.index;
    const cell = cellProps.cell;

    return (
        <TableCell cell={cell} input>
            <TextareaWithHookForms
                data-testid="tourenplan-silo-input"
                data-cy="silo"
                name={`loseWareBestellungen.${rowIndex}.silo`}
                defaultValue={silo}
                resizable={false}
                inTable
            />
        </TableCell>
    );
};

export const siloColumnConfig: Column<TourenplanLoseWareBestellung> = {
    id: 'silo',
    Header: 'Silo',
    accessor: (bestellung): Partial<TourenplanLoseWareBestellung> => {
        return {
            silo: bestellung.silo,
        };
    },
    Cell: SiloColumnCell,
};
