import styles from '../../ArtikelLoseWareTable/LoseWareTable.module.scss';
import { PreisartAuswahl } from '../../../PreisartAuswahl/PreisartAuswahl';
import { TableCell } from '../../../../ui-components/Table';
import { berechnungsartOptions } from '../../../../helper/select-options';
import { Berechnungsart } from '../../../../types/enums';
import { PropertyEntryReadonlyProps } from '../types';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const PreisartTableCell = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly) {
        return (
            <TableCell text storniert={bestellposition.storno}>
                <span data-cy={`lw-berechnungsart`}>
                    {berechnungsartOptions.find((o) => o.value === bestellposition.berechnungsart)?.label}
                </span>
                {bestellposition.berechnungsart === Berechnungsart.Kontrakt && <span> {bestellposition.kontrakt}</span>}
            </TableCell>
        );
    }

    return (
        <TableCell selectWithError storniert={bestellposition.storno}>
            <div className={styles._abrechnungsContainer}>
                <PreisartAuswahl bestellposition={bestellposition} disabled={disabled} />
            </div>
        </TableCell>
    );
};
