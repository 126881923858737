import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useFastestValidatorResolver from '../../shared/validation/hooks/useFastestValidatorResolver';
import { TourenplanLoseWareTable } from './tables/TourenplanLoseWareTable';
import { TourenplanSackwareTable } from './tables/TourenplanSackwareTable';
import { OrderAllButton } from './components/OrderAllButton';
import { tourenplanValidationSchema } from '../../shared/helper/tourenplan-validation';
import { useDispatch, useSelector } from 'react-redux';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { GET_BESTELLUNGEN_ERROR, OVERWEIGHT_ERROR, routes } from '../../shared/constants';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';
import useMaximalGewichtValidator from '../../shared/validation/hooks/useMaximalGewichtValidator';
import TourenplanHeader from './components/TourenplanHeader';
import { resetUpdateBestellung, selectBestellungUpdateFailed, updateBestellungBatch } from '../../store/Bestellung.store';
import {
    mapBestellungenToTourenplanLoseWareBestellungen,
    mapBestellungenToTourenplanSackwareBestellungen,
    mergeTourenplanFormIntoBestellungen,
} from '../../shared/helper/bestellungen-helper';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import {
    getErrorMessageForPropertyFieldError,
    getFirstErrorMessageFromLoseWareBestellung,
    getFirstErrorMessageFromSackwareBestellung,
} from './error-helper';
import { TourenplanBestelluebermittlung } from './TourenplanBestelluebermittlung';
import { BestelluebersichtSelectors, resetCurrentSammelbestellung } from '../../store/Bestelluebersicht.store';
import { useHistory } from 'react-router-dom';
import Button from '../../shared/ui-components/Button/Button';
import ButtonGroup, { ButtonGroupAlignment } from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import { getData } from '../../shared/fetchApi';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import MandatoryFormFieldHint from '../../shared/ui-components/Form/MandatoryFormFieldHint';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Lieferbedingungen, RequestState, Severity } from '../../shared/types/enums';
import type { BestellungDetails } from '../../shared/types';
import type { TourenplanForm, TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from './TourenplanTypes';

type BestellungenLoadingState = RequestState;
export const Tourenplan = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentSammelbestellungen = useSelector(BestelluebersichtSelectors.currentSammelbestellung);
    const [tourenplanLoseWareBestellungen, setTourenplanLoseWareBestellungen] = useState<TourenplanLoseWareBestellung[]>([]);
    const [tourenplanSackwareBestellungen, setTourenplanSackwareBestellungen] = useState<TourenplanSackwareBestellung[]>([]);
    const [bestellungen, setBestellungen] = useState<BestellungDetails[]>([]);
    const [bestellungenLoadingState, setBestellungenLoadingState] = useState<BestellungenLoadingState>(RequestState.INITIAL);
    const updateBestellungBatchRequestFailed = useSelector(selectBestellungUpdateFailed);

    const formMethods = useForm<TourenplanForm>({
        resolver: useFastestValidatorResolver<TourenplanForm>(tourenplanValidationSchema),
        defaultValues: {
            loseWareBestellungen: tourenplanLoseWareBestellungen,
            sackwareBestellungen: tourenplanSackwareBestellungen,
        },
    });
    const { errors, isSubmitSuccessful } = formMethods.formState;
    const { reset } = formMethods;
    const [hasOverweight, overweightConfirmed, setOverweightConfirmed] = useMaximalGewichtValidator(formMethods);
    const notificationSeverity = hasOverweight && overweightConfirmed ? Severity.CONFIRMRED : Severity.ERROR;

    const fetchBestellungenByBestellnummern = useCallback(() => {
        setBestellungenLoadingState(RequestState.LOADING);
        getData<BestellungDetails[]>(`bestellungen/bybestellnummern?bestellnummern=${currentSammelbestellungen.join(',')}`)
            .then((bestellungen) => {
                setBestellungen(bestellungen);
                const tourenplanLoseWareBestellungen = mapBestellungenToTourenplanLoseWareBestellungen(bestellungen);
                const tourenplanSackwareBestellungen = mapBestellungenToTourenplanSackwareBestellungen(bestellungen);
                setTourenplanLoseWareBestellungen(tourenplanLoseWareBestellungen);
                setTourenplanSackwareBestellungen(tourenplanSackwareBestellungen);
                reset({
                    loseWareBestellungen: tourenplanLoseWareBestellungen,
                    sackwareBestellungen: tourenplanSackwareBestellungen,
                });
                setBestellungenLoadingState(RequestState.SUCCESSFUL);
            })
            .catch((_) => {
                setBestellungenLoadingState(RequestState.FAILED);
            });
    }, [currentSammelbestellungen, reset]);

    useDocumentTitle('Aufträge zusammenfassen / Tour planen');

    useEffect(() => {
        if (currentSammelbestellungen.length === 0) {
            history.push(routes.auftragsuebersicht);
        }

        // Reset Bestellung update loading/failed states on mount to start the next Bestellung in an clean state
        dispatch(resetUpdateBestellung());
        fetchBestellungenByBestellnummern();

        return () => {
            dispatch(resetCurrentSammelbestellung());
        };
    }, [currentSammelbestellungen.length, dispatch, fetchBestellungenByBestellnummern, history]);

    const onSubmit: SubmitHandler<TourenplanForm> = (data) => {
        if (data.lieferbedingung === Lieferbedingungen.FRANCO) {
            data.spedition = '';
        }
        try {
            const updatedBestellungen: BestellungDetails[] = mergeTourenplanFormIntoBestellungen(data, bestellungen);
            dispatch(updateBestellungBatch(updatedBestellungen));
        } catch (error) {
            console.error(error);
        }
    };

    const sackwareWerkIdErrorMessage = getFirstErrorMessageFromSackwareBestellung(errors, 'werkId') ?? '';
    const mengeVeErrorMessage = getFirstErrorMessageFromSackwareBestellung(errors, 'mengeVe') ?? '';
    const mengePalErrorMessage = getFirstErrorMessageFromSackwareBestellung(errors, 'mengePal') ?? '';

    const mengeErrorMessage = getFirstErrorMessageFromLoseWareBestellung(errors, 'menge') ?? '';
    const loseWareWerkIdErrorMessage = getFirstErrorMessageFromLoseWareBestellung(errors, 'werkId') ?? '';

    const dateErrorMessage = getErrorMessageForPropertyFieldError(errors, 'datumDebitor') ?? '';
    const timeErrorMessage = getErrorMessageForPropertyFieldError(errors, 'uhrzeitDebitor') ?? '';
    const lieferbedingungErrorMessage = getErrorMessageForPropertyFieldError(errors, 'lieferbedingung') ?? '';

    return (
        <>
            <h2>Aufträge zusammenfassen / Tour planen</h2>
            <NotificationBar message={dateErrorMessage} isVisible={Boolean(dateErrorMessage)} dataCy={'date-error-bar'} />
            <NotificationBar message={timeErrorMessage} isVisible={Boolean(timeErrorMessage)} dataCy={'time-error-bar'} />
            <NotificationBar
                message={lieferbedingungErrorMessage}
                isVisible={Boolean(lieferbedingungErrorMessage)}
                dataCy={'lieferbedingung-error-bar'}
            />
            <NotificationBar
                message={loseWareWerkIdErrorMessage}
                isVisible={Boolean(loseWareWerkIdErrorMessage)}
                dataCy={'loseware-werk-error-bar'}
            />
            <NotificationBar
                message={sackwareWerkIdErrorMessage}
                isVisible={Boolean(sackwareWerkIdErrorMessage)}
                dataCy={'sackware-werk-error-bar'}
            />
            <NotificationBar message={mengeErrorMessage} isVisible={Boolean(mengeErrorMessage)} dataCy={'menge-error-bar'} />
            <NotificationBar
                message={mengeVeErrorMessage}
                isVisible={Boolean(mengeVeErrorMessage) && Boolean(mengePalErrorMessage)}
                dataCy={'mengeVe-error-bar'}
            />
            <NotificationBar
                message={GET_BESTELLUNGEN_ERROR}
                isVisible={bestellungenLoadingState === RequestState.FAILED}
                actionText="Neu laden"
                actionCallback={fetchBestellungenByBestellnummern}
            />
            <NotificationBar
                testId="weight-validation-message-bar"
                message=""
                isVisible={hasOverweight && (!isSubmitSuccessful || updateBestellungBatchRequestFailed)}
                severity={notificationSeverity}
            >
                <NonFormikCheckbox
                    id="notificationCheck"
                    dataCy={'notificationCheck'}
                    name="notificationCheck"
                    label={OVERWEIGHT_ERROR}
                    onFieldChange={(checked): void => {
                        setOverweightConfirmed(checked);
                    }}
                />
            </NotificationBar>
            <FormProvider {...formMethods}>
                {currentSammelbestellungen.length > 0 && <TourenplanHeader />}
                <LoadingSpinner isLoading={bestellungenLoadingState === RequestState.LOADING}>
                    <>
                        {tourenplanLoseWareBestellungen.length > 0 && (
                            <>
                                <div className={formStyles._sectionHeader}>
                                    <h3>Bestellpositionen lose Ware</h3>
                                </div>
                                <TourenplanLoseWareTable bestellungen={tourenplanLoseWareBestellungen} />
                            </>
                        )}
                        {tourenplanSackwareBestellungen.length > 0 && (
                            <>
                                <div className={formStyles._sectionHeader}>
                                    <h3>Bestellpositionen Sackware</h3>
                                </div>
                                <TourenplanSackwareTable bestellungen={tourenplanSackwareBestellungen} />
                            </>
                        )}
                        <MandatoryFormFieldHint />
                        <TourenplanBestelluebermittlung>
                            <ButtonGroup alignment={ButtonGroupAlignment.LEFT}>
                                <Button
                                    type="button"
                                    isSecondary
                                    onClick={(): void => {
                                        history.push(routes.auftragsuebersicht);
                                    }}
                                >
                                    Zurück
                                </Button>
                                <OrderAllButton<TourenplanForm>
                                    submitHandler={onSubmit}
                                    disabled={
                                        (!overweightConfirmed && hasOverweight) || bestellungenLoadingState !== RequestState.SUCCESSFUL
                                    }
                                    data-cy={'order-all-button'}
                                />
                            </ButtonGroup>
                        </TourenplanBestelluebermittlung>
                    </>
                </LoadingSpinner>
            </FormProvider>
        </>
    );
};
