import { CellProps, Column } from 'react-table';
import TextInputWithHookForms from '../../../../../shared/ui-components/TextInput/TextInputWithHookForms';
import TableCell from '../../../../../shared/ui-components/Table/ReactTable/TableCell';
import { useFormContext } from 'react-hook-form';
import { getErrorMessageForPropertyFieldError } from '../../../error-helper';
import { SackwareColumns } from '../ColumnEnums';
import { TextInputRule } from '../../../../../shared/types/enums';
import type { TourenplanForm, TourenplanSackwareBestellung } from '../../../TourenplanTypes';
import { BestellungDetails } from '../../../../../shared/types';

const MengeVeColumnCell = (cellProps: CellProps<BestellungDetails, Partial<TourenplanSackwareBestellung>>): JSX.Element => {
    const { mengeVe } = cellProps.value;
    const rowIndex = cellProps.row.index;
    const cell = cellProps.cell;

    const { formState } = useFormContext<TourenplanForm>();
    const { errors } = formState;
    const mengeVeErrorMessage = getErrorMessageForPropertyFieldError(errors?.sackwareBestellungen?.[rowIndex], 'mengeVe');

    return (
        <TableCell cell={cell} input>
            <TextInputWithHookForms
                data-testid="tourenplan-mengeve-input"
                data-cy="sw-menge-ve"
                defaultValue={mengeVe}
                textInputRule={TextInputRule.ONLY_POSITIVE_INTEGERS}
                name={`sackwareBestellungen.${rowIndex}.mengeVe`}
                hasError={Boolean(mengeVeErrorMessage)}
                errorMessage={mengeVeErrorMessage ?? ''}
                inTableCell
            />
        </TableCell>
    );
};

export const mengeVeColumnConfig: Column<TourenplanSackwareBestellung> = {
    id: 'mengeVe',
    Header: SackwareColumns.MengeVeColumnHeader,
    accessor: (bestellung): Partial<TourenplanSackwareBestellung> => {
        return {
            bestellnummer: bestellung.bestellnummer,
            mengeVe: bestellung.mengeVe,
            nummer: bestellung.nummer,
        };
    },
    Cell: MengeVeColumnCell,
};
