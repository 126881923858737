import { Bestellprozesstyp } from '../types/enums';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDebitor } from '../../store/Navigation.store';
import { useCallback, useEffect } from 'react';
import { getArtikelLoseWare } from '../../store/ArtikelLoseWare.store';

export const useFetchArtikelLoseWare = (
    rechnungsempfaengerPartnerNummer: string | undefined,
    warenempfaengerPartnerNummer: string | undefined,
    bestellprozesstyp: Bestellprozesstyp
): (() => void) => {
    const isDebitor = useSelector(selectIsDebitor);
    const dispatch = useDispatch();

    const fetchLoseWareArtikel = useCallback(() => {
        if (rechnungsempfaengerPartnerNummer && warenempfaengerPartnerNummer) {
            dispatch(
                getArtikelLoseWare({
                    bestellprozesstyp,
                    rechnungsempfaengerPartnerNummer: rechnungsempfaengerPartnerNummer,
                    warenempfaengerPartnerNummer: isDebitor ? undefined : warenempfaengerPartnerNummer,
                })
            );
        }
    }, [bestellprozesstyp, dispatch, isDebitor, rechnungsempfaengerPartnerNummer, warenempfaengerPartnerNummer]);

    useEffect(() => {
        fetchLoseWareArtikel();
    }, [fetchLoseWareArtikel]);

    return fetchLoseWareArtikel;
};
