import { call, put, SagaGenerator } from 'typed-redux-saga';
import { CallEffect, PutEffect } from 'redux-saga/effects';
import { getDataWithResponseHeaders } from '../shared/fetchApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetRationsservicesAction, getRationsservicesFailed, getRationsservicesSucceeded } from '../store/Rationsservice.store';
import type { Vorgang } from '../shared/types';

type GetRationsservicesWithHeaders = (url: string) => Promise<[Vorgang[], Headers]>;
export function* getRationsservicesSaga(action: PayloadAction<GetRationsservicesAction>): SagaGenerator<void, PutEffect | CallEffect> {
    const {} = action.payload;

    const url = `vorgaenge?vorgangstyp=RS`;

    try {
        const [rationsservices, headers] = yield* call<[string], GetRationsservicesWithHeaders>(getDataWithResponseHeaders, url);

        const totalCount = Number(headers.get('x-total-count'));
        if (isNaN(totalCount)) {
            yield* put(getRationsservicesFailed());
        } else {
            yield* put(getRationsservicesSucceeded({ rationsservices, totalCount }));
        }
    } catch (errors: unknown) {
        yield* put(getRationsservicesFailed());
    }
}
