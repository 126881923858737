import { ControlProps, GroupTypeBase, OptionProps, OptionTypeBase, SingleValueProps, Styles } from 'react-select';
import { CSSObject } from '@emotion/serialize';
import styles from './SelectInput.module.scss';

export function createCustomStyles<T extends OptionTypeBase>(
    hasError: boolean,
    inTableCell?: boolean,
    menuAlignment = 'left',
    storniert = false
): Partial<Styles<T, false>> {
    const createControlStyle = (_: CSSObject, state: ControlProps<T, false>): CSSObject => {
        const defaultControlStyle: CSSObject = {
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            transition: 'all 100ms',
            height: '2.625rem',
        };

        const borderStyleColor = (): string => {
            if (hasError) {
                return styles.errorColor;
            }
            if (state.isDisabled) {
                return styles.borderColorDisabled;
            }
            if (state.isFocused) {
                return styles.focusBorderColor;
            }

            return styles.borderColor;
        };

        const backgroundColor = (): string => {
            if (state.isDisabled) {
                return styles.inputBackgroundColorDisabled;
            }

            if (state.isFocused) {
                return styles.focusBackgroundColor;
            }

            return styles.inputBackgroundColor;
        };

        if (inTableCell) {
            return {
                ...defaultControlStyle,
                border: 'none',
                height: '100%',
                backgroundColor: backgroundColor(),
            };
        }

        return {
            ...defaultControlStyle,
            border: `1px solid ${borderStyleColor()}`,
            backgroundColor: backgroundColor(),
        };
    };

    const createOptionStyle = (base: CSSObject, state: OptionProps<T, false>): CSSObject => {
        const backgroundColor = (): string => {
            if (state.isFocused) {
                return styles.hoverBackgroundColor;
            }

            if (state.isSelected) {
                return styles.selectedOptionBackgroundColor;
            }

            return 'transparent';
        };

        return {
            ...base,
            backgroundColor: backgroundColor(),
            color: styles.textColor,
            whiteSpace: 'nowrap',
        };
    };

    const createMenuStyle = (base: CSSObject): CSSObject => {
        return {
            ...base,
            borderRadius: 0,
            zIndex: 1000,
            minWidth: 'fit-content',
            [menuAlignment]: 0,
            margin: 0,
        };
    };

    const createSingleValueStyle = (base: CSSObject, state: SingleValueProps<T, GroupTypeBase<T>>): CSSObject => {
        if (storniert) {
            return { ...base, color: styles.storniertColor, textDecoration: 'line-through' };
        }
        return { ...base, color: state.selectProps.menuIsOpen ? 'lightgrey' : styles.textColor };
    };

    const createPlaceHolderStyle = (base: CSSObject): CSSObject => {
        return {
            ...base,
            fontSize: '1rem',
            top: '53%',
        };
    };

    const createMenuListStyle = (base: CSSObject): CSSObject => {
        return {
            ...base,
            padding: 0,
        };
    };

    return {
        control: createControlStyle,
        option: createOptionStyle,
        menu: createMenuStyle,
        menuList: createMenuListStyle,
        singleValue: createSingleValueStyle,
        placeholder: createPlaceHolderStyle,
        indicatorSeparator: (provided: CSSObject): CSSObject => {
            return { ...provided, marginBottom: 0, marginTop: 0 };
        },
        valueContainer: (provided: CSSObject): CSSObject => {
            return { ...provided, height: '100%' };
        },
    };
}
