import './StartseiteInteressent.module.scss';

const StartseiteInteressent = (): JSX.Element => {
    return (
        <>
            <h2>Willkommen</h2>
            <p>
                und vielen Dank für Ihre Anmeldung zum DEU·PA - Deutsche Tiernahrung Cremer Partnerportal. <br />
                Es erfolgt nun die Zuordnung Ihrer erfassten Kundendaten in unserer Warenwirtschaft.
            </p>
            <p>Wir werden Sie in den nächsten Tagen für Ihre erste Bestellung freischalten. Bitte haben Sie noch etwas Geduld.</p>
        </>
    );
};

export default StartseiteInteressent;
