const einheiten = {
    CMQ: 'cm³',
    CMT: 'cm',
    CT: 'Karton',
    GK: 'g/kg',
    GRM: 'g',
    HUR: 'Std',
    KGM: 'kg',
    KMT: 'km',
    LTR: 'Liter',
    MGM: 'mg',
    MIN: 'min',
    MMT: 'mm',
    MTK: 'm²',
    MTQ: 'm³',
    MTR: 'm',
    NA: 'mg/kg',
    PCE: 'Stk',
    PF: 'Pal.',
    PK: 'Pack',
    PR: 'Paar',
    RO: 'Rolle',
    SA: 'Sack',
    SEC: 's',
    TNE: 't',
};
export const translateEinheit = (isoString: string): string => {
    return einheiten[isoString] || isoString;
};
