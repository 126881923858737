import { PropsWithChildren, ReactElement, useMemo } from 'react';
import styles from './FilterPanel.module.scss';

export enum FilterPanelAlignment {
    EVENTLY,
    SPACED,
    END,
}

const FilterPanel = ({
    children,
    alignment = FilterPanelAlignment.END,
}: PropsWithChildren<{ alignment?: FilterPanelAlignment }>): ReactElement => {
    const filterPanelClasses = useMemo(() => {
        const _styles = [styles._filterPanel];
        switch (alignment) {
            case FilterPanelAlignment.SPACED:
                _styles.push(styles['_filterPanel--space-between']);
                break;
            case FilterPanelAlignment.EVENTLY:
                break;
            case FilterPanelAlignment.END:
                break;
        }
        return _styles.join(' ');
    }, [alignment]);

    return <div className={filterPanelClasses}>{children}</div>;
};

type FilterPanelEntryProps = {
    col?: 1 | 2 | 3 | 4 | 5 | 6;
};

FilterPanel.Entry = function FilterEntry({ children, col = 1 }: PropsWithChildren<FilterPanelEntryProps>): ReactElement {
    const filterPanelEntryClasses = useMemo(() => {
        const _styles = [styles._filter, styles[`_filter--space-${col}`]];
        return _styles.join(' ');
    }, [col]);

    return <div className={filterPanelEntryClasses}>{children}</div>;
};

export { FilterPanel };
