import { ModifikationsstatusIcon } from './ModifikationsstatusIcon';
import { useErpRueckmeldung } from './useErpRueckmeldung';
import { ModifikationsstatusCellProps } from './type';
import localStyles from '../../../../../views/Bestellansicht/Bestellansicht.module.scss';
import { Breakpoint, useBreakpoint } from '../../../../hooks/useBreakpoint';
import { ReactElement } from 'react';

export const ModifikationsstatusListEntry = (props: ModifikationsstatusCellProps): ReactElement | null => {
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const erpRueckmeldung = useErpRueckmeldung(props.nummer, props.positionsnummer);
    const kommentar = erpRueckmeldung?.modifikationskommentar;
    const status = erpRueckmeldung?.modifikationsstatus;

    if (!status) {
        return null;
    }

    if (isMobile) {
        return (
            <div>
                <ModifikationsstatusIcon status={status} comment={kommentar} />
                {kommentar ? <span className={localStyles._kommentar}>{kommentar}</span> : null}
            </div>
        );
    }

    return <ModifikationsstatusIcon status={status} comment={kommentar} withText={props.withText} />;
};
