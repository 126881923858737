import { useField } from 'formik';
import AnimateHeight from 'react-animate-height';
import Label from '../Label/Label';
import { collapseAnimationDuration } from '../TextInput/TextInput';
import styles from './FormControl.module.scss';
import { BaseProps } from './index';

export type Props = BaseProps &
    Readonly<{
        renderChild: (props: BaseProps) => JSX.Element;
        label?: string;
        allowChildToGrow?: boolean;
        displayError?: boolean;
        noLabelWrap?: boolean;
        inTableCell?: boolean;
    }>;

const FormControl = ({
    label,
    allowChildToGrow,
    noLabelWrap = false,
    displayError = true,
    inTableCell = false,
    ...props
}: Props): JSX.Element => {
    const [, meta] = useField(props);
    const showError = meta.touched && Boolean(meta.error);
    const baseProps: BaseProps = {
        name: props.name,
        id: props.id,
    };

    let inputContainerStyle = '';

    if (!allowChildToGrow) {
        inputContainerStyle = styles._inputContainer;
    }
    if (inTableCell) {
        inputContainerStyle = styles['_inputContainer--inTableCell'];
    }

    const containerStyle = inTableCell ? styles['_container--inTableCell'] : styles._container;

    return (
        <div className={containerStyle}>
            {label && (
                <Label htmlFor={props.id || props.name} hasError={showError} noWrap={noLabelWrap}>
                    {label}
                </Label>
            )}
            <div className={inputContainerStyle}>{props.renderChild(baseProps)}</div>
            {displayError && (
                <AnimateHeight duration={collapseAnimationDuration} height={showError ? 'auto' : '0%'}>
                    <div className={styles._error}>{meta.error}</div>
                </AnimateHeight>
            )}
        </div>
    );
};

export const FormControlWithoutErrorDisplay = (props: Props): JSX.Element => {
    return <FormControl {...props} displayError={false} />;
};

export default FormControl;
