import createSagaMiddleware from 'redux-saga';
import { Store } from 'redux';
import rootSaga from './sagas/rootSaga';
import { loadPartialState, savePartialState } from './localStorage';
import { rootReducer } from './store/rootReducer';
import { throttle } from './shared/throttle';
import { configureStore as reduxConfigureStore } from '@reduxjs/toolkit';

export default function configureStore(withMiddleware = true): Store<ReturnType<typeof rootReducer>> {
    const sagaMiddleware = createSagaMiddleware();
    const configuredStore = reduxConfigureStore({
        reducer: rootReducer,
        preloadedState: loadPartialState(),
        middleware: withMiddleware ? [sagaMiddleware] : [],
    });
    if (withMiddleware) {
        sagaMiddleware.run(rootSaga);
    }
    configuredStore.subscribe(
        throttle(() => {
            savePartialState(configuredStore.getState());
        }, 200)
    );
    return configuredStore;
}

export const store = configureStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export function configureStoreWithMockDispatch(withMiddleware = false): Store<RootState> {
    const configuredStore = configureStore(withMiddleware);
    const origDispatch = configuredStore.dispatch;
    configuredStore.dispatch = jest.fn(origDispatch);
    return configuredStore;
}
