import { FieldArray, FormikProps } from 'formik';
import { WarenempfaengerWithFormFields } from '../../shared/types';
import styles from './WarenempfaengerAnlegen.module.scss';
import Label from '../../shared/ui-components/Label/Label';
import MaskedTextInput from '../../shared/ui-components/MaskedTextInput/MaskedTextInput';
import IconButton from '../../shared/ui-components/IconButton/IconButton';
import iconTrashcan from '../../assets/icon-trashcan.svg';
import { ReactElement } from 'react';

type VvvoBlockProps = {
    formikProps: FormikProps<WarenempfaengerWithFormFields>;
};

export const VvvoBlock = ({ formikProps }: VvvoBlockProps): ReactElement => (
    <>
        <h3>VVVO-Nummern</h3>
        <div className={styles._vvvoContainer}>
            <Label>VVVO-Nummern</Label>
            <FieldArray
                name="vvvoNummern"
                render={(arrayHelpers): JSX.Element => (
                    <>
                        {formikProps.values.vvvoNummern.map((vvvo, index) => (
                            <div className={styles._vvvoEntry} key={`vvvoNummern[${index}]`}>
                                <div className={styles._vvvoNumber}>
                                    <MaskedTextInput
                                        id={`vvvoNummern[${index}]`}
                                        name={`vvvoNummern[${index}]`}
                                        mask="999 99 999 999 9999"
                                        type="text"
                                    />
                                </div>
                                <div className={styles._iconContainer}>
                                    <IconButton
                                        icon={iconTrashcan}
                                        alt="Löschen"
                                        onClick={(event): void => {
                                            event.preventDefault();
                                            arrayHelpers.remove(index);
                                        }}
                                    />
                                </div>
                            </div>
                        ))}

                        <div className={styles._vvvoTemplate}>
                            <div className={styles._vvvoNumber}>
                                <MaskedTextInput
                                    id={`addVvvo`}
                                    name={`addVvvo`}
                                    mask="999 99 999 999 9999"
                                    type="text"
                                    placeholder="VVVO Nummer hinzufügen"
                                    onBlur={async (): Promise<void> => {
                                        const validation = await formikProps.validateForm();
                                        if (
                                            !validation['addVvvo'] &&
                                            formikProps.values['addVvvo'].trim() !== '' &&
                                            !formikProps.values.vvvoNummern.some((entry) => entry === formikProps.values['addVvvo'])
                                        ) {
                                            arrayHelpers.push(formikProps.values.addVvvo);
                                            formikProps.setFieldValue('addVvvo', '');

                                            formikProps.setFieldTouched('addVvvo', false);
                                        } else {
                                            formikProps.setFieldTouched('addVvvo', true);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            />
        </div>
    </>
);
