import { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const ScrollToTop = ({ history }: RouteComponentProps): JSX.Element | null => {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return (): void => {
            unlisten();
        };
    }, [history]);

    return null;
};

export default withRouter(ScrollToTop);
