import { RefObject, useCallback, useEffect, useRef } from 'react';

export default function usePreventWheel(): RefObject<HTMLInputElement> {
    // This is a complicated workaround because of the way chrome and react interact with respect to passive events:
    // see:
    // - https://github.com/facebook/react/issues/14856
    // - https://codesandbox.io/s/zzqxp1yvy3?file=/src/index.tsx:1132-1171

    const input = useRef<HTMLInputElement>(null);

    const handleWheel = useCallback((e: Event) => {
        e.preventDefault();
    }, []);

    const handleFocus = useCallback(() => {
        if (input && input.current) {
            input.current.addEventListener('mousewheel', handleWheel, {
                passive: false,
            });
        }
    }, [handleWheel]);
    const handleBlur = useCallback(() => {
        if (input && input.current) {
            input.current.removeEventListener('mousewheel', handleWheel);
        }
    }, [handleWheel]);
    useEffect(() => {
        if (input && input.current) {
            input.current.addEventListener('focus', handleFocus);
            input.current.addEventListener('blur', handleBlur);
        }
    });

    return input;
}
