import { Partner } from '../shared/types';
import type { RootState } from '../configureStore';

export const GET_PARTNER = 'GET_PARTNER';
export const GET_PARTNER_SUCCEEDED = 'GET_PARTNER_SUCCEEDED';
export const GET_PARTNER_FAILED = 'GET_PARTNER_FAILED';

export interface GetPartnerAction {
    type: typeof GET_PARTNER;
    searchQuery: string;
}

export interface GetPartnerSucceededAction {
    type: typeof GET_PARTNER_SUCCEEDED;
    partner: Partner[];
}

export interface GetPartnerFailedAction {
    type: typeof GET_PARTNER_FAILED;
}

export function getPartner(searchQuery: string): GetPartnerAction {
    return {
        type: GET_PARTNER,
        searchQuery,
    };
}

export function getPartnerSucceeded(partner: Partner[]): GetPartnerSucceededAction {
    return {
        type: GET_PARTNER_SUCCEEDED,
        partner,
    };
}

export function getPartnerFailed(): GetPartnerFailedAction {
    return {
        type: GET_PARTNER_FAILED,
    };
}

export type PartnerSucheActions = GetPartnerAction | GetPartnerSucceededAction | GetPartnerFailedAction;

export type PartnerSucheState = {
    partner: Partner[];
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
};

export const INITIAL_PARTNER_SUCHE_STATE: PartnerSucheState = {
    partner: [],
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
};

const selectPartnerSuche = (state: RootState): PartnerSucheState => state.partnersuche;
const selectPartner = (state: RootState): Partner[] => selectPartnerSuche(state).partner;

export const PartnerSucheSelectors = {
    partner: selectPartner,
    isLoading: (state: RootState): boolean => selectPartnerSuche(state).isLoading,
    loadFailed: (state: RootState): boolean => selectPartnerSuche(state).loadFailed,
    loadFinished: (state: RootState): boolean => selectPartnerSuche(state).loadFinished,
};

export function partnerSucheReducer(state = INITIAL_PARTNER_SUCHE_STATE, action: PartnerSucheActions): PartnerSucheState {
    switch (action.type) {
        case GET_PARTNER:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
                loadFinished: false,
            };
        case GET_PARTNER_FAILED:
            return {
                ...state,
                loadFailed: true,
                isLoading: false,
                loadFinished: true,
            };
        case GET_PARTNER_SUCCEEDED:
            return {
                ...state,
                partner: action.partner,
                loadFailed: false,
                isLoading: false,
                loadFinished: true,
            };
        default:
            return state;
    }
}
