import formStyles from '../../ui-components/Form/Form.module.scss';
import TextInputWithHookForms from '../../ui-components/TextInput/TextInputWithHookForms';
import { useFormContext } from 'react-hook-form';
import { WUNSCHTERMIN_VALIDATION_ERRORS } from '../../constants';
import { WunschterminLabels } from './labels';

interface WunschterminProps {
    minDate: string;
    dateFieldName?: string;
    timeFieldName?: string;
    isRequired?: boolean;
    label?: string;
}

export const WunschterminWithHookForms = ({
    minDate,
    dateFieldName = 'datumDebitor',
    timeFieldName = 'uhrzeitDebitor',
    isRequired = true,
    label,
}: WunschterminProps): JSX.Element => {
    const { formState } = useFormContext();
    const errors = formState.errors;

    const hasDateError = Boolean(errors[dateFieldName]);
    const hasTimeError = Boolean(errors[timeFieldName]);

    return (
        <div data-cy={'form-lw-wunschtermin'} className={formStyles._terminContainer}>
            <div>
                <label htmlFor={dateFieldName}>{label || `${WunschterminLabels.ABHOL_BZW_LIEFERTERMIN}${isRequired ? ' *' : ''}`}</label>
                <TextInputWithHookForms
                    name={dateFieldName}
                    id={dateFieldName}
                    type="date"
                    min={minDate}
                    placeholder="jjjj-mm-tt"
                    hasError={hasDateError}
                    errorMessage={WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_DATE}
                />
            </div>
            <div>
                <label htmlFor={timeFieldName}>{`${WunschterminLabels.UHRZEIT}${isRequired ? ' *' : ''}`}</label>
                <TextInputWithHookForms
                    name={timeFieldName}
                    id={timeFieldName}
                    type="time"
                    placeholder="hh:mm"
                    hasError={hasTimeError}
                    errorMessage={WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_TIME}
                />
            </div>
        </div>
    );
};
