import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { BestelldatenFormular } from '../../shared/types';
import { useCallback } from 'react';
import { getISOWunschtermin } from '../../shared/helper/date-helper';
import { setBestelldaten } from '../../store/Bestellung.store';
import { selectIsDebitor } from '../../store/Navigation.store';

type SaveBestelldaten = {
    manualSave: (vvvoNummer?: string) => void;
    saveViaSubmit: (bestelldaten: BestelldatenFormular) => void;
};

export const useSaveBestelldaten = (): SaveBestelldaten => {
    const dispatch = useDispatch();
    const isDebitor = useSelector(selectIsDebitor);
    const formikContext = useFormikContext<BestelldatenFormular>();

    // FIXME: This results in an warning: Formik context is undefined, please verify you are calling useFormikContext() as child of a <Formik> component.
    const bestelldatenFromFormik = formikContext?.values;

    const saveBestelldatenInternal = useCallback(
        (bestelldaten?: BestelldatenFormular, newVvvoNummer?: string) => {
            const {
                lieferbedingung,
                ansprechpartner,
                vvvoNummer: selectedVvoNummer,
                datumDebitor,
                uhrzeitDebitor,
                datumWEVon,
                uhrzeitWEVon,
                datumWEBis,
                uhrzeitWEBis,
                bestellhinweis,
                bestellhinweisWE,
                lieferhinweis,
                kfzKennzeichen,
                spedition,
            } = bestelldaten ?? bestelldatenFromFormik;

            const vvvoNummer = newVvvoNummer ?? selectedVvoNummer;

            let wunschtermin: string | undefined = '';
            let wunschterminVon: string | undefined = '';
            let wunschterminBis: string | undefined = '';

            if (isDebitor) {
                wunschtermin = getISOWunschtermin(datumDebitor, uhrzeitDebitor);
            } else {
                wunschterminVon = getISOWunschtermin(datumWEVon, uhrzeitWEVon);
                wunschterminBis = getISOWunschtermin(datumWEBis, uhrzeitWEBis);
            }

            dispatch(
                setBestelldaten({
                    lieferbedingung,
                    ansprechpartner,
                    bestellhinweis,
                    bestellhinweisWE,
                    lieferhinweis,
                    kfzKennzeichenId: kfzKennzeichen,
                    speditionId: spedition,
                    wunschtermin,
                    wunschterminVon,
                    wunschterminBis,
                    vvvoNummer,
                })
            );
        },
        [bestelldatenFromFormik, dispatch, isDebitor]
    );

    const manualSave = useCallback(
        (vvvoNummer?: string) => {
            saveBestelldatenInternal(undefined, vvvoNummer);
        },
        [saveBestelldatenInternal]
    );

    const saveViaSubmit = useCallback(
        (bestelldaten: BestelldatenFormular) => {
            saveBestelldatenInternal(bestelldaten);
        },
        [saveBestelldatenInternal]
    );

    return {
        saveViaSubmit,
        manualSave,
    };
};
