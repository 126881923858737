import { useCallback, useEffect, useState } from 'react';
import nutztierImage from './../../assets/images/nutztierfutter.600x550.jpg';
import heimtierImage from './../../assets/images/heimtierfutter.600x550.jpg';
import styles from './AuswahlFuttertyp.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    routes,
    selectBestellprozesstyp,
    selectCurrentRoute,
    selectHasOpenBestellung,
    selectIsCalculatingWorkflow,
    selectNextRoute,
    setBestellprozesstyp,
    setCurrentWorkflowStep,
    WorkflowStep,
} from '../../store/Workflow.store';
import { clearBestellung, setWerk } from '../../store/Bestellung.store';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '../../shared/ui-components/Button/Button';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Bestellprozesstyp } from '../../shared/types/enums';
import { TeaserBox } from '../../shared/ui-components/TeaserBox/TeaserBox';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';

const AuswahlFuttertyp = (): JSX.Element => {
    const dispatch = useDispatch();
    const nextRoute = useSelector(selectNextRoute);
    const currentRoute = useSelector(selectCurrentRoute);
    const history = useHistory();
    const currentBestellprozesstyp = useSelector(selectBestellprozesstyp);
    const [bestellprozesstypSelected, setBestellprozesstypSelected] = useState(false);
    const hasOpenBestellung = useSelector(selectHasOpenBestellung);
    const calculatingWorkflow = useSelector(selectIsCalculatingWorkflow);
    useDocumentTitle('Auswahl Futtertyp');

    const goToCurrentRoute = useCallback((): void => {
        history.push(currentRoute);
    }, [currentRoute, history]);

    const clearCurrentBestellung = useCallback((): void => {
        dispatch(clearBestellung());
    }, [dispatch]);

    useEffect(() => {
        if (currentBestellprozesstyp !== undefined && hasOpenBestellung && !calculatingWorkflow) {
            showConfirmationDialog({
                title: 'Sind Sie sicher?',
                message: (
                    <p>
                        Sie haben noch eine{' '}
                        {currentBestellprozesstyp === Bestellprozesstyp.Nutztierfutter ? 'Nutztierfutter' : 'Heimtierfutter'} Bestellung,
                        die nicht abgeschlossen ist. Wenn Sie fortfahren werden Ihre Bestellpositionen gelöscht.
                    </p>
                ),
                okButtonText: 'Bestehende Bestellung löschen',
                onOkCallback: clearCurrentBestellung,
                onCloseCallback: history.goBack,
                onClickOutside: history.goBack,
                additionalButtonCallback: (onClose) => (
                    <Button
                        isSecondary
                        onClick={() => {
                            onClose();
                            goToCurrentRoute();
                        }}
                    >
                        Bestellung fortsetzen
                    </Button>
                ),
            });
        }
    }, [calculatingWorkflow, clearCurrentBestellung, currentBestellprozesstyp, goToCurrentRoute, hasOpenBestellung, history.goBack]);

    useEffect(() => {
        // Die nextRoute wird asynchron von der workflow.saga berechnet.
        // Erst wenn diese Berechnung durch ist, darf auf die nächste Seite weitergeleitet werden.
        if (bestellprozesstypSelected && nextRoute !== routes.auswahlFuttertyp) {
            history.push(nextRoute);
        }
    }, [bestellprozesstypSelected, history, nextRoute]);

    useEffect(() => {
        if (!hasOpenBestellung) {
            dispatch(setCurrentWorkflowStep(WorkflowStep.AuswahlBestellprozesstyp, history));
        }
    }, [history, dispatch, hasOpenBestellung]);

    const handleChangeBestellprozesstyp = (newBestellprozesstyp: Bestellprozesstyp): void => {
        dispatch(setBestellprozesstyp(newBestellprozesstyp));
        dispatch(setCurrentWorkflowStep(WorkflowStep.AuswahlBestellprozesstyp, history));
        dispatch(setWerk());
        setBestellprozesstypSelected(true);
    };

    const setBestellprozesstypToNutztierfutter = (): void => {
        handleChangeBestellprozesstyp(Bestellprozesstyp.Nutztierfutter);
    };

    const setBestellprozesstypToHeimtierfutter = (): void => {
        handleChangeBestellprozesstyp(Bestellprozesstyp.Heimtierfutter);
    };

    return (
        <>
            <h2>Neue Bestellung erfassen</h2>
            <p>Was möchten Sie bestellen?</p>
            <LoadingSpinner isLoading={calculatingWorkflow}>
                <div className={styles._auswahlContainer}>
                    <TeaserBox
                        testId="auswahl-nutztierfutter"
                        onClick={setBestellprozesstypToNutztierfutter}
                        image={nutztierImage}
                        alt="Lose Ware"
                    >
                        <div>Lose Ware</div>
                        <div className={styles['_itemText--subline']}>(Sackware kombinierbar)</div>
                    </TeaserBox>
                    <TeaserBox onClick={setBestellprozesstypToHeimtierfutter} image={heimtierImage} alt="Sackware">
                        Sackware
                    </TeaserBox>
                </div>
            </LoadingSpinner>
        </>
    );
};

export default AuswahlFuttertyp;
