import {
    ArtikelTyp,
    Auftragsart,
    Auftragssstatus,
    Bestellprozesstyp,
    BestellungGesamtstatus,
    Fakturierungsstatus,
    Lieferbedingungen,
    Lieferstatus,
    LieferungLieferbedingung,
} from './enums';
import type {
    ArtikelLoseWare,
    ArtikelSackware,
    Kontrakt,
    KontraktDetails,
    Lieferung,
    Partner,
    PartnerMitAdminDaten,
    Rechnungsempfaenger,
    User,
    Warenempfaenger,
    Werk,
    UebersichtKontrakt,
} from './types';
import type { BestellungDetails } from './bestellung';

export const emptyWarenempfaenger: Warenempfaenger = {
    partnerNummer: '',
    hauptname: '',
    nebenname: '',
    strasse: '',
    postleitzahl: '',
    ort: '',
    namenszusatz: '',
    land: '',
    kommunikationsdaten: [],
    vvvoNummern: [],
};

export const emptyRechnungsempfaenger: Rechnungsempfaenger = {
    hauptname: '',
    nebenname: '',
    strasse: '',
    postleitzahl: '',
    ort: '',
    partnerNummer: '',
    eigenesErp: false,
    zulagenErfassungErlaubt: true,
};

export const emptyUser: User = {
    id: '',
    nachname: '',
    vorname: '',
    rolle: '',
    registration: {
        createdAt: '',
        lastModifiedAt: '',
        id: '',
        unternehmen: '',
        registrierenAls: '',
        strasse: '',
        hausnummer: '',
        postleitzahl: '',
        stadt: '',
        land: '',
        telefon: '',
        haendler: '',
        kundenNummern: '',
    },
    email: '',
    registrationValidated: false,
    partnerListe: [],
};

export const emptyBestellungDetails: BestellungDetails = {
    version: 0,
    bestellpositionen: [],
    bestellnummer: '',
    bestelldatum: '',
    warenempfaenger: emptyWarenempfaenger,
    rechnungsempfaenger: emptyRechnungsempfaenger,
    lieferbedingung: Lieferbedingungen.EX_WERK,
    ansprechpartner: '',
    bestellprozesstyp: Bestellprozesstyp.Nutztierfutter,
    wunschtermin: '',
    wunschterminWEVon: '',
    wunschterminWEBis: '',
    isDraft: false,
    debitorEigenesErp: false,
    geschlossen: false,
    status: BestellungGesamtstatus.NONE,
};

export const emptyLieferung: Lieferung = {
    werk: '',
    vertriebsauftragsnummer: '',
    vertriebsauftragsdatum: '',
    fremdbelegnummer: '',
    status: Auftragssstatus.InBearbeitung,
    fakturierungsstatus: Fakturierungsstatus.NichtFakturiert,
    lieferstatus: Lieferstatus.NichtGeliefert,
    lieferscheinnummer: '',
    lieferscheindatum: '',
    wunschtermin: '',
    auftragsart: Auftragsart.LoseWare,
    sonderherstellung: false,
    warenempfaenger: emptyWarenempfaenger,
    rechnungsempfaenger: emptyRechnungsempfaenger,
    lieferbedingung: LieferungLieferbedingung.AbWerk,
    bestellreferenzDebitor: '',
    positionen: [],
    vvvoNummer: '',
};

export const emptyArtikelLoseWare: ArtikelLoseWare = {
    werkId: '',
    nummer: '',
    bezeichnung: '',
    artikelTyp: ArtikelTyp.LoseWare,
    artikelGruppeBezeichnung: '',
    artikelGruppeId: '',
    preislistenKennzeichen: '',
    preislistenSequenz: '',
    markeBezeichnung: '',
};

export const emptyArtikelSackware: ArtikelSackware = {
    werkId: '',
    nummer: '',
    bezeichnung: '',
    faktorBasiseinheitVe: 1,
    faktorBasiseinheitPal: 1,
    artikelTyp: ArtikelTyp.Sackware,
    artikelGruppeBezeichnung: '',
    artikelGruppeId: '',
    preislistenKennzeichen: '',
    preislistenSequenz: '',
    markeBezeichnung: '',
    markeId: '',
};

export const emptyPartner: Partner = {
    hauptname: '',
    nebenname: '',
    partnerNummer: '',
    postleitzahl: '',
    ort: '',
    strasse: '',
};

export const emptyPartnerMitMarken: PartnerMitAdminDaten = {
    ...emptyPartner,
    nutztierfutterMarken: [],
    heimtierfutterMarken: [],
    interneArtikel: [],
    eigenesErp: false,
    zulagenErfassungErlaubt: true,
};

export const emptyWerk: Werk = {
    id: '',
    name: '',
};

export const emptyUebersichtKontrakt: UebersichtKontrakt = {
    kontraktNummer: '',
    kontraktpartnerName: '',
    kontraktpartnerNummer: '',
    warenempfaengerName: '',
    warenempfaengerNummer: '',
    abschlussmenge: 1,
    abschlussmengeEinheit: '',
    restmenge: 1,
    restmengeEinheit: '',
    lieferDatumAb: '',
    lieferDatumBis: '',
    preislistenArt: '',
    allWarenempfaenger: [],
    anzahlWarenempfaenger: 0,
    lastModifiedAt: '',
};

export const emptyKontrakt: Kontrakt = {
    kontraktNummer: '',
    abschlussmenge: 0,
    restmenge: 0,
    lieferDatumAb: '',
    lieferDatumBis: '',
    artikelnummer: '',
    werkId: '',
    kontraktpartnerName: '',
    kontraktpartnerNummer: '',
    lastModifiedAt: '',
};

export const emptyKontraktDetails: KontraktDetails = {
    kontraktNummer: '',
    abschlussmenge: 0,
    restmenge: 0,
    lieferDatumAb: '',
    lieferDatumBis: '',
    artikelnummer: '',
    werkId: '',
    kontraktpartnerName: '',
    kontraktpartnerNummer: '',
    warenempfaenger: [],
    kontraktpartner: emptyPartner,
    artikelList: [],
    abschlussmengeEinheit: '',
    restmengeEinheit: '',
    preislistenArt: '',
    lastModifiedAt: '',
};
