import styles from './Vorgangsansicht.module.scss';
import { ReactElement } from 'react';
import ButtonIcon from '../Button/ButtonIcon';
import { ReactComponent as DownloadIcon } from '../../../assets/icon-download.svg';
import { useDispatch } from 'react-redux';
import { dokumentDownload } from '../../../store/Dokument.store';
import { Dokument } from '../../types';
import ReactHtmlParser from 'react-html-parser';

export type Props = {
    date: string;
    time: string;
    reference: string;
    message: string;
    documents: Dokument[];
};

const Vorgangsansicht = (props: Props): ReactElement => {
    const dispatch = useDispatch();
    const dokumentList = props.documents.map((aktuellesDokument) => {
        return (
            <ButtonIcon
                key={aktuellesDokument.id}
                icon={<DownloadIcon title={'Dokument herunterladen'} stroke={'#6f6f6f'} fill={'#6f6f6f'} strokeWidth={1.5} />}
                onClick={() => dispatch(dokumentDownload({ dokumentId: aktuellesDokument.id }))}
            >
                <div>{aktuellesDokument.name}</div>
            </ButtonIcon>
        );
    });

    const timeDiv = props.time !== '00:00' ? <div className={styles._time}>{props.time}&nbsp;Uhr</div> : null;
    const referenceDiv = props.reference !== '' || null ? <div className={styles._reference}>{props.reference}</div> : null;
    const separator = referenceDiv ? '-' : null;
    const messageDiv = props.message !== '' || null ? <div className={styles._message}>{ReactHtmlParser(props.message)}</div> : null;

    return (
        <div className={styles._background}>
            <div className={styles._header}>
                <div className={styles._date}>{props.date}</div>
                {timeDiv}
                {separator}
                {referenceDiv}
            </div>
            {messageDiv}
            <div className={styles._documents}>{dokumentList}</div>
        </div>
    );
};

export default Vorgangsansicht;
