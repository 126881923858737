import { UseTableCellProps } from 'react-table';
import { PropsWithChildren } from 'react';
import tableStyles from '../Table.module.scss';

interface TableCellProps<T extends Record<string, unknown>> {
    cell: UseTableCellProps<T>;
    input?: boolean;
    select?: boolean;
    additionalClasses?: string[];
}

const TableCell = <T extends Record<string, unknown>>({
    cell,
    children,
    input = false,
    select = false,
    additionalClasses = [],
    ...additionalProps
}: PropsWithChildren<TableCellProps<T>>): JSX.Element => {
    const cellStyles = [
        ...additionalClasses,
        tableStyles._tableCell,
        input ? tableStyles['_tableCell--input'] : tableStyles['_tableCell--text'],
        select ? tableStyles['_tableCell--select'] : '',
    ].join(' ');

    return (
        <td {...cell.getCellProps()} {...additionalProps} className={cellStyles}>
            {children}
        </td>
    );
};

export default TableCell;
