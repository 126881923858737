import { log, logException } from './helper/logging';
import { Severity } from '@sentry/types';
import Config from '../shared/config';
import FlutterBridge from '../flutter/flutterBridge';

const { apiUrl, correlationId, isApp } = Config.getConfig();

export type DownloadConfig = {
    objectUrl: ReturnType<typeof window.URL.createObjectURL>;
    filename: string;
    originalUrl?: string;
};

function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

async function fetchRetry(url: string, options = {}, retries = 1, backoff = 1000): Promise<Response> {
    const retryCodes = [408];
    const response = await fetch(url, options);

    if (response.ok) return response;

    if (retries > 0 && retryCodes.includes(response.status)) {
        await delay(backoff);
        return fetchRetry(url, options, retries - 1, backoff * 2); /* 3 */
    } else {
        log('fetchRetry failed', Severity.Error, {
            statusText: response.statusText,
            status: response.status,
            url: response.url,
            redirected: response.redirected,
            type: response.type,
            headers: JSON.stringify(response.headers),
            retries: retries,
        });
        throw new Error(response.statusText);
    }
}

async function postData<T>(endpoint = '', data = {}, method = 'POST'): Promise<T> {
    // Default options are marked with *
    const options = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-Correlation-ID': correlationId,
            Authorization: 'Bearer ' + sessionStorage.getItem('authentication'),
            Accept: 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    };
    const response = await fetchRetry(apiUrl + endpoint, options);
    return response.json(); // parses JSON response into native JavaScript objects
}

async function getData<T>(endpoint = ''): Promise<T> {
    const [data] = await getDataWithResponseHeaders<T>(endpoint);
    return data;
}

async function getDataWithResponseHeaders<T>(endpoint = ''): Promise<[T, Headers]> {
    let response: Response | null = null;
    try {
        response = await fetch(apiUrl + endpoint, {
            mode: 'cors',
            credentials: 'include',
            headers: {
                'X-Correlation-ID': correlationId,
                Authorization: 'Bearer ' + sessionStorage.getItem('authentication'),
                Accept: 'application/json',
            },
        });
    } catch (error: unknown) {
        if (error instanceof Error) {
            logException(error, {
                method: 'getData',
                endpoint: endpoint,
            });
        }
        throw new Error();
    }
    if (!response.ok) {
        log('getData failed', Severity.Error, {
            statusText: response.statusText,
            status: response.status,
            url: response.url,
            redirected: response.redirected,
            type: response.type,
            headers: JSON.stringify(response.headers),
        });
        throw new Error(response.statusText);
    }
    return [await response.json(), response.headers];
}

async function getDataAsBlob(endpoint = ''): Promise<DownloadConfig> {
    try {
        const downloadLink = apiUrl + endpoint;
        const response = await fetch(downloadLink, {
            mode: 'cors',
            credentials: 'include',
            headers: {
                'X-Correlation-ID': correlationId,
                Authorization: `Bearer ${sessionStorage.getItem('authentication')}`,
                Accept: 'application/octet-stream',
            },
        });
        const filename = response.headers.get('Content-Disposition')?.split('inline; filename=')[1] ?? 'unknown.txt';
        const blob = await response.blob();
        return { objectUrl: window.URL.createObjectURL(blob), filename, originalUrl: downloadLink };
    } catch (error: unknown) {
        if (error instanceof Error) {
            logException(error, {
                method: 'getData',
                endpoint: endpoint,
            });
        }
        throw new Error();
    }
}

export function triggerDownload(config: DownloadConfig): void {
    if (isApp()) {
        FlutterBridge.sendDownloadLink(config);
    } else {
        const tempAnchorTag = document.createElement('a');
        tempAnchorTag.style.display = 'none';
        tempAnchorTag.href = config.objectUrl;
        tempAnchorTag.setAttribute('download', config.filename);
        tempAnchorTag.setAttribute('data-testid', `download-button`);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempAnchorTag.download === 'undefined') {
            tempAnchorTag.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempAnchorTag);
        tempAnchorTag.click();

        // Fixes "webkit blob resource error 1"
        setTimeout((): void => {
            document.body.removeChild(tempAnchorTag);
            window.URL.revokeObjectURL(config.objectUrl);
        }, 50);
    }
}

export { getData, getDataWithResponseHeaders, getDataAsBlob, postData, fetchRetry };
