import iconStorniert from '../../assets/icon-bestellung-storniert.svg';
import iconEingangDebitor from '../../assets/icon-bestellung-eingang-debitor.svg';
import iconEingangDTC from '../../assets/icon-bestellung-eingang-dtc.svg';
import { extractDateAndTimeFromDateString, getISOWunschtermin } from './date-helper';
import { ArtikelTyp, Berechnungsart, BestellungGesamtstatus } from '../types/enums';
import { emptyArtikelLoseWare, emptyArtikelSackware } from '../types/defaultValues';
import type { TourenplanForm, TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from '../../views/Tourenplan/TourenplanTypes';
import { NEWID_PREFIX } from '../constants';
import type {
    ArtikelLoseWare,
    ArtikelSackware,
    BaseBestellposition,
    BestellpositionArtikelLoseWare,
    BestellpositionArtikelLoseWareView,
    BestellpositionArtikelSackware,
    BestellpositionArtikelSackwareView,
    BestellpositionDetails,
    BestellpositionDetailsLoseWare,
    BestellpositionDetailsSackware,
    BestellpositionView,
    BestellungDetails,
} from '../types';
import dayjs from 'dayjs';

export const isStornierbarePosition = (bestellposition: BestellpositionView | Partial<BestellpositionView>): boolean => {
    return typeof bestellposition.storno !== 'undefined' && !bestellposition.storno;
};

export const isExistingBestellposition = (bestellposition: BestellpositionView | Partial<BestellpositionView>): boolean => {
    return typeof bestellposition.storno !== 'undefined' && typeof bestellposition.positionsnummer !== 'undefined';
};

export const isLoseWare = (
    bestellposition: BestellpositionView | Partial<BestellpositionView>
): bestellposition is BestellpositionArtikelLoseWareView => {
    return bestellposition.artikelTyp === ArtikelTyp.LoseWare;
};

export const isLoseWareDetails = (bestellposition: BestellpositionDetails): bestellposition is BestellpositionDetailsLoseWare => {
    return bestellposition.artikelTyp === ArtikelTyp.LoseWare;
};

export const isSackwareDetails = (bestellposition: BestellpositionDetails): bestellposition is BestellpositionDetailsSackware => {
    return bestellposition.artikelTyp === ArtikelTyp.Sackware;
};

export const isSackwareView = (
    bestellposition: BestellpositionView | Partial<BestellpositionView>
): bestellposition is BestellpositionArtikelSackwareView => {
    return bestellposition.artikelTyp === ArtikelTyp.Sackware;
};

export const isSackware = (bestellposition: BaseBestellposition): bestellposition is BestellpositionArtikelSackware => {
    return bestellposition.artikelTyp === ArtikelTyp.Sackware;
};

export const hasAenderbarBisExpiredForBestellposition = (bestellposition: BestellpositionArtikelLoseWareView): boolean => {
    return dayjs(bestellposition.aenderbarBis).isBefore(dayjs());
};

export const loseWareMengePattern = /^([1-9][0-9]*(,\d)?|0,\d)$/;
export const sackwareMengePattern = /^\d*$/;

export const convertLoseWareMengeToNumber = (menge: string): number => {
    if (menge === '') {
        return 0;
    }

    const mengeAsNumber = Number(menge.replace(',', '.'));
    if (isNaN(mengeAsNumber)) {
        throw new Error(`Provided lose Ware menge string ${menge} cannot be parsed`);
    }
    return mengeAsNumber;
};

export const convertSackwareMengeToNumber = (menge: string): number => {
    if (menge === '') {
        return 0;
    }

    const mengeAsNumber = Number(menge);
    if (isNaN(mengeAsNumber)) {
        throw new Error(`Provided Sackware menge string ${menge} cannot be parsed`);
    }
    return mengeAsNumber;
};

export const convertMengeToString = (menge: number | string): string => {
    if (!menge) {
        return '';
    }

    return menge.toString().replace('.', ',');
};

export const addIndexToBestellposition = (bestellposition: BestellpositionView, index: number): BestellpositionView => {
    return {
        ...bestellposition,
        index,
    };
};

export const bestellpositionenViewFromBestellpositionenAndArtikeln = (
    bestellpositionen: BaseBestellposition[],
    artikelSackwareList: ArtikelSackware[],
    artikelLoseWareList: ArtikelLoseWare[]
): BestellpositionView[] => {
    return bestellpositionen.map((bp, index) => {
        if (isSackwareView(bp)) {
            const artikel = artikelSackwareList.find((a) => bp.artikelNummer === a.nummer) || emptyArtikelSackware;
            return {
                ...(artikel || emptyArtikelSackware),
                mengeVe: bp ? bp.mengeVe : 0,
                mengePal: bp ? bp.mengePal : 0,
                silo: bp ? bp.silo : 0,
                werkId: bp.werkId || '',
                aufPalRunden: false,
                lagerKapazitaetVE: undefined,
                bestandVE: undefined,
                index,
            };
        }
        const artikel = artikelLoseWareList.find((a) => bp.artikelNummer === a.nummer) || emptyArtikelLoseWare;
        const bpLw = bp as BestellpositionArtikelLoseWare;
        const isNewArtikel = bpLw.artikelNummer.startsWith(NEWID_PREFIX);
        return {
            ...(artikel || emptyArtikelLoseWare),
            bezeichnung: isNewArtikel && bpLw.bezeichnung ? bpLw.bezeichnung : artikel.bezeichnung,
            nummer: isNewArtikel ? bpLw.artikelNummer : artikel.nummer,
            menge: bp ? bpLw.menge.toString().replace('.', ',') : '0',
            werkId: bp.werkId || '',
            silo: bpLw.silo || '',
            zulage: bpLw.zulage || false,
            zulageText: bpLw.zulageText || '',
            berechnungsart: bpLw.berechnungsart || Berechnungsart.KeinVorgabe,
            kontrakt: bpLw.kontrakt,
            index,
        };
    });
};

export const bestellpositionenViewFromBestellpositionenDetailsAndArtikeln = (
    bestellpositionen: BestellpositionDetails[],
    artikelSackwareList: ArtikelSackware[],
    artikelLoseWareList: ArtikelLoseWare[]
): BestellpositionView[] => {
    return bestellpositionen.map((position, index) => {
        if (isLoseWareDetails(position)) {
            const dateAndTimeFromDateString = extractDateAndTimeFromDateString(position.wunschtermin);
            const artikel = artikelLoseWareList.find((a) => position.nummer === a.nummer) || emptyArtikelLoseWare;
            const isNewArtikel = position.nummer.startsWith(NEWID_PREFIX);
            const bestellpositionLoseWareView: BestellpositionArtikelLoseWareView = {
                ...artikel,
                index: index,
                bezeichnung: isNewArtikel && position.bezeichnung ? position.bezeichnung : artikel.bezeichnung,
                nummer: isNewArtikel ? position.nummer : artikel.nummer,
                positionsnummer: position.positionsnummer,
                menge: position.menge.toString().replace('.', ',') || '0',
                werkId: position.werkId || '',
                silo: position.silo || '',
                zulage: position.zulage || false,
                zulageText: position.zulageText || '',
                berechnungsart: position.berechnungsart || Berechnungsart.KeinVorgabe,
                kontrakt: position.kontrakt,
                storno: position.storno,
                aenderbarBis: position.aenderbarBis,
                wunschtermin: position.wunschtermin,
                debitorDatum: dateAndTimeFromDateString?.datum,
                debitorUhrzeit: dateAndTimeFromDateString?.uhrzeit,
            };
            return bestellpositionLoseWareView;
        }

        const artikel = artikelSackwareList.find((a) => position.nummer === a.nummer) || emptyArtikelSackware;
        const bestellpositionSackwareView: BestellpositionArtikelSackwareView = {
            ...artikel,
            index: index,
            positionsnummer: position.positionsnummer,
            mengeVe: position.mengeVe,
            mengePal: position.mengePal,
            silo: position.silo,
            werkId: position.werkId,
            aufPalRunden: false,
            lagerKapazitaetVE: undefined,
            bestandVE: undefined,
            storno: position.storno,
        };
        return bestellpositionSackwareView;
    });
};

export const onlyBestellpositionenWithMenge = (bestellPosition: Partial<BestellpositionView>): boolean => {
    if (bestellPosition.artikelTyp === ArtikelTyp.Sackware) {
        const bvSw = bestellPosition as BestellpositionArtikelSackwareView;
        return bvSw.mengeVe > 0 || bvSw.mengePal > 0;
    } else {
        const bvLW = bestellPosition as BestellpositionArtikelLoseWareView;
        const menge = bvLW.menge || '0';
        return parseFloat(menge.toString().replace(',', '.')) > 0;
    }
};

export const bestellpositionDetailsToBestellpositionView = (bestellpositionen: BestellpositionDetails[]): BestellpositionView[] => {
    return bestellpositionen.map((bestellposition, index) => {
        if (isSackwareDetails(bestellposition)) {
            return {
                ...bestellposition,
                bezeichnung: bestellposition.bezeichnung ?? '',
                aufPalRunden: false,
                lagerKapazitaetVE: undefined,
                bestandVE: undefined,
                index,
            };
        }
        return {
            ...bestellposition,
            bezeichnung: bestellposition?.bezeichnung ?? '',
            index,
        };
    });
};

export const mapBestellpositionenDetailsToBestellpositionen = (
    bestellposition: BestellpositionDetails
): BestellpositionArtikelSackware | BestellpositionArtikelLoseWare => {
    if (isSackwareDetails(bestellposition)) {
        return {
            ...bestellposition,
            artikelNummer: bestellposition.nummer,
        };
    }
    const bezeichnung = bestellposition.nummer.startsWith(NEWID_PREFIX) ? { bezeichnung: bestellposition.bezeichnung } : {};
    return {
        ...bestellposition,
        ...bezeichnung,
        artikelNummer: bestellposition.nummer,
        zulageText: bestellposition.zulage ? bestellposition.zulageText : '',
        kontrakt: bestellposition.berechnungsart === Berechnungsart.Kontrakt ? bestellposition.kontrakt : undefined,
    };
};

export const mapBestellpositionenViewToBestellpositionen = (
    bestellposition: BestellpositionView
): BestellpositionArtikelSackware | BestellpositionArtikelLoseWare => {
    if (isSackwareView(bestellposition)) {
        return {
            positionsnummer: bestellposition.positionsnummer,
            artikelNummer: bestellposition.nummer,
            artikelTyp: ArtikelTyp.Sackware,
            mengeVe: typeof bestellposition.mengeVe === 'string' ? 0 : bestellposition.mengeVe,
            mengePal: typeof bestellposition.mengePal === 'string' ? 0 : bestellposition.mengePal,
            silo: typeof bestellposition.silo === 'string' ? 0 : bestellposition.silo,
            werkId: bestellposition.werkId,
            storno: bestellposition.storno,
            index: bestellposition.index,
        };
    }
    const bezeichnung = bestellposition.nummer.startsWith(NEWID_PREFIX) ? { bezeichnung: bestellposition.bezeichnung } : {};
    return {
        ...bezeichnung,
        positionsnummer: bestellposition.positionsnummer,
        artikelNummer: bestellposition.nummer,
        artikelTyp: ArtikelTyp.LoseWare,
        menge: parseFloat(bestellposition.menge.toString().replace(',', '.')),
        werkId: bestellposition.werkId,
        storno: bestellposition.storno,
        silo: bestellposition.silo,
        zulage: bestellposition.zulage,
        zulageText: bestellposition.zulage ? bestellposition.zulageText : '',
        berechnungsart: bestellposition.berechnungsart,
        kontrakt: bestellposition.berechnungsart === Berechnungsart.Kontrakt ? bestellposition.kontrakt : undefined,
        index: bestellposition.index,
        wunschtermin: bestellposition.wunschtermin,
    };
};

export const mapDateAndTimeToWunschterminForPosition = (bestellposition: BestellpositionView): BestellpositionView => {
    bestellposition.wunschtermin = getISOWunschtermin(bestellposition.debitorDatum, bestellposition.debitorUhrzeit);
    return bestellposition;
};

export const mengeStringFromBestellpositionView = (bestellposition: BestellpositionDetails): string => {
    if (isSackwareDetails(bestellposition)) {
        const mengePal = bestellposition.mengePal && bestellposition.mengePal !== 0 ? `${bestellposition.mengePal} Pal` : '';
        const mengeVe = bestellposition.mengeVe && bestellposition.mengeVe !== 0 ? `${bestellposition.mengeVe} Ve` : '';
        return [mengePal, mengeVe].filter(Boolean).join(', ');
    }
    return bestellposition.menge ? `${convertMengeToString(bestellposition.menge)} t` : '';
};

export const statusToIcon = (status: BestellungGesamtstatus): string => {
    switch (status) {
        case BestellungGesamtstatus.STORNIERT_DURCH_LANDWARENHANDEL:
            return iconStorniert;
        case BestellungGesamtstatus.STORNIERT_DURCH_WARENEMPFAENGER:
            return iconStorniert;
        case BestellungGesamtstatus.LANDWARENHANDEL:
            return iconEingangDebitor;
        case BestellungGesamtstatus.DTC:
            return iconEingangDTC;
        case BestellungGesamtstatus.AENDERUNGSWUNSCH:
            return iconEingangDebitor;
        default:
            return 'X';
    }
};

export const statusToText = (status: BestellungGesamtstatus): string => {
    switch (status) {
        case BestellungGesamtstatus.STORNIERT_DURCH_LANDWARENHANDEL:
            return 'Storniert durch Landwarenhandel';
        case BestellungGesamtstatus.STORNIERT_DURCH_WARENEMPFAENGER:
            return 'Storniert durch Warenempfänger';
        case BestellungGesamtstatus.LANDWARENHANDEL:
            return 'Beim Landwarenhandel eingegangen';
        case BestellungGesamtstatus.DTC:
            return 'Bei der Deutschen Tiernahrung Cremer eingegangen';
        case BestellungGesamtstatus.AENDERUNGSWUNSCH:
            return 'Änderungswunsch an DTC übermittelt';
        case BestellungGesamtstatus.NONE:
            return '';
    }
};

const createFormInputsMap = <T extends TourenplanLoseWareBestellung | TourenplanSackwareBestellung>(
    tourenplanBestellungen: T[],
    bestellnummer: string
): Map<string, T> => {
    const formInputsMap = new Map<string, T>();
    tourenplanBestellungen
        ?.filter((bestellungInForm) => bestellungInForm.bestellnummer === bestellnummer)
        .forEach((currentBestellungInForm) => {
            formInputsMap.set(`${bestellnummer}-${currentBestellungInForm.nummer}`, currentBestellungInForm);
        });
    return formInputsMap;
};

const getFormInputFromMap = <T extends TourenplanLoseWareBestellung | TourenplanSackwareBestellung>(
    map: Map<string, T>,
    bestellnummer: string,
    artikelnummer: string
): T => {
    const key = `${bestellnummer}-${artikelnummer}`;
    const value = map.get(key);
    if (value) {
        return value;
    } else {
        throw new Error(`form values missing: Bestellnummer: ${bestellnummer}, Artikelnummer: ${artikelnummer}`);
    }
};

export const mapBestellungenToTourenplanLoseWareBestellungen = (bestellungen: BestellungDetails[]): TourenplanLoseWareBestellung[] => {
    return bestellungen.flatMap((bestellung) => {
        return bestellung.bestellpositionen.filter(isLoseWareDetails).map((position) => {
            const { menge, nummer, bezeichnung, silo, werk, werkId, zulage, zulageText } = position;
            const { bestellnummer, lieferhinweis, warenempfaenger, rechnungsempfaenger } = bestellung;
            const tourenplanBestellung: TourenplanLoseWareBestellung = {
                bestellnummer,
                lieferhinweis,
                warenempfaenger,
                menge: convertMengeToString(menge),
                nummer,
                bezeichnung,
                partnerNummer: rechnungsempfaenger.partnerNummer,
                silo,
                werk,
                werkId,
                zulage,
                zulageText,
                kesselverteilung: [],
            };

            return tourenplanBestellung;
        });
    });
};

export const mapBestellungenToTourenplanSackwareBestellungen = (bestellungen: BestellungDetails[]): TourenplanSackwareBestellung[] => {
    return bestellungen.flatMap((bestellung) => {
        return bestellung.bestellpositionen.filter(isSackwareDetails).map((position) => {
            const { mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe, nummer, bezeichnung, werk, werkId } = position;
            const { bestellnummer, lieferhinweis, warenempfaenger, rechnungsempfaenger } = bestellung;

            const tourenplanBestellung: TourenplanSackwareBestellung = {
                bestellnummer,
                lieferhinweis,
                warenempfaenger,
                mengeVe: convertMengeToString(mengeVe),
                mengePal: convertMengeToString(mengePal),
                faktorBasiseinheitPal,
                faktorBasiseinheitVe,
                partnerNummer: rechnungsempfaenger.partnerNummer,
                nummer,
                bezeichnung,
                werk,
                werkId,
            };

            return tourenplanBestellung;
        });
    });
};

export const mergeTourenplanFormIntoBestellungen = (
    tourenplanFormular: TourenplanForm,
    currentSammelbestellung: BestellungDetails[]
): BestellungDetails[] => {
    const formularDatum = tourenplanFormular.datumDebitor;
    const formularUhrzeit = tourenplanFormular.uhrzeitDebitor;
    const wunschtermin = getISOWunschtermin(formularDatum, formularUhrzeit) ?? '';
    const formularKfzKennzeichen = tourenplanFormular.kfzKennzeichen;
    const formularSpedition = tourenplanFormular.spedition;
    const formularLieferbedingung = tourenplanFormular.lieferbedingung;

    return currentSammelbestellung.map((bestellung) => {
        const { bestellnummer, bestellpositionen } = bestellung;
        const loseWareFormInputs = createFormInputsMap(tourenplanFormular.loseWareBestellungen, bestellnummer);
        const sackwareFormInputs = createFormInputsMap(tourenplanFormular.sackwareBestellungen, bestellnummer);
        const updatedBestellpositionen: BestellpositionDetails[] = bestellpositionen.map((bestellposition) => {
            const { nummer: artikelnummer } = bestellposition;

            if (isLoseWareDetails(bestellposition)) {
                const { menge, silo, werkId, werk } = getFormInputFromMap(loseWareFormInputs, bestellnummer, artikelnummer);
                return {
                    ...bestellposition,
                    werkId,
                    werk,
                    menge: convertLoseWareMengeToNumber(menge),
                    silo,
                };
            } else {
                const { mengePal, mengeVe, werkId, werk } = getFormInputFromMap(sackwareFormInputs, bestellnummer, artikelnummer);
                return {
                    ...bestellposition,
                    mengePal: convertSackwareMengeToNumber(mengePal),
                    mengeVe: convertSackwareMengeToNumber(mengeVe),
                    werkId,
                    werk,
                };
            }
        });

        return {
            ...bestellung,
            wunschtermin,
            kfzKennzeichen: formularKfzKennzeichen,
            spedition: formularSpedition,
            lieferbedingung: formularLieferbedingung,
            bestellpositionen: updatedBestellpositionen,
        };
    });
};
