import { useDispatch, useSelector } from 'react-redux';
import { selectIsDebitor } from '../../store/Navigation.store';
import { getSpeditionen, speditionSelectors } from '../../store/Spedition.store';
import { useEffect } from 'react';

export const useFetchSpedition = (): void => {
    const dispatch = useDispatch();
    const isDebitor = useSelector(selectIsDebitor);
    const loaded = useSelector(speditionSelectors.loaded);

    useEffect(() => {
        if (isDebitor && !loaded) {
            dispatch(getSpeditionen());
        }
    }, [isDebitor, dispatch, loaded]);
};
