import type { KontraktDetails, UebersichtKontrakt } from '../shared/types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    GetKontraktAction,
    GetKontrakteAction,
    getKontrakteFailed,
    getKontrakteSucceeded,
    getKontraktFailed,
    getKontraktSucceeded,
} from '../store/Kontraktuebersicht.store';
import { call, put, SagaGenerator } from 'typed-redux-saga';
import { CallEffect, PutEffect } from 'redux-saga/effects';
import { getData } from '../shared/fetchApi';
import { convertFilterToString } from './utils/sagaHelperFunctions';

type GetKontrakte = (url: string) => Promise<UebersichtKontrakt[]>;
type GetKontrakt = (url: string) => Promise<KontraktDetails>;

export function* getKontrakteSaga(action: PayloadAction<GetKontrakteAction>): SagaGenerator<void, PutEffect | CallEffect> {
    try {
        const filter = convertFilterToString(action.payload.filter);

        const kontrakte = yield* call<[string], GetKontrakte>(getData, `kontrakte/kontrakte?${filter}`);
        if (!kontrakte) {
            yield* put(getKontrakteFailed());
        } else {
            yield* put(
                getKontrakteSucceeded({
                    kontrakte,
                })
            );
        }
    } catch (errors: unknown) {
        yield* put(getKontrakteFailed());
    }
}

export function* getKontraktSaga(action: PayloadAction<GetKontraktAction>): SagaGenerator<void, PutEffect | CallEffect> {
    try {
        const kontrakt = yield* call<[string], GetKontrakt>(getData, `kontrakte/${action.payload.kontraktNummer}`);

        yield* put(getKontraktSucceeded(kontrakt));
    } catch (errors: unknown) {
        yield* put(getKontraktFailed());
    }
}
