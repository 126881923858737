import tableStyles from '../Table.module.scss';
import { HeaderGroup, TableInstance } from 'react-table';

interface TableHeaderRow<T extends Record<string, unknown>> extends TableInstance<T> {
    headerGroup: HeaderGroup<T>;
    showSortingIcon?: boolean;
}

const TableHeaderRow = <T extends Record<string, unknown>>({ headerGroup, showSortingIcon = false }: TableHeaderRow<T>): JSX.Element => {
    const headers = headerGroup.headers;
    const headerGroupProps = headerGroup.getHeaderGroupProps();
    return (
        <tr {...headerGroupProps} className={tableStyles._overviewHeaderRow}>
            {headers.map((column, index) => {
                const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                return (
                    <th {...headerProps} className={tableStyles['_tableCell--text']} key={index}>
                        {column.render('Header')}
                        {showSortingIcon && column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                    </th>
                );
            })}
        </tr>
    );
};

export default TableHeaderRow;
