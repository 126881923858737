import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, useFormikContext } from 'formik';
import { BestellungSelectors, selectBestelldaten, selectVvvoNummer, updateLieferbedingung } from '../../store/Bestellung.store';
import { kfzKennzeichenSelectors } from '../../store/KfzKennzeichen.store';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import {
    selectIsHeimtierfutter,
    selectIsNutztierfutter,
    selectNextRoute,
    setCurrentWorkflowStep,
    WorkflowStep,
} from '../../store/Workflow.store';
import { getWerke } from '../../store/Abholwerk.store';
import { getWarenempfaenger } from '../../store/Warenempfaenger.store';
import MandatoryFormFieldHint from '../../shared/ui-components/Form/MandatoryFormFieldHint';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { useAppSelector } from '../../appSelector';
import { useSaveBestelldaten } from './useSaveBestelldaten';
import { MissingVvvoNumberNotification } from './MissingVvvoNumberNotification';
import { BestelldatenWorkflowButtons } from './BestelldatenWorkflowButtons';
import { FormikLieferbedingungenSelect } from './FormikLieferbedingungenSelect';
import { FormikAnsprechpartnerInput } from './FormikAnsprechpartnerInput';
import { BestelldatenKennzeichenAuswahl } from './Debitor/BestelldatenKennzeichenAuswahl';
import { BestelldatenHint } from './BestelldatenHint';
import { useBestelldatenSchema } from './useBestelldatenSchema';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import { selectIsWarenempfaenger } from '../../store/Navigation.store';
import { FormValidationError } from '../../shared/ui-components/Form/FormValidationError';
import { createWunschterminLabelByLieferbedingung } from '../../shared/content-components/Wunschtermin/WunschterminTageszeit';
import { Bestellprozesstyp, Lieferbedingungen } from '../../shared/types/enums';
import VvvoNummerAuswahl from '../../shared/content-components/VvvoNummerAuswahl/VvvoNummerAuswahl';
import { Wunschtermin } from '../../shared/content-components/Wunschtermin/Wunschtermin';
import { WunschterminMode } from '../../shared/content-components/Wunschtermin/wunschtermin-helper';
import { BestellprozessGuard } from '../../shared/guards/BestellprozessGuard';
import type { BestelldatenFormular } from '../../shared/types';
import { RechnungsempfaengerErrorNotification } from '../../shared/content-components/Notifications/RechnungsempfaengerErrorNotification';
import 'tippy.js/dist/tippy.css';
import { FormikBestellhinweisInput } from '../../shared/content-components/Form/FormikBestellhinweisInput';
import { FormikLieferhinweisInput } from '../../shared/content-components/Form/FormikLieferhinweisInput';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';
import styles from './BestelldatenView.module.scss';
import { lieferbedingungOptions, Option } from '../../shared/helper/select-options';
import SpeditionAuswahl from 'shared/content-components/SpeditionAuswahl/SpeditionAuswahl';
import { speditionSelectors, addSpedition, TEMPORARY_SPEDITION_ID } from '../../store/Spedition.store';
import Button from '../../shared/ui-components/Button/Button';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import { useFetchSpedition } from 'shared/hooks/useFetchSpedition';

const BestelldatenView = (): JSX.Element => {
    useDocumentTitle('Bestelldaten');
    const history = useHistory();
    const dispatch = useDispatch();
    const { saveViaSubmit } = useSaveBestelldaten();
    const bestelldatenValidationSchema = useBestelldatenSchema();

    const nextRoute = useSelector(selectNextRoute);
    const isWarenempfaenger = useSelector(selectIsWarenempfaenger);
    const isNutztierfutter = useSelector(selectIsNutztierfutter);
    const isHeimtierfutter = useSelector(selectIsHeimtierfutter);
    const rechnungsempfaengerPartnerNummer = useSelector(BestellungSelectors.rechnungsempfaengerPartnerNummer);
    const hasKfzServerError = useSelector(kfzKennzeichenSelectors.hasSubmitError);
    const hasSpeditionServerError = useSelector(speditionSelectors.hasSubmitError);
    const bestelldaten = useSelector(selectBestelldaten);

    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [missingVvvoConfirmed, setMissingVvvoConfirmed] = useState(false);

    const vvvoNummer = useSelector(selectVvvoNummer);

    useEffect(() => {
        dispatch(setCurrentWorkflowStep(WorkflowStep.Bestelldaten, history));
        dispatch(getWarenempfaenger());
        dispatch(updateLieferbedingung());
    }, [dispatch, history]);

    useEffect(() => {
        if (rechnungsempfaengerPartnerNummer && isHeimtierfutter) {
            dispatch(getWerke(rechnungsempfaengerPartnerNummer));
        }
    }, [dispatch, isHeimtierfutter, rechnungsempfaengerPartnerNummer]);

    const onSubmit = (bestelldaten: BestelldatenFormular): void => {
        saveViaSubmit(bestelldaten);
        if (!Boolean(vvvoNummer) && !missingVvvoConfirmed && isNutztierfutter) {
            return;
        }
        history.push(nextRoute);
    };

    if (!rechnungsempfaengerPartnerNummer) {
        return <RechnungsempfaengerErrorNotification />;
    }

    return (
        <Formik
            initialValues={bestelldaten}
            onSubmit={onSubmit}
            validationSchema={bestelldatenValidationSchema}
            enableReinitialize
            validateOnChange
        >
            <Form>
                <h2>Weitere Angaben</h2>
                <NotificationBar
                    testId="server-validation-message-bar"
                    message="Beim Speichern des KFZ-Kennzeichens ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
                    isVisible={hasKfzServerError}
                />
                <NotificationBar
                    testId="server-validation-message-bar"
                    message="Beim Speichern der Spedition ist ein Fehler aufgetreten. Bitte geben Sie eine E-Mailadresse ein."
                    isVisible={hasSpeditionServerError}
                />
                <MissingVvvoNumberNotification
                    triedToSubmit={triedToSubmit}
                    missingVvvoConfirmed={missingVvvoConfirmed}
                    setMissingVvvoConfirmed={setMissingVvvoConfirmed}
                />
                <FormValidationError />
                {isWarenempfaenger ? (
                    <WarenempfaengerFormular setTriedToSubmit={setTriedToSubmit} />
                ) : (
                    <DebitorFormular setTriedToSubmit={setTriedToSubmit} />
                )}
            </Form>
        </Formik>
    );
};

const DebitorFormular = ({ setTriedToSubmit }: { setTriedToSubmit: Dispatch<SetStateAction<boolean>> }): JSX.Element => {
    const { values } = useFormikContext<BestelldatenFormular>();
    const { manualSave } = useSaveBestelldaten();
    const dispatch = useDispatch();

    const selectedLieferbedingung: Option | undefined = lieferbedingungOptions.find((option) => option.value === values.lieferbedingung);
    const shouldDisplaySpeditionAuswahl = selectedLieferbedingung && selectedLieferbedingung.value !== Lieferbedingungen.FRANCO;
    const speditionOptions: Option[] = useSelector(speditionSelectors.allAsOptions);
    const [hasInvalidEmailError, setHasInvalidEmailError] = useState(false);
    const history = useHistory();
    const navigateToKfzKennzeichen = (): void => {
        manualSave();
        history.push('/kfz-kennzeichen-und-speditionen');
    };

    function isValidEmail(email: string): boolean {
        return /\S+@\S+\.\S+/.test(email);
    }

    useFetchSpedition();

    const getEmailById = (speditionId: string): string => {
        const selectedSpedition = speditionOptions.find((spedition) => spedition.value === speditionId);
        return selectedSpedition ? (typeof selectedSpedition.label === 'string' ? selectedSpedition.label : '') : '';
    };

    return (
        <>
            <FormRow>
                <FormColumn>
                    <FormikLieferbedingungenSelect />
                </FormColumn>
                <FormColumn>
                    <FormikAnsprechpartnerInput />
                </FormColumn>
            </FormRow>
            <FormRow>
                <CommonRowContent />
            </FormRow>
            <FormRow>
                <FormColumn>
                    <BestelldatenKennzeichenAuswahl />
                    {shouldDisplaySpeditionAuswahl && (
                        <div className={formStyles._selectWithButtonContainer}>
                            {/* Speditionsauswahl */}
                            <SpeditionAuswahl
                                spedition={speditionOptions}
                                onSpeditionCreated={(spedition): void => {
                                    if (spedition.trim() !== '') {
                                        if (!isValidEmail(spedition)) {
                                            setHasInvalidEmailError(true);
                                            return;
                                        } else {
                                            setHasInvalidEmailError(false);
                                        }
                                    }
                                    dispatch(
                                        addSpedition({
                                            id: TEMPORARY_SPEDITION_ID,
                                            email: spedition,
                                        })
                                    );
                                }}
                                onSpeditionSelected={(spedition): void => {
                                    if (spedition.trim() !== '') {
                                        if (!isValidEmail(getEmailById(spedition))) {
                                            setHasInvalidEmailError(true);
                                            return;
                                        } else {
                                            setHasInvalidEmailError(false);
                                        }
                                    }
                                }}
                            />
                            <div className={formStyles._selectEdit}>
                                <Button type="button" onClick={navigateToKfzKennzeichen}>
                                    ...
                                </Button>
                            </div>
                        </div>
                    )}

                    <NotificationBar
                        testId="invalid-email-message-bar"
                        message="Bitte geben Sie eine gültige Mailadresse ein."
                        isVisible={hasInvalidEmailError}
                    />
                </FormColumn>
                <FormColumn>
                    <BestellprozessGuard prozesstyp={Bestellprozesstyp.Heimtierfutter}>
                        <Wunschtermin
                            mode={WunschterminMode.TAGESZEIT}
                            label={createWunschterminLabelByLieferbedingung(values.lieferbedingung)}
                        />
                    </BestellprozessGuard>
                    <BestellprozessGuard prozesstyp={Bestellprozesstyp.Nutztierfutter}>
                        <VvvoNummerAuswahl onVvvoCreated={manualSave} onVvvoNummerSelected={manualSave} />
                    </BestellprozessGuard>
                </FormColumn>
            </FormRow>

            <div style={{ marginTop: '50px' }}>
                <BestelldatenWorkflowButtons setTriedToSubmit={setTriedToSubmit} />
            </div>
        </>
    );
};

const WarenempfaengerFormular = ({ setTriedToSubmit }: { setTriedToSubmit: Dispatch<SetStateAction<boolean>> }): JSX.Element => {
    const { values } = useFormikContext<BestelldatenFormular>();
    const { manualSave } = useSaveBestelldaten();

    return (
        <>
            <FormRow>
                <BestellprozessGuard prozesstyp={Bestellprozesstyp.Heimtierfutter}>
                    <FormColumn>
                        <Wunschtermin label={createWunschterminLabelByLieferbedingung(values.lieferbedingung)} />
                    </FormColumn>
                </BestellprozessGuard>
                <BestellprozessGuard prozesstyp={Bestellprozesstyp.Nutztierfutter}>
                    <FormColumn>
                        <VvvoNummerAuswahl onVvvoCreated={manualSave} onVvvoNummerSelected={manualSave} />
                    </FormColumn>
                    <FormColumn />
                </BestellprozessGuard>
            </FormRow>
            <FormRow>
                <CommonRowContent />
            </FormRow>
            <FormRow>
                <FormColumn>
                    <BestellprozessGuard prozesstyp={Bestellprozesstyp.Heimtierfutter}>
                        <MandatoryFormFieldHint />
                    </BestellprozessGuard>
                </FormColumn>
                <FormColumn>
                    <BestelldatenWorkflowButtons setTriedToSubmit={setTriedToSubmit} />
                </FormColumn>
            </FormRow>
        </>
    );
};

const CommonRowContent = (): ReactElement => {
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const isWarenempfaenger = useAppSelector(selectIsWarenempfaenger);
    const bestellhinweisText = isWarenempfaenger
        ? 'Hinweis an den Bearbeiter bei Ihrem Landwarenhandel.'
        : 'Hinweis an den Bearbeiter bei der Deutschen Tiernahrung Cremer.';
    const lieferhinweisText = 'Hinweis an den Fahrer. Wird auf den Lieferschein gedruckt.';

    return (
        <>
            <BestelldatenHint text={bestellhinweisText} disabled={isMobile}>
                <FormColumn>
                    <FormikBestellhinweisInput>
                        {isMobile ? <span className={styles._subInfo}>{bestellhinweisText}</span> : null}
                    </FormikBestellhinweisInput>
                </FormColumn>
            </BestelldatenHint>
            <BestelldatenHint text={lieferhinweisText} disabled={isMobile}>
                <FormColumn>
                    <FormikLieferhinweisInput>
                        {isMobile ? <span className={styles._subInfo}>{lieferhinweisText}</span> : null}
                    </FormikLieferhinweisInput>
                </FormColumn>
            </BestelldatenHint>
        </>
    );
};

export default BestelldatenView;
