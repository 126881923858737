import { useEffect } from 'react';
import './Bestellbestaetigung.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { useParams } from 'react-router-dom';
import { BestellbestaetigungParam } from '../../shared/types';
import { BESTELLUNG_ERROR } from '../../shared/constants';
import { BestellungSelectors, resetUpdateBestellung } from '../../store/Bestellung.store';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';

const Bestellbestaetigung = (): JSX.Element => {
    const dispatch = useDispatch();
    const isSubmitting = useSelector(BestellungSelectors.isSubmitting);
    const submitFailed = useSelector(BestellungSelectors.submitFailed);
    const isUpdating = useSelector(BestellungSelectors.isUpdating);
    const updateFailed = useSelector(BestellungSelectors.updateFailed);

    const { update, auftragOrBestellung } = useParams<BestellbestaetigungParam>();
    const isUpdate = Boolean(update);
    const isAuftrag = auftragOrBestellung === 'auftrag';

    const inProgress = isUpdate ? isUpdating : isSubmitting;
    const failed = isUpdate ? updateFailed : submitFailed;

    useDocumentTitle('Bestellversand');
    useEffect(() => {
        return () => {
            // Reset Bestellung update loading/failed states on unmount to start the next Bestellung in an clean state
            dispatch(resetUpdateBestellung());
        };
    }, [dispatch]);

    return (
        <div>
            {failed ? (
                <>
                    <h2>Bestellung</h2>
                    <NotificationBar
                        testId="bestellung-error-message-bar"
                        dataCy="bestellung-error-message-bar"
                        message={BESTELLUNG_ERROR}
                        isVisible={true}
                    />
                </>
            ) : isAuftrag ? (
                <>
                    <h2>{inProgress ? 'Auftrag wird übermittelt' : 'Auftrag gespeichert.'}</h2>
                    <LoadingSpinner isLoading={inProgress}>
                        <p>Ihr Auftrag wurde erfolgreich zum Auftragskorb verschoben.</p>
                    </LoadingSpinner>
                </>
            ) : (
                <>
                    <h2>{inProgress ? 'Bestellung wird übermittelt' : 'Vielen Dank für Ihre Bestellung.'}</h2>
                    <LoadingSpinner isLoading={inProgress}>
                        <p>Sie erhalten in Kürze die Bestellzusammenfassung per E-Mail.</p>
                    </LoadingSpinner>
                </>
            )}
        </div>
    );
};

export default Bestellbestaetigung;
