import { FocusEventHandler, HTMLProps, useMemo } from 'react';
import { useField } from 'formik';
import Tippy from '@tippyjs/react';
import useTextInputRuleProps from '../../hooks/useTextInputRuleProps';
import usePreventWheel from '../../hooks/usePreventWheel';
import { TextInputRule } from '../../types/enums';
import textInputStyles from './TextInput.module.scss';
import { BaseProps } from '../FormControl';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

export const collapseAnimationDuration = process.env.NODE_ENV === 'test' ? 0 : 500;

type Props = Omit<HTMLProps<HTMLInputElement>, 'onBlur' | 'onChange'> & {
    inTableCell?: boolean;
    textInputRule?: TextInputRule;
    showValidationUntouched?: boolean;
    onBlurCallback?: FocusEventHandler<HTMLInputElement>;
    noTip?: boolean;
    storniert?: boolean;
};

const TextInput = (props: BaseProps & Props): JSX.Element => {
    const {
        inTableCell,
        textInputRule = TextInputRule.ANY,
        showValidationUntouched,
        onBlurCallback,
        noTip,
        storniert = false,
        disabled,
        ...textFieldProps
    } = props;
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const [{ onBlur: fieldOnBlur, ...field }, meta] = useField(props);
    const { value, ...fieldWithoutValue } = field;
    const controlledValue = value ?? '';
    const showError = (meta.touched || Boolean(showValidationUntouched)) && Boolean(meta.error);

    const combinedStyles = useMemo(() => {
        const styles = [textInputStyles._input];
        if (inTableCell) {
            styles.push(textInputStyles['_input--inTableCell']);
        } else {
            styles.push(textInputStyles['_input--regular']);
        }

        if (showError) {
            styles.push(textInputStyles['_input--error']);
        }

        if (storniert) {
            styles.push(textInputStyles['_input--storniert']);
        }
        return styles.join(' ');
    }, [inTableCell, showError, storniert]);

    const preventWheel = usePreventWheel();
    const textInputRuleProps = useTextInputRuleProps(textInputRule);

    return (
        <Tippy content={meta.error} disabled={!meta.error || noTip || isMobile}>
            <input
                className={combinedStyles}
                ref={preventWheel}
                {...fieldWithoutValue}
                value={controlledValue}
                {...textFieldProps}
                {...textInputRuleProps}
                onBlur={(event): void => {
                    fieldOnBlur(event);
                    onBlurCallback && onBlurCallback(event);
                }}
                disabled={disabled || storniert}
            />
        </Tippy>
    );
};

export default TextInput;
