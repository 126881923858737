import { BestellpositionArtikelSackwareView } from '../../../../types';
import 'tippy.js/dist/tippy.css';
import Formulas from '../../../../formulas';
import { FieldArrayRenderProps } from 'formik';
import { TableRow } from '../../../../ui-components/Table';
import { BezeichnungEntry, DeleteEntry, MengeSWEntry, WerkeEntry } from '../../PropertyEntries';
import { ReactElement } from 'react';

export type SackwareTableRowNewBestellungProps = Readonly<{
    bestellposition: BestellpositionArtikelSackwareView;
    allowDelete?: boolean;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
}>;

export const SackwareTableRowNewBestellung = ({
    bestellposition,
    allowDelete,
    arrayHelpers,
    onArtikelDeleted,
}: SackwareTableRowNewBestellungProps): ReactElement => {
    const { mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe } = bestellposition;
    const overallVECount = Formulas.overallVerkaufseinheitCount(mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe);

    return (
        <TableRow key={bestellposition.index}>
            <BezeichnungEntry.Cell bestellposition={bestellposition} />
            <WerkeEntry.Cell bestellposition={bestellposition} />
            <MengeSWEntry.Cell bestellposition={bestellposition} />
            {allowDelete ? (
                <DeleteEntry.Delete.Cell
                    arrayHelpers={arrayHelpers}
                    bestellposition={bestellposition}
                    onArtikelDeleted={onArtikelDeleted}
                    showConfirmDelete={overallVECount > 0}
                />
            ) : null}
        </TableRow>
    );
};
