import { forwardRef, Ref, useMemo } from 'react';
import styles from './Checkbox.module.scss';

export type NonFormikCheckboxProps = Readonly<{
    label?: JSX.Element | string;
    name: string;
    id: string;
    multiline?: boolean;
    checked?: boolean;
    disabled?: boolean;
    placement?: 'left' | 'center' | 'right';
    onFieldChange?: (value: boolean) => void;
    dataCy?: string;
}>;

const NonFormikCheckbox = forwardRef(
    (
        { label, id, onFieldChange, multiline, dataCy, placement = 'center', ...props }: NonFormikCheckboxProps,
        ref: Ref<HTMLDivElement>
    ): JSX.Element => {
        const containerStyles = useMemo((): string => {
            const containerStyles = [styles._checkbox, styles[`_checkbox--${placement}`]];
            if (multiline) {
                containerStyles.push(styles['_checkbox--multiline']);
            }

            return containerStyles.join(' ');
        }, [multiline, placement]);

        const inputStyles = useMemo((): string => {
            const inputStyles = [styles._checkboxInput];
            return inputStyles.join(' ');
        }, []);

        const labelStyles = useMemo((): string => {
            const labelStyles = [styles._checkboxLabel];
            if (multiline) {
                labelStyles.push(styles['_checkboxLabel--multiline']);
            }
            return labelStyles.join(' ');
        }, [multiline]);

        return (
            <div ref={ref} className={containerStyles}>
                <input
                    data-cy={dataCy}
                    type="checkbox"
                    id={id}
                    {...props}
                    className={inputStyles}
                    onChange={(e): void => {
                        if (onFieldChange) {
                            onFieldChange(e.currentTarget.checked);
                        }
                    }}
                />
                {label && (
                    <label htmlFor={id} className={labelStyles}>
                        {label}
                    </label>
                )}
            </div>
        );
    }
);

NonFormikCheckbox.displayName = 'NonFormikCheckbox';

export default NonFormikCheckbox;
