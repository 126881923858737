import TextInput, { collapseAnimationDuration } from '../../../../ui-components/TextInput/TextInput';
import FormControl from '../../../../ui-components/FormControl/FormControl';
import AnimateHeight from 'react-animate-height';
import { PropertyEntryProps } from '../types';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const ZulageListeEntryText = ({
    bestellposition,
    disabled,
}: PropertyEntryProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    return (
        <AnimateHeight duration={collapseAnimationDuration} height={bestellposition.zulage ? 'auto' : '0%'}>
            <FormControl
                name={`bestellpositionenView[${bestellposition.index}].zulageText`}
                id={`bestellpositionenView[${bestellposition.index}].zulageText`}
                label="Zulage (*)"
                renderChild={(props): JSX.Element => (
                    <TextInput
                        {...props}
                        storniert={bestellposition.storno}
                        disabled={disabled}
                        showValidationUntouched={true}
                        type="text"
                    />
                )}
            />
        </AnimateHeight>
    );
};
