import Validator, { MessagesType, ValidationError, ValidationSchema } from 'fastest-validator';
import { useCallback } from 'react';
import { FieldValues, ResolverSuccess, ResolverError, ResolverOptions, Resolver, FieldError, FieldErrors } from 'react-hook-form';
import { toNestError } from '@hookform/resolvers';

const parseFastestValidatorErrors = (errors: ValidationError[]): FieldErrors => {
    return errors.reduce((allErrors: FieldErrors, currentError: ValidationError) => {
        const newError: FieldError = {
            type: currentError.type ?? 'validation',
            message: currentError.message,
        };

        return {
            ...allErrors,
            [currentError.field]: newError,
        };
    }, {});
};

export default function useFastestValidatorResolver<T extends FieldValues>(
    validationSchema: ValidationSchema<T>,
    errorMapping?: MessagesType
): Resolver<T> {
    return useCallback(
        async (values, context, options: ResolverOptions<T>) => {
            const formValidator = new Validator({
                useNewCustomCheckerFunction: true,
                messages: errorMapping,
            });
            const errorsOrTrue = formValidator.validate(values, validationSchema);

            if (errorsOrTrue === true) {
                return {
                    values,
                    errors: {},
                } as ResolverSuccess<T>;
            } else {
                const errors = errorsOrTrue as ValidationError[];
                const mappedErrors = toNestError(parseFastestValidatorErrors(errors), options);
                return {
                    values: {},
                    errors: mappedErrors,
                } as ResolverError<T>;
            }
        },
        [validationSchema, errorMapping]
    );
}
