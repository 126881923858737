import styles from './CookieBanner.module.scss';
import Button from '../Button/Button';
import { ALLOW_COOKIES_KEY } from '../../../localStorage';

export type Props = Readonly<{
    bannerVisible: boolean;
    onHideCookieBanner: () => void;
}>;

const CookieBanner = ({ bannerVisible, onHideCookieBanner }: Props): JSX.Element | null => {
    if (bannerVisible) {
        return (
            <div className={styles._cookieJar}>
                <div className={styles._cookieTextContainer}>
                    <h2>COOKIE-EINSTELLUNGEN</h2>
                    <p>
                        Das Deutsche Tiernahrung Cremer Partnerportal verwendet Cookies um Ihre Bestellung zwischenzuspeichern und erhöht
                        damit den Bestellkomfort. Klicken Sie auf „Cookies akzeptieren“ und erklären Sie sich damit mit dem Setzen der
                        Cookies einverstanden. Detaillierte Informationen und wie Sie Ihre freiwillige Einwilligung jederzeit widerrufen
                        können, erfahren Sie{' '}
                        <a href="https://www.deuka.de/datenschutz/" target="_blank" rel="noopener noreferrer">
                            hier
                        </a>
                        .
                    </p>
                </div>
                <div className={styles._cookieButtonContainer}>
                    <Button
                        type="button"
                        onClick={(): void => {
                            localStorage.setItem(ALLOW_COOKIES_KEY, 'true');
                            onHideCookieBanner();
                        }}
                    >
                        Cookies akzeptieren
                    </Button>
                    <Button
                        type="button"
                        onClick={(): void => {
                            localStorage.clear();
                            localStorage.setItem(ALLOW_COOKIES_KEY, 'false');
                            onHideCookieBanner();
                        }}
                    >
                        Cookies ablehnen
                    </Button>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default CookieBanner;
