import localStyles from './Bestellansicht.module.scss';
import Tippy from '@tippyjs/react';
import { statusToIcon, statusToText } from '../../shared/helper/bestellungen-helper';
import { convertDateWithTimeFromIsoStringToDateInfo } from '../../shared/helper/date-helper';
import { useSelector } from 'react-redux';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { ReactElement } from 'react';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';

export const BestellstatusTable = (): ReactElement => {
    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);
    const bestelldatumInfo = convertDateWithTimeFromIsoStringToDateInfo(bestellung.bestelldatum);
    const isMobile = useBreakpoint(Breakpoint.MOBILE);

    return (
        <table className={localStyles._datentabelle}>
            <tbody>
                <tr>
                    <td>Status:</td>
                    <td>
                        <Tippy content={statusToText(bestellung.status)} disabled={isMobile}>
                            <img
                                className={localStyles._statusIcon}
                                src={statusToIcon(bestellung.status)}
                                alt={statusToText(bestellung.status)}
                            />
                        </Tippy>
                        &nbsp;
                        {isMobile ? statusToText(bestellung.status) : null}
                    </td>
                </tr>
                {bestellung.bestelldatum ? (
                    <tr>
                        <td>Bestelleingang:</td>
                        <td>
                            <span>
                                {bestelldatumInfo.datum} {bestelldatumInfo.uhrzeit}
                            </span>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </table>
    );
};
