import { useDispatch, useSelector } from 'react-redux';
import { selectIsDebitor } from '../../store/Navigation.store';
import { getKfzKennzeichen, kfzKennzeichenSelectors } from '../../store/KfzKennzeichen.store';
import { useEffect } from 'react';

export const useFetchKfzKennzeichen = (): void => {
    const dispatch = useDispatch();
    const isDebitor = useSelector(selectIsDebitor);
    const loaded = useSelector(kfzKennzeichenSelectors.loaded);

    useEffect(() => {
        if (isDebitor && !loaded) {
            dispatch(getKfzKennzeichen());
        }
    }, [isDebitor, dispatch, loaded]);
};
