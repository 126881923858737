import { Bestellprozesstyp } from '../types/enums';
import { PropsWithChildren } from 'react';
import { selectBestellprozesstyp } from '../../store/Workflow.store';
import { useSelector } from 'react-redux';

type BestellprozessGuardProps = {
    prozesstyp: Bestellprozesstyp;
};

export const BestellprozessGuard = ({ children, prozesstyp }: PropsWithChildren<BestellprozessGuardProps>): JSX.Element | null => {
    const bestellprozesstyp = useSelector(selectBestellprozesstyp);

    if (bestellprozesstyp === prozesstyp) {
        return <>{children}</>;
    }

    return null;
};
