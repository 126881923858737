import { ReactNode } from 'react';
import CreatableSelectWithHookForm from '../../ui-components/SelectInput/CreatableSelectWithHookForms';
import { useSelector } from 'react-redux';
import { kfzKennzeichenSelectors } from '../../../store/KfzKennzeichen.store';
import NotificationBar from '../../ui-components/NotificationBar/NotificationBar';
import { KENNZEICHEN_LOADING_ERROR } from '../../constants';
import styles from './KfzKennzeichenAuswahl.module.scss';
import { useFetchKfzKennzeichen } from '../../hooks/useFetchKfzKennzeichen';

export type KfzKennzeichenSelectWithHookFormsProps = Readonly<{
    onKfzKennzeichenCreated: (kfzKennzeichen: string) => void;
}>;

const KfzKennzeichenSelectWithHookForms = ({ onKfzKennzeichenCreated }: KfzKennzeichenSelectWithHookFormsProps): JSX.Element => {
    const kfzKennzeichenOptions = useSelector(kfzKennzeichenSelectors.allAsOptions);
    const isLoading = useSelector(kfzKennzeichenSelectors.isLoading);
    const hasLoadError = useSelector(kfzKennzeichenSelectors.hasSubmitError);

    useFetchKfzKennzeichen();

    return (
        <div>
            <label htmlFor="kfzKennzeichen">KFZ-Kennzeichen</label>
            <NotificationBar isVisible={hasLoadError} message={KENNZEICHEN_LOADING_ERROR} />
            <CreatableSelectWithHookForm
                isLoading={isLoading}
                options={kfzKennzeichenOptions}
                onCreateOption={onKfzKennzeichenCreated}
                formatCreateLabel={(inputValue: string): ReactNode => (
                    <div className={styles._newSelectOption}>{`"${inputValue}" anlegen`}</div>
                )}
                placeholder={'Kennzeichenauswahl'}
                maxLength={12}
                isClearable={true}
                autoSelectIfOnlyOneOption={false}
                name="kfzKennzeichen"
            />
        </div>
    );
};

export default KfzKennzeichenSelectWithHookForms;
