import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import styles from './Bestellpositionen.module.scss';
import { useMediaQuery } from 'react-responsive';
import { addIndexToBestellposition, isLoseWare, isSackwareView } from '../../helper/bestellungen-helper';
import type { ArtikelTableValues } from '../../types';
import { useSelector } from 'react-redux';
import { selectIsBestellungExisting } from '../../../store/Global.selectors';
import { ModifikationsstatusEntry } from './PropertyEntries';
import { BestelluebersichtSelectors } from '../../../store/Bestelluebersicht.store';
import { SackwareTable } from './ArtikelSackwareTable/SackwareTable';
import { LoseWareList } from './ArtikelLoseWareTable/LoseWareList';
import { LoseWareTable } from './ArtikelLoseWareTable/LoseWareTable';
import { SackwareList } from './ArtikelSackwareTable/SackwareList';
import { ArtikelauswahlDropdown } from '../Artikelauswahl/ArtikelauswahlDropdown';
import { ShowBestellpositionenWhenExist } from './ShowBestellpositionWhenExist';
import { WunschterminUhrzeit } from '../Wunschtermin/WunschterminUhrzeit';

interface BestellpositionenProps {
    onArtikelDeleted: (artikelNummer: string) => void;
}

export const Bestellpositionen = ({ onArtikelDeleted }: BestellpositionenProps): JSX.Element | null => {
    const { values, setFieldValue } = useFormikContext<ArtikelTableValues>();
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });
    const isExisting = useSelector(selectIsBestellungExisting);
    const isDebitor = useSelector(selectIsBestellungExisting);
    const positionWithModifikationsergebnis = useSelector(BestelluebersichtSelectors.currentBestellungHasSackwareWithModifikationsergebnis);
    const isExistingAndDebitor = isExisting && isDebitor;
    useEffect(() => {
        const bestellpositionenWithIndex = values.bestellpositionenView.map(addIndexToBestellposition); // NOTE: Preserve index in the Bestellung for later operations on the array

        setFieldValue('bestellpositionenView', bestellpositionenWithIndex, false);

        // NOTE: We only want to recalculate the indicies if we changed the array in length, e.g. by adding or removing
        //       However, this does not consider swapping out elements and thus not changing the length
        // eslint-disable-next-line
    }, [setFieldValue, values.bestellpositionenView.length]);

    if (values.bestellpositionenView.length === 0) {
        return <div className={styles._emptyArticlesMessage}>Wählen Sie zuerst die Artikel aus, die Sie bestellen möchten.</div>;
    }

    const SackwareComponent = isMobile ? SackwareList : SackwareTable;
    const LosewareComponent = isMobile ? LoseWareList : LoseWareTable;

    if (isExistingAndDebitor) {
        return (
            <>
                <h4>Artikel Sackware</h4>
                {positionWithModifikationsergebnis ? (
                    <div className={styles._modifikationsContainer}>
                        <ModifikationsstatusEntry.List
                            nummer={positionWithModifikationsergebnis.nummer}
                            positionsnummer={positionWithModifikationsergebnis.positionsnummer}
                            withText
                        />
                    </div>
                ) : null}
                <ArtikelauswahlDropdown showOnlySackWare={isDebitor} allowAddingNewArticles={false} />
                <ShowBestellpositionenWhenExist isSackOrLoseWare={isSackwareView}>
                    <>
                        <WunschterminUhrzeit />
                        <SackwareComponent onArtikelDeleted={onArtikelDeleted} allowDelete />
                    </>
                </ShowBestellpositionenWhenExist>
                <ShowBestellpositionenWhenExist isSackOrLoseWare={isLoseWare} title={'Artikel lose Ware'}>
                    <LosewareComponent onArtikelDeleted={onArtikelDeleted} />
                </ShowBestellpositionenWhenExist>
            </>
        );
    }
    return (
        <>
            <ShowBestellpositionenWhenExist isSackOrLoseWare={isSackwareView} title={'Artikel Sackware'}>
                <SackwareComponent onArtikelDeleted={onArtikelDeleted} allowDelete />
            </ShowBestellpositionenWhenExist>
            <ShowBestellpositionenWhenExist isSackOrLoseWare={isLoseWare} title={'Artikel lose Ware'}>
                <LosewareComponent onArtikelDeleted={onArtikelDeleted} />
            </ShowBestellpositionenWhenExist>
        </>
    );
};
