import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BestellungSelectors } from '../../../store/Bestellung.store';
import NotificationBar from '../../../shared/ui-components/NotificationBar/NotificationBar';
import { routes } from '../../../store/Workflow.store';

export const WarenempfaengerErrorNotification = (): JSX.Element => {
    const warenempfaengerPartnerNummer = useSelector(BestellungSelectors.warenempfaengerPartnerNummer);
    return (
        <NotificationBar testId="warenempfaenger-error-message-bar" message="" isVisible={!Boolean(warenempfaengerPartnerNummer)}>
            <Link to={routes.warenempfaengerAuswaehlen}>Bitte wählen Sie erst einen Warenempfänger aus.</Link>
        </NotificationBar>
    );
};
