import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getWerkeForArtikel } from '../../store/Abholwerk.store';

export const useFetchWerkeForArtikel = (
    bestellpositionsNummern: string[],
    rechnungsempfaengerPartnerNummer: string | undefined
): (() => void) => {
    const dispatch = useDispatch();

    const reloadWerke = useCallback(() => {
        if (rechnungsempfaengerPartnerNummer && bestellpositionsNummern.length > 0) {
            bestellpositionsNummern.forEach((nummer) => {
                if (nummer) {
                    dispatch(getWerkeForArtikel(nummer, rechnungsempfaengerPartnerNummer));
                }
            });
        }
    }, [bestellpositionsNummern, dispatch, rechnungsempfaengerPartnerNummer]);

    useEffect(() => {
        reloadWerke();
    }, [reloadWerke]);

    return reloadWerke;
};
