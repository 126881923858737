import { ArtikelLoseWare } from '../shared/types';
import { ArtikelTyp, Bestellprozesstyp } from '../shared/types/enums';
import type { RootState } from '../configureStore';
import type { SetRechnungsempfaengerType, SetWarenempfaengerType } from './Bestellung.store';

export const GET_ARTIKEL_LOSE_WARE = 'GET_ARTIKEL_LOSE_WARE';
export const GET_ARTIKEL_LOSE_WARE_SUCCEEDED = 'GET_ARTIKEL_LOSE_WARE_SUCCEEDED';
export const GET_ARTIKEL_LOSE_WARE_FAILED = 'GET_ARTIKEL_LOSE_WARE_FAILED';

export interface GetArtikelLoseWareParams {
    bestellprozesstyp: Bestellprozesstyp;
    rechnungsempfaengerPartnerNummer: string;
    warenempfaengerPartnerNummer?: string;
}
export interface GetArtikelLoseWareAction {
    type: typeof GET_ARTIKEL_LOSE_WARE;
    params: GetArtikelLoseWareParams;
}
export interface GetArtikelLoseWareSucceededAction {
    type: typeof GET_ARTIKEL_LOSE_WARE_SUCCEEDED;
    artikel: ArtikelLoseWare[];
}
export interface GetArtikelLoseWareFailedAction {
    type: typeof GET_ARTIKEL_LOSE_WARE_FAILED;
}

export function getArtikelLoseWare(params: GetArtikelLoseWareParams): GetArtikelLoseWareAction {
    return {
        type: GET_ARTIKEL_LOSE_WARE,
        params,
    };
}
export function getArtikelLoseWareSucceeded(artikel: ArtikelLoseWare[]): GetArtikelLoseWareSucceededAction {
    return {
        type: GET_ARTIKEL_LOSE_WARE_SUCCEEDED,
        artikel,
    };
}
export function getArtikelLoseWareFailed(): GetArtikelLoseWareFailedAction {
    return {
        type: GET_ARTIKEL_LOSE_WARE_FAILED,
    };
}

export type ArtikelLoseWareActions =
    | GetArtikelLoseWareAction
    | GetArtikelLoseWareSucceededAction
    | GetArtikelLoseWareFailedAction
    | SetWarenempfaengerType
    | SetRechnungsempfaengerType;

export type ArtikelLoseWareState = {
    artikel: ArtikelLoseWare[];
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
};

export const INITIAL_ARTIKEL_LOSE_WARE_STATE: ArtikelLoseWareState = {
    artikel: [],
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
};

const selectArtikelLoseWare = (state: RootState) => state.artikelLoseWare;
export const selectArtikelLoseWareList = (state: RootState): ArtikelLoseWare[] => selectArtikelLoseWare(state).artikel;
export const selectArtikelLoseWareLoadFailed = (state: RootState): boolean => selectArtikelLoseWare(state).loadFailed;
export const selectArtikelLoseWareIsLoading = (state: RootState): boolean => selectArtikelLoseWare(state).isLoading;
export const selectArtikelLoseWareLoaded = (state: RootState): boolean => selectArtikelLoseWare(state).loadFinished;

export function artikelLoseWareReducer(state = INITIAL_ARTIKEL_LOSE_WARE_STATE, action: ArtikelLoseWareActions): ArtikelLoseWareState {
    switch (action.type) {
        case GET_ARTIKEL_LOSE_WARE:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
            };
        case GET_ARTIKEL_LOSE_WARE_SUCCEEDED:
            return {
                ...state,
                artikel: action.artikel.map((a) => ({ ...a, artikelTyp: ArtikelTyp.LoseWare })),
                loadFailed: false,
                isLoading: false,
                loadFinished: true,
            };
        case GET_ARTIKEL_LOSE_WARE_FAILED:
            return {
                ...state,
                loadFailed: true,
                isLoading: false,
                loadFinished: true,
            };
        case 'bestellung/setWarenempfaenger':
        case 'bestellung/setRechnungsempfaenger':
            return INITIAL_ARTIKEL_LOSE_WARE_STATE;
        default:
            return state;
    }
}
