import React, { FunctionComponent, useEffect, useMemo, useState, KeyboardEvent, SetStateAction } from 'react';
import styles from './BestelluebersichtUndAuftragskorb.module.scss';
import selectStyles from '../../shared/ui-components/SelectInput/SelectInput.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { BestelluebersichtSelectors, getBestellungen, resetCurrentSammelbestellung } from '../../store/Bestelluebersicht.store';
import { Column, SortingRule, usePagination, useSortBy, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { routes } from '../../store/Workflow.store';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { OverviewList } from '../../shared/ui-components/OverviewList/OverviewList';
import { OverviewTable } from '../../shared/ui-components/OverviewTable/OverviewTable';
import { OverviewPagination } from '../../shared/ui-components/OverviewPagination/OverviewPagination';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { Dispatch } from 'redux';
import { getWarenempfaenger, WarenempfaengerSelectors } from '../../store/Warenempfaenger.store';
import Select, { components, OptionTypeBase, SingleValueProps, ValueType } from 'react-select';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import FeatureToggle from '../../shared/ui-components/FeatureToggle/FeatureToggle';
import { localStorageWrapper } from '../../localStorage';
import {
    bestelldatumColumn,
    bestellungColumn,
    checkboxColumnConfig,
    bestelldetailsLinkColumn,
    rechnungsempfaengerOrLandwarenhandelColumn,
    statusColumn,
    warenempfaengerColumn,
    wunschterminColumn,
    wunschterminWarenempfaengerVonColumn,
    wunschterminWarenempfaengerBisColumn,
} from './Columns';
import SammelbestellungButton from './SammelbestellungButton';
import { useMediaQuery } from 'react-responsive';
import { UnregisterCallback } from 'history';
import { GET_BESTELLUNGEN_ERROR, NEWID_PREFIX, WUNSCHTERMIN_LABEL } from '../../shared/constants';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import type { Option } from '../../shared/helper/select-options';
import type { RootState } from '../../configureStore';
import type { BestellungDetails, Filter, Warenempfaenger } from '../../shared/types';
import { selectIsDebitor, selectIsWarenempfaenger } from '../../store/Navigation.store';
import dayjs from 'dayjs';
import { Order } from '../../shared/types/enums';
import { createCustomStyles } from '../../shared/ui-components/SelectInput/CustomStyles';
import Label from '../../shared/ui-components/Label/Label';

type WarenempfaengerOption = ValueType<Option, false> & { warenempfaenger: Warenempfaenger };

const FormattedValueAfterSelection: FunctionComponent<SingleValueProps<OptionTypeBase>> = (props: SingleValueProps<OptionTypeBase>) => {
    const warenempfaenger = props.data.warenempfaenger as Warenempfaenger;
    const { hauptname, nebenname, namenszusatz, partnerNummer } = warenempfaenger;
    return <components.SingleValue {...props}>{`${hauptname} ${nebenname} ${namenszusatz} (${partnerNummer})`}</components.SingleValue>;
};

export const onWunschterminFilterChanged = (
    value: string,
    setInternalValue: React.Dispatch<SetStateAction<string>>,
    setFilter: React.Dispatch<SetStateAction<string>>
): void => {
    const selectedDate = dayjs(value);
    if (selectedDate.isBefore('2100-01-01', 'day') && selectedDate.isAfter('2000-01-01', 'day')) {
        setInternalValue(value);
        setFilter(value);
    } else {
        if (!value) {
            setFilter(value);
        }
        setInternalValue(value);
    }
};

const MOBILE_COLUMNS = ['bestellnummer', 'bestelldatum', 'warenempfaenger', 'isDraft'];

const getBestelluebersichtColumns = (
    currentSammelbestellung: string[],
    dispatch: Dispatch,
    isMobile: boolean,
    isDebitor: boolean,
    route: string
): Column<BestellungDetails>[] => {
    return [
        bestellungColumn(isMobile),
        statusColumn(),
        bestelldatumColumn(isMobile),
        warenempfaengerColumn(isMobile),
        rechnungsempfaengerOrLandwarenhandelColumn(isDebitor),
        ...(isDebitor ? [wunschterminColumn()] : []),
        ...[wunschterminWarenempfaengerVonColumn(), wunschterminWarenempfaengerBisColumn()],
        bestelldetailsLinkColumn(route),
    ];
};

const getAuftragskorbColumns = (
    currentSammelbestellung: string[],
    dispatch: Dispatch,
    isMobile: boolean,
    isDebitor: boolean,
    route: string
): Column<BestellungDetails>[] => {
    return [
        ...(!isMobile ? [checkboxColumnConfig] : []),
        bestellungColumn(isMobile),
        ...(!isDebitor ? [statusColumn()] : []),
        bestelldatumColumn(isMobile),
        warenempfaengerColumn(isMobile),
        wunschterminColumn(),
        ...(isDebitor ? [wunschterminWarenempfaengerVonColumn(), wunschterminWarenempfaengerBisColumn()] : []),
        bestelldetailsLinkColumn(route),
    ];
};

function loadBestellungenForPage(
    pageIndex: number,
    pageSize: number,
    sortBy: Array<SortingRule<Record<string, unknown>>>,
    dispatch: Dispatch,
    isDraft?: boolean,
    warenempfaengerPartnerNummer?: string,
    bestellnummer?: string,
    geschlossen?: boolean,
    wunschtermin?: string,
    artikelNummer?: string
): void {
    let sortColumn = sortBy[0].id;
    if (sortColumn === 'warenempfaenger') {
        sortColumn = 'warenempfaengername';
    }

    if (sortColumn === 'rechnungsempfaenger') {
        sortColumn = 'rechnungsempfaengername';
    }
    const sortOrder = sortBy[0].desc ? Order.DESC : Order.ASC;
    const filter: Filter =
        typeof isDraft !== 'undefined'
            ? {
                  isDraft: Boolean(isDraft),
              }
            : {};
    if (warenempfaengerPartnerNummer) {
        filter.warenempfaengerPartnerNummer = warenempfaengerPartnerNummer;
    }
    if (bestellnummer) {
        filter.bestellnummer = bestellnummer;
    }
    if (geschlossen !== undefined) {
        filter.geschlossen = geschlossen;
    }
    if (wunschtermin) {
        filter.wunschtermin = wunschtermin;
    }
    if (artikelNummer) {
        filter.artikelNummer = artikelNummer;
    }

    dispatch(
        getBestellungen({
            page: pageIndex + 1,
            size: pageSize,
            sortColumn,
            sortOrder,
            filter,
            includeBestellpositionen: true,
        })
    );
}

type BestelluebersichtUndAuftragskorbProps = Readonly<{
    pageTitle: string;
    pageBaseKey: string;
    detailansichtBaseRoute: string;
    bestellungenAreDraftsForDebitor: boolean;
    getColumnsFunction: (
        currentSammelbestellung: string[],
        dispatch: Dispatch,
        isMobile: boolean,
        isDebitor: boolean,
        route: string
    ) => Column<BestellungDetails>[];
    allowSammelbestellung: boolean;
}>;

export const mobileQuery = `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})`;
const BestelluebersichtUndAuftragskorb = ({
    pageTitle,
    pageBaseKey,
    detailansichtBaseRoute,
    bestellungenAreDraftsForDebitor,
    getColumnsFunction,
    allowSammelbestellung,
}: BestelluebersichtUndAuftragskorbProps): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const bestellungen = useSelector(BestelluebersichtSelectors.bestellungen);
    const isLoading = useSelector(BestelluebersichtSelectors.bestellungenLoading);
    const loadFailed = useSelector(BestelluebersichtSelectors.bestellungenLoadFailed);
    const totalCount = useSelector(BestelluebersichtSelectors.bestellungenTotalCount);
    const isDebitor = useSelector(selectIsDebitor);
    const isWarenempfaenger = useSelector(selectIsWarenempfaenger);
    const warenempfaenger = useSelector(WarenempfaengerSelectors.all);
    const warenempfaengerLoading = useSelector((state: RootState) => state.warenempfaenger.isLoading);
    const currentSammelbestellung = useSelector(BestelluebersichtSelectors.currentSammelbestellung);

    const isMobile = useMediaQuery({ query: mobileQuery });
    const PAGE_SIZE_KEY = `${pageBaseKey}.pageSize`;
    const PAGE_INDEX_KEY = `${pageBaseKey}.page`;
    const PAGE_SORTBY_KEY = `${pageBaseKey}.sortBy`;

    const [pageSizeState, setPageSizeState] = useState<number>(parseInt(localStorageWrapper.getItem(PAGE_SIZE_KEY) || '10'));
    const [warenempfaengerFilter, setWarenempfaengerFilter] = useState<WarenempfaengerOption>();
    const [bestellnummerFilter, setBestellnummerFilter] = useState('');
    const [bestellnummerFilterInternalValue, setBestellnummerFilterInternalValue] = useState('');
    const [wunschterminFilter, setWunschterminFilter] = useState('');
    const [wunschterminFilterInternalValue, setWunschterminInternalValue] = useState('');
    const [artikelnummerFilter, setArtikelnummerFilter] = useState('');
    const [artikelnummerFilterInternalValue, setArtikelnummerFilterInternalValue] = useState('');

    useEffect(() => {
        localStorageWrapper.setItem(PAGE_SIZE_KEY, pageSizeState.toString());
    }, [pageSizeState, PAGE_SIZE_KEY]);

    useDocumentTitle(pageTitle);

    useEffect(() => {
        dispatch(getWarenempfaenger());
    }, [dispatch]);

    const warenempfaengerOptionen = warenempfaenger.map((we): Option & { warenempfaenger: Warenempfaenger } => ({
        value: `${we.partnerNummer} ${we.hauptname} ${we.nebenname} ${we.namenszusatz}`,
        label: (
            <>
                <div>{we.hauptname}</div>
                <div>{we.nebenname}</div>
                <div>
                    <span>{we.namenszusatz}</span>
                    <span> ({we.partnerNummer?.startsWith(NEWID_PREFIX) ? 'Neu angelegt' : we.partnerNummer})</span>
                </div>
            </>
        ),
        warenempfaenger: we,
    }));

    const columns: Column<BestellungDetails>[] = useMemo(
        () => getColumnsFunction(currentSammelbestellung, dispatch, isMobile, isDebitor, detailansichtBaseRoute),
        [getColumnsFunction, currentSammelbestellung, dispatch, isMobile, isDebitor, detailansichtBaseRoute]
    );

    const initialPageCount = Math.ceil(totalCount / pageSizeState);

    const tableInstance = useTable<BestellungDetails>(
        {
            columns,
            data: bestellungen,
            initialState: {
                sortBy: [
                    {
                        id: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.id || 'bestelldatum',
                        desc: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.desc || true,
                    },
                ],
                pageSize: pageSizeState,
                pageIndex: parseInt(localStorageWrapper.getItem(PAGE_INDEX_KEY) || '0'),
            },
            pageCount: initialPageCount,
            manualPagination: true,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortRemove: true,
        },
        useSortBy,
        usePagination
    );

    const { state } = tableInstance;

    useEffect(() => {
        const isDraft = isWarenempfaenger ? undefined : bestellungenAreDraftsForDebitor;
        const geschlossen = allowSammelbestellung ? (isDebitor ? false : undefined) : undefined;
        loadBestellungenForPage(
            state.pageIndex,
            state.pageSize,
            state.sortBy,
            dispatch,
            isDraft,
            warenempfaengerFilter?.warenempfaenger.partnerNummer,
            bestellnummerFilter,
            geschlossen,
            wunschterminFilter,
            artikelnummerFilter
        );
    }, [
        dispatch,
        state.pageIndex,
        state.pageSize,
        state.sortBy,
        bestellungenAreDraftsForDebitor,
        isWarenempfaenger,
        warenempfaengerFilter,
        bestellnummerFilter,
        isDebitor,
        wunschterminFilter,
        artikelnummerFilter,
        allowSammelbestellung,
    ]);

    useEffect(() => {
        localStorageWrapper.setItem(PAGE_INDEX_KEY, state.pageIndex.toString());
        localStorageWrapper.setItem(PAGE_SORTBY_KEY, JSON.stringify(state.sortBy));
    }, [state.pageIndex, state.sortBy, PAGE_INDEX_KEY, PAGE_SORTBY_KEY]);

    const onBestellnummerFilterKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            setBestellnummerFilter(bestellnummerFilterInternalValue);
        }
    };

    const onArtikelnummerFilterKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            setArtikelnummerFilter(artikelnummerFilterInternalValue);
        }
    };

    return (
        <>
            <h2>{pageTitle}</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message={GET_BESTELLUNGEN_ERROR}
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={(): void => {
                    const isDraft = isWarenempfaenger ? undefined : bestellungenAreDraftsForDebitor;
                    const geschlossen = isDebitor ? false : undefined;
                    loadBestellungenForPage(
                        state.pageIndex,
                        state.pageSize,
                        state.sortBy,
                        dispatch,
                        isDraft,
                        warenempfaengerFilter?.warenempfaenger.partnerNummer,
                        bestellnummerFilter,
                        geschlossen,
                        wunschterminFilter,
                        artikelnummerFilter
                    );
                }}
            />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    <div className={styles._panelAboveTable}>
                        {allowSammelbestellung && !isMobile && (
                            <div className={styles._sammelbestellungPanelTop}>
                                <SammelbestellungButton />
                            </div>
                        )}
                        <FeatureToggle featureName="BESTELLUNGEN_FILTER">
                            <div className={styles._filterPanel}>
                                <div className={styles._filter}>
                                    <Select
                                        options={warenempfaengerOptionen}
                                        name={'warenempfaengerFilter'}
                                        value={warenempfaengerFilter}
                                        onChange={(selectedOption): void => {
                                            setWarenempfaengerFilter(selectedOption as unknown as WarenempfaengerOption);
                                        }}
                                        styles={createCustomStyles(false, false, 'left')}
                                        className={[selectStyles._select, selectStyles['_select--inTableCell']].join(' ')}
                                        placeholder="Warenempfänger Filter"
                                        menuShouldScrollIntoView
                                        components={{ SingleValue: FormattedValueAfterSelection }}
                                        menuPlacement={'auto'}
                                        noOptionsMessage={(): string => 'Keine Einträge'}
                                        isLoading={warenempfaengerLoading}
                                        isClearable
                                    />
                                </div>
                                <div className={styles._filter}>
                                    <TextInputWithoutFormik
                                        name="bestellnummerFilter"
                                        id="bestellnummerFilter"
                                        placeholder="Bestellnummern Filter"
                                        value={bestellnummerFilterInternalValue}
                                        onKeyDown={onBestellnummerFilterKeyDown}
                                        onFieldChange={setBestellnummerFilterInternalValue}
                                        onFieldBlur={setBestellnummerFilter}
                                    />
                                </div>
                                {!allowSammelbestellung ? (
                                    <>
                                        <div className={styles._filter}>
                                            <Label>{WUNSCHTERMIN_LABEL}</Label>
                                            <TextInputWithoutFormik
                                                type="date"
                                                name="wunschterminFilter"
                                                id="wunschterminFilter"
                                                placeholder="Wunschtermin Filter"
                                                value={wunschterminFilterInternalValue}
                                                onFieldChange={(value: string) =>
                                                    onWunschterminFilterChanged(value, setWunschterminInternalValue, setWunschterminFilter)
                                                }
                                                onFieldBlur={setWunschterminFilter}
                                            />
                                        </div>
                                        <div className={styles._filter}>
                                            <TextInputWithoutFormik
                                                name="artikelnummerFilter"
                                                id="artikelnummerFilter"
                                                placeholder="Artikelnummer Filter"
                                                value={artikelnummerFilterInternalValue}
                                                onKeyDown={onArtikelnummerFilterKeyDown}
                                                onFieldChange={setArtikelnummerFilterInternalValue}
                                                onFieldBlur={setArtikelnummerFilter}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </FeatureToggle>
                    </div>
                    {allowSammelbestellung && !isMobile && currentSammelbestellung.length === 0 && (
                        <div data-testid="tourenplanAdvice" className={styles._emptySammelbestellung}>
                            Wählen Sie die Aufträge aus, die Sie als Tour zusammenfassen und gesammelt bestellen möchten.
                        </div>
                    )}
                    {isMobile ? (
                        <OverviewList
                            tableInstance={tableInstance}
                            onRowClick={(row): void => {
                                history.push(`${detailansichtBaseRoute}/${row.original['bestellnummer']}`);
                            }}
                            mobileColumns={MOBILE_COLUMNS}
                        />
                    ) : (
                        <OverviewTable tableInstance={tableInstance} />
                    )}
                    {allowSammelbestellung && !isMobile && (
                        <div className={styles._sammelbestellungPanelBottom}>
                            <SammelbestellungButton />
                        </div>
                    )}
                    <OverviewPagination tableInstance={tableInstance} setPageSizeState={setPageSizeState} totalCount={totalCount} />
                </>
            </LoadingSpinner>
        </>
    );
};

export const Bestelluebersicht = (): JSX.Element => (
    <BestelluebersichtUndAuftragskorb
        pageTitle={'Meine Bestellungen'}
        pageBaseKey={'Bestelluebersicht'}
        detailansichtBaseRoute={routes.bestellansicht}
        bestellungenAreDraftsForDebitor={false}
        getColumnsFunction={getBestelluebersichtColumns}
        allowSammelbestellung={false}
    />
);

export const Auftragskorb = (): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const unlisten: UnregisterCallback = history.listen(({ pathname }) => {
            if (pathname !== routes.tourenplan) {
                dispatch(resetCurrentSammelbestellung());
            }
        });

        return () => {
            unlisten();
        };
    }, [dispatch, history]);

    return (
        <BestelluebersichtUndAuftragskorb
            pageTitle={'Auftragskorb'}
            pageBaseKey={'Auftragsuebersicht'}
            detailansichtBaseRoute={routes.auftragsansicht}
            bestellungenAreDraftsForDebitor
            getColumnsFunction={getAuftragskorbColumns}
            allowSammelbestellung
        />
    );
};
