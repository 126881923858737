import React, { useState } from 'react';
import styles from './Agrarwetter.module.scss';
import Button from '../../shared/ui-components/Button/Button';

const Agrarwetter = (): JSX.Element => {
    const [displayedZipCode, setDisplayedZipCode] = useState<string>('');
    const [actualZipCode, setActualZipCode] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayedZipCode(event.target.value);
        let updatedZipCode = event.target.value;
        updatedZipCode = updatedZipCode.replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss');
        setActualZipCode(updatedZipCode);
    };

    const handleButtonClick = () => {
        const iframe = document.getElementById('agrarwetter-iframe') as HTMLIFrameElement;
        if (iframe) {
            iframe.src = `https://www.deuka.de/agrarwetter/${actualZipCode}/?external=true`;
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    return (
        <>
            <div className={styles._searchbar}>
                <input
                    className={styles._zipCodeInputField}
                    type="text"
                    value={displayedZipCode}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Postleitzahl/Ort"
                />
                <Button className={styles._searchZipCodeButton} onClick={handleButtonClick}>
                    Absenden
                </Button>
            </div>
            <iframe
                id="agrarwetter-iframe"
                title="Agrarwetter"
                className={styles._frame}
                src="https://www.deuka.de/agrarwetter/?external=true"
            />
        </>
    );
};

export default Agrarwetter;
