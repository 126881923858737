import { TableCell } from '../../../../ui-components/Table';
import FormControl from '../../../../ui-components/FormControl/FormControl';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { PropertyEntryReadonlyProps } from '../types';
import { BestellpositionArtikelLoseWareView } from '../../../../types';
import { extractDateAndTimeFromDateString } from '../../../../helper/date-helper';

export const WunschterminUhrzeitCell = ({
    disabled,
    bestellposition,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly || bestellposition.storno) {
        const dateAndTime = extractDateAndTimeFromDateString(bestellposition.wunschtermin);
        return (
            <TableCell text storniert={bestellposition.storno}>
                <span>{dateAndTime.uhrzeit}</span>
            </TableCell>
        );
    }
    return (
        <TableCell input storniert={bestellposition.storno}>
            <FormControl
                id={`bestellpositionenView[${bestellposition.index}].debitorUhrzeit`}
                name={`bestellpositionenView[${bestellposition.index}].debitorUhrzeit`}
                inTableCell
                displayError={false}
                renderChild={(props) => {
                    return <TextInput {...props} inTableCell type="time" placeholder="hh:mm" disabled={disabled} />;
                }}
            />
        </TableCell>
    );
};
