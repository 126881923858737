import Button from '../../ui-components/Button/Button';
import ButtonGroup from '../../ui-components/ButtonGroup/ButtonGroup';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectIsCalculatingWorkflow, selectPreviousRoute } from '../../../store/Workflow.store';

type WorkflowButtonsProps = {
    onPreviousClick?: () => void;
    onNextClick: () => void;
};

const WorkflowButtons = ({ onNextClick, onPreviousClick }: WorkflowButtonsProps): JSX.Element => {
    const history = useHistory();
    const calculatingWorkflow = useSelector(selectIsCalculatingWorkflow);
    const previousRoute = useSelector(selectPreviousRoute);

    return (
        <ButtonGroup>
            <Button
                data-cy="workflow-previous"
                type="button"
                onClick={onPreviousClick || ((): void => history.push(previousRoute))}
                isSecondary={true}
                disabled={calculatingWorkflow}
            >
                Zurück
            </Button>
            <Button data-cy="workflow-next" type="button" onClick={onNextClick} disabled={calculatingWorkflow}>
                Weiter
            </Button>
        </ButtonGroup>
    );
};

export default WorkflowButtons;
