import { PropsWithChildren, ReactElement } from 'react';
import styles from '../../../views/AuswahlFuttertyp/AuswahlFuttertyp.module.scss';

type TeaserBoxProps = {
    onClick: () => void;
    image: string;
    alt?: string;
    testId?: string;
};

export const TeaserBox = ({ onClick, children, image, alt, testId }: PropsWithChildren<TeaserBoxProps>): ReactElement => {
    return (
        <button className={styles._itemLink} data-testid={testId} onClick={onClick}>
            <div className={styles._item}>
                <img className={styles._image} src={image} alt={alt} />
                <div className={styles._itemText}>{children}</div>
            </div>
        </button>
    );
};
