import styles from './WarenempfaengerAnlegen.module.scss';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Button from '../../shared/ui-components/Button/Button';
import { Warenempfaenger, WarenempfaengerWithFormFields } from '../../shared/types';
import { useDispatch } from 'react-redux';
import { addWarenempfaenger } from '../../store/Warenempfaenger.store';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import { v4 as uuidv4 } from 'uuid';
import { routes } from '../../store/Workflow.store';
import { setWarenempfaenger } from '../../store/Bestellung.store';
import { vvvoRegExp } from '../../shared/helper/vvvoNummern-helper';
import {
    EMAIL_VALIDATION_ERROR,
    FAX_VALIDATION_ERROR,
    NEWID_PREFIX,
    PHONE_VALIDATION_ERROR,
    VALIDATION_ERROR,
    VVVO_VALIDATION_ERROR,
    WEBSEITEN_VALIDATION_ERROR,
} from '../../shared/constants';
import MandatoryFormFieldHint from '../../shared/ui-components/Form/MandatoryFormFieldHint';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Kommunikationsart } from '../../shared/types/enums';
import { emptyWarenempfaenger } from '../../shared/types/defaultValues';
import { Kommunikationsdaten } from './Kommunikationsdaten';
import { ReactElement } from 'react';
import { Adressblock } from './Adressblock';
import { VvvoBlock } from './VvvoBlock';

const phoneRegExp = /^(\+[0-9/s]+)?([ -]?\([0-9/s]+([0-9/s]+)*\)[ -]?)?[0-9/s]+([ -/][0-9/s]+)*$/;
const urlRegExp = /^(?:https?:\/\/|s?ftps?:\/\/)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9./%&=?_:;-]+$/;

const WarenempfaengerAnlegen = (): ReactElement | null => {
    const warenempfaenger: Warenempfaenger = { ...emptyWarenempfaenger, partnerNummer: NEWID_PREFIX + uuidv4(), land: 'DE' };
    const dispatch = useDispatch();
    const history = useHistory();
    useDocumentTitle('Warenempfänger erfassen');

    const initialValues = { ...warenempfaenger, addVvvo: '', addEmail: '', addFax: '', addPhone: '', addWebsite: '' };

    return warenempfaenger ? (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
                hauptname: Yup.string().required(),
                strasse: Yup.string().required(),
                postleitzahl: Yup.string().required(),
                ort: Yup.string().required(),
                land: Yup.string().required(),
                vvvoNummern: Yup.array().of(Yup.string().matches(vvvoRegExp, VVVO_VALIDATION_ERROR)),
                kommunikationsdaten: Yup.array().of(
                    Yup.object().shape({
                        kommunikationsdaten: Yup.string()
                            .when('kommunikationsart', {
                                is: Kommunikationsart.EMAIL,
                                then: Yup.string().email(EMAIL_VALIDATION_ERROR),
                            })
                            .when('kommunikationsart', {
                                is: Kommunikationsart.TELEFON,
                                then: Yup.string().matches(phoneRegExp, PHONE_VALIDATION_ERROR),
                            })
                            .when('kommunikationsart', {
                                is: Kommunikationsart.FAX,
                                then: Yup.string().matches(phoneRegExp, FAX_VALIDATION_ERROR),
                            })
                            .when('kommunikationsart', {
                                is: Kommunikationsart.WEBSEITE,
                                then: Yup.string().matches(urlRegExp, WEBSEITEN_VALIDATION_ERROR),
                            }),
                    })
                ),
                addEmail: Yup.string().email(EMAIL_VALIDATION_ERROR),
                addFax: Yup.string().matches(phoneRegExp, FAX_VALIDATION_ERROR),
                addPhone: Yup.string().matches(phoneRegExp, PHONE_VALIDATION_ERROR),
                addWebsite: Yup.string().matches(urlRegExp, WEBSEITEN_VALIDATION_ERROR),
                addVvvo: Yup.string().matches(vvvoRegExp, VVVO_VALIDATION_ERROR),
            })}
            onSubmit={(values): void => {
                const warenempfaenger: Warenempfaenger = {
                    land: values.land,
                    nebenname: values.nebenname,
                    hauptname: values.hauptname,
                    kommunikationsdaten: values.kommunikationsdaten,
                    ort: values.ort,
                    partnerNummer: values.partnerNummer,
                    postleitzahl: values.postleitzahl,
                    strasse: values.strasse,
                    vvvoNummern: values.vvvoNummern,
                    vvvoNummernNew: values.vvvoNummernNew,
                };
                dispatch(addWarenempfaenger(warenempfaenger));
                dispatch(setWarenempfaenger(warenempfaenger.partnerNummer));
                history.push(routes.warenempfaengerAuswaehlen);
            }}
            validateOnChange={false}
        >
            {(formikProps: FormikProps<WarenempfaengerWithFormFields>): ReactElement => {
                const errorKeys = Object.keys(formikProps.errors);
                const touchedKeys = Object.keys(formikProps.touched);
                const hasError = errorKeys.some((errorKey) => touchedKeys.includes(errorKey));

                return (
                    <Form data-testid={'warenempfaenger-anlegen-form'} noValidate>
                        <h2>Warenempfänger erfassen</h2>
                        <NotificationBar
                            testId="validation-message-bar"
                            message={VALIDATION_ERROR}
                            dataCy="warenempfaenger-anlegen-error"
                            isVisible={hasError}
                        />
                        <div className={styles._formContainer}>
                            <div className={styles._formColumn}>
                                <Adressblock />
                            </div>
                            <div className={styles._formColumn}>
                                <VvvoBlock formikProps={formikProps} />
                                <Kommunikationsdaten formikProps={formikProps} />
                            </div>
                        </div>
                        <MandatoryFormFieldHint />
                        <ButtonGroup>
                            <Button
                                type="button"
                                data-cy="warenempfaenger-anlegen-cancel"
                                onClick={(): void => history.push(routes.warenempfaengerAuswaehlen)}
                                isSecondary
                            >
                                Abbrechen
                            </Button>
                            <Button
                                type="button"
                                data-cy="warenempfaenger-anlegen-save"
                                data-testid="submitButton"
                                onClick={formikProps.submitForm}
                            >
                                Speichern
                            </Button>
                        </ButtonGroup>
                    </Form>
                );
            }}
        </Formik>
    ) : null;
};

export default WarenempfaengerAnlegen;
