export enum BestellungErpStatus {
    F = 'F', // Freigegeben
    G = 'G', // Gesperrt
}

export enum Modifikationsstatus {
    E = 'E', // Erfolgreich
    V = 'V', // Verworfen
}

export enum Severity {
    ERROR,
    CONFIRMRED,
}

export enum Sichtbarkeit {
    ALLE = 'ALLE',
    ZUWEISUNG = 'ZUWEISUNG',
    KEINE = 'KEINE',
}

export enum BestellungGesamtstatus {
    STORNIERT_DURCH_LANDWARENHANDEL = 'STORNIERT_DURCH_LANDWARENHANDEL',
    STORNIERT_DURCH_WARENEMPFAENGER = 'STORNIERT_DURCH_WARENEMPFAENGER',
    LANDWARENHANDEL = 'LANDWARENHANDEL',
    DTC = 'DTC',
    AENDERUNGSWUNSCH = 'AENDERUNGSWUNSCH',
    NONE = 'NONE',
}

// NOTE: Represents the state of GET, DELETE, POST, PUT requests
export enum RequestState {
    INITIAL,
    LOADING,
    SUCCESSFUL,
    FAILED,
}

export enum RegistrierungsStatus {
    NEU = 'neu',
    REGISTRIERT = 'registriert',
    ALLE = 'alle',
}

export enum ArtikelTyp {
    LoseWare = 'LW',
    Sackware = 'SW',
}

export enum TextInputRule {
    ONLY_POSITIVE_INTEGERS,
    ONLY_POSITIVE_DECIMALS_WITH_ONE_DECIMAL_PLACE,
    ANY,
}

export enum Bestellprozesstyp {
    Nutztierfutter = 'NUTZTIERFUTTER',
    Heimtierfutter = 'HEIMTIERFUTTER',
}

export enum Auftragssstatus {
    InBearbeitung = 'B',
    Storniert = 'S',
    Freigegeben = 'F',
    Erledigt = 'E',
    Gesperrt = 'G',
    Ungueltig = 'U',
}

export enum Fakturierungsstatus {
    NichtFakturiert = 'NF',
    TeilweiseFakturiert = 'TF',
    KomplettFakturiert = 'KF',
}

export enum Lieferstatus {
    NichtGeliefert = 'NL',
    TeilweiseGeliefert = 'TL',
    KomplettGeliefert = 'KL',
}

export enum Auftragsart {
    Sackware = 'SW',
    LoseWare = 'LW',
    Werbemittel = 'WM',
}

// Leider anders als bei der Bestellung
export enum LieferungLieferbedingung {
    AbWerk = 'AW',
    FreiKunde = 'FS',
    Unbekannt = 'UNBEKANNT',
}

export enum Berechnungsart {
    KeinVorgabe = 'KEINE_VORGABE',
    Tagespreis = 'TAGESPREIS',
    Kontrakt = 'KONTRAKT',
}

export enum Kommunikationsart {
    TELEFON = 'TELEFON',
    EMAIL = 'EMAIL',
    FAX = 'FAX',
    WEBSEITE = 'WEBSITE',
}

export enum Lieferbedingungen {
    EX_WERK = 'ex werk',
    FRANCO = 'franco',
}

export enum LieferbedingungShortLabel {
    EX_WERK = 'Ab Werk',
    FRANCO = 'Franko',
}

export enum Rollen {
    DEBITOR = 'PORTAL_DEBITOR',
    ADMIN = 'PORTAL_ADMIN',
    RECIPIENT = 'PORTAL_RECIPIENT',
    PROSPECT = 'PORTAL_PROSPECT',
}

export enum LieferbedingungLabel {
    EX_WERK = 'Ab Werk (Besteller organisiert Abholung)',
    FRANCO = 'Franko (Deutsche Tiernahrung organisiert Zufuhr)',
}

export enum Order {
    DESC = 'desc',
    ASC = 'asc',
}
