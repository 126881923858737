import { isLoseWare, isSackwareView } from '../../helper/bestellungen-helper';
import { PropsWithChildren, ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { ArtikelTableValues } from '../../types';

type Predicate = typeof isSackwareView | typeof isLoseWare;

export const ShowBestellpositionenWhenExist = ({
    isSackOrLoseWare,
    children,
    title,
}: PropsWithChildren<{ title?: string; isSackOrLoseWare: Predicate }>): ReactElement => {
    const { values } = useFormikContext<ArtikelTableValues>();
    return (
        <>
            {values.bestellpositionenView.some(isSackOrLoseWare) ? (
                <>
                    <h4>{title}</h4>
                    {children}
                </>
            ) : null}
        </>
    );
};
