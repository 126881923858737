import { call, put, SagaGenerator } from 'typed-redux-saga';
import { getData } from '../shared/fetchApi';
import { getArtikelSackwareFailed, GetArtikelSackwareParams, getArtikelSackwareSucceeded } from '../store/ArtikelSackware.store';
import { PayloadAction } from '@reduxjs/toolkit';
import { CallEffect, PutEffect } from 'redux-saga/effects';
import { ArtikelSackware } from '../shared/types';

type GetArtikelSackware = (url: string) => Promise<ArtikelSackware[]>;
export function* getArtikelSackwareSaga({ payload }: PayloadAction<GetArtikelSackwareParams>): SagaGenerator<void, PutEffect | CallEffect> {
    try {
        const { warenempfaengerPartnerNummer, rechnungsempfaengerPartnerNummer, werkId, bestellprozesstyp } = payload;
        const urlPath = warenempfaengerPartnerNummer
            ? `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/warenempfaenger/${warenempfaengerPartnerNummer}/artikel/sackware`
            : `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/artikel/sackware`;
        const urlQuery = werkId
            ? `?werkId=${werkId}&process=${bestellprozesstyp.toUpperCase()}`
            : `?process=${bestellprozesstyp.toUpperCase()}`;

        const artikel = yield* call<[string], GetArtikelSackware>(getData, `${urlPath}${urlQuery}`);

        yield* put(getArtikelSackwareSucceeded(artikel));
    } catch (error: unknown) {
        if (error instanceof Error) {
            yield* put(getArtikelSackwareFailed(error.message));
        }
    }
}
