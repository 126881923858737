import React from 'react';
import { FormColumn } from './FormColumn';
import formStyles from './Form.module.scss';
import { useMediaQuery } from 'react-responsive';
import { mobileQuery } from '../../../views/BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb';

export const FormColumnContent: React.FC<{ content: string | undefined; title: string }> = ({ content, title }) => {
    const isMobile = useMediaQuery({ query: mobileQuery });
    if (isMobile && !content) return <FormColumn />;
    return (
        <FormColumn isSection>
            <div className={formStyles._sectionHeader}>
                <h3>{title}</h3>
            </div>
            <div>{content || '-'}</div>
        </FormColumn>
    );
};
