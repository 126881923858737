import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData, getDataWithResponseHeaders } from '../shared/fetchApi';
import { BestellungDetails, Lieferung } from '../shared/types';
import {
    GET_LIEFERUNG_FAILED,
    GET_LIEFERUNG_SUCCEEDED,
    GET_LIEFERUNGEN_FAILED,
    GET_LIEFERUNGEN_SUCCEEDED,
    GetLieferungAction,
    GetLieferungenAction,
} from '../store/Lieferung.store';

export function* getLieferungSaga(action: GetLieferungAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const lieferung = (yield call(getData, `lieferungen/${action.vertriebsauftragsnummer}`)) as unknown as Lieferung;

        yield put({ type: GET_LIEFERUNG_SUCCEEDED, lieferung: lieferung });
    } catch (e) {
        yield put({ type: GET_LIEFERUNG_FAILED });
    }
}

export function* getLieferungenSaga(action: GetLieferungenAction): IterableIterator<PutEffect | CallEffect> {
    const filter = Object.entries(action.filter || {}).reduce(
        (acc, [filtername, filterwert]) => `${acc}&${encodeURIComponent(filtername)}=${encodeURIComponent(filterwert.toString())}`,
        ''
    );
    const url = action.bestellnummer
        ? `bestellungen/${action.bestellnummer}/lieferungen?page=${action.page - 1}&size=${action.size}&sort=${action.sortColumn},${
              action.sortOrder
          }`
        : `lieferungen?page=${action.page - 1}&size=${action.size}&sort=${action.sortColumn},${action.sortOrder}${filter}`;
    try {
        const [lieferungen, headers] = (yield call(getDataWithResponseHeaders, url)) as unknown as [BestellungDetails[], Headers];

        const totalCount = headers.get('x-total-count');
        yield put({ type: GET_LIEFERUNGEN_SUCCEEDED, lieferungen: lieferungen, totalCount });
    } catch (e) {
        yield put({ type: GET_LIEFERUNGEN_FAILED });
    }
}
