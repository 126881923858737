import { InternerArtikel } from '../shared/types';

export const SEARCH_INTERNE_ARTIKEL = 'SEARCH_INTERNE_ARTIKEL';
export const SEARCH_INTERNE_ARTIKEL_SUCCEEDED = 'SEARCH_INTERNE_ARTIKEL_SUCCEEDED';
export const SEARCH_INTERNE_ARTIKEL_FAILED = 'SEARCH_INTERNE_ARTIKEL_FAILED';
export const CLEAR_INTERNE_ARTIKEL = 'CLEAR_INTERNE_ARTIKEL';

export interface SearchInterneArtikelAction {
    type: typeof SEARCH_INTERNE_ARTIKEL;
    searchQuery: string;
    partnernummer: string;
}

export interface SearchInterneArtikelSucceededAction {
    type: typeof SEARCH_INTERNE_ARTIKEL_SUCCEEDED;
    interneArtikel: InternerArtikel[];
}

export interface SearchInterneArtikelFailedAction {
    type: typeof SEARCH_INTERNE_ARTIKEL_FAILED;
}

export interface ClearInterneArtikelAction {
    type: typeof CLEAR_INTERNE_ARTIKEL;
}

export function searchInterneArtikel(searchQuery: string, partnernummer: string): SearchInterneArtikelAction {
    return {
        type: SEARCH_INTERNE_ARTIKEL,
        searchQuery,
        partnernummer,
    };
}

export function searchInterneArtikelSucceeded(interneArtikel: InternerArtikel[]): SearchInterneArtikelSucceededAction {
    return {
        type: SEARCH_INTERNE_ARTIKEL_SUCCEEDED,
        interneArtikel,
    };
}

export function searchInterneArtikelFailed(): SearchInterneArtikelFailedAction {
    return {
        type: SEARCH_INTERNE_ARTIKEL_FAILED,
    };
}

export function clearInterneArtikel(): ClearInterneArtikelAction {
    return {
        type: CLEAR_INTERNE_ARTIKEL,
    };
}

export type InterneArtikelSucheActions =
    | SearchInterneArtikelAction
    | SearchInterneArtikelSucceededAction
    | SearchInterneArtikelFailedAction
    | ClearInterneArtikelAction;

export type InterneArtikelSucheState = {
    interneArtikel: InternerArtikel[];
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
};

export const INITIAL_INTERNE_ARTIKEL_SUCHE_STATE: InterneArtikelSucheState = {
    interneArtikel: [],
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
};

export function interneArtikelSucheReducer(
    state = INITIAL_INTERNE_ARTIKEL_SUCHE_STATE,
    action: InterneArtikelSucheActions
): InterneArtikelSucheState {
    switch (action.type) {
        case SEARCH_INTERNE_ARTIKEL:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
                loadFinished: false,
            };
        case SEARCH_INTERNE_ARTIKEL_FAILED:
            return {
                ...state,
                loadFailed: true,
                isLoading: false,
                loadFinished: true,
            };
        case SEARCH_INTERNE_ARTIKEL_SUCCEEDED:
            return {
                ...state,
                interneArtikel: action.interneArtikel,
                loadFailed: false,
                isLoading: false,
                loadFinished: true,
            };
        case CLEAR_INTERNE_ARTIKEL:
            return INITIAL_INTERNE_ARTIKEL_SUCHE_STATE;
        default:
            return state;
    }
}
