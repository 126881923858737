import { CellProps, Column } from 'react-table';
import { UebersichtKontrakt } from '../../shared/types';
import styles from '../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb.module.scss';
import kontraktStyle from './Kontraktuebersicht.module.scss';
import dayjs from 'dayjs';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import TableCell from '../../shared/ui-components/Table/ReactTable/TableCell';
import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import iconDetailsLink from '../../assets/icon-details-link.svg';
import { routes } from '../../shared/constants';
import { convertDateWithTimeFromIsoStringToDateInfo } from '../../shared/helper/date-helper';
import { amountNumberFormat } from '../../shared/helper/format-helper';

export const EmptyColumn = ({ withBackGround = false }: { withBackGround?: boolean }): JSX.Element => {
    const cellStyles = useMemo(() => {
        return [tableStyles._tableCell, withBackGround ? tableStyles['_tableCell--empty'] : ''].join(' ');
    }, [withBackGround]);
    return <td className={cellStyles} />;
};

export const kontraktColumn = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        id: 'kontraktNummer',
        Header: () => (
            <>
                Kontrakt /
                <br />
                <div className={styles._subInfo}>Stand</div>
            </>
        ),
        accessor: function accessor(row: UebersichtKontrakt): [string, string] {
            return [row.kontraktNummer, row.lastModifiedAt];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): JSX.Element {
            const { cell } = cellProps;
            const [kontraktNummer, lastModifiedAt] = cell.value;

            if (!kontraktNummer) {
                if (isMobile) {
                    return <></>;
                }
                return <EmptyColumn withBackGround />;
            }

            const lastModifiedAtDateInfo = convertDateWithTimeFromIsoStringToDateInfo(lastModifiedAt);
            if (isMobile) {
                return (
                    <h3 className={styles._mobileBestellnummer}>
                        <div>Kontrakt&nbsp;{kontraktNummer}</div>
                        <div className={styles._subInfo}>
                            Stand {lastModifiedAtDateInfo.datum}&nbsp;{lastModifiedAtDateInfo.uhrzeit}
                        </div>
                    </h3>
                );
            }

            return (
                <TableCell cell={cell}>
                    <div>{kontraktNummer}</div>
                    <div className={styles._subInfo}>{lastModifiedAtDateInfo.datum}</div>
                    <div className={styles._subInfo}>{lastModifiedAtDateInfo.uhrzeit}</div>
                </TableCell>
            );
        },
    };
};

export const kontraktpartnerColumn = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        id: 'kontraktpartnerName',
        Header: 'Kontraktpartner',
        accessor: function accessor(row: UebersichtKontrakt): string[] {
            return [row.kontraktpartnerName, row.kontraktpartnerNummer];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): ReactElement {
            const { cell } = cellProps;
            const [kontraktPartnerName, kontraktpartnerNummer] = cell.value;

            if (!kontraktPartnerName && !kontraktpartnerNummer) {
                if (isMobile) {
                    return <></>;
                }
                return <EmptyColumn withBackGround />;
            }

            if (isMobile) {
                return <TextItem label="Kontraktpartner" text={kontraktPartnerName} compressed />;
            }

            return (
                <TableCell cell={cell}>
                    <div>{kontraktPartnerName}</div>
                    <div className={styles._subInfo}>{kontraktpartnerNummer}</div>
                </TableCell>
            );
        },
    };
};

export const kontraktPreislistenArt = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        id: 'preislistenArt',
        Header: 'Preislistenart',
        accessor: function accessor(row: UebersichtKontrakt): string[] {
            return [row.preislistenArt, row.kontraktNummer];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): JSX.Element {
            const { cell } = cellProps;
            const [preislistenArt, kontraktNummer] = cell.value;

            if (!preislistenArt && !kontraktNummer) {
                if (isMobile) {
                    return <></>;
                }
                return <EmptyColumn withBackGround />;
            }

            if (isMobile) {
                return <TextItem label="Preislistenarten" text={preislistenArt} compressed />;
            }

            return <TableCell cell={cell}>{preislistenArt}</TableCell>;
        },
    };
};

export const kontraktWeNameColumn = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        id: 'warenempfaengerName',
        Header: 'Warenempfänger',
        accessor: function accessor(row: UebersichtKontrakt): (string | number)[] {
            return [row.warenempfaengerName, row.warenempfaengerNummer, row.anzahlWarenempfaenger, row.kontraktpartnerName];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): ReactElement {
            const { cell } = cellProps;
            const [warenempfaengerName, warenempfaengerNummer, anzahlWarenempfaenger, kontraktpartnerName] = cell.value;

            if (!warenempfaengerName && !kontraktpartnerName) {
                if (isMobile) {
                    return <></>;
                }
                return <EmptyColumn withBackGround />;
            }

            if (isMobile) {
                return <TextItem label="Warenempfänger" text={warenempfaengerName} compressed />;
            }

            return (
                <TableCell cell={cell}>
                    <div>{warenempfaengerName}</div>
                    <div className={styles._subInfo}>{warenempfaengerNummer}</div>
                    <div className={styles._subInfo}>{anzahlWarenempfaenger > 1 ? '...' : ''}</div>
                </TableCell>
            );
        },
    };
};

export const kontraktLieferDatumAbColumn = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        Header: 'Gültig Ab',
        id: 'lieferDatumAb',
        accessor: function accessor(row: UebersichtKontrakt): string[] {
            return [row.lieferDatumAb, row.kontraktNummer];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): JSX.Element {
            const { cell } = cellProps;
            const [lieferDatumAb, kontraktNummer] = cell.value;
            if (!lieferDatumAb && !kontraktNummer) {
                if (isMobile) {
                    return <></>;
                }
                return <EmptyColumn withBackGround />;
            }
            const datum = dayjs(lieferDatumAb).format('DD.MM.YYYY');
            if (isMobile) {
                return <TextItem label="Gültig Ab" text={datum} compressed />;
            }

            return <TableCell cell={cell}>{datum}</TableCell>;
        },
    };
};
export const kontraktLieferDatumBisColumn = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        Header: 'Gültig Bis',
        id: 'lieferDatumBis',
        accessor: function accessor(row: UebersichtKontrakt): string[] {
            return [row.lieferDatumBis, row.kontraktNummer];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): JSX.Element {
            const { cell } = cellProps;
            const [lieferDatumBis, kontraktNummer] = cell.value;

            if (!lieferDatumBis && !kontraktNummer) {
                if (isMobile) {
                    return <></>;
                }
                return <EmptyColumn withBackGround />;
            }

            const datum = dayjs(lieferDatumBis).format('DD.MM.YYYY');
            if (isMobile) {
                return <TextItem label="Gültig Bis" text={datum} compressed />;
            } else {
                return <TableCell cell={cell}>{datum}</TableCell>;
            }
        },
    };
};

export const kontraktAbschlussmengeColumn = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        Header: 'Abschlussmenge',
        id: 'abschlussmenge',
        accessor: function accessor(row: UebersichtKontrakt): string[] {
            const abschlussMenge = Math.round(row.abschlussmenge * 100) / 100;
            return [`${amountNumberFormat(abschlussMenge)}  ${row.abschlussmengeEinheit}`, row.kontraktNummer];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): JSX.Element {
            const { cell } = cellProps;
            const [abschlussMenge, kontraktNummer] = cell.value;
            const isAggregatedAbschlussMenge = !kontraktNummer && abschlussMenge;

            if (!abschlussMenge) {
                return <EmptyColumn />;
            }

            if (isMobile) {
                return (
                    <TextItem
                        containerStyle={isAggregatedAbschlussMenge ? styles._mobileBestellnummer : ''}
                        additionalStyles={isAggregatedAbschlussMenge ? [kontraktStyle._aggregatedMengen] : []}
                        label="Abschlussmenge"
                        text={abschlussMenge}
                        compressed={!isAggregatedAbschlussMenge}
                    />
                );
            }

            return (
                <TableCell cell={cell} additionalClasses={[isAggregatedAbschlussMenge ? tableStyles['_tableCell--empty'] : '']}>
                    {abschlussMenge}
                </TableCell>
            );
        },
    };
};
export const kontraktRestmengeColumn = (isMobile: boolean): Column<UebersichtKontrakt> => {
    return {
        Header: () => (
            <>
                Restmenge
                <br />
                <div className={styles._subInfo}>inkl. Vorbestellungen</div>
            </>
        ),
        id: 'restmenge',
        accessor: function accessor(row: UebersichtKontrakt): string[] {
            const restMenge = Math.round(row.restmenge * 100) / 100;
            return [`${amountNumberFormat(restMenge)} ${row.restmengeEinheit}`, row.kontraktNummer];
        },
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt>): JSX.Element {
            const { cell } = cellProps;
            const [restMenge, kontraktNummer] = cell.value;
            const isAggregatedAbschlussMenge = !kontraktNummer && restMenge;

            if (!restMenge) {
                return <EmptyColumn />;
            }

            if (isMobile) {
                return (
                    <TextItem
                        additionalStyles={isAggregatedAbschlussMenge ? [kontraktStyle._aggregatedMengen] : []}
                        label="Restmenge (inkl. Vorbestellungen)"
                        text={restMenge}
                        compressed={!isAggregatedAbschlussMenge}
                    />
                );
            }

            return (
                <TableCell cell={cell} additionalClasses={[isAggregatedAbschlussMenge ? tableStyles['_tableCell--empty'] : '']}>
                    {restMenge}
                </TableCell>
            );
        },
    };
};

export const kontraktansichtLinkColumn = (): Column<UebersichtKontrakt> => {
    return {
        Header: '',
        id: 'linkColumn',
        accessor: 'kontraktNummer',
        Cell: function cell(cellProps: CellProps<UebersichtKontrakt, string>): JSX.Element {
            const { cell } = cellProps;
            const value = cell.value;
            if (!value) {
                return <TableCell cell={cell} additionalClasses={[tableStyles['_tableCell--empty']]} />;
            }
            return (
                <TableCell cell={cell}>
                    <Link to={`${routes.kontraktansicht}/${value}`} data-cy={`kontraktdetails-link-${value}`}>
                        <img src={iconDetailsLink} className={styles._editIcon} alt="Kontrakte ansehen" title="Kontrakte ansehen" />
                    </Link>
                </TableCell>
            );
        },
        disableSortBy: true,
    };
};
