import type { WerkeForArtikel, Werk } from '../shared/types';
import type { RootState } from '../configureStore';

export const GET_WERKE = 'GET_WERKE';
export const GET_WERKE_SUCCEEDED = 'GET_WERKE_SUCCEEDED';
export const GET_WERKE_FAILED = 'GET_WERKE_FAILED';
export const GET_WERKE_FOR_ARTIKEL = 'GET_WERKE_FOR_ARTIKEL';
export const GET_WERKE_FOR_ARTIKEL_SUCCEEDED = 'GET_WERKE_FOR_ARTIKEL_SUCCEEDED';
export const GET_WERKE_FOR_ARTIKEL_FAILED = 'GET_WERKE_FOR_ARTIKEL_FAILED';

export interface GetWerkeAction {
    type: typeof GET_WERKE;
    rechnungsempfaengerPartnerNummer: string;
    warenempfaengerPartnerNummer?: string;
}
export interface GetWerkeSucceededAction {
    type: typeof GET_WERKE_SUCCEEDED;
    werke: Werk[];
}
export interface GetWerkeFailedAction {
    type: typeof GET_WERKE_FAILED;
}
export interface GetWerkeForArtikelAction {
    type: typeof GET_WERKE_FOR_ARTIKEL;
    artikelNummer: string;
    rechnungsempfaengerPartnerNummer: string;
}
export interface GetWerkeForArtikelSucceededAction {
    type: typeof GET_WERKE_FOR_ARTIKEL_SUCCEEDED;
    artikelNummer: string;
    werke: Werk[];
}
export interface GetWerkeForArtikelFailedAction {
    type: typeof GET_WERKE_FOR_ARTIKEL_FAILED;
}

export function getWerke(rechnungsempfaengerPartnerNummer: string, warenempfaengerPartnerNummer?: string): GetWerkeAction {
    return {
        type: GET_WERKE,
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
    };
}
export function getWerkeSucceeded(werke: Werk[]): GetWerkeSucceededAction {
    return {
        type: GET_WERKE_SUCCEEDED,
        werke,
    };
}
export function getWerkeFailed(): GetWerkeFailedAction {
    return {
        type: GET_WERKE_FAILED,
    };
}
export function getWerkeForArtikel(artikelNummer: string, rechnungsempfaengerPartnerNummer: string): GetWerkeForArtikelAction {
    return {
        type: GET_WERKE_FOR_ARTIKEL,
        artikelNummer,
        rechnungsempfaengerPartnerNummer,
    };
}
export function getWerkeForArtikelSucceeded(artikelNummer: string, werke: Werk[]): GetWerkeForArtikelSucceededAction {
    return {
        type: GET_WERKE_FOR_ARTIKEL_SUCCEEDED,
        artikelNummer,
        werke,
    };
}
export function getWerkeForArtikelFailed(): GetWerkeForArtikelFailedAction {
    return {
        type: GET_WERKE_FOR_ARTIKEL_FAILED,
    };
}

export type WerkActions =
    | GetWerkeAction
    | GetWerkeSucceededAction
    | GetWerkeFailedAction
    | GetWerkeForArtikelAction
    | GetWerkeForArtikelSucceededAction
    | GetWerkeForArtikelFailedAction;

export type WerkeState = {
    werke: Werk[];
    loadFailed: boolean;
    isLoading: boolean;
    werkeForArtikel: WerkeForArtikel[];
    werkeLoadFailed: boolean;
};

export const INITIAL_WERKE_STATE: WerkeState = {
    werke: [],
    loadFailed: false,
    isLoading: false,
    werkeForArtikel: [],
    werkeLoadFailed: false,
};

export const WerkSelectors = {
    selectWerkeForArtikel(state: RootState): WerkeForArtikel[] {
        return state.werke.werkeForArtikel;
    },
    selectWerkeLoadFailed(state: RootState): boolean {
        return state.werke.werkeLoadFailed;
    },
};

const sortWerke = (a: Werk, b: Werk): number => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
};

export function werkeReducer(state = INITIAL_WERKE_STATE, action: WerkActions): WerkeState {
    switch (action.type) {
        case GET_WERKE:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
            };
        case GET_WERKE_SUCCEEDED:
            return {
                ...state,
                werke: action.werke.sort(sortWerke),
                loadFailed: false,
                isLoading: false,
            };
        case GET_WERKE_FAILED:
            return {
                ...state,
                loadFailed: true,
                isLoading: false,
            };
        case GET_WERKE_FOR_ARTIKEL:
            return {
                ...state,
                werkeLoadFailed: false,
            };
        case GET_WERKE_FOR_ARTIKEL_SUCCEEDED:
            const werkeForArtikelOhneArtikelAusAction = state.werkeForArtikel.filter((wfa) => wfa.artikelNummer !== action.artikelNummer);
            return {
                ...state,
                werkeForArtikel: [
                    ...werkeForArtikelOhneArtikelAusAction,
                    {
                        artikelNummer: action.artikelNummer,
                        werke: action.werke.sort(sortWerke),
                    },
                ],
                werkeLoadFailed: false,
            };
        case GET_WERKE_FOR_ARTIKEL_FAILED:
            return {
                ...state,
                werkeLoadFailed: true,
            };
        default:
            return state;
    }
}
