export const amountNumberFormat = (n: number): string =>
    n.toLocaleString('de-DE', {
        maximumFractionDigits: 2,
        useGrouping: true,
        minimumFractionDigits: 2,
    });

export const currencyNumberFormat = (n: number): string =>
    n.toLocaleString('de-DE', {
        maximumFractionDigits: 2,
        useGrouping: true,
        minimumFractionDigits: 2,
    });

export const currencyUnitFormat = (unit: string): string => (unit === 'EUR' ? '\u20AC' : unit);
