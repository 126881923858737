import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData } from '../shared/fetchApi';
import { GET_VORSCHLAEGE_SUCCEEDED, GetVorschlaegeAction } from '../store/Vorschlaege.store';

export function* getVorschlaegeSaga({
    rechnungsempfaengerPartnerNummer,
    warenempfaengerPartnerNummer,
}: GetVorschlaegeAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const vorschlaege = yield call(
            getData,
            `rechnungsempfaenger/${rechnungsempfaengerPartnerNummer}/warenempfaenger/${warenempfaengerPartnerNummer}/vorschlaege`
        );
        yield put({ type: GET_VORSCHLAEGE_SUCCEEDED, vorschlaege });
    } catch (e) {
        console.error('Loading vorschläge failed');
    }
}
