import { ReactElement } from 'react';
import legendeStyles from './ModifikationsstatusLegende.module.scss';
import iconStorniert from '../../../../../assets/icon-bestellung-storniert.svg';
import iconEingangDTC from '../../../../../assets/icon-bestellung-eingang-dtc.svg';

export const ModifikationsstatusLegende = (): ReactElement => {
    return (
        <div className={legendeStyles._legende}>
            <img src={iconStorniert} alt={'Modifikation verworfen'} />
            <span>{'Modifikation verworfen'}</span>
            <img src={iconEingangDTC} alt={'Modifikation erfolgreich'} />
            <span>{'Modifikation erfolgreich'}</span>
        </div>
    );
};
