import { StornoListEntry } from './StornoListEntry';
import { StornoCell } from './StornoCell';
import { DeleteCell } from './DeleteCell';
import { DeleteListEntry } from './DeleteListEntry';

const DeleteEntry = {
    Storno: {
        Cell: StornoCell,
        List: StornoListEntry,
    },
    Delete: {
        Cell: DeleteCell,
        List: DeleteListEntry,
    },
};

export { DeleteEntry };
