import { BestellpositionArtikelLoseWareView } from '../../../../types';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../../../store/Navigation.store';
import { FieldArrayRenderProps } from 'formik';
import { TableRow } from '../../../../ui-components/Table';
import { AenderbarBisTooltip } from '../../../AenderbarBis/AenderbarBisTooltip';
import { BestelluebersichtSelectors } from '../../../../../store/Bestelluebersicht.store';
import {
    BezeichnungEntry,
    DeleteEntry,
    MengeEntry,
    SiloEntry,
    WerkeEntry,
    ModifikationsstatusEntry,
    WunschterminEntry,
    ZulagenEntry,
    PreisartEntry,
} from '../../PropertyEntries';
import { isExistingBestellposition } from '../../../../helper/bestellungen-helper';

type Props = {
    bestellposition: BestellpositionArtikelLoseWareView;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
};

export const LoseWareTableRowExistingBestellung = ({ bestellposition, arrayHelpers, onArtikelDeleted }: Props): JSX.Element => {
    const { nummer, storno, menge, positionsnummer } = bestellposition;
    const isDebitor = useSelector(selectIsDebitor);
    const aenderbarBisExpired = useSelector(BestelluebersichtSelectors.hasAenderbarBisExpiredForPosition(nummer, positionsnummer));
    const existingBestellposition = isExistingBestellposition(bestellposition);
    const isCurrentPositionGesperrt = useSelector(BestelluebersichtSelectors.isCurrentPositionGesperrt(nummer, positionsnummer));
    const isDisabled = isCurrentPositionGesperrt || aenderbarBisExpired;

    return (
        <Fragment key={nummer}>
            <AenderbarBisTooltip
                aenderbarBisExpired={aenderbarBisExpired}
                nummer={nummer}
                positionsnummer={positionsnummer}
                storno={storno}
            >
                <TableRow>
                    {isDebitor && existingBestellposition ? (
                        <ModifikationsstatusEntry.Cell nummer={nummer} positionsnummer={positionsnummer} />
                    ) : null}
                    <BezeichnungEntry.Cell bestellposition={bestellposition} disabled={isDisabled} replaceable={isDebitor} />
                    <ZulagenEntry.Cell bestellposition={bestellposition} disabled={isDisabled} />
                    {isDebitor && existingBestellposition ? (
                        <WunschterminEntry.DatumCell bestellposition={bestellposition} disabled={isDisabled} />
                    ) : null}
                    {isDebitor && existingBestellposition ? (
                        <WunschterminEntry.UhrzeitCell bestellposition={bestellposition} disabled={isDisabled} />
                    ) : null}
                    <WerkeEntry.Cell bestellposition={bestellposition} disabled={isDisabled} />
                    {isDebitor ? <PreisartEntry.Cell bestellposition={bestellposition} disabled={isDisabled} /> : null}
                    <SiloEntry.Cell bestellposition={bestellposition} disabled={isDisabled} />
                    <MengeEntry.Cell bestellposition={bestellposition} disabled={isDisabled} />
                    {existingBestellposition ? (
                        <DeleteEntry.Storno.Cell bestellposition={bestellposition} disabled={isDisabled} />
                    ) : (
                        <DeleteEntry.Delete.Cell
                            bestellposition={bestellposition}
                            arrayHelpers={arrayHelpers}
                            disabled={isDisabled}
                            onArtikelDeleted={onArtikelDeleted}
                            showConfirmDelete={menge > '0'}
                        />
                    )}
                </TableRow>
            </AenderbarBisTooltip>
            <ZulagenEntry.Row bestellposition={bestellposition} disabled={isDisabled} />
        </Fragment>
    );
};
