import { useEffect, useState, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addKfzKennzeichen,
    createKennzeichen,
    deleteKfzKennzeichen,
    getKfzKennzeichen,
    KfzKennzeichenView,
    TEMPORARY_KFZ_ID,
    updateKfzKennzeichen,
    kfzKennzeichenSelectors,
} from '../../store/KfzKennzeichen.store';
import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import iconTrashcan from '../../assets/icon-trashcan.svg';
import IconButton from '../../shared/ui-components/IconButton/IconButton';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { useFetchKfzKennzeichen } from '../../shared/hooks/useFetchKfzKennzeichen';
import { TableHeaderCell, TableCell } from '../../shared/ui-components/Table';
import {
    speditionSelectors,
    SpeditionView,
    addSpedition,
    TEMPORARY_SPEDITION_ID,
    updateSpedition,
    createSpedition,
    getSpeditionen,
    deleteSpedition,
} from '../../store/Spedition.store';
import { useFetchSpedition } from '../../shared/hooks/useFetchSpedition';

export type Props = Readonly<Record<string, unknown>>;

const KfzKennzeichenUndSpeditionenFormular = (): JSX.Element => {
    const dispatch = useDispatch();

    const hasServerErrorKfzKennzeichen = useSelector(kfzKennzeichenSelectors.hasSubmitError);
    const isLoadingKfzKennzeichen = useSelector(kfzKennzeichenSelectors.isLoading);
    const allKfzKennzeichen = useSelector(kfzKennzeichenSelectors.allKfzKennzeichen);

    const hasServerErrorSpedition = useSelector(speditionSelectors.hasSubmitError);
    const isLoadingSpeditionen = useSelector(speditionSelectors.isLoading);
    const allSpeditionen = useSelector(speditionSelectors.allSpeditionen);

    const [addKennzeichen, setAddKennzeichen] = useState('');
    const [editKennzeichen, setEditKennzeichen] = useState<KfzKennzeichenView[]>([]);
    const [hasDublettenErrorKfzKennzeichen, sethasDublettenErrorKfzKennzeichen] = useState(false);

    const [addCurrentSpedition, setAddCurrentSpedition] = useState('');
    const [editCurrentSpedition, setEditCurrentSpedition] = useState<SpeditionView[]>([]);
    const [hasDublettenErrorSpedition, setHasDublettenErrorSpedition] = useState(false);

    const [hasInvalidEmailError, setHasInvalidEmailError] = useState(false);

    useFetchKfzKennzeichen();
    useFetchSpedition();

    useEffect(() => {
        setEditKennzeichen(JSON.parse(JSON.stringify(allKfzKennzeichen)));
    }, [allKfzKennzeichen, setEditKennzeichen]);

    useEffect(() => {
        setEditCurrentSpedition(JSON.parse(JSON.stringify(allSpeditionen)));
    }, [allSpeditionen, setEditCurrentSpedition]);

    useDocumentTitle('KFZ-Kennzeichen und Speditionen');

    function onSubmitKennzeichen(value: string): void {
        const isDublette = allKfzKennzeichen.some((k) => k.kennzeichen.toLowerCase() === value.toLowerCase());
        sethasDublettenErrorKfzKennzeichen(isDublette);
        if (value.trim() !== '' && !isDublette) {
            dispatch(
                addKfzKennzeichen({
                    id: TEMPORARY_KFZ_ID,
                    kennzeichen: value,
                })
            );
            setAddKennzeichen('');
        }
    }

    function onSubmitSpedition(value: string): void {
        const isDublette = allSpeditionen.some((s) => s.email.toLowerCase() === value.toLowerCase());
        setHasDublettenErrorSpedition(isDublette);
        if (value.trim() !== '' && !isDublette) {
            if (!isValidEmail(value)) {
                setHasInvalidEmailError(true);
                return;
            } else {
                setHasInvalidEmailError(false);
            }
            dispatch(
                addSpedition({
                    id: TEMPORARY_SPEDITION_ID,
                    email: value,
                })
            );
            setAddCurrentSpedition('');
        }
    }

    function onChangeKennzeichen(value: string, kfzKennzeichen: KfzKennzeichenView, itemIndex: number): void {
        const isDublette = allKfzKennzeichen.some(({ kennzeichen }, index) => {
            return kennzeichen.toLowerCase() === value.toLowerCase() && index !== itemIndex;
        });
        const kennzeichenCopy = [...editKennzeichen];
        kennzeichenCopy[itemIndex].hasValidationError = isDublette;
        setEditKennzeichen(kennzeichenCopy);

        if (value !== allKfzKennzeichen[itemIndex].kennzeichen && !isDublette) {
            dispatch(updateKfzKennzeichen(createKennzeichen(kfzKennzeichen.id, value)));
        }
    }

    function onChangeSpedition(value: string, spedition: SpeditionView, itemIndex: number): void {
        const isDublette = allSpeditionen.some(({ email }, index) => {
            return email.toLowerCase() === value.toLowerCase() && index !== itemIndex;
        });
        const emailCopy = [...editCurrentSpedition];
        emailCopy[itemIndex].hasValidationError = isDublette;
        setEditCurrentSpedition(emailCopy);

        if (value !== allSpeditionen[itemIndex].email && !isDublette) {
            dispatch(updateSpedition(createSpedition(spedition.id, value)));
        }
    }
    function isValidEmail(email: string): boolean {
        return /\S+@\S+\.\S+/.test(email);
    }

    const onKfzKennzeichenKeyDown = (kfzKennzeichen: KfzKennzeichenView, index: number) => {
        return (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                onChangeKennzeichen(event.currentTarget.value, kfzKennzeichen, index);
            }
        };
    };
    const onSpeditionKeyDown = (spedition: SpeditionView, index: number) => {
        return (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                onChangeSpedition(event.currentTarget.value, spedition, index);
            }
        };
    };
    const onAddKfzKennzeichenKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSubmitKennzeichen(event.currentTarget.value);
        }
    };
    const onAddSpeditionKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSubmitSpedition(event.currentTarget.value);
        }
    };

    return (
        <>
            <h2>KFZ-Kennzeichen verwalten</h2>
            <NotificationBar
                testId="server-validation-message-bar"
                message="Beim Speichern ist ein Fehler aufgetreten. Bitte laden Sie neu."
                isVisible={hasServerErrorKfzKennzeichen}
                actionText={'KFZ-Kennzeichen neu laden'}
                actionCallback={(): void => {
                    dispatch(getKfzKennzeichen());
                }}
            />
            <LoadingSpinner isLoading={isLoadingKfzKennzeichen}>
                <>
                    <table className={tableStyles._table}>
                        <thead>
                            <tr className={tableStyles._tableHeaderRow}>
                                <TableHeaderCell text>KFZ-Kennzeichen</TableHeaderCell>
                                <TableHeaderCell text actionColumn>
                                    &nbsp;
                                </TableHeaderCell>
                            </tr>
                        </thead>
                        <tbody>
                            {editKennzeichen.map((kfzKennzeichen, index) => {
                                return (
                                    <tr className={tableStyles._tableBodyRow} key={index}>
                                        <TableCell text input>
                                            <TextInputWithoutFormik
                                                name={`kfzKennzeichen[${index}].kfzKennzeichen`}
                                                id={`kfzKennzeichen[${index}].kfzKennzeichen`}
                                                type="text"
                                                disabled={kfzKennzeichen.isSubmitting}
                                                inTableCell={true}
                                                data-testid={kfzKennzeichen.kennzeichen}
                                                value={kfzKennzeichen.kennzeichen}
                                                maxLength={12}
                                                onFieldChange={(value): void => {
                                                    const isDublette = allKfzKennzeichen.some(
                                                        ({ kennzeichen }, idx) =>
                                                            kennzeichen.toLowerCase() === value.toLowerCase() && idx !== index
                                                    );
                                                    sethasDublettenErrorKfzKennzeichen(isDublette);
                                                    setEditKennzeichen(
                                                        editKennzeichen.map((s, i) =>
                                                            i !== index
                                                                ? s
                                                                : {
                                                                      ...s,
                                                                      kennzeichen: value,
                                                                      hasValidationError: isDublette,
                                                                  }
                                                        )
                                                    );
                                                }}
                                                onFieldBlur={(value): void => {
                                                    onChangeKennzeichen(value, kfzKennzeichen, index);
                                                }}
                                                onKeyDown={onKfzKennzeichenKeyDown(kfzKennzeichen, index)}
                                            />
                                        </TableCell>
                                        <TableCell text actionColumn>
                                            <IconButton
                                                icon={iconTrashcan}
                                                alt="Löschen"
                                                disabled={kfzKennzeichen.isSubmitting}
                                                onClick={(event): void => {
                                                    dispatch(deleteKfzKennzeichen(kfzKennzeichen));
                                                    event.preventDefault();
                                                }}
                                            />
                                        </TableCell>
                                    </tr>
                                );
                            })}
                            <tr className={tableStyles._tableBodyRow}>
                                <TableCell text input colSpan={2}>
                                    <TextInputWithoutFormik
                                        name="addKfzKennzeichen"
                                        id="addKfzKennzeichen"
                                        inTableCell={true}
                                        type="text"
                                        placeholder="KFZ-Kennzeichen hinzufügen"
                                        value={addKennzeichen}
                                        maxLength={12}
                                        onFieldChange={(value): void => {
                                            setAddKennzeichen(value);
                                        }}
                                        onFieldBlur={(value): void | string => {
                                            onSubmitKennzeichen(value);
                                        }}
                                        onKeyDown={onAddKfzKennzeichenKeyDown}
                                    />
                                </TableCell>
                            </tr>
                        </tbody>
                    </table>
                    <NotificationBar
                        testId="duplicate-email-message-bar"
                        message="Dieses Kennzeichen existiert bereits."
                        isVisible={hasDublettenErrorKfzKennzeichen}
                    />
                </>
            </LoadingSpinner>
            <br />
            <br />
            <h2>Speditionen verwalten</h2>
            <NotificationBar
                testId="server-validation-message-bar"
                message="Beim Speichern ist ein Fehler aufgetreten. Bitte laden Sie neu."
                isVisible={hasServerErrorSpedition}
                actionText={'Speditionen neu laden'}
                actionCallback={(): void => {
                    dispatch(getSpeditionen());
                }}
            />
            <LoadingSpinner isLoading={isLoadingSpeditionen}>
                <>
                    <table className={tableStyles._table}>
                        <thead>
                            <tr className={tableStyles._tableHeaderRow}>
                                <TableHeaderCell text>Hinterlegte Speditionen</TableHeaderCell>
                                <TableHeaderCell text actionColumn>
                                    &nbsp;
                                </TableHeaderCell>
                            </tr>
                        </thead>
                        <tbody>
                            {editCurrentSpedition.map((spedition, index) => (
                                <tr className={tableStyles._tableBodyRow} key={index}>
                                    <TableCell text input>
                                        <TextInputWithoutFormik
                                            name={`spedition[${index}].spedition`}
                                            id={`spedition[${index}].spedition`}
                                            type="email"
                                            disabled={spedition.isSubmitting}
                                            inTableCell={true}
                                            data-testid={spedition.email}
                                            value={spedition.email}
                                            onFieldChange={(value): void => {
                                                const isValid = isValidEmail(value);
                                                const isDublette = allSpeditionen.some(
                                                    ({ email }, idx) => email.toLowerCase() === value.toLowerCase() && idx !== index
                                                );
                                                setHasInvalidEmailError(!isValid);
                                                setHasDublettenErrorSpedition(isDublette);
                                                setEditCurrentSpedition(
                                                    editCurrentSpedition.map((s, i) =>
                                                        i !== index
                                                            ? s
                                                            : {
                                                                  ...s,
                                                                  email: value,
                                                                  hasValidationError: !isValid || isDublette,
                                                              }
                                                    )
                                                );
                                            }}
                                            onFieldBlur={(value): void => {
                                                onChangeSpedition(value, spedition, index);
                                            }}
                                            onKeyDown={onSpeditionKeyDown(spedition, index)}
                                        />
                                    </TableCell>
                                    <TableCell text actionColumn>
                                        <IconButton
                                            icon={iconTrashcan}
                                            alt="Löschen"
                                            disabled={spedition.isSubmitting}
                                            onClick={(event): void => {
                                                dispatch(deleteSpedition(spedition));
                                                event.preventDefault();
                                            }}
                                        />
                                    </TableCell>
                                </tr>
                            ))}
                            <tr className={tableStyles._tableBodyRow}>
                                <TableCell text input colSpan={2}>
                                    <TextInputWithoutFormik
                                        name="addSpedition"
                                        id="addSpedition"
                                        inTableCell={true}
                                        type="email"
                                        placeholder="Mailadresse hinzufügen"
                                        value={addCurrentSpedition}
                                        onFieldChange={(value): void => {
                                            setAddCurrentSpedition(value);
                                        }}
                                        onFieldBlur={(value): void | string => {
                                            onSubmitSpedition(value);
                                        }}
                                        onKeyDown={onAddSpeditionKeyDown}
                                    />
                                </TableCell>
                            </tr>
                        </tbody>
                    </table>
                    <NotificationBar
                        testId="invalid-email-message-bar"
                        message="Bitte geben Sie eine gültige Mailadresse ein."
                        isVisible={hasInvalidEmailError}
                    />
                    <NotificationBar
                        testId="duplicate-email-message-bar"
                        message="Diese E-Mail-Adresse existiert bereits."
                        isVisible={hasDublettenErrorSpedition}
                    />
                </>
            </LoadingSpinner>
        </>
    );
};

export default KfzKennzeichenUndSpeditionenFormular;
