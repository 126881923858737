import type { InternerArtikel } from '../../shared/types';
import { useEffect, useState, KeyboardEvent } from 'react';
import styles from './PartnerDetail.module.scss';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';
import IconButton, { IconSize } from '../../shared/ui-components/IconButton/IconButton';
import iconAddArrow from '../../assets/icon-add-arrow.svg';
import iconTrashcan from '../../assets/icon-trashcan.svg';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import Button from '../../shared/ui-components/Button/Button';
import { useMediaQuery } from 'react-responsive';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';

type InterneArtikelComponentProps = {
    ausgewaehlteArtikel: InternerArtikel[];
    alleArtikel: InternerArtikel[];
    onChangedArtikel: (newArtikel: InternerArtikel[]) => void;
    onSearch: (searchTerm: string) => void;
    idPrefix: string;
    isLoading: boolean;
};
type InternerArtikelView = InternerArtikel & { selected: boolean };

const artikelFromArtkelView = ({ selected: _, ...artikel }: InternerArtikelView): InternerArtikel => artikel;

export const InterneArtikelzuordnung = ({
    ausgewaehlteArtikel,
    alleArtikel,
    onChangedArtikel,
    onSearch,
    idPrefix,
    isLoading,
}: InterneArtikelComponentProps): JSX.Element => {
    const artikelFromStore = alleArtikel;
    const selectedArtikel: InternerArtikel[] = ausgewaehlteArtikel;
    const [selectedArtikelView, setSelectedArtikelView] = useState<InternerArtikelView[]>([]);
    const [artikelView, setArtikelView] = useState<InternerArtikelView[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });

    useEffect(() => {
        setArtikelView(artikelFromStore.map((m) => ({ ...m, selected: false })));
    }, [artikelFromStore, setArtikelView]);

    useEffect(() => {
        if (selectedArtikel) {
            setSelectedArtikelView(selectedArtikel.map((m) => ({ ...m, selected: false })));
        }
    }, [selectedArtikel, setSelectedArtikelView]);

    const filteredArtikelView = artikelView.filter(
        (a) => !selectedArtikelView.some((av) => a.nummer === av.nummer && a.werk.id === av.werk.id)
    );

    const addSelectedArtikel = (): void => {
        const selectionToAdd = filteredArtikelView.filter((av) => av.selected);
        const selectedArtikelViewNext = [...selectedArtikelView, ...selectionToAdd].map((a) => ({
            ...a,
            selected: false,
        }));
        setSelectedArtikelView(selectedArtikelViewNext);
        onChangedArtikel(selectedArtikelViewNext.map(artikelFromArtkelView));
    };

    const onArtikelSucheKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            onSearch(searchTerm);
        }
    };

    return (
        <>
            <div className={styles._markenZuordnungContainer}>
                <div className={styles._alleMarken}>
                    <h4>Verfügbare Artikel</h4>
                    <div className={styles._suche}>
                        <TextInputWithoutFormik
                            id="artikelSuche"
                            name="artikelSuche"
                            placeholder="Suche nach verfügbaren Artikeln"
                            onFieldChange={setSearchTerm}
                            onKeyDown={onArtikelSucheKeyDown}
                        />
                        <Button
                            type="button"
                            onClick={(): void => {
                                onSearch(searchTerm);
                            }}
                            disabled={!searchTerm}
                        >
                            {isMobile ? '🔍' : 'Suche'}
                        </Button>
                    </div>
                    <div className={[styles._listContainer, styles['_listContainer--fixedHeightArtikel']].join(' ')}>
                        <LoadingSpinner isLoading={isLoading} marginTop="60px">
                            <>
                                {filteredArtikelView.map((a, index) => (
                                    <NonFormikCheckbox
                                        id={idPrefix + a.nummer + a.werk.id}
                                        name={idPrefix + a.nummer + a.werk.id}
                                        multiline={true}
                                        placement="left"
                                        label={
                                            <span>
                                                <strong>{a.bezeichnung}</strong>
                                                <br />
                                                {a.nummer}
                                                <br />
                                                Werk {a.werk.name}
                                            </span>
                                        }
                                        key={index}
                                        checked={a.selected}
                                        onFieldChange={(checked: boolean): void => {
                                            setArtikelView(
                                                artikelView.map((avLocal) =>
                                                    avLocal.nummer === a.nummer && avLocal.werk.id === a.werk.id
                                                        ? {
                                                              ...avLocal,
                                                              selected: checked,
                                                          }
                                                        : avLocal
                                                )
                                            );
                                        }}
                                    />
                                ))}
                            </>
                        </LoadingSpinner>
                    </div>
                </div>
                <div className={styles._zuordnungActions}>
                    <IconButton icon={iconAddArrow} iconSize={IconSize.LARGE} alt="Artikel hinzufügen" onClick={addSelectedArtikel} />
                </div>
                <div className={styles._ausgewaehlteMarken}>
                    <h4>Zugeordnete Artikel</h4>
                    <div className={[styles._listContainer, styles['_listContainer--fullHeight']].join(' ')}>
                        {selectedArtikelView.map((artikel, index) => (
                            <div key={index} className={styles._selectedEntry}>
                                <span>
                                    <strong>{artikel.bezeichnung}</strong>
                                    <br />
                                    {artikel.nummer}
                                    <br />
                                    Werk {artikel.werk.name}
                                </span>
                                <IconButton
                                    onClick={(): void => {
                                        const selectedMarkenViewNext = selectedArtikelView.filter(
                                            (artikelInView) =>
                                                !(artikelInView.nummer === artikel.nummer && artikelInView.werk.id === artikel.werk.id)
                                        );
                                        setSelectedArtikelView(selectedMarkenViewNext);
                                        setArtikelView(artikelView.map((artikelView) => ({ ...artikelView, selected: false })));
                                        onChangedArtikel(selectedMarkenViewNext.map(artikelFromArtkelView));
                                    }}
                                    icon={iconTrashcan}
                                    alt="Artikelzuordnung löschen"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
