import { INITIAL_BESTELLUNGEN_STATE } from './store/Bestellung.store';
import { INITIAL_WARENEMPFAENGER_STATE, isNewWarenempfaenger } from './store/Warenempfaenger.store';
import { PreloadedState } from 'redux';
import type { RootState } from './configureStore';

export const ALLOW_COOKIES_KEY = 'allow-cookies';

export const localStorageWrapper = {
    setItem: (key: string, value: string): void => {
        if (localStorage.getItem(ALLOW_COOKIES_KEY) === 'true') {
            localStorage.setItem(key, value);
        } else {
            sessionStorage.setItem(key, value);
        }
    },
    getItem: (key: string): string | null => {
        if (localStorage.getItem(ALLOW_COOKIES_KEY) === 'true') {
            return localStorage.getItem(key);
        } else {
            return sessionStorage.getItem(key);
        }
    },
};

export const loadPartialState = (): PreloadedState<Record<string, unknown>> | undefined => {
    try {
        const serializedState = localStorage.getItem('state');

        if (serializedState === null) {
            return undefined;
        }
        const parsedState = JSON.parse(serializedState);
        const hasParsedStateRightVersion = parsedState?.bestellung?.stateVersion === INITIAL_BESTELLUNGEN_STATE.stateVersion;
        if (!hasParsedStateRightVersion) {
            console.log(
                'Dismissing old state version from local storage. Storage version:',
                parsedState?.bestellung?.stateVersion,
                'Expected version:',
                INITIAL_BESTELLUNGEN_STATE.stateVersion
            );
        }
        return hasParsedStateRightVersion ? parsedState : undefined;
    } catch (err) {
        return undefined;
    }
};

export const savePartialState = (state: Partial<RootState>): void => {
    try {
        if (localStorage.getItem(ALLOW_COOKIES_KEY) === 'true') {
            const bestellungState = {
                bestellung: state.bestellung,
                workflow: state.workflow,
                warenempfaenger: {
                    ...INITIAL_WARENEMPFAENGER_STATE,
                    // lokal angelegte WE werden solange gespeichert, bis die Bestellung abgeschlossen ist:
                    warenempfaenger: state.warenempfaenger?.warenempfaenger.filter(isNewWarenempfaenger),
                },
            };
            const serializedState = JSON.stringify(bestellungState);
            localStorage.setItem('state', serializedState);
        }
    } catch (e) {
        console.error('Could not save state', e);
    }
};
