import { forwardRef, PropsWithChildren, useMemo } from 'react';
import formStyles from '../../../shared/ui-components/Form/Form.module.scss';

type FormColumProps = {
    isSection?: boolean;
    isSmall?: boolean;
};

export const FormColumn = forwardRef<HTMLDivElement, PropsWithChildren<FormColumProps>>(
    ({ children, isSection = false, isSmall = false }, ref): JSX.Element => {
        const classNames = useMemo(() => {
            if (isSection) {
                return [formStyles._formColumn, formStyles._section].join(' ');
            }

            if (isSmall) {
                return [formStyles._formSmallColumn, formStyles._section].join(' ');
            }

            return [formStyles._formColumn].join(' ');
        }, [isSection, isSmall]);

        return (
            <div ref={ref} className={classNames}>
                {children}
            </div>
        );
    }
);

FormColumn.displayName = 'FormColumn';
