import formStyles from '../../ui-components/Form/Form.module.scss';
import Button from '../../ui-components/Button/Button';
import iconArrowDown from '../../../assets/icon-arrow-down.svg';
import styles from '../../../views/Auftragsansicht/Auftragsansicht.module.scss';
import AnimateHeight from 'react-animate-height';
import { collapseAnimationDuration } from '../../ui-components/TextInput/TextInput';
import { useEffect, useState } from 'react';
import { Artikelauswahl } from './Artikelauswahl';
import { useDispatch, useSelector } from 'react-redux';
import { ArtikelSackwarenSelectors } from '../../../store/ArtikelSackware.store';
import { selectArtikelLoseWareList } from '../../../store/ArtikelLoseWare.store';
import { BestelluebersichtSelectors } from '../../../store/Bestelluebersicht.store';
import { setArtikelNummern } from '../../../store/Artikelauswahl.store';

type ArtikelauswahlDropdownProps = {
    showOnlySackWare?: boolean;
    allowAddingNewArticles?: boolean;
};

export const ArtikelauswahlDropdown = ({ showOnlySackWare = false, allowAddingNewArticles }: ArtikelauswahlDropdownProps): JSX.Element => {
    const dispatch = useDispatch();
    const [showAddArticle, setShowAddArticle] = useState(false);
    const artikelSackwareList = useSelector(ArtikelSackwarenSelectors.list);
    const artikelLoseWareList = useSelector(selectArtikelLoseWareList);
    const bestellpositionsNummern = useSelector(BestelluebersichtSelectors.artikelNummernOfCurrentBestellung);

    useEffect(() => {
        dispatch(setArtikelNummern(bestellpositionsNummern));
    }, [bestellpositionsNummern, dispatch]);

    return (
        <>
            <div
                className={[formStyles._sectionHeader, formStyles['_sectionHeader--clickable']].join(' ')}
                onClick={(): void => {
                    setShowAddArticle(!showAddArticle);
                }}
                role={'button'}
            >
                <h3>Artikel hinzufügen </h3>
                <Button isSmall={true} type="button">
                    <img
                        src={iconArrowDown}
                        className={styles._addIcon}
                        style={{
                            transform: `rotate(${showAddArticle ? 0 : -90}deg) ${showAddArticle ? 'translateY(3px)' : 'translateX(-2px)'}`,
                        }}
                        alt="Artikel hinzufügen"
                        aria-expanded={showAddArticle}
                    />
                </Button>
            </div>
            <AnimateHeight duration={collapseAnimationDuration} height={showAddArticle ? 'auto' : '0%'}>
                {showOnlySackWare ? (
                    <Artikelauswahl artikelSackwareList={artikelSackwareList} allowAddingNewArticles={allowAddingNewArticles} />
                ) : (
                    <Artikelauswahl
                        artikelSackwareList={artikelSackwareList}
                        artikelLoseWareList={artikelLoseWareList}
                        allowAddingNewArticles={allowAddingNewArticles}
                    />
                )}
            </AnimateHeight>
        </>
    );
};
