import { useCallback, useEffect } from 'react';
import { getArtikelSackware } from '../../store/ArtikelSackware.store';
import { Bestellprozesstyp } from '../types/enums';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDebitor } from '../../store/Navigation.store';

export const useFetchArtikelSackwaren = (
    rechnungsempfaengerPartnerNummer: string | undefined,
    warenempfaengerPartnerNummer: string | undefined,
    bestellprozesstyp: Bestellprozesstyp,
    werkId?: string // NOTE: Only relevant for Heimtierfutter
): (() => void) => {
    const isDebitor = useSelector(selectIsDebitor);
    const dispatch = useDispatch();

    const fetchSackwarenArtikel = useCallback(() => {
        if (rechnungsempfaengerPartnerNummer) {
            dispatch(
                getArtikelSackware({
                    bestellprozesstyp,
                    rechnungsempfaengerPartnerNummer,
                    werkId,
                    warenempfaengerPartnerNummer: isDebitor ? undefined : warenempfaengerPartnerNummer,
                })
            );
        }
    }, [bestellprozesstyp, dispatch, isDebitor, rechnungsempfaengerPartnerNummer, werkId, warenempfaengerPartnerNummer]);

    useEffect(() => {
        fetchSackwarenArtikel();
    }, [fetchSackwarenArtikel]);

    return fetchSackwarenArtikel;
};
