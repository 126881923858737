import * as Yup from 'yup';
import { date, string, TypeOf } from 'yup';
import {
    BESTELLMENGE_KOMMA_ERROR,
    KONTRAKT_VALIDATION_ERROR,
    NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR,
    NEWID_PREFIX,
    WERK_VALIDATION_ERROR,
    WUNSCHTERMIN_VALIDATION_ERRORS,
    ZULAGE_VALIDATION_ERROR,
} from '../../constants';
import { Berechnungsart } from '../../types/enums';
import { testLogischerWunschterminzeitraum } from './testFunctions';

const mengeSchema = Yup.string().matches(/^\d*(?:,\d)?$/, BESTELLMENGE_KOMMA_ERROR);

const kontraktSchema = Yup.string().when(['berechnungsart'], {
    is: (berechnungsart: Berechnungsart) => {
        return berechnungsart === Berechnungsart.Kontrakt;
    },
    then: Yup.string().required(KONTRAKT_VALIDATION_ERROR),
    otherwise: (schema) => schema.nullable(),
});

const bezeichnungSchema = Yup.string().when(['nummer'], {
    is: (nummer: string) => nummer && nummer.startsWith(NEWID_PREFIX),
    then: Yup.string().required(NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR),
});

const mengePalSchema = Yup.string()
    .matches(/^\d*(?:,\d)?$/, ' ')
    .nullable()
    .optional();
const mengeVeSchema = Yup.string()
    .matches(/^\d*(?:,\d)?$/, ' ')
    .nullable()
    .optional();

const werkIdSchema = Yup.string().when(['menge', 'mengeVe', 'mengePal'], {
    is: (menge: number | string, mengeVe: number | string, mengePal: number | string) =>
        Boolean(menge) || Boolean(mengeVe) || Boolean(mengePal),
    then: Yup.string().required(WERK_VALIDATION_ERROR),
});

const zulageTextSchema = Yup.string().when(['zulage'], {
    is: (zulage: string) => Boolean(zulage),
    then: Yup.string().required(ZULAGE_VALIDATION_ERROR),
});

export const datumWEBisSchema = date().when('datumWEVon', (datumWEVon: string) => {
    return datumWEVon ? date().min(datumWEVon, WUNSCHTERMIN_VALIDATION_ERRORS.DATUM_VON_IS_AFTER_DATUM_BIS) : date();
});

export const uhrzeitWEBisSchema = string().test(
    'logischerWunschterminzeitraum',
    WUNSCHTERMIN_VALIDATION_ERRORS.TIME_VON_IS_AFTER_TIME_BIS,
    testLogischerWunschterminzeitraum
);

export const bestellpositionViewBaseSchema = Yup.object({
    bezeichnung: bezeichnungSchema,
    kontrakt: kontraktSchema,
    menge: mengeSchema,
    mengePal: mengePalSchema,
    mengeVe: mengeVeSchema,
    werkId: werkIdSchema,
    zulageText: zulageTextSchema,
});

export type BestellpositionViewTypeShape = TypeOf<typeof bestellpositionViewBaseSchema>;
