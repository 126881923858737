import { PutEffect, CallEffect, call, put } from 'redux-saga/effects';
import { getData } from '../shared/fetchApi';
import { GET_PARTNER_FAILED, GET_PARTNER_SUCCEEDED, GetPartnerAction } from '../store/Partner.store';

export function* getPartnerSaga(action: GetPartnerAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const partner = yield call(getData, 'admin/partners/search?q=' + action.searchQuery);
        yield put({ type: GET_PARTNER_SUCCEEDED, partner });
    } catch (e) {
        yield put({ type: GET_PARTNER_FAILED });
    }
}
