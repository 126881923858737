import { getData } from '../shared/fetchApi';
import { getKontrakteForArtikelFailed, getKontrakteForArtikelSucceeded, GetKontraktePayload } from '../store/Kontrakt.store';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaGenerator } from 'typed-redux-saga';
import { Kontrakt } from '../shared/types';
import { CallEffect, PutEffect } from 'redux-saga/effects';

type GetKontrakte = (url: string) => Promise<Kontrakt[]>;
export function* getKontrakteForArtikelSaga({ payload }: PayloadAction<GetKontraktePayload>): SagaGenerator<void, PutEffect | CallEffect> {
    const { rechnungsempfaengerPartnerNummer, warenempfaengerPartnerNummer, werkId, artikelNummer, wunschtermin } = payload;

    // TODO: How do we deal with wunschtermin von -> bis
    const url = `kontrakte?rechnungsempfaenger=${rechnungsempfaengerPartnerNummer}&warenempfaenger=${warenempfaengerPartnerNummer}&artikel=${artikelNummer}&werk=${werkId}&wunschtermin=${wunschtermin}`;

    try {
        const kontrakte = yield* call<[string], GetKontrakte>(getData, url);
        yield* put(
            getKontrakteForArtikelSucceeded({
                kontrakte,
                artikelNummer,
            })
        );
    } catch (error: unknown) {
        if (error instanceof Error) {
            yield* put(getKontrakteForArtikelFailed());
        }
    }
}
