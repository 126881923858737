import SelectInputWithHookForms from '../../ui-components/SelectInput/SelectInputWithHookForms';
import { lieferbedingungOptions } from '../../helper/select-options';

export type Props = Readonly<{
    onLieferbedingungSelected: (lieferbedingung: string) => void;
}>;

const LieferbedingungSelectWithHookForms = ({ onLieferbedingungSelected }: Props): JSX.Element => {
    return (
        <div>
            <label htmlFor="lieferbedingung">Lieferart *</label>
            <SelectInputWithHookForms
                autoSelectIfOnlyOneOption={false}
                name="lieferbedingung"
                placeholder={'Lieferart auswählen'}
                options={lieferbedingungOptions}
                data-cy={'lieferbedingung'}
                classNamePrefix="react_select__form-lieferbedingung"
                onValueChange={onLieferbedingungSelected}
            />
        </div>
    );
};

export default LieferbedingungSelectWithHookForms;
