import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSollbestand } from '../../store/Sollbestand.store';
import { BestellungSelectors } from '../../store/Bestellung.store';

export const useFetchSollbestand = (warenempfaengerPartnerNummer: string | undefined): (() => void) => {
    const dispatch = useDispatch();
    const selectedWerkId = useSelector(BestellungSelectors.selectedWerkId);

    const fetchSackwarenArtikel = useCallback(() => {
        if (selectedWerkId && warenempfaengerPartnerNummer) {
            dispatch(getSollbestand(warenempfaengerPartnerNummer, selectedWerkId));
        }

        return;
    }, [dispatch, selectedWerkId, warenempfaengerPartnerNummer]);

    useEffect(() => {
        fetchSackwarenArtikel();
    }, [fetchSackwarenArtikel]);

    return fetchSackwarenArtikel;
};
