import FormControl from '../../shared/ui-components/FormControl/FormControl';
import TextInput from '../../shared/ui-components/TextInput/TextInput';

export const FormikAnsprechpartnerInput = (): JSX.Element => {
    return (
        <FormControl
            name="ansprechpartner"
            id="ansprechpartner"
            label="Kontakt für Rückfragen"
            renderChild={(props): JSX.Element => <TextInput {...props} maxLength={250} />}
        />
    );
};
