import { DetailedHTMLProps, PropsWithChildren, ThHTMLAttributes, useMemo } from 'react';
import tableStyles from './Table.module.scss';

type TableHeaderCellProps = {
    text?: boolean;
    textCentered?: boolean;
    button?: boolean;
    number?: boolean;
    header?: boolean;
    additionalStyles?: string[];
    actionColumn?: boolean;
} & DetailedHTMLProps<ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;

export const TableHeaderCell = ({
    children,
    additionalStyles,
    text = false,
    number = false,
    button = false,
    hidden = false,
    header = false,
    textCentered = false,
    actionColumn = false,
    ...thHTMLElementPops
}: PropsWithChildren<TableHeaderCellProps>): JSX.Element => {
    const tableHeaderCellClasses = useMemo(() => {
        let styles = [tableStyles._tableCell];
        if (button) {
            styles.push(tableStyles['_tableCell--button']);
        }

        if (button) {
            styles.push(tableStyles['_tableCell--button']);
        }

        if (number) {
            styles.push(tableStyles['_tableCell--number']);
        }

        if (hidden) {
            styles.push(tableStyles['_tableCell--hidden']);
        }

        if (text) {
            styles.push(tableStyles['_tableCell--text']);
        }

        if (textCentered) {
            styles.push(tableStyles['_tableCell--text-centered']);
        }

        if (additionalStyles) {
            styles = styles.concat(additionalStyles);
        }

        if (header) {
            styles.push(tableStyles['_tableCell--rowHeader']);
        }

        if (actionColumn) {
            styles.push(tableStyles._actionColumn);
        }

        return styles.join(' ');
    }, [actionColumn, additionalStyles, button, header, hidden, number, text, textCentered]);

    return (
        <th {...thHTMLElementPops} className={tableHeaderCellClasses}>
            {children}
        </th>
    );
};
