import { ReactElement, useEffect, useMemo, useState } from 'react';
import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import paginationStyles from '../../shared/ui-components/OverviewPagination/OverviewPagination.module.scss';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { Column, Row, useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../shared/ui-components/Button/Button';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import iconEdit from '../../assets/icon-pencil.svg';
import { routes } from '../../store/Workflow.store';
import type { UserParam, User } from '../../shared/types';
import { getUser, UserSelectors } from '../../store/User.store';
import { rollenOptionsIncludingAdmin } from '../../shared/helper/select-options';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { RegistrierungsStatus } from '../../shared/types/enums';
import styles from './UserTable.module.scss';

export type FilterProps = Readonly<{
    preGlobalFilteredRows: Row[];
    globalFilter: string;
    setGlobalFilter: (a: string) => void;
}>;

export type EditCellProps = Readonly<{
    cell: { value: string };
}>;

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: FilterProps): ReactElement {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((debouncedValue) => {
        setGlobalFilter(debouncedValue || undefined);
    }, 200);

    return (
        <div className={styles._searchField__container}>
            <strong>Suche: </strong>
            <input
                value={value || ''}
                onChange={(event): void => {
                    setValue(event.target.value);
                    onChange(event.target.value);
                }}
                placeholder={`in ${count} Benutzern`}
                className={styles._searchField}
            />
        </div>
    );
}

const UserTable = (): ReactElement => {
    const dispatch = useDispatch();
    const allUsers = useSelector(UserSelectors.user);
    const isLoading = useSelector(UserSelectors.isLoading);
    const { registrierungsStatus } = useParams<UserParam>();
    const showRegistrierungen = registrierungsStatus === RegistrierungsStatus.NEU;
    const showUser = registrierungsStatus === RegistrierungsStatus.REGISTRIERT;
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

    useEffect(() => {
        setFilteredUsers(
            registrierungsStatus === RegistrierungsStatus.REGISTRIERT ? allUsers.filter((u) => u.registrationValidated) : allUsers
        );
    }, [allUsers, setFilteredUsers, registrierungsStatus]);

    useEffect(() => {
        dispatch(getUser(registrierungsStatus));
    }, [dispatch, registrierungsStatus]);

    useDocumentTitle('Benutzerübersicht');

    const columns: Column[] = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'nachname',
            },
            {
                Header: 'Vorname',
                accessor: 'vorname',
            },
            {
                Header: 'Unternehmen',
                accessor: 'registration.unternehmen',
            },
            {
                Header: 'E-Mail',
                accessor: 'email',
            },
            {
                Header: (
                    <>
                        Registrierungs-
                        <br />
                        zeitpunkt
                    </>
                ),
                id: 'createdAt',
                sortType: 'datetime',
                // react-table only accepts any here
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (row: any): Date => {
                    return new Date(row.registration?.createdAt);
                },
                Cell: ({ cell: { value } }): string => {
                    return dayjs(value).format('DD.MM.YYYY - HH:mm') + ' Uhr';
                },
            },
            ...(showUser
                ? [
                      {
                          Header: 'Rolle',
                          // react-table only accepts any here
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          accessor: (row: any): string => {
                              return (rollenOptionsIncludingAdmin.find((r) => r.value === row.rolle) || { value: '', label: '' }).label;
                          },
                      },
                  ]
                : []),
            {
                Header: 'PLZ',
                accessor: 'registration.postleitzahl',
            },
            {
                Header: 'Stadt',
                accessor: 'registration.stadt',
            },
            {
                Header: '',
                id: 'editColumn',
                // react-table only accepts any here
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: 'id',
                Cell: function editCell({ cell: { value } }: EditCellProps): ReactElement {
                    return (
                        <Link to={`${routes.benutzerdetails}/${value}`}>
                            <img src={iconEdit} className={styles._editIcon} alt="Benutzer bearbeiten" title="Benutzer bearbeiten" />
                        </Link>
                    );
                },
                disableSortBy: true,
            },
        ],
        [showUser]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: filteredUsers,
            initialState: {
                sortBy: [
                    {
                        id: 'nachname',
                        desc: false,
                    },
                ],
                pageSize: 10,
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <>
            <div className={styles._headerContainer}>
                <h2>{showRegistrierungen ? 'Offene Registrierungen' : 'Registrierte Benutzer'}</h2>
                {!isLoading && (
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                )}
            </div>
            <LoadingSpinner isLoading={isLoading}>
                <div className={[tableStyles._tableContainer, tableStyles['_tableContainer--scrolling']].join(' ')}>
                    <table {...getTableProps()} className={tableStyles._table}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    className={[
                                        tableStyles._tableHeaderRow,
                                        tableStyles['_tableHeaderRow--notSticky'],
                                        tableStyles['_tableHeaderRow--nowrap'],
                                    ].join(' ')}
                                    key={index}
                                >
                                    {headerGroup.headers.map((column, i) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={tableStyles['_tableCell--text']}
                                            key={i}
                                        >
                                            {column.render('Header')}
                                            <div className={tableStyles._tableSortElement}>
                                                {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} className={tableStyles._tableBodyRow} key={row.index}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={[tableStyles._tableCell, tableStyles['_tableCell--text']].join(' ')}
                                                    key={index}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <div className={paginationStyles._paginationContainer}>
                        <span>zu Seite:</span>
                        <div className={paginationStyles._pageJump}>
                            <input
                                type="number"
                                defaultValue={state.pageIndex + 1}
                                onChange={(event): void => {
                                    const page = event.target.value ? Number(event.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                className={paginationStyles._pageJump__input}
                            />
                        </div>{' '}
                        <span>Zeilen anzeigen:</span>
                        <select
                            value={state.pageSize}
                            onChange={(e): void => {
                                setPageSize(Number(e.target.value));
                            }}
                            className={paginationStyles._rowAmount}
                        >
                            {[10, 20, 30, 50, 100].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                        <Button onClick={(): void => gotoPage(0)} disabled={!canPreviousPage} isSmall={true}>
                            {'<<'}
                        </Button>
                        <Button onClick={(): void => previousPage()} disabled={!canPreviousPage} isSmall={true}>
                            {'<'}
                        </Button>
                        <span className={paginationStyles._pageCounter}>
                            Seite&nbsp;
                            <strong>
                                {state.pageIndex + 1} von {pageOptions.length}
                            </strong>
                        </span>
                        <Button onClick={(): void => nextPage()} disabled={!canNextPage} isSmall={true}>
                            {'>'}
                        </Button>
                        <Button onClick={(): void => gotoPage(pageCount - 1)} disabled={!canNextPage} isSmall={true}>
                            {'>>'}
                        </Button>
                    </div>
                </div>
            </LoadingSpinner>
        </>
    );
};

export default UserTable;
