import { FormEvent, InputHTMLAttributes } from 'react';
import Tippy from '@tippyjs/react';
import styles from './TextInput.module.scss';
import { useFormContext } from 'react-hook-form';
import useTextInputRuleProps from '../../hooks/useTextInputRuleProps';
import { TextInputRule } from '../../types/enums';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

type AllOptionalInputProps = InputHTMLAttributes<HTMLInputElement>;
type RequiredInputProps = Required<Pick<InputHTMLAttributes<HTMLInputElement>, 'name'>>;

type TextInputProps = AllOptionalInputProps &
    RequiredInputProps & {
        inTableCell?: boolean;
        textInputRule?: TextInputRule;
        onFieldChange?: (value: string) => void;
        onFieldBlur?: (value: string) => void | string;
        hasError?: boolean;
        valueAsNumber?: boolean;
        errorMessage?: string;
        allowEditAfterSubmit?: boolean;
    };

const TextInputWithHookForms = (props: TextInputProps): JSX.Element => {
    const {
        inTableCell,
        textInputRule = TextInputRule.ANY,
        onFieldChange,
        onFieldBlur,
        hasError,
        errorMessage,
        name,
        valueAsNumber,
        allowEditAfterSubmit,
        ...textFieldProps
    } = props;
    const { register, formState } = useFormContext();
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const baseStyles = inTableCell ? [styles._input, styles['_input--inTableCell']] : [styles._input, styles['_input--regular']];
    const inputStyles = hasError ? [...baseStyles, styles['_input--error']] : baseStyles;

    const { ref, onChange, onBlur, ...rest } = register(name, { valueAsNumber });
    const { isSubmitSuccessful } = formState;

    const textInputRuleProps = useTextInputRuleProps(textInputRule);

    return (
        <Tippy content={errorMessage} disabled={!hasError || isMobile}>
            <input
                className={inputStyles.join(' ')}
                ref={ref}
                {...textFieldProps}
                {...rest}
                {...textInputRuleProps}
                onChange={(e: FormEvent<HTMLInputElement>): void => {
                    if (onFieldChange) {
                        onFieldChange(e.currentTarget.value);
                    }
                    void onChange(e);
                }}
                onBlur={(e: FormEvent<HTMLInputElement>): void => {
                    if (onFieldBlur) {
                        onFieldBlur(e.currentTarget.value);
                    }
                    void onBlur(e);
                }}
                readOnly={isSubmitSuccessful && !allowEditAfterSubmit}
            />
        </Tippy>
    );
};

TextInputWithHookForms.displayName = 'TextInputWithHookForms';

export default TextInputWithHookForms;
