import { TableInstance } from 'react-table';
import TableHeaderRow from './TableHeaderRow';
import TableHeaderRowWithoutSorting from './TableHeaderWithoutSorting';

interface TableHeaderProps<T extends Record<string, unknown>> extends TableInstance<T> {
    enableSorting?: boolean;
    showSortingIcon?: boolean;
}

const TableHeader = <T extends Record<string, unknown>>(tableInstance: TableHeaderProps<T>): JSX.Element => {
    const { headerGroups, enableSorting = false, showSortingIcon = false } = tableInstance;

    if (enableSorting) {
        return (
            <thead>
                {headerGroups.map((headerGroup, index) => {
                    return <TableHeaderRow {...tableInstance} key={index} headerGroup={headerGroup} showSortingIcon={showSortingIcon} />;
                })}
            </thead>
        );
    }
    return (
        <thead>
            {headerGroups.map((headerGroup, index) => {
                return <TableHeaderRowWithoutSorting {...tableInstance} key={index} headerGroup={headerGroup} />;
            })}
        </thead>
    );
};

export default TableHeader;
