import { useCallback, useEffect, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import SelectInputWithHookForms from '../../../../shared/ui-components/SelectInput/SelectInputWithHookForms';
import { useDispatch, useSelector } from 'react-redux';
import { getWerkeForArtikel, WerkSelectors } from '../../../../store/Abholwerk.store';
import TableCell from '../../../../shared/ui-components/Table/ReactTable/TableCell';
import { useFormContext } from 'react-hook-form';
import type { Option } from '../../../../shared/helper/select-options';
import type { TourenplanForm, TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from '../../TourenplanTypes';

type TourenplanBestellungenWithFieldName = Partial<TourenplanLoseWareBestellung | TourenplanSackwareBestellung> & {
    fieldName: keyof Pick<TourenplanForm, 'loseWareBestellungen' | 'sackwareBestellungen'>;
};

const WerkColumnCell = (
    cellProps: CellProps<TourenplanLoseWareBestellung | TourenplanSackwareBestellung, TourenplanBestellungenWithFieldName>
): JSX.Element => {
    const dispatch = useDispatch();
    const rowIndex = cellProps.row.index;
    const cell = cellProps.cell;
    const { partnerNummer, nummer, fieldName } = cellProps.value;
    const { setValue } = useFormContext<TourenplanForm>();
    const werkOptions = useSelector(WerkSelectors.selectWerkeForArtikel);

    useEffect(() => {
        if (nummer && partnerNummer) {
            // TODO: Fetch Werk options for all articles in a batch and just assign to correct ones here
            dispatch(getWerkeForArtikel(nummer, partnerNummer));
        }
    }, [nummer, partnerNummer, dispatch]);

    const werkeForArtikel: Option[] = useMemo((): Option[] => {
        const werkeForOneArticle = werkOptions.find((entry) => entry.artikelNummer === nummer)?.werke ?? [];
        return werkeForOneArticle.map(
            (w): Option => ({
                value: w.id,
                label: w.name,
            })
        );
    }, [werkOptions, nummer]);

    const onValueChange = useCallback(
        (value) => {
            const werkName = werkeForArtikel.find((werkOption) => werkOption.value === value)?.label as string;

            if (werkName) {
                setValue(`${fieldName}.${rowIndex}.werk`, werkName);
            }
        },
        [fieldName, rowIndex, setValue, werkeForArtikel]
    );

    return (
        <TableCell cell={cell} select>
            <SelectInputWithHookForms
                onValueChange={onValueChange}
                classNamePrefix="react_select__form-werk"
                data-cy="werk"
                id={`${fieldName}.${rowIndex}.werkId`}
                name={`${fieldName}.${rowIndex}.werkId`}
                options={werkeForArtikel ? werkeForArtikel : []}
                inTableCell={true}
                placeholder="Auswahl Werk"
                autoSelectIfOnlyOneOption={true}
            />
        </TableCell>
    );
};

const werkColumnConfig = <T extends TourenplanLoseWareBestellung | TourenplanSackwareBestellung>(
    fieldName: keyof Pick<TourenplanForm, 'loseWareBestellungen' | 'sackwareBestellungen'>
): Column<T> => ({
    id: 'werk',
    Header: 'Werk',
    accessor: (bestellung: T): TourenplanBestellungenWithFieldName => {
        return {
            werkId: bestellung.werkId,
            nummer: bestellung.nummer,
            partnerNummer: bestellung.partnerNummer,
            fieldName: fieldName,
        };
    },
    Cell: WerkColumnCell,
});

export const loseWareWerkColumnConfig = werkColumnConfig<TourenplanLoseWareBestellung>('loseWareBestellungen');
export const sackwareWerkColumnConfig = werkColumnConfig<TourenplanSackwareBestellung>('sackwareBestellungen');
