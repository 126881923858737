import { useEffect } from 'react';
import Select, { NamedProps } from 'react-select';
import { useController } from 'react-hook-form';
import type { Option } from '../../helper/select-options';
import styles from './SelectInput.module.scss';
import './react-select-overrides.css';
import { createCustomStyles } from './CustomStyles';

export type SelectInputProps = {
    autoSelectIfOnlyOneOption: boolean;
    inTableCell?: boolean;
    hideNativeSelect?: boolean;
    onValueChange?: (value: string) => void;
    menuAlignment?: 'right' | 'left';

    // List of NamedProps overrides to make them required
    name: string;
    options: Option[];
} & NamedProps<Option>;

const SelectInputWithHookForms = ({
    autoSelectIfOnlyOneOption,
    inTableCell,
    onValueChange,
    menuAlignment,
    ...additionalProps
}: SelectInputProps): JSX.Element => {
    const { options, menuPlacement, noOptionsMessage, name } = additionalProps;
    const { field, fieldState, formState } = useController({
        name,
    });

    useEffect(() => {
        if (autoSelectIfOnlyOneOption && options.length === 1) {
            const value = options[0].value;
            if (field.value !== value) {
                field.onChange(value);
                onValueChange && onValueChange(value);
            }
        }
    }, [options, onValueChange, field, autoSelectIfOnlyOneOption]);

    const selectedOption = (options ? options.find((option) => option.value === field.value) || '' : '') as Option;
    const selectStyles = inTableCell ? [styles._select, styles['_select--inTableCell']] : [styles._select];
    const onChange = (option: Option | null): void => {
        if (option !== null) {
            field.onChange(option.value);
            onValueChange && onValueChange(option.value);
        } else {
            field.onChange(null);
        }
    };

    return (
        <Select<Option>
            {...additionalProps}
            className={selectStyles.join(' ')}
            styles={createCustomStyles(fieldState.invalid, inTableCell, menuAlignment)}
            name={field.name}
            value={selectedOption}
            onBlur={field.onBlur}
            onChange={onChange}
            menuPlacement={menuPlacement || 'auto'}
            menuShouldScrollIntoView={true}
            isDisabled={formState.isSubmitSuccessful}
            noOptionsMessage={noOptionsMessage ?? ((): string => 'Keine Einträge')}
        />
    );
};

export default SelectInputWithHookForms;
