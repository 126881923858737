import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { KontraktParam } from '../../shared/types';
import { KontraktuebersichtSelectors } from '../../store/Kontraktuebersicht.store';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { useMediaQuery } from 'react-responsive';
import { mobileQuery } from '../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import Button from '../../shared/ui-components/Button/Button';
import { routes } from '../../shared/constants';
import { KontraktansichtListReadOnly } from './KontraktansichtListReadOnly';
import { KontraktansichtTableReadonly } from './KontraktansichtTableReadonly';
import { GeneralKontraktInformationReadonlyTable } from './GeneralKontraktInformationReadonlyTable';
import { useKontraktDetails } from '../../shared/hooks/useKontraktDetails';

export const Kontraktansicht = (): JSX.Element => {
    const { kontraktNummer } = useParams<KontraktParam>();
    const reloadFetchKontrakteDetails = useKontraktDetails(kontraktNummer);
    const history = useHistory();
    const isLoading = useSelector(KontraktuebersichtSelectors.currentKontraktIsLoading);
    const loadFailed = useSelector(KontraktuebersichtSelectors.currentKontraktFailed);
    const isMobile = useMediaQuery({ query: mobileQuery });
    useDocumentTitle('Kontrakt ' + kontraktNummer);

    return (
        <>
            <h2>Kontrakt {kontraktNummer}</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message="Der Kontrakt konnte nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={reloadFetchKontrakteDetails}
            />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    <GeneralKontraktInformationReadonlyTable />
                    {isMobile ? <KontraktansichtListReadOnly /> : <KontraktansichtTableReadonly />}
                    <ButtonGroup>
                        <Button onClick={(): void => history.push(routes.kontraktuebersicht)}>Zurück</Button>
                    </ButtonGroup>
                </>
            </LoadingSpinner>
        </>
    );
};
