import { ReactNode } from 'react';
import FormControl from '../../ui-components/FormControl/FormControl';
import SelectInput from '../../ui-components/SelectInput/SelectInput';
import { Option } from '../../helper/select-options';
import { SpeditionView } from '../../../store/Spedition.store';
import styles from './SpeditionAuswahl.module.scss';

export type Props = Readonly<{
    spedition: Option[];
    onSpeditionCreated: (spedition: string) => void;
    onSpeditionSelected: (spedition: string) => void;
}>;

export const mapSpeditionIdToSpedition = (allSpeditionen: SpeditionView[], selectedspeditionId: string | undefined): string => {
    if (!selectedspeditionId) return '';
    return allSpeditionen.find((SpeditionView) => SpeditionView.id === selectedspeditionId)?.email ?? '';
};

const SpeditionAuswahl = ({ spedition, onSpeditionCreated, onSpeditionSelected }: Props): JSX.Element => {
    return (
        <FormControl
            name="spedition"
            id="spedition"
            label="Spediteur"
            renderChild={(props): JSX.Element => (
                <SelectInput
                    {...props}
                    options={spedition}
                    onCreateItem={onSpeditionCreated}
                    onValueChange={onSpeditionSelected}
                    formatCreateLabel={(inputValue: string): ReactNode => (
                        <div className={styles._newSelectOption}>{`"${inputValue}" anlegen`}</div>
                    )}
                    placeholder={'Speditionauswahl'}
                    maxLength={350}
                    isClearable={true}
                    nativeClearLabel={'(Keine Spedition)'}
                    autoSelectIfOnlyOneOption={false}
                />
            )}
        />
    );
};

export default SpeditionAuswahl;
