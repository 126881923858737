import { useCallback, useState } from 'react';
import { ExcelGenerator } from '../../excel/ExcelGenerator';
import ButtonIcon from './ButtonIcon';
import { ReactComponent as DownloadIcon } from '../../../assets/icon-download.svg';
import { DownloadConfig, triggerDownload } from '../../fetchApi';

type ExcelDownloadButtonProps<T extends Record<string, unknown>> = {
    filenameWithoutExtension: string;
    excelGenerator: ExcelGenerator<T, number>;
};

export const ExcelDownloadButton = <T extends Record<string, unknown>>(props: ExcelDownloadButtonProps<T>): JSX.Element => {
    const { filenameWithoutExtension, excelGenerator, ...otherProps } = props;
    const [isGenerating, setIsGenerating] = useState(false);
    const [erroredWhileGenerating, setErroredWhileGenerating] = useState(false);

    const handleGenerateExcel = useCallback(() => {
        setIsGenerating(true);
        setErroredWhileGenerating(false);
        excelGenerator
            .generateExcel()
            .then((xlsx) => {
                const msExcelBlob = new Blob([xlsx], {
                    type: 'application/msexcel',
                });
                const blobUrl = URL.createObjectURL(msExcelBlob);
                const downloadConfig: DownloadConfig = {
                    objectUrl: blobUrl,
                    filename: `${filenameWithoutExtension}.xlsx`,
                };
                triggerDownload(downloadConfig);
            })
            .catch(() => {
                setErroredWhileGenerating(true);
            })
            .finally(() => {
                setIsGenerating(false);
            });
    }, [excelGenerator, filenameWithoutExtension]);

    return (
        <ButtonIcon
            icon={<DownloadIcon stroke={'#6f6f6f'} fill={'#6f6f6f'} strokeWidth={1.5} />}
            onClick={handleGenerateExcel}
            disabled={erroredWhileGenerating}
            {...otherProps}
        >
            {isGenerating ? 'Generiere ...' : 'Download Excel'}
        </ButtonIcon>
    );
};
