import FormControl from '../../ui-components/FormControl/FormControl';
import Textarea from '../../ui-components/Textarea/Textarea';
import { PropsWithChildren, ReactElement } from 'react';

export const FormikLieferhinweisInput = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    return (
        <FormControl
            name="lieferhinweis"
            id="lieferhinweis"
            label="Lieferhinweis für Lieferschein"
            allowChildToGrow={true}
            renderChild={(props): ReactElement => {
                return (
                    <>
                        <Textarea data-cy={'form-lieferhinweis'} maxLength={235} {...props} />
                        {children}
                    </>
                );
            }}
        />
    );
};
