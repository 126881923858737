import { PropertyEntryProps } from '../types';
import { useFormikContext } from 'formik';
import { StornoArtikelButton } from '../../../StornoArtikelButton/StornoArtikelButton';
import { isStornierbarePosition } from '../../../../helper/bestellungen-helper';

export const StornoListEntry = ({ bestellposition, disabled = false }: PropertyEntryProps): JSX.Element => {
    const { setFieldValue } = useFormikContext();

    const onStorno = () => {
        setFieldValue(`bestellpositionenView[${bestellposition.index}].storno`, true);
        setFieldValue(`bestellpositionenView[${bestellposition.index}].zulage`, false);
    };

    return <StornoArtikelButton disabled={!isStornierbarePosition(bestellposition) || disabled} onStorno={onStorno} />;
};
