import Validator, { ValidationError, ValidationRuleObject, ValidationSchema } from 'fastest-validator';
import { KONTRAKT_VALIDATION_ERROR, NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR, NEWID_PREFIX, ZULAGE_VALIDATION_ERROR } from '../constants';
import { ArtikelTyp, Berechnungsart } from '../types/enums';
import type { BestellpositionArtikelLoseWareView, BestellpositionDetails } from '../types';

export type AuftragskorbBestellung = {
    loseWareBestellpositionen: BestellpositionDetails[];
    sackwareBestellpositionen: BestellpositionDetails[];
};

const validator = new Validator({
    useNewCustomCheckerFunction: true,
});

const bestellpositionArtikelnummerValidationSchema = {
    type: 'custom',
    check(
        value: string,
        errors: ValidationError[],
        schema: ValidationRuleObject,
        path: string,
        parent: BestellpositionArtikelLoseWareView | null
    ) {
        if (value.startsWith(NEWID_PREFIX) && !parent?.bezeichnung) {
            errors.push({ field: 'bezeichnung', message: NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR, type: 'string' });
        }
        return value;
    },
};

const bestellpositionBerechnungsartValidationSchema = {
    type: 'custom',
    optional: true,
    check(
        value: Berechnungsart,
        errors: ValidationError[],
        schema: ValidationRuleObject,
        path: string,
        parent: BestellpositionArtikelLoseWareView | null
    ) {
        if (value === Berechnungsart.Kontrakt && !parent?.kontrakt) {
            errors.push({ field: 'kontrakt', message: KONTRAKT_VALIDATION_ERROR, type: 'string' });
        }
        return value;
    },
};

const auftragskorbBestellungSchema = {
    $$async: false,
    loseWareBestellpositionen: {
        type: 'array',
        items: {
            type: 'object',
            props: {
                artikelTyp: { type: 'enum', values: [ArtikelTyp.LoseWare] },
                werkId: { type: 'string', empty: true },
                menge: [
                    { type: 'string', pattern: /^\d*(?:,\d)?$/, nullable: false },
                    { type: 'number', negative: false },
                ],
                zulage: {
                    type: 'custom',
                    check(
                        value: boolean,
                        errors: ValidationError[],
                        schema: ValidationRuleObject,
                        path: string,
                        parent: BestellpositionArtikelLoseWareView | null
                    ) {
                        if (value && !parent?.zulageText) {
                            errors.push({ field: 'zulageText', message: ZULAGE_VALIDATION_ERROR, type: 'string' });
                        }
                        return value;
                    },
                },
                berechnungsart: bestellpositionBerechnungsartValidationSchema,
                nummer: bestellpositionArtikelnummerValidationSchema,
            },
        },
    },
    sackwareBestellpositionen: {
        type: 'array',
        items: {
            type: 'object',
            props: {
                artikelTyp: { type: 'enum', values: [ArtikelTyp.Sackware] },
                werkId: { type: 'string', empty: true },
                mengeVe: [
                    { type: 'string', pattern: /^\d*(?:,\d)?$/, optional: true },
                    { type: 'number', negative: false, optional: true },
                ],
                mengePal: [
                    { type: 'string', pattern: /^\d*(?:,\d)?$/, optional: true },
                    { type: 'number', negative: false, optional: true },
                ],
                nummer: bestellpositionArtikelnummerValidationSchema,
            },
        },
    },
} as ValidationSchema<AuftragskorbBestellung>;

export const validateAuftragskorbBestellung = (auftragskorbBestellung: AuftragskorbBestellung): boolean => {
    const errorsOrTrue = validator.validate(auftragskorbBestellung, auftragskorbBestellungSchema);
    // We are currently ignoring the returned errors because we don't show them.
    return errorsOrTrue === true;
};
