import styles from './LoadingSpinner.module.scss';

export type Props = Readonly<{
    children: JSX.Element;
    isLoading: boolean;
    marginTop?: string;
}>;

const LoadingSpinner = ({ marginTop, isLoading, children }: Props): JSX.Element => {
    return isLoading ? (
        <div className={styles['lds-roller-container']} style={{ marginTop }}>
            <div className={styles['lds-roller']}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    ) : (
        <div className={styles['lds-fade-in']}>{children}</div>
    );
};

export default LoadingSpinner;
