import { TableCell, TableHeaderCell, TableRow } from '../../../../ui-components/Table';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { PropertyEntryProps } from '../types';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const ZulageInputTableRow = ({
    bestellposition,
    disabled,
}: PropertyEntryProps<BestellpositionArtikelLoseWareView>): JSX.Element | null => {
    if (bestellposition.zulage) {
        return (
            <TableRow>
                <TableHeaderCell header>Zulage (*):</TableHeaderCell>
                <TableCell detail colSpan={6}>
                    <TextInput
                        storniert={bestellposition.storno}
                        disabled={disabled}
                        name={`bestellpositionenView[${bestellposition.index}].zulageText`}
                        id={`bestellpositionenView[${bestellposition.index}].zulageText`}
                        type="text"
                        data-cy={'zulage-input'}
                        showValidationUntouched
                    />
                </TableCell>
            </TableRow>
        );
    }
    return null;
};
