import { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { Severity } from '../../shared/types/enums';
import { selectVvvoNummer } from '../../store/Bestellung.store';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';
import { selectIsNutztierfutter } from '../../store/Workflow.store';

type MissingVvvoNumberNotificationProps = {
    triedToSubmit: boolean;
    missingVvvoConfirmed: boolean;
    setMissingVvvoConfirmed: Dispatch<boolean>;
};

export const MissingVvvoNumberNotification = ({
    triedToSubmit,
    missingVvvoConfirmed,
    setMissingVvvoConfirmed,
}: MissingVvvoNumberNotificationProps): JSX.Element => {
    const isNutztierfutter = useSelector(selectIsNutztierfutter);
    const vvvoNummer = useSelector(selectVvvoNummer);
    const vvvoNotificationSeverity = !Boolean(vvvoNummer) && missingVvvoConfirmed ? Severity.CONFIRMRED : Severity.ERROR;

    return (
        <NotificationBar
            testId="vvvo-validation-message-bar"
            dataCy="bestelldaten-vvvo-warning-checkbox"
            message=""
            isVisible={!Boolean(vvvoNummer) && triedToSubmit && isNutztierfutter}
            severity={vvvoNotificationSeverity}
        >
            <NonFormikCheckbox
                id="notificationCheck"
                name="notificationCheck"
                label="Sie haben keine VVVO-Nummer ausgewählt. Bitte bestätigen Sie, dass die Bestellung ohne Angabe einer VVVO-Nummer erfolgen soll."
                onFieldChange={setMissingVvvoConfirmed}
            />
        </NotificationBar>
    );
};
