import { BestellpositionArtikelLoseWareView } from '../../../types';
import styles from './LoseWareList.module.scss';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../../store/Navigation.store';
import {
    BezeichnungEntry,
    MengeEntry,
    ModifikationsstatusEntry,
    PreisartEntry,
    SiloEntry,
    WerkeEntry,
    WunschterminEntry,
} from '../PropertyEntries';
import { selectIsBestellungExisting } from '../../../../store/Global.selectors';

export type Props = Readonly<{
    bestellpositionen: BestellpositionArtikelLoseWareView[];
}>;

export const LoseWareListReadonly = ({ bestellpositionen }: Props): JSX.Element => {
    const isDebitor = useSelector(selectIsDebitor);
    const isExisting = useSelector(selectIsBestellungExisting);

    return (
        <div>
            {bestellpositionen.map((bestellposition, index: number) => {
                return (
                    <div className={styles._artikelContainer} key={index}>
                        <BezeichnungEntry.List bestellposition={bestellposition} readonly />
                        {isDebitor && isExisting ? (
                            <div className={styles._modifikationsContainer}>
                                <ModifikationsstatusEntry.List
                                    nummer={bestellposition.nummer}
                                    positionsnummer={bestellposition.positionsnummer}
                                />
                            </div>
                        ) : null}
                        <div className={styles._amountsContainer}>
                            <MengeEntry.List bestellposition={bestellposition} readonly />
                            <SiloEntry.List bestellposition={bestellposition} readonly />
                        </div>
                        {isDebitor && isExisting ? <WunschterminEntry.List bestellposition={bestellposition} readonly /> : null}
                        <WerkeEntry.List bestellposition={bestellposition} readonly />
                        {isDebitor ? <PreisartEntry.List bestellposition={bestellposition} readonly /> : null}
                    </div>
                );
            })}
        </div>
    );
};
