import * as Yup from 'yup';
import { DateSchema, StringSchema } from 'yup';
import {
    ARTIKEL_VALIDATION_ERROR,
    BESTELLMENGE_VALIDATION_ERROR,
    ONLY_STORNO_POSITIONS_ERROR,
    TIME_FORMAT_REGEX,
    WUNSCHTERMIN_VALIDATION_ERRORS,
} from '../../constants';
import { bestellpositionViewBaseSchema } from './schemas';
import {
    testAtLeastOnePositionWithoutStorno,
    testMaximalGewicht,
    testMengeValide,
    testMindestensEinArtikel,
    testMindestensEineMenge,
    testWunschtermin,
} from './testFunctions';

export const createDateSchema = (minDate: string, isRequired = true): DateSchema => {
    const dateSchema = Yup.date()
        .typeError(WUNSCHTERMIN_VALIDATION_ERRORS.WRONG_DATE_FORMAT)
        .min(minDate, WUNSCHTERMIN_VALIDATION_ERRORS.DATE_IN_THE_PAST);
    return isRequired ? dateSchema.required(WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_DATE) : dateSchema;
};

export const createTimeSchema = (isRequired = true): StringSchema => {
    const timeSchema = Yup.string().matches(TIME_FORMAT_REGEX, WUNSCHTERMIN_VALIDATION_ERRORS.WRONG_TIME_FORMAT);
    return isRequired ? timeSchema.required(WUNSCHTERMIN_VALIDATION_ERRORS.MISSING_TIME) : timeSchema;
};

const bestellpositionViewWarenempfaengerSchema = bestellpositionViewBaseSchema.shape({
    werkId: Yup.mixed().strip(),
});

// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBestellpositionViewSchemaNewBestellung = (isDebitor: boolean, checkForOverWeight = false) => {
    let bestellpositionSchema = bestellpositionViewWarenempfaengerSchema;
    if (isDebitor) {
        bestellpositionSchema = bestellpositionViewBaseSchema;
    }
    const schema = Yup.array().of(bestellpositionSchema).required();

    return schema
        .test('mindestensEinArtikel', ARTIKEL_VALIDATION_ERROR, testMindestensEinArtikel)
        .test('mindestensEineMenge', BESTELLMENGE_VALIDATION_ERROR, testMindestensEineMenge)
        .test('maximalGewicht', MAX_WEIGHT_ERROR, testMaximalGewicht(checkForOverWeight));
};

export const MAX_WEIGHT_ERROR = 'max weight error';
// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBestellpositionViewSchemaExistingBestellung = (isDebitor: boolean, checkForOverWeight = false) => {
    let bestellpositionSchema = bestellpositionViewWarenempfaengerSchema;
    if (isDebitor) {
        bestellpositionSchema = bestellpositionViewBaseSchema;
        bestellpositionSchema = bestellpositionSchema.test('testWunschtermin', testWunschtermin);
    }
    bestellpositionSchema = bestellpositionSchema.test('testMengeValide', testMengeValide);

    const schema = Yup.array().of(bestellpositionSchema).required();

    return schema
        .test('atLeastOnePositionWithoutStorno', ONLY_STORNO_POSITIONS_ERROR, testAtLeastOnePositionWithoutStorno)
        .test('maximalGewicht', MAX_WEIGHT_ERROR, testMaximalGewicht(checkForOverWeight));
};

// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBestellpositionViewSchemaDraftBestellung = (isDebitor: boolean, checkForOverWeight = false) => {
    let bestellpositionSchema = bestellpositionViewWarenempfaengerSchema;
    if (isDebitor) {
        bestellpositionSchema = bestellpositionViewBaseSchema;
    }
    bestellpositionSchema = bestellpositionSchema.test('testMengeValide', testMengeValide);

    const schema = Yup.array().of(bestellpositionSchema).required();

    return schema
        .test('atLeastOnePositionWithoutStorno', ONLY_STORNO_POSITIONS_ERROR, testAtLeastOnePositionWithoutStorno)
        .test('maximalGewicht', MAX_WEIGHT_ERROR, testMaximalGewicht(checkForOverWeight));
};

export * from './validation-helper';
export * from './testFunctions';
