import styles from './UnderConstruction.module.scss';
import iconUnderConstruction from '../../assets/icon-under-construction.svg';

const UnderConstruction = (): JSX.Element => {
    return (
        <div className={styles._message}>
            <img src={iconUnderConstruction} className={styles._icon} alt="Seite im Aufbau" />
            <h3>Diese Seite befindet sich noch im Aufbau.</h3>
        </div>
    );
};

export default UnderConstruction;
