import { Fragment, ReactElement } from 'react';
import './SackwareTable.module.scss';
import tableStyles from '../../../ui-components/Table/Table.module.scss';
import type { BestellpositionArtikelSackwareView, BestellpositionView } from '../../../types';
import { useFormikContext } from 'formik';
import { ArtikelGruppe, ArtikelTableValuesGrouped } from '../../../helper/artikel-helper';
import { addIndexToBestellposition, isSackwareView } from '../../../helper/bestellungen-helper';
import { TableHeaderCell } from '../../../ui-components/Table';
import IconButton, { IconSize } from '../../../ui-components/IconButton/IconButton';
import iconAddArrow from '../../../../assets/icon-add-arrow.svg';
import { SackwareTableGroupedRow } from './SackwareTableGroupedRow';
import { Breakpoint, useBreakpoint } from '../../../hooks/useBreakpoint';

export type Props = Readonly<{
    markenFilter: string;
    showSollbestaende?: boolean;
}>;

function calculateBestellvorschlag(bpView: BestellpositionArtikelSackwareView): number | undefined {
    if (bpView.lagerKapazitaetVE !== undefined && bpView.bestandVE !== undefined) {
        const differenzBestand = Math.max(bpView.lagerKapazitaetVE - bpView.bestandVE, 0);
        const anzahlPalettenGerundet = Math.ceil((differenzBestand * bpView.faktorBasiseinheitVe) / bpView.faktorBasiseinheitPal);
        const veAufGerundetenPaletten = (anzahlPalettenGerundet * bpView.faktorBasiseinheitPal) / bpView.faktorBasiseinheitVe;
        return bpView.aufPalRunden ? veAufGerundetenPaletten : differenzBestand;
    } else {
        return undefined;
    }
}

const orderByArtikelGruppe = ([, groupA]: [string, ArtikelGruppe], [, groupB]: [string, ArtikelGruppe]): number => {
    const idA = parseInt(groupA.artikelGruppeId);
    const idB = parseInt(groupB.artikelGruppeId);
    if (idA < idB) {
        return -1;
    }
    if (idA > idB) {
        return 1;
    }
    return 0;
};

const orderByPreislistenSequenz = (a: BestellpositionView, b: BestellpositionView): number => {
    // The value of Preislistensequenz is a number as a string for Sackwarenartikel. (e.g. "123")
    // This is because the backend handles Sackwarenpreislisten (number) and
    // Losewarenpreislisten (string) the same way. So if we can parse a number, we should do it to ensure numeric sorting, string as fallback.
    const aPreislistenSequenz: number | string = parseFloat(a.preislistenSequenz) || a.preislistenSequenz;
    const bPreislistenSequenz: number | string = parseFloat(b.preislistenSequenz) || b.preislistenSequenz;
    if (aPreislistenSequenz < bPreislistenSequenz) {
        return -1;
    }
    if (aPreislistenSequenz > bPreislistenSequenz) {
        return 1;
    }
    return 0;
};

type SackwareTableGroupedHeaderProps = {
    showSollbestaende: boolean;
    onApplyBestellvorschlaege: () => void;
};

const SackwareTableGroupedHeader = ({ showSollbestaende, onApplyBestellvorschlaege }: SackwareTableGroupedHeaderProps): ReactElement => {
    const isTabletPortrait = useBreakpoint(Breakpoint.TABLET_PORTRAIT);

    if (showSollbestaende) {
        return (
            <>
                <TableHeaderCell textCentered>Artikelnr.</TableHeaderCell>
                <TableHeaderCell textCentered>Artikel</TableHeaderCell>
                <TableHeaderCell textCentered>Auf volle Paletten runden?</TableHeaderCell>
                <TableHeaderCell textCentered>Mein Lagerplatz VE</TableHeaderCell>
                <TableHeaderCell textCentered>Mein Ist-Bestand VE</TableHeaderCell>
                <TableHeaderCell textCentered>Bestell&shy;vorschlag</TableHeaderCell>
                <TableHeaderCell button>
                    <div>
                        <div>Vorschlag übernehmen</div>
                        <IconButton
                            icon={iconAddArrow}
                            iconSize={IconSize.LARGE}
                            alt="Vorschlag übernehmen"
                            fullWidth
                            onClick={(): void => {
                                if (onApplyBestellvorschlaege) {
                                    onApplyBestellvorschlaege();
                                }
                            }}
                        />
                    </div>
                </TableHeaderCell>
                <TableHeaderCell textCentered>VE</TableHeaderCell>
                <TableHeaderCell textCentered>kg/VE</TableHeaderCell>
            </>
        );
    }

    const headers: string[] = ['Artikel', 'VEs', 'kg / VE', 'Pal.', 'kg / Pal.', 'Gesamt VE'];
    if (isTabletPortrait) {
        headers.unshift('Artikelnr.');
    }

    return (
        <>
            {headers.map((label, index) => {
                return (
                    <TableHeaderCell key={index} textCentered>
                        {label}
                    </TableHeaderCell>
                );
            })}
        </>
    );
};

const SackwareTableGrouped = ({ markenFilter, showSollbestaende = false }: Props): JSX.Element => {
    const { values, setFieldValue } = useFormikContext<ArtikelTableValuesGrouped>();
    const artikelGruppen = values.groupedBestellpositionen[markenFilter].artikelGruppen;
    const sortedArtikelGruppenEntries = Object.entries(artikelGruppen).sort(orderByArtikelGruppe);

    const onApplyBestellvorschlaege = (): void => {
        sortedArtikelGruppenEntries.forEach(([artikelGruppeId, artikelGruppe]) => {
            Array.isArray(artikelGruppe.bestellpositionenView) &&
                artikelGruppe.bestellpositionenView
                    .map(addIndexToBestellposition)
                    .filter(isSackwareView)
                    .sort(orderByPreislistenSequenz)
                    .forEach((bestellposition, index) => {
                        const bestellvorschlagVE = calculateBestellvorschlag(bestellposition);
                        setFieldValue(
                            `groupedBestellpositionen[${markenFilter}].artikelGruppen[${artikelGruppeId}].bestellpositionenView[${index}].mengeVe`,
                            bestellvorschlagVE
                        );
                    });
        });
    };

    return (
        <>
            <div className={tableStyles._tableContainer}>
                <table className={tableStyles._table}>
                    <thead>
                        <tr className={tableStyles._tableHeaderRow}>
                            <SackwareTableGroupedHeader
                                showSollbestaende={showSollbestaende}
                                onApplyBestellvorschlaege={onApplyBestellvorschlaege}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {sortedArtikelGruppenEntries.map(([artikelGruppeId, artikelGruppe], index) => {
                            return (
                                <Fragment key={index}>
                                    <tr className={tableStyles._tableSubheadRow}>
                                        <td className={tableStyles._tableCell} colSpan={9} data-testid={artikelGruppe.artikelGruppeId}>
                                            {artikelGruppe.artikelGruppeBezeichnung}
                                        </td>
                                    </tr>
                                    {Array.isArray(artikelGruppe.bestellpositionenView) &&
                                        artikelGruppe.bestellpositionenView
                                            .map(addIndexToBestellposition)
                                            .filter(isSackwareView)
                                            .sort(orderByPreislistenSequenz)
                                            .map((bestellposition, index) => {
                                                const bestellvorschlagVE = calculateBestellvorschlag(bestellposition);
                                                const onApplyBestellvorschlag = (): void => {
                                                    void setFieldValue(
                                                        `groupedBestellpositionen[${markenFilter}].artikelGruppen[${artikelGruppeId}].bestellpositionenView[${bestellposition.index}].mengeVe`,
                                                        bestellvorschlagVE
                                                    );
                                                };

                                                return (
                                                    <SackwareTableGroupedRow
                                                        index={index}
                                                        onApplyBestellvorschlag={onApplyBestellvorschlag}
                                                        key={bestellposition.index}
                                                        bestellvorschlagVE={bestellvorschlagVE}
                                                        withSollbestaende={showSollbestaende}
                                                        bestellposition={bestellposition}
                                                        formikRowPath={`groupedBestellpositionen[${markenFilter}].artikelGruppen[${artikelGruppeId}].bestellpositionenView`}
                                                    />
                                                );
                                            })}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SackwareTableGrouped;
