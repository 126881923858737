import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData, postData } from '../shared/fetchApi';
import {
    DELETE_USER_FAILED,
    DELETE_USER_SUCCEEDED,
    DeleteUserAction,
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_SUCCEEDED,
    GET_USER_FAILED,
    GET_USER_SUCCEEDED,
    GetUserAction,
    GetUserDetailsAction,
    UPDATE_USER_FAILED,
    UPDATE_USER_SUCCEEDED,
    UpdateUserAction,
} from '../store/User.store';
import { routes } from '../store/Workflow.store';
import { RegistrierungsStatus } from '../shared/types/enums';

export function* getUserSaga(action: GetUserAction): Generator<PutEffect | CallEffect> {
    try {
        const url = action.registrierungsStatus === RegistrierungsStatus.NEU ? 'admin/users?approvalRequiredOnly=true' : 'admin/users';
        const user = yield call(getData, url);
        yield put({ type: GET_USER_SUCCEEDED, user });
    } catch (e) {
        yield put({ type: GET_USER_FAILED });
    }
}

export function* getUserDetailsSaga(action: GetUserDetailsAction): Generator<PutEffect | CallEffect> {
    try {
        const url = 'admin/users/' + action.userId;
        const user = yield call(getData, url);
        yield put({ type: GET_USER_DETAILS_SUCCEEDED, user });
    } catch (e) {
        yield put({ type: GET_USER_DETAILS_FAILED });
    }
}

export function* updateUserSaga(action: UpdateUserAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const user = yield call(postData, 'admin/users/' + action.user.id, action.user, 'PUT');
        yield put({ type: UPDATE_USER_SUCCEEDED, user });
        action.history.push(
            `${routes.benutzer}/${action.user.registrationValidated ? RegistrierungsStatus.REGISTRIERT : RegistrierungsStatus.NEU}`
        );
    } catch (e) {
        yield put({ type: UPDATE_USER_FAILED, user: action.user });
    }
}

export function* deleteUserSaga(action: DeleteUserAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const user = yield call(postData, 'admin/users/' + action.user.id, action.user, 'DELETE');
        yield put({ type: DELETE_USER_SUCCEEDED, user });
        action.history.push(`${routes.benutzer}/${RegistrierungsStatus.NEU}`);
    } catch (e) {
        yield put({ type: DELETE_USER_FAILED });
    }
}
