import styles from '../Lieferungsansicht/Lieferungsansicht.module.scss';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import { KontraktDetails } from '../../shared/types';
import { useSelector } from 'react-redux';
import { KontraktuebersichtSelectors } from '../../store/Kontraktuebersicht.store';
import { currencyNumberFormat, currencyUnitFormat } from '../../shared/helper/format-helper';

export const KontraktansichtListReadOnly = (): JSX.Element => {
    const kontrakt: KontraktDetails = useSelector(KontraktuebersichtSelectors.currentKontrakt);

    return (
        <>
            <div>
                <h3>Artikel</h3>
                {kontrakt.artikelList.map((artikel, index: number) => {
                    return (
                        <div className={styles._positionContainer} key={index}>
                            <h4>Artikel:&nbsp;{artikel.bezeichnung}</h4>
                            {<TextItem label="Werke" text={artikel.werk.name} />}
                            <TextItem
                                label="Preis"
                                text={`${currencyNumberFormat(artikel.preis)} ${currencyUnitFormat(artikel.waehrungsEinheit)}`}
                            />
                        </div>
                    );
                })}
            </div>
            <div>
                <h3>Warenempfänger</h3>
                {kontrakt.warenempfaenger.map((warenempfaenger, index: number) => {
                    return (
                        <div className={styles._positionContainer} key={index}>
                            <h4>
                                {warenempfaenger.hauptname}&nbsp;{warenempfaenger.nebenname}
                            </h4>
                            <TextItem
                                label="Strasse"
                                text={`${warenempfaenger.strasse} ${warenempfaenger.postleitzahl} ${warenempfaenger.ort}`}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};
