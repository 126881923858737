import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestState } from '../shared/types/enums';
import type { RootState } from '../configureStore';
import type { KfzKennzeichen } from '../shared/types';
import { Option } from '../shared/helper/select-options';

export const TEMPORARY_KFZ_ID = 'NEW';

const selectAllKfzKennzeichen = (state: RootState): KfzKennzeichenView[] => {
    return state.kfzKennzeichen.kfzKennzeichen;
};

const selectKfzKennzeichenOptions = createSelector(selectAllKfzKennzeichen, (kfzKennzeichen): Option[] => {
    return kfzKennzeichen.map((kennzeichen) => ({
        value: kennzeichen.id,
        label: kennzeichen.kennzeichen,
    }));
});

const selectKfzKennzeichenIdFromKennzeichen =
    (kfzKennzeichen: string | undefined): ((state: RootState) => string) =>
    (state) => {
        if (!kfzKennzeichen) {
            return '';
        }
        return (
            state.kfzKennzeichen.kfzKennzeichen.find((kfzKennzeichenView) => kfzKennzeichenView.kennzeichen === kfzKennzeichen)?.id ?? ''
        );
    };
export const kfzKennzeichenSelectors = {
    allKfzKennzeichen: selectAllKfzKennzeichen,
    allAsOptions: selectKfzKennzeichenOptions,
    isLoading(state: RootState): boolean {
        return state.kfzKennzeichen.loadingState === RequestState.LOADING;
    },
    loaded(state: RootState): boolean {
        return state.kfzKennzeichen.loadingState === RequestState.SUCCESSFUL;
    },
    hasSubmitError(state: RootState): boolean {
        return state.kfzKennzeichen.submitState === RequestState.FAILED;
    },
    mapKennzeichenIdFromKennzeichen: selectKfzKennzeichenIdFromKennzeichen,
};

export type KfzKennzeichenView = KfzKennzeichen & {
    isSubmitting: boolean;
    hasValidationError?: boolean;
    hasDublettenError?: boolean;
};

export const createKennzeichen = (id: string, kennzeichen: string): KfzKennzeichen => {
    return { id, kennzeichen };
};

export const viewFromKfzKennzeichen = (kfzKennzeichen: KfzKennzeichen): KfzKennzeichenView => ({
    ...kfzKennzeichen,
    isSubmitting: false,
    hasValidationError: false,
    hasDublettenError: false,
});

const sortKennzeichen = (a: KfzKennzeichenView, b: KfzKennzeichenView): number => {
    if (a.kennzeichen.toUpperCase() < b.kennzeichen.toUpperCase()) {
        return -1;
    }
    if (a.kennzeichen.toUpperCase() > b.kennzeichen.toUpperCase()) {
        return 1;
    }
    return 0;
};

function setKfzKennzeichenSubmitting(state: KfzKennzeichenState, kennzeichen: KfzKennzeichen, isSubmitting: boolean): KfzKennzeichenView[] {
    return state.kfzKennzeichen.map((kfzKennzeichen) => ({
        ...kfzKennzeichen,
        isSubmitting: kfzKennzeichen.id === kennzeichen.id ? isSubmitting : kfzKennzeichen.isSubmitting,
    }));
}

export interface KfzKennzeichenState {
    kfzKennzeichen: KfzKennzeichenView[];
    loadingState: RequestState;
    submitState: RequestState;
}

export const INITIAL_KFZ_KENNZEICHEN_STATE: KfzKennzeichenState = {
    kfzKennzeichen: [],
    loadingState: RequestState.INITIAL,
    submitState: RequestState.INITIAL,
};

const kfzKennzeichenSlice = createSlice({
    name: 'kfzKennzeichen',
    initialState: INITIAL_KFZ_KENNZEICHEN_STATE,
    reducers: {
        addKfzKennzeichen(state, action: PayloadAction<KfzKennzeichen>) {
            state.submitState = RequestState.INITIAL;
            state.kfzKennzeichen.push(viewFromKfzKennzeichen(action.payload));
        },
        addKfzKennzeichenSucceeded(state, action: PayloadAction<KfzKennzeichen>) {
            state.kfzKennzeichen = state.kfzKennzeichen.filter((k) => k.id !== TEMPORARY_KFZ_ID);
            state.loadingState = RequestState.INITIAL;
            state.kfzKennzeichen.push(viewFromKfzKennzeichen(action.payload));
        },
        addKfzKennzeichenFailed(state, _: PayloadAction<string>) {
            state.submitState = RequestState.FAILED;
            state.kfzKennzeichen = state.kfzKennzeichen.filter((k) => k.id !== TEMPORARY_KFZ_ID);
        },
        getKfzKennzeichen(state) {
            state.loadingState = RequestState.LOADING;
        },
        getKfzKennzeichenSucceeded(state, action: PayloadAction<KfzKennzeichen[]>) {
            const kfzKennzeichenSorted = action.payload.map(viewFromKfzKennzeichen).sort(sortKennzeichen);
            state.loadingState = RequestState.SUCCESSFUL;
            state.submitState = RequestState.SUCCESSFUL;
            state.kfzKennzeichen = kfzKennzeichenSorted;
        },
        getKfzKennzeichenFailed(state, _: PayloadAction<string>) {
            state.loadingState = RequestState.FAILED;
        },
        deleteKfzKennzeichen(state, action: PayloadAction<KfzKennzeichen>) {
            state.kfzKennzeichen = setKfzKennzeichenSubmitting(state, action.payload, true);
        },
        deleteKfzKennzeichenSucceeded(state, action: PayloadAction<KfzKennzeichen>) {
            state.submitState = RequestState.SUCCESSFUL;
            state.loadingState = RequestState.INITIAL;
            state.kfzKennzeichen = state.kfzKennzeichen.filter((k) => k.id !== action.payload.id);
        },
        deleteKfzKennzeichenFailed(state, action: PayloadAction<KfzKennzeichen>) {
            state.submitState = RequestState.FAILED;
            state.kfzKennzeichen = setKfzKennzeichenSubmitting(state, action.payload, false);
        },
        updateKfzKennzeichen(state, { payload: updatedKfzKennzeichen }: PayloadAction<KfzKennzeichen>) {
            state.kfzKennzeichen = state.kfzKennzeichen.map((kfzKennzeichen) => ({
                ...(kfzKennzeichen.id === updatedKfzKennzeichen.id ? updatedKfzKennzeichen : kfzKennzeichen),
                isSubmitting: kfzKennzeichen.id === updatedKfzKennzeichen.id ? true : kfzKennzeichen.isSubmitting,
            }));
        },
        updateKfzKennzeichenSucceeded(state, action: PayloadAction<KfzKennzeichen>) {
            state.submitState = RequestState.SUCCESSFUL;
            state.loadingState = RequestState.INITIAL;
            state.kfzKennzeichen = setKfzKennzeichenSubmitting(state, action.payload, false);
        },
        updateKfzKennzeichenFailed(state, action: PayloadAction<KfzKennzeichen>) {
            state.submitState = RequestState.FAILED;
            state.kfzKennzeichen = setKfzKennzeichenSubmitting(state, action.payload, true);
        },
    },
});

export const {
    addKfzKennzeichen,
    addKfzKennzeichenSucceeded,
    addKfzKennzeichenFailed,
    deleteKfzKennzeichen,
    deleteKfzKennzeichenSucceeded,
    deleteKfzKennzeichenFailed,
    getKfzKennzeichen,
    getKfzKennzeichenSucceeded,
    getKfzKennzeichenFailed,
    updateKfzKennzeichen,
    updateKfzKennzeichenSucceeded,
    updateKfzKennzeichenFailed,
} = kfzKennzeichenSlice.actions;
export const kfzKennzeichenReducer = kfzKennzeichenSlice.reducer;
