import { SubmitHandler, useFormContext } from 'react-hook-form';
import Button from '../../../shared/ui-components/Button/Button';
import { BESTELLEN_BUTTON_TEXT } from '../../../shared/constants';

type OrderAllButtonProps<T extends Record<string, unknown>> = {
    submitHandler: SubmitHandler<T>;
    disabled?: boolean;
};

export const OrderAllButton = <T extends Record<string, unknown>>({
    submitHandler,
    disabled = false,
}: OrderAllButtonProps<T>): JSX.Element => {
    const { handleSubmit } = useFormContext<T>();

    const onClick = (): void => {
        handleSubmit(submitHandler)();
    };

    return (
        <Button onClick={onClick} disabled={disabled} data-cy={'verbindlich-bestellen-button'}>
            {BESTELLEN_BUTTON_TEXT}
        </Button>
    );
};
