import { useField } from 'formik';
import styles from '../TextInput/TextInput.module.scss';
import { HTMLProps } from 'react';
import areaStyles from './Textarea.module.scss';
import { BaseProps } from '../FormControl';

type Props = HTMLProps<HTMLTextAreaElement>;

const Textarea = (props: BaseProps & Props): JSX.Element => {
    const [field, meta] = useField(props);
    const showError = meta.touched && Boolean(meta.error);
    const baseStyles = [styles._input, styles['_input--regular'], areaStyles._textarea];
    const inputStyles = showError ? [...baseStyles, styles['_input--error']] : baseStyles;

    return (
        <>
            <div className={areaStyles._print}>{field.value}</div>
            <textarea {...props} {...field} className={inputStyles.join(' ')} />
        </>
    );
};

export default Textarea;
