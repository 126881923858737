import { FieldErrors } from 'react-hook-form';
import type { TourenplanForm, TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from './TourenplanTypes';

export const getErrorMessageForPropertyFieldError = <
    T extends Partial<TourenplanLoseWareBestellung | TourenplanSackwareBestellung | TourenplanForm>
>(
    fieldError: unknown,
    nestedPropertyName: keyof T
): string | null => {
    return Object(fieldError)[nestedPropertyName]?.message ?? null;
};

export const getFirstErrorMessageFromLoseWareBestellung = (
    fieldErrors: FieldErrors<TourenplanForm>,
    nestedPropertyName: keyof TourenplanLoseWareBestellung
): string | null => {
    if (fieldErrors && fieldErrors.loseWareBestellungen) {
        //@ts-ignore
        const errorMessages = fieldErrors.loseWareBestellungen
            .map((error) => getErrorMessageForPropertyFieldError(error, nestedPropertyName))
            .filter(Boolean);
        return errorMessages.length > 0 ? errorMessages[0] : null;
    } else {
        return null;
    }
};

export const getFirstErrorMessageFromSackwareBestellung = (
    fieldErrors: FieldErrors<TourenplanForm>,
    nestedPropertyName: keyof TourenplanSackwareBestellung
): string | null => {
    if (fieldErrors && fieldErrors.sackwareBestellungen) {
        //@ts-ignore
        const errorMessages = fieldErrors.sackwareBestellungen
            .map((error) => getErrorMessageForPropertyFieldError(error, nestedPropertyName))
            .filter(Boolean);
        return errorMessages.length > 0 ? errorMessages[0] : null;
    } else {
        return null;
    }
};
