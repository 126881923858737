import { CellProps, Column } from 'react-table';
import styles from '../../../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb.module.scss';
import TableCell from '../../../../shared/ui-components/Table/ReactTable/TableCell';
import type { Warenempfaenger } from '../../../../shared/types';
import type { TourenplanLoseWareBestellung, TourenplanSackwareBestellung } from '../../TourenplanTypes';

const WarenempfaengerColumnCell = (
    cellProps: CellProps<TourenplanLoseWareBestellung | TourenplanSackwareBestellung, Warenempfaenger>
): JSX.Element => {
    const warenempfaenger = cellProps.value;
    const cell = cellProps.cell;
    return (
        <TableCell cell={cell} data-cy="tourenplan-warenempfaenger">
            <div>{warenempfaenger.hauptname}</div>
            {warenempfaenger.nebenname && <div>{warenempfaenger.nebenname}</div>}
            {warenempfaenger.namenszusatz && <div>{warenempfaenger.namenszusatz}</div>}
            <div className={styles._subInfo}>
                {warenempfaenger.postleitzahl} {warenempfaenger.ort}
            </div>
        </TableCell>
    );
};

const warenempfaengerColumnConfig = <T extends TourenplanLoseWareBestellung | TourenplanSackwareBestellung>(): Column<T> => ({
    Header: 'Warenempfänger',
    accessor: 'warenempfaenger',
    Cell: WarenempfaengerColumnCell,
});

export const loseWareWarenempfaengerColumnConfig = warenempfaengerColumnConfig<TourenplanLoseWareBestellung>();
export const sackwareWarenempfaengerColumnConfig = warenempfaengerColumnConfig<TourenplanSackwareBestellung>();
