import { Rechnungsempfaenger } from '../types';
import { routes } from '../constants';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { BestellungSelectors } from '../../store/Bestellung.store';
import { RechnungsempfaengerSelectors } from '../../store/Rechnungsempfaenger.store';

export const useRechnungsempfaengerFromBestellung = (): Rechnungsempfaenger | undefined => {
    // NOTE: Select the Rechnungsempfänger from the active Lose Ware or Sackwaren Bestellung
    const rechnungsempfaengerPartnerNummer = useSelector(BestellungSelectors.rechnungsempfaengerPartnerNummer);
    let rechnungsempfaenger = useSelector(RechnungsempfaengerSelectors.byPartnerNummer(rechnungsempfaengerPartnerNummer));

    // NOTE: Select the Rechnungsempfänger from the current selected Bestellung from Bestelluebersicht/Auftragskorb
    const location = useLocation();
    const currentBestellung = useSelector(BestelluebersichtSelectors.currentBestellung);

    if (location.pathname.includes(routes.auftragsansicht) || location.pathname.includes(routes.bestellansicht)) {
        rechnungsempfaenger = currentBestellung.rechnungsempfaenger;
    }

    return rechnungsempfaenger;
};
