import { MengeSWListEntry } from './MengeSWListEntry';
import { MengeSWTableCell } from './MengeSWTableCell';
import { MengeSWSollbestaende } from './MengeSWTableCellWithSollbestaende';

const MengeSWEntry = {
    List: MengeSWListEntry,
    Cell: MengeSWTableCell,
    Sollbestaende: MengeSWSollbestaende,
};

export { MengeSWEntry };
