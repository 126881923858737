import { v4 as uuidv4 } from 'uuid';
import type { AppConfig, AppConfiguration } from './types';

function getOrThrow(paramName: string): string {
    const value = process.env[paramName];

    if (!value) {
        throw new Error(`Environment variable ${paramName} is not set`);
    }

    return value;
}

function getOrDefault(paramName: string, defaultValue: string): string {
    const value = process.env[paramName];

    if (!value) {
        // console.info(`Environment variable ${paramName} is not set, using default [${defaultValue}]`);
        return defaultValue;
    }

    return value;
}

class Config implements AppConfig {
    config: AppConfiguration;

    constructor() {
        this.config = {} as AppConfiguration;
    }

    clear(): void {
        this.config = {} as AppConfiguration;
    }

    getConfig(): AppConfiguration {
        if (Object.keys(this.config).length === 0) {
            this.config = {
                mockKeycloak: getOrDefault('REACT_APP_MOCK_KEYCLOAK', 'true') === 'true',
                apiUrl: getOrThrow('REACT_APP_API'),
                gitBranch: getOrDefault('REACT_APP_GIT_BRANCH', 'develop'),
                isApp: () => window.navigator.userAgent === 'deupa.app',
                keycloakConfig: {
                    url: getOrThrow('REACT_APP_KEYCLOAK_URL'),
                    realm: getOrThrow('REACT_APP_KEYCLOAK_REALM'),
                    clientId: getOrThrow('REACT_APP_KEYCLOAK_CLIENTID'),
                },
                sentryConfig: {
                    dsn: getOrDefault('REACT_APP_SENTRY_DSN', ''),
                    sentryEnv: getOrDefault('REACT_APP_SENTRY_ENV', ''),
                    origin: getOrDefault('REACT_APP_SENTRY_TRACING_ORIGINS', ''),
                    sampleRate: Number(getOrDefault('REACT_APP_SENTRY_TRACING_SAMPLE_RATE', '0.2')),
                },
                correlationId: getOrDefault('REACT_APP_CORRELATION_ID', uuidv4()),
            };
        }

        if (this.config.sentryConfig.dsn && !this.config.sentryConfig.sentryEnv) {
            throw new Error(`environment variable 'REACT_APP_SENTRY_DSN' is set but 'REACT_APP_SENTRY_ENV' is missing.
        Please add 'REACT_APP_SENTRY_ENV' or remove 'REACT_APP_SENTRY_DSN'`);
        }

        return this.config;
    }
}

const ConfigInstance = new Config();

export default ConfigInstance;
