import { BestellpositionArtikelSackwareView } from '../../../../types';
import 'tippy.js/dist/tippy.css';
import Formulas from '../../../../formulas';
import { useSelector } from 'react-redux';
import { FieldArrayRenderProps } from 'formik';
import { isExistingBestellposition } from '../../../../helper/bestellungen-helper';
import { TableRow } from '../../../../ui-components/Table';
import { AenderbarBisTooltip } from '../../../AenderbarBis/AenderbarBisTooltip';
import { BestelluebersichtSelectors } from '../../../../../store/Bestelluebersicht.store';
import { BezeichnungEntry, DeleteEntry, MengeSWEntry, WerkeEntry } from '../../PropertyEntries';

export type RowProps = Readonly<{
    bestellposition: BestellpositionArtikelSackwareView;
    allowDelete?: boolean;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
}>;

export const SackwareTableRowExistingBestellung = ({
    bestellposition,
    allowDelete,
    arrayHelpers,
    onArtikelDeleted,
}: RowProps): JSX.Element => {
    const { mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe } = bestellposition;
    const overallVECount = Formulas.overallVerkaufseinheitCount(mengeVe, mengePal, faktorBasiseinheitPal, faktorBasiseinheitVe);
    const aenderbarBisExpired = useSelector(
        BestelluebersichtSelectors.hasAenderbarBisExpiredForPosition(bestellposition.nummer, bestellposition.positionsnummer)
    );
    const isCurrentPositionGesperrt = useSelector(
        BestelluebersichtSelectors.isCurrentPositionGesperrt(bestellposition.nummer, bestellposition.positionsnummer)
    );
    const isDisabled = isCurrentPositionGesperrt || aenderbarBisExpired;

    return (
        <AenderbarBisTooltip
            aenderbarBisExpired={aenderbarBisExpired}
            nummer={bestellposition.nummer}
            positionsnummer={bestellposition.positionsnummer}
            storno={bestellposition.storno}
        >
            <TableRow key={bestellposition.index}>
                <BezeichnungEntry.Cell bestellposition={bestellposition} disabled={isDisabled} />
                <WerkeEntry.Cell bestellposition={bestellposition} readonly={isExistingBestellposition(bestellposition)} />
                <MengeSWEntry.Cell bestellposition={bestellposition} />
                {isExistingBestellposition(bestellposition) ? (
                    <DeleteEntry.Storno.Cell bestellposition={bestellposition} disabled={isDisabled} />
                ) : allowDelete ? (
                    <DeleteEntry.Delete.Cell
                        bestellposition={bestellposition}
                        arrayHelpers={arrayHelpers}
                        disabled={isDisabled}
                        onArtikelDeleted={onArtikelDeleted}
                        showConfirmDelete={overallVECount > 0}
                    />
                ) : null}
            </TableRow>
        </AenderbarBisTooltip>
    );
};
