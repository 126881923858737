import { useFormikContext } from 'formik';
import { FormControlWithoutErrorDisplay } from '../../shared/ui-components/FormControl/FormControl';
import SelectInput from '../../shared/ui-components/SelectInput/SelectInput';
import { lieferbedingungOptions, Option } from '../../shared/helper/select-options';

export const FormikLieferbedingungenSelect = (): JSX.Element => {
    const { setFieldTouched } = useFormikContext();

    return (
        <FormControlWithoutErrorDisplay
            name="lieferbedingung"
            id="lieferbedingung"
            label="Lieferart *"
            renderChild={(props): JSX.Element => (
                <SelectInput<Option>
                    {...props}
                    classNamePrefix={'react_select__form-lieferbedingung'}
                    setFieldTouched={setFieldTouched}
                    options={lieferbedingungOptions}
                    placeholder="bitte auswählen"
                    isClearable={false}
                    autoSelectIfOnlyOneOption={true}
                />
            )}
        />
    );
};
