import { combineReducers } from 'redux';
import { warenempfaengerReducer } from './Warenempfaenger.store';
import { werkeReducer } from './Abholwerk.store';
import { artikelSackwareReducer } from './ArtikelSackware.store';
import { artikelLoseWareReducer } from './ArtikelLoseWare.store';
import { bestellungReducer } from './Bestellung.store';
import { kfzKennzeichenReducer } from './KfzKennzeichen.store';
import { workflowReducer } from './Workflow.store';
import { userReducer } from './User.store';
import { partnerSucheReducer } from './Partner.store';
import { rechnungsempfaengerReducer } from './Rechnungsempfaenger.store';
import { bestelluebersichtReducer } from './Bestelluebersicht.store';
import { lieferungsReducer } from './Lieferung.store';
import { kontrakteReducer } from './Kontrakt.store';
import { kontraktuebersichtReducer } from './Kontraktuebersicht.store';
import { partneruebersichtReducer } from './Partneruebersicht.store';
import { markenReducer } from './Marke.store';
import { navigationReducer } from './Navigation.store';
import { interneArtikelSucheReducer } from './InterneArtikelSuche.store';
import { preislistenkennzeichenReducer } from './Preislistenkennzeichen.store';
import { sollbestandReducer } from './Sollbestand.store';
import { vorschlaegeReducer } from './Vorschlaege.store';
import { artikelauswahlReducer } from './Artikelauswahl.store';
import { dokumentReducer } from './Dokument.store';
import { dokumenteSilageergebnisseReducer } from './DokumentSilageergebnisse.store';
import { rationsserviceReducer } from './Rationsservice.store';
import { speditionReducer } from './Spedition.store';

export const rootReducer = combineReducers({
    artikelLoseWare: artikelLoseWareReducer,
    artikelSackware: artikelSackwareReducer,
    bestelluebersicht: bestelluebersichtReducer,
    bestellung: bestellungReducer,
    kfzKennzeichen: kfzKennzeichenReducer,
    spedition: speditionReducer,
    kontrakte: kontrakteReducer,
    kontraktuebersicht: kontraktuebersichtReducer,
    lieferungen: lieferungsReducer,
    navigation: navigationReducer,
    rechnungsempfaenger: rechnungsempfaengerReducer,
    sollbestand: sollbestandReducer,
    vorschlaege: vorschlaegeReducer,
    warenempfaenger: warenempfaengerReducer,
    werke: werkeReducer,
    workflow: workflowReducer,
    artikelauswahl: artikelauswahlReducer,
    dokumente: dokumentReducer,
    dokumenteSilageergebnisse: dokumenteSilageergebnisseReducer,
    rationsservices: rationsserviceReducer,
    // Admin Reducer
    interneArtikelSuche: interneArtikelSucheReducer,
    marken: markenReducer,
    partnersuche: partnerSucheReducer,
    partneruebersicht: partneruebersichtReducer,
    user: userReducer,
    preislistenkennzeichen: preislistenkennzeichenReducer,
});
