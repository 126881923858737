import { useMemo } from 'react';
import { TableInstance, TableOptions, useSortBy, useTable } from 'react-table';
import { loseWareWarenempfaengerColumnConfig } from './columns/WarenempfaengerColumnConfig';
import { loseWareArtikelColumnConfig } from './columns/ArtikelColumnConfig';
import { siloColumnConfig } from './columns/loseware/SiloColumnConfig';
import { loseWareLieferhinweisColumnConfig } from './columns/LieferhinweisColumnConfig';
import { loseWareWerkColumnConfig } from './columns/WerkColumnConfig';
import { kesselverteilungColumnConfig } from './columns/loseware/KesselverteilungColumnConfig';
import { mengeInTonnenColumnConfig } from './columns/loseware/MengeInTonnenColumnConfig';
import tableStyles from '../../../shared/ui-components/Table/Table.module.scss';
import TableBody from '../../../shared/ui-components/Table/ReactTable/TableBody';
import TableHeader from '../../../shared/ui-components/Table/ReactTable/TableHeader';
import type { TourenplanLoseWareBestellung } from '../TourenplanTypes';

type TourenplanTableProps = {
    bestellungen: TourenplanLoseWareBestellung[];
};

export const TourenplanLoseWareTable = ({ bestellungen }: TourenplanTableProps): JSX.Element => {
    const columns = useMemo(
        () => [
            loseWareWarenempfaengerColumnConfig,
            loseWareArtikelColumnConfig,
            loseWareWerkColumnConfig,
            mengeInTonnenColumnConfig,
            kesselverteilungColumnConfig,
            siloColumnConfig,
            loseWareLieferhinweisColumnConfig,
        ],
        []
    );

    const options: TableOptions<TourenplanLoseWareBestellung> = {
        columns,
        data: bestellungen,
        initialState: {},
    };

    const tableInstance: TableInstance<TourenplanLoseWareBestellung> = useTable<TourenplanLoseWareBestellung>(options, useSortBy);

    const { getTableProps } = tableInstance;
    return (
        <div data-cy="tourenplan-loseware-table" className={tableStyles._tableContainer}>
            <table {...getTableProps()} className={tableStyles._table}>
                <TableHeader {...tableInstance} />
                <TableBody {...tableInstance} />
            </table>
        </div>
    );
};
