import Button, { ButtonProps } from './Button';
import styles from './ButtonIcon.module.scss';
import { PropsWithChildren, ReactElement } from 'react';

export type ButtonIconProps = Readonly<{
    icon: ReactElement;
}> &
    ButtonProps;

const ButtonIcon = ({ icon, children, ...additionalProps }: PropsWithChildren<ButtonIconProps>): ReactElement => {
    return (
        <Button {...additionalProps} additionalStyles={styles._buttonIcon}>
            {children}
            <div className={styles['_buttonIcon__imageContainer']}>
                <div className={styles['_buttonIcon__image']}>{icon}</div>
            </div>
        </Button>
    );
};

export default ButtonIcon;
