import { useHistory } from 'react-router-dom';
import ButtonGroup, { ButtonGroupAlignment } from '../../../shared/ui-components/ButtonGroup/ButtonGroup';
import styles from '../TourenplanBestelluebermittlung.module.scss';
import { ExcelDownloadButton } from '../../../shared/ui-components/Button/ExcelDownloadButton';
import TourenplanExcelGenerator from '../service/excel/TourenplanExcelGenerator';
import { PrintButton } from '../../../shared/ui-components/Button/PrintButton';
import Button from '../../../shared/ui-components/Button/Button';
import { routes } from '../../../shared/constants';
import type { TourenplanFormWithRechnungsempfaengerList } from '../TourenplanBestelluebermittlung';

type SuccessfulBestellungButtonsProps = {
    data: TourenplanFormWithRechnungsempfaengerList;
};

export const SuccessfulBestellungButtons = ({ data }: SuccessfulBestellungButtonsProps): JSX.Element => {
    const history = useHistory();
    return (
        <ButtonGroup className={styles._buttonGroup} alignment={ButtonGroupAlignment.LEFT}>
            <ExcelDownloadButton<TourenplanFormWithRechnungsempfaengerList>
                filenameWithoutExtension="tourenplan"
                excelGenerator={new TourenplanExcelGenerator(data)}
                data-cy={'excel-download-button'}
            />
            <PrintButton />
            <Button
                data-cy={'zum-auftragskorb-button'}
                onClick={() => {
                    history.push(routes.auftragsuebersicht);
                }}
            >
                Zum Auftragskorb
            </Button>
        </ButtonGroup>
    );
};
