import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSaveBestelldaten } from '../useSaveBestelldaten';
import { Option } from '../../../shared/helper/select-options';
import { addKfzKennzeichen, kfzKennzeichenSelectors, TEMPORARY_KFZ_ID } from '../../../store/KfzKennzeichen.store';
import formStyles from '../../../shared/ui-components/Form/Form.module.scss';
import KfzKennzeichenAuswahl from '../../../shared/content-components/KfzKennzeichenAuswahl/KfzKennzeichenAuswahl';
import Button from '../../../shared/ui-components/Button/Button';
import { useFetchKfzKennzeichen } from '../../../shared/hooks/useFetchKfzKennzeichen';

export const BestelldatenKennzeichenAuswahl = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { manualSave } = useSaveBestelldaten();
    const kfzKennzeichenOptions: Option[] = useSelector(kfzKennzeichenSelectors.allAsOptions);

    const navigateToKfzKennzeichen = (): void => {
        manualSave();
        history.push('/kfz-kennzeichen-und-speditionen');
    };

    useFetchKfzKennzeichen();

    const onKfzKennzeichenCreated = (kfzKennzeichen: string): void => {
        dispatch(
            addKfzKennzeichen({
                id: TEMPORARY_KFZ_ID,
                kennzeichen: kfzKennzeichen,
            })
        );
    };

    return (
        <div className={formStyles._selectWithButtonContainer}>
            <KfzKennzeichenAuswahl kfzKennzeichen={kfzKennzeichenOptions} onKfzKennzeichenCreated={onKfzKennzeichenCreated} />
            <div className={formStyles._selectEdit}>
                <Button type="button" onClick={navigateToKfzKennzeichen}>
                    ...
                </Button>
            </div>
        </div>
    );
};
