import { Workbook } from 'exceljs';
import type { Border, Buffer, Cell, Font, RichText, Row, Style, Worksheet } from 'exceljs';

interface Enum {
    [id: number]: string;
}

export enum BorderOptions {
    none = 0,
    left = 1,
    right = 2,
    top = 4,
    bottom = 8,
    all = 16,
}

// TODO: write tests
export class ExcelGenerator<DataType extends Record<string, unknown>, ColumnKeyType extends number> {
    workbook: Workbook;
    worksheet: Worksheet;
    data: DataType;
    CELL_BORDER_STYLE: Partial<Border> = { style: 'thin', color: { argb: 'E0B300' } };

    constructor(data: DataType) {
        this.workbook = new Workbook();
        this.worksheet = this.workbook.getWorksheet(0);
        this.data = data;
    }

    createRichText(text?: string, newLine = true, font?: Partial<Font>): RichText {
        return {
            text: text ? `${newLine ? '\r\n' : ''}${text}` : '',
            font,
        };
    }

    createRowConfig<ContentType>(content: ContentType[], columns: Enum): Record<string, ContentType> {
        return Object.keys(columns)
            .filter((colKey) => isNaN(Number(colKey)))
            .reduce((acc, colKey, index) => {
                return {
                    ...acc,
                    [columns[colKey]]: content[index],
                };
            }, {});
    }

    generateExcel(): Promise<Buffer> {
        return Promise.resolve(Buffer.from([]));
    }

    getRowOfActiveWorksheet(index: number): Row {
        return this.worksheet.getRow(index);
    }

    mergeCellsWithAddresses(from: string, to: string): void {
        this.worksheet.mergeCells(`${from}:${to}`);
    }

    getCellAddressInColumn(columnKey: ColumnKeyType, row: number): string {
        return this.worksheet.getRow(row).getCell(columnKey).address;
    }

    mergeCellsHorizontal(key1: ColumnKeyType, key2: ColumnKeyType, row: number): void {
        const address1 = this.getCellAddressInColumn(key1, row);
        const address2 = this.getCellAddressInColumn(key2, row);
        this.mergeCellsWithAddresses(address1, address2);
    }

    mergeCellArea(startTuple: [row: number, col: number], endTuple: [row: number, col: number]): void {
        const [startRow, startColumn] = startTuple;
        const [endRow, endColumn] = endTuple;
        this.worksheet.mergeCells(startRow, startColumn, endRow, endColumn);
    }

    mergeCellsVertical(key: ColumnKeyType, row1: number, row2: number): void {
        const address1 = this.getCellAddressInColumn(key, row1);
        const address2 = this.getCellAddressInColumn(key, row2);
        this.mergeCellsWithAddresses(address1, address2);
    }

    setTableHeaderStyle(cell: Cell): void {
        this.styleCell(cell, {
            alignment: { horizontal: 'center', vertical: 'middle' },
            font: { bold: true, name: 'Arial Narrow', family: 1, size: 12 },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8D3' } },
        });
    }

    setCellBorder(cell: Cell, borderOptions: number = BorderOptions.all): void {
        const isLeft = (borderOptions & BorderOptions.left) === BorderOptions.left;
        const isRight = (borderOptions & BorderOptions.right) === BorderOptions.right;
        const isTop = (borderOptions & BorderOptions.top) === BorderOptions.top;
        const isBottom = (borderOptions & BorderOptions.bottom) === BorderOptions.bottom;
        const isAll = (borderOptions & BorderOptions.all) === BorderOptions.all;

        if (borderOptions === BorderOptions.none) {
            this.styleCell(cell, { border: {} });
        } else {
            this.styleCell(cell, {
                border: {
                    ...(isLeft || isAll ? { left: this.CELL_BORDER_STYLE } : {}),
                    ...(isRight || isAll ? { right: this.CELL_BORDER_STYLE } : {}),
                    ...(isTop || isAll ? { top: this.CELL_BORDER_STYLE } : {}),
                    ...(isBottom || isAll ? { bottom: this.CELL_BORDER_STYLE } : {}),
                },
            });
        }
    }

    styleCellInColumn(columnKey: ColumnKeyType, activeRow: Row, style: Partial<Style>): void {
        const cell = activeRow.getCell(columnKey);
        this.styleCell(cell, style);
    }

    styleCell(cell: Cell, style: Partial<Style>): void {
        cell.style = {
            ...cell.style,
            ...style,
        };
    }

    styleRow(row: Row, style: Partial<Style> & Pick<Row, 'height'>): void {
        Object.keys(style).forEach((key) => {
            if (style[key]) {
                row[key] = style[key];
            }
        });
    }
}
