const BESTELLUNG_ERROR = 'Ihre Bestellung konnte leider nicht versendet werden.';
const GET_BESTELLUNGEN_ERROR = 'Die Bestellungen konnten nicht geladen werden. Bitte versuchen Sie es noch einmal.';
const GET_KONTRAKTE_ERROR = 'Die Kontrakte konnten nicht geladen werden. Bitte versuchen Sie es noch einmal.';
const UPDATE_BESTELLUNG_ERROR = 'Ihre Bestellung konnte leider nicht aktualisiert werden.';
const SAMMELBESTELLUNG_ERROR = 'Ihre Bestellungen konnten leider nicht versendet werden.';
const KENNZEICHEN_LOADING_ERROR = 'Kennzeichen konnten nicht geladen werden';
const SPEDITION_LOADING_ERROR = 'Beim Speichern der Spedition ist ein Fehler aufgetreten. Bitte geben Sie eine E-Mailadresse ein.';

export { KENNZEICHEN_LOADING_ERROR, BESTELLUNG_ERROR, UPDATE_BESTELLUNG_ERROR, SAMMELBESTELLUNG_ERROR, SPEDITION_LOADING_ERROR };

const GEWICHT_LEERPALETTE = 23;
const FAKTOR_UEBERMENGE = 1.01;
const FAKTOR_BASISEINHEIT_PAL = 750;
const FAKTOR_BASISEINHEIT_VE = 25;

const BESTELLEN_BUTTON_TEXT = 'Jetzt verbindlich bestellen';

export const NEWID_PREFIX = 'NEW-';

const BESTELLUNG_MAX_WEIGHT_IN_KG = 26000;
const OVERWEIGHT_ERROR =
    'Das Gesamtgewicht der Bestellung überschreitet 26t. Bitte bestätigen Sie, dass die Bestellung trotzdem korrekt ist.';
const VVVO_VALIDATION_ERROR = 'Bitte geben Sie eine gültige VVVO-Nummer ein.';
const EMAIL_VALIDATION_ERROR = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
const PHONE_VALIDATION_ERROR = 'Bitte geben Sie eine gültige Telefonnummer ein.';
const FAX_VALIDATION_ERROR = 'Bitte geben Sie eine gültige Faxnummer ein.';

export enum WUNSCHTERMIN_VALIDATION_ERRORS {
    WRONG_DATE_FORMAT = 'Bitte verwenden Sie die Form jjjj-mm-tt.',
    WRONG_TIME_FORMAT = 'Bitte verwenden Sie die Form: hh:mm.',
    DATE_IN_THE_PAST = 'Bitte wählen Sie das heutige Datum oder einen Tag in der Zukunft.',
    MISSING_TIME = 'Bitte geben Sie eine Uhrzeit zu Ihrem Wunschtermin an.',
    MISSING_DATE = 'Bitte geben Sie einen Wunschtermin an.',
    DATUM_VON_IS_AFTER_DATUM_BIS = 'Bitte wählen Sie das "bis Datum" so, dass es nach oder am selben Tag des "von Datum" liegt.',
    TIME_VON_IS_AFTER_TIME_BIS = 'Die ausgewählte (bis) Tageszeit kann nicht vor der (von) Tageszeit liegen.',
}

const WEBSEITEN_VALIDATION_ERROR = 'Bitte geben Sie eine gültige Webseitenadresse ein.';
const BESTELLMENGE_VALIDATION_ERROR = 'Bitte Bestellmenge erfassen.';
const ONLY_STORNO_POSITIONS_ERROR = 'Bitte fügen sie mindestens eine gültige Position hinzu.';
const LOSE_WARE_BESTELLMENGE_VALIDATION_ERROR = 'Bitte Lose Ware Bestellmenge erfassen.';
const BESTELLMENGE_KOMMA_ERROR = 'Bitte geben Sie nur Mengen mit maximal einer Kommastelle ein.';
const SACKWARE_BESTELLMENGE_VALIDATION_ERROR = 'Bitte Sackware Bestellmenge erfassen.';
const WERK_VALIDATION_ERROR = 'Bitte Werk auswählen.';
const WERK_LOAD_ERROR = 'Die Werke konnten leider nicht geladen werden.';
const ZULAGE_VALIDATION_ERROR = 'Bitte definieren Sie die Zulage für Ihre Sonderherstellung.';
const KONTRAKT_VALIDATION_ERROR = 'Bitte wählen Sie einen Kontrakt aus.';
const KONTRAKT_PRICE_VALIDATION_ERROR = 'Für die Preisfindung „Kontrakt“ muss ein gültiger Kontrakt vorliegen und ausgewählt werden.';
const NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR = 'Für einen neuen Artikel müssen Sie eine Beschreibung angeben.';
const LIEFERBEDINGUNG_VALIDATION_ERROR = 'Bitte wählen Sie eine Lieferart aus.';
const ARTIKEL_VALIDATION_ERROR = 'Bitte bestellen Sie mindestens einen Artikel.';
const ARTIKEL_LOAD_ERROR = 'Die Artikel konnten nicht geladen werden. Bitte versuchen Sie es noch einmal.';
const VALIDATION_ERROR = 'Bitte überprüfen Sie die markierten Felder.';

export {
    BESTELLMENGE_KOMMA_ERROR,
    ONLY_STORNO_POSITIONS_ERROR,
    ARTIKEL_VALIDATION_ERROR,
    ARTIKEL_LOAD_ERROR,
    BESTELLEN_BUTTON_TEXT,
    BESTELLMENGE_VALIDATION_ERROR,
    BESTELLUNG_MAX_WEIGHT_IN_KG,
    EMAIL_VALIDATION_ERROR,
    FAKTOR_BASISEINHEIT_PAL,
    FAKTOR_BASISEINHEIT_VE,
    FAKTOR_UEBERMENGE,
    FAX_VALIDATION_ERROR,
    GET_BESTELLUNGEN_ERROR,
    GET_KONTRAKTE_ERROR,
    GEWICHT_LEERPALETTE,
    KONTRAKT_VALIDATION_ERROR,
    KONTRAKT_PRICE_VALIDATION_ERROR,
    LIEFERBEDINGUNG_VALIDATION_ERROR,
    LOSE_WARE_BESTELLMENGE_VALIDATION_ERROR,
    NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR,
    OVERWEIGHT_ERROR,
    PHONE_VALIDATION_ERROR,
    SACKWARE_BESTELLMENGE_VALIDATION_ERROR,
    VVVO_VALIDATION_ERROR,
    WEBSEITEN_VALIDATION_ERROR,
    WERK_LOAD_ERROR,
    WERK_VALIDATION_ERROR,
    ZULAGE_VALIDATION_ERROR,
    VALIDATION_ERROR,
};

export const TIME_FORMAT_REGEX = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;

export const routes = {
    warenempfaengerAnlegen: '/warenempfaenger-anlegen',
    warenempfaengerAuswaehlen: '/warenempfaenger',
    rechnungsempfaengerAuswaehlen: '/rechnungsempfaenger',
    bestelluebermittlung: '/bestelluebermittlung',
    artikelHeimtierfutter: '/artikel-heimtier',
    abholwerk: '/abholwerk',
    bestelldaten: '/bestelldaten',
    bestellzusammenfassung: '/bestellzusammenfassung',
    bestelluebersicht: '/bestelluebersicht',
    bestellansicht: '/bestellansicht',
    auftragsuebersicht: '/auftragsuebersicht',
    auftragsansicht: '/auftragsansicht',
    tourenplan: '/tourenplan',
    bestelldetails: '/bestelldetails',
    kfzKennzeichenUndSpeditionen: '/kfz-kennzeichen-und-speditionen',
    nachrichten: '/nachrichten',
    accountLoeschen: '/account-loeschen',
    lizenzen: '/lizenzen',
    auswahlFuttertyp: '/auswahl-futtertyp',
    artikelNutztier: '/artikel-nutztier',
    startseiteInteressent: '/welcome',
    underConstruction: '/noch-in-aufbau',
    lieferungsuebersicht: '/lieferungen',
    lieferungsansicht: '/lieferung',
    npRechner: '/nprechner',
    agrarwetter: '/agrarwetter',
    marktcharts: '/marktcharts',
    fragenUndAntworten: '/fragen-und-antworten',
    dokumentenuebersicht: '/dokumente',
    benutzer: '/admin/benutzer',
    benutzerdetails: '/admin/benutzerdetails',
    partner: '/admin/partner',
    partnerdetails: '/admin/partnerdetails',
    preislistenkennzeichen: '/admin/preislistenkennzeichen',
    kontraktuebersicht: '/kontraktuebersicht',
    kontraktansicht: '/kontraktansicht',
    futteruntersuchungen: '/futteruntersuchungen',
    rationsservice: '/rationsservice',
};

export const PAGE_TITLE_KONTRAKTE = 'Meine Kontrakte';
export const WUNSCHTERMIN_LABEL = 'Wunschtermin';

export const ACCOUNT_DELETION_EMAIL = 'unsubscribe@deutsche-tiernahrung.de';
