import NotificationBar from '../../ui-components/NotificationBar/NotificationBar';
import {
    ARTIKEL_LOAD_ERROR,
    KONTRAKT_PRICE_VALIDATION_ERROR,
    NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR,
    WERK_LOAD_ERROR,
    WERK_VALIDATION_ERROR,
    ZULAGE_VALIDATION_ERROR,
} from '../../constants';
import { useFormikContext } from 'formik';
import { errorForBestellpositionsField, errorForFieldExist, MAX_WEIGHT_ERROR } from '../../validation/bestellung/validation';
import { ArtikelNutztierfutterFormular } from '../../helper/artikel-helper';
import { useSelector } from 'react-redux';
import { WerkSelectors } from '../../../store/Abholwerk.store';
import { selectArtikelLoseWareLoadFailed } from '../../../store/ArtikelLoseWare.store';
import { ArtikelTableValues } from '../../types';
import { ArtikelSackwarenSelectors } from '../../../store/ArtikelSackware.store';

type NotificationProps = {
    triedToSubmit: boolean;
    reloadArtikelCallback: () => void;
};

const MengeFormError = (): JSX.Element => {
    // TODO: Extract touched state from formik
    const { errors } = useFormikContext<ArtikelTableValues>();
    const [hasError, message] = errorForBestellpositionsField(errors, 'menge');
    return <NotificationBar message={message ?? ''} isVisible={hasError} dataCy={'menge-error-bar'} />;
};
const WunschterminDatumFormError = (): JSX.Element => {
    // TODO: Extract touched state from formik
    const { errors } = useFormikContext<ArtikelTableValues>();
    const [hasError, message] = errorForBestellpositionsField(errors, 'debitorDatum');
    return <NotificationBar message={message ?? ''} isVisible={hasError} dataCy={'debitorDatum-error-bar'} />;
};

const WunschterminUhrzeitFormError = (): JSX.Element => {
    // TODO: Extract touched state from formik
    const { errors } = useFormikContext<ArtikelTableValues>();
    const [hasError, message] = errorForBestellpositionsField(errors, 'debitorUhrzeit');
    return <NotificationBar message={message ?? ''} isVisible={hasError} dataCy={'debitorUhrzeit-error-bar'} />;
};

const MengePalFormError = (): JSX.Element => {
    const { errors } = useFormikContext<ArtikelTableValues>();
    const [hasError, message] = errorForBestellpositionsField(errors, 'mengePal');
    return <NotificationBar message={message ?? ''} isVisible={hasError} dataCy={'menge-pal-error-bar'} />;
};

export const BestellpositionenNotifications = ({ reloadArtikelCallback, triedToSubmit }: NotificationProps): JSX.Element => {
    const { errors } = useFormikContext<ArtikelNutztierfutterFormular>();
    const werkeLoadFailed = useSelector(WerkSelectors.selectWerkeLoadFailed);
    const sackwareLoadFailed = useSelector(ArtikelSackwarenSelectors.loadFailed);
    const loseWareLoadFailed = useSelector(selectArtikelLoseWareLoadFailed);
    const artikelLoadFailed = sackwareLoadFailed || loseWareLoadFailed;

    const allErrors = {
        bestellpositionErrorMessage:
            typeof errors.bestellpositionenView === 'string' && errors.bestellpositionenView !== MAX_WEIGHT_ERROR
                ? errors.bestellpositionenView
                : '',
    };

    return (
        <>
            <NotificationBar
                message={ARTIKEL_LOAD_ERROR}
                actionText="Neu laden"
                actionCallback={reloadArtikelCallback}
                isVisible={artikelLoadFailed}
            />
            <NotificationBar
                dataCy={'bestellpositionen-error-message-bar'}
                message={allErrors.bestellpositionErrorMessage}
                isVisible={allErrors.bestellpositionErrorMessage !== '' && triedToSubmit}
            />
            <NotificationBar
                dataCy={'bestellpositionen-error-message-bar'}
                message={WERK_VALIDATION_ERROR}
                isVisible={errorForFieldExist(errors, 'werkId') && triedToSubmit}
                testId="werke-error-notification"
            />
            <NotificationBar
                dataCy={'zulage-error-message-bar'}
                message={ZULAGE_VALIDATION_ERROR}
                isVisible={errorForFieldExist(errors, 'zulageText') && triedToSubmit}
            />
            <NotificationBar
                dataCy={'bestellpositionen-error-message-bar'}
                message={NEUER_ARTIKEL_BEZEICHNUNG_VALIDATION_ERROR}
                isVisible={errorForFieldExist(errors, 'bezeichnung') && triedToSubmit}
            />
            <MengePalFormError />
            <MengeFormError />
            <WunschterminDatumFormError />
            <WunschterminUhrzeitFormError />
            <NotificationBar
                message={KONTRAKT_PRICE_VALIDATION_ERROR}
                isVisible={errorForFieldExist(errors, 'kontrakt') && triedToSubmit}
            />
            <NotificationBar message={WERK_LOAD_ERROR} isVisible={werkeLoadFailed} />
        </>
    );
};
