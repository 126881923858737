import { useMemo } from 'react';
import {
    createBestellpositionViewSchemaDraftBestellung,
    createDateSchema,
    createTimeSchema,
} from '../../shared/validation/bestellung/validation';
import { minDate } from '../../shared/helper/date-helper';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../store/Navigation.store';
import { datumWEBisSchema, uhrzeitWEBisSchema } from '../../shared/validation/bestellung/schemas';

function createNonDraftBestellungValidationSchema(isDebitor: boolean, overWeightConfirmed: boolean, minDate: string) {
    return Yup.object({
        notificationCheck: Yup.boolean(),
        bestellpositionenView: Yup.object().when('notificationCheck', {
            is: true,
            then: () => {
                return createBestellpositionViewSchemaDraftBestellung(isDebitor, false);
            },
            otherwise: () => {
                return createBestellpositionViewSchemaDraftBestellung(isDebitor, true);
            },
        }),
        lieferbedingung: Yup.string().required(),
        datumDebitor: createDateSchema(minDate),
        uhrzeitDebitor: createTimeSchema(),
        datumWE: createDateSchema(minDate, false),
        uhrzeitWE: createTimeSchema(false),
        datumWEVon: createDateSchema(minDate, false),
        datumWEBis: datumWEBisSchema,
        uhrzeitWEVon: createTimeSchema(false),
        uhrzeitWEBis: uhrzeitWEBisSchema,
    });
}

function createDraftBestellungValidationSchema(isDebitor: boolean) {
    return Yup.object({
        bestellpositionenView: createBestellpositionViewSchemaDraftBestellung(isDebitor, true),
    });
}

// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuftragskorbValidationSchema = (overWeightConfirmed: boolean, saveAsDraft: boolean, storniereAuftrag: boolean) => {
    const isDebitor = useSelector(selectIsDebitor);

    return useMemo(() => {
        if (!storniereAuftrag) {
            if (saveAsDraft) {
                return createDraftBestellungValidationSchema(isDebitor);
            } else {
                return createNonDraftBestellungValidationSchema(isDebitor, overWeightConfirmed, minDate);
            }
        }
        return null;
    }, [isDebitor, overWeightConfirmed, saveAsDraft, storniereAuftrag]);
};
