import React from 'react';
import { BestellungDetails } from '../../types';
import { FormRow } from '../Form/FormRow';
import { useSelector } from 'react-redux';
import { selectIsDebitor } from '../../../store/Navigation.store';
import { FormColumnContent } from '../Form/FormColumnContent';

export const BestellhinweisAndLieferhinweisFormRow: React.FC<{ bestellung: BestellungDetails }> = ({ bestellung }): JSX.Element => {
    const isDebitor = useSelector(selectIsDebitor);
    const bestellhinweis = isDebitor ? bestellung.bestellhinweis : bestellung.bestellhinweisWE;
    return (
        <FormRow>
            <FormColumnContent content={bestellhinweis} title={'Bestellhinweis'} />
            <FormColumnContent content={bestellung.lieferhinweis} title={'Lieferhinweis'} />
        </FormRow>
    );
};
