import type { Warenempfaenger } from '../shared/types';
import { emptyWarenempfaenger } from '../shared/types/defaultValues';
import { NEWID_PREFIX } from '../shared/constants';
import type { RootState } from '../configureStore';

export const ADD_WARENEMPFAENGER = 'ADD_WARENEMPFAENGER';
export const SEARCH_WARENEMPFAENGER = 'SEARCH_WARENEMPFAENGER';
export const GET_WARENEMPFAENGER = 'GET_WARENEMPFAENGER';
export const GET_WARENEMPFAENGER_SUCCEEDED = 'GET_WARENEMPFAENGER_SUCCEEDED';
export const GET_WARENEMPFAENGER_FAILED = 'GET_WARENEMPFAENGER_FAILED';
export const ADD_VVVO_NUMMER = 'ADD_VVVO_NUMMER';
export const RESET_WARENEMPFAENGER = 'RESET_WARENEMPFAENGER';

export interface AddVvvoNummerAction {
    type: typeof ADD_VVVO_NUMMER;
    partnerNummer: string;
    vvvoNummer: string;
}

export interface AddWarenempfaengerAction {
    type: typeof ADD_WARENEMPFAENGER;
    warenempfaenger: Warenempfaenger;
}

export interface GetWarenempfaengerAction {
    type: typeof GET_WARENEMPFAENGER;
}
export interface GetWarenempfaengerSucceededAction {
    type: typeof GET_WARENEMPFAENGER_SUCCEEDED;
    warenempfaenger: Warenempfaenger[];
}
export interface GetWarenempfaengerFailedAction {
    type: typeof GET_WARENEMPFAENGER_FAILED;
    message: string;
}
export interface SearchWarenempfaengerAction {
    type: typeof SEARCH_WARENEMPFAENGER;
    searchText: string;
}
export interface ResetWarenempfaengerAction {
    type: typeof RESET_WARENEMPFAENGER;
}

export function addVvvoNummer(partnerNummer: string, vvvoNummer: string): AddVvvoNummerAction {
    return {
        type: ADD_VVVO_NUMMER,
        partnerNummer,
        vvvoNummer,
    };
}

export function addWarenempfaenger(warenempfaenger: Warenempfaenger): AddWarenempfaengerAction {
    return {
        type: ADD_WARENEMPFAENGER,
        warenempfaenger,
    };
}

export function getWarenempfaenger(): GetWarenempfaengerAction {
    return {
        type: GET_WARENEMPFAENGER,
    };
}

export function getWarenempfaengerSucceeded(warenempfaenger: Warenempfaenger[]): GetWarenempfaengerSucceededAction {
    return {
        type: GET_WARENEMPFAENGER_SUCCEEDED,
        warenempfaenger,
    };
}
export function getWarenempfaengerFailed(message: string): GetWarenempfaengerFailedAction {
    return {
        type: GET_WARENEMPFAENGER_FAILED,
        message,
    };
}

export function searchWarenempfaenger(searchText: string): SearchWarenempfaengerAction {
    return {
        type: SEARCH_WARENEMPFAENGER,
        searchText,
    };
}

export function resetWarenempfaenger(): ResetWarenempfaengerAction {
    return {
        type: RESET_WARENEMPFAENGER,
    };
}

export type WarenempfaengerActions =
    | AddVvvoNummerAction
    | AddWarenempfaengerAction
    | GetWarenempfaengerAction
    | GetWarenempfaengerSucceededAction
    | GetWarenempfaengerFailedAction
    | SearchWarenempfaengerAction
    | ResetWarenempfaengerAction;

export const INITIAL_WARENEMPFAENGER_STATE: WarenempfaengerState = {
    warenempfaenger: [],
    searchResult: [],
    hasLoadError: false,
    isLoading: false,
    loadFinished: false,
};

export type WarenempfaengerState = {
    warenempfaenger: Warenempfaenger[];
    searchResult: Warenempfaenger[];
    hasLoadError: boolean;
    isLoading: boolean;
    loadFinished: boolean;
};

function searchWarenempfaengerHelper(warenempfaenger: Warenempfaenger[], searchText: string): Warenempfaenger[] {
    const searchTerms = searchText.toLowerCase().split(' ');
    const filteredArray = searchTerms.map((term) =>
        warenempfaenger.filter((adresse) => {
            const matchInHauptname = adresse.hauptname.toLowerCase().includes(term);
            const matchInNebenname = (adresse.nebenname || '').toLowerCase().includes(term);
            const matchInAdresse = adresse.ort.toLowerCase().includes(term);
            const matchInPartnernummer = adresse.partnerNummer.includes(term);
            const matchInNamenszusatz = adresse.namenszusatz?.toLowerCase().includes(term);
            return matchInHauptname || matchInNebenname || matchInAdresse || matchInPartnernummer || matchInNamenszusatz;
        })
    );
    const schnittmenge = filteredArray.reduce(
        (acc, curr) =>
            acc.filter((adresse) =>
                curr.find((adresseAusVergleichsArray) => adresse.partnerNummer === adresseAusVergleichsArray.partnerNummer)
            ),
        warenempfaenger
    );
    return schnittmenge;
}

const selectWarenempfaenger = (state: RootState): WarenempfaengerState => state.warenempfaenger;
const selectAllWarenempfaenger = (state: RootState): Warenempfaenger[] => selectWarenempfaenger(state).warenempfaenger;
const selectWarenempfaengerByPartnerNummer = (partnerNummer: string) => {
    return (state: RootState): Warenempfaenger | undefined => {
        return selectAllWarenempfaenger(state).find((partner) => partner.partnerNummer === partnerNummer);
    };
};

export const WarenempfaengerSelectors = {
    all: selectAllWarenempfaenger,
    byPartnerNummer: selectWarenempfaengerByPartnerNummer,
};

export const isNewWarenempfaenger = (we: Warenempfaenger): boolean => we.partnerNummer.startsWith(NEWID_PREFIX);

export function warenempfaengerReducer(state = INITIAL_WARENEMPFAENGER_STATE, action: WarenempfaengerActions): WarenempfaengerState {
    switch (action.type) {
        case ADD_WARENEMPFAENGER:
            const vvvoNummernWithoutSpaces = action.warenempfaenger.vvvoNummern.map((n) => n.replace(/ /g, ''));
            const warenempfaengerToAdd = {
                ...action.warenempfaenger,
                vvvoNummern: vvvoNummernWithoutSpaces,
            };
            return {
                ...state,
                warenempfaenger: [...state.warenempfaenger, warenempfaengerToAdd],
            };
        case ADD_VVVO_NUMMER:
            const warenempfaengerListOhneTarget = state.warenempfaenger.filter((we): boolean => we.partnerNummer !== action.partnerNummer);
            const target = state.warenempfaenger.find((we): boolean => we.partnerNummer === action.partnerNummer) || emptyWarenempfaenger;
            const newVvvoNummer = action.vvvoNummer;
            target.vvvoNummern.push(newVvvoNummer);
            if (target.vvvoNummernNew) {
                target.vvvoNummernNew.push(newVvvoNummer);
            } else {
                target.vvvoNummernNew = [newVvvoNummer];
            }
            return {
                ...state,
                warenempfaenger: [...warenempfaengerListOhneTarget, target],
            };
        case GET_WARENEMPFAENGER:
            return {
                ...state,
                isLoading: true,
            };
        case GET_WARENEMPFAENGER_SUCCEEDED:
            const newWarenempfaenger = state.warenempfaenger.filter(isNewWarenempfaenger);
            const warenempfaengerFromServer = action.warenempfaenger;
            const warenempfaengerFromServerWithNewVvvoNummern = warenempfaengerFromServer.map((serverWe) => {
                const localWe: Warenempfaenger =
                    state.warenempfaenger.find((stateWe) => serverWe.partnerNummer === stateWe.partnerNummer) || emptyWarenempfaenger;
                const newVvvoNummern = localWe.vvvoNummernNew || [];
                return {
                    ...serverWe,
                    vvvoNummern: [...serverWe.vvvoNummern, ...newVvvoNummern],
                    vvvoNummernNew: newVvvoNummern,
                };
            });
            const mergedWithLocal = [...newWarenempfaenger, ...warenempfaengerFromServerWithNewVvvoNummern];
            return {
                ...state,
                warenempfaenger: mergedWithLocal,
                searchResult: mergedWithLocal,
                isLoading: false,
                hasLoadError: false,
                loadFinished: true,
            };
        case GET_WARENEMPFAENGER_FAILED:
            return {
                ...state,
                warenempfaenger: state.warenempfaenger.filter(isNewWarenempfaenger),
                searchResult: state.warenempfaenger.filter(isNewWarenempfaenger),
                hasLoadError: true,
                isLoading: false,
                loadFinished: true,
            };
        case SEARCH_WARENEMPFAENGER:
            return {
                ...state,
                searchResult: searchWarenempfaengerHelper(state.warenempfaenger, action.searchText),
            };
        case RESET_WARENEMPFAENGER:
            return INITIAL_WARENEMPFAENGER_STATE;
        default:
            return state;
    }
}
