import { BestellpositionArtikelLoseWareView } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { useRechnungsempfaengerFromBestellung } from '../../hooks/useRechnungsempfaengerFromBestellung';
import { ArtikelSackwarenSelectors } from '../../../store/ArtikelSackware.store';
import { selectArtikelLoseWareIsLoading, selectArtikelLoseWareList } from '../../../store/ArtikelLoseWare.store';
import { removeAndAddToListOfSelectedArtikel, selectListOfSelectedArtikel } from '../../../store/Artikelauswahl.store';
import { useEffect, useState } from 'react';
import { mergeAndSortOptions, Option } from '../../helper/select-options';
import { useField } from 'formik';
import textItemStyles from '../../ui-components/TextItem/TextItem.module.scss';
import { VirtualizedSelectInput } from '../../ui-components/SelectInput/SelectInput';
import { getWerkeForArtikel } from '../../../store/Abholwerk.store';
import { mapArtikelToOptions } from '../../helper/artikel-helper';

type ArtikelaustauschProps = {
    bestellposition: BestellpositionArtikelLoseWareView;
    inTableCell?: boolean;
};

export const Artikelaustausch = ({ bestellposition, inTableCell = false }: ArtikelaustauschProps): JSX.Element => {
    const dispatch = useDispatch();
    const rechnungsempfaenger = useRechnungsempfaengerFromBestellung();
    const artikelSackwareList = useSelector(ArtikelSackwarenSelectors.list);
    const artikelLoseWareList = useSelector(selectArtikelLoseWareList);
    const selectedArtikelNummern = useSelector(selectListOfSelectedArtikel);
    const loseWareIsLoading = useSelector(selectArtikelLoseWareIsLoading);
    const [selectOptions, setSelectOptions] = useState<Option[]>([]);

    const [_, __, helpers] = useField(`bestellpositionenView[${bestellposition.index}].werkId`);

    useEffect(() => {
        const artikelLoseWareOptions = artikelLoseWareList?.map(mapArtikelToOptions) ?? [];
        const sortedArtikelOptions = mergeAndSortOptions([], artikelLoseWareOptions);
        const availableOptions = sortedArtikelOptions.filter(
            (option) => !selectedArtikelNummern.includes(option.value) || option.value === bestellposition.nummer
        );
        setSelectOptions(availableOptions);
    }, [artikelLoseWareList, artikelSackwareList, bestellposition.nummer, selectedArtikelNummern]);

    const onValueChange = (artikelNummer: string) => {
        if (artikelNummer && rechnungsempfaenger) {
            // NOTE: Remove the old bestellposition.nummer form selected Artikel and add the new one.
            //       With this the old one can be selected again and the new one not anymore.
            dispatch(
                removeAndAddToListOfSelectedArtikel({
                    toRemove: bestellposition.nummer,
                    toAdd: artikelNummer,
                })
            );
            dispatch(getWerkeForArtikel(artikelNummer, rechnungsempfaenger?.partnerNummer));
        }
        helpers.setValue('');
    };

    return loseWareIsLoading ? (
        <div className={textItemStyles._label}>Artikel werden geladen...</div>
    ) : (
        <VirtualizedSelectInput
            onValueChange={onValueChange}
            id={`bestellpositionenView[${bestellposition.index}].nummer`}
            name={`bestellpositionenView[${bestellposition.index}].nummer`}
            options={selectOptions}
            placeholder="Suche..."
            isClearable={false}
            autoSelectIfOnlyOneOption={false}
            inTableCell={inTableCell}
            hideNativeSelect
        />
    );
};
