import * as Sentry from '@sentry/react';
import { Extra } from '@sentry/types/dist/extra';
import { Severity } from '@sentry/types';

export function log(message: string, level: Severity = Severity.Info, extra?: Record<string, Extra>): void {
    Sentry.withScope((scope) => {
        extra && scope.setExtras(extra);
        scope.setLevel(level);
        Sentry.captureMessage(message);
        console.log(message, extra);
    });
}

export function logException(err: Error, extra?: Record<string, Extra>): void {
    Sentry.withScope((scope) => {
        extra && scope.setExtras(extra);
        Sentry.captureException(err);

        console.log(err.message, extra);
    });
}

export function hashUserId(input: string): string {
    return String(
        input.split('').reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0)
    );
}
