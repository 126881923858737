import styles from './NpRechner.module.scss';
/*
IFrame not resizing
The most common cause of this is not placing the iframeResizer.contentWindow.min.js script inside the iFramed page.
If the other page is on a domain outside your control and you can not add JavaScript to that page, then now is the time to give up all hope
of ever getting the iFrame to size to the content. As it is impossible to work out the size of the contained page, without using JavaScript
on both the parent and child pages.

 */
const NpRechner = (): JSX.Element => {
    return (
        <>
            <iframe
                title="RumiTop® N+P-Effizienzrechner"
                className={styles._frame}
                src="https://www.deuka.de/nutztiere/services/n-p-rechner/?external=true&extended=true"
            />
        </>
    );
};

export default NpRechner;
