import { useMediaQuery } from 'react-responsive';

const BREAKPOINT_TABLET_PORTRAIT_UP = 576;
const BREAKPOINT_TABLET_LANDSCAPE_UP = 769;
const BREAKPOINT_DESKTOP = 992;
const BREAKPOINT_BIG_DESKTOP = 1200;

export enum Breakpoint {
    MOBILE = 'MOBILE',
    TABLET_PORTRAIT = 'TABLET_PORTRAIT',
    TABLET_LANDSCAPE = 'TABLET_LANDSCAPE',
    DESKTOP = 'DESKTOP',
    BIG_DESKTOP = 'BIG_DESKTOP',
}

export const useBreakpoint = (breakpoint: Breakpoint): boolean => {
    const isMobile = useMediaQuery({ query: `screen and (max-width: ${BREAKPOINT_TABLET_PORTRAIT_UP}px)` });
    const isTabletPortrait = useMediaQuery({
        query: `screen and (min-width: ${BREAKPOINT_TABLET_PORTRAIT_UP}px)`,
    });
    const isTabletLandscape = useMediaQuery({
        query: `screen and (min-width: ${BREAKPOINT_TABLET_LANDSCAPE_UP}px)`,
    });
    const isDesktop = useMediaQuery({
        query: `screen and (min-width: ${BREAKPOINT_DESKTOP}px)`,
    });
    const isBigDesktop = useMediaQuery({ query: `screen and (min-width: ${BREAKPOINT_BIG_DESKTOP}px)` });

    switch (breakpoint) {
        case Breakpoint.MOBILE:
            return isMobile;
        case Breakpoint.TABLET_PORTRAIT:
            return isTabletPortrait;
        case Breakpoint.TABLET_LANDSCAPE:
            return isTabletLandscape;
        case Breakpoint.DESKTOP:
            return isDesktop;
        case Breakpoint.BIG_DESKTOP:
            return isBigDesktop;
        default:
            return false;
    }
};
