import { GET_MARKEN_FAILED, GET_MARKEN_SUCCEEDED } from '../store/Marke.store';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData } from '../shared/fetchApi';

export function* getMarkenSaga(): IterableIterator<PutEffect | CallEffect> {
    try {
        const marken = yield call(getData, 'admin/marken');
        yield put({ type: GET_MARKEN_SUCCEEDED, marken });
    } catch (e) {
        yield put({ type: GET_MARKEN_FAILED });
    }
}
