import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getRationsservices } from '../../store/Rationsservice.store';

export const useFetchRationsservices = (): (() => void) => {
    const dispatch = useDispatch();

    const fetchRationsservices = useCallback(() => {
        dispatch(getRationsservices({}));
        return;
    }, [dispatch]);

    useEffect(() => {
        fetchRationsservices();
    }, [fetchRationsservices]);

    return fetchRationsservices;
};
