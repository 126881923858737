import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectIsWarenempfaenger } from '../../../store/Navigation.store';
import Tippy from '@tippyjs/react';
import { BestelluebersichtSelectors } from '../../../store/Bestelluebersicht.store';
import { getAenderbarBisText } from './aenderbarBis';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

type AenderbarBisTooltipProps = {
    aenderbarBisExpired: boolean;
    nummer: string;
    positionsnummer?: number;
    storno?: boolean;
    children: ReactElement;
};

const AenderbarBisTooltip = ({ aenderbarBisExpired, nummer, positionsnummer, storno, children }: AenderbarBisTooltipProps): JSX.Element => {
    const aenderbarBis = useSelector(BestelluebersichtSelectors.getAenderbarBisDateForPosition(nummer, positionsnummer));
    const isWarenempfaenger = useSelector(selectIsWarenempfaenger);
    const isMobile = useBreakpoint(Breakpoint.MOBILE);

    return (
        <Tippy
            content={getAenderbarBisText(aenderbarBisExpired, aenderbarBis)}
            disabled={isWarenempfaenger || !aenderbarBis || storno || isMobile}
            placement="top"
            arrow={false}
        >
            {children}
        </Tippy>
    );
};

AenderbarBisTooltip.displayName = 'AenderbarBisTooltip';

export { AenderbarBisTooltip };
