import { useFormikContext } from 'formik';
import NotificationBar from '../NotificationBar/NotificationBar';
import { VALIDATION_ERROR } from '../../constants';

export const FormValidationError = (): JSX.Element => {
    const { errors, touched } = useFormikContext();

    const errorKeys = Object.keys(errors);
    const touchedKeys = Object.keys(touched);
    const hasValidationError = errorKeys.some((errorKey) => touchedKeys.includes(errorKey));

    return <NotificationBar dataCy="validation-message-bar" message={VALIDATION_ERROR} isVisible={hasValidationError} />;
};
