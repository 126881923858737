import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import { useDispatch, useSelector } from 'react-redux';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { DOKUMENTART, dokumenteDownload, DokumentSelectors } from '../../store/DokumentSilageergebnisse.store';
import type { Dokument } from '../../shared/types';
import { localStorageWrapper } from '../../localStorage';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { OverviewPagination } from '../../shared/ui-components/OverviewPagination/OverviewPagination';
import { OverviewTable } from '../../shared/ui-components/OverviewTable/OverviewTable';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { OptionTypeBase } from 'react-select';
import {
    createBelegDatumColumn,
    createBelegNummerColumn,
    createBeschreibungColumn,
    createDokumentenCheckBox,
    createDownloadLinkColumn,
} from './Columns';
import Button from '../../shared/ui-components/Button/Button';
import { useFetchDokumente } from './useFetchDokumente';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { OverviewList } from '../../shared/ui-components/OverviewList/OverviewList';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';
import { FilterPanel, FilterPanelAlignment } from '../../shared/ui-components/Filter/FilterPanel';

const PAGE_SIZE_KEY = 'DokumentSilageergebnisseuebersicht.pageSize';
const PAGE_INDEX_KEY = 'DokumentSilageergebnisseuebersicht.page';
const PAGE_SORTBY_KEY = 'DokumentSilageergebnisseuebersicht.sortBy';

const DEBOUNCE_INPUT_TIME_IN_MS = 400;

const DokumentSilageergebnisseuebersicht = (): ReactElement => {
    const pageTitle = 'Meine Futtermittelanalyse';
    const dispatch = useDispatch();
    const isMobile = useBreakpoint(Breakpoint.MOBILE);

    useDocumentTitle(pageTitle);
    const [pageSizeState, setPageSizeState] = useState<number>(parseInt(localStorageWrapper.getItem(PAGE_SIZE_KEY) || '10'));
    const dokumente = useSelector(DokumentSelectors.dokumente);
    const isLoading = useSelector(DokumentSelectors.dokumenteLoading);
    const totalCount = useSelector(DokumentSelectors.totalCount);
    const loadFailed = useSelector(DokumentSelectors.dokumenteFailed);
    const noDokumentSelected = useSelector(DokumentSelectors.isSelectedDokumenteEmpty);
    const [dokumentartFilterOption] = useState<OptionTypeBase>();
    const columns: Column<Dokument>[] = useMemo(
        () => [
            createDokumentenCheckBox(),
            createBelegNummerColumn(),
            createBeschreibungColumn(),
            createBelegDatumColumn(),
            createDownloadLinkColumn(),
        ],
        []
    );
    const initialPageCount = Math.ceil(totalCount / pageSizeState);
    const tableInstance = useTable<Dokument>(
        {
            columns,
            data: dokumente,
            initialState: {
                sortBy: [
                    {
                        id: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.id || 'belegDatum',
                        desc: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.desc || true,
                    },
                ],
                pageSize: pageSizeState,
                pageIndex: parseInt(localStorageWrapper.getItem(PAGE_INDEX_KEY) || '0'),
            },
            pageCount: initialPageCount,
            manualPagination: true,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortRemove: true,
        },
        useSortBy,
        usePagination
    );
    const { state, gotoPage } = tableInstance;
    const [keyword] = useState<string | undefined>();
    const debouncedKeyword = useDebounce(keyword, DEBOUNCE_INPUT_TIME_IN_MS);

    useEffect(() => {
        gotoPage(0);
    }, [debouncedKeyword, gotoPage]);

    const reloadDokumente = useFetchDokumente(
        dokumentartFilterOption?.value as DOKUMENTART,
        state.sortBy,
        state.pageSize,
        state.pageIndex,
        debouncedKeyword
    );

    return (
        <>
            <h2>{pageTitle}</h2>
            <NotificationBar
                testId="loadError-message-bar"
                message="Die Dokumente konnten nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={reloadDokumente}
            />
            <FilterPanel alignment={FilterPanelAlignment.SPACED}>
                <FilterPanel.Entry>
                    <Button
                        onClick={() => {
                            dispatch(dokumenteDownload());
                        }}
                        disabled={noDokumentSelected}
                    >
                        Download Dokumente
                    </Button>
                </FilterPanel.Entry>
            </FilterPanel>
            <LoadingSpinner isLoading={isLoading}>
                <>
                    {isMobile ? (
                        <OverviewList
                            tableInstance={tableInstance}
                            headerColumns={['belegDatum']}
                            mobileColumns={['checkBox', 'dokumentBelegNummer', 'dokumentBeschreibung', 'belegDatum', 'actions']}
                        />
                    ) : (
                        <OverviewTable tableInstance={tableInstance} />
                    )}
                    {dokumente.length > 0 && (
                        <OverviewPagination tableInstance={tableInstance} setPageSizeState={setPageSizeState} totalCount={totalCount} />
                    )}
                </>
            </LoadingSpinner>
        </>
    );
};

export default DokumentSilageergebnisseuebersicht;
