import { MouseEvent, ReactElement } from 'react';
import styles from './Footer.module.scss';
import dtcLogo from '../../../assets/dtc.png';
import cremerImage from '../../../assets/cremer.jpg';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

export type Props = Readonly<{
    onShowCookieBanner: () => void;
}>;

type FooterLink = {
    label: string;
    href: string;
};

export const FOOTER_LINKS: FooterLink[] = [
    {
        label: 'AGB',
        href: 'https://www.deuka.de/agb/',
    },
    {
        label: 'Datenschutz',
        href: 'https://www.deuka.de/datenschutz/',
    },
    {
        label: 'Impressum',
        href: 'https://www.deuka.de/impressum/',
    },
];

const Footer = ({ onShowCookieBanner }: Props): ReactElement => {
    const isDesktop = useBreakpoint(Breakpoint.DESKTOP);

    const showCookieBanner = (event: MouseEvent): void => {
        onShowCookieBanner();
        event.preventDefault();
    };

    if (isDesktop) {
        return (
            <footer>
                <div className={styles._links}>
                    {FOOTER_LINKS.map((entry, index) => {
                        return (
                            <a key={`${entry.label}-${index}`} href={entry.href} target="_blank" rel="noopener noreferrer">
                                {entry.label}
                            </a>
                        );
                    })}
                </div>
                <div className={styles._content}>
                    <div>
                        <h4>Cookies</h4>
                        <a href="/" onClick={showCookieBanner}>
                            Einstellungen
                        </a>
                    </div>
                    <div>
                        <h4>Services</h4>
                        <a href="https://www.deuka.de/unternehmen/uebersicht-verladezeiten/" target="_blank" rel="noopener noreferrer">
                            Standorte und Verladezeiten
                        </a>
                    </div>
                </div>
                <div className={styles._dtc}>
                    <img className={styles._dtcLogo} src={dtcLogo} alt="Deutsche Tiernahrung Cremer" />
                </div>
                <div className={styles._cremerContainer}>
                    <img src={cremerImage} className={styles._cremerLogo} alt="A company of Cremer" />
                </div>
            </footer>
        );
    }

    return <></>;
};

export default Footer;
