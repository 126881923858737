import tableStyles from '../../shared/ui-components/Table/Table.module.scss';
import { KontraktDetails } from '../../shared/types';
import { useSelector } from 'react-redux';
import { KontraktuebersichtSelectors } from '../../store/Kontraktuebersicht.store';
import { TableCell, TableHeaderCell, TableRow } from '../../shared/ui-components/Table';
import { currencyNumberFormat, currencyUnitFormat } from '../../shared/helper/format-helper';

const artikelTableStyling = `${tableStyles._table} ${tableStyles['_table--spacing']}`;
export const ArtikelReadOnlyTable = (): JSX.Element => {
    const kontrakt: KontraktDetails = useSelector(KontraktuebersichtSelectors.currentKontrakt);
    return (
        <>
            <h3>Artikel</h3>
            <table className={artikelTableStyling}>
                <thead>
                    <tr className={tableStyles._tableHeaderRow}>
                        <TableHeaderCell text>Artikelnr.</TableHeaderCell>
                        <TableHeaderCell text>Bezeichnung</TableHeaderCell>
                        <TableHeaderCell text>Werk</TableHeaderCell>
                        <TableHeaderCell number>Preis</TableHeaderCell>
                    </tr>
                </thead>
                <tbody>
                    {kontrakt.artikelList.map((artikel, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell text>{artikel.nummer}</TableCell>
                                <TableCell text>{artikel.bezeichnung}</TableCell>
                                <TableCell text>{artikel.werk.name}</TableCell>
                                <TableCell number>
                                    {currencyNumberFormat(artikel.preis)}&nbsp;
                                    {currencyUnitFormat(artikel.waehrungsEinheit)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
