import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Order } from '../../shared/types/enums';
import { Dokument, Filter } from '../../shared/types';
import { DOKUMENTART, getDokumenteSilageergebnisse } from '../../store/DokumentSilageergebnisse.store';
import { SortingRule } from 'react-table';

export const useFetchDokumente = (
    dokumentArt: DOKUMENTART,
    sortBy: Array<SortingRule<Dokument>>,
    pageSize: number,
    pageIndex: number,
    keyword?: string
): (() => void) => {
    const dispatch = useDispatch();

    const fetchDokumente = useCallback(() => {
        let sortColumn = sortBy[0].id;
        if (sortColumn === 'absender') {
            sortColumn = 'absenderName';
        }
        const sortOrder = sortBy[0].desc ? Order.DESC : Order.ASC;

        const filter: Filter = {};
        if (dokumentArt) {
            filter.dokumentArt = dokumentArt;
        }
        const keywordTrimmed = keyword?.trim();

        if (!keywordTrimmed) {
            dispatch(getDokumenteSilageergebnisse({ page: pageIndex, size: pageSize, sortColumn, sortOrder, filter, keyword: undefined }));
            return;
        }

        if (keywordTrimmed.length >= 3) {
            dispatch(
                getDokumenteSilageergebnisse({
                    sortColumn: 'belegDatum',
                    sortOrder,
                    size: pageSize,
                    page: pageIndex,
                    filter,
                    keyword: keywordTrimmed,
                })
            );
        }
    }, [dispatch, dokumentArt, keyword, pageIndex, pageSize, sortBy]);

    useEffect(() => {
        fetchDokumente();
    }, [fetchDokumente]);

    return fetchDokumente;
};
