// Generated code; DO NOT EDIT

const buildinfo = {
    version: '0.1.0',
    gitcommit: '2eb6f06',
    buildDate: '2024-10-31T17:13:18.259Z',
};

console.log('version: 0.1.0 git: 2eb6f06 date: 2024-10-31T17:13:18.259Z');

export default buildinfo;
