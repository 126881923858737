import { FormikProps } from 'formik';
import { WarenempfaengerWithFormFields } from '../../shared/types';
import { ReactElement } from 'react';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';
import styles from './WarenempfaengerAnlegen.module.scss';
import IconButton, { IconSize } from '../../shared/ui-components/IconButton/IconButton';
import iconFavorite from '../../assets/icon-favorite.svg';
import iconNonFavorite from '../../assets/icon-non-favorite.svg';

import Label from '../../shared/ui-components/Label/Label';
import { Kommunikationsart } from '../../shared/types/enums';
import { KommunikationsdatenEntries } from './KommunikationsdatenEntries';

export const Kommunikationsdaten = ({ formikProps }: { formikProps: FormikProps<WarenempfaengerWithFormFields> }): ReactElement => {
    const isDesktop = useBreakpoint(Breakpoint.DESKTOP);
    return (
        <>
            <h3>Kommunikationsdaten</h3>
            {!isDesktop ? (
                <div className={styles._kommunikationsdatenHint}>
                    <IconButton icon={iconFavorite} iconSize={IconSize.SMALL} alt="Favorit" />
                    &nbsp;
                    <span>Favorit</span>
                    &nbsp;
                    <IconButton icon={iconNonFavorite} iconSize={IconSize.SMALL} alt="Kein Favorit" />
                    &nbsp;
                    <span>Kein Favorit</span>
                </div>
            ) : null}
            <div className={styles._kommunikationsdatenContainer}>
                <div className={styles._kommunikationsdatenBlock}>
                    <Label>E-Mail Adressen</Label>
                    <KommunikationsdatenEntries
                        formikProps={formikProps}
                        kommunikationsart={Kommunikationsart.EMAIL}
                        fieldForAdding="addEmail"
                        placeholderText="E-Mail Adresse hinzufügen"
                        inputType="email"
                    />
                </div>
                <div className={styles._kommunikationsdatenBlock}>
                    <Label>Telefonnummern</Label>
                    <KommunikationsdatenEntries
                        formikProps={formikProps}
                        kommunikationsart={Kommunikationsart.TELEFON}
                        fieldForAdding="addPhone"
                        placeholderText="Telefonnummer hinzufügen"
                    />
                </div>
                <div className={styles._kommunikationsdatenBlock}>
                    <Label>Fax-Nummern</Label>
                    <KommunikationsdatenEntries
                        formikProps={formikProps}
                        kommunikationsart={Kommunikationsart.FAX}
                        fieldForAdding="addFax"
                        placeholderText="Fax hinzufügen"
                    />
                </div>
                <div className={styles._kommunikationsdatenBlock}>
                    <Label>Webseiten</Label>
                    <KommunikationsdatenEntries
                        formikProps={formikProps}
                        kommunikationsart={Kommunikationsart.WEBSEITE}
                        fieldForAdding="addWebsite"
                        placeholderText="Webseite hinzufügen"
                    />
                </div>
            </div>
        </>
    );
};
